import React, { useState } from 'react';

import * as styles from './FAQ.css';
import classNames from 'classnames/bind';
import FAQAccordion from '../../../components/core/FAQAccordion/FAQAccordion';
import { PRE_LOGIN_UID } from '../../../common/Constants';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

const FAQ = ({ className }) => {
    const [activeFAQ, setActiveFAQ] = useState(null);

    const cmsComponents = useSelector(state => state.pageContent.components) || [];

    if (!cmsComponents) {
        return null;
    }

    const title = cmsComponents.filter(component => component.uid === PRE_LOGIN_UID.FAQ_COMPONENTS.TITLE_UID)[0]
        ?.content;
    const FAQs = cmsComponents.filter(
        cmsComponent =>
            cmsComponent.type === PRE_LOGIN_UID.FAQ_COMPONENTS.TYPE &&
            cmsComponent.uid !== PRE_LOGIN_UID.FAQ_COMPONENTS.TITLE_UID
    );

    if (FAQs.length === 0) {
        return null;
    }

    return (
        <div className={cx('FAQContainer', className)}>
            <p className={cx('title')}>{title}</p>
            <div className={cx('questionsContainer')}>
                {FAQs.map(FAQ => {
                    const isActive = FAQ.uid === activeFAQ;

                    if (!FAQ.content) return null;

                    return (
                        <FAQAccordion
                            key={FAQ.uid}
                            headline={FAQ.localizedName}
                            body={parse(FAQ.content || '')}
                            isActive={isActive}
                            onToggle={() => {
                                setActiveFAQ(isActive ? null : FAQ.uid);
                                buttonClickEvent(
                                    buttonClickEventConfigs.preLogin.actions.preLoginFAQ,
                                    buttonClickEventConfigs.preLogin.labels.preLoginFAQScroll,
                                    buttonClickEventConfigs.preLogin.categories.preLogin
                                );
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default FAQ;
