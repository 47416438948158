import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import classNames from 'classnames/bind';
import Button from '../../core/Button/Button';
import { restoreOrderTemplate } from '../../../actions/OrderTemplateAction';
import * as styles from './RestoreModalContent.css';
import { TEMP_POPUP_L10N, PAGE_ID, GENERIC_L10N } from '../../../common/Constants';

const cx = classNames.bind(styles);
export class RestoreModalContent extends Component {
    static propTypes = {
        row: PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            qty: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            totalQty: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            products: PropTypes.arrayOf(PropTypes.any)
        }),
        onCancel: PropTypes.func,
        commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    };

    static defaultProps = {
        row: {},
        onCancel: () => {},
        commonLocalization: {}
    };

    handleRestore = () => {
        const {
            row: { products },
            miniCartItmes,
        } = this.props;

        if(miniCartItmes){
            miniCartItmes.forEach(element => {
                products.push({
                    productCode: element.code,
                    quantity: element.quantity,
                    unitCode: element.quantityType
                })
            });
        }

        const postData = {
            preventSaveActiveCart: false,
            keepRestoredCart: true,
            products
        };

        this.props.actions.restoreOrderTemplate({ templateId: this.props.row.id, postData });
        this.props.onCancel();
    };

    handleCancel = () => {
        this.props.onCancel();
    };

    renderCartDetails = () => {
        const { row, localization } = this.props;
        return (
            <div className={cx('padtb')}>
                <div className={cx('bdrbBottom', 'innerpad')}>
                    <div automation-id='at-cart-name' className={cx('col6')}>
                        <h4 className={cx('font-bold')}> {localization[TEMP_POPUP_L10N.CART_NAME_TITLE]}</h4>
                    </div>

                    <div automation-id='at-cart-name' className={cx('col6', 'text-right', 'greyclr')}>
                        {row.name}
                    </div>
                </div>
                <div className={cx('padtb', 'innerpad')}>
                    <div automation-id='at-number-of-products' className={cx('col6')}>
                        <h4 className={cx('font-bold')}> {localization[TEMP_POPUP_L10N.NO_OF_PRODS]}</h4>
                    </div>

                    <div automation-id='at-number-of-products' className={cx('col6', 'text-right', 'greyclr')}>
                        {row.totalQty || 0}
                    </div>
                </div>
            </div>
        );
    };

    renderButtonSection = () => {
        const { row, localization, commonLocalization } = this.props;
        return (
            <>
                <Button
                    isDisabled={!row.totalQty}
                    automation-id='at-restore-button'
                    className={cx('restoreButton')}
                    type='button'
                    size='Sm'
                    buttonType='Primary'
                    onClick={this.handleRestore}
                >
                    {localization[TEMP_POPUP_L10N.RESTORE_BTN]}
                </Button>
                <Button
                    automation-id='at-cancel-button'
                    className={cx('cancelButton')}
                    type='button'
                    size='Sm'
                    onClick={this.handleCancel}
                >
                    {commonLocalization[GENERIC_L10N.CANCEL_BTN]}
                </Button>
            </>
        );
    };

    render() {
        const { localization } = this.props;
        return (
            <div className={cx('RestoreModalContainer')}>
                <div className={cx('restoreModalTitle')}>
                    <h1 automation-id='at-restore-template-popup-title' className={cx('text-center')}>
                        {localization[TEMP_POPUP_L10N.RESTORE_TEMP_TITLE]}
                    </h1>
                    <p automation-id='at-restore-template-popup-text' className={cx('text-center')}>
                        {localization[TEMP_POPUP_L10N.RESTORE_TEMP_DESC]}
                    </p>
                </div>
                <div>{this.renderCartDetails()}</div>
                <div className={cx('float-right')}>{this.renderButtonSection()}</div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    localization: _get(state, 'pageContent.localization'),
    commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`),
    miniCartItmes: _get(state, 'cartDetails.miniCartItmes')
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            restoreOrderTemplate
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(RestoreModalContent);
