import { put, takeLatest, call } from 'redux-saga/effects';
import _get from 'lodash/get';
import {
    GET_USER_MAINTENANCE_REQUEST,
    GET_USER_MAINTENANCE_SUCCESS,
    GET_USER_MAINTENANCE_ERROR,
    ADD_NEW_USER_ACCOUNT,
    EDIT_USER_ACCOUNT,
    DELETE_FROM_USER,
    EDIT_USER_ACCOUNT_SUCCESS,
    EDIT_USER_ACCOUNT_ERROR,
    UPDATE_SOFT_CONSENT_FLAG
} from '../actionTypes/UserMaintenanceActionTypes';
import { getErrorInfo } from '../common/Utils/Utils';
import FactorySaga from './FactorySaga';
import UserMaintenanceApi from '../interfaces/UserMaintenance/UserMaintenanceApi';
import { notifySuccess } from '../actions/NotificationAction';

export function* userMaintenanceSaga(action) {
    try {
        const result = yield call(FactorySaga, UserMaintenanceApi.getUserMaintenanceData, action);
        if (result.isSuccess) {
            yield put({ type: GET_USER_MAINTENANCE_SUCCESS, data: _get(result.response, 'data') });
        } else {
            yield put({ type: GET_USER_MAINTENANCE_ERROR, data: { error: getErrorInfo(result) } });
        }
    } catch (err) {
        yield put({ type: GET_USER_MAINTENANCE_ERROR });
    }
}

export function* submitAddNewUserSaga(action) {
    try {
        const result = yield call(FactorySaga, UserMaintenanceApi.submitAddNewUser, action.payload);

        if (result.isSuccess) {
            yield call(userMaintenanceSaga);
            yield put(notifySuccess(result.response.data));
        }
    } catch (err) {
        yield put({ type: GET_USER_MAINTENANCE_ERROR });
    }
}

export function* submitEditUserSaga(action) {
    try {
        const result = yield call(FactorySaga, UserMaintenanceApi.updateEditUser, action.payload);

        if (result.isSuccess) {
            yield put({ type: EDIT_USER_ACCOUNT_SUCCESS, isSuccess: result.isSuccess });
            yield call(userMaintenanceSaga);
            yield put(notifySuccess(result.response.data));
        } else {
            yield put({ type: EDIT_USER_ACCOUNT_ERROR, isSuccess: result.isSuccess });
        }
    } catch (err) {
        yield put({ type: GET_USER_MAINTENANCE_ERROR });
    }
}

function* deleteFromUserSaga(action) {
    try {
        const result = yield call(FactorySaga, UserMaintenanceApi.deleteFromUser, action.payload);

        if (result.isSuccess) {
            yield put(notifySuccess(result.response.data));
            yield call(userMaintenanceSaga);
        }
    } catch (err) {
        yield put({ type: GET_USER_MAINTENANCE_ERROR });
    }
}

export function* updateSoftConsentFlag(action) {
    try {
        yield call(FactorySaga, UserMaintenanceApi.submitSoftConsentFlag, action);
    } catch (err) {
        console.log(err)
    }
}

export default function* watchUserMaintenanceRequest() {
    yield takeLatest(GET_USER_MAINTENANCE_REQUEST, userMaintenanceSaga);
    yield takeLatest(ADD_NEW_USER_ACCOUNT, submitAddNewUserSaga);
    yield takeLatest(EDIT_USER_ACCOUNT, submitEditUserSaga);
    yield takeLatest(DELETE_FROM_USER, deleteFromUserSaga);
    yield takeLatest(UPDATE_SOFT_CONSENT_FLAG, updateSoftConsentFlag);
}