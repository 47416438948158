export const sl = {
  "sl": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
"callus": {
"CallUsTitle": "Pokliči nas",
"ContactsHeader": "Lahko nas kontaktirate na katero koli od naslednjih števil",
"CancelButtonText": "Prekliči",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Already on a call? Let us <a role='link' tabindex='0' class='cx-cobrowse-link'>browse with you</a></span>",
"CoBrowse":"Začnite skupono iskanje",
"CoBrowseWarning":"Skupno brskanje mogoča,da vaš agent vidi in nadzoruje vaše namizje,da vas lažje vodi.ko ste v klicu v živo z agentom,zahtevajte kodo za začetek skupneg brskanja in jo vnesite spodaj.Niste več na klicu?Samo prekličite ta zaslon,da se vrnete na stran Pokličite nas.",
"SubTitle":"Lahko nas pokličete na katero koli od naslednjih številk...",
"AriaWindowLabel":"Pokličite nas-okno",
"AriaCallUsClose":"pokličite nas-zapri",
"AriaBusinessHours":"DelovniČas",
"AriaCallUsPhoneApp":"Odpira telefonsko aplikacijo",
"AriaCobrowseLink":"Odpre skupno iskanje",
"AriaCancelButtonTex":"Prekliče Pokličite nas"
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector": {
      "Title":"Pomoč v živo",
      "SubTitle":"Kako bi radi stopili v stik?",
      "WaitTimeTitle":"Čekalni čas",
      "AvalibleTitle":"Dosegljivo",
      "UnavailableTitle":"Nedosegljivo",
      "CobrowseButtonText":"Skupni iskanje pod natpisa",
      "CallbackTitle":"Dolazni klic",
      "CobrowseSubTitle":"Agent se mora priklopiti.",
      "AriaClose":"Zapri pomoč v živo",
      "AriaWarning":"Opozorilo",
      "minute":"minute",
      "AriaWindowlabel": "Okno za pomoč v živo"
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse": {
"agentJoined":"Agnet se priključil pogovoru.",
"youLeft":"Pogovor ste končali.Skupno iskanje je zdej končano.",
"sessionTimedOut":"Seja je potekla.skupno iskanje je zdej ukinjeno.",
"sessionlnactiveTimedOut":"Seja je potekla.Skupno iskanje je zdej ukinjeno.",
"ageentLeft":"Agent je zdej napustil pogovor.Skupno iskanje je končano.",
"sessionError":"Priplo je do nepričekovane napake.Skupno iskanje je zdej končano.",
"sesionStarted":"Začela je seja skupnog iskanja.Čaka se predstavnik da se vključi v seju...",
"navRefresh": "Predsatvnik je osvežil strani.",
"navBack":"Predstavnik je pritisnel gumb \"nazaj\".Ponovo učitava stranico.",
"navForward":"Pridobivanje podatkov.Počakajte nekaj sekund in poskusite znova /izrezati/ ali osvežiti stranico.",
"navUrl":"Predstavnik išče navigacijo.Spet osvežiti stranicu.",
"navFailed":"Zahtevek zastopnika za navigacijo ni uspela.",
"toolbarContente":"ID seje {sessionld}",
"contentMasked":"Vsebina je skirta pred predstavnikom.",
"comtentMaskedPartially":"Neka celina je skrta od predstavnika",
"exitBtntitle":"Izhod v skupno iskanje seje",
"areYouOnPhone":"Ali si na telefonu z našim predstavnikom?",
"areYouOnPhoneOrChat":"Ali si na telefonu za našim predtsvnikom?",
"connectBeforeCobrowse":"Če želite nastaviti s skupnim iskanjem,morate biti povezani z našim predstavnikom.Pokličite nas,ali začnite klepet v živo z nami in nato znova začnite skupno brskanje.",
"sessionStartedAutoConnet":"seja skupnog iskanja je začela.Čakanje na predstavnika da se poveže na sejo...",
"browseUnsupported":"Žal vaš brskalnik trenutno ni podprt.",
"chatlsAlredyRunning":"Klepet se že izvaja na drugi strani.",
"modalYes":"Ja",
"modalNo":"Ne"
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar": {
"SidebarTitle":"Rabiš pomoč?",
"ChannelSelectorName":"Pomoč v živo",
"ChannelSelectorTitle":"Poiščite pomoč takoj od naših agentov.",
"SearchName":"Iskanje.",
"SearchTitle":"Iskanje.",
"callUsName":"Pokličite nas.",
"CallUsTitle":"Pokličite nas za podrobnosti",
"CallbackName":"Povratni klici",
"Callbacktitle":"Reaktivni klici",
"ClickToCallName":"Klikni za klic",
"ClickToCallTitle":"Zahtevajte telefonsko številko službe za pomoč strankami",
"SendMessageName":"Pošlji sporočilo",
"SendMessageTitle":"Pošlji sporočilo",
"WebChatName":"Klepet v živo",
"WebChatTitle":"Klepet v živo",
"ChatTitle":"Klepet v živo",
"ArialClose":"Zapri meni Rabim pomoč"
    },
    "webchat": {
"ChatTitle":"Klepet v živo",
"Charbutton":"Klepet",
"ChatStarted":"Klepet je začel",
"ChetEnded":"Klepet je končan",
"AgentaNameDefault":"Agent",
"AgentConnected":"<%Agent%>Prikopljen",
"AgentDisconnected":"<%Agent%>Otklopljen",
"BotNameDefault":"Bot",
"BOotConnected":"<%Bot%>Priklopljen",
"BotDisconnected":"<%Bot%>Otklopljen",
"SupervisorNameDefaulr":"Supervizor",
"SupervisorConnected":">Priklopljen",
"SupervisorDisconnected":"<%Agent%>Otklopljen",
"AgentTyping":"#...",
"AriaAgentTyping":"Agent tipka",
"AriaUnavabilable":"Se oprovičujemo,amapk zdje noben ni dostopen.Prosimo poskusite kasneje.",
"CharTitle":"Čet v živo",
"ChatEnd":"X",
"ChatClosed":"X",
"ChatMinimize":"Min",
"ChatFormiFirstName":"Ime",
"ChatFormiLastName":"Priimek",
"ChatFormNickname":"Vzdevek",
"ChatForName":"Ime",
  "ChatFormPlaceholderName":"Možnost",
  "ChatForCustomerId":"ID kupca",
  "ChatForPlaceholderCustomerid":"Možnost",
  "CustomerForSubjectPassword":"Geslo",
  "ChatFromSubjectiveService":"Storitev",
  "ChatFromSubjectiveOreder":"Naročilo",
  "ChatFromSubjectiveFinance":"Financ",
"ChatFormName":"Ime",
"ChatFormCustomerId":"ID kupca",
"ChatFromEmail":"Email",
"ChatFormEmail":"Email",
"ChatFromSubject":"Predmet",
"ChatFormSubject":"Predmet",
"ChatFormSubjectPassword": "Geslo",

"ChatFormPlaceholderFirstName":"Zahteva",
"ChatFormPlaceholderCustomerId":"Neobavezno",
"ChatFormPlaceholderNickaname":"Neobavezno",
"ChatFormPlaceholderEmail":"Zahteva",
"ChatFormPlaceholderSubject":"Izbira",

"ChatFromPlaceholderFirstName":"Zahteva",
"ChatFormPlaceholderLastName":"Zahteva",
"ChatFOrmPlaceholderCustomerid":"Neobavezno",
"ChatFromPlaceholderNickaname":"Neobavezno",
"ChatFromPlaceholderEmail":"Zahteva",
"ChatFormSubmit":"Začni klepet",
"AriaChatFormSubmit":"Začni klepet",
"ChatFormCancel":"Prekinjeno",
"AriaChatFormCancel":"Prekini klepet",
"ChatFormClose":"Zaprto",
"ChatlnputPlaceholder":"Napišite svoje sporočilo tukaj",
"ChatlnputSend":"Pošlji",
"AriaChatlnputSend":"Pošlji",
"ChatEndQuestion":"A ste prepričani da želite končati ovu seju?",
"ChatEndCancel":"Prekinjeno",
"ChatEndConfirm":"Klepet končan",
"AriaChatEndConfirme":"Prekinjen",
"AriaChatEndConfirme":"Končan",
"ConfirmeCloseWindows":"Da li ste prepričani da želite zapreti klepet?",
"ConfirmeCloseCancel":"Prekini",
"ConfirmeCloseConfirm":"Zapri",
"AriaConfirmeCloseCancel":"Prekini",
"AriaConfirmeCloseConfirme":"Zapri",
"ActionsDownloand":"Prenesi prepis",
"ActionsEmail":"Email prepis",
"ActionPrint":"Ptint prepis",
"ActionsCobrowseStart":"Začni skupno iskanje",
"AriaActionCobrovseStartTitle":"Odpri sejo skupno iskanje",
"ActionsSendFile":"Priložite datoteko",
"AriaActionsSendFileTitle":"Odpre pogovorno okno za nalaganje datoteke",
"ActionsEmoji":"Pošlji emoji",
"ActionsCobroweStop":"Pojdi ven iz skupnog iskanja",
"ActionsVideo":"Pokliči v vidio klepet",
"ActionTransfer":"Prenos",
"ActionsInvite":"Pokliči",
"instructionsTransfer":"Odprite to povezavo v drugi napravi za prenos seje klepeta\"</br></br><%link%>\"",
"Instructionslnvite":"Deli to povezavo z drugim ljudmi v tej seji</br></br><%link%>",
"InviteTittle":"Rabite pomoč",
"invitebody":"Sporočite nam,če lahko pomagamo",
"InvitedReject":"Ne,hvala",
"InviteAccept":"Začni klepet",
"ArialnvitetAcceot":"Sprejete povabilo in začetek klepeta",
"ArialInviteRejecti":"Zavrni povabilo",
"ChatError":"Pri zagonu seje klepeta je prišlo do težave.Poskuite znova.",
"ChatErrorButton":"OK",
"AriaChatErrorButton":"OK",
"ChatErrorPrimaryButton":"Ja",
"ChatErrorDefaultButton":"Ne",
"AriaChatErrorPrimaryButton":"Ja",
"AriaChatErrorDefaultButton":"Ne",
"DownloadButton":"Prednesi",
"AriaDownloadButton":"Prenesi datoteko",
"FileSent":"Je poslal",
"FileTransferRety":"Znova",
"AriaFileTransferretry":"Poskusite znove prenesti datoteko",
"filetransferError":"OK",
"AriaFileTransferError":"OK",
"FileTransferCancel":"Prekliči",
"AriaFileTransferCancel":"Prekliči prenos",
"RestoreTimeoutTitle":"Klepet končan",
"RestoreTimeoutBody":"Pretekla seja klepeta je potekla.Bi radi začeli novo?",
"RestoreTimeoutReject":"Ne,hvala",
"RestoreTimeoutAccept":"Začni klepet",
"AriaRestoreTimeoutAccept":"Sprejemi povabilo in začni klepet",
"AriaRstoreTimeReject":"Zavrni povabilo",
"EndConfirmeBody":"Bi res želeli končati sejo klepeta",
"EndConfirmeAccept":"Končaj klepet",
"EndConfirmeRejecte":"Prekliči",
"AriaEndConfirmAccept":"Končaj klepet",
"AriaEndConfirmReject":"Prekliči",
"SurveyOfferQuestion":"Bi radi sodelovali v anketi",
"ShowSurveyAccept":"Ja",
"ShowSurveyReject":"Ne",
"AriaShowSurveyAccept":"Ja",
"AriaShowSurveyReject":"Ne",
"UnreadMessagesTitle":"Neprebrano",
"AriaYouSaid":"Rekel si",
"AriaSaid":"Rekel",
"AriaSystemSaid":"Sistem je povedal",
"AriaWindowlabel":"Okno za klepet v živo",
"AriaMinimize":"Zmanjšajte klepet v živo",
"AriaMaximize":"Povečajte klepet v živo",
"AriaClose":"Zapri klepet v živo",
"AriaEmojiStatusOpen":"Odpre se povogorno okno za izbiro emojijev",
"AriaEmojiStatusClose":"Pogovorno oko izbirnika emoji je zaprto",
"AriaEmoji":"Emoji",
"AriaEmojiPicker":"Izbirnik emoja",
"AriaChaReamining":"Preostali znaki",
"AriaMessagelnput":"Sporočilo",
"AsynyChetEnd":"Končaj klepet",
"AsynyChatClose":"Zapri okno",
"AriaAsynyChetEnd":"Končaj kelpet",
"AriaAsynyChatClose":"Zapri okno",
      "DayLabels": [
"Ned",
"Pon",
"Tor",
"Sre",
"Čet",
"Pet",
"Sub",
      ],
      "MonthLabels": [
"Jan",
"Feb",
"Mar",
"Apr",
"Maj",
"Jun",
"Jul",
"Avg",
"Sep",
"Okt",
"Nov",
"Dec",
      ],
"todayLabel":"Danes",
      "Errors": {
          "102":"Ime je obvezno",
          "103":"Priimek je obvezen",
          "161":"Prosim vnesite svoje ime",
          "201":"Datoteke ni bilo moguče poslati.<br/><strong<p class='imedatoteke'  title='<%ImedatotekePolnno%>!><%ImedatotekeOkrnjeno%>'</p></močno><p class='cx-advice'>Preseženo največje število priloženih datotek(<%MaxFilesAllowed%>).</p>",
          "202":"Datoteko ni bilo moguče poslati.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advic'>Omjeitev nalaganja in/ali največje število prilog bi bila presežena(<%MaxAttachmentsSize%>).</p>",
          "203":"Datoteke ni bilo moguče poslati.<br/><strong><p class='filname' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Vrsta datoteke ni dovoljna.</p>",
          "204":"Žal nam je,ampak vaše sporočilo je predolgo.Prosim,napišite krajše sporočilo.",
          "240":"Žal nam je,ampak trenutno ne moremo začeti novega klepeta.Prosimo,poskusite kasneje.",
          "364":"Nevaljaven e-poštni naslov.",
          "401":"Seje klepeta žal ne moremo avtorizirati.Bi radi začeli nov klepet?",
          "404":"Oprostite,vendar ne moremo najti vaše seje pred kleptom.Bi radi začeli nov klepet?",
          "500":"Žal je prišlo do nepričakovane napake s stvoritvijo.Bi radi zaprli in začeli nov klepet?",
          "503":"Oprostite,storitev trenutno ni na voljo ali je zasedana.Bi radi zaprli in začeli no klepet?",
          "ChatUnavailable":"Žal nam je,amapk trenutno ne moremo začeti novega klepeta.Prodimo poskusite kasneje.",
          "CriricalFault":"Vaša seja klepeta se je nepričakovano končala zaradi neznane težave.Oprovičujemo se za nevšečnosti.",
          "StartFailed":"Pri zagonu seje klepeta je prišlo do težave.Preverite povezavo in pravilno predložitev vseh zahtevanih podatkov,nato poskusite znova.",
          "MessageFailed":"Vaše sporočilo ni bilo uspešno prejeto.Prosimo poskusite znova.",
          "RestoreFailed":"Žal,nam ni bilo moguče obnoviti vaše seje klepeta zarad neznane napake.",
          "TransferFailed":"Trenutno ne moremo prenesti klepeta.Prosimo poskusite znova.",
          "FileTransferSizeError":"To datoteko ni močuge poslati.<br/><strong><p class='filename' title='<%File nameFull%>''>'<%FilenameTruncated%>'</strong><p class='cx-advice'>Velikost datoteke je večja od dovoljene(<%MaxSizePerFile%>).</p>",
          "InviteFailed":"Trenutno ni moguče ustvariti povabila.Prosimo poskusnite spet kasneje.",
          "ChatServerWentOffline":"Sporočila trenutno trajajo dlje kot običajno.Žal nam je za zamudo.",
          "RestoredOffline":"Sporočila trenutno trajajo dlje kot običajno.Žal nam je za zamudo.",
          "Disconnected":"<div style='text-align:center'>Povezava Izgubljen</div>",
          "Reconnected":"div style='text-align:center'>Povezava obnovljena</div>",
          "FileSendFailed":"Datoteke ni bilo moguče poslat.<br/><storng><p class='filname' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong>p class='cx-advice'>Došlo je do nepričekovanog odklopa.Poskusi ponovno?</p>",
          "Generic":"<div stayl='text-aling:center'>Zgodila se je nepričakovana napaka.</div>",
          "pureengage-v3-rest-INVALID_FILE_TYPE":"Nevaljavna vrsta datoteke.Dovoljeno samo za slie.",
          "pureengage-v3-rest-LIMIT_FILE_SIZE":"Velikost datoteke je večja od dvoljene.",
          "Pureengage-v3-rest-LIMIT_FILE_COUNT":"Največje število priloženih datotek je preseglo omejitve.",
          "pureengage-v3-rest-INVALID_CONTACT_CENTER":"Neveljavna konfiguracija prenosa kluča x-api.",
          "pureegage-v3-rest-INVALID_ENDPOINT":"Neveljavna konfiguracija prenosa končne točke.",
          "pureengage-v3.rest-INVALID_NICKANAME":"Ime je obvezno.",
          "pureengage-v3-rest-AUTHENTICATION_REQUIRED":"Žal nam je,vendar seje klepet ne moremo avtorizirati.",
          "purecloud-v2-socketd-400":"Žal,prišlo je do napake.Preverite svojo povezavo in ali ste pravilno poslali sve zahtevane podatke,nato poskusite znova.",
          "purecloud-v2sockets-500":"Žal,pri storitvi je prišlo do nepričekovane napake.",
          "purecloud-v2-sockets-503":"Oprostite,storitev trenutno ni na voljo."
      }
      }
  }
}