import {
    GET_PRODUCT_DETAIL_SUCCESS,
    UPDATE_FAVOURITE_IN_PRODUCT_DETAIL,
    RESET_PRODUCT_DETAIL,
} from '../actionTypes/ProductDetailTypes';
import { pdpFillteredData } from '../service/PDPFillter';
import { pdpDataLayerFillteredData } from '../service/DataLayerFilter';
import {SUBSCRIBE_TO_PRODUCT_SUCCESS} from "../actionTypes/NotifyMeActionTypes";

const initialState = {};

export default function ProductDetailReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_PRODUCT_DETAIL_SUCCESS: {
            const transformedData = pdpFillteredData(action.data);
            transformedData.dataLayer = pdpDataLayerFillteredData(action.data);
            return {
                ...state,
                ...transformedData
            };
        }
        case UPDATE_FAVOURITE_IN_PRODUCT_DETAIL: {
            return {
                ...state,
                isFavourite: action.isFavourite
            };
        }
        case SUBSCRIBE_TO_PRODUCT_SUCCESS: {
            if (!state.code) {
                return state;
            }
            return {
                ...state,
                stockNotified: true
            };
        }
        case RESET_PRODUCT_DETAIL:
            return initialState;
        default:
            return state;
    }
}
