import React from 'react';
import classNames from 'classnames/bind';

import * as styles from '../../OrderTemplate/OrderTemplate.css';
import { PAGE_ID, TEMP_L10N, GENERIC_L10N, URL_PATHS } from '../../../../common/Constants';
import { useSelector } from 'react-redux';
import Button from '../../../../components/core/Button/Button';
import Icon from '../../../../components/core/Icon/Icon';
import Link from 'react-router/lib/Link';

const cx = classNames.bind(styles);
export const ListOfTemplates = ({
    pagination,
    renderRestoreTemplate,
    renderCopyTemplate,
    renderRemoveTemplate,
    carts
}) => {
    const localization = useSelector(state => state.pageContent.localization);
    const commonLocalization = useSelector(state => state.pageContent.commonLocalization[PAGE_ID.GENERIC]);
    const onQuickLinkHandler = (e, id) => {
        e.preventDefault();
        const listItem = document.getElementById(id);
        if (listItem) {
            listItem.classList.toggle('OrderTemplate-expandedListItem');
        }
    };
    return (
        <div automation-id='at-list-of-template' className={cx('orderTempList', 'clearfix')}>
            <ul>
                <li className={cx('orderlistheader', 'hiddenSm')}>
                    <div className={cx('th-data', 'col3')}>{localization[TEMP_L10N.NAME]}</div>
                    <div className={cx('th-data', 'col1', 'md2')}>{localization[TEMP_L10N.DATE_SAVED]}</div>
                    <div className={cx('th-data', 'col2', 'qcenter')}>{localization[TEMP_L10N.QTY]}</div>
                    <div className={cx('th-data', 'col3', 'md2')}>{localization[TEMP_L10N.DESC]}</div>
                    <div className={cx('th-data', 'col3')}>&nbsp;</div>
                </li>
                {pagination && pagination.totalResults > 0 ? (
                    carts &&
                    carts.map((row, i) => {
                        return (
                            <li id={`temp-${row.code}`} key={i.toString()} className={cx('orderlistItem', 'clearfix')}>
                                <div className={cx('td-data', 'col3', 'sm12')}>
                                    <div className={cx('sm7')}>
                                        <Link
                                            to={`${URL_PATHS.ORDER_TEMPLATE_DETAILS}/${row.code}`}
                                            automation-id='at-template-name'
                                            className={cx('tempName', 'font-bold')}
                                        >
                                            {row.name}
                                        </Link>
                                    </div>

                                    <div className={cx('listItemhead', 'hiddenLg', 'visibleSm', 'quickView')}>
                                        <Button
                                            automation-id='at-template-quickview'
                                            type='linkbutton'
                                            buttonType='Ordinary'
                                            className={cx('quickviewBtn')}
                                            onClick={e => onQuickLinkHandler(e, `temp-${row.code}`)}
                                        >
                                            {localization[TEMP_L10N.QUICK_VIEW]}
                                            <Icon
                                                className={cx('downArrow')}
                                                width='16'
                                                height='16'
                                                viewBox='0 0 16 16'
                                                name='down-red'
                                            />
                                        </Button>
                                    </div>
                                </div>

                                <div className={cx('td-data', 'sm12', 'mobShow', 'OrderListShow', 'tempIDdata')}>
                                    <div className={cx('listItemhead', 'visibleSm', 'sm6')}>
                                        {localization[TEMP_L10N.ID]}
                                    </div>
                                    <div
                                        automation-id='at-template-date-saved'
                                        className={cx('visibleSm', 'hiddenLg', 'sm6', 'mobData', 'text-right')}
                                    >
                                        {row.code}
                                    </div>
                                </div>

                                <div className={cx('td-data', 'col1', 'md2', 'sm12', 'greyBG', 'OrderListShow')}>
                                    <div
                                        className={cx(
                                            'listItemhead',
                                            'hiddenLg',
                                            'hiddenMd',
                                            'hiddenXl',
                                            'visibleSm',
                                            'sm6'
                                        )}
                                    >
                                        {localization[TEMP_L10N.DATE_SAVED]}
                                    </div>
                                    <div automation-id='at-template-date-saved' className={cx('sm6', 'mobData')}>
                                        {row.formattedSaveTime}
                                    </div>
                                </div>

                                <div className={cx('td-data', 'col2', 'sm12', 'qcenter', 'greyBG', 'OrderListShow')}>
                                    <div
                                        className={cx(
                                            'listItemhead',
                                            'hiddenLg',
                                            'hiddenMd',
                                            'hiddenXl',
                                            'visibleSm',
                                            'sm6'
                                        )}
                                    >
                                        {localization[TEMP_L10N.QTY]}
                                    </div>
                                    <div automation-id='at-item-qty' className={cx('sm6', 'mobData')}>
                                        {row.totalItems}
                                    </div>
                                </div>

                                <div className={cx('td-data', 'col3', 'md2', 'sm12', 'OrderListShow', 'bdrbtmnone')}>
                                    <div className={cx('listItemhead', 'hiddenLg', 'hiddenXl', 'visibleSm', 'sm6')}>
                                        {localization[TEMP_L10N.DESC]}
                                    </div>
                                    <div automation-id='at-item-description' className={cx('sm6', 'orderDescp')}>
                                        {row.description}
                                    </div>
                                </div>

                                <div className={cx('td-data', 'actions-btn', 'col3', 'hiddenSm')}>
                                    <div className={cx('actionBtn')}>{renderRestoreTemplate(row)}</div>
                                    <span className={cx('actionIcon')}>{renderCopyTemplate(row)}</span>
                                    <span className={cx('dotIcon')}>
                                        <Icon
                                            iconType='svg'
                                            width='25'
                                            height='24'
                                            viewBox='0 0 25 24'
                                            name='horizontal'
                                        />
                                    </span>
                                    <span className={cx('actionIcon')}>{renderRemoveTemplate(row)}</span>
                                </div>
                            </li>
                        );
                    })
                ) : (
                    <li
                        automation-id='at-no-orders-found-text'
                        className={cx('orderlistItem', 'noResults', 'clearfix')}
                    >
                        <Icon iconType='svg' width='24' height='24' viewBox='0 0 24 24' name='no-results' />{' '}
                        {commonLocalization[GENERIC_L10N.NO_RESULTS_MSG]}
                    </li>
                )}
            </ul>
        </div>
    );
};
