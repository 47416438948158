import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as styles from './Tooltip.css';

const cx = classNames.bind(styles);

/* istanbul ignore next */
class Tooltip extends Component {
    static propTypes = {
        renderTooltipContents: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        children: PropTypes.element,
        customTooltipContent: PropTypes.string,
        customInnerStyle: PropTypes.string,
        isChangeEvent: PropTypes.bool,
        onClick: PropTypes.func
    };

    static defaultProps = {
        renderTooltipContents: {},
        children: null,
        customTooltipContent: '',
        customInnerStyle: '',
        isChangeEvent: false,
        onClick: () => {}
    };

    constructor(props) {
        super(props);
        this.state = {
            isVisible: false
        };
    }

    handleMouseEnter = () => {
        this.setState({
            isVisible: true
        });
    };

    handleMouseLeave = () => {
        this.setState({
            isVisible: false
        });
    };

    handleClickEvent = () => {
        const { isVisible } = this.state;
        const { isChangeEvent } = this.props;
        this.setState({
            isVisible: isChangeEvent || !isVisible
        });
    };

    handleBlur = event => {
        const { currentTarget } = event;
        const { relatedTarget } = event;
        if (currentTarget.contains(relatedTarget)) {
            return;
        }
        this.setState(
            {
                isVisible: false
            },
            () => {
                if (typeof this.props.onClick === 'function') {
                    this.props.onClick();
                }
            }
        );
    };

    render() {
        const isVisible = this.state.isVisible ? 'isVisible' : 'isHidden';
        const CustomClassName = `${isVisible}`;
        const {
            renderTooltipContents,
            children,
            customTooltipContent,
            customInnerStyle,
            customTooltipContainer,
            isEmpty
        } = this.props;

        return (
            <div
                className={cx(CustomClassName, 'toolTipContainer', customTooltipContainer)}
                onClick={this.handleClickEvent}
                onKeyDown={this.handleMouseEnter}
                onBlur={this.handleBlur}
                role='button'
                tabIndex={0}
            >
                {children}
                <div
                    onMouseLeave={this.handleMouseLeave}
                    className={cx('tooltipContent', customTooltipContent, this.state.isVisible && customInnerStyle)}
                    style={
                        isEmpty
                            ? { display: 'none' }
                            : this.state.isVisible
                            ? { display: 'block' }
                            : { display: 'none' }
                    }
                >
                    {renderTooltipContents}
                </div>
            </div>
        );
    }
}

export default Tooltip;
