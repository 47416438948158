import { call, put, takeLatest } from 'redux-saga/effects';
import FactorySaga from './FactorySaga';
import MicrosoftChatBotApi from '../interfaces/MicrosoftChatBot/MicrosoftChatBotApi';
import { GET_MICROSOFT_CHATBOT_TOKEN, GET_MICROSOFT_CHATBOT_TOKEN_SUCCESS, GET_MICROSOFT_CHATBOT_TOKEN_ERROR } from '../actionTypes/MicrosoftChatBotTypes'; 

function* getMicrosoftChatBotTokenFunc(action) {
    try {
        const result = yield call(FactorySaga, MicrosoftChatBotApi.getMicrosoftChatBotToken, action);
        if (result.isSuccess) {
            yield put({ type: GET_MICROSOFT_CHATBOT_TOKEN_SUCCESS, data: { ...result.response.data }, payload: action.payload });
        } else {
            yield put({ type: GET_MICROSOFT_CHATBOT_TOKEN_ERROR });
        }
    } catch (err) {
        console.error(err);
        yield put({ type: GET_MICROSOFT_CHATBOT_TOKEN_ERROR });
    }
}

// watch for GET_POWER_BI_TOKEN action to trigger the MySales saga
export default function* watchMicrosoftChatBot() {
    yield takeLatest(GET_MICROSOFT_CHATBOT_TOKEN, getMicrosoftChatBotTokenFunc);
}
