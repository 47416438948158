import {call, put, takeLatest} from 'redux-saga/effects';
import _get from 'lodash/get';
import FactorySaga from './FactorySaga';
import {
    getUser,
    getUserType,
    loginUser,
    logoutUser,
    registerUser,
    updateUserLocale,
    verifyUser
} from '../interfaces/Login/LoginApi';
import {getErrorInfo, getErrorInfoById, navigateFromLogin, redirectTo} from '../common/Utils/Utils';
import {
    getUserId,
    logout,
    setCustomerGroup,
    setMiniCartWorkingWay,
    setOrderSplitIndirect,
    setPickUpAllowed,
    setTokenInfo,
    setUserInfo,
    setUserType,
    setZADOrderType,
} from '../common/UserManager/UserManager';
import {
    NEW_REGISTER_USER,
    NEW_REGISTER_USER_ERROR,
    NEW_REGISTER_USER_SUCCESS,
    UPDATE_USER_LOCALE,
    UPDATE_USER_TYPE,
    USER_LOGIN,
    USER_LOGIN_ERROR,
    USER_LOGOUT,
    USER_VERIFY,
    USER_VERIFY_ERROR,
    USER_VERIFY_SUCCESS
} from '../actionTypes/LoginActionTypes';
import { URL_PATHS } from '../common/Constants';
import { ERROR_CODES } from '../common/MessageCodes';
import { userLoggedInEvent } from '../common/GoogleTagManager/GoogleTagManager';


export function* getUserInfo(action) {
    try {
        const result = yield call(FactorySaga, getUser, action);
        if (result.isSuccess) {
            setUserInfo(result.response.data);
        } else {
            yield put({ type: USER_LOGIN_ERROR });
        }
    } catch (err) {
        yield put({ type: USER_LOGIN_ERROR });
    }
}

// TODO: This API response is merged into getSelectedOutletSaga() later this would be removed.
export function* getUserTypeSaga(action) {
    try {
        const result = yield call(FactorySaga, getUserType, action);

        if (result.isSuccess) {
            const { indirectCustomer, pickUpAllowed, assignedCustGroupCode, isZADOrderType, orderSplitIndirect } = _get(
                result,
                'response.data',
                {}
            );
            setUserType(indirectCustomer);
            setPickUpAllowed(pickUpAllowed);
            setCustomerGroup(assignedCustGroupCode);
            setZADOrderType(isZADOrderType);
            setOrderSplitIndirect(orderSplitIndirect);
            yield put({
                type: UPDATE_USER_TYPE,
                data: { isIndirectUser: indirectCustomer, pickUpAllowed, isZADOrderType, orderSplitIndirect }
            });
        } else {
            setUserType();
            setPickUpAllowed();
            setCustomerGroup();
            setZADOrderType();
            setOrderSplitIndirect();
            setMiniCartWorkingWay();

        }
    } catch (err) {
        yield put({ type: USER_LOGIN_ERROR });
    }
}

function* loginSaga(action = {}) {
    try {
        action.isSkipNotifyError = true;
        const result = yield call(FactorySaga, loginUser, action);

        if (result.isSuccess) {
            const data = _get(result, 'response.data', {});
            // Preserving user session info.
            const principal = _get(action, 'payload.data.crmUsername', '');
            data.principal = principal.toLowerCase();
            setTokenInfo(data);

            // PERF: If the loginUser() API provides the user info skip the getUser() API call.
            if (data.user) {
                setUserInfo(data.user);
            } else {
                yield call(getUserInfo, action);
            }

            try {
                userLoggedInEvent();
            } catch (err) {
                console.error(err);
            }

            // Redirection to bookemarked URL is disabled. After all countries pilot launch is done need to enable this
            // for landing the user to the accessed bookmarked URL page.
            navigateFromLogin();
        } else if (getErrorInfoById(result, ERROR_CODES.CREDENTIALS_EXPIRED)) {
            redirectTo(`${URL_PATHS.RESET_PWD}?userName=${action.payload.data.userName}`);
        } else {
            yield put({ type: USER_LOGIN_ERROR, data: { error: getErrorInfo(result) } });
        }
    } catch (err) {
        yield put({ type: USER_LOGIN_ERROR });
    }
}

function* verifyUserSaga(action) {
    try {
        action.isSkipNotifyError = true;
        const result = yield call(FactorySaga, verifyUser, action);
        if (result.isSuccess) {
            yield put({ type: USER_VERIFY_SUCCESS, data: result.response.data });
        } else {
            yield put({ type: USER_VERIFY_ERROR, data: { error: getErrorInfo(result) } });
        }
    } catch (err) {
        yield put({ type: USER_VERIFY_ERROR });
    }
}

function* registerUserSaga(action) {
    try {
        action.isSkipNotifyError = true;
        const result = yield call(FactorySaga, registerUser, action);

        if (result.isSuccess) {
            yield put({ type: NEW_REGISTER_USER_SUCCESS, data: result.response.data });
        } else {
            yield put({ type: NEW_REGISTER_USER_ERROR, data: { error: getErrorInfo(result) } });
        }
    } catch (err) {
        yield put({ type: NEW_REGISTER_USER_ERROR });
    }
}

function* logoutUserSaga(action) {
    try {
        action.isSkipNotifyError = true;
        yield call(FactorySaga, logoutUser, action);

        //Remove the current session.
        // Hard refresh to clear all the store data of the current session.
        logout(() => window.location.href = URL_PATHS.LOGIN);
        
    } catch (err) {
        yield put({ type: NEW_REGISTER_USER_ERROR });
    }
}

function* updateUserLocaleSaga(action) {
    try {
        action.isSkipNotifyError = true;
        yield call(FactorySaga, updateUserLocale, action);
    } catch (err) {
        yield put({ type: USER_LOGIN_ERROR });
    }
}

export default function* watchLoginRequest() {
    yield takeLatest(USER_LOGIN, loginSaga);
    yield takeLatest(USER_VERIFY, verifyUserSaga);
    yield takeLatest(NEW_REGISTER_USER, registerUserSaga);
    yield takeLatest(USER_LOGOUT, logoutUserSaga);
    yield takeLatest(UPDATE_USER_LOCALE, updateUserLocaleSaga);
}
