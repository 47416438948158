import { put, call, takeLatest, putResolve } from 'redux-saga/effects';
import DeliveryApi from '../interfaces/Cart/DeliveryApi';
import FactorySaga from './FactorySaga';
import { clearAllCartSaga } from './CartSaga';
import { getActiveCartId, setActiveWholeSaler, setDeliveryType } from '../common/UserManager/UserManager';
import Storage from '../common/Storage/Storage';
import { URL_PATHS, ZORB_ORDER } from '../common/Constants';
import { RESET_PRODUCT_LIST } from '../actionTypes/ProductListActionTypes';
import { GET_MULTI_CART, CLEAR_SAVED_CART, RESTORE_CART } from '../actionTypes/CartActionTypes';
import {
    GET_WHOLE_SALER,
    GET_WHOLE_SALER_SUCCESS,
    GET_SELECTED_WHOLE_SALER_REQUEST,
    GET_PLANT_TYPE,
    GET_PLANT_TYPE_SUCCESS,
    GET_SELECTED_PLANT_TYPE_REQUEST,
    ACTIVATE_DELIVERY_TYPE_REQUEST,
    UPDATE_DELIVERY_TYPE,
    UPDATE_WHOLESALER_OUTLET,
    DELIVERY_ERROR
} from '../actionTypes/DeliveryActionTypes';
import { redirectTo } from '../common/Utils/Utils';

export function* activateDeliveryType(action) {
    try {
        const result = yield call(FactorySaga, DeliveryApi.activateDeliveryType, action);
        if (result.isSuccess) {
            setDeliveryType(action.payload.deliveryType);
            yield put({ type: UPDATE_DELIVERY_TYPE, data: action.payload.deliveryType });

            if (getActiveCartId() && action.payload.isClearCart) {
                yield call(clearAllCartSaga, { isClearCart: true });
            }
            yield put({ type: CLEAR_SAVED_CART });
        } else {
            yield put({ type: DELIVERY_ERROR });
        }
    } catch (err) {
        yield put({ type: DELIVERY_ERROR });
    }
}

export function* getWholesalerListSaga(action) {
    try {
        action.isSkipLoader = true;
        const result = yield call(FactorySaga, DeliveryApi.getWholesalerList, action);

        if (result.isSuccess) {
            yield put({ type: GET_WHOLE_SALER_SUCCESS, data: result.response.data });
        } else {
            yield put({ type: DELIVERY_ERROR });
        }
    } catch (err) {
        yield put({ type: DELIVERY_ERROR });
    }
}

export function* getPlantAddressListSaga(action) {
    try {
        action.isSkipLoader = true;
        const result = yield call(FactorySaga, DeliveryApi.getPlantsTypeList, action);

        if (result.isSuccess) {
            yield put({ type: GET_PLANT_TYPE_SUCCESS, data: result.response.data });
        } else {
            yield put({ type: DELIVERY_ERROR });
        }
    } catch (err) {
        yield put({ type: DELIVERY_ERROR });
    }
}

export function* submitWholeSaler(action) {
    try {
        const result = yield call(FactorySaga, DeliveryApi.changeWholeSaler, action);
        const isOptimizedMiniCartFlow = action?.payload?.data?.isOptimizedMiniCartFlow;
        const enableWholesalerRefreshPrices = result.response.data.enableWholesalerRefreshPrices;
        if (result.isSuccess) {
            setActiveWholeSaler(action.payload.data.wholeSalerId);
            yield put({ type: UPDATE_WHOLESALER_OUTLET, data: action.payload.data.wholeSalerId });
            
            if(enableWholesalerRefreshPrices){
                Storage.removeData(URL_PATHS.PLP_REFERRER_URL);
                if(isOptimizedMiniCartFlow){
                    action.payload.isOptmizedMiniCartFlow = isOptimizedMiniCartFlow;
                    yield putResolve({ type: RESET_PRODUCT_LIST });
                    yield put({ type: RESTORE_CART, action });
                } else {
                    yield put({ type: GET_MULTI_CART });
                    yield put({ type: RESET_PRODUCT_LIST });
                }
            }
        } else {
            yield put({ type: DELIVERY_ERROR });
        }
    } catch (err) {
        yield put({ type: DELIVERY_ERROR });
    }
}

export function* submitPlants(action) {
    try {
        const result = yield call(FactorySaga, DeliveryApi.changePlants, action);

        if (result.isSuccess) {
            if(action.payload.data.plantId === ZORB_ORDER.PLANT_ID){
                redirectTo("/plp/order/brand,page/ROSA,1"); // Temporary solution for ZORB order until business comes with requirement
                location.reload();
            }
            setDeliveryType(action.payload.data.plantId);
            yield put({ type: UPDATE_DELIVERY_TYPE, data: action.payload.data.plantId });
        } else {
            yield put({ type: DELIVERY_ERROR });
        }
    } catch (err) {
        yield put({ type: DELIVERY_ERROR });
    }
}

export default function* watchDeliveryRequest() {
    yield takeLatest(ACTIVATE_DELIVERY_TYPE_REQUEST, activateDeliveryType);
    yield takeLatest(GET_PLANT_TYPE, getPlantAddressListSaga);
    yield takeLatest(GET_SELECTED_PLANT_TYPE_REQUEST, submitPlants);
    yield takeLatest(GET_WHOLE_SALER, getWholesalerListSaga);
    yield takeLatest(GET_SELECTED_WHOLE_SALER_REQUEST, submitWholeSaler);
}
