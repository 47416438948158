import classNames from 'classnames/bind';
import React, { Component } from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '../../core/Button/Button';
import { GENERIC_L10N } from '../../../common/Constants';
import * as styles from './OrderTemplateModal.css';

const cx = classNames.bind(styles)

class OrderTemplateModal extends Component {
    render() {
        const { content, localization, onClose } = this.props;
        const { errors } = content;

        return (
            <div className={cx('OrderTemplateModalContainer')}>
                <div className={cx('OrderTemplateModalTitle')}>
                    {localization[GENERIC_L10N.ERROR_UPLOAD_TITLE]}
                </div>
                <div className={cx('OrderTemplateModalSubtitle')}>
                    {localization[GENERIC_L10N.ERROR_UPLOAD_SUBTITLE]}
                </div>
                <div className={cx('OrderTemplateModalMessageList')}>
                    {errors.map(({message, errorCode}) => 
                        <div className={cx('OrderTemplateModalMessage')}>
                            <div className={cx('OrderTemplateModalMessageDescription')}>{message}</div>
                            <div className={cx('OrderTemplateModalMessageCode')}>{errorCode || 'Error: unknown'}</div>
                        </div>)}
                </div>
                <div className={cx('OrderTemplateControlsContainer')}>
                    <Button
                        automation-id='at-upload-catalogue-error-button'
                        className={cx('create-new')}
                        type='button'
                        buttonType='Default'
                        size='Default'
                        onClick={onClose}
                    >
                        OK
                    </Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        localization: _get(state, 'pageContent.localization'),
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderTemplateModal);