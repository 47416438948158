export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const UPDATE_USER_TYPE = 'UPDATE_USER_TYPE';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const CLEAR_LOGIN_PAGE = 'CLEAR_LOGIN_PAGE';
export const CLEAR_LOGIN_MESSAGE = 'CLEAR_LOGIN_MESSAGE';

export const USER_VERIFY = 'USER_VERIFY';
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS';
export const USER_VERIFY_ERROR = 'USER_VERIFY_ERROR';

export const NEW_REGISTER_USER = 'NEW_REGISTER_USER';
export const NEW_REGISTER_USER_SUCCESS = 'NEW_REGISTER_USER_SUCCESS';
export const NEW_REGISTER_USER_ERROR = 'NEW_REGISTER_USER_ERROR';

export const SET_PICKUPALLOWED = 'SET_PICKUPALLOWED';
export const UPDATE_USER_LOCALE = 'UPDATE_USER_LOCALE';