import React from 'react';
import classNames from 'classnames/bind';
import * as styles from './ReadyToOrder.css';
const cx = classNames.bind(styles);
import { PRE_LOGIN_UID, URL_PATHS } from '../../../common/Constants';
import { findComponent } from '../../../common/Utils/Utils';
import { getLocalization } from '../../../common/UserManager/UserManager';
import { Link } from 'react-router';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const ReadyToOrder = ({ className, cmsComponents, loginLink, signupLink, handlePopup, callUsBtn }) => {
    const readyToOrderComponent = findComponent(
        cmsComponents,
        PRE_LOGIN_UID.OURCOMMITMENT_COMPONENTS.READY_TO_ORDER_UID
    );
    const signupTextComponent = findComponent(cmsComponents, PRE_LOGIN_UID.OURCOMMITMENT_COMPONENTS.SIGNUP_TEXT_UID);
    const loginTextComponent = findComponent(cmsComponents, PRE_LOGIN_UID.OURCOMMITMENT_COMPONENTS.LOGIN_TEXT_UID);
    const separatorTextComponent = findComponent(
        cmsComponents,
        PRE_LOGIN_UID.OURCOMMITMENT_COMPONENTS.SEPARATOR_TEXT_UID
    );
    const ignoreSelfRegistrationCountries = ['ni', 'roi', 'austria'];
    const localization = getLocalization();

    return (
        <section className={cx(className)}>
            <div className={cx('login-register-section-background')}>
                <h3 className={cx('title')}>{readyToOrderComponent?.content}</h3>
                <div className={cx('login-register-section')}>
                    <div className={cx('register-section')}>
                        <p>{signupTextComponent?.content}</p>
                        {ignoreSelfRegistrationCountries.includes(localization?.siteId) ? (
                            <button
                                type='button'
                                className={cx('call-us')}
                                onClick={e => {
                                    handlePopup(e);
                                    buttonClickEvent(
                                        buttonClickEventConfigs.preLogin.actions.preLoginBottomBanner,
                                        buttonClickEventConfigs.preLogin.labels.preLoginBottomBannerCallUs,
                                        buttonClickEventConfigs.preLogin.categories.preLogin
                                    );
                                }}
                            >
                                {callUsBtn?.content}
                            </button>
                        ) : (
                            <Link
                                to={URL_PATHS.REGISTER}
                                className={cx('signup-btn')}
                                onClick={() =>
                                    buttonClickEvent(
                                        buttonClickEventConfigs.preLogin.actions.preLoginBottomBanner,
                                        buttonClickEventConfigs.preLogin.labels.preLoginBottomBannerSignUp,
                                        buttonClickEventConfigs.preLogin.categories.preLogin
                                    )
                                }
                            >
                                {signupLink?.content}
                            </Link>
                        )}
                    </div>
                    <div className={cx('or')}>
                        <div className={cx('line')} />
                        <p className={cx('or-text')}>{separatorTextComponent?.content}</p>
                    </div>
                    <div className={cx('login-section')}>
                        <p>{loginTextComponent?.content}</p>
                        <Link
                            to={URL_PATHS.LOGIN}
                            className={cx('login-btn')}
                            onClick={() =>
                                buttonClickEvent(
                                    buttonClickEventConfigs.preLogin.actions.preLoginBottomBanner,
                                    buttonClickEventConfigs.preLogin.labels.preLoginBottomBannerLogin,
                                    buttonClickEventConfigs.preLogin.categories.preLogin
                                )
                            }
                        >
                            {loginLink?.content}
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ReadyToOrder;
