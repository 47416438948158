import React from "react";
import PropTypes from "prop-types";

export const CreditIssueIcon = (props) => {
    return <svg width={`${props.width}px`} height={`${props.height}px`} viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
        <g id="Group_47444" data-name="Group 47444" >
            <g id="Path_2740" data-name="Path 2740" fill="#de6d70">
                <path d="M7.5 14.5c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7z">
                </path>
                <path d="M7.5 1A6.507 6.507 0 001 7.5C1 11.084 3.916 14 7.5 14S14 11.084 14 7.5 11.084 1 7.5 1m0-1a7.5 7.5 0 110 15 7.5 7.5 0 010-15z" fill="#C2443D">
                </path>
            </g>
            <text id="Label" fill="#fff" fontSize="14" fontFamily="Nunito Sans" fontWeight="400">
                <tspan x="5.2" y="12">!</tspan>
            </text>
        </g>
    </svg>
}

CreditIssueIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number
}