import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import Tabs from '../../../components/core/Tabs/Tabs';
import Icon from '../../../components/core/Icon/Icon';
import Button from '../../../components/core/Button/Button';
import TabPanel from '../../../components/core/Tabs/TabPanel';
import PromotionCartSummary from '../../../components/site/PromotionCartSummary/PromotionCartSummary';
import { redirectTo } from '../../../common/Utils/Utils';
import Modal from '../../../components/core/Modal/Modal';
import Layout from '../../../components/site/Layout/Layout';
import { PROMO_NOTE_LENGTH, PAGE_ID, PROMO_L10N, URL_PATHS, GENERIC_L10N } from '../../../common/Constants';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import { getActiveCartId, isReturnEmpties } from '../../../common/UserManager/UserManager';
import { getPageContent } from '../../../actions/PageContentAction';
import {
    addToCartPromo,
    getPromotionsList,
    preCheckoutUpdate,
    removePromoFromCart,
    resetAddToCartPromo,
    resetRemovePromoFromCart,
    removePreselectedDeal,
    resetReqActiveDealIndex,
    resetPromotionList,
} from '../../../actions/PromotionsAction';
import { StepOnePromotion, StepTwoPromotion, StepThreePromotion } from './promotionSteps';
import { clearAllNotification } from '../../../actions/NotificationAction';
import * as styles from './Promotions.css';
import PromotionTypeB from './PromotionsTypeB/PromotionTypeB';
import PromotionNotification from './PromotionNotification/PromotionNotification';

const cx = classNames.bind(styles);

export class Promotions extends Component {

	static propTypes = {
        commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        promotionsData: PropTypes.objectOf(PropTypes.array)
    };

    static defaultProps = {
        commonLocalization: {},
        promotionsData: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            mobilePromotionSummary: false,
            mobileCartSummary: false,
            mobileFreeSummary: false,
            selectGroup: '',
            activeTabPanelIndex: null,
            activeDealIndex: null,
            updatedFreeItemIndex: null,
            selectedDeals: {},
            copiedSelectedDeals: {},
            selectedAllDeals: [],
            showCheckoutWarningModal: false,
            isPreSelectedDeal: null,
            activeCartName: '',
            isLastPromoDeleted: false,
            isNewDiscountAchieved: false,
            achievedDealText: '',
            isInitial: true,
            isAfterUpdateCart: false
        };

		this.autoApplyFreePromotion = false;
    }

    componentDidMount() {
        const activeCartId = getActiveCartId();
        const isReturnEmpty = isReturnEmpties();

        if (!activeCartId) redirectTo(URL_PATHS.CART);
        if (isReturnEmpty) redirectTo(URL_PATHS.CHECKOUT);

        this.props.actions.clearAllNotification();

        if (_isEmpty(this.props.promotionsData)) {
            this.props.actions.getPageContent({ pageId: PAGE_ID.PROMOTIONS });
        } else {
            // Load only CMS when browser back button used and CART page will handle the get promotion call when user comes from there.
            this.props.actions.getPageContent({ pageId: PAGE_ID.PROMOTIONS, isSkipPageCall: true });
            this.setPreSelectedPromoTab();
        }
    }

    componentDidUpdate() {
        const {
            promotionsData: { promotions, isPromoAddedToCart, isPromoRemoved, isRemovePreselectedDeal, isPrecheckoutUpdateDone },
            cartDetails
        } = this.props;
        const { activeTabPanelIndex, isLastPromoDeleted, isInitial } = this.state;

        const activeCartId = getActiveCartId();
        if (
            (promotions && promotions.length === 0) ||
            (cartDetails && cartDetails.totalCartCount === 0) ||
            !activeCartId
        ) {
            redirectTo(URL_PATHS.CART);
        }

        if (activeTabPanelIndex === null && promotions && promotions.length > 0) this.setPreSelectedPromoTab();

        if (isInitial && activeTabPanelIndex !== null && promotions && promotions.length > 0 && promotions[activeTabPanelIndex].mechanicType === 'B') {
            this.setInitialPreselectedDeal(activeTabPanelIndex, promotions);
        }

        if (isPrecheckoutUpdateDone) {
            redirectTo(URL_PATHS.CHECKOUT);
        }

        if (isPromoAddedToCart && isPromoAddedToCart.length > 0 && isPromoAddedToCart[0] === true)
            this.resetSelectedProducts(null, isPromoAddedToCart[0], false);

        if (isPromoRemoved && isPromoRemoved.length > 0 && isPromoRemoved[0] === true) {
            this.resetSelectedProducts(null, false, isPromoRemoved[0]);

            if (isLastPromoDeleted) {
                document.getElementById(`tab${promotions.length - 1}`).firstChild.click();
                this.setLastPromoDeletedToFalse();
            }
        }

        if (isRemovePreselectedDeal !== undefined && isRemovePreselectedDeal !== null) {
            this.resetSelectedProducts(isRemovePreselectedDeal);
        }
    }

    setLastPromoDeletedToFalse = () => {
        this.setState({ isLastPromoDeleted: false });
    };

    resetSelectedProducts = (reqActiveDealIndex = null, isPromoAddedToCart = false, isPromoRemoved = false) => {
        const {
            promotionsData: { promotions }
        } = this.props;
        const { selectedAllDeals, activeTabPanelIndex, activeDealIndex } = this.state;

        const { promotionId } = promotions[activeTabPanelIndex];
        selectedAllDeals[promotionId] = [];
        if (reqActiveDealIndex !== null) {
            this.props.actions.resetReqActiveDealIndex();
            this.setState({ selectedAllDeals, isPreSelectedDeal: null });

            if (activeDealIndex !== null) this.handleStepOneRadioChange({}, reqActiveDealIndex, true);
        } else {
            let preSelectedNextTab = 0;
            if (this.state.isAfterUpdateCart === true) {
                preSelectedNextTab = activeTabPanelIndex;
                this.setState({
                    isAfterUpdateCart: false
                })
            } else {
                preSelectedNextTab = this.getPreSelectedNextPromoTab();
            }

            if (preSelectedNextTab === activeTabPanelIndex) {
                const { showInitial } = promotions[activeTabPanelIndex];
                const preselectedDealIndex = promotions[activeTabPanelIndex].preselectedDeal.id;
                const activeDealIndex =
                    showInitial === true
                        ? promotions[activeTabPanelIndex].deals.findIndex(deal => deal.id === preselectedDealIndex)
                        : 0;

                if (isPromoAddedToCart) this.props.actions.resetAddToCartPromo();
                if (isPromoRemoved) this.props.actions.resetRemovePromoFromCart();
                this.setState({ selectedAllDeals, isPreSelectedDeal: null });
                this.handleStepOneRadioChange({}, activeDealIndex, true);
            } else {
                if (isPromoAddedToCart) this.props.actions.resetAddToCartPromo();
                if (isPromoRemoved) this.props.actions.resetRemovePromoFromCart();

                selectedAllDeals[promotionId] = undefined;

                const selectedDealsCopy = cloneDeep(promotions[preSelectedNextTab].deals[0]);
                let selectedDeals = {};
                const { promotionId: promoId, promotionGuid } = promotions[preSelectedNextTab];
                const dealId = selectedDealsCopy.id;

                if (selectedAllDeals[promoId] === undefined) selectedAllDeals[promoId] = [];

                selectedDealsCopy.promotionGuid = promotionGuid;
                selectedAllDeals[promoId][dealId] = selectedDealsCopy;
                selectedDeals = selectedAllDeals[promoId][dealId];

                selectedDeals.conditionalGroups.map((group, key) => {
                    selectedDeals.conditionalGroups[key].selectedConditionalItem = this.getSelectedItemsCount(
                        selectedDeals.conditionalGroups[key].items
                    );
                    return selectedDeals.conditionalGroups[key].selectedConditionalItem;
                });

                selectedDeals.selectedFreeItem = 0;
                this.setState({
                    activeTabPanelIndex: preSelectedNextTab,
                    selectedDeals,
                    activeDealIndex: 0,
                    selectedAllDeals,
                    activeCartName: promotions[preSelectedNextTab].cartName
                });
                this[`promo-tab-${preSelectedNextTab}`].current.props.onSelect(preSelectedNextTab);
            }
        }
    };

    setPreSelectedPromoTab = () => {
        const {
            promotionsData: { copiedPromotions }
        } = this.props;
        const isPreSelectedPromo = promo => promo.showInitial && promo.showInitial === true;

        let preSelectedTab = copiedPromotions.findIndex(isPreSelectedPromo);

        preSelectedTab = preSelectedTab === -1 ? 0 : preSelectedTab;
        const cartName = copiedPromotions[preSelectedTab] ? copiedPromotions[preSelectedTab].cartName : '';

        this.setState({
            activeTabPanelIndex: preSelectedTab,
            activeCartName: cartName
        });

        return preSelectedTab;
    };

    setInitialPreselectedDeal = (activeTabPanelIndex, promotions) => {
        const targetPreselectedDealIndex = promotions[activeTabPanelIndex].preselectedDeal.id;
        const targetActiveDealIndex = promotions[activeTabPanelIndex].deals.findIndex(deal => deal.id === targetPreselectedDealIndex)


        this.setState({
            isInitial: false,
            activeTabPanelIndex,
            activeDealIndex: targetActiveDealIndex,
            selectedDeals: promotions[activeTabPanelIndex].deals[targetActiveDealIndex]
        }, () => {
            this.handleStepOneRadioChange({}, targetActiveDealIndex, true);
        });
    }

    getPreSelectedNextPromoTab = () => {
        const {
            promotionsData: { promotions, copiedPromotions }
        } = this.props;
        const { activeTabPanelIndex, selectedAllDeals } = this.state;
        let nextPromoList = [];
        let prevPromoList = [];
        let preSelectedTab;
        let activeTabPosition;

        const isPreSelectedPromo = (promo, isOnlyInitial = false) => {
            const initialPreSelect =
                promo.showInitial && promo.showInitial === true && promo.deals[0].freeItemAmount !== 0;
            let userUpdatedQty = 0;

            if (!initialPreSelect && !isOnlyInitial) {
                const { promotionId } = promo;
                promo.deals.map(deal => {
                    if (
                        userUpdatedQty === 0 &&
                        selectedAllDeals[promotionId] &&
                        selectedAllDeals[promotionId][deal.id]
                    ) {
                        const selectedDeals = selectedAllDeals[promotionId][deal.id];
                        const count = selectedDeals.conditionalGroups.reduce((total, currentValue) => {
                            return currentValue.selectedConditionalItem && currentValue.selectedConditionalItem > 0
                                ? total + 1
                                : total;
                        }, 0);
                        userUpdatedQty += count;
                    }
                    return true;
                });
            }

            return initialPreSelect || userUpdatedQty > 0;
        };

        const currentPromo = promotions[activeTabPanelIndex];
        preSelectedTab = isPreSelectedPromo(currentPromo, true);

        if (preSelectedTab === true) return activeTabPanelIndex;

        if (activeTabPanelIndex === 0) {
            nextPromoList = copiedPromotions;
            activeTabPosition = 'first';
        } else if (activeTabPanelIndex === copiedPromotions.length - 1) {
            nextPromoList = copiedPromotions.slice(0, activeTabPanelIndex);
            activeTabPosition = 'last';
        } else {
            nextPromoList = copiedPromotions.slice(activeTabPanelIndex);
            prevPromoList = copiedPromotions.slice(0, activeTabPanelIndex);
            activeTabPosition = 'mid';
        }
        preSelectedTab = nextPromoList.findIndex(isPreSelectedPromo);

        if (preSelectedTab !== -1 && activeTabPosition === 'mid') {
            preSelectedTab += activeTabPanelIndex;
        } else if (preSelectedTab === -1 && activeTabPosition === 'mid') {
            preSelectedTab = prevPromoList.findIndex(isPreSelectedPromo);
        }

        if (preSelectedTab === -1) return activeTabPanelIndex;

        return preSelectedTab;
    };

    onDetailsHandler = (event, targetId, promoIndex, conditionalGroups) => {
        event.preventDefault();
        const handleCloseOpenBehavior = () => {
            for (let index = 0; index < conditionalGroups.length; index++) {
                const currentId = `Promotions-${promoIndex}-${index}`;
                const currentListItem = this[currentId].current;
    
                //check if current mobile promotion is open to close it or open it
                if (currentId === targetId) {
                    currentListItem.classList.toggle('Promotions-expandedListItem');
                } else {
                    // if it is not current and is opened close all other promo groups
                    if (currentListItem && currentListItem.classList.value.includes("Promotions-expandedListItem")) {
                        currentListItem.classList.toggle('Promotions-expandedListItem');
                    }
                }
            }
        }

        const handleScrollToTopOfGroup = () => {
            const getPageOffset = (el) => {
                const rect = el.getBoundingClientRect(),
                    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
            }

            const targetListItem = this[targetId].current;
            const childAnchorTag = targetListItem.getElementsByTagName('a')[0];
            const divOffset = getPageOffset(targetListItem);
    
            window.scrollTo({
                top: divOffset.top - childAnchorTag.offsetHeight,
                left: 0,
            });
        }
        
        handleCloseOpenBehavior();
        handleScrollToTopOfGroup();

        const { mobilePromotionSummary } = this.state;
        const selectGroup = event.currentTarget.id;
        this.setState({
            mobilePromotionSummary: !mobilePromotionSummary,
            selectGroup
        });
    };

    toggleFreeView = () => {
        const { mobileFreeSummary } = this.state;
        this.setState({
            mobileFreeSummary: !mobileFreeSummary
        });
    };

    getSelectedItemsCount = items =>
        items.reduce((total, currentValue) => {
            return currentValue.quantity && currentValue.quantity > 0 ? total + currentValue.quantity : total;
        }, 0);

    getNeedToSelectItemsCount = (group, selectedCount) => {
        let needToSelect = 0;
        needToSelect = group.itemAmount - selectedCount;
        needToSelect = needToSelect <= 0 ? 0 : needToSelect;
        return needToSelect;
    };

    freeItemNeedToSelect = selectedDeal => {
        let needToSelect = 0;
        const selectedItemsCount = this.getSelectedItemsCount(selectedDeal.freeGroup.items);
        needToSelect = selectedDeal.freeItemAmount - selectedItemsCount;
        return needToSelect <= 0 ? 0 : needToSelect;
    };

    setSelectedDeal = activeDealIndex => {
        const {
            promotionsData: { promotions }
        } = this.props;
        const { activeTabPanelIndex } = this.state;
        const selectedDealsCopy = cloneDeep(promotions[activeTabPanelIndex].deals[activeDealIndex]);
        let selectedDeals = {};
        const { promotionId, promotionGuid } = promotions[activeTabPanelIndex];
        const dealId = selectedDealsCopy.id;
        const { selectedAllDeals } = this.state;

        if (selectedAllDeals[promotionId] && selectedAllDeals[promotionId][dealId]) {
            selectedDeals = selectedAllDeals[promotionId][dealId];
        } else {
            if (selectedAllDeals[promotionId] === undefined) {
                selectedAllDeals[promotionId] = [];
            }
            selectedDealsCopy.promotionGuid = promotionGuid;
            selectedAllDeals[promotionId][dealId] = selectedDealsCopy;
            selectedDeals = selectedAllDeals[promotionId][dealId];
        }

        selectedDeals.conditionalGroups.map((group, key) => {
            selectedDeals.conditionalGroups[key].selectedConditionalItem = this.getSelectedItemsCount(
                selectedDeals.conditionalGroups[key].items
            );
            return selectedDeals.conditionalGroups[key].selectedConditionalItem;
        });

        selectedDeals.selectedFreeItem = 0;
        this.setState({ selectedDeals, activeDealIndex });
    };

    removePreselectedDeal = (promotionIndex, dealIndex, reqActiveDealIndex) => {
        this.props.actions.removePreselectedDeal({
            promotionIndex,
            dealIndex,
            reqActiveDealIndex: reqActiveDealIndex - 1
        });
    };

    handleStepOneRadioChange = (e, reqActiveDealIndex, fromAddToCart = false) => {
        const {
            promotionsData: { promotions }
        } = this.props;
        const { activeTabPanelIndex, activeDealIndex } = this.state;
        let { selectedAllDeals } = this.state;

        if (
            fromAddToCart === false &&
            promotions[activeTabPanelIndex].showInitial &&
            promotions[activeTabPanelIndex].deals[0].conditionalItemAmount === 0
        ) {
            this.removePreselectedDeal(activeTabPanelIndex, activeDealIndex, reqActiveDealIndex);
            return 0;
        }

        if (activeDealIndex !== reqActiveDealIndex || fromAddToCart) {
            const { promotionId, promotionGuid } = promotions[activeTabPanelIndex];
            const selectedDealsCopy = cloneDeep(promotions[activeTabPanelIndex].deals[reqActiveDealIndex]);
            selectedDealsCopy.promotionGuid = promotionGuid;
            const dealId = selectedDealsCopy.id;

            if (fromAddToCart) selectedAllDeals = [];

            selectedAllDeals[promotionId] = [];
            selectedAllDeals[promotionId][dealId] = selectedDealsCopy;

            this.setState({ activeDealIndex: reqActiveDealIndex, selectedAllDeals });
        }

        this.setSelectedDeal(reqActiveDealIndex);
    };

    setPreSelectedDeal = (dealsLength, promotionKey, showInitial, mechanicType = '') => {
        const { activeTabPanelIndex, activeDealIndex, isPreSelectedDeal, selectedAllDeals } = this.state;
        let isFromService;
        if (dealsLength > 0 && isPreSelectedDeal !== null && promotionKey === activeTabPanelIndex)
            isFromService = false;
        else if (dealsLength > 0 && showInitial && activeDealIndex == null && promotionKey === activeTabPanelIndex)
            isFromService = true;

        const {
            promotionsData: { promotions }
        } = this.props;
        const { promotionId } = promotions[activeTabPanelIndex];
        const previousActiveDealIndex =
            selectedAllDeals[promotionId] !== undefined &&
                selectedAllDeals[promotionId].previousActiveDealIndex !== undefined
                ? selectedAllDeals[promotionId].previousActiveDealIndex
                : null;

        if (previousActiveDealIndex !== null && previousActiveDealIndex !== undefined) {
            selectedAllDeals[promotionId].previousActiveDealIndex = null;

            //Filter empties and getting first element
            const selectedDeals = selectedAllDeals[promotionId].filter(deal => deal != null)[0];
            selectedDeals.conditionalGroups.map((group, key) => {
                selectedDeals.conditionalGroups[key].selectedConditionalItem = this.getSelectedItemsCount(
                    selectedDeals.conditionalGroups[key].items
                );
                return selectedDeals.conditionalGroups[key].selectedConditionalItem;
            });

            selectedDeals.selectedFreeItem = 0;
            this.setState({ selectedDeals, selectedAllDeals });
        } else if (isFromService && previousActiveDealIndex !== 0) {
            let dealIndex =
                mechanicType === 'B'
                    ? promotions[activeTabPanelIndex].deals.findIndex(
                        deal => deal.id === promotions[activeTabPanelIndex].preselectedDeal.id
                    )
                    : 0;

            if (dealIndex === -1) {
                //Handling if findIndex does not find id and will return -1
                dealIndex = 0;
            }
            this.setSelectedDeal(dealIndex);
        }
    };

    updatedSelectedAllDeals = selectedDeals => {
        const {
            promotionsData: { promotions }
        } = this.props;
        const { activeTabPanelIndex, activeDealIndex, selectedAllDeals } = this.state;
        const { promotionId } = promotions[activeTabPanelIndex];
        const dealId = promotions[activeTabPanelIndex].deals[activeDealIndex].id;
        selectedAllDeals[promotionId][dealId] = selectedDeals;
        return selectedAllDeals;
    };

    changeConditionalQuantity = (value, itemIndex, groupIndex) => {
        const { selectedDeals } = this.state;
        selectedDeals.conditionalGroups[groupIndex].items[itemIndex].quantity = value;
        selectedDeals.conditionalGroups[groupIndex].selectedConditionalItem = this.getSelectedItemsCount(
            selectedDeals.conditionalGroups[groupIndex].items
        );
        const selectedAllDeals = this.updatedSelectedAllDeals(selectedDeals);
        this.setState({ selectedDeals, selectedAllDeals });
    };

    changeFreeQuantity = (value, itemIndex) => {
        const { selectedDeals } = cloneDeep(this.state);
        const copiedSelectedDeals = cloneDeep({ ...selectedDeals });
        let updatedFreeItemIndex = itemIndex;
        selectedDeals.freeGroup.items[itemIndex].quantity = value;
        const selectedFreeItem = this.getSelectedItemsCount(selectedDeals.freeGroup.items);
        if (selectedFreeItem <= selectedDeals.freeItemAmount) {
            selectedDeals.selectedFreeItem = selectedFreeItem;
            updatedFreeItemIndex = null;
        }
        const selectedAllDeals = this.updatedSelectedAllDeals(selectedDeals);
        this.setState({ selectedDeals, copiedSelectedDeals, updatedFreeItemIndex, selectedAllDeals });
    };

    isFreeConditionalSatisfied = updatedFreeItemIndex => {
        const { copiedSelectedDeals } = this.state;
        const { quantity } = copiedSelectedDeals.freeGroup.items[updatedFreeItemIndex];
        copiedSelectedDeals.selectedFreeItem = copiedSelectedDeals.selectedFreeItem
            ? copiedSelectedDeals.selectedFreeItem
            : 0;
        copiedSelectedDeals.freeGroup.items[updatedFreeItemIndex].quantity = quantity !== undefined ? quantity : 0;

        const selectedAllDeals = this.updatedSelectedAllDeals(copiedSelectedDeals);
        this.setState({ selectedDeals: copiedSelectedDeals, updatedFreeItemIndex: null, selectedAllDeals });
    };

    isStepOneActive = () => {
        const { selectedDeals } = this.state;
        return !!(selectedDeals && selectedDeals.conditionalGroups);
    };

    isStepThreeActive = () => {
        const { selectedDeals } = this.state;
        if (selectedDeals && selectedDeals.conditionalGroups) {
            const count = selectedDeals.conditionalGroups.reduce((total, currentValue) => {
                const selectedItems = this.getSelectedItemsCount(currentValue.items);
                const needToSelect = this.getNeedToSelectItemsCount(currentValue, selectedItems);
                return needToSelect + total;
            }, 0);
            return count === 0;
        }
        return false;
    };

    isCheckOutActive = () => {
        const { selectedDeals } = this.state;

        return selectedDeals && selectedDeals.freeGroup
            ? parseInt(selectedDeals.selectedFreeItem, 10) === parseInt(selectedDeals.freeItemAmount, 10)
            : false;
    };

    handleReset = () => {
        const { selectedDeals } = this.state;
        if (this.isStepThreeActive() === true && selectedDeals.selectedFreeItem > 0) {
            selectedDeals.freeGroup.items.map(item => {
                item.quantity = 0;
                return item;
            });
            selectedDeals.selectedFreeItem = 0;
        } else if (this.isStepOneActive() === true) {
            selectedDeals.conditionalGroups.map(group => {
                group.selectedConditionalItem = 0;
                group.items.map(item => {
                    item.quantity = 0;
                    return item;
                });
                return group;
            });
        }

        this.setState({ selectedDeals });
    };

    removePromoFromCart = (promotionId, promotionName) => {
        const { promotionsData } = this.props;
        const reqData = {};
        const { activeTabPanelIndex } = this.state;

        if (promotionsData.promotions.length - 1 === activeTabPanelIndex) {
            this.setState({ activeTabPanelIndex: 0, activeDealIndex: null, isLastPromoDeleted: true });
        }

        reqData.promotionIdToRemove = promotionId;
        reqData.promotionName = promotionName;
        reqData.allAvailiablePromotions = promotionsData.promotions;

        this.props.actions.removePromoFromCart({ data: reqData });
    };

    updatePreviousActiveDeal = reqActiveTabPanelIndex => {
        const {
            promotionsData: { promotions }
        } = this.props;
        const { activeTabPanelIndex, activeDealIndex, selectedAllDeals } = this.state;
        const { promotionId } = promotions[activeTabPanelIndex];

        const reqPromotionId = promotions[reqActiveTabPanelIndex]
            ? promotions[reqActiveTabPanelIndex].promotionId
            : undefined;
        const previousActiveDealIndex =
            reqPromotionId !== undefined &&
                selectedAllDeals[reqPromotionId] !== undefined &&
                selectedAllDeals[reqPromotionId].previousActiveDealIndex !== undefined
                ? selectedAllDeals[reqPromotionId].previousActiveDealIndex
                : null;

        if (selectedAllDeals[promotionId] !== undefined)
            selectedAllDeals[promotionId].previousActiveDealIndex = activeDealIndex;

        return {
            selectedAllDeals,
            activeDealIndex: previousActiveDealIndex,
            isPreSelectedDeal: previousActiveDealIndex
        };
    };

    onTabChangePrepareDataForUpdate = (currentActiveTab, mechanicType, promotions) => {
        const { selectedDeals } = this.state;
        const currentPreselectedDealIndex = promotions[currentActiveTab].preselectedDeal.id;
        const currentActiveDealIndex = promotions[currentActiveTab].deals.findIndex(deal => deal.id === currentPreselectedDealIndex)

        const reqData = {};
        reqData.conditionalItems = [];
        reqData.cartUpdate = mechanicType === 'B';
        reqData.freeItems = [];
        reqData.promotionIds = promotions.map(promo => promo.promotionId);

        // if selected deals are empty take preselected deal by default for current promotion
        if (_isEmpty(selectedDeals)) {
            reqData.selectedDeal = promotions[currentActiveTab].deals[currentActiveDealIndex];
            reqData.promotionGuid = promotions[currentActiveTab].promotionGuid;
            reqData.selectedDeal.promotionGuid = promotions[currentActiveTab].promotionGuid;
            promotions[currentActiveTab].deals[currentActiveDealIndex].conditionalGroups.map(group =>
                group.items.map(item => {
                    if (mechanicType === 'B') {
                        reqData.conditionalItems.push({
                            code: item.productCode,
                            quantity: item.quantity > 0 ? item.quantity : item.inCartAmount
                        });
                    } else if (item.quantity > 0) {
                        reqData.conditionalItems.push({
                            code: item.productCode,
                            quantity: item.quantity
                        });
                    }

                    return reqData;
                })
            )
        } else {
            reqData.selectedDeal = selectedDeals;
            reqData.promotionGuid = selectedDeals.promotionGuid;
            selectedDeals.conditionalGroups.map(group =>
                group.items.map(item => {
                    if (mechanicType === 'B') {
                        reqData.conditionalItems.push({
                            code: item.productCode,
                            quantity: item.quantity > 0 ? item.quantity : item.inCartAmount
                        });
                    } else if (item.quantity > 0) {
                        reqData.conditionalItems.push({
                            code: item.productCode,
                            quantity: item.quantity
                        });
                    }

                    return reqData;
                })
            )
        }

        return reqData;
    }

    handlePromotionTabChangeTypeB = (activeTabPanelIndex, currentActiveTab, promotions, mechanicType) => {
        const targetPreselectedDealIndex = promotions[activeTabPanelIndex].preselectedDeal.id;
        const targetActiveDealIndex = promotions[activeTabPanelIndex].deals.findIndex(deal => deal.id === targetPreselectedDealIndex)

        const reqData = this.onTabChangePrepareDataForUpdate(currentActiveTab, mechanicType, promotions)

        this.setState({
            activeTabPanelIndex,
            activeDealIndex: targetActiveDealIndex,
            selectedDeals: promotions[activeTabPanelIndex].deals[targetActiveDealIndex],
            activeCartName: promotions[activeTabPanelIndex].cartName,
            isAfterUpdateCart: mechanicType === "B"
        }, () => {
            document.getElementById( `tab${activeTabPanelIndex}` ).getElementsByTagName( 'button' )[0].click();
            this.handleStepOneRadioChange({}, targetActiveDealIndex, true);
            if (reqData.conditionalItems.length > 0) {
                this.props.actions.addToCartPromo({ data: reqData });
            }
        });
    }

    handlePromotionTabChange = (activeTabPanelIndex, promotions) => {
        const { selectedAllDeals, activeDealIndex, isPreSelectedDeal } = this.updatePreviousActiveDeal(
            activeTabPanelIndex
        );

        this.setState({
            activeTabPanelIndex,
            activeDealIndex,
            selectedDeals: {},
            selectedAllDeals,
            isPreSelectedDeal,
            activeCartName: promotions[activeTabPanelIndex].cartName
        });
    }

    handlePromotionChange = activeTabPanelIndex => {
        const { activeTabPanelIndex: currentActiveTab } = this.state;

        if (currentActiveTab !== activeTabPanelIndex) {
            const {
                promotionsData: { promotions }
            } = this.props;

            if (promotions[currentActiveTab].mechanicType !== 'B' && promotions[activeTabPanelIndex].mechanicType === 'B') {
                // current is NOT type B and target is type B
                // call get promotion
                const targetPreselectedDealIndex = promotions[activeTabPanelIndex].preselectedDeal.id;
                const targetActiveDealIndex = promotions[activeTabPanelIndex].deals.findIndex(deal => deal.id === targetPreselectedDealIndex)

                this.setState({
                    activeTabPanelIndex: activeTabPanelIndex,
                    activeDealIndex: targetActiveDealIndex,
                    activeCartName: promotions[activeTabPanelIndex].cartName
                }, () => {
                    this.handleStepOneRadioChange({}, targetActiveDealIndex, true);
                    this.props.actions.getPromotionsList();
                });

            } else if (promotions[currentActiveTab].mechanicType === 'B') {
                // current is type B
                this.handlePromotionTabChangeTypeB(activeTabPanelIndex, currentActiveTab, promotions, promotions[currentActiveTab].mechanicType);

            } else {
                // current is NOT type B and target is NOT type B 
                this.handlePromotionTabChange(activeTabPanelIndex, promotions);
            }
        }
    };

    continueNextPromo = e => {
        e.preventDefault();
        const { activeTabPanelIndex } = this.state;
        document.getElementById(`tab${activeTabPanelIndex + 1}`).firstChild.click();
    };

    isStepTwoItemsSelected = (promotions, copiedPromotions) => {
        const { selectedAllDeals } = this.state;
        let showCount = 0;
        promotions.map(promo => {
            const { promotionId } = promo;
            return showCount === 0
                ? promo.deals.map(deal => {
                    if (showCount === 0 && selectedAllDeals[promotionId] && selectedAllDeals[promotionId][deal.id]) {
                        const selectedDeals = selectedAllDeals[promotionId][deal.id];
                        const count = selectedDeals.conditionalGroups.reduce((total, currentValue) => {
                            return currentValue.selectedConditionalItem && currentValue.selectedConditionalItem > 0
                                ? total + 1
                                : total;
                        }, 0);
                        showCount += count;
                    }
                    return true;
                })
                : showCount;
        });

        if (showCount === 0) {
            copiedPromotions.map(promo => {
                showCount =
                    showCount === 0 && promo.deals[0].conditionalItemAmount === 0 && promo.deals[0].freeItemAmount !== 0
                        ? (showCount += 1)
                        : showCount;
                return showCount;
            });

            if (showCount > 0) {
                this.setState({
                    updatedFreeItemIndex: null,
                    selectedDeals: {},
                    copiedSelectedDeals: {},
                    selectedAllDeals: [],
                    isPreSelectedDeal: null
                });
                this.props.actions.resetPromotionList();
            }
        }

        return showCount > 0;
    };

    arePromotionsValid = (verifyFreePromotions = false) => {
        const {
            promotionsData: { promotions, copiedPromotions }
        } = this.props;

        //filter by showInitial to get initally valid promotions returned from BE and not current while user is clicking
		let pormoMechanicToVerify = 'A';
		if(verifyFreePromotions){
			pormoMechanicToVerify = '';
		}

        const promotionsToVerify = promotions.filter(promotion => promotion.mechanicType === pormoMechanicToVerify);
        const copiedPromotionsToVerify = copiedPromotions.filter(promotion => promotion.mechanicType === pormoMechanicToVerify);

        return (
            this.isStepTwoItemsSelected(promotionsToVerify, copiedPromotionsToVerify) === false
        );
    };

    goToCheckOutPage = () => {
        if (this.arePromotionsValid()) {
            const {
                promotionsData: { promotions }
            } = this.props;
            const promosTypeB = promotions.filter(el => el.mechanicType === "B");

			this.autoApplyFreePromotion = !this.arePromotionsValid(true);

			let payload = null;

            // TODO finalize the call and state
            if (promosTypeB.length > 0) {

                // This will redirect in component did update after the call is done
                // isPrecheckoutUpdateDone is set into reducer for this call
                const { activeTabPanelIndex: currentActiveTab } = this.state;

                if (promotions[currentActiveTab].mechanicType === "B") {
                    payload = this.onTabChangePrepareDataForUpdate(currentActiveTab, promotions[currentActiveTab].mechanicType, promotions)
                }

                this.props.actions.preCheckoutUpdate({ data: payload });

            } else if (this.autoApplyFreePromotion) {
				this.props.actions.preCheckoutUpdate({ data: payload });
			}else{
                redirectTo(URL_PATHS.CHECKOUT);
            }
        } else {
            this.setState({ showCheckoutWarningModal: true });
        }
    };

    handletWarningModalClose = () => {
        this.setState({ showCheckoutWarningModal: false });
    };

    isToShowPromoTabIcon = promo => {
        const { selectedAllDeals } = this.state;
        const { promotionId } = promo;
        let showCount = 0;

        if (promo.showInitial) return true;

        promo.deals.map(deal => {
            if (showCount === 0 && selectedAllDeals[promotionId] && selectedAllDeals[promotionId][deal.id]) {
                const selectedDeals = selectedAllDeals[promotionId][deal.id];
                showCount += selectedDeals.conditionalGroups.reduce((total, currentValue) => {
                    return currentValue.selectedConditionalItem && currentValue.selectedConditionalItem > 0
                        ? total + 1
                        : total;
                }, 0);
            }
            return showCount;
        });

        return showCount > 0;
    };

    addToCartPromo = () => {
        const { selectedDeals } = this.state;
        const { promotionsData } = this.props;
        const reqData = {};
        reqData.selectedDeal = selectedDeals;
        reqData.conditionalItems = [];
        reqData.freeItems = [];
        reqData.cartUpdate = false;
        reqData.promotionIds = promotionsData.promotions.map(promo => promo.promotionId);
        reqData.promotionGuid = selectedDeals.promotionGuid;
        selectedDeals.conditionalGroups.map(group =>
            group.items.map(item => {
                if (item.quantity > 0) {
                    reqData.conditionalItems.push({
                        code: item.productCode,
                        quantity: item.quantity
                    });
                }

                return reqData;
            })
        );

        selectedDeals.freeGroup.items.map(item => {
            if (item.quantity > 0)
                reqData.freeItems.push({
                    code: item.productCode,
                    quantity: item.quantity
                });

            return reqData;
        });

        if (reqData.conditionalItems.length > 0 || reqData.freeItems.length > 0) {
            this.props.actions.addToCartPromo({ data: reqData });
        }
    };

    updateCart = () => {
        const { selectedDeals } = this.state;
        const { promotionsData } = this.props;
        const reqData = {};
        reqData.selectedDeal = selectedDeals;
        reqData.conditionalItems = [];
        reqData.freeItems = [];
        reqData.cartUpdate = true;
        reqData.promotionIds = promotionsData.promotions.map(promo => promo.promotionId);
        reqData.promotionGuid = selectedDeals.promotionGuid;
        selectedDeals.conditionalGroups.map(group =>
            group.items.map(item => {
                reqData.conditionalItems.push({
                    code: item.productCode,
                    quantity: item.quantity > 0 ? item.quantity : item.inCartAmount
                });
                return reqData;
            })
        );

        this.setState({
            isAfterUpdateCart: true
        }, () => {
            if (reqData.conditionalItems.length > 0 || reqData.freeItems.length > 0)
                this.props.actions.addToCartPromo({ data: reqData });
        })
    };

    renderPageTitle = (title, subTitle) => {
        return (
            <>
                <h1 automation-id='at-product-promotions-title-text' className={cx('title', 'col10', 'sm12')}>
                    <Icon className={cx('leftIcon')} width='24px' height='24px' viewBox='0 0 24 24' name='promo-icon' />
                    {title}
                </h1>
                <div className={cx('col12', 'pad0', 'visibleSm')}>
                    <p automation-id='at-promotions-sub-title-text' className={cx('cartTxt')}>
                        {subTitle}
                    </p>
                </div>
            </>
        );
    };

    renderCheckoutButton = checkoutText => {
        return (
            <Button
                automationId='at-continue-to-check-out-button'
                type='button'
                onClick={() => this.goToCheckOutPage()}
                buttonType='Primary'
                className={cx('continueBtn', 'col5', 'sm12')}
                title='checkoutBtn'
            >
                {checkoutText}
            </Button>
        );
    };

    getItemsQuantityPromoB = group => {
        const result = group.items.reduce((total, currentValue) => {
            return currentValue.quantity !== undefined && currentValue.quantity >= 0
                ? total + currentValue.quantity
                : total + currentValue.inCartAmount;
        }, 0);
        return result;
    };

    areGroupConditionsMet = () => {
        const { selectedDeals, activeDealIndex } = this.state;
        const conditionalGroups =
            activeDealIndex != null && selectedDeals.conditionalGroups ? selectedDeals.conditionalGroups : [];

        const groupsState = conditionalGroups.map(group => {
            const selectedCount = this.getItemsQuantityPromoB(group);
            return selectedCount >= group.itemAmount;
        });

        return groupsState.length > 0 ? groupsState.every(state => state === true) : false;
    };


    handleNewPromotionDiscount = (isNewDiscountAchieved, achievedDealText) => {
        this.setState({
            isNewDiscountAchieved: isNewDiscountAchieved,
            achievedDealText: achievedDealText
        })
    }

    renderNextPromoButton = (localization, currentActiveTabIndex) => {
        const nextActiveTargetPanelIndex = currentActiveTabIndex + 1;

        return (<Button
            automationId='at-add-to-cart-button'
            type='button'
            buttonType='Primary'
            size={cx('Md')}
            className={cx(
                'cartsBtns',
                'promoDetailsHeaderNextPromoButton'
            )}

            onClick={() =>
                this.handlePromotionChange(nextActiveTargetPanelIndex)
            }
        >
            {localization[GENERIC_L10N.NEXT_PROMO]}
        </Button>)
    }

    isAddToCartDisabled = () => !this.isStepThreeActive();

    isLastPromotionActive = (promotionsLength, activeTabPanelIndex) => {
        return promotionsLength > 1 && activeTabPanelIndex === (promotionsLength - 1);
    }

    renderAddToCartPromoButton = (commonLocalization) => {
        return (<Button
            automationId='at-add-to-cart-button'
            type='button'
            buttonType='Primary'
            size={cx('Md')}
            className={cx(
                'cartsBtns',
                'promoDetailsHeaderNextPromoButton',
                this.isAddToCartDisabled() && 'disabled'
            )}

            isDisabled={this.isAddToCartDisabled()}

            onClick={() => this.addToCartPromo()}
        >
            {commonLocalization[GENERIC_L10N.ADD_TO_CART]}
        </Button>)
    }

    

    render() {
        const {
            mobileCartSummary,
            activeTabPanelIndex,
            showCheckoutWarningModal,
            activeCartName,
            activeDealIndex,
            isPreSelectedDeal,
            mobilePromotionSummary,
            selectGroup,
            selectedDeals,
            mobileFreeSummary,
            updatedFreeItemIndex
        } = this.state;
        const { promotionsData, localization, commonLocalization } = this.props;
        const { promotions, breadcrumbs } = promotionsData;

        return (
            <Layout showDashboardHeader showOutlet>
                <div automation-id='at-promotions-screen'>
                    <BreadCrumbs breadcrumbValues={breadcrumbs} />
                    {promotions && promotions.length > 0 && localization[PROMO_L10N.TITLE] && (
                        <div className={cx('promotionsPageContainer')}>

                            <div className={cx('col8', 'pad0', 'mobileHeaderWidth-col12')}>
                                {this.renderPageTitle(
                                    localization[PROMO_L10N.TITLE],
                                    localization[PROMO_L10N.SUB_TITLE]
                                )}
                                {showCheckoutWarningModal && (
                                    <Modal
                                        automationId='at-warning-message'
                                        showModal={showCheckoutWarningModal}
                                        onClose={() => this.handletWarningModalClose()}
                                        title={localization[PROMO_L10N.CTC_WARNING_TITLE]}
                                    >
                                        <div automation-id='at-warning-sub-title-text'>
                                            <p>{localization[PROMO_L10N.CTC_WARNING_SUBTITLE]}</p>
                                        </div>
                                    </Modal>
                                )}
                            </div>
                            {this.renderCheckoutButton(localization[PROMO_L10N.CTC])}
                            <div className={cx('col12', 'pad0', 'hiddenSm')}>
                                <p className={cx('cartTxt')}>{localization[PROMO_L10N.SUB_TITLE]}</p>
                            </div>
                            <div className={cx('promoWrapper')}>
                                <div automation-id='at-shopping-cart-section' className={cx('listItems')}>
                                    {promotions && promotions.length > 0 && activeTabPanelIndex !== null && (
                                        <Tabs
                                            activeTabPanelIndex={activeTabPanelIndex}
                                            themeTabContainerClass={cx('themeTabContainerClass')}
                                            automationId='cart-tabs'
                                            customTabPanelContentClass={cx('contentWrapper')}
                                            onTabChange={e => this.handlePromotionChange(e)}
                                        >
                                            {promotions.map((row, key) => {
                                                const showIcon = this.isToShowPromoTabIcon(row);
                                                const ref = `promo-tab-${key}`;
                                                const { mechanicType } = row;
                                                if (!this[ref]) {
                                                    this[ref] = React.createRef();
                                                }
                                                return (
                                                    <TabPanel
                                                        key={`at-promotions-tab-${key + 1}`}
                                                        automationId={`at-promotions-tab-${key + 1}`}
                                                        showIcon={showIcon}
                                                        iconName='info-red'
                                                        title={`${row.promotionName}`}
                                                        index={key}
                                                        customTabButtonClass={cx('customTabButtonClass')}
                                                        ref={this[ref]}
                                                    >
                                                        <div automation-id='at-promotions-steps-section'>
                                                            <div className={cx("detailsHeader")}>
                                                                {!_isEmpty(row.note) && (
                                                                    <div className={cx('moreDetails')}>
                                                                        <div className={cx('notes')}>
                                                                            <h5>
                                                                                {commonLocalization[GENERIC_L10N.DETAILS]}:{' '}
                                                                                <span className={cx('lessData')}>
                                                                                    {row.note.substring(
                                                                                        0,
                                                                                        PROMO_NOTE_LENGTH
                                                                                    )}
                                                                                </span>
                                                                                {row.note.length > PROMO_NOTE_LENGTH && (
                                                                                    <Button
                                                                                        type='linkbutton'
                                                                                        className={cx('threedots')}
                                                                                        key='promotionData'
                                                                                    >
                                                                                        ...
                                                                                        {
                                                                                            commonLocalization[
                                                                                            GENERIC_L10N.MORE
                                                                                            ]
                                                                                        }
                                                                                    </Button>
                                                                                )}
                                                                            </h5>
                                                                        </div>
                                                                        {row.note.length > PROMO_NOTE_LENGTH && (
                                                                            <div className={cx('promotionLongDescription')}>
                                                                                <h5 className={cx('details')}>
                                                                                    {
                                                                                        commonLocalization[
                                                                                        GENERIC_L10N.DETAILS
                                                                                        ]
                                                                                    }
                                                                                :{' '}
                                                                                    <span className={cx('moreData')}>
                                                                                        {row.note}
                                                                                    </span>
                                                                                </h5>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                                {
                                                                    mechanicType === "B"
                                                                        ? promotions.length > 1 && this.isLastPromotionActive(promotions.length, activeTabPanelIndex) === false && this.renderNextPromoButton(localization, activeTabPanelIndex)
                                                                        : this.renderAddToCartPromoButton(commonLocalization)
                                                                }
                                                            </div>
                                                            {mechanicType === 'B' &&
                                                                <>
                                                                    <PromotionNotification
                                                                        achievedDealText={this.state.achievedDealText}
                                                                        isNewDiscountAchieved={this.state.isNewDiscountAchieved}
                                                                        isDealAchieved={row.isDealAchieved}
                                                                        row={row}
                                                                        isLastPromotionActive={this.isLastPromotionActive(promotions.length, activeTabPanelIndex)}
                                                                        promotionsLength={promotions.length}
                                                                    />
                                                                </>
                                                            }
                                                            <p className={cx('promDescr')}>{row.description}</p>
                                                            <div className={cx('timelineContent')}>
                                                                {mechanicType === 'B'
                                                                    ? <PromotionTypeB
                                                                        row={row}
                                                                        promotionKey={key}
                                                                        activeTabPanelIndex={activeTabPanelIndex}
                                                                        setPreSelectedDeal={this.setPreSelectedDeal}
                                                                        handleStepOneRadioChange={
                                                                            this.handleStepOneRadioChange
                                                                        }

                                                                        //props for step two
                                                                        mobilePromotionSummary={mobilePromotionSummary}
                                                                        selectGroup={selectGroup}
                                                                        activeDealIndex={activeDealIndex}
                                                                        selectedDeals={selectedDeals}
                                                                        isStepThreeActive={this.isStepThreeActive}
                                                                        mechanicType={mechanicType}
                                                                        getItemsQuantityPromoB={this.getItemsQuantityPromoB}
                                                                        getSelectedItemsCount={this.getSelectedItemsCount}
                                                                        getNeedToSelectItemsCount={
                                                                            this.getNeedToSelectItemsCount
                                                                        }
                                                                        onDetailsHandler={this.onDetailsHandler}
                                                                        changeConditionalQuantity={
                                                                            this.changeConditionalQuantity
                                                                        }
                                                                        isStep3Active={this.areGroupConditionsMet()}
                                                                        refThis={this}
                                                                        handleNewPromotionDiscount={this.handleNewPromotionDiscount}
                                                                        isLastPromotionActive={this.isLastPromotionActive(promotions.length, activeTabPanelIndex)}

                                                                    //props for step three
                                                                    /> : (
                                                                        <>
                                                                            <StepOnePromotion
                                                                                deals={row.deals}
                                                                                showInitial={row.showInitial}
                                                                                promotionKey={key}
                                                                                localization={localization}
                                                                                activeDealIndex={activeDealIndex}
                                                                                isPreSelectedDeal={isPreSelectedDeal}
                                                                                activeTabPanelIndex={activeTabPanelIndex}
                                                                                setPreSelectedDeal={this.setPreSelectedDeal}
                                                                                handleStepOneRadioChange={
                                                                                    this.handleStepOneRadioChange
                                                                                }
                                                                            />
                                                                            <StepTwoPromotion
                                                                                localization={localization}
                                                                                commonLocalization={commonLocalization}
                                                                                promoIndex={key}
                                                                                mobilePromotionSummary={mobilePromotionSummary}
                                                                                selectGroup={selectGroup}
                                                                                activeDealIndex={activeDealIndex}
                                                                                selectedDeals={selectedDeals}
                                                                                isStepThreeActive={this.isStepThreeActive}
                                                                                mechanicType={mechanicType}
                                                                                getItemsQuantityPromoB={this.getItemsQuantityPromoB}
                                                                                getSelectedItemsCount={this.getSelectedItemsCount}
                                                                                getNeedToSelectItemsCount={
                                                                                    this.getNeedToSelectItemsCount
                                                                                }
                                                                                onDetailsHandler={this.onDetailsHandler}
                                                                                changeConditionalQuantity={
                                                                                    this.changeConditionalQuantity
                                                                                }
                                                                                isStep3Active={
                                                                                    mechanicType === 'B'
                                                                                        ? this.areGroupConditionsMet()
                                                                                        : this.isStepThreeActive()
                                                                                }
                                                                                refThis={this}
                                                                            />
                                                                            <StepThreePromotion
                                                                                mobileFreeSummary={mobileFreeSummary}
                                                                                selectedDeals={selectedDeals}
                                                                                updatedFreeItemIndex={updatedFreeItemIndex}
                                                                                localization={localization}
                                                                                commonLocalization={commonLocalization}
                                                                                mechanicType={mechanicType}
                                                                                isStepThreeActive={this.isStepThreeActive}
                                                                                isCheckOutActive={this.isCheckOutActive}
                                                                                isFreeConditionalSatisfied={
                                                                                    this.isFreeConditionalSatisfied
                                                                                }
                                                                                freeItemNeedToSelect={this.freeItemNeedToSelect}
                                                                                toggleFreeView={this.toggleFreeView}
                                                                                changeFreeQuantity={this.changeFreeQuantity}
                                                                            />
                                                                        </>
                                                                    )}
                                                                {mechanicType === 'B' && (
                                                                    <div
                                                                        automation-id='at-green-tick-step-3'
                                                                        className={cx(
                                                                            'container',
                                                                            'thirdLevel',
                                                                            `${this.areGroupConditionsMet()
                                                                                ? 'active'
                                                                                : ''
                                                                            }`
                                                                        )}
                                                                    />
                                                                )}
                                                                <div
                                                                    className={cx(
                                                                        'btmBtn',
                                                                        `${!this.isStepOneActive()
                                                                            ? 'display-none'
                                                                            : ''
                                                                        }`
                                                                    )}
                                                                >
                                                                    <Button
                                                                        automationId='at-reset-button'
                                                                        type='button'
                                                                        size={cx('Sm')}
                                                                        className={cx('cartsBtns')}
                                                                        onClick={this.handleReset}
                                                                    >
                                                                        {localization[PROMO_L10N.RESET_BTN]}
                                                                    </Button>
                                                                    {
                                                                        mechanicType === "B"
                                                                            ? promotions.length > 1 && this.isLastPromotionActive(promotions.length, activeTabPanelIndex) === false && this.renderNextPromoButton(localization, activeTabPanelIndex)
                                                                            : this.renderAddToCartPromoButton(commonLocalization)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                );
                                            })}
                                        </Tabs>
                                    )}

                                    {this.isStepOneActive() && promotions.length > 1 && promotions[activeTabPanelIndex].mechanicType !== 'B' && (
                                        <div automation-id='at-promotion-tab-link' className={cx('continueBtm')}>
                                            {/* 'display-none' Todo Need to remove */}
                                            <p className={cx('stepContinue')}>
                                                {localization[PROMO_L10N.CT]} &nbsp;&nbsp;
                                                <Button
                                                    type='linkbutton'
                                                    className={cx(
                                                        'promotionTxt',
                                                        'textBrand',
                                                        `${activeTabPanelIndex + 1 < promotions.length
                                                            ? ''
                                                            : 'disabled'
                                                        }`
                                                    )}
                                                    onClick={e => this.continueNextPromo(e)}
                                                >
                                                    {activeTabPanelIndex + 1 < promotions.length
                                                        ? promotions[activeTabPanelIndex + 1].promotionName
                                                        : promotions[activeTabPanelIndex].promotionName}
                                                </Button>
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className={cx('listItems')}>
                                    <PromotionCartSummary
                                        title={localization[PROMO_L10N.SHOPPING_CART]}
                                        totalExTax={localization[PROMO_L10N.TOTAL_EX_TAX]}
                                        checkoutBtnText={localization[PROMO_L10N.CTC]}
                                        skuText={localization[PROMO_L10N.SKU]}
                                        freeText={localization[PROMO_L10N.FREE]}
                                        iconName='promo-cart'
                                        promotionDetails
                                        setActive={mobileCartSummary}
                                        onClickCheckoutBtn={() => this.goToCheckOutPage()}
                                        activeCartName={activeCartName}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        promotionsData: _get(state, 'promotionsData'),
        localization: _get(state, 'pageContent.localization'),
        cartDetails: _get(state, 'cartDetails.cart'),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getPageContent,
            getPromotionsList,
            addToCartPromo,
            preCheckoutUpdate,
            removePromoFromCart,
            resetRemovePromoFromCart,
            resetAddToCartPromo,
            removePreselectedDeal,
            resetReqActiveDealIndex,
            resetPromotionList,
            clearAllNotification,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
