import React from 'react';
import classNames from 'classnames/bind';
import styles from './NotSellingOnlineComponent.css';
import Button from '../../../../components/core/Button/Button';
import { useSelector } from 'react-redux';
import { FSA, FSA_IMAGES } from '../../../../common/Constants';

const cx = classNames.bind(styles);

const NotSellingOnlineCard = ({
    title,
    subtitle,
    info1Main,
    info1,
    info2Main,
    info2,
    info3Main,
    info3,
    footer,
    buttonText,
    imageSrc,
    buttonOnClick
}) => {
    return (
        <div className={cx('notSellingOnlineCardContainer')}>
            <div className={cx('notSellingOnlineCardMain')}>
                <div className={cx('notSellingOnlineCardTitle')}>
                    <p>{title}</p>
                </div>
                <div className={cx('notSellingOnlineCardSubtitle')}>
                    <p>{subtitle}</p>
                </div>
                <div className={cx('notSellingOnlineCardInfo')}>
                    <p>
                        <span>{info1Main} </span> {info1}
                    </p>
                </div>
                <div className={cx('notSellingOnlineCardInfo')}>
                    <p>
                        <span>{info2Main} </span> {info2}
                    </p>
                </div>
                <div className={cx('notSellingOnlineCardInfo')}>
                    <p>
                        <span>{info3Main} </span> {info3}
                    </p>
                </div>
                <div className={cx('notSellingOnlineCardFooter')}>
                    <p>{footer}</p>
                </div>
                <div className={cx('notSellingOnlineCardButton')}>
                    <Button type='button' className={cx('button')} onClick={buttonOnClick}>
                        {buttonText}
                    </Button>
                </div>
            </div>
            <div className={cx('notSellingOnlineImg')}>
                <img src={imageSrc} alt='Not Selling Online' />
            </div>
        </div>
    );
};

const NotSellingOnlineComponent = ({ getStartedRef, onGetStartedClicked, onLearnMoreClicked }) => {
    const localization = useSelector(state => state.pageContent.localization);
    const components = useSelector(state => state.pageContent.components);
    const learnMoreImgSrc = components.find(component => component.uid === FSA_IMAGES.LEARN_MORE)?.url?.replace('"', '');
    const getStartedImgSrc = components.find(component => component.uid === FSA_IMAGES.GET_STARTED)?.url?.replace('"', '');
    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);
    
    return (
        <div className={cx('notSellingOnlineContainer')} ref={getStartedRef}>
            <div className={cx('notSellingOnlineTitle')}>
                <p>{localization[FSA.SECTION_TWO_TEXT_1]}​</p>
            </div>
            <div className={cx('cardsContainer')}>
                <NotSellingOnlineCard
                    title={localization[FSA.SECTION_TWO_TEXT_2]}
                    subtitle={localization[FSA.SECTION_TWO_TEXT_3]}
                    info1Main={localization[FSA.SECTION_TWO_TEXT_4]}
                    info1={localization[FSA.SECTION_TWO_TEXT_5]}
                    info2Main={localization[FSA.SECTION_TWO_TEXT_6]}
                    info2={localization[FSA.SECTION_TWO_TEXT_7]}
                    info3Main={localization[FSA.SECTION_TWO_TEXT_8]}
                    info3={localization[FSA.SECTION_TWO_TEXT_9]}
                    footer={localization[FSA.SECTION_TWO_TEXT_10]}
                    buttonText={localization[FSA.SECTION_TWO_TEXT_11]}
                    imageSrc={`${assetBaseUrl}${getStartedImgSrc}`}
                    buttonOnClick={onGetStartedClicked}
                />
                <NotSellingOnlineCard
                    title={localization[FSA.SECTION_TWO_TEXT_12]}
                    subtitle={localization[FSA.SECTION_TWO_TEXT_13]}
                    info1Main={localization[FSA.SECTION_TWO_TEXT_14]}
                    info1={localization[FSA.SECTION_TWO_TEXT_15]}
                    info2Main={localization[FSA.SECTION_TWO_TEXT_16]}
                    info2={localization[FSA.SECTION_TWO_TEXT_17]}
                    info3Main={localization[FSA.SECTION_TWO_TEXT_18]}
                    info3={localization[FSA.SECTION_TWO_TEXT_19]}
                    footer={localization[FSA.SECTION_TWO_TEXT_20]}
                    buttonText={localization[FSA.SECTION_TWO_TEXT_21]}
                    imageSrc={`${assetBaseUrl}${learnMoreImgSrc}`}
                    buttonOnClick={onLearnMoreClicked}
                />
            </div>
        </div>
    );
};

export default NotSellingOnlineComponent;
