import _get from 'lodash/get';
import {
    GET_QUICK_ORDER_LIST_SUCCESS,
    GET_ORDER_LIST_SUCCESS,
    UPDATE_ORDER_ID_REQUEST,
    UPDATE_CREATION_PERIOD_REQUEST,
    UPDATE_PROCESS_TYPE_REQUEST,
    CLEAR_ORDER_SEARCH_FORM,
    CANCEL_ORDER_REQUEST,
    CANCEL_ORDER_SUCCESS,
    RESET_ORDER_LIST,
    CLEAR_REDUCER
} from '../actionTypes/OrderListActionTypes';
import filterOrderlistData from '../service/OrderListFilter';

/**
 {
    items: [
        {
            orderNumber: number,
            type: string,
            total: string,
            deliveryDate: string,
            description: string,
            status: string
        },
        {
            orderNumber: number,
            type: string,
            total: string,
            deliveryDate: string,
            description: string,
            status: string
        },

    ],
    totalOrders: number,
    totalPage: number,
    currentPage: number,
    itemPerPage: number,
    currentStatusCode: string,
    currentPeriod: string,
    status: [
        {
            type: string,
        },
        {
            type: string,
            code: string
        },
        {
            type: string,
            code: string
        }
    ],
    period: [
        {
            label: string,
            value: string
        },
        {
            label: string,
            value: string
        },
        {
            label: string,
            value: string
        },
        {
            label: string,
            value: string
        }
    ]
}
 */

export default function OrderListReducer(state = {}, action = {}) {
    switch (action.type) {
        case GET_QUICK_ORDER_LIST_SUCCESS: {
            return {
                ...state,
                ...action.data
            };
        }
        case GET_ORDER_LIST_SUCCESS: {
            const transformedData = filterOrderlistData(action.orderList);
            return {
                ...state,
                ...transformedData.list,
                defualtProcessType: transformedData.list.currentProcessType,
                defualtPeriod: transformedData.list.currentPeriod,
                defaultStatus: transformedData.list.currentStatusType,
                orderId: _get(action, 'payload.orderId')
            };
        }
        case UPDATE_ORDER_ID_REQUEST:
            return {
                ...state,
                orderId: action.payload
            };
        case UPDATE_CREATION_PERIOD_REQUEST: {
            const newState = { ...state };
            newState.currentPeriod = action.payload;
            return newState;
        }
        case UPDATE_PROCESS_TYPE_REQUEST: {
            const newState = { ...state };
            newState.currentProcessType = action.payload;
            return newState;
        }
        case CLEAR_ORDER_SEARCH_FORM: {
            const newState = { ...state };
            newState.currentProcessType = state.defualtStatusCode;
            newState.currentPeriod = state.defualtPeriod;
            newState.currentStatusType = state.defaultStatus;
            newState.orderId = '';
            return newState;
        }
        case RESET_ORDER_LIST: {
            const { quickOrders } = state;
            return {
                quickOrders
            };
        }
        case CANCEL_ORDER_REQUEST:
            return {
                ...state,
                orderId: action.payload
            };
        case CANCEL_ORDER_SUCCESS:
            return {
                ...state,
                content: action.messageContent
            };
        case CLEAR_REDUCER:
            return {}
        default:
            return state;
    }
}
