import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Icon from '../Icon/Icon';
import * as styles from './Accordion.css';

const cx = classNames.bind(styles);
class Accordion extends Component {
    constructor() {
        super();
        this.state = {
            setActive: '',
            content: React.createRef(),
        };
    }

    componentDidMount() {
        const setActive = this.props.setActive === true ? 'accordionActive' : '';
        this.toggleAccordion(setActive);
    }

    toggleAccordion = setActive => {
        if (setActive !== '') {
            setActive = this.state.setActive === '' ? 'accordionActive' : '';
        }
        this.setState({ setActive});
        if(this.props.onChange){
            this.props.onChange(setActive)
        }

        if (this.props.onChange && setActive === '') this.props.onChange(setActive === 'accordionActive');
    };

    render() {
        const {
            customTitle,
            iconTheme,
            customIconName,
            titleIcon,
            themeIcons,
            iconClass,
            themesetActive,
            showSubTitle,
            customSubTitle,
            themeAccordionContent,
            customTitleIcon,
            customIconDimension
        } = this.props;
        let dimension = customIconDimension ? customIconDimension : 16

        const accorTitleIcon = customTitleIcon || (
            <Icon className={iconClass} iconType='svg' width='16px' height='16px' viewBox={`0 0 ${dimension} ${dimension}`} name={customIconName} />
        );
        return (
            <div className={cx('accordionContainer', `${this.props.className ? this.props.className : ''}`)}>
                <button
                    type='button'
                    className={cx(
                        'accordionHeader',
                        `${this.state.setActive}`,
                        customTitle,
                        this.state.setActive && themesetActive
                    )}
                    onClick={this.toggleAccordion}
                >
                    {titleIcon && <span className={cx(themeIcons)}>{accorTitleIcon}</span>}
                    <p className={cx('accordionTitle')}>
                        {this.props.title}
                        {showSubTitle && <span className={cx('subTitle', customSubTitle)}>{this.props.subtitle}</span>}
                    </p>
                    {this.state.setActive ? (
                        <span className={cx('iconTheme', iconTheme)}>
                            <Icon iconType='svg' width='16px' height='16px' viewBox='0 0 16 16' name='acc-up' />
                        </span>
                    ) : (
                        <span className={cx('iconTheme', iconTheme)}>
                            {' '}
                            <Icon iconType='svg' width='16px' height='16px' viewBox='0 0 16 16' name='acc-down' />
                        </span>
                    )}
                </button>
                <div
                    ref={this.state.content}
                    className={`${cx('accordionContent', themeAccordionContent)} ${
                        this.state.setActive && cx('scrollConent')
                    }`}
                >
                    <div className='accordionText'>{this.props.children}</div>
                 
                </div>
            </div>
        );
    }
}

Accordion.propTypes = {
    title: PropTypes.string,
    setActive: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    customTitle: PropTypes.string,
    iconTheme: PropTypes.string,
    customIconName: PropTypes.string,
    titleIcon: PropTypes.bool,
    themeIcons: PropTypes.string,
    themesetActive: PropTypes.string,
    subtitle: PropTypes.string,
    showSubTitle: PropTypes.bool,
    customSubTitle: PropTypes.string,
    themeAccordionContent: PropTypes.string,
    customTitleIcon: PropTypes.Icon
};

Accordion.defaultProps = {
    title: '',
    setActive: false,
    className: null,
    onChange: null,
    customTitle: '',
    iconTheme: '',
    customIconName: '',
    titleIcon: false,
    themeIcons: '',
    themesetActive: '',
    subtitle: '',
    showSubTitle: false,
    customSubTitle: '',
    themeAccordionContent: '',
    customTitleIcon: null
};

export default Accordion;
