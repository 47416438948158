import React from 'react';
import classNames from 'classnames/bind';
import * as styles from './MyCooler.css';
import Icon from '../../core/Icon/Icon';

const cx = classNames.bind(styles);

const MyCooler = ({ cooler, customSortingFn }) => {

    const { name,
        avgMonthly,
        heightText,
        widthText,
        lengthText,
        serialNumber,
        date1,
        date2,
        doorsText,
        enrgyConsumption,
        macAddress
    } = cooler;

    const dimensions = `${heightText}/${widthText}/${lengthText}`;
    const coolerData = { serialNumber, doors: doorsText, dimensions, enrgyConsumption, date1, date2, macAddress };

    const renderAverages = ([key, average]) => {
        const { color, description, rating, title, value, targetValue } = average;
        const isLastDownload = key === 'lastDownload';
        const svgIconName = {
            doorOpenings: 'door-openings',
            lastDownload: 'last-download',
            temperature: 'temperature'
        };

        return (
            <div key={key + 'averages'} className={cx('AverageItem')}>
                <Icon 
                    className={cx('ItemInfoIcon')}
                    iconType='svg'
                    width='36px'
                    height='36px'
                    viewBox='0 0 40 40'
                    name={svgIconName[key]}
                />
                {isLastDownload && <div className={cx('AverageName')}>{title || description || 'Last Download'}</div>}
                {!isLastDownload && <div className={cx('AverageName')}>{targetValue ?`${value}/${targetValue}` :(title || description)}</div>}
                <div
                    className={cx('AverageValue')}
                    style={{ backgroundColor: color }}
                >
                    {rating || value}
                </div>
            </div>
        );
    };

    const renderAttribute = ([key, value]) => {
        return (
            <div key={key + 'attribute'} className={cx('AttributeWrapper')}>
                <Icon 
                    className={cx('ItemInfoIcon')}
                    iconType='svg'
                    width='20px'
                    height='20px'
                    viewBox='0 0 20 20'
                    name={key.replace(' ', '-').toLocaleLowerCase()}
                />
                <div className={cx('AttributeValue')}>{value}</div>
            </div>
        );
    };

    return (
        <div className={cx('MyCooler')} key={cooler.serialNumber}>
            <div className={cx('AttributesContainer')}>
                <div className={cx('NameAndImageContainer')}>
                    {/* <div className={cx('CoolerImage')}>
                        <img
                            alt='cooler'
                            width="64"
                            height="auto"
                            src="https://th.bing.com/th/id/R.151acc1e43f7412b4393da8a9012d7de?rik=QNxyyV1%2bpPETdA&pid=ImgRaw&r=0"
                        />
                    </div> */}
                    <div className={cx('CoolerName')}>{name}</div>
                </div>
                <div className={cx('AttributesList')}>
                    {Object.entries(coolerData).map(renderAttribute)}
                </div>
            </div>
            <div className={cx('AverageItemsContainer')}>
                {customSortingFn(avgMonthly).map(renderAverages)}
            </div>
        </div>
    );
}

export default MyCooler;
