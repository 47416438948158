import classNames from 'classnames/bind';
import React from 'react';
import Link from 'react-router/lib/Link';
import { PROMO_L10N, GENERIC_L10N } from '../../../../common/Constants';
import PromotionCard from '../../../../components/site/PromotionCard/PromotionCard';
import Icon from '../../../../components/core/Icon/Icon';
import * as styles from '../Promotions.css';

const cx = classNames.bind(styles);

const StepTwoPromotion = ({
    commonLocalization,
    mobilePromotionSummary,
    promoIndex,
    activeDealIndex,
    localization,
    mechanicType,
    isStep3Active,
    selectGroup,
    selectedDeals,
    ...props
}) => {
    const groupId = selectGroup.replace(/\s/g, '');
    const conditionalGroups =
        activeDealIndex != null && selectedDeals.conditionalGroups ? selectedDeals.conditionalGroups : [];

    const getNeedToSelectPromoB = group => {
        let needToSelect = group.itemAmount - props.getItemsQuantityPromoB(group);
        needToSelect = needToSelect <= 0 ? 0 : needToSelect;
        return needToSelect;
    };

    const isGroupValid = group => {
        const selectedCount =
            mechanicType === 'B' ? props.getItemsQuantityPromoB(group) : props.getSelectedItemsCount(group.items);
        return selectedCount >= group.itemAmount ? 'validCounts' : 'invalidCounts';
    };

    return (
        <div
            automation-id='at-green-tick-step-2'
            className={cx('container', 'levelTwo', `${isStep3Active ? 'active' : ''}`)}
        >
            {' '}
            <div
                automation-id='at-step-2-option'
                className={cx('content', mechanicType === 'B' ? cx('noBottomBorder') : '')}
            >
                <h2 automation-id='at-promotional-products-title-text' className={cx('stepsTitle')}>
                    <strong>{localization[PROMO_L10N.STEP2]}. </strong> {localization[PROMO_L10N.STEP2_TEXT]}
                </h2>
                {conditionalGroups.map((group, key) => {
                    const ref = `Promotions-${promoIndex}-${key}`;
                    if (!props.refThis[ref]) {
                        props.refThis[ref] = React.createRef();
                    }

                    const selectedCount = props.getSelectedItemsCount(group.items);
                    const needToSelect = props.getNeedToSelectItemsCount(group, selectedCount);
                    return (
                        <div
                            key={ref}
                            ref={props.refThis[ref]}
                            className={cx('innerContent', `${activeDealIndex == null ? 'display-none' : ''}`)}
                        >
                            <p className={cx('groupAfter', `${isStep3Active ? 'groupAfterActive' : ''}`)}>
                                <Link
                                    className={cx('showlinks')}
                                    onClick={(e) => props.onDetailsHandler(e, ref, promoIndex, conditionalGroups)}
                                    role='button'
                                    id={`group${key}`}
                                >
                                    <span className={cx('col8', 'sm8', 'pad0', 'grayTxt')}>
                                        <span className={cx('groupTxt')}>
                                            {localization[PROMO_L10N.GROUP]} {key + 1}.
                                        </span>
                                        {localization[PROMO_L10N.SELECT]}{' '}
                                        {mechanicType === 'B' ? getNeedToSelectPromoB(group) : needToSelect}{' '}
                                        {localization[PROMO_L10N.PROD_BELOW]}
                                    </span>
                                    <span
                                        automation-id={`at-promo-product-counter-as-${key}`}
                                        className={cx('counts', 'col4', 'sm4', 'pad0', isGroupValid(group))}
                                    >
                                        {mechanicType === 'B' ? props.getItemsQuantityPromoB(group) : selectedCount}/
                                        {group.itemAmount}
                                        <span className={cx(groupId === `group${key}`)}>
                                            <Icon
                                                className={cx('accordionArrow', 'visibleSm')}
                                                iconType='svg'
                                                width='24px'
                                                height='24px'
                                                viewBox='0 0 16 16'
                                                name='acc-up'
                                            />
                                        </span>
                                    </span>
                                </Link>
                            </p>
                            <div
                                ref={props.refThis[ref]}
                                automation-id='at-promotional-products-group'
                                className={cx('hiddenSm', 'mobileActiveTable')}
                            >
                                <PromotionCard
                                    showCard={mobilePromotionSummary}
                                    items={group.items}
                                    changeQuantity={(e, itemIndex) =>
                                        props.changeConditionalQuantity(e, itemIndex, key)
                                    }
                                    inCartText={localization[PROMO_L10N.IN_CART]}
                                    skuText={commonLocalization[GENERIC_L10N.SKU]}
                                    mechanicType={mechanicType}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default StepTwoPromotion;
