import React from 'react';
import classNames from 'classnames/bind';

import * as styles from '../../OrderTemplate/OrderTemplate.css';
import { PAGE_ID, TEMP_L10N, GENERIC_L10N } from '../../../../common/Constants';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../../components/core/Button/Button';
import Icon from '../../../../components/core/Icon/Icon';
import Modal from '../../../../components/core/Modal/Modal';
import CreateNewTemplate from '../../../../components/site/CreateNewTemplate/CreateNewTemplate';

const cx = classNames.bind(styles);
export const CreateTemplate = ({
    pagination,

    copyModalData,
    showModal,
    handleCreateNewTemplate,
    OnClose
}) => {
    const localization = useSelector(state => state.pageContent.localization);
    const commonLocalization = useSelector(state => state.pageContent.commonLocalization[PAGE_ID.GENERIC]);

    const deviceType = useSelector(state => state.context.deviceType);

    const currentPage = pagination.currentPage || 0;
    const dispatch = useDispatch();
    return (
        <div className={cx('orderTempleft', 'clearfix')}>
            <div className={cx('orderControlsGroup')}>
                <div className={cx('totalOrder', 'col6', 'sm6')}>
                    <span automation-id='at-number-of-title'>{localization[TEMP_L10N.TOTAL_TEMP]}</span>{' '}
                    <span className={cx('orderNumber')}>({pagination && pagination.totalResults})</span>
                </div>
                <div className={cx('orderTempright', 'col6', 'sm6')}>
                    <Button
                        automation-id='at-create-new-button'
                        className={cx('create-new')}
                        type='button'
                        buttonType='Primary'
                        onClick={handleCreateNewTemplate}
                    >
                        {commonLocalization[GENERIC_L10N.CREATE_NEW_BTN]}
                        <Icon iconType='svg' width='16' height='16' viewBox='0 0 16 16' name='add' />
                    </Button>
                    {showModal && (
                        <Modal
                            automationId='at-create-new-template-popup'
                            showModal={showModal}
                            onClose={OnClose}
                            modalTheme={cx('userThemeModal', 'orderuserThemeModal')}
                        >
                            <CreateNewTemplate
                                copyTemplateData={copyModalData}
                                onCancel={OnClose}
                                pageNumber={`${currentPage}`}
                                pageSize={`${pagination.pageSize}`}
                            />
                        </Modal>
                    )}
                </div>
            </div>
        </div>
    );
};
