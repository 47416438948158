import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { AUTH } from '../common/Api';
export default class HomeApi {
    static getFiniancialInfo(params) {
        const { baseInfo } = params;
        const config = RestConfig.v2ComApiConfig(params);
        config.url = `/${baseInfo.siteId}${AUTH.USERS}/${baseInfo.userId}/financialinfo`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static getDiscountInfo(params) {
        const { baseInfo } = params;
        const config = RestConfig.v2ComApiConfig(params);
        config.url = `/${baseInfo.siteId}${AUTH.USERS}/${baseInfo.userId}/discountInfo`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    // config.params = {
    //     //         b2bUnitId: "2700006858"
    //     //     }



    static getSavedCart(payload) {
        const { baseInfo, params } = payload;
   
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}${AUTH.USERS}/${baseInfo.userId}/getSavedCart`;
        config.params = params;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static getCustomReports(params) {
        const config = RestConfig.v2ComApiConfig(params);
        config.url = `/${config.baseInfo.siteId}${AUTH.USERS}/${config.baseInfo.userId}/reports`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }
}
