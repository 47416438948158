import {
    GET_ORDER_DETAILS_REQUEST,
    GET_ORDER_DETAILS_SUCCESS,
    GET_ORDER_DETAILS_ERROR,
    RESET_ORDER_DETAILS
} from '../actionTypes/OrderDetailsActionTypes';

export const getOrderDetailData = id => ({
    type: GET_ORDER_DETAILS_REQUEST,
    id
});

export const getOrderDetailSuccess = payload => ({
    type: GET_ORDER_DETAILS_SUCCESS,
    payload
});

export const getOrderDetailError = error => ({
    type: GET_ORDER_DETAILS_ERROR,
    error
});

export const resetOrderDetails = payload => ({
    type: RESET_ORDER_DETAILS,
    payload
});
