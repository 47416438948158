export const sh = {
  "sh": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
    "callus": {
      "CallUsTitle": "Pozovite nas",
      "ContactsHeader": "Možete nas dobiti na bilo koji od sledećih brojeva ...",
      "CancelButtonText": "Otkaži",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Already on a call? Let us <a role='link' tabindex='0' class='cx-cobrowse-link'>browse with you</a></span>",
      "CoBrowse": "Početak zajedničkog pretraživanja ",
      "CoBrowseWarning": "Žajednicko pregledanje dozvoljava vašem agentu da vidi i kontrolise vaš desktop, da bi vas uputio.Kada razgovarate sa agentom uživo, zatražite kod za startovanje zajedničkog pregledanja i unesita ga ispod.Još uvek ne razgovarate? Samo se odjavite  sa ovog ekrana da bi se vratili na Kontaktirajte nas stranicu.",
      "SubTitle": "Možete nas dobiti na bilo koji od sledećih brojeva...",
      "AriaWindowLabel": "Pozovite nas prozor",
      "AriaCallUsClose": "Pozovite nas Zatvori",
      "AriaBusinessHours": "Radno vreme",
      "AriaCallUsPhoneApp": "Otvara telefonsku aplikaciju",
      "AriaCobrowseLink": "Otvara sesiju zajedničkog pregledavanja",
      "AriaCancelButtonText": "Pozovite nas Otkazi"
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector": {
      "Title": "Pomoc uzivo",
      "SubTitle": "Kako bi zeleli da kontaktiramo?",
      "WaitTimeTitle": "Vreme cekanja",
      "AvailableTitle": "Dostupan",
      "AriaAvailableTitle": "Dostupan",
      "UnavailableTitle": "Nedostupan",
      "CobrowseButtonText": "Podnaslov zajednickog pregledanja",
      "CallbackTitle": "Primite poziv",
      "CobrowseSubTitle": "Prisustvo agenta je neophodno za ovo.",
      "AriaClose": "Pomoc uzivo Zatvori",
      "AriaWarning": "Upozorenje",
      "AriaAlert": "Uzbuna",
      "minute": "minut",
      "minutes": "minuti",
      "AriaWindowLabel": "Prozor za pomoc uzivo"
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse": {
      "agentJoined": "Predstavnik se pridruzio sesiji.",
      "youLeft": "Napustili ste sesiju. Zajednicko pregledanje je sada ukinuto.",
      "sessionTimedOut": "Sesija je istekla. Zajednicko pregledanje je sada ukinuto.",
      "sessionInactiveTimedOut": "Sesija je istekla. Zajednicko pregledanje je sada ukinuto.",
      "agentLeft": "Predstavnik je napustio sesiju. Zajednicko pregledanje je sada ukinuto.",
      "sessionError": "Doslo je do neocekivane greske. Zajednicko pregledanje je sada ukinuto.",
      "sessionStarted": "Pocela je sesija zajednickog pregledanja. Ceka se da se predstavnik pridruzi sesiji…",
      "navRefresh": "Predstavnik je osvezio stranicu. Ponovono ucitavanje.",
      "navBack": "Predstavnik je pritisnuo  \"nazad\" dugme. Ponovno ucitavanje stranice.",
      "navForward": "Predstavnik je pritisnuo \"napred\" dugme. Ponovno ucitavanje stranice.",
      "navUrl": "Predstavnik je zatrazio navigaciju. Ponovno ucitavanje stranice.",
      "navFailed": "Zahtev predstavnika za navigaciju nije uspeo.",
      "toolbarContent": "Broj Sesije: {sessionId}",
      "contentMasked": "Sadrzaj je skriven od predstavnika.",
      "contentMaskedPartially": "Deo sadrzaja je skriven od predstavnika.",
      "exitBtnTitle": "Izadjite iz sesije zajednickog pregledanja",
      "areYouOnPhone": "Da li ste na telefonskoj vezi sa nasim predstavnikom?",
      "areYouOnPhoneOrChat": "Are you on the phone or chat with our representative?",
      "connectBeforeCobrowse": "Potrebno je da budete povezani sa nasim predstavnikom da biste nastavili sa zajednickim pregledanjem. Molimo vas pozovite nas ili pokrenite uzivo caskanje sa nama i ponovo pokrenite zajednicko pregledanje.",
      "sessionStartedAutoConnect": "Sesija zajednickog pregledanja je pocela. Cekamo predstavnika da se pridruzi sesiji…",
      "browserUnsupported": "Nazalost, vas pretrazivac trenutno nije podrzan.<br><br> Podrzani pretrazivaci su: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 and above</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 and above</a></li></ul>",
      "chatIsAlreadyRunning": "Caskanje je vec pokrenuto na drugoj stranici.",
      "modalYes": "Da",
      "modalNo": "Ne"
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar": {
      "SidebarTitle": "Potrebna vam je pomoc?",
      "ChannelSelectorName": "Pomoc uzivo",
      "ChannelSelectorTitle": "Zatrazite pomoc jednog od nasih agenata odmah",
      "SearchName": "Pretraga",
      "SearchTitle": "Pretraga",
      "CallUsName": "Pozovite nas",
      "CallUsTitle": "Pozovite nas Detalji",
      "CallbackName": "Uzvratiti poziv",
      "CallbackTitle": "Primiti pozivl",
      "ClickToCallName": "Kliknite za poziv",
      "ClickToCallTitle": "Zatrazite broj telefona korisnickog servisa",
      "SendMessageName": "Posaljite poruku",
      "SendMessageTitle": "Posaljite poruku",
      "WebChatName": "Caskanje uzivo",
      "WebChatTitle": "Caskanje uzivo",
      "AriaClose": "Zatvorite meni potrebna pomoc"
    },
    "webchat": {
      "ChatButton": "Caskanje",
      "ChatStarted": "Caskanje je zapoceto",
      "ChatEnded": "Caskanje se zavrsilo",
      "AgentNameDefault": "Agent",
      "AgentConnected": "<%Agent%> Konektovano",
      "AgentDisconnected": "<%Agent%> Diskonektovano",
      "BotNameDefault": "Bot",
      "BotConnected": "<%Bot%> Konektovano",
      "BotDisconnected": "<%Bot%> Diskonektovano",
      "SupervisorNameDefault": "Supervizor",
      "SupervisorConnected": "<%Agent%> Konektovano",
      "SupervisorDisconnected": "<%Agent%> Diskonektovano",
      "AgentTyping": "...",
      "AriaAgentTyping": "Agent kuca",
      "AgentUnavailable": "Izvinjavamo se. Trenutno nema raspoloživih agenata. Molimo vas pokušajte kasnije",
      "ChatTitle": "Ćaskanje uzivo",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Min",
      "ChatFormFirstName": "Ime",
      "ChatFormLastName": "Prezime",
      "ChatFormNickname": "Nadimak",
      "ChatFormName": "Ime",
      "ChatFormCustomerId": "Identifikacioni broj kupca",
      "ChatFormEmail": "Email",
      "ChatFormSubject": "Naslov",
	    "ChatFormSubjectPassword": "Lozinka",
	    "ChatFormSubjectService": "Usluga",
	    "ChatFormSubjectOrder": "Porudžbina",
	    "ChatFormSubjectFinance": "Finansije",
      "ChatFormPlaceholderFirstName": "Zahtevano",
      "ChatFormPlaceholderLastName": "Zahtevano",
      "ChatFormPlaceholderName": "Opciono",
      "ChatFormPlaceholderCustomerId": "Opciono",
      "ChatFormPlaceholderNickname": "Opciono",
      "ChatFormPlaceholderEmail": "Zahtevano",
      "ChatFormPlaceholderSubject": "Optional",
      "ChatFormSubmit": "Pokrenite caskanje",
      "AriaChatFormSubmit": "Pokrenite caskanje",
      "ChatFormCancel": "Otkazite",
      "AriaChatFormCancel": "Otkazite caskanje",
      "ChatFormClose": "Zatvorite",
      "ChatInputPlaceholder": "Unesite svoju poruku ovde",
      "ChatInputSend": "Posaljite",
      "AriaChatInputSend": "Posaljite",
      "ChatEndQuestion": "Da li ste sigurni da zelite da prekinete sesiju?",
      "ChatEndCancel": "Otkazite",
      "ChatEndConfirm": "Izadjite iz caskanja",
      "AriaChatEndCancel": "Ponistiti",
      "AriaChatEndConfirm": "Kraj",
      "ConfirmCloseWindow": "da li ste sigurni da zelite da zatvorite caskanje?",
      "ConfirmCloseCancel": "Otkazite",
      "ConfirmCloseConfirm": "Zatvorite",
      "AriaConfirmCloseCancel": "Otkazite",
      "AriaConfirmCloseConfirm": "Zatvorite",
      "ActionsDownload": "Preuzimanje prepiske",
      "ActionsEmail": "Email prepiska",
      "ActionsPrint": "Odstampajte prepisku",
      "ActionsCobrowseStart": "Pokrenite zajednicko pregledanje",
      "AriaActionsCobrowseStartTitle": "Otvara sesiju zajednickog pregledanja",
      "ActionsSendFile": "Prilozite datoteke",
      "AriaActionsSendFileTitle": "Otvara dijalog za otpremanje datoteka",
      "ActionsEmoji": "Posaljite emotikon",
      "ActionsCobrowseStop": "Napustite zajednicko pregledanje",
      "ActionsVideo": "Pozovite u videopoziv",
      "ActionsTransfer": "Prenos",
      "ActionsInvite": "Pozovite",
      "InstructionsTransfer": "Otvorite ovaj link na drugom uredjaju da biste preneli sesiju ćaskanja</br></br><%link%>",
      "InstructionsInvite": "Podelite ovaj link sa drugom osobom kako biste je dodali u ovu sesiju ćaskanja</br></br><%link%>",
      "InviteTitle": "Potrebna vam je pomoć?",
      "InviteBody": "Javite nam ako možemo da vam pomognemo.",
      "InviteReject": "Ne hvala",
      "InviteAccept": "Pokrenite ćaskanje",
      "AriaInviteAccept": "Prihvatite poziv i pokrenite ćaskanje",
      "AriaInviteReject": "Odbijte poziv",
      "ChatError": "Doslo je do problema prilikom pokretanja sesije. Molimo pokušajte ponovo.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Da",
      "ChatErrorDefaultButton": "Ne",
      "AriaChatErrorPrimaryButton": "Da",
      "AriaChatErrorDefaultButton": "Ne",
      "DownloadButton": "Preuzimanje",
      "AriaDownloadButton": "Preuzmite datoteku",
      "FileSent": "Poslao:",
      "FileTransferRetry": "Pokušajte ponovo",
      "AriaFileTransferRetry": "Pokušajte ponovo prenos datoteke",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Otkažite",
      "AriaFileTransferCancel": "Otkažite prenos datoteke",
      "RestoreTimeoutTitle": "Ćaskanje je završeno",
      "RestoreTimeoutBody": "Vreme prethodne sesije caskanja je isteklo. Da li biste zeleli da zapocnete novo?",
      "RestoreTimeoutReject": "Ne hvala",
      "RestoreTimeoutAccept": "Pokrenie caskanje",
      "AriaRestoreTimeoutAccept": "Prihvatite pozivnicu i pokrenite caskanje",
      "AriaRestoreTimeoutReject": "Odbijte pozivnicu",
      "EndConfirmBody": "Da li biste zaista zeleli da zavrsite sesiju caskanja?",
      "EndConfirmAccept": "Zavrsite caskanje",
      "EndConfirmReject": "Otkazite",
      "AriaEndConfirmAccept": "Zavrsite caskanje",
      "AriaEndConfirmReject": "Otkazite",
      "SurveyOfferQuestion": "Da li biste zeleli da ucestvujete u anketi?",
      "ShowSurveyAccept": "Da",
      "ShowSurveyReject": "Ne",
      "AriaShowSurveyAccept": "Da",
      "AriaShowSurveyReject": "Ne",
      "UnreadMessagesTitle": "Neprocitano",
      "AriaYouSaid": "Vi ste rekli",
      "AriaSaid": "Rekao",
      "AriaSystemSaid": "Sistem je rekao",
      "AriaWindowLabel": "Prozor za caskanje uzivo",
      "AriaMinimize": "Smanjite prozor caskanja uzivo",
      "AriaMaximize": "Povecajte prozor caskanja uzivo",
      "AriaClose": "Zatvorite caskanje uzivo",
      "AriaEmojiStatusOpen": "Otvoren je dijalog prozor za odabir emotikona",
      "AriaEmojiStatusClose": "Zatvoren je dijalog prozor za odabir emotikona",
      "AriaEmoji": "emotikoni",
      "AriaEmojiPicker": "Birac emotikona",
      "AriaCharRemaining": "Preostalo karaktera",
      "AriaMessageInput": "Okvir za poruke",
      "AsyncChatEnd": "Zatvorite caskanje",
      "AsyncChatClose": "Zatvorite prozor",
      "AriaAsyncChatEnd": "Zatvorite caskanje",
      "AriaAsyncChatClose": "Zatvorite prozor",
      "DayLabels": [
        "Ned",
        "Pon",
        "Uto",
        "Sre",
        "Cet",
        "Pet",
        "Sub"
      ],
      "MonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Maj",
        "Jun",
        "Jul",
        "Avg",
        "Sept",
        "Okt",
        "Nov",
        "Dec"
      ],
      "todayLabel": "Danas",
      "Errors": {
        "102": "Ime je obavezno.",
        "103": "Prezime je obavezno.",
        "161": "Molimo vas unesite vase ime.",
        "201": "Datoteka nije uspela da se posalje<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Premasen je maksimalan broj prilozenih datoteka  (<%MaxFilesAllowed%>).</p>",
        "202": "Slanje datoteke nije uspesno.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Ogranicenje za otpremanje i / ili maksimalan broj priloga ce biti premasen (<%MaxAttachmentsSize%>).</p>",
        "203": "Datoteka nije uspela da se posalje<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Tip datoteke nije dozvoljen.</p>",
        "204": "Zao nam je, ali vasa poruka je predugacka. Molimo napisite kracu poruku.",
        "240": "Zao nam je, ali trenutno ne mozemo zapoceti novo caskanje. Molimo pokusajte ponovo kasnije",
        "364": "Neispravna mail adresa.",
        "401": "Zao nam je, ali trenutno nismo u mogucnosti da odobrimo sesiju caskanja. Da li biste zeleli da zapocnete novo caskanje?",
        "404": "Zao nam je, ali ne mozemo pronaci vasu prethodnu sesiju caskanja. Da li biste zeleli da zapocnete novo caskanje?",
        "500": "Zao nam je, doslo je do neocekivane greske sa uslugom. Da li biste zeleli da zatvorite ovo i zapocnete novo caskanje?",
        "503": "Zao nam je, usluga trenutno nije dostupna ili je zauzeta . Da li biste zeleli da zatvorite ovo i zapocnete novo caskanje ponovo?",
        "ChatUnavailable": "Zao nam je, ali trenutno nismo u mogucnosti da pokrenemo novo caskanje. Molimo pokusajte kasnije",
        "CriticalFault": "Vasa sesija caskanja se neocekivano zavrsila usled nepoznatog problema. Izvinjavamo se zbog neprijatnosti",
        "StartFailed": "Doslo je do problema prilikom zapocinjanja sesije caskanja. Molimo vas proverite vasu konekciju i da li ste pravilno poslali sve potrebne informacije, a zatim pokusajte ponovo.",
        "MessageFailed": "Vasa poruka nije uspesno primljena. Molimo pokusajte ponovo..",
        "RestoreFailed": "Zao nam je, ali nismo u mogucnosti da obnovimo vasu sesiju caskanja zbog nepoznate greske.",
        "TransferFailed": "Trenutno nije moguce preneti caskanje. Molimo pokusajte ponovo kasnije",
        "FileTransferSizeError": "Datoteka nije uspela da se posalje.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Velicina datoteke je veca od dozvoljene (<%MaxSizePerFile%>).</p>",
        "InviteFailed": "Trenutno nije moguce kreirati pozivnicu. Molimo pokusajte ponovo kasnije.",
        "ChatServerWentOffline": "Prolazak poruka trenutno traju duze nego uobicajeno. Zao nam je zbog kasnjenja.",
        "RestoredOffline": "Prolazak poruka trenutno traje duze nego uobicajeno. Zao nam je zbog kasnjenja.",
        "Disconnected": "<div style='text-align:center'>Konekcija prekinuta</div>",
        "Reconnected": "<div style='text-align:center'>Konekcija obnovljena</div>",
        "FileSendFailed": "Slanje datoteke nije uspesno.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>Doslo je do neocekivanog prekida veze. Pokusajte ponovo?</p>",
        "Generic": "<div style='text-align:center'>Doslo je do neocekivanje greske</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Neispravan tip datoteke. Dozvoljene su samo slike",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "Velicina datoteke je veca od dozvoljene.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "Maksimalan broj prilozenih datoteka je premasio ogranicenje.",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Neispravna konfugiracija transporta x-api kljuca.",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Neispravna konfiguracija prenosa krajnje tacke",
        "pureengage-v3-rest-INVALID_NICKNAME": "Ime je obavezno.",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "Zao nam je, ali nismo u mogucnosti da odobrimo sesiju caskanja.",
        "purecloud-v2-sockets-400": "Zao nam je, doslo je do greske. Molimo vas proverite vasu konekciju i da li ste pravilno poslali sve potrebne informacije, a zatim pokusajte ponovo.",
        "purecloud-v2-sockets-500": "Zao nam je doslo je do neocekivane greske u usluzi.",
        "purecloud-v2-sockets-503": "Zao nam je, usluga trenutno nije dostupna."
      }
    }
  }
}
