import {
    GET_ORDER_CONFIRMATION_REQUEST,
    UPDATE_SURVEY_LAST_CLICKED_DATE_REQUEST,
    CLEAR_REDUCER
} from '../actionTypes/OrderConfirmActionType';

export const clearOrderConfirmReducer = () => ({
    type: CLEAR_REDUCER
});

export const getOrderConfirmationData = payload => ({
    type: GET_ORDER_CONFIRMATION_REQUEST,
    payload
});

export const updateSurveyClickedDate = payload => ({
    type: UPDATE_SURVEY_LAST_CLICKED_DATE_REQUEST,
    payload
});

export default {
    getOrderConfirmationData,
    updateSurveyClickedDate
};
