import React from 'react';

import _get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';

import classNames from 'classnames/bind';
import * as styles from '../../OrderTemplate/OrderTemplate.css';
import Modal from '../../../../components/core/Modal/Modal';


import { getOrderTemplateDetails, postExcel, resetExcelErrors } from '../../../../actions/OrderTemplateAction';
import isEmpty from 'lodash/isEmpty';
import { ExcelUpload } from './ExcelUpload';
import OrderTemplateModal from '../../../../components/site/OrderTemplateModal/OrderTemplateModal';
import { HowItWorks } from './HowItWorks';
const cx = classNames.bind(styles);
export const AccountShoppingQuickOrder = () => {
    const localization = useSelector(state => state.pageContent.localization);
    const orderTemplate = useSelector(state => state.orderTemplate.templateList);
    const templateUploadError = useSelector(state => state.orderTemplate.templateUploadError);

    const dispatch = useDispatch();

    const submitExcel = files => {
        if (files) {
            const formData = new FormData();
            formData.append('file', files[0], files[0].name);
            dispatch(postExcel({ data: formData }));
        }
    };


    const getPageContentFunc = React.useCallback(() => {
        dispatch(getOrderTemplateDetails())
    }, [dispatch,  getOrderTemplateDetails]);

    React.useEffect(() => {
        getPageContentFunc();
    }, [getPageContentFunc]);

    return (
        <div>
            {!isEmpty(orderTemplate) && !isEmpty(localization) && (
                <div automation-id='at-order-template-list' className={cx('orderTemp', 'clearfix', 'savedCart')}>
                    <ExcelUpload submitExcel={submitExcel} />
                    <HowItWorks />
                    {templateUploadError && (
                        <Modal
                            automationId='at-restore-template-popup'
                            showModal
                            onClose={() => dispatch(resetExcelErrors())}
                            modalTheme={cx('userThemeModal', 'orderuserThemeModal')}
                        >
                            <OrderTemplateModal
                                content={templateUploadError}
                                onClose={() => dispatch(resetExcelErrors())}
                            />
                        </Modal>
                    )}
                </div>
            )}
        </div>
    );
};
