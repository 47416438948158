import _get from 'lodash/get';
import ReactGA4 from './GA4';
import { getLocalization } from '../UserManager/UserManager';
import { LOGIN_INFO } from '../Constants';

/**
 * Initializing Google Analytics based on selected country, if the site needs to be tracked.
 */
let ga4enabled = false;
let gaConfig = {};
export const initializeGA = state => {
    const siteId = getLocalization()[LOGIN_INFO.SITE_ID] || '';
    // Getting Google Analytics Configuration.
    gaConfig = _get(state, 'context.environment.gaConfig', {});

    if (gaConfig[siteId] && gaConfig[siteId].ga4Id) {
        ReactGA4.initialize(gaConfig[siteId].ga4Id, {
            debug: gaConfig.debug,
            gaOptions: {
                siteSpeedSampleRate: 100
            }
        });
        ReactGA4.set({ anonymizeIp: true });
    }

    ga4enabled = !!gaConfig[siteId]?.ga4Id;
};

export const trackPageView = pageLoadTime => {
    if (!ga4enabled) {
        return;
    }

    // ReactGA4.send({ hitType: "pageview", page: window.location.pathname });

    // Sends the timing hit to Google Analytics if it is configured.
    // Not sure if can be handles or should be handled with custom event.
    // if (gaConfig.performance && pageLoadTime) {
    //     const { category = 'Page Load Time', variable = 'Load' } = gaConfig.performance || {};

    //     ReactGA.timing({
    //         category,
    //         variable,
    //         value: pageLoadTime,
    //         label: window.location.pathname
    //     });
    // }
};
