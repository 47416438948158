import React, { useMemo } from 'react';
import ReactWebChat, { createDirectLine, createStoreWithDevTools } from 'botframework-webchat';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import * as styles from './MicrosoftChatBot.css';
import ChatBotImage from '../../../assets/images/MicrosoftChatBot.webp';
import Icon from '../../core/Icon/Icon';
import {
    getLocalization,
    getUserId
} from '../../../common/UserManager/UserManager';
import { LOGIN_INFO } from '../../../common/Constants';

const MicrosoftChatBot = ({
    microsoftChatBotDirectLineURL,
    microsoftChatBotToken,
    setMicrosoftChatBotToken,
    setMicrosoftChatBotDirectLineURL,
    setShowMicrosoftChatBot
}) => {
    const cx = classNames.bind(styles);
    const userId = getUserId();
    const gaConfig = useSelector(state => state.context.environment.gaConfig);
    const siteId = getLocalization()[LOGIN_INFO.SITE_ID] || '';
    const salesOrg = gaConfig?.[siteId]?.marketingArea || '';
    const selectedOutletId = useSelector(state => state.pageContent.selectedOutlet.mainPartnerId);
    const outletList = useSelector(state => state.pageContent.outletList.mainPartners);

    // Create the store with middleware to dispatch a welcome message
    const store = useMemo(
        () =>
            createStoreWithDevTools({}, ({ dispatch }) => next => action => {
                if (microsoftChatBotToken && microsoftChatBotDirectLineURL)
                    if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
                        dispatch({
                            type: 'WEB_CHAT/SEND_MESSAGE_BACK',
                            payload: { displayText: 'Hello', text: 'Hello', value: 'Hello' }
                        });
                    }
                return next(action);
            }),
        [microsoftChatBotToken, microsoftChatBotDirectLineURL]
    );

    const directLine = useMemo(() => {
        if (microsoftChatBotToken && microsoftChatBotDirectLineURL) {
            try {
                const data = createDirectLine({
                    domain: new URL('v3/directline', 'https://europe.directline.botframework.com/'),
                    token: microsoftChatBotToken
                });
                return data;
            } catch (error) {
                console.error(error);
            }
        }
    }, [microsoftChatBotToken, microsoftChatBotDirectLineURL]);

    // React WebChat bot options
    const styleOptions = {
        hideUploadButton: true,
        botAvatarInitials: 'HELLEN',
        botAvatarImage: ChatBotImage,
        bubbleBackground: '#f2f2f2',
        bubbleFromUserBackground: '#FBDADA',
        bubbleBorderRadius: '0px 12px 12px 12px',
        bubbleFromUserBorderRadius: '12px 12px 0px 12px',
        bubbleBorderColor: 'transparent',
        bubbleFromUserBorderColor: 'transparent',
        bubbleTextColor: '#616161',
        bubbleFromUserTextColor: '#616161'
    };

    return (
        directLine &&
        store &&
        userId &&
        selectedOutletId &&
        outletList.length > 0 &&
        salesOrg && (
            <div className={cx('chat-container')}>
                <div className={cx('chat-header')}>
                    <p className={cx('chat-header-text')}>CCHBC ChatBot</p>
                    <div className={cx('chat-header-icons')}>
                        <div
                            tabIndex='0'
                            role='button'
                            onKeyDown={() => {
                                setMicrosoftChatBotToken('');
                                setMicrosoftChatBotDirectLineURL('');
                                setShowMicrosoftChatBot(false);
                            }}
                            onClick={() => {
                                setMicrosoftChatBotToken('');
                                setMicrosoftChatBotDirectLineURL('');
                                setShowMicrosoftChatBot(false);
                            }}
                        >
                            <Icon
                                className={cx('flex-icon')}
                                iconType='svg'
                                width='25px'
                                height='25px'
                                viewBox='3 3 20 20'
                                name='close-global'
                            />
                        </div>
                    </div>
                </div>
                <div className={cx('chat-content')}>
                    <ReactWebChat
                        styleOptions={styleOptions}
                        directLine={directLine}
                        store={store}
                        username={`${userId};${selectedOutletId}-${outletList
                            .filter(outlet => outlet.mainPartnerId !== selectedOutletId)
                            .map(outlet => outlet.mainPartnerId)
                            .join('-')};${salesOrg}`}
                    />
                </div>
            </div>
        )
    );
};

export default MicrosoftChatBot;
