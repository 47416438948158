import {PopupPortal, Title} from "../CreditIssuePopup/CreditIssuePopup";
import {useSelector} from "react-redux";
import Modal from "../../../core/Modal/Modal";
import styles from "../CreditIssuePopup/CreditIssuePopup.pcss";
import {CreditIssueMessage} from "../CreditIssueMessage/CreditIssueMessage";
import React from "react";
import PropTypes from "prop-types";

export const CreditLimitExceededPopup = (props) => {
    const isOpen = useSelector(store => store.creditInformation.showCreditLimitExceededPopup);
    const message = useSelector(store => store.creditInformation.creditLimitExceededMessage);
    const header = useSelector(store => store.creditInformation.creditLimitExceededHeader);

    if (!message || !header || !isOpen) return null;

    return <PopupPortal>
            <Modal showModal={isOpen}
                   defaultCloseBtn={false}
                   title={<Title onClose={props.onClose} text={header}/>}
                   modalTheme={styles.popup}
                   modalTitleTheme={styles.titleContainer}
                   themeContent={styles.modalContent}
                   className={styles.overlay}
                   themeModalBlock={styles.modalBlock}
            >
                <CreditIssueMessage checkInvoicesButton={false} text={message} />
            </Modal>
        </PopupPortal>
}
CreditLimitExceededPopup.propTypes = {
    onClose: PropTypes.func
}