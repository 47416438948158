import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Link from 'react-router/lib/Link';
import * as styles from './MyRewardsSelfRedCard.css';
import Icon from '../../core/Icon/Icon';
import { getCurrency } from '../../../common/UserManager/UserManager';
import { redirectTo } from '../../../common/Utils/Utils';
import { URL_PATHS } from '../../../common/Constants';
import { useDeviceType } from '../../../common/Device/Device';


const cx = classNames.bind(styles);

const MyRewardsSelfRedCard = ({
    title,
    isInnerPage,
    link,
    messageUnderTitle,
    currentDisountText,
    currentDiscountPeriodText,
    nextDiscountText,
    nextDiscountPeriodText,
    captureNowText,
    getYourRewardText,
    setShowOnboardingSelfREDPopUp
}) => {

    const currentDiscountFrom = useSelector(state => state.pageContent.currentDiscountFrom);
    const currentDiscountTo = useSelector(state => state.pageContent.currentDiscountTo);
    const nextDiscountFrom = useSelector(state => state.pageContent.nextDiscountFrom);
    const nextDiscountTo = useSelector(state => state.pageContent.nextDiscountTo);
    const discountForCurrentPeriod = useSelector(state => state.pageContent.selfREDDiscountForCurrentPeriod);
    const discountForNextPeriod = useSelector(state => state.pageContent.selfREDDiscountForNextPeriod);

    const deviceType = useDeviceType();
    const { symbol } = getCurrency();

    return (
        <div className={cx('self-red-container')}>
            <div className={cx('self-red-heading')}>
                <h1 className={cx('self-red-title')}>{title}</h1>
                <Icon iconType='svg' width='40px' height='40px' viewBox='0 0 40 40' name='self-red' />
            </div>
            <div className={cx('self-red-subheading')}>
                <p className={cx('self-red-text')}>{messageUnderTitle && parse(messageUnderTitle)}</p>
            </div>
            <div className={cx(isInnerPage ? 'self-red-row-inner' : 'self-red-row')}>
                <div className={cx('self-red-col-1')}>
                    <div className={cx('self-red-inner-text-row')}>
                        <div className={cx('self-red-col-2')}>
                            <p className={cx('self-red-inner-text')}>{currentDisountText}</p>
                            <p className={cx('self-red-inner-text2')}>
                                {currentDiscountPeriodText &&
                                    parse(
                                        currentDiscountPeriodText
                                            .replace('{currentDiscountFrom}', currentDiscountFrom)
                                            .replace('{currentDiscountTo}', currentDiscountTo)
                                    )}
                            </p>
                        </div>
                        <p className={cx('self-red-inner-text')}>
                            <span style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>{discountForCurrentPeriod}{symbol}</span>
                        </p>
                    </div>
                    <div className={cx('self-red-inner-text-row')}>
                        <div className={cx('self-red-col-2')}>
                            <p className={cx('self-red-inner-text')}>{nextDiscountText}</p>
                            <p className={cx('self-red-inner-text2')}>
                                {nextDiscountPeriodText &&
                                    parse(
                                        nextDiscountPeriodText
                                            .replace('{nextDiscountFrom}', nextDiscountFrom)
                                            .replace('{nextDiscountTo}', nextDiscountTo)
                                    )}
                            </p>
                        </div>
                        <p className={cx('self-red-inner-text')}>
                            <span style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>{discountForNextPeriod}{symbol}</span>
                        </p>
                    </div>
                </div>
                <div
                    className={cx(isInnerPage ? 'self-red-col-3-inner' : 'self-red-col-3')}
                >
                    <div
                        className={cx('self-red-row-1')}
                        style={{
                            width: (deviceType === 'tablet' || deviceType === 'tablet-xl') && !isInnerPage && '50%'
                        }}
                    >
                        <div className={cx('self-red-col-2')} style={{ alignItems: 'center' }}>
                            <Icon
                                className={cx('flex-icon')}
                                iconType='svg'
                                width='40px'
                                height='40px'
                                viewBox='0 0 40 40'
                                name='camera'
                            />
                            {isInnerPage && (
                                <p
                                    role='presentation'
                                    onClick={() => {
                                        setShowOnboardingSelfREDPopUp();
                                    }}
                                    className={cx('self-red-inner-text3')}
                                >
                                    {captureNowText}
                                </p>
                            )}
                        </div>
                        <div className={cx('stepper-line')} />
                        <div className={cx('self-red-col-2')} style={{ alignItems: 'center' }}>
                            <Icon
                                className={cx('flex-icon',
                                    !(discountForCurrentPeriod && discountForCurrentPeriod > 0) && 'inactive-icon')}
                                iconType='svg'
                                width='40px'
                                height='40px'
                                viewBox='0 0 40 40'
                                name='trofy'
                            />
                            {isInnerPage && (
                                <p
                                    role='presentation'
                                    onClick={() => {
                                        redirectTo(URL_PATHS.PLP_ORDER);
                                    }}
                                    className={cx('self-red-inner-text3')}
                                >
                                    {getYourRewardText}
                                </p>
                            )}
                        </div>
                    </div>
                    {isInnerPage && (
                        <div className={cx('mobile-buttons-col')}>
                            <p
                                role='presentation'
                                onClick={() => {
                                    setShowOnboardingSelfREDPopUp();
                                }}
                                className={cx('mobile-button')}
                            >
                                {captureNowText}
                            </p>
                            <p
                                role='presentation'
                                onClick={() => {
                                    redirectTo(URL_PATHS.PLP_ORDER);
                                }}
                                className={cx('mobile-button')}
                                style={{ backgroundColor: '#e90000', color: '#ffffff' }}
                            >
                                {getYourRewardText}
                            </p>
                        </div>
                    )}
                </div>
            </div>
            {link && (
                <div className={cx(isInnerPage ? 'self-red-footer-inner' : 'self-red-footer')}>
                    <Link to={link}>
                        <Icon
                            className={cx('arrow-right')}
                            iconType='svg'
                            width='35px'
                            height='35px'
                            viewBox='0 0 35 35'
                            name='loyalty-arrow-right'
                        />
                    </Link>
                </div>
            )}
        </div>
    );
};

MyRewardsSelfRedCard.propTypes = {
    title: PropTypes.string,
    isInnerPage: PropTypes.bool,
    link: PropTypes.string || null,
    messageUnderTitle: PropTypes.string,
    currentDisountText: PropTypes.string,
    currentDiscountPeriodText: PropTypes.string,
    nextDiscountText: PropTypes.string,
    nextDiscountPeriodText: PropTypes.string,
    captureNowText: PropTypes.string,
    getYourRewardText: PropTypes.string,
    setShowOnboardingSelfREDPopUp: PropTypes.func
};

export default MyRewardsSelfRedCard;