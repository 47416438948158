import { put, call, takeLatest } from 'redux-saga/effects';
import {
    GET_ORDER_DETAILS_REQUEST,
    GET_ORDER_DETAILS_SUCCESS,
    GET_ORDER_DETAILS_ERROR
} from '../actionTypes/OrderDetailsActionTypes';
import FactorySaga from './FactorySaga';
import OrderHistoryApi from '../interfaces/OrderHistory/OrderHistoryApi';

export function* getOrderDetailsSaga(action) {
    try {
        const result = yield call(FactorySaga, OrderHistoryApi.getOrderDetail, action);

        if (result.isSuccess) {
            yield put({ type: GET_ORDER_DETAILS_SUCCESS, orderDetail: result.response.data });
        }
    } catch (err) {
        yield put({ type: GET_ORDER_DETAILS_ERROR });
    }
}

export default function* watchOrderDetailsRequest() {
    yield takeLatest(GET_ORDER_DETAILS_REQUEST, getOrderDetailsSaga);
}
