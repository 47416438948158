import React, { useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _get from 'lodash/get';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import classNames from 'classnames/bind';
import { PLP_L10N, GENERIC_L10N, PAGE_ID } from '../../../common/Constants';
import ProductCard from '../../../components/site/ProductCards/ProductCards';

import * as styles from './ProductList.css';
import Icon from '../../../components/core/Icon/Icon';
import StockNotificationModal from '../../../components/core/StockNotificationModal/StockNotificationModal';
import { subscribeToProduct } from '../../../actions/NotifyMeAction';

const cx = classNames.bind(styles);

const List = ({
    productList,
    blockAddToCartEnabled,
    selectedView,
    currentPage,
    bdaaSuggestion,
    localization,
    isShowFilter,
    isShowPrice,
    isGrid,
    isReturnEmpties,
    appliedMobileView,
    isSearch,

    isMarketingMaterials,
    plpType,
    isFrom,
    nextPage,
    isPagination
}) => {
    const observer = useRef(null);
    const productList1 = productList?.products || [];
    const [subscribedProduct, setSubscribedProduct] = useState(null);
    const [showNotifyPopup, setShowNotifyPopup] = useState(null);
    const commonLocalization = useSelector(state => state.pageContent.commonLocalization[PAGE_ID.GENERIC]);

    const dispatch = useDispatch();
    const elementRef = useCallback(
        node => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (
                        entry.isIntersecting &&
                        entry.intersectionRatio > 0 &&
                        productList.currentPage + 1 < productList.totalPages &&
                        !entry.target.once
                    ) {
                        nextPage(productList.currentPage + 1);
                        entry.target.once = true;
                    }
                });
            });
            if (node) observer.current.observe(node);
        },
        [observer, productList]
    );

    const openStockNotificationModal = productCode => {
        setSubscribedProduct(productCode);
        setShowNotifyPopup(true);
    };

    const subscribeCustomerToTheProduct = email => {
        dispatch(
            subscribeToProduct({
                params: { email: email, code: subscribedProduct, isPLP: true }
            })
        );
        setShowNotifyPopup(false);
    };

    const onCloseModal = () => {
        setShowNotifyPopup(false);
    };

    const renderNotificationModal = () => {
        return (
            <StockNotificationModal
                localization={commonLocalization}
                showNotifyPopup={showNotifyPopup}
                subscribeCustomerToTheProduct={subscribeCustomerToTheProduct}
                onCloseModal={() => onCloseModal()}
            />
        );
    };

    return (
        <>
            <ul className={cx('gridParent')}>
                {productList1 &&
                    productList1.map((item, index) => (
                        <ProductCard
                            {...item}
                            selectedView={selectedView}
                            forwardRef={index === productList1.length - 1 && isPagination ? elementRef : null}
                            currentPage={currentPage + 1}
                            stockFlag={item.stockFlag}
                            stockNotified={item.stockNotified}
                            key={item.sku.id}
                            name={item.name}
                            skuId={item.sku.id}
                            price={item.listPrice}
                            currency={item.currency}
                            unitOptions={item.units}
                            addToCartLabel={item.addToCartLabel}
                            isRecommended={
                                !!bdaaSuggestion &&
                                bdaaSuggestion.findIndex(({ sku: { id } }) => id === item.sku.id) !== -1
                            }
                            isFav={item.isFav}
                            isPromo={item.isPromoProduct}
                            promotion={item.promotion}
                            shipping={item.shippingInfo}
                            imageUrl={item.imageUrl}
                            isPlpPage
                            addToCartMessage={localization[GENERIC_L10N.ADD_TO_CART_MESSAGE]}
                            addToCartNotifyButton={
                                commonLocalization[
                                    GENERIC_L10N
                                        .STOCK_NOTIFICATION
                                        .NOTIFY_ME
                                    ]
                            }
                            addToCartNotifiedButton={
                                commonLocalization[
                                    GENERIC_L10N
                                        .STOCK_NOTIFICATION
                                        .GET_NOTIFIED
                                    ]
                            }
                            url={item.url}
                            isShowFilter={isShowFilter}
                            isShowPrice={isShowPrice}
                            isGrid={isGrid}
                            promoDescription={item.promoDescription}
                            description={item.description}
                            isReturnEmpties={isReturnEmpties}
                            isMarketingMaterials={isMarketingMaterials}
                            selectedMobileView={appliedMobileView}
                            productUrl={item.productUrl}
                            code={item.code}
                            isFrom={isFrom}
                            plpType={plpType}
                            minQty={0}
                            isSearch={isSearch}
                            openStockNotificationModal={e => openStockNotificationModal(item.code)}
                            product={item}
                            blockAddToCartEnabled={blockAddToCartEnabled}
                            recommendedQuantity={item?.quantity || null}
                            recommendedReason={item?.reason || ''}
                        />
                    ))}
            </ul>

            <div className={cx('centerEl')}>
                {(!productList1 || productList1.length === 0) && (
                    <h3 automation-id='at-return-empties-title' className={cx('title', 'noProducts')}>
                        <Icon iconType='svg' width='24' height='24' viewBox='0 0 24 24' name='no-results' />{' '}
                        {localization[PLP_L10N.NO_PRODUCTS]}
                    </h3>
                )}
            </div>

            {showNotifyPopup && renderNotificationModal()}
        </>
    );
};

export default List;
