import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import Button from '../../core/Button/Button';
import Input from '../../core/Input/Input';
import * as OrderTemplateActionCreators from '../../../actions/OrderTemplateAction';
import * as styles from './CreateNewTemplate.css';
import { PAGE_ID, TEMP_L10N, GENERIC_L10N } from '../../../common/Constants';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

export class CreateNewTemplate extends Component {
    static propTypes = {
        // eslint-disable-next-line react/no-unused-prop-types
        orderTemplateDetails: PropTypes.objectOf(PropTypes.any),
        onCancel: PropTypes.func,
        copyTemplateData: PropTypes.objectOf(PropTypes.any),
        fromAddToOrderTemplate: PropTypes.bool,
        pageNumber: PropTypes.string,
        pageSize: PropTypes.string,
        fromTemplateDetails: PropTypes.bool,
        code: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        gaFromOrderTemplate: PropTypes.bool,
        gaFromCreate: PropTypes.bool
    };

    static defaultProps = {
        orderTemplateDetails: {},
        onCancel: () => {},
        copyTemplateData: {},
        fromAddToOrderTemplate: false,
        pageNumber: '',
        pageSize: '',
        fromTemplateDetails: false,
        commonLocalization: {},
        gaFromOrderTemplate: false,
        gaFromCreate: false
    };

    constructor(props) {
        super(props);
        this.state = {
            newTemplateName: props.copyTemplateData ? props.copyTemplateData.name : '',
            newDescription: ''
        };
    }

    handleCreate = () => {
        const {
            orderTemplateActions,
            copyTemplateData,
            onCancel,
            pageNumber,
            pageSize,
            fromTemplateDetails,
            fromAddToOrderTemplate,
            code
        } = this.props;
        const { newTemplateName, newDescription } = this.state;

        const reqData = {
            name: newTemplateName,
            description: newDescription
        };

        if (!isEmpty(copyTemplateData)) {
            reqData.code = `${copyTemplateData.id}`;
        }

        const payload = {
            data: reqData
        };

        if (!isEmpty(pageNumber)) {
            payload.pageNumber = pageNumber;
        }

        if (!isEmpty(pageSize)) {
            payload.pageSize = pageSize;
        }

        if (fromTemplateDetails) {
            payload.fromTemplateDetails = fromTemplateDetails;
            payload.code = code;
            buttonClickEvent(
                buttonClickEventConfigs.templateDetails.actions.copy,
                buttonClickEventConfigs.templateDetails.labels.copyCreate,
                buttonClickEventConfigs.templateDetails.categories.templateDetails
            );
        }

        if (fromAddToOrderTemplate) {
            payload.isAllTemplates = true;
        }

        if (this.props.gaFromOrderTemplate) {
            if (this.props.gaFromCreate) {
                buttonClickEvent(
                    buttonClickEventConfigs.orderTemplates.actions.createNew,
                    buttonClickEventConfigs.orderTemplates.labels.createNewCreate,
                    buttonClickEventConfigs.orderTemplates.categories.orderTemplates
                );
            }
        }

        orderTemplateActions.createOrderTemplate(payload);
        onCancel();
        this.setState({ newTemplateName: '', newDescription: '' });
    };

    onChangeInput = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    renderButtonSection = () => {
        const { fromAddToOrderTemplate, commonLocalization } = this.props;

        return (
            <div className={cx('buttonSection', `${fromAddToOrderTemplate ? 'float-left' : 'float-right'}`)}>
                <Button
                    automationId='at-create-button'
                    className={cx('createButton')}
                    type='button'
                    size='Sm'
                    buttonType='Primary'
                    isDisabled={isEmpty(this.state.newTemplateName)}
                    onClick={this.handleCreate}
                >
                    {commonLocalization[GENERIC_L10N.CREATE_BTN]}
                </Button>
                {!fromAddToOrderTemplate && (
                    <Button
                        automationId='at-close-icon'
                        className={cx('cancelButton')}
                        type='button'
                        size='Sm'
                        onClick={e => {
                            if (this.props.gaFromOrderTemplate) {
                                if (this.props.gaFromCreate) {
                                    buttonClickEvent(
                                        buttonClickEventConfigs.orderTemplates.actions.createNew,
                                        buttonClickEventConfigs.orderTemplates.labels.createNewCancel,
                                        buttonClickEventConfigs.orderTemplates.categories.orderTemplates
                                    );
                                }
                            } else if (this.props.fromTemplateDetails) {
                                buttonClickEvent(
                                    buttonClickEventConfigs.templateDetails.actions.copy,
                                    buttonClickEventConfigs.templateDetails.labels.copyCancel,
                                    buttonClickEventConfigs.templateDetails.categories.templateDetails
                                );
                            }
                            this.props.onCancel(e);
                        }}
                    >
                        {commonLocalization[GENERIC_L10N.CANCEL_BTN]}
                    </Button>
                )}
            </div>
        );
    };

    render() {
        const { fromAddToOrderTemplate, commonLocalization, localization } = this.props;

        return (
            <div className={cx('NewTemplateContainer')}>
                <div className={cx('createNewTemplate', 'createnewModalTitle')}>
                    {!fromAddToOrderTemplate && (
                        <h4 automation-id='at-create-new-template-popup-title' className={cx('text-center')}>
                            {commonLocalization[GENERIC_L10N.TEMP_NEW_TITLE]}
                        </h4>
                    )}
                    <div className={cx('innerContent')}>
                        {/* <p automation-id='at-create-new-template-popup-info' className={cx('addTemplate', `${fromAddToOrderTemplate ? 'text-left' : 'text-center'} ${fromAddToOrderTemplate ? 'mob-cent' : ''}`)}>{localization[TEMP_POPUP_L10N.CREATE_NEW_TEMP_DESC]}</p> */}
                        {!fromAddToOrderTemplate && (
                            <p
                                automation-id='at-create-new-template-popup-info'
                                className={cx('addTemplate', 'text-center')}
                            >
                                {localization[TEMP_L10N.CREATE_NEW_TEMP_DESC]}
                            </p>
                        )}
                        {fromAddToOrderTemplate && (
                            <p
                                automation-id='at-create-new-template-popup-info'
                                className={cx('addTemplate', 'text-left', 'mob-cent')}
                            >
                                {commonLocalization[GENERIC_L10N.TEMP_NEW_DESC]}
                            </p>
                        )}
                        <div className={cx('inputPad')}>
                            <Input
                                automationId='at-new-template-name-textfield'
                                type='text'
                                id='newTemplateName'
                                name='newTemplateName'
                                placeholder={commonLocalization[GENERIC_L10N.TEMP_NEW_FIELD]}
                                value={this.state.newTemplateName}
                                onChange={this.onChangeInput}
                            />

                            <Input
                                automationId='at-tempate-description-textfield'
                                type='text'
                                id='newDescription'
                                name='newDescription'
                                placeholder={commonLocalization[GENERIC_L10N.TEMP_DESC]}
                                value={this.state.newDescription}
                                onChange={this.onChangeInput}
                                maxlength='255'
                            />
                        </div>
                    </div>
                    {this.renderButtonSection()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    orderTemplateDetails: _get(state, 'orderTemplateDetails'),
    localization: _get(state, 'pageContent.localization'),
    commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
});

const mapDispatchToProps = dispatch => {
    return {
        orderTemplateActions: bindActionCreators(OrderTemplateActionCreators, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewTemplate);
