import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import { Link } from 'react-router';
import Image from '../../core/Image/Image';
import Button from '../../core/Button/Button';
import QtyInput from '../../core/QtyInput/QtyInput';
import Icon from '../../core/Icon/Icon';
import { getImageByFormat, formatHungaryTousends } from '../../../common/Utils/Utils';
import { isReturnEmpties } from '../../../common/UserManager/UserManager';
import { deleteSavedCartEntry, updateCart, addToCart } from '../../../actions/CartAction';
import {
    MAX_PRODUCT_QUANTITY,
    MIN_PRODUCT_QUANTITY,
    CART_PRODUCT_LENGTH,
    CART_L10N,
    URL_PATHS,
    GENERIC_L10N,
    DATA_LAYER_LISTS
} from '../../../common/Constants';
import * as styles from './OptimizedCartItemDetails.css';
import { productClickEventGA4, removeFromCartEventGA4, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';
import { productClickOptimizedCartItemDetailDataLayerFilterGA4, removeFromCartOptimizedCartItemDetailDataLayerFilterGA4 } from '../../../service/DataLayerFilter';

const cx = classNames.bind(styles);

export const OptimizedCartItemDetails = props => {
    const {
        customAddCartDetailsWrapper,
        pageLevelClass,
        customAddcartContainer,
        localization,
        commonLocalization,
        customCartLevels,
        showGrossPrice,
        stockAllocation,
    } = props;
    const cartInfo= props.cartInfo || {};
    const isReturnEmpty = isReturnEmpties();

    const handleUpdateMiniCart = (quantity, isAdd, code , substitute = false, analyticsProductInfo, gaAction) => {
        let origin_element = '';

        if (props.isFrom === DATA_LAYER_LISTS.MINI_CART_LIST) {
            origin_element = gaAction === 'add' ? buttonClickEventConfigs.minicart.labels.productCardPlus : gaAction === 'remove' ? buttonClickEventConfigs.minicart.labels.productCardMinus : gaAction === 'input' ? buttonClickEventConfigs.minicart.labels.productCardQuantityInput : '';
        }
     
        const payload = {
            data: {
                code,
                quantity,
                substitute,
                isAdd,
                freeItem: false,
                promotionId: null,
                isSavedCartUpdateFlow: true,
                analyticsProductInfo: { ...analyticsProductInfo, list: DATA_LAYER_LISTS.MINI_CART_LIST, origin_element }
            }
        }
        props.actions.addToCart(payload);
    };

    const deleteCartItem = (code, product) => {
        let productClone = null;
        try {
            productClone = _cloneDeep(product);
        } catch (err) {
            console.error(err);
        }
        
        props.actions.deleteSavedCartEntry({ code });

        try {
            if (productClone) {
                removeFromCartEventGA4([removeFromCartOptimizedCartItemDetailDataLayerFilterGA4(productClone)], buttonClickEventConfigs.minicart.labels.productCardDelete);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleAnalyticsProductClick = (product) => {
        try {
            const productClone = _cloneDeep(product);
            productClickEventGA4(productClickOptimizedCartItemDetailDataLayerFilterGA4(productClone), DATA_LAYER_LISTS.MINI_CART_LIST);
        } catch (err) {
            console.error(err);
        }
    };

    const renderCartItem = (
        {
            code,
            codeShort,
            product = {},
            quantity,
            quantityType,
            quantityTypeName,
            name,
            basePrice = {},
            totalPrice = {},
            stockFlag,
            scheduleLines = [],
            freeItem,
            price
        },
        isEmpties
    ) => {
        const image = getImageByFormat(product.images);
        const productUrl = `${URL_PATHS.PDP}/${code}`;
        const stockColour = stockFlag == "IN_STOCK" ? "green" : "red";
        const stockLength = showGrossPrice ? _get(totalPrice, 'formattedValue', '') : _get(basePrice, 'formattedValue', '');
        const priceformattedValue = price?.formattedValue;

        let stockMessage = '';
       
        if(stockFlag){
             stockMessage = stockFlag === 'IN_STOCK' ? commonLocalization[GENERIC_L10N.IN_STOCK] : commonLocalization[GENERIC_L10N.OUT_OF_STOCK];
        }
        const maxQty = stockAllocation?.products.find(({product: { codeShort: stockAllocationCode }}) => stockAllocationCode === codeShort )?.quantity;

        const analyticsProductClick = { codeShort, quantity, quantityType, name, price };
        const analyticsProductToCart = { id: codeShort, name, list: DATA_LAYER_LISTS.MINI_CART_LIST, uom: quantityType, price: price?.formattedValue || price?.value || 0, dividePrice: true, oldQuantity: quantity };

        return (
           
                <div
                    className={cx(
                        'cartItemDetailsWrapper',
                        customAddCartDetailsWrapper,
                        pageLevelClass && 'pageLevelClass',
                        !_isEmpty(scheduleLines) && 'pageScheduleLine'
                    )}
                    automation-id='addcard-details'
                    key={`prod-${code}`}
                >
                    <div className={cx('bdrBtmShort')}>
                        <div className={cx('productDetails', 'col9', pageLevelClass && 'sm12')}>
                            <div className={cx('topListView')}>
                                <div className={cx('thumbImg')}>
                                    <Image
                                        src={image.url}
                                        linkUrl={productUrl}
                                        alt={image.altText}
                                        automationId='at-cart-item-image'
                                        theme={cx('imgClass')}
                                        isInternal={image.defaultImage}
                                    />
                                </div>
                            </div>
                            <span  className={cx('rightContent')}>
                               <h4 className={cx('primaryTile')}>
                                    <Link to={productUrl} onClick={() => handleAnalyticsProductClick(analyticsProductClick)}>
                                        {freeItem && (
                                            <span className={cx('freeTxt', 'textBrand')}>
                                                {commonLocalization[GENERIC_L10N.FREE]}
                                            </span>
                                        )}{' '}
                                        <span translate="no">{name?.substring(0, CART_PRODUCT_LENGTH)}</span>
                                        {name?.length > CART_PRODUCT_LENGTH && <span>...</span>}
                                    </Link>
                                </h4>
                                <span className={cx('secondaryTitle')}>
                                    {commonLocalization[GENERIC_L10N.SKU]} {codeShort}
                                </span>
                                {stockMessage && !isEmpties && (
                                    <span className={cx('stockContainer')}>
                                        <span
                                            className={cx('inStock', 'inlineLevel', `${stockColour || 'default'}bgATP`)}
                                        >
                                            {stockMessage}
                                        </span>
                                        {!_isEmpty(scheduleLines) && (
                                            <span
                                                className={cx(
                                                    'scheduleLines',
                                                    'inlineLevel',
                                                    stockMessage.length >= 15 && 'lineAlignment'
                                                )}
                                            >
                                                {formatHungaryTousends(scheduleLines[0].formattedValue)}
                                            </span>
                                        )}
                                    </span>
                                )}
                            </span>
                        </div>

                        <div
                            className={cx(
                                'col3',
                                'inStockWrapper',
                                (stockLength.length >= 9 || (stockMessage && stockMessage.length >= 15)) &&
                                'inStockWrapperLevel'
                            )}
                        >
                            {!_isEmpty(priceformattedValue) && (
                                <span className={cx('priceValue', pageLevelClass && 'display-none')}>
                                    {formatHungaryTousends(priceformattedValue)}
                                </span>
                            )}
                            {stockMessage && !isEmpties && (
                                <span
                                    className={cx(
                                        'inStock',
                                        `${stockColour || 'default'}bgATP`,
                                        stockLength.length > 0 && 'instockPrice',
                                        stockLength.length >= 14 && 'inStockLevel',
                                        stockMessage.length >= 15 && 'availStockLevel',
                                        _isEmpty(priceformattedValue) && 'hidePriceLevel'
                                    )}
                                >
                                    {stockMessage}
                                </span>
                            )}
                        </div>

                        <div
                            className={cx(
                                'col4',
                                'md4',
                                'sm3',
                                'pad0',
                                pageLevelClass ? 'pageQtyMain' : 'qtyMain',
                                isEmpties && 'emptiesNone'
                            )}
                        >
                            {!isEmpties && (
                               
                                <QtyInput
                                    id={`qty-${code}`}
                                    theme={cx('inputTheme')}
                                    themeIncreBtn={cx('themeIncreBtn')}
                                    themeDescrBtn={cx('themeDescrBtn')}
                                    minValue={MIN_PRODUCT_QUANTITY}
                                    maxValue={maxQty || MAX_PRODUCT_QUANTITY}
                                    value={quantity}
                                    onChange={(value, gaAction) => {
                                        const diff = value - quantity;
                                        handleUpdateMiniCart(value, diff > 0, code , true, analyticsProductToCart, gaAction);
                                    }}
                                    onIncrement={(gaAction) => {
                                        handleUpdateMiniCart(1, true, code, false, analyticsProductToCart, gaAction);
                                    }}
                                    onDecrement={(gaAction) => {
                                        handleUpdateMiniCart(1, false, code, false, analyticsProductToCart, gaAction);
                                    }}
                                />
                            )}
                            {pageLevelClass && !isEmpties && (
                                <Button
                                    type='linkbutton'
                                    className={cx('deleteLabel', 'textBrand')}
                                    onClick={() => deleteCartItem(code, analyticsProductClick)}
                                >
                                    {' '}
                                    {localization[CART_L10N.DELETE_BTN]}
                                </Button>
                            )}
                        </div>
                        <div className={cx('col4', 'md4', 'sm3', 'bdrRght', isEmpties && 'emptiesNone')}>
                            {!isEmpties && !isReturnEmpty && <span className={cx('csText', 'csLabel')}>{quantityTypeName}</span>}
                        </div>
                        <div className={cx('col4', 'md4', 'sm3', 'pageLevelNone', isEmpties && 'emptiesNone')}>
                            {!isEmpties && (
                                <div className={cx('delItem')}>
                                    <div className={cx('float-left')}>
                                        <Button
                                            type='linkbutton'
                                            className={cx('deleteLabel', 'quickView', 'textBrand')}
                                            onClick={() => deleteCartItem(code, analyticsProductClick)}
                                        >
                                            {' '}
                                            {localization[CART_L10N.DELETE_BTN]}
                                        </Button>
                                    </div>
                                    <Button
                                        type='linkbutton'
                                        className={cx('float-right', pageLevelClass ? 'mobileIcon' : 'delIcon')}
                                        onClick={() => deleteCartItem(code, analyticsProductClick)}
                                    >
                                        <Icon
                                            iconType='svg'
                                            width='20px'
                                            height='20px'
                                            viewBox='0 0 23 23'
                                            name='delete-product'
                                        />
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className={cx('pageGridLevel')}>
                            {!_isEmpty(priceformattedValue) && (
                                <a className={cx('mobileDisplay')}>
                                    <div className={cx('price')}>{formatHungaryTousends(priceformattedValue)}</div>
                                   {/* <div className={cx('priceText')}>{localization[CART_L10N.PRICE_EX_TAX]}</div> */}
                                </a>
                            )}
                        </div>
                        {quantity === maxQty && <div className={cx('maxQtyWarn')}>
                            <Icon
                                iconType='svg'
                                width='20px'
                                height='20px'
                                viewBox='0 0 23 23'
                                name='info-red'
                            /> {commonLocalization[CART_L10N.STOCK_ALLOCATION_LIMIT_MESSAGE]}
                        </div>}
                    </div>
                </div>
      
        );
    };

    return (
        <div className={cx('addCartContainer', customAddcartContainer, pageLevelClass && 'shoppingCartPageContainer')}>
            {(cartInfo || []).map(entry => {
                return (
                         // <div className={cx('cartLevels', customCartLevels)} key={`${'shoppingCart'}-${entry.product.codeShort}-${entry.product.qtyTypeName}`}>
                         <div key={entry.code} className={cx('cartLevels', customCartLevels)}>
                      {renderCartItem(entry)}
                       
                    </div>
                );
            })}
        </div>
    );
};


OptimizedCartItemDetails.propTypes = {
    customAddCartDetailsWrapper: PropTypes.string,
    pageLevelClass: PropTypes.bool,
    customAddcartContainer: PropTypes.string,
    customCartLevels: PropTypes.string,
    commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    showGrossPrice: PropTypes.bool
};

OptimizedCartItemDetails.defaultProps = {
    customAddCartDetailsWrapper: '',
    pageLevelClass: false,
    customAddcartContainer: '',
    customCartLevels: '',
    commonLocalization: {},
    showGrossPrice: false
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            deleteSavedCartEntry,
            updateCart,
            addToCart 
        },
        dispatch
    )
});

export default connect(null, mapDispatchToProps)(OptimizedCartItemDetails);
