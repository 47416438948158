import React from 'react';

export default ({ color, active }) => {
    if (active) {
        return (
            <svg
                id='Group_48560'
                data-name='Group 48560'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
                width='21.875'
                height='22.449'
                viewBox='0 0 21.875 22.449'
            >
                <defs>
                    <clipPath id='clip-path'>
                        <rect
                            id='Rectangle_24503'
                            data-name='Rectangle 24503'
                            width='21.875'
                            height='22.449'
                            fill='none'
                        />
                    </clipPath>
                </defs>
                <g id='Group_48559' data-name='Group 48559' clipPath='url(#clip-path)'>
                    <path
                        id='Path_4729'
                        data-name='Path 4729'
                        d='M21.018,220.457a.86.86,0,0,0,.776-1.225c-1.731-3.743-5.94-6.385-10.856-6.385S1.813,215.489.082,219.232a.86.86,0,0,0,.776,1.225Z'
                        transform='translate(0 -198.008)'
                        fill={color || '#ffffff'}
                    />
                    <path
                        id='Path_4730'
                        data-name='Path 4730'
                        d='M66.25,6.3a6.319,6.319,0,1,1,6.3,6.349A6.31,6.31,0,0,1,66.25,6.3'
                        transform='translate(-61.632 0.001)'
                        fill={color || '#ffffff'}
                    />
                </g>
            </svg>
        );
    }

    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='22.289' height='22.716' viewBox='0 0 22.289 22.716'>
            <g id='Group_48547' data-name='Group 48547' transform='translate(4310.145 -17320.65)'>
                <path
                    id='Path_3724'
                    data-name='Path 3724'
                    d='M57.26,0a6.2,6.2,0,1,1-6.2,6.179A6.2,6.2,0,0,1,57.26,0m4.578,6.2a4.582,4.582,0,1,0-4.582,4.582A4.579,4.579,0,0,0,61.838,6.2'
                    transform='translate(-4356.254 17321)'
                    fill='#616161'
                    stroke='#616161'
                    strokeWidth='0.7'
                />
                <path
                    id='Path_3725'
                    data-name='Path 3725'
                    d='M11.214,161.607a12.16,12.16,0,0,1,10.168,6.059,1.338,1.338,0,0,1,.2.526.757.757,0,0,1-.484.8.816.816,0,0,1-.938-.261c-.076-.1-.133-.207-.2-.311a10.41,10.41,0,0,0-9.668-5.153A10.3,10.3,0,0,0,1.6,168.532c-.3.484-.691.648-1.119.446a.806.806,0,0,1-.341-1.194,11.917,11.917,0,0,1,3.1-3.557,12.684,12.684,0,0,1,7.978-2.62'
                    transform='translate(-4309.792 17173.938)'
                    fill='#616161'
                    stroke='#616161'
                    strokeWidth='0.7'
                />
            </g>
        </svg>
    );
};
