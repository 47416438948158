import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import * as styles from './ContactMe.css';
import Button from '../../core/Button/Button';
import SelectBox from '../../core/SelectBox/SelectBox';
import * as ContactMeActionCreators from '../../../actions/ContactMeAction';
import { PAGE_ID, GENERIC_L10N, URL_PATHS } from '../../../common/Constants';

const cx = classNames.bind(styles);

export class ContactMe extends Component {
    static propTypes = {
        concern: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
        showContactMe: PropTypes.bool,
        onCancelEvent: PropTypes.func,
        commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    };

    static defaultProps = {
        concern: '',
        options: [],
        showContactMe: true,
        onCancelEvent: () => {},
        commonLocalization: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            concernError: false
        };
        this.isForceUpdate = false;
    }

    componentDidMount() {
        if (_isEmpty(this.props.options)) {
            this.props.contactActions.getContactMeFormData();
        }
        const { pathname } = window.location;
        if (pathname.includes(URL_PATHS.CLAIMS_HISTORY)) {
            this.isForceUpdate = true;
        }
        if (!this.props.showContactMe) this.props.contactActions.showConatctMe();
    }

    onChangeHandler = value => {
        this.props.contactActions.updateContactMeInfo({ id: value });
    };

    getContactInfoOptions = () => {
        const { options } = this.props;
        return (
            options &&
            options.map(item => ({
                ...item,
                label: item.shortText,
                value: item.code
            }))
        );
    };

    getSelectedOption = () => {
        const { options } = this.props;
        const selectedOption = options && options.find(item => item.selected);
        return selectedOption || {};
    };

    textAreaHandler = e => {
        const {
            target: { value }
        } = e;
        this.props.contactActions.updateContactMeConcern({ value });
    };

    contactMeHandler = event => {
        event.preventDefault();
        const { concern } = this.props;
        const selectedOption = this.getSelectedOption();
        if (_isEmpty(concern)) {
            this.setState({ concernError: _isEmpty(concern) });
        } else {
            const payload = {
                data: {
                    reasoncode: selectedOption.code,
                    reasontext: selectedOption.shortText,
                    reasongroup: selectedOption.codeGroup,
                    concern
                },
                forceUpdate: this.isForceUpdate
            };

            this.props.contactActions.submitContactMeForm(payload);
            this.props.onCancelEvent();
        }
    };

    renderSelectBox = () => {
        const options = this.getContactInfoOptions();
        const selectedOption = this.getSelectedOption();
        const { code: value } = selectedOption;
        return (
            <div className={cx('contactMeSelectBox')}>
                <SelectBox
                    placeholder='Please choose a subject'
                    automationId='at-subject-dropdown'
                    theme={cx('customBox')}
                    arrowTheme={cx('arrowTheme')}
                    themeSelectBox={cx('contactThemeselectBox')}
                    onChange={this.onChangeHandler}
                    id='subject'
                    defaultValue={value}
                    options={options}
                />
            </div>
        );
    };

    renderTextArea = () => {
        const { concern, commonLocalization } = this.props;
        const { concernError } = this.state;
        const pleaseDescTxt = `${commonLocalization[GENERIC_L10N.PLEASE_TEXT] || ''} ${
            commonLocalization[GENERIC_L10N.DESC_CONCERN] || ''
        }`;

        return (
            <div>
                <h3 className={cx('lableTxt')} automation-id='at-please-describe-your-concern-hint-text'>
                    {pleaseDescTxt}*
                </h3>
                <div className={cx('txtArea')}>
                    <textarea
                        className={cx('form-control', concernError && 'error')}
                        onChange={this.textAreaHandler}
                        value={concern}
                        placeholder={pleaseDescTxt}
                        automation-id='at-input-field-content'
                    />
                    {concernError && (
                        <span
                            automation-id='at-empty-detailed-description-field-error-message'
                            className={cx('errorMessage')}
                        >
                            {commonLocalization[GENERIC_L10N.ERROR_MESSAGE]}
                        </span>
                    )}
                </div>
            </div>
        );
    };

    renderButton = () => {
        const { commonLocalization } = this.props;
        const isFromAccountMyServices = this.props?.from === 'accountMyServices';
        return (
            <div className={cx('btnSec', 'float-right', 'sm12', 'pad0')}>
                <div className={cx('clearfix', 'float-left', 'pd-16', 'sm12', 'pad0', 'cancelBtn')}>
                    {!isFromAccountMyServices && (
                        <Button
                            automationId='at-cancel-button'
                            id='serviceContactButton'
                            className={cx('cancelButton')}
                            type='button'
                            buttonType='Secondary'
                            size='Sm'
                            onClick={this.props.onCancelEvent}
                        >
                            {commonLocalization[GENERIC_L10N.CANCEL_BTN]}
                        </Button>
                    )}
                </div>
                <div className={cx('clearfix', 'float-right', 'mrgRgt', 'sm12', 'pad0', 'submitBtn')}>
                    <Button
                        automationId='at-submit-button'
                        className={cx('submitButton')}
                        id='serviceCancelButton'
                        type='submit'
                        buttonType='Primary'
                        size='Sm'
                        onClick={this.contactMeHandler}
                    >
                        {commonLocalization[GENERIC_L10N.SUBMIT_BTN]}
                    </Button>
                </div>
            </div>
        );
    };

    render() {
        const isFromAccountMyServices = this.props?.from === 'accountMyServices';

        const { showContactMe, commonLocalization } = this.props;
        if (!showContactMe && !isFromAccountMyServices) {
            return null;
        }

        return (
            <div automation-id='at-request-support-popup' className={cx('requestmain')}>
                <form
                    id='serviceContactForm'
                    className='service-contact-form logged_in'
                    onSubmit={this.contactMeHandler}
                >
                    {!isFromAccountMyServices && (
                        <h2 className={cx('titleHead')}>{commonLocalization[GENERIC_L10N.REQUEST_SUPPORT]}</h2>
                    )}

                    <div id='serviceContactDiv' className='collapse'>
                        <h3 className={cx('lableTxt')} automation-id='at-what-is-your-concern-title'>
                            {commonLocalization[GENERIC_L10N.YOUR_CONCERN]}
                            <br />
                            <span>{commonLocalization[GENERIC_L10N.CHOOSE_SUPPORT]}</span>
                        </h3>
                        <div className={cx('selBox')}>{this.renderSelectBox()}</div>
                        {this.renderTextArea()}
                        <div automation-id='at-please-fill-out-this-field-error-message'>
                            <div className={cx('reqField', 'col12', 'md12', 'sm12')}>
                                {commonLocalization[GENERIC_L10N.MAND_INFO]}
                            </div>
                            {this.renderButton()}
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    concern: _get(state, 'contactInfo.concern'),
    showContactMe: _get(state, 'contactInfo.showContactForm'),
    options: _get(state, 'contactInfo.options') || [],
    commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
});

const mapDispatchToProps = dispatch => {
    return {
        contactActions: bindActionCreators(ContactMeActionCreators, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactMe);
