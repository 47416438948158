import {
    GET_LOYALTY_NOTIFICATIONS,
    READ_LOYALTY_NOTIFICATION,
    MUTE_LOYALTY_NOTIFICATIONS,
    UNMUTE_LOYALTY_NOTIFICATIONS,
    CLEAR_LOYALTY_NOTIFICATIONS,
    GET_LOYALTY_DASHBOARD_DATA,
    SHOW_NOTIFICATION_BANNER
} from '../actionTypes/LoyaltyActionTypes';

export const getAllLoyaltyNotifications = payload => ({
    type: GET_LOYALTY_NOTIFICATIONS,
    payload
});

export const readLoyaltyNotification = messageId => ({
    type: READ_LOYALTY_NOTIFICATION,
    payload: { messageId }
});

export const muteLoyaltyNotifications = payload => ({
    type: MUTE_LOYALTY_NOTIFICATIONS,
    payload
});

export const unmuteLoyaltyNotifications = payload => ({
    type: UNMUTE_LOYALTY_NOTIFICATIONS,
    payload
});

export const clearLoyaltyNotifications = () => ({
    type: CLEAR_LOYALTY_NOTIFICATIONS
});

export const getLoyaltyDashboardData = payload => ({
    type: GET_LOYALTY_DASHBOARD_DATA,
    payload
});

export const displayNotificationBanner = payload => ({
    type: SHOW_NOTIFICATION_BANNER,
    payload
});
