import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import Icon from '../../../components/core/Icon/Icon';
import { PRE_LOGIN_UID } from '../../../common/Constants';
import { findComponent } from '../../../common/Utils/Utils';

import * as styles from './Benefits.css';

const cx = classNames.bind(styles);

const Benefits = ({ className, cmsComponents }) => {

    if (!cmsComponents) {
        return null;
    }
    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);
    const bannerComponent = findComponent(cmsComponents, PRE_LOGIN_UID.BENEFITS_COMPONENTS.BANNER_UID);
    const titleComponent = findComponent(cmsComponents, PRE_LOGIN_UID.BENEFITS_COMPONENTS.TITLE_UID);
    const text1Component = findComponent(cmsComponents, PRE_LOGIN_UID.BENEFITS_COMPONENTS.TEXT1_UID);
    const text2Component = findComponent(cmsComponents, PRE_LOGIN_UID.BENEFITS_COMPONENTS.TEXT2_UID);
    const text3Component = findComponent(cmsComponents, PRE_LOGIN_UID.BENEFITS_COMPONENTS.TEXT3_UID);
    const text4Component = findComponent(cmsComponents, PRE_LOGIN_UID.BENEFITS_COMPONENTS.TEXT4_UID);
    const text5Component = findComponent(cmsComponents, PRE_LOGIN_UID.BENEFITS_COMPONENTS.TEXT5_UID);
    const text6Component = findComponent(cmsComponents, PRE_LOGIN_UID.BENEFITS_COMPONENTS.TEXT6_UID);

    return (
        <section className={cx("benefits", className)}>
            <div className={cx("benefit-img-container")}>
                {bannerComponent && <img src={`${assetBaseUrl}/${bannerComponent.url}`} alt={bannerComponent.altText} />}
            </div>
            <div>
                <h2>{titleComponent?.content}</h2>
                <div className={cx("benefits-list")}>
                    {text1Component?.content &&
                        <div className={cx("benefit")}>
                            <Icon
                                className={cx('ItemIcon')}
                                iconType='svg'
                                width='32px'
                                height='32px'
                                viewBox='-12 -12 46 46'
                                name="shopping-cart"
                            />
                            <h4>{text1Component?.content}</h4>
                        </div>
                    }
                    {text2Component?.content &&
                        <div className={cx("benefit")}>
                            <Icon
                                className={cx('ItemIcon')}
                                iconType='svg'
                                width='32px'
                                height='32px'
                                viewBox='-8 -10 46 46'
                                name="history"
                            />
                            <h4>{text2Component?.content}</h4>
                        </div>
                    }
                    {text3Component?.content &&
                        <div className={cx("benefit")}>
                            <Icon
                                className={cx('ItemIcon')}
                                iconType='svg'
                                width='32px'
                                height='32px'
                                viewBox='-12 -12 46 46'
                                name="discounts"
                            />
                            <h4>{text3Component?.content}</h4>
                        </div>
                    }
                    {text4Component?.content &&
                        <div className={cx("benefit")}>
                            <Icon
                                className={cx('ItemIcon')}
                                iconType='svg'
                                width='32px'
                                height='32px'
                                viewBox='-12 -12 46 46'
                                name="invoices"
                            />
                            <h4>{text4Component?.content}</h4>
                        </div>
                    }
                    {text5Component?.content &&
                        <div className={cx("benefit")}>
                            <Icon
                                className={cx('ItemIcon')}
                                iconType='svg'
                                width='32px'
                                height='32px'
                                viewBox='-12 -12 46 46'
                                name="renew"
                            />
                            <h4>{text5Component?.content}</h4>
                        </div>
                    }
                    {text6Component?.content &&
                        <div className={cx("benefit")}>
                            <Icon
                                className={cx('ItemIcon')}
                                iconType='svg'
                                width='32px'
                                height='32px'
                                viewBox='-10 -9 46 46'
                                name="credit-control"
                            />
                            <h4>{text6Component?.content}</h4>
                        </div>
                    }
                </div>
            </div>
        </section>
    )
};

export default Benefits;