import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import { enUS, cs, ro, hu, bg, hr, uk, sk, el, sl, de, ru, pl, mk, fr, sr, lv, lt, et } from 'date-fns/locale';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import {
    getInitialDeliveryDate,
    getLocalization,
    isIndirectUser,
    setDeliveryDatePreference
} from '../../../common/UserManager/UserManager';
import { CART_L10N, GENERIC_L10N, LOGIN_INFO, PAGE_ID } from '../../../common/Constants';
import { albanianLocale } from './CustomLocale';
import * as styles from './Datepicker.css';
import './react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { convertDateFormat, parseDateString } from '../../../common/Utils/Utils';
import { clearSpecificNotification } from '../../../actions/NotificationAction';

const cx = classNames.bind(styles);

const defaultFormat = 'dd/MM/yyyy';

const Datepicker = props => {
    const initialDeliveryDate = getInitialDeliveryDate();
    const commonLocalization = useSelector(state => state.pageContent.commonLocalization[PAGE_ID.GENERIC]);
    const localization = useSelector(state => state.pageContent.localization);
    const nextDeliveryDate = useSelector(state => state.cartDetails.cart.nextDeliveryDate);
    const isIndirectCustomer = isIndirectUser();
    const dispatch = useDispatch();

    const {
        calendarTitle,
        calendarNote,
        calendarMessage,
        theme,
        footerText,
        onChange,
        selected,
        dateFormat,
        showTimeSelect,
        inline,
        minDate,
        selectedDate = new Date(),
        onCloseClick,
        popoverAttachment,
        popoverTargetAttachment,
        customCalWrapper,
        includeDates,
        deliveryPreference,
        setDeliveryPreference
    } = props;
    const selectedFormat = defaultFormat;

    const lang = getLocalization()[LOGIN_INFO.LANG];

    const getLocale = () => {
        switch (lang) {
            case 'cs':
                return cs;
            case 'ro':
                return ro;
            case 'hu':
                return hu;
            case 'bg':
                return bg;
            case 'hr':
                return hr;
            case 'uk':
                return uk;
            case 'sk':
                return sk;
            case 'el':
                return el;
            case 'sl':
                return sl;
            case 'de':
            case 'de_AT':
                return de;
            case 'ru':
            case 'ru_BY':
                return ru;
            case 'pl':
                return pl;
            case 'mk':
                return mk;
            case 'sh':
                return sr;
            case 'sq':
                return albanianLocale;
            case 'fr':
                return fr;
            case 'lv':
                return lv;
            case 'lt':
                return lt;
            case 'et':
                return et;
            default:
                return enUS;
        }
    };

    const handleChange = selectedDate => {
        if (onChange) {
            const convertedDate = convertDateFormat(selectedDate);
            if (nextDeliveryDate !== initialDeliveryDate) {
                if (convertedDate === nextDeliveryDate) {
                    setDeliveryDatePreference('nextDay');
                    setDeliveryPreference('nextDay');
                } else if (convertedDate !== nextDeliveryDate && nextDeliveryDate) {
                    setDeliveryDatePreference('standard');
                    setDeliveryPreference('standard');
                    setTimeout(() => {
                        dispatch(clearSpecificNotification(localization?.[CART_L10N.NEXT_DAY_DELIVERY_SERVICE_TEXT]));
                    }, 2000);
                } else {
                    setDeliveryDatePreference('');
                    setDeliveryPreference('');
                }
            }
            onChange(selectedDate);
        }
    };

    const datePickerContainer = ({ className, children }) => {
        let listItems = '';
        if (footerText) {
            listItems = calendarMessage && (
                <div className={cx('footerText')}>
                    <span>{calendarNote} </span>
                    {calendarMessage}
                </div>
            );
        }
        return (
            <div className={cx('calWrapper', customCalWrapper)}>
                <CalendarContainer className={className}>
                    <div className={cx('topContent')}>
                        <div className={cx('dateText')}>
                            <Icon
                                className={cx('leftCalIcon')}
                                iconType='svg'
                                width='24px'
                                height='24px'
                                viewBox='0 0 24 24'
                                name='cal-inner'
                            />
                            <h1>{calendarTitle}</h1>
                            <Button type='button' className={cx('closeIcon')} lable='button' onClick={onCloseClick} />
                        </div>
                        {!isIndirectCustomer && nextDeliveryDate && nextDeliveryDate !== initialDeliveryDate && (
                            <div className={cx('selectDeliveryContainer')}>
                                <div
                                    role='button'
                                    tabIndex='-1'
                                    className={cx(
                                        'selectDeliveryRow',
                                        deliveryPreference === 'standard' ? 'selectedRow' : ''
                                    )}
                                    onKeyDown={() => {
                                        if (deliveryPreference === 'standard') return;
                                        setDeliveryPreference('standard');
                                        setDeliveryDatePreference('standard');
                                        handleChange(includeDates[0]);
                                    }}
                                    onClick={() => {
                                        if (deliveryPreference === 'standard') return;
                                        setDeliveryPreference('standard');
                                        setDeliveryDatePreference('standard');
                                        handleChange(includeDates[0]);
                                    }}
                                >
                                    <div
                                        className={cx(
                                            'selectDeliveryCircle',
                                            deliveryPreference === 'standard' ? 'selected' : ''
                                        )}
                                    >
                                        {deliveryPreference === 'standard' && (
                                            <div className={cx('selectedDeliveryCircle')} />
                                        )}
                                    </div>
                                    {commonLocalization &&
                                        commonLocalization[GENERIC_L10N.DELIVERY_DATES.STANDARD_DELIVERY]}
                                </div>
                                <div
                                    role='button'
                                    tabIndex='-1'
                                    className={cx(
                                        'selectDeliveryRow',
                                        deliveryPreference === 'nextDay' ? 'selectedRow' : ''
                                    )}
                                    onKeyDown={() => {
                                        if (deliveryPreference === 'nextDay') return;
                                        setDeliveryPreference('nextDay');
                                        setDeliveryDatePreference('nextDay');
                                        handleChange(parseDateString(nextDeliveryDate));
                                    }}
                                    onClick={() => {
                                        if (deliveryPreference === 'nextDay') return;
                                        setDeliveryPreference('nextDay');
                                        setDeliveryDatePreference('nextDay');
                                        handleChange(parseDateString(nextDeliveryDate));
                                    }}
                                >
                                    <div
                                        className={cx(
                                            'selectDeliveryCircle',
                                            deliveryPreference === 'nextDay' ? 'selected' : ''
                                        )}
                                    >
                                        {deliveryPreference === 'nextDay' && (
                                            <div className={cx('selectedDeliveryCircle')} />
                                        )}
                                    </div>
                                    <Icon
                                        className={cx('flex-icon')}
                                        iconType='svg'
                                        width='16px'
                                        height='16px'
                                        viewBox='-3 0 16 16'
                                        name='flash'
                                    />
                                    {commonLocalization &&
                                        commonLocalization[GENERIC_L10N.DELIVERY_DATES.NEXT_DAY_DELIVERY]}
                                </div>
                            </div>
                        )}
                    </div>
                    {children}
                    <div className={cx('bottomText')}>{listItems}</div>
                </CalendarContainer>
            </div>
        );
    };

    return (
        <div className={`${cx('datepickerContainer')} ${theme}`}>
            <DatePicker
                includeDates={includeDates}
                locale={getLocale()}
                customInput={null}
                calendarContainer={datePickerContainer}
                selected={selected || selectedDate}
                dateFormat={dateFormat || selectedFormat}
                onChange={handleChange}
                minDate={minDate}
                showTimeSelect={showTimeSelect}
                placeholderText
                inline={inline}
                calendarClassName={`${isIndirectCustomer ? cx('indirectCustomerCalendar') : ''}`}
                popoverAttachment={popoverAttachment}
                popoverTargetAttachment={popoverTargetAttachment}
                highlightDates={
                    nextDeliveryDate && nextDeliveryDate !== initialDeliveryDate
                        ? [parseDateString(nextDeliveryDate)]
                        : []
                }
            />
        </div>
    );
};

Datepicker.propTypes = {
    calendarTitle: PropTypes.string,
    calendarNote: PropTypes.string,
    calendarMessage: PropTypes.string,
    theme: PropTypes.string,
    footerText: PropTypes.bool,
    onChange: PropTypes.func,
    showTimeSelect: PropTypes.string,
    inline: PropTypes.bool,
    minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onCloseClick: PropTypes.func.isRequired,
    popoverAttachment: PropTypes.string,
    popoverTargetAttachment: PropTypes.string,
    customCalWrapper: PropTypes.string,
    includeDates: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]),
    deliveryPreference: PropTypes.string,
    setDeliveryPreference: PropTypes.func
};

Datepicker.defaultProps = {
    calendarTitle: '',
    calendarNote: '',
    calendarMessage: '',
    theme: '',
    footerText: null,
    onChange: null,
    showTimeSelect: '',
    inline: '',
    minDate: '',
    popoverAttachment: '',
    popoverTargetAttachment: '',
    customCalWrapper: '',
    includeDates: '',
    deliveryPreference: '',
    setDeliveryPreference: null
};

export default Datepicker;
