import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import classNames from 'classnames/bind';
import Image from '../../core/Image/Image';
import Button from '../../core/Button/Button';
import { URL_PATHS, ROUND_LOGO_COUNTRIES, LOGIN_UID  } from '../../../common/Constants';
import * as styles from './Header.css';
import { getSiteId } from '../../../common/UserManager/UserManager';

const cx = classNames.bind(styles);

const LoginHeader = (props) => {
    const { isSticky } = props;
    const { logo = {} } = useSelector(state => state.pageContent.headerData);
    const {footerComponent = {}} = useSelector(state => state.loginPage.footerComponent);

    const { childComponents = [] } = footerComponent;

    const backButton = childComponents.find((comp) => comp.uid === LOGIN_UID.BACK_TO_SITE);

    const isRoundLogo = ROUND_LOGO_COUNTRIES.includes(getSiteId());

    return (
        <div automation-id='at-header-of-the-screen' className={cx('row', 'headerCont', 'mobileHeaderShadow')}>
            <div className={cx(isSticky && 'stickyHeader')} id='stickyHeader'>
                <div className={cx('mainHeaderSection', 'mainHeaderSectionLogin')}>
                    <ul className={cx('headerBlockList', 'headerBlockListLogin')}>
                        <li
                            className={cx('headerList', 'logoSectionBlock')}
                            data-automation-id='logoBlock-menu'
                        >
                            {logo && logo.imageUrl && (
                                <Image
                                    automationId='at-cchbc-logo'
                                    theme={cx('headerImgContimg', isRoundLogo ? 'roundLogo' : 'defaultLogo')}
                                    src={logo.imageUrl}
                                    alt={logo.altText}
                                    linkUrl={URL_PATHS.HOME}
                                />
                            )}
                        </li>
                        {backButton && <li className={cx('headerList','ctaSectionBlock', 'right', 'headerBlockListLogin' )} >
                            <Button 
                                type='button'
                                size='Sm'
                                buttonType="Primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    browserHistory.push(backButton.url)
                                }}>
                                {backButton.name || ''}
                            </Button>
                        </li>}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default LoginHeader;