import {
    ADD_TO_CART,
    DELETE_FROM_CART,
    GET_MINI_CART,
    GET_CART,
    GET_MULTI_CART,
    UPDATE_CART,
    CLEAR_ALL_CART,
    CLEAR_SAVED_CART,
    UPDATE_DELIVERY_DATE,
	UPDATE_SAP_CART,
    UPDATE_MINI_CART_COUNT,
    UPDATE_TEMPORARY_PLP_CART,
    CREATE_TEMP_MINI_CART,
	OPEN_MINI_CART,
    CLEAR_MINI_CART,
    CLEAR_REDUCER,
    UPDATE_MINI_CART_LIST,
    UPDATE_SAVED_MINI_CART_TOTAL_PRICE,
    CHANGE_ORDER_TYPE,
    RESTORE_CART,
    CART_PAGE_LOAD,
    DELETE_SAVED_CART_ENTRY,
    REMOVE_MANY_FROM_CART
} from '../actionTypes/CartActionTypes';

export const restoreCart = (payload) => ({
    type: RESTORE_CART,
    payload
})

export const changeOrderType = payload => ({
    type: CHANGE_ORDER_TYPE,
    payload
})

export const createTempMiniCartItems = data => ({
    type: CREATE_TEMP_MINI_CART,
    data
});

export const updateMiniCartList = data => ({
    type: UPDATE_MINI_CART_LIST,
    data
})

export const updateTempMiniCart = payload => ({
    type: UPDATE_TEMPORARY_PLP_CART,
    payload
})

export const clearMiniCart = _ => ({
    type: CLEAR_MINI_CART
})

export const clearCartReducer = () => ({
    type: CLEAR_REDUCER
})

export const addToCart = payload => ({
    type: ADD_TO_CART,
    payload
});
export const removeManyFromCart = payload => ({
    type: REMOVE_MANY_FROM_CART,
    payload
});

export const updateMiniCartCount = payload => ({
    type: UPDATE_MINI_CART_COUNT,
    payload
})

export const updateSavedMiniCartTotalPrice = payload => ({
    type: UPDATE_SAVED_MINI_CART_TOTAL_PRICE,
    payload
})

export const addToCartSapUpdate = payload => ({
    type: UPDATE_SAP_CART,
    payload
});

export const deleteFromCart = payload => ({
    type: DELETE_FROM_CART,
    payload
});

export const deleteSavedCartEntry = payload => ({
    type: DELETE_SAVED_CART_ENTRY,
    payload
});

export const getMiniCart = payload => ({
    type: GET_MINI_CART,
    payload
});

export const getMultiCart = payload => ({
    type: GET_MULTI_CART,
    payload
});

export const getCart = payload => ({
    type: GET_CART,
    payload
});

export const updateCart = payload => ({
    type: UPDATE_CART,
    payload
});

export const clearAll = payload => ({
    type: CLEAR_ALL_CART,
    payload
});

export const clearSavedAll = payload => ({
    type: CLEAR_SAVED_CART,
    payload
});

export const updateDeliveryDate = payload => ({
    type: UPDATE_DELIVERY_DATE,
    payload
});

export const openMiniCart = payload => ({
    type: OPEN_MINI_CART,
    payload
});

export const cartPageLOoag = payload => ({
    type: CART_PAGE_LOAD,
    payload
});