import { put, takeLatest, call, all, select, fork } from 'redux-saga/effects';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import ContentApi from '../interfaces/Content/ContentApi';
import NotificationApi from '../interfaces/Notification/NotificationApi';
import FactorySaga from './FactorySaga';
import { trackPageView } from '../common/GoogleAnalytics/GoogleAnalytics';
import { redirectTo, isInSmartEdit } from '../common/Utils/Utils';
import Storage, { SessionStorage } from '../common/Storage/Storage';
import {
    isIndirectUser,
    isUseMarketingMaterials,
    isReturnEmpties,
    setMarketingMaterials,
    setReturnEmpties,
    isLoadedPrices,
    getMiniCartWorkingWay,
} from '../common/UserManager/UserManager';
import { PAGE_ID, URL_PATHS, NO_COMMON_SAGA, CART_L10N, LOGIN_INFO, MINI_CART_WORKING_WAYS } from '../common/Constants';
import { getLanguageSaga } from './SelectLanguageSaga';
import { getSelectedOutletSaga, loadOutletPricesSaga } from './OutletSaga';
import { openMiniCartSaga, clearAllCartSaga, getMultiCartSaga, cartPageLoad, restoreCartSaga, clearSavedCartSaga } from './CartSaga';
import { getProductListSaga } from './ProductListSaga';
import { getProductDetailSaga } from './ProductDetailSaga';
import { getOrderTemplateSaga } from './OrderTemplateSaga';
import { getTemplateDetailsSaga } from './OrderTemplateDetailsSaga';
import { getOrderListSaga } from './OrderListSaga';
import { getUserInfo } from './LoginSaga';
import { getOrderDetailsSaga } from './OrderDetailsSaga';
import { getPromotionsCalenderListSaga, getPromotionsListSaga } from './PromotionSaga';
import { getInvoicesSaga } from './InvoicesSaga';
import { getCheckoutSaga } from './CheckoutSaga';
import { userMaintenanceSaga } from './UserMaintenanceSaga';
import { OrderConfirmSaga } from './OrderConfirmSaga';
import { getAccountDataSaga } from './AccountDataSaga';
import { getClaimHistorySaga } from './ClaimHistorySaga';

import { showLoader, removeLoader } from '../actions/LoaderAction';
import {
    GET_PAGE_CONTENT_REQUEST,
    GET_PAGE_CONTENT_SUCCESS,
    UPDATE_PAGE_CONTENT,
    GET_PAGE_CONTENT_ERROR,
    LOGIN_CONTENT_SUCCESS,
    UPDATE_COMMON_LOCALIZATION,
    PLP_BANNER_CONTENT_SUCCESS
} from '../actionTypes/PageContentActionTypes';
import { COUNTRY_CONTENT_SUCCESS } from '../actionTypes/SelectLanguageActionTypes';
import {  RESET_PRODUCT_LIST } from '../actionTypes/ProductListActionTypes';
import { UPDATE_MARKETING_MATERIALS, UPDATE_RETURN_EMPTIES } from '../actionTypes/CartActionTypes';
import { SET_CUTOFF_NOTIFICATION, SET_ERROR_NOTIFICATION } from '../actionTypes/NotificationActionTypes';


const getPageContent = (state, pageId) => _get(state, `pageContent.${pageId}`);
const getCommonContent = (state, pageId) => _get(state, `pageContent.commonLocalization.${pageId}`);
const getProductList = state => _get(state, 'products');
const getMiniCartData = state => _get(state, 'cartDetails.miniCart');
const getTemplateListData = state => _get(state, 'orderTemplate.templateList');
const getFavoriteListData = state => _get(state, 'favorites.products');
const getInvoicesDetails = state => _get(state, 'invoicesDetails');
const getPromotionCalendar = state => _get(state, 'promotionsData.promoCalender');
const getOrderConfirmation = state => _get(state, 'confirmationData.orderConfirmationList');
export const getSelectedOutlet = state => _get(state, 'pageContent.selectedOutlet');
export const getOrderListData = state => _get(state, 'orderListData.orders');

function* getPageSaga(pageId, parentPageId) {
    const storedCommonContent = yield select(getCommonContent, pageId);

    if (_isEmpty(storedCommonContent)) {
        const actionPayload = { payload: { pageId }, isSkipLoader: true };
        const result = yield call(FactorySaga, ContentApi.getPage, actionPayload);

        if (result.isSuccess) {
            const data = _get(result, 'response.data', {});
            yield put({ type: UPDATE_COMMON_LOCALIZATION, pageId, parentPageId, data });
        }
    }
}

function* getCutOffNotifications() {
    try {
        const action = { isSkipLoader: true };
        const result = yield call(FactorySaga, NotificationApi.getCutOffNotifications, action);

        if (result.isSuccess) {
            if (result.response.data !== '') {
                yield put({ type: SET_CUTOFF_NOTIFICATION, data: result.response.data });
            }
        } else {
            yield put({ type: SET_ERROR_NOTIFICATION });
        }
    } catch (err) {
        yield put({ type: SET_ERROR_NOTIFICATION });
    }
}

function* getCommonPageSaga(action) {
    const selectedOutlet = yield select(getSelectedOutlet);
    const miniCart = yield select(getMiniCartData);
    const { pageId } = action.payload;
    const isIndirectCustomer = isIndirectUser();

    // if current user's outlet & cutomer info is not available in the store then get it from API.
    if (_isEmpty(selectedOutlet) || isIndirectCustomer === undefined || isIndirectCustomer === null) {
        yield fork(getSelectedOutletSaga, action);
    }

    if (_isEmpty(Storage.getData(LOGIN_INFO.USER_INFO))) {
        yield fork(getUserInfo, action);
    }

    // if mini cart is not available in the store then get it from API.
    // if (pageId !== PAGE_ID.CART && pageId !== PAGE_ID.PROMOTIONS && _isEmpty(miniCart)) {
    //     yield fork(getMiniCartSaga, action);
    // }

    // Invoking Generic CMS Page.
    yield fork(getPageSaga, PAGE_ID.GENERIC);
}

function* includePageSaga(pageSagas, pageId, action) {
 

    switch (pageId) {
        case PAGE_ID.PLP: {
            const { queryString } = action.payload;
            const isReturnEmptyFlow = !!isReturnEmpties();
            const isReturnEmpty = !!action.payload.isReturnEmpties;
            const isMarketingMaterialsFlow = !!isUseMarketingMaterials();
            const isMarketingMaterials = !!action.payload.isUseMarketingMaterials;
            const productList = yield select(getProductList) || {};

            // If switch between order drinks/return empties then clear the cart and handle the delivery type.
            if (isReturnEmptyFlow !== isReturnEmpty) {
                yield fork(clearAllCartSaga, action);
                yield fork(clearSavedCartSaga, action)
                setReturnEmpties(isReturnEmpty);
                yield put({ type: UPDATE_RETURN_EMPTIES, data: { isReturnEmpties: isReturnEmpty } });
            }

            // If switch between order drinks/return empties/marketing materials then clear the cart and handle the delivery type.
            if (isMarketingMaterialsFlow !== isMarketingMaterials) {
                yield fork(clearAllCartSaga, action);
                yield fork(clearSavedCartSaga, action)
                setMarketingMaterials(isMarketingMaterials);
                yield put({
                    type: UPDATE_MARKETING_MATERIALS,
                    data: { isUseMarketingMaterials: isMarketingMaterials }
                });
            }

            // If requested query string is same as current productlist then no need to make API call.
            if (queryString !== productList.queryResult) {
                // No need to fire Search API call via Reset.
                yield put({ type: RESET_PRODUCT_LIST, data: true });
                pageSagas.push(call(getProductListSaga, action));
            }
            break;
        }
        case PAGE_ID.PDP:
            pageSagas.push(call(getProductDetailSaga, action));
            break;
        case PAGE_ID.TEMPLATE_LIST: {
            const templateList = yield select(getTemplateListData);

            if (_isEmpty(templateList)) {
                pageSagas.push(call(getOrderTemplateSaga, action));
            }
            break;
        }
   
        case PAGE_ID.TEMPLATE_DETAILS:
            pageSagas.push(call(getTemplateDetailsSaga, action));
            break;
        case PAGE_ID.ORDER_LIST: {
            const orderList = yield select(getOrderListData);

            if (_isEmpty(orderList)) {
                pageSagas.push(call(getOrderListSaga, action));
            }
            break;
        }
        case PAGE_ID.ORDER_DETAILS:
            pageSagas.push(call(getOrderDetailsSaga, action));
            break;
        case PAGE_ID.INVOICES: {
            const invoicesDetails = yield select(getInvoicesDetails);

            if (_isEmpty(invoicesDetails)) {
                pageSagas.push(call(getInvoicesSaga, action));
            }
            break;
        }

        case PAGE_ID.MY_SERVICES: {
            pageSagas.push(call(getClaimHistorySaga, action));
            break;
        }

        case PAGE_ID.MINI_CART:
            if(getMiniCartWorkingWay() === MINI_CART_WORKING_WAYS.MINICARTNOCRM && !isUseMarketingMaterials() && !isReturnEmpties()){
                action.payload.isOptmizedMiniCartFlow = true;
                pageSagas.push(call(restoreCartSaga, action));

            } else {
                pageSagas.push(call(openMiniCartSaga, action));
            }
            break;
        case PAGE_ID.CART:
            if (!SessionStorage.getData(CART_L10N.IS_SKIP_GET_CART, true)) {

                if(getMiniCartWorkingWay() === MINI_CART_WORKING_WAYS.CRMLOAD && !SessionStorage.getData(CART_L10N.FROM_STOCK_ALLOCATION_POPUP, true)){
                    pageSagas.push(call(getMultiCartSaga, action));

                } else {
                    pageSagas.push(call(cartPageLoad, action));
                    SessionStorage.removeData(CART_L10N.FROM_STOCK_ALLOCATION_POPUP, true);
                }
            }
            SessionStorage.removeData(CART_L10N.IS_SKIP_GET_CART, true);
            SessionStorage.removeData(CART_L10N.SHOW_STOCK_ALLOCATION_MODAL, true);
            break;
        case PAGE_ID.PROMOTIONS: {
            if (!action.payload.isSkipPageCall) {
                pageSagas.push(call(getPromotionsListSaga, action));
            }
            break;
        }
        case PAGE_ID.CHECKOUT:
            pageSagas.push(call(getCheckoutSaga, action));
            break;
        case PAGE_ID.ORDER_CONFIRMATION: {
            const orderConfirmationData = yield select(getOrderConfirmation);

            if (_isEmpty(orderConfirmationData)) {
                pageSagas.push(call(OrderConfirmSaga, action));
            }
            break;
        }
        case PAGE_ID.USER_MAINTENANCE:
            pageSagas.push(call(userMaintenanceSaga, action));
            break;
        case PAGE_ID.PERSONAL:
            pageSagas.push(call(getAccountDataSaga, action));
            break;
        case PAGE_ID.COMPANY:
            pageSagas.push(call(getAccountDataSaga, action));
            break;
        case PAGE_ID.CUSTOMER_FACT_SHEET:
            pageSagas.push(call(getAccountDataSaga, action));
            break;
        case PAGE_ID.CLAIMS_HISTORY:
            pageSagas.push(call(getClaimHistorySaga, action));
            break;
        case PAGE_ID.PROMOTIONS_CALENDAR: {
            const promotionCalendar = yield select(getPromotionCalendar);

            if (_isEmpty(promotionCalendar)) {
                pageSagas.push(call(getPromotionsCalenderListSaga, action));
            }
            break;
        }
        default:
    }
}

function* handlePageResult(type, pageId, data, payload, requestStart) {
    // To capture the page load in Google Analytics.
    if (requestStart) {
        trackPageView(Math.round(window.performance.now()) - requestStart);
    }

    switch (pageId) {
        case PAGE_ID.SELECTLANGUAGE:
            yield put({ type: COUNTRY_CONTENT_SUCCESS, data, pageId: PAGE_ID.SELECTLANGUAGE });
            break;
        case PAGE_ID.COOKIE_NOTICE:
            yield put({ type, pageId, data });
            yield put({ type: LOGIN_CONTENT_SUCCESS, data, pageId: PAGE_ID.COOKIE_NOTICE });
            break;
        case PAGE_ID.LOGIN:
            yield put({ type: LOGIN_CONTENT_SUCCESS, data, pageId: PAGE_ID.LOGIN });
            redirectTo(`${URL_PATHS.LOGIN}${payload?.params?.search && decodeURIComponent(payload.params.search)}`);
            break;
        case PAGE_ID.LOGIN_FORGOT:
        case PAGE_ID.LOGIN_RESET:
        case PAGE_ID.LOGIN_IMPRINT:
        case PAGE_ID.LOGIN_REGISTER_USER:
            yield put({ type: LOGIN_CONTENT_SUCCESS, data, pageId });
            break;
        case PAGE_ID.PLP:
            yield all([
                put({ type, pageId, data }),
                put({ type: PLP_BANNER_CONTENT_SUCCESS, data, pageId: PAGE_ID.PLP })
            ]);
            break;
            
        case PAGE_ID.MINI_CART:
            yield put({ type: UPDATE_COMMON_LOCALIZATION, pageId, data, parentPageId: PAGE_ID.CART });
            break;
        default:
            yield put({ type, pageId, data });
    }
}

/**
 * To get the page content for the requested page id.
 *
 * @param {object} action
 */
function* getPageContentSaga(action) {
    try {
        let { pageId } = action.payload;
        const loadedPrices = isLoadedPrices();

        // Invoking CutOff Notifications & ignoring loader.
        if (NO_COMMON_SAGA.indexOf(pageId) === -1 && pageId !== PAGE_ID.ORDER_CONFIRMATION) {
            yield fork(getCutOffNotifications);
        }

        //Invoking loadOutletPrices and ignoring loader
        if (NO_COMMON_SAGA.indexOf(pageId) === -1 && loadedPrices !== true) {
            action.isSkipLoader = true;
            yield fork(loadOutletPricesSaga, action);
        }

        yield put(showLoader());
        const pageSagas = [];
        let requestStart = 0;
        // action.isSkipLoader = true;

        // To calculate page load.
        if (window.performance) {
            requestStart = Math.round(window.performance.now());
        }

        // Including page sepecific APIs to make parallel call.
        yield call(includePageSaga, pageSagas, pageId, action);
        // Ignore the common sagas for the login related pages.
        if (NO_COMMON_SAGA.indexOf(pageId) === -1) {
            pageSagas.push(call(getCommonPageSaga, action));
        }

        // For error page 404, include the page & common sagas then make home CMS API call.
        if (pageId === PAGE_ID.ERROR_404) {
            pageId = PAGE_ID.HOME;
            action.payload.pageId = pageId;
        }

        // Get the page content from the the store, if it was already preserved.

        const storedPageExceptions = [PAGE_ID.CART]

        const storedPageContent = yield select(getPageContent, pageId);
        if (_isEmpty(storedPageContent) || isInSmartEdit() || storedPageExceptions.includes(pageId)) {
            let result;
          
            if (pageId === PAGE_ID.SELECTLANGUAGE || pageId === PAGE_ID.PRE_LOGIN) {
                [result] = yield all([call(ContentApi.getPage, action.payload), call(getLanguageSaga)]);
            } else {
                // Include the Page CMS API in the top of the list.
                pageSagas.unshift(call(FactorySaga, ContentApi.getPage, action));
                [result] = yield all(pageSagas);
            }

            if (result.isSuccess) {
                let data;
                if (pageId === PAGE_ID.SELECTLANGUAGE || pageId === PAGE_ID.PRE_LOGIN) {
                    data = result.data || {};
                } else {
                    data = _get(result, 'response.data', {});
                }
                yield call(handlePageResult, GET_PAGE_CONTENT_SUCCESS, pageId, data, action.payload, requestStart);
            }
        } else {
            pageSagas.unshift(
                call(handlePageResult, UPDATE_PAGE_CONTENT, pageId, storedPageContent, action.payload, requestStart)
            );
            yield all(pageSagas);
        }
    } catch (err) {
        yield put({ type: GET_PAGE_CONTENT_ERROR });
    } finally {
        if(!action.isSkipLoader && !action?.payload?.isSkipLoader){
            yield put(removeLoader());
        }
   
    }
}

export default function* watchPageContentSaga() {
    yield takeLatest(GET_PAGE_CONTENT_REQUEST, getPageContentSaga);
}
