import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import * as styles from './Pagination.css';
import SelectBox from '../SelectBox/SelectBox';

const cx = classNames.bind(styles);

function Pagination(props) {
    const {
        currentPage,
        totalPage,
        renderType,
        onChange,
        itemOnChange,
        arrowTheme,
        themeSelectBox,
        paginationSelect,
        customListPage,
        customSelectItems,
        pageSize,
        singlePageItems,
        customPageWrapper,
        customItemsClass,
        itemsOptions,
        pageText,
        itemsText,
        ofText
    } = props;

    const options = [];
    let i = 1;
    // generating options
    while (i <= totalPage) {
        const obj = {
            id: i,
            value: i,
            label: `${pageText} ${i}`
        };
        options.push(obj);
        i += 1;
    }

    const pageSizeOptions =
        itemsOptions &&
        itemsOptions.map(item => {
            return {
                id: item,
                value: item,
                label: `${item} ${itemsText} `
            };
        });

    function handlerChange(val) {
        onChange(val);
    }

    function ItemHandlerChange(val) {
        itemOnChange(val);
    }

    if (renderType === 'List') {
        return (
            <div className={cx('paginationItems')}>
                <div
                    automation-id='at-page-number-dropdown'
                    className={cx('selectBox', singlePageItems && 'pageItemNone')}
                >
                    <SelectBox
                        theme={cx('customBox', 'grdComBox', paginationSelect)}
                        themeSelectBox={cx('pageThemeselectBox', themeSelectBox)}
                        arrowTheme={cx('arrowSvg', arrowTheme)}
                        options={options}
                        onChange={handlerChange}
                        defaultValue={`${currentPage}`}
                    />
                    <span className={cx('listPage', customListPage)}>
                        {' '}
                        {ofText} {totalPage}
                    </span>
                </div>
                <div
                    automation-id='at-list-number-of-item-dropdown'
                    className={cx(
                        customSelectItems,
                        singlePageItems ? 'singlePageItems' : 'selectBoxItems',
                        customItemsClass
                    )}
                >
                    <SelectBox
                        theme={cx('customBox', paginationSelect)}
                        themeSelectBox={cx('pageThemeselectBox', themeSelectBox)}
                        arrowTheme={arrowTheme}
                        options={pageSizeOptions}
                        onChange={ItemHandlerChange}
                        defaultValue={`${pageSize}`}
                    />
                </div>
            </div>
        );
    }
    return (
        <nav className={cx('paginationWrapper', customPageWrapper)}>
            <div className={cx(customSelectItems, 'singlePageItems')}>
                <SelectBox
                    theme={cx('customBox', paginationSelect)}
                    themeSelectBox={cx('pageThemeselectBox', themeSelectBox)}
                    arrowTheme={arrowTheme}
                    options={pageSizeOptions}
                    onChange={ItemHandlerChange}
                    defaultValue={`${pageSize}`}
                />
            </div>
            <div className={cx('pagination')}>
                <ul>
                    <li
                        automation-id='at-previous-arrow-page-button'
                        className={cx(currentPage > 1 ? '' : 'disabledArrow')}
                    >
                        <a
                            role='presentation'
                            className={cx('prevbtn')}
                            id='backNav'
                            aria-label='arrow'
                            onClick={() => props.onClick(currentPage - 1)}
                        >
                            <i className={cx('arrow', 'left')} />
                        </a>
                    </li>
                    {currentPage === totalPage && currentPage - 2 > 0 && (
                        <li>
                            <span className={cx('pagination-inner')}>
                                <a
                                    role='presentation'
                                    id='twoStepBackNav'
                                    onClick={() => props.onClick(currentPage - 2)}
                                >
                                    {currentPage - 2}
                                </a>
                            </span>
                        </li>
                    )}
                    {currentPage - 1 >= 1 && (
                        <li>
                            <span className={cx('pagination-inner')}>
                                <a
                                    role='presentation'
                                    id='oneStepBackNav'
                                    onClick={() => props.onClick(currentPage - 1)}
                                >
                                    {currentPage - 1}
                                </a>
                            </span>
                        </li>
                    )}
                    <li className={cx(currentPage && 'paginationActive')}>
                        <span>
                            {' '}
                            <a>{currentPage}</a>
                        </span>
                    </li>
                    {currentPage + 1 <= totalPage && (
                        <li className={cx(currentPage === currentPage + 1 && 'paginationActive')}>
                            <span className={cx('pagination-inner')}>
                                <a
                                    role='presentation'
                                    id='oneStepFwdNav'
                                    onClick={() => props.onClick(currentPage + 1)}
                                >
                                    {currentPage + 1}
                                </a>
                            </span>
                        </li>
                    )}
                    {currentPage === 1 && currentPage + 2 <= totalPage && (
                        <li>
                            <span className={cx('pagination-inner')}>
                                <a
                                    role='presentation'
                                    id='twoStepFwdNav'
                                    onClick={() => props.onClick(currentPage + 2)}
                                >
                                    {currentPage + 2}
                                </a>
                            </span>
                        </li>
                    )}
                    <li
                        automation-id='at-next-arrow-page-button'
                        className={cx(currentPage === totalPage ? 'disabledArrow' : '', 'paginationNext')}
                    >
                        <a
                            role='presentation'
                            className={cx('nextbtn')}
                            id='fwdNav'
                            aria-label='arrow'
                            onClick={() => props.onClick(currentPage + 1)}
                        >
                            <i className={cx('arrow', 'right')} />
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

Pagination.propTypes = {
    onClick: PropTypes.func,
    currentPage: PropTypes.number,
    totalPage: PropTypes.number,
    onChange: PropTypes.func,
    renderType: PropTypes.string,
    arrowTheme: PropTypes.string,
    themeSelectBox: PropTypes.string,
    paginationSelect: PropTypes.string,
    customListPage: PropTypes.string,
    customSelectItems: PropTypes.string,
    singlePageItems: PropTypes.bool,
    customPageWrapper: PropTypes.string,
    customItemsClass: PropTypes.string,
    pageText: PropTypes.string,
    itemsText: PropTypes.string,
    ofText: PropTypes.string
};

Pagination.defaultProps = {
    currentPage: 1,
    renderType: '',
    arrowTheme: '',
    themeSelectBox: '',
    paginationSelect: '',
    customListPage: '',
    customSelectItems: '',
    singlePageItems: false,
    customPageWrapper: '',
    customItemsClass: '',
    pageText: '',
    itemsText: '',
    ofText: ''
};

export default Pagination;
