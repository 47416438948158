import {
    GET_USER_MAINTENANCE_REQUEST,
    ADD_NEW_USER_ACCOUNT,
    EDIT_USER_ACCOUNT,
    DELETE_FROM_USER,
    RESET_EDIT_SUCCESS,
    CLEAR_REDUCER,
    UPDATE_SOFT_CONSENT_FLAG
} from '../actionTypes/UserMaintenanceActionTypes';

export const clearUserMaintenanceReducer = () => ({
    type: CLEAR_REDUCER
})

export const getUserMaintenanceDetails = payload => ({
    type: GET_USER_MAINTENANCE_REQUEST,
    payload
});

export const onAddNewUser = addUserInfo => ({
    type: ADD_NEW_USER_ACCOUNT,
    payload: addUserInfo
});

export const onEditUser = editUserInfo => ({
    type: EDIT_USER_ACCOUNT,
    payload: editUserInfo
});

export const onDeleteFromUser = payload => ({
    type: DELETE_FROM_USER,
    payload
});

export const onResetEditSuccess = () => ({
    type: RESET_EDIT_SUCCESS
});

export const updateSoftConsentFlag = payload => ({
    type: UPDATE_SOFT_CONSENT_FLAG,
    payload
});
