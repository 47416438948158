import { put, takeLatest, call } from 'redux-saga/effects';
import _get from 'lodash/get';
import {
    GET_CONTACT_INFO_REQUEST,
    GET_CONTACT_INFO_SUCCESS,
    GET_CONTACT_INFO_ERROR,
    SUBMIT_CONTACT_INFO_FORM,
    HIDE_CONTACT_INFO_FORM
} from '../actionTypes/ContactMeActionTypes';
import FactorySaga from './FactorySaga';
import { getClaimHistorySaga } from './ClaimHistorySaga';
import ContactMeApi from '../interfaces/ContactMe/ContactMeApi';
import { getErrorInfo } from '../common/Utils/Utils';
import { notifySuccess } from '../actions/NotificationAction';
import { PAGE_ID } from '../common/Constants';

function* getContactInfoSaga(action) {
    try {
        const result = yield call(FactorySaga, ContactMeApi.getContactInfo, action);
        if (result.isSuccess) {
            yield put({ type: GET_CONTACT_INFO_SUCCESS, data: _get(result.response, 'data') });
        } else {
            yield put({ type: GET_CONTACT_INFO_ERROR, data: { error: getErrorInfo(result) } });
        }
    } catch (err) {
        yield put({ type: GET_CONTACT_INFO_ERROR });
    }
}

function* submitContactInfoSaga(action) {
    try {
        const result = yield call(FactorySaga, ContactMeApi.submitContactInfo, action);
        if (result.isSuccess) {
            yield put(notifySuccess(result.response.data));
            yield put({ type: HIDE_CONTACT_INFO_FORM });
            if (action.payload.forceUpdate) {
                yield call(getClaimHistorySaga, {
                    payload: {
                        pageId: PAGE_ID.CLAIMS_HISTORY,
                        params: {
                            status: 'All'
                        }
                    }
                });
            }
        }
    } catch (err) {
        yield put({ type: GET_CONTACT_INFO_ERROR });
    }
}

export default function* watchContactInfoRequest() {
    yield takeLatest(GET_CONTACT_INFO_REQUEST, getContactInfoSaga);
    yield takeLatest(SUBMIT_CONTACT_INFO_FORM, submitContactInfoSaga);
}
