/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _find from 'lodash/find';
import classNames from 'classnames/bind';
import PageFooter from './PageFooter';
import { LOGIN_UID, COMMON_COMPONENT, UNIQUE_ID, PAGE_ID, GENERIC_L10N, URL_PATHS } from '../../../common/Constants';
import { getAccountData } from '../../../actions/AccountDataAction';
import Modal from '../../core/Modal/Modal';
import Button from '../../core/Button/Button';
import FooterLogin from './FooterLogin';
import { setInitialSetting } from '../../../common/widgetChat/settings/widgets.config.authenticated';
import OnTopButton from './OnTopButton';
import { useDeviceType } from '../../../common/Device/Device';
import { getUserId, getChatBotEnabled } from '../../../common/UserManager/UserManager';
import { setChatPluginLoaded } from '../../../actions/PageContentAction';
import * as styles from './Footer.css';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';
import Icon from '../../core/Icon/Icon';

const cx = classNames.bind(styles);

function Footer({ isLoginFooter }) {
    const dispatch = useDispatch();
    const deviceType = useDeviceType();

    const isPlpPage = window.location.pathname.includes(URL_PATHS.PLP);

    const [isChatPluginLoaded, setIsChatPluginLoaded] = useState(false);
    const [isShowPopup, setIsShowPopup] = useState(false);
    const [isSalePopup, setIsSalePopup] = useState(false);
    const [showMicrosoftChatBot, setShowMicrosoftChatBot] = useState(false);
    const [showMicrosoftChatBotPopup, setShowMicrosoftChatBotPopup] = useState(false);
    const [visible, setVisible] = useState(false);

    const userId = getUserId();
    const isMicrosoftChatBotEnabled = getChatBotEnabled();

    const restrictedRoutes = [
        URL_PATHS.CART,
        URL_PATHS.CHECKOUT,
        URL_PATHS.CHECKOUT_PROMOTIONS,
        URL_PATHS.ORDER_CONFIRMATION
    ];

    const localization = useSelector(state => state.pageContent.localization);
    const { pageId } = localization;
    const commonLocalization = useSelector(state => state.pageContent.commonLocalization[PAGE_ID.GENERIC]);
    const loginFooterComp = useSelector(state => state.loginPage[LOGIN_UID.FOOTER]);
    const salesContact = useSelector(state => state.commonData.factSheet || {});
    const showChatWidget = getUserId()
        ? useSelector(state => state.pageContent.showChatWidget)
        : useSelector(state => state.loginPage.footerComponent.showChatWidget);
    const chatLanguage = getUserId()
        ? useSelector(state => state.pageContent.chatLanguage)
        : useSelector(state => state.loginPage.footerComponent.chatLanguage);
    const chatCountry = getUserId()
        ? useSelector(state => state.pageContent.chatCountry)
        : useSelector(state => state.loginPage.footerComponent.chatCountry);
    const chatEmail = getUserId()
        ? useSelector(state => state.pageContent.chatEmail)
        : useSelector(state => state.loginPage.footerComponent.chatEmail);
    const content = useSelector(state => {
        return !userId ? state.loginPage.footerComponent.content : null;
    });
    const chatCurrentOutletId = getUserId()
        ? useSelector(state => state.pageContent.chatCurrentOutletId)
        : useSelector(state => state.loginPage.footerComponent.chatCurrentOutletId);
    const chatCurrentCompanyName = getUserId()
        ? useSelector(state => state.pageContent.chatCurrentCompanyName)
        : useSelector(state => state.loginPage.footerComponent.chatCurrentCompanyName);
    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);

    const renderChatWidget = async () => {
        const widgetBaseUrl = 'https://apps.mypurecloud.de/widgets/9.0/';

        if (!document.getElementById('chatWidget')) {
            const chatWidget = document.createElement('script');

            chatWidget.setAttribute('id', 'chatWidget');
            chatWidget.setAttribute('src', `${widgetBaseUrl}cxbus.min.js`);
            chatWidget.addEventListener('load', function () {
                CXBus.configure({ debug: false, pluginsPath: `${widgetBaseUrl}plugins/` });
                setInitialSetting(chatLanguage, chatCountry, null, null, null, content, assetBaseUrl).then(
                    function async() {
                        CXBus.loadPlugin('widgets-core').then(() => {
                            setIsChatPluginLoaded(true);
                            dispatch(setChatPluginLoaded());
                            setTimeout(() => {
                                try {
                                    const chatIcon = document.querySelector(
                                        'ul.cx-widget.cx-sidebar > li.chat > div.cx-sidebar-button'
                                    );
                                    chatIcon?.addEventListener?.('click', e => {
                                        const clickedElement = e.target.closest('div.cx-sidebar-button');
                                        if (clickedElement) {
                                            setShowMicrosoftChatBotPopup(false);
                                            buttonClickEvent(
                                                buttonClickEventConfigs.footer.actions.chat,
                                                buttonClickEventConfigs.footer.labels.chatStartChat,
                                                buttonClickEventConfigs.footer.categories.footer
                                            );
                                        }
                                    });
                                } catch (ex) {
                                    console.error(ex);
                                }
                            }, 1000);
                        });
                    }
                );
            });
            document.head.appendChild(chatWidget);
        } else {
            document.querySelector('.cx-widget').style.display = 'block';
            setIsChatPluginLoaded(true);
        }
    };

    const hideChatWidget = () => {
        if (document.querySelector('.cx-widget')) {
            document.querySelector('.cx-widget').style.display = 'none';
        }
    };

    useEffect(() => {
        if (showChatWidget) {
            const isRestrictedPage = restrictedRoutes.some(route => window.location.pathname.includes(route));
            if (deviceType === 'mobile' && isRestrictedPage) {
                hideChatWidget();
            } else {
                renderChatWidget();
            }
        }
    }, [showChatWidget]);

    // useEffect that shows the chatbot popup only 1 time on mount
    useEffect(() => {
        const liveChat = document.querySelector('.cx-webchat');
        if (isMicrosoftChatBotEnabled && pageId === 'home' && !liveChat) {
            setShowMicrosoftChatBotPopup(true);
        } else {
            setShowMicrosoftChatBotPopup(false);
        }
    }, [isMicrosoftChatBotEnabled, pageId]);

    const handlePopup = (e, item) => {
        e.preventDefault();
        const footerLink = (item || {})[UNIQUE_ID];
        if (footerLink === COMMON_COMPONENT.FOOTER_CALL_US_LINK || footerLink === LOGIN_UID.CONTACT_US) {
            setIsShowPopup(true);
        } else if (footerLink === COMMON_COMPONENT.FOOTER_SALES_LINK) {
            _isEmpty(salesContact) && dispatch(getAccountData({ pageId: PAGE_ID.CUSTOMER_FACT_SHEET }));
            setIsSalePopup(true);
        } else if (footerLink === COMMON_COMPONENT.FOOTER_LIVE_CHAT) {
            //ask for this case of live chat in footer is it deprecated
            window.open(
                `${item.linkUrl}`,
                'myWin',
                'toolbar=no, directories=no, location=no, status=yes, menubar=no, resizable=yes, scrollbars=yes, width=500, height=500'
            );
        }
    };

    const onCloseModal = useCallback(() => {
        setIsShowPopup(false);
        setIsSalePopup(false);
    });

    const renderModal = () => {
        const { content = {} } = loginFooterComp;
        const callUsText = content[LOGIN_UID.CONTENT.CALL_US] || commonLocalization[GENERIC_L10N.CALL_US];
        const phoneTxt = content[LOGIN_UID.CONTENT.PHONE_NO] || commonLocalization[GENERIC_L10N.PHONE_NO];
        const cancelBtn = content[LOGIN_UID.CONTENT.CANCEL_BTN] || commonLocalization[GENERIC_L10N.CANCEL_BTN];

        return (
            <div className={cx('contactUsModal')}>
                <Modal
                    showModal={isShowPopup}
                    modalTheme={cx('userThemeModal')}
                    modalTitleTheme={cx('modalTitleTheme')}
                    themeContent={cx('themeContent')}
                    themeClose={cx('themeClose')}
                    themeModalBlock={cx('themeModalBlock')}
                    defaultCloseBtn={false}
                >
                    <div className={cx('clearContent')}>
                        <p>
                            {callUsText}
                            <a
                                href={`tel:${phoneTxt}`}
                                className={cx('phoneTxt')}
                                onClick={() =>
                                    buttonClickEvent(
                                        buttonClickEventConfigs.footer.actions.contactUsPopUp,
                                        buttonClickEventConfigs.footer.labels.contactUsPopUpPhoneNumberClick,
                                        buttonClickEventConfigs.footer.categories.footer
                                    )
                                }
                            >
                                {phoneTxt}
                            </a>
                        </p>

                        <div className={cx('bottomBtns')}>
                            <Button
                                type='button'
                                size={cx('Sm')}
                                className={cx('userBtns', 'cancelBtn')}
                                onClick={() =>
                                    onCloseModal() &
                                    buttonClickEvent(
                                        buttonClickEventConfigs.footer.actions.contactUsPopUp,
                                        buttonClickEventConfigs.footer.labels.contactUsPopUpCancel,
                                        buttonClickEventConfigs.footer.categories.footer
                                    )
                                }
                            >
                                {cancelBtn}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    };

    const renderSalesModal = () => {
        const { content = {} } = loginFooterComp;
        const cancelBtn = content[LOGIN_UID.CONTENT.CANCEL_BTN] || commonLocalization[GENERIC_L10N.CANCEL_BTN];
        const bdName = _find(salesContact.commonData, item => item.label === 'BD_NAME');
        let bdPhoneNo = _find(salesContact.commonData, item => item.label === 'BD_TEL_NUMBER');
        bdPhoneNo = _get(bdPhoneNo, 'value') || '-';

        return (
            <div className={cx('contactUsModal', 'saleModal')}>
                <Modal
                    showModal={isSalePopup}
                    modalTheme={cx('userThemeModal')}
                    modalTitleTheme={cx('modalTitleTheme')}
                    themeContent={cx('themeContent')}
                    themeClose={cx('themeClose')}
                    themeModalBlock={cx('themeModalBlock')}
                    defaultCloseBtn={false}
                >
                    <div className={cx('clearContent')}>
                        <div className={cx('saleDetails')}>
                            <div automation-id='at-user_name' className={cx('col6', 'sm12')}>
                                <h5 className={cx('tableTitle', 'sm12')}>{commonLocalization[GENERIC_L10N.BD_Name]}</h5>
                                <p className={cx('sm12')}>{_get(bdName, 'value') || '-'}</p>
                            </div>
                            <div automation-id='at-user_name' className={cx('col6', 'sm12')}>
                                <h5 className={cx('tableTitle', 'sm12')}>
                                    {commonLocalization[GENERIC_L10N.BD_Phone]}
                                </h5>
                                <p className={cx('sm12', 'phoneNo')}>
                                    {bdPhoneNo !== '-' && (
                                        <a
                                            href={`tel:${bdPhoneNo}`}
                                            className={cx('phoneTxt')}
                                            onClick={() =>
                                                buttonClickEvent(
                                                    buttonClickEventConfigs.footer.actions.bdPopUp,
                                                    buttonClickEventConfigs.footer.labels.bdPopupPhoneNumberClick,
                                                    buttonClickEventConfigs.footer.categories.footer
                                                )
                                            }
                                        >
                                            {bdPhoneNo}
                                        </a>
                                    )}
                                    {bdPhoneNo === '-' && bdPhoneNo}
                                </p>
                            </div>
                        </div>

                        <div className={cx('bottomBtns')}>
                            <Button
                                type='button'
                                size={cx('Sm')}
                                className={cx('userBtns', 'cancelBtn')}
                                onClick={() =>
                                    onCloseModal() &
                                    buttonClickEvent(
                                        buttonClickEventConfigs.footer.actions.bdPopUp,
                                        buttonClickEventConfigs.footer.labels.bdPopUpCancel,
                                        buttonClickEventConfigs.footer.categories.footer
                                    )
                                }
                            >
                                {cancelBtn}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    };

    const renderMicrosoftChatBotIcon = () => {
        return (
            <div
                style={{
                    bottom:
                        visible && deviceType !== 'mobile'
                            ? '145px'
                            : visible && deviceType === 'mobile'
                            ? '175px'
                            : !visible && deviceType === 'mobile'
                            ? '135px'
                            : '125px'
                }}
                className={cx('microsoft-chatbot-container')}
            >
                <div
                    role='button'
                    tabIndex={0}
                    className={cx('microsoft-chat-bot')}
                    onKeyDown={() => {
                        setShowMicrosoftChatBot(prevState => !prevState);
                        setShowMicrosoftChatBotPopup(false);
                    }}
                    onClick={() => {
                        setShowMicrosoftChatBot(prevState => !prevState);
                        setShowMicrosoftChatBotPopup(false);
                    }}
                >
                    <Icon
                        className={cx('flex-icon')}
                        iconType='svg'
                        width='25px'
                        height='25px'
                        viewBox='0 0 18 24'
                        name='chatbox-ellipsis-outline'
                    />
                    {showMicrosoftChatBotPopup && (
                        <div className={cx('microsoft-chatbot-popup')}>
                            <div className={cx('microsoft-chatbot-popup-col')}>
                                <p className={cx('microsoft-chatbot-popup-title')} style={{ marginBottom: '5px' }}>
                                    {commonLocalization[GENERIC_L10N.CHATBOT_POPUP_TEXT_1]}
                                </p>
                                <p className={cx('microsoft-chatbot-popup-title')}>
                                    {commonLocalization[GENERIC_L10N.CHATBOT_POPUP_TEXT_2]}
                                </p>
                                <div className={cx('microsoft-chatbot-popup-divider')} />
                                <p
                                    role='presentation'
                                    className={cx('microsoft-chatbot-popup-title')}
                                    style={{ textDecoration: 'underline', color: '#E90000' }}
                                    onKeyDown={e => {
                                        e.stopPropagation();
                                        setShowMicrosoftChatBot(true);
                                        setShowMicrosoftChatBotPopup(false);
                                    }}
                                    onClick={e => {
                                        e.stopPropagation();
                                        setShowMicrosoftChatBot(true);
                                        setShowMicrosoftChatBotPopup(false);
                                    }}
                                >
                                    {commonLocalization[GENERIC_L10N.CHATBOT_POPUP_TEXT_3]}
                                </p>
                            </div>
                            <div
                                role='presentation'
                                onKeyDown={e => {
                                    e.stopPropagation();
                                    setShowMicrosoftChatBotPopup(false);
                                }}
                                onClick={e => {
                                    e.stopPropagation();
                                    setShowMicrosoftChatBotPopup(false);
                                }}
                                className={cx('microsoft-chatbot-popup-close-icon')}
                            >
                                <Icon
                                    className={cx('flex-icon')}
                                    iconType='svg'
                                    width='15px'
                                    height='15px'
                                    viewBox='0 0 24 24'
                                    name='close-global'
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <footer automation-id='at-footer-section' className={cx('footerInnerSection')}>
            {isLoginFooter ? (
                <FooterLogin handlePopup={handlePopup} />
            ) : (
                <PageFooter
                    isChatPluginLoaded={isChatPluginLoaded}
                    chatEmail={chatEmail}
                    chatCurrentOutletId={chatCurrentOutletId}
                    chatCurrentCompanyName={chatCurrentCompanyName}
                    handlePopup={handlePopup}
                    showMicrosoftChatBot={showMicrosoftChatBot}
                    setShowMicrosoftChatBot={setShowMicrosoftChatBot}
                />
            )}
            {isShowPopup && renderModal()}
            {isSalePopup && renderSalesModal()}
            {isMicrosoftChatBotEnabled && renderMicrosoftChatBotIcon()}
            <OnTopButton visible={visible} setVisible={setVisible} isPlpPage={isPlpPage} />
        </footer>
    );
}

Footer.defaultProps = {
    isLoginFooter: false,
    salesContact: {},
    loginFooterComp: {},
    commonLocalization: {}
};

Footer.propTypes = {
    isLoginFooter: PropTypes.bool,
    loginFooterComp: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default Footer;
