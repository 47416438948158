import classNames from 'classnames/bind';
import React from 'react';
import RadioButton from '../../../../components/core/RadioButton/RadioButton';
import { PROMO_L10N } from '../../../../common/Constants';
import * as styles from '../Promotions.css';

const cx = classNames.bind(styles);

const StepOnePromotion = props => {
    props.setPreSelectedDeal(props.deals.length, props.promotionKey, props.showInitial);

    const renderDealDescription = (conditionalItemAmount, freeItemMaxAmount) => {
        return (
            <>
                <strong>
                    {props.localization[PROMO_L10N.BUY]} {conditionalItemAmount}
                </strong>{' '}
                {props.localization[PROMO_L10N.PROD_TO]}{' '}
                <strong>
                    {props.localization[PROMO_L10N.GET]} {freeItemMaxAmount}
                </strong>{' '}
                {props.localization[PROMO_L10N.PROD_FOR]}{' '}
                <span className={cx('freeTxt', 'textBrand')}>{props.localization[PROMO_L10N.FREE]}</span>.
            </>
        );
    };

    return (
        <div
            automation-id='at-green-tick-step-1'
            className={cx('container', `${props.activeDealIndex == null ? '' : 'active'}`)}
        >
            {' '}
            {/* 'active'  Todo Active CSS class Added Here */}
            <div automation-id='at-step-1-option' className={cx('content')}>
                <h2 className={cx('stepsTitle')}>
                    <strong>{props.localization[PROMO_L10N.STEP1]}. </strong>{' '}
                    {props.localization[PROMO_L10N.STEP1_TEXT]}
                </h2>
                {props.deals.map((deal, key) => {
                    const id = `${props.activeTabPanelIndex}buy${key}`;
                    return (
                        <label
                            key={id}
                            automation-id={`at-valid-deals-${key + 1}`}
                            className={cx('customRadio')}
                            htmlFor={id}
                        >
                            <RadioButton
                                name='buy'
                                id={id}
                                checked={props.activeDealIndex === key}
                                onChange={e => props.handleStepOneRadioChange(e, key)}
                            />
                            <p>{renderDealDescription(deal.conditionalItemAmount, deal.freeItemMaxAmount)}</p>
                        </label>
                    );
                })}
            </div>
        </div>
    );
};

export default StepOnePromotion;
