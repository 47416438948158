import classNames from 'classnames/bind';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RadioButton from '../../../../components/core/RadioButton/RadioButton';
import { PROMO_L10N } from '../../../../common/Constants';

import * as styles from '../Promotions.css';
const cx = classNames.bind(styles);

const StepOnePromotionTypeB = ({
    deals,
    promotionKey,
    showInitial,
    mechanicType,
    activeDealIndex,
    activeTabPanelIndex,
    ...props
}) => {
    const localization = useSelector(state => state.pageContent.localization);

    props.setPreSelectedDeal(deals.length, promotionKey, showInitial, mechanicType);
    return (
        <div
            automation-id='at-green-tick-step-1'
            className={cx('container', `${activeDealIndex == null ? '' : 'active'}`)}
        >
            <div automation-id='at-step-1-option' className={cx('content')}>
                <h2 className={cx('stepsTitle')}>
                    <strong>{localization[PROMO_L10N.STEP1]}. </strong> {localization[PROMO_L10N.STEP1_TEXT]}
                </h2>
                {deals.map((deal, key) => {
                    const id = `${activeTabPanelIndex}buy${key}`;
                    const dealConditionalGroup = deal.conditionalGroups.map(group => group.itemAmount);
                    const { scaleText } = deal.promotionMechanicDealScale
                        ? deal.promotionMechanicDealScale
                        : { scaleText: '' };

                    return (
                        <label
                            key={id}
                            automation-id={`at-valid-deals-${key + 1}`}
                            className={cx('customRadio')}
                            htmlFor={id}
                        >
                            <RadioButton
                                name='buy'
                                id={id}
                                checked={activeDealIndex === key}
                                onChange={e => props.handleStepOneRadioChange(e, key)}
                            />
                            <p>
                                <strong>
                                    {' '}
                                    {localization[PROMO_L10N.BUY]} {dealConditionalGroup.join(' + ')}
                                </strong>
                                {` ${scaleText}`}
                            </p>
                        </label>
                    );
                })}
            </div>
        </div>
    );
};

export default StepOnePromotionTypeB;
