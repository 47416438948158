import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { AUTH, HEADERS } from '../common/Api';

export default class InvoicesApi {
    static getInvoices(payload) {
        const { baseInfo } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}${AUTH.USERS}/${baseInfo.userId}/invoices`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static filterInvoices(payload) {
        const { baseInfo, data } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}${AUTH.USERS}/${baseInfo.userId}/filterinvoices`;
        config.data = data;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static createClaim(payload) {
        const { baseInfo, data } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}${AUTH.USERS}/${baseInfo.userId}/createclaim`;
        config.data = data;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static submitClaim(payload) {
        const { baseInfo, data } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}${AUTH.USERS}/${baseInfo.userId}/submitclaim`;
        config.data = data;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static getInvoicePDF(payload) {
        const { baseInfo, selectedInvoices } = payload;
        if (selectedInvoices.length === 0) {
            return;
        }
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}${AUTH.USERS}/${baseInfo.userId}/invoices/download`;
        config.data = { invoices: selectedInvoices };
        config.customHeaders = {
            [HEADERS.CONTENT_TYPE]: 'application/json'
        };
        return RestClient.post(config).then(json => {
            return json;
        });
    }
}
