import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import NavLink from '../NavLink/NavLink';

const Image = props => {
    const { alt, assetBaseUrl, automationId, theme, src, linkUrl, isInternal, onClick } = props;
    const imageSrc = isInternal ? src : `${assetBaseUrl}${src}`;

    return linkUrl ? (
        <NavLink linkUrl={linkUrl} automationId={automationId} onClick={onClick}>
            <img automation-id={automationId} className={theme} src={imageSrc} alt={alt} />
        </NavLink>
    ) : (
        <img automation-id={automationId} className={theme} src={imageSrc} alt={alt} />
    );
};

Image.propTypes = {
    alt: PropTypes.string,
    assetBaseUrl: PropTypes.string,
    automationId: PropTypes.string,
    theme: PropTypes.string,
    src: PropTypes.string,
    linkUrl: PropTypes.string,
    isInternal: PropTypes.bool,
    onClick: PropTypes.func
};

Image.defaultProps = {
    alt: '',
    assetBaseUrl: '',
    automationId: '',
    theme: '',
    src: '',
    linkUrl: '',
    isInternal: false,
    onClick: () => {}
};

const mapStateToProps = state => ({
    assetBaseUrl: _get(state, 'context.environment.assetBaseUrl')
});

export default connect(mapStateToProps)(Image);
