import { GET_MICROSOFT_CHATBOT_TOKEN_SUCCESS } from "../actionTypes/MicrosoftChatBotTypes";

const initialState = {};

export default function MicrosoftChatBotReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_MICROSOFT_CHATBOT_TOKEN_SUCCESS: {
            return {
                ...state,
                ...action.data,
                ...action.payload
            };
        }
        default:
            return state;
    }
}
