import React from 'react'
import classNames from 'classnames/bind';
import Icon from '../../../components/core/Icon/Icon';
import * as styles from './Invoices.css';

const cx = classNames.bind(styles);


const CustomDatePicker =  ({ value, onClick, dateError, customDateError })  => {
    return (
        <div
            role='presentation'
            onKeyDown={onClick}
            className={cx((dateError || customDateError) && 'date-error', 'custom-date-picker')}
            onClick={onClick}
        >
            {value}
            <Icon iconType='svg' width='20px' height='auto' viewBox='0 0 22 22' name='calender' className={cx('custom-date-picker-icon')} />
        </div>
    );
}

export default CustomDatePicker