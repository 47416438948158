export const lt = {
  "lt": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
    "callus": {
      "CallUsTitle": "Skambinkite mums",
      "ContactsHeader": "Galite susisiekti su mumis bet kuriuo iš šių numerių ...",
      "CancelButtonText": "Atšaukti",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Already on a call? Let us <a role='link' tabindex='0' class='cx-cobrowse-link'>browse with you</a></span>",
      "CoBrowse": "Pradėti naršymą kartu",
      "CoBrowseWarning": "Naršymas kartu leidžia jūsų agentui matyti ir valdyti jūsų darbalaukį, kad jums padėtų. Pokalbio metu, paprašykite agento kodo, kad pradėtumėte naršymą kartu, ir įveskite kodą žemiau. Dar neskambinote? Tiesiog išeikite iš šio ekrano, kad grįžtumėte į puslapį „Skambinkite mums",
      "SubTitle": "Galite susisiekti su mumis bet kuriuo iš šių numerių...",
      "AriaWindowLabel": "Skambinkite mums langas",
      "AriaCallUsClose": "Uždaryti Skambinkite mums",
      "AriaBusinessHours": "Darbo valandos",
      "AriaCallUsPhoneApp": "Atidaro telefono programą",
      "AriaCobrowseLink": "Atidaro naršymo kartu sesiją",
      "AriaCancelButtonText": "Atšaukti Skambinkite mums"
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector": {
      "Title": "Pagalba gyvai",
      "SubTitle": "Kaip norėtumėte susisiekti?",
      "WaitTimeTitle": "Laukimo laikas",
      "AvailableTitle": "Pasiekiamas",
      "AriaAvailableTitle": "Pasiekiamas",
      "UnavailableTitle": "Nepasiekiamas",
      "CobrowseButtonText": "Naršymo kartu paantraštė",
      "CallbackTitle": "Gauti skambutį",
      "CobrowseSubTitle": "Reikalingas agento prisijungimas.",
      "AriaClose": "Uždaryti Pagalbą gyvai",
      "AriaWarning": "Perspėjimas",
      "AriaAlert": "Įspėjimas",
      "minute": "min",
      "minutes": "min",
      "AriaWindowLabel": "Pagalbos gyvai langas"
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse": {
      "agentJoined": "Atstovas prisijungė prie sesijos.",
      "youLeft": "Jūs palikote sesiją. Naršymas kartu yra nutrauktas.",
      "sessionTimedOut": "Sesijos laikas baigėsi. Dabar naršymas kartu yra nutrauktas.",
      "sessionInactiveTimedOut": "Sesijos laikas baigėsi. Dabar naršymas kartu yra nutrauktas.",
      "agentLeft": "Atstovas paliko sesiją. Dabar naršymas kartu yra nutrauktas.",
      "sessionError": "Įvyko netikėta klaida. Dabar naršymas kartu yra nutrauktas.",
      "sessionStarted": "Prasidėjo naršymo kartu sesija. Laukiama, kol atstovas prisijungs prie sesijos…",
      "navRefresh": "Atstovas atnaujino puslapį. Perkraunama.",
      "navBack": "Atstovas paspaudė mygtuką Atgal. Perkraunamas puslapis.",
      "navForward": "Atstovas paspaudė mygtuką „Persiųsti“. Perkraunamas puslapis.",
      "navUrl": "Atstovas paprašė naršymo. Perkraunamas puslapis.",
      "navFailed": "Nepavyko pateikti atstovo naršymo užklausos.",
      "toolbarContent": "Sesijos ID: {sessionId}",
      "contentMasked": "Turinys paslėptas nuo atstovo.",
      "contentMaskedPartially": "Kai kuris turinys paslėptas nuo atstovo.",
      "exitBtnTitle": "Išeiti iš naršymo kartu sesijos",
      "areYouOnPhone": "Ar jūs kalbatės telefonu su mūsų atstovu?",
      "areYouOnPhoneOrChat": "Ar jūs kalbatės telefonu, ar susirašinėjate su mūsų atstovu?",
      "connectBeforeCobrowse": "Norėdami tęsti naršymą kartu, turite būti susijungę su mūsų atstovu. Paskambinkite mums arba pradėkite tiesioginį pokalbį su mumis ir vėl pradėkite naršymą kartu.",
      "sessionStartedAutoConnect": "Prasidėjo naršymo kartu sesija. Laukiama, kol atstovas prisijungs prie sesijos…",
      "browserUnsupported": "Deja, jūsų naudojama naršyklė šiuo metu nepalaikoma.<br><br> Palaikomos naršyklės yra: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 and above</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 and above</a></li></ul>",
      "chatIsAlreadyRunning": "Pokalbis jau vykdomas kitame puslapyje.",
      "modalYes": "Taip",
      "modalNo": "Ne"
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar": {
      "SidebarTitle": "Reikia pagalbos?",
      "ChannelSelectorName": "Pagalba gyvai",
      "ChannelSelectorTitle": "Gaukite pagalbą iš vieno iš mūsų atstovų dabar",
      "SearchName": "Paieška",
      "SearchTitle": "Paieška",
      "CallUsName": "Skambinkite mums",
      "CallUsTitle": "Skambinkite mums detalės",
      "CallbackName": "Perskambinti",
      "CallbackTitle": "Gauti skambutį",
      "ClickToCallName": "Spustelėkite, kad skambintumėte",
      "ClickToCallTitle": "Paprašyti klientų aptarnavimo telefono numerio ",
      "SendMessageName": "Siųsti žinutę",
      "SendMessageTitle": "Siųsti žinutę",
      "WebChatName": "Gyvas pokalbis",
      "WebChatTitle": "Gyvas pokalbis",
      "AriaClose": "Uždarykite meniu Reikia pagalbos"
    },
    "webchat": {
      "ChatButton": "Pokalbis",
      "ChatStarted": "Pokalbis prasidėjo",
      "ChatEnded": "Pokalbis baigėsi",
      "AgentNameDefault": "Agentas",
      "AgentConnected": "<%Agent%> Prisijungė",
      "AgentDisconnected": "<%Agent%> Atsijungė",
      "BotNameDefault": "Robotas",
      "BotConnected": "<%Bot%> Prisijungė",
      "BotDisconnected": "<%Bot%> Atsijungė",
      "SupervisorNameDefault": "Vadovas",
      "SupervisorConnected": "<%Agent%> Prisijungė",
      "SupervisorDisconnected": "<%Agent%> Atsijungė",
      "AgentTyping": "...",
      "AriaAgentTyping": "Agentas rašo",
      "AgentUnavailable": "Atsiprašome. Šiuo metu nėra laisvų agentų. Pabandykite vėliau.",
      "ChatTitle": "Gyvas pokalbis",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Min",
      "ChatFormFirstName": "Vardas",
      "ChatFormLastName": "Pavardė",
      "ChatFormNickname": "Slapyvardis",
      "ChatFormName": "Vardas",
      "ChatFormCustomerId": "Kliento ID",
      "ChatFormEmail": "Elektroninis paštas",
      "ChatFormSubject": "Tema",
	    "ChatFormSubjectPassword": "Slaptažodis",
	    "ChatFormSubjectService": "Paslauga",
	    "ChatFormSubjectOrder": "Užsakymas",
	    "ChatFormSubjectFinance": "Finansai",
      "ChatFormPlaceholderFirstName": "Privaloma",
      "ChatFormPlaceholderLastName": "Privaloma",
      "ChatFormPlaceholderName": "Pasirenkama",
      "ChatFormPlaceholderCustomerId": "Pasirenkama",
      "ChatFormPlaceholderNickname": "Pasirenkama",
      "ChatFormPlaceholderEmail": "Privaloma",
      "ChatFormPlaceholderSubject": "Pasirenkama",
      "ChatFormSubmit": "Pradėti pokalbį",
      "AriaChatFormSubmit": "Pradėti pokalbį",
      "ChatFormCancel": "Atšaukti",
      "AriaChatFormCancel": "Atšaukti pokalbį",
      "ChatFormClose": "Uždaryti",
      "ChatInputPlaceholder": "Rašykite savo žinutę čia",
      "ChatInputSend": "Siųsti",
      "AriaChatInputSend": "Siųsti",
      "ChatEndQuestion": "Ar tikrai norite baigti šio pokalbio sesiją?",
      "ChatEndCancel": "Atšaukti",
      "ChatEndConfirm": "Baigti pokalbį",
      "AriaChatEndCancel": "Atšaukti",
      "AriaChatEndConfirm": "Baigti",
      "ConfirmCloseWindow": "Ar tikrai norite uždaryti pokalbį?",
      "ConfirmCloseCancel": "Atšaukti",
      "ConfirmCloseConfirm": "Uždaryti",
      "AriaConfirmCloseCancel": "Atšaukti",
      "AriaConfirmCloseConfirm": "Uždaryti",
      "ActionsDownload": "Atsisiųsti išrašą",
      "ActionsEmail": "Siųsti išrašą el. paštu",
      "ActionsPrint": "Spausdinti išrašą",
      "ActionsCobrowseStart": "Pradėti naršymą kartu",
      "AriaActionsCobrowseStartTitle": "Atidaro naršymo kartu sesiją",
      "ActionsSendFile": "Pridėti failus",
      "AriaActionsSendFileTitle": "Atidaro failo įkėlimo langą ",
      "ActionsEmoji": "Siųsti jaustukus",
      "ActionsCobrowseStop": "Išeiti iš naršymo kartu",
      "ActionsVideo": "Pakviesti į vaizdo pokalbį",
      "ActionsTransfer": "Perkelti",
      "ActionsInvite": "Pakviesti",
      "InstructionsTransfer": "Atidarykite šią nuorodą kitame įrenginyje, kad perkeltumėte pokalbio sesiją </br></br><%link%>",
      "InstructionsInvite": "Bendrinkite šią nuorodą su kitu asmeniu, kad pridėtumėte jį prie šio pokalbio sesijos </br></br><%link%>",
      "InviteTitle": "Reikia pagalbos?",
      "InviteBody": "Praneškite mums, ar galime padėti.",
      "InviteReject": "Ačiū, ne",
      "InviteAccept": "Pradėti pokalbį",
      "AriaInviteAccept": "Priimti kvietimą ir pradėti pokalbį",
      "AriaInviteReject": "Atmesti kvietimą",
      "ChatError": "Pradedant pokalbio sesiją kilo problema. Bandykite dar kartą.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Taip",
      "ChatErrorDefaultButton": "Ne",
      "AriaChatErrorPrimaryButton": "Taip",
      "AriaChatErrorDefaultButton": "Ne",
      "DownloadButton": "Atsisiųsti",
      "AriaDownloadButton": "Atsisiųsti failą",
      "FileSent": "buvo išsiųstas:",
      "FileTransferRetry": "Bandyti dar kartą",
      "AriaFileTransferRetry": "Bandyti dar kartą perkelti failą ",
      "FileTransferError": "Gerai",
      "AriaFileTransferError": "Gerai",
      "FileTransferCancel": "Atšaukti",
      "AriaFileTransferCancel": "Atšaukti failo perkėlimą",
      "RestoreTimeoutTitle": "Pokalbis baigtas",
      "RestoreTimeoutBody": "Baigėsi jūsų ankstesnio pokalbio sesijos laikas. Ar norėtumėte pradėti naują?",
      "RestoreTimeoutReject": "Ačiū, ne",
      "RestoreTimeoutAccept": "Pradėti pokalbį",
      "AriaRestoreTimeoutAccept": "Priimti kvietimą ir pradėti pokalbį",
      "AriaRestoreTimeoutReject": "Atmesti kvietimą",
      "EndConfirmBody": "Ar tikrai norite baigti pokalbio sesiją?",
      "EndConfirmAccept": "Baigti pokalbį",
      "EndConfirmReject": "Atšaukti",
      "AriaEndConfirmAccept": "Baigti pokalbį",
      "AriaEndConfirmReject": "Atšaukti",
      "SurveyOfferQuestion": "Ar norite dalyvauti apklausoje?",
      "ShowSurveyAccept": "Taip",
      "ShowSurveyReject": "Ne",
      "AriaShowSurveyAccept": "Taip",
      "AriaShowSurveyReject": "Ne",
      "UnreadMessagesTitle": "neskaitytas",
      "AriaYouSaid": "Jūs minėjote",
      "AriaSaid": "Minėjo",
      "AriaSystemSaid": "Sistema minėjo",
      "AriaWindowLabel": "Gyvo pokalbio langas",
      "AriaMinimize": "Sumažinti gyvo pokalbio langą",
      "AriaMaximize": "Padidinti gyvo pokalbio langą",
      "AriaClose": "Uždaryti gyvą pokalbį",
      "AriaEmojiStatusOpen": "Atidarytas jaustukų pasirinkimo langas",
      "AriaEmojiStatusClose": "Jaustukų pasirinkimo langas uždarytas ",
      "AriaEmoji": "Jaustukai",
      "AriaEmojiPicker": "Jaustukų pasirinkimas",
      "AriaCharRemaining": "Liko simbolių",
      "AriaMessageInput": "Žinučių dėžutė",
      "AsyncChatEnd": "Baigti pokalbį",
      "AsyncChatClose": "Uždaryti langą",
      "AriaAsyncChatEnd": "Baigti pokalbį",
      "AriaAsyncChatClose": "Uždaryti langą",
      "DayLabels": [
        "Sek",
        "Pir",
        "Ant",
        "Tre",
        "Ket",
        "Pen",
        "Šeš"
      ],
      "MonthLabels": [
        "Sau",
        "Vas",
        "Kov",
        "Bal",
        "Geg",
        "Bir",
        "Lie",
        "Rugp",
        "Rugs",
        "Spa",
        "Lap",
        "Gru"
      ],
      "todayLabel": "Šiandien",
      "Errors": {
        "102": "Reikalingas vardas.",
        "103": "Reikalinga pavardė.",
        "161": "Prašome įvesti vardą.",
        "201": "Nepavyko išsiųsti failo.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Maksimalus pridedamų failų skaičius būtų viršytas (<%MaxFilesAllowed%>).</p>",
        "202": "Nepavyko išsiųsti failo.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Bus viršytas įkėlimo limitas ir (arba) maksimalus priedų skaičius (<%MaxAttachmentsSize%>).</p>",
        "203": "Nepavyko išsiųsti failo.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Failo tipas neleidžiamas.</p>",
        "204": "Atsiprašome, bet jūsų pranešimas per ilgas. Parašykite trumpesnį pranešimą.",
        "240": "Atsiprašome, bet šiuo metu negalime pradėti naujo pokalbio. Pabandykite dar kartą vėliau.",
        "364": "Neteisingas el. pašto adresas.",
        "401": "Atsiprašome, bet negalime suteikti prieigos teisės pokalbio sesijai. Ar norėtumėte pradėti naują pokalbį?",
        "404": "Atsiprašome, bet negalime rasti jūsų ankstesnio pokalbio sesijos. Ar norėtumėte pradėti naują pokalbį?",
        "500": "Deja, įvyko netikėta paslaugos klaida. Ar norėtumėte uždaryti ir pradėti naują pokalbį?",
        "503": "Deja, paslauga šiuo metu nepasiekiama arba užimta. Ar norėtumėte uždaryti ir vėl pradėti naują pokalbį?",
        "ChatUnavailable": "Atsiprašome, bet šiuo metu negalime pradėti naujo pokalbio. Pabandykite dar kartą vėliau.",
        "CriticalFault": "Jūsų pokalbio sesija netikėtai baigėsi dėl nežinomos problemos. Mes atsiprašome dėl nepatogumų.",
        "StartFailed": "Pradedant pokalbio sesiją kilo problema. Patikrinkite savo ryšį ir ar tinkamai pateikėte visą reikiamą informaciją, tada bandykite dar kartą.",
        "MessageFailed": "Jūsų pranešimas nebuvo gautas sėkmingai. Prašome, pabandykite dar kartą.",
        "RestoreFailed": "Atsiprašome, bet nepavyko atkurti jūsų pokalbio sesijos dėl nežinomos klaidos.",
        "TransferFailed": "Šiuo metu negalima perkelti pokalbio. Pabandykite dar kartą vėliau.",
        "FileTransferSizeError": "Nepavyko išsiųsti failo.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Failo dydis yra didesnis nei leidžiamas dydis (<%MaxSizePerFile%>).</p>",
        "InviteFailed": "Šiuo metu nepavyko sugeneruoti kvietimo. Pabandykite dar kartą vėliau.",
        "ChatServerWentOffline": "Šiuo metu pranešimams gauti reikia daugiau laiko nei įprasta. Atsiprašome už vėlavimą.",
        "RestoredOffline": "Šiuo metu pranešimams gauti reikia daugiau laiko nei įprasta. Atsiprašome už vėlavimą.",
        "Disconnected": "<div style='text-align:center'>Nutrūko ryšys</div>",
        "Reconnected": "<div style='text-align:center'>Ryšys atkurtas</div>",
        "FileSendFailed": "Nepavyko išsiųsti failo.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>Įvyko netikėtas atsijungimas. Bandyti iš naujo?</p>",
        "Generic": "<div style='text-align:center'>Įvyko netikėta klaida.</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Neteisingas failo tipas. Leidžiami tik vaizdai.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "Failo dydis yra didesnis nei leidžiamas dydis.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "The maximum number of attached files exceeded the limit.",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Neteisinga x-api-key perdavimo konfigūracija.",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Netinkama galutinio taško perdavimo konfigūracija.",
        "pureengage-v3-rest-INVALID_NICKNAME": "Reikalingas vardas.",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "Atsiprašome, bet negalime suteikti prieigos teisės pokalbio sesijai.",
        "purecloud-v2-sockets-400": "Deja, kažkas ne taip. Patikrinkite savo ryšį ir ar tinkamai pateikėte visą reikiamą informaciją, tada bandykite dar kartą.",
        "purecloud-v2-sockets-500": "Atsiprašome, įvyko netikėta paslaugos klaida.",
        "purecloud-v2-sockets-503": "Deja, paslauga šiuo metu nepasiekiama."
      }
    }
  }
}
