/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Link from 'react-router/lib/Link';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { format, subMonths } from 'date-fns';
import { enUS, cs, ro, hu, bg, hr, uk, sk, el, sl, de, ru, pl, mk, fr, sr, lv, lt, et, th } from 'date-fns/locale';
import { albanianLocale } from '../../../components/core/Datepicker/CustomLocale';
import Layout from '../../../components/site/Layout/Layout';
import PageHeader from '../../../components/site/PageHeader/PageHeader';
import Button from '../../../components/core/Button/Button';
import Checkbox from '../../../components/core/Checkbox/Checkbox';
import SelectBox from '../../../components/core/SelectBox/SelectBox';
import RadioButton from '../../../components/core/RadioButton/RadioButton';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import Modal from '../../../components/core/Modal/Modal';
import CreateClaim from '../../../components/site/CreateClaim/CreateClaim';
import Icon from '../../../components/core/Icon/Icon';
import { getPageContent } from '../../../actions/PageContentAction';
import {
    getFilterInvoicesDetails,
    createClaim,
    getInvoicePDF,
    resetInvoiceDetails,
    getInvoicesDetails
} from '../../../actions/InvoicesAction';
import { clearAccountStatementError } from '../../../actions/AccountStatementAction';
import { PAGE_ID, INVOICES_L10N, GENERIC_L10N, ACCOUNT_STATEMENTS_L10N, LOGIN_INFO } from '../../../common/Constants';
import * as styles from './Invoices.css';
import { convertBGNToEUR, formatHungaryTousends } from '../../../common/Utils/Utils';
import AccountStatement from '../../../components/site/AccountStatement/AccountStatement';
import CustomSelect from '../../../components/core/CustomSelect/CustomSelect';
import { getCurrency, getLocalization, getSiteId } from '../../../common/UserManager/UserManager';
import CustomDatePicker from './CustomDatePicker';
import Input from '../../../components/core/Input/Input';
import Loader from '../../../components/site/Loader/Loader';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

export class Invoices extends Component {
    static propTypes = {
        localization: PropTypes.objectOf(PropTypes.string),
        invoicesDetails: PropTypes.objectOf(PropTypes.any),
        createClaimData: PropTypes.objectOf(PropTypes.any),
        deviceType: PropTypes.objectOf(PropTypes.bool),
        commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        message: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    };

    static defaultProps = {
        localization: {},
        invoicesDetails: {},
        createClaimData: {},
        deviceType: {},
        commonLocalization: {}
    };

    constructor(props) {
        super(props);
        this.mobileButtonsCardRef = React.createRef();
        this.currency = getCurrency();
        this.siteId = getSiteId();
        this.state = {
            showModal: false,
            showAccountStatementModal: false,
            selectedInvoices: [],
            invoiceNumberToDownload: '',
            fillterShow: false,
            fillterStatusValue: '',
            showAdvancedFilters: false,
            statusValue: [],
            statusOptions: [],
            timePeriodValue: 'lastMonth',
            timePeriodOptions: [],
            customerIdValue: [],
            customerIdOptions: [],
            documentTypeValue: null,
            documentTypeOptions: [],
            billNoValue: null,
            priceFromValue: null,
            priceToValue: null,
            showCustomInvoicePeriodModal: false,
            customDateError: false,
            dateError: false,
            priceError: false,
            displayCalendarNote: false,
            startDate: new Date(),
            endDate: new Date(),
            formattedStartDate: '',
            formattedEndDate: '',
            customFormattedStateDate: '',
            customFormattedEndDate: '',
            selectedOptionsStatus: [],
            selectedOptionsDocumentType: [],
            showMobileButtonsCard: false,
            resetFiltersPossible: false
        };
        this.invoicePDF = 'invoice';
        this.invoiceZIP = 'invoices';
        this.initialFiltersSet = false;
    }

    componentDidMount() {
        const { embeded, actions } = this.props;
        if (!embeded) {
            actions.getPageContent({ pageId: PAGE_ID.INVOICES });
        } else {
            actions.getInvoicesDetails({});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { invoicePDFData, invoiceZIPData, localization, payerCustomers, invoicePageMounted } = this.props;

        // If the initial filters are set, do nothing. If not, set them once we have access to the customerIdOptions and statusOptions
        if (prevProps.localization !== localization || prevProps.invoicePageMounted !== invoicePageMounted) {
            // Initialize the invoice filters options for the first time
            this.updateInvoiceFiltersOptions(localization);
            // Initialize custom date range to 1 month ago by default
            const dateRange = this.getDateRange(1);
            // Initialize the statue value to open and time period value to last month by default
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                statusValue: ['OPEN', 'OVERDUE'],
                selectedOptionsStatus: [
                    {
                        value: 'OPEN',
                        label: localization[INVOICES_L10N.FILTER_STATUS_OPEN]
                    },
                    {
                        value: 'OVERDUE',
                        label: localization[INVOICES_L10N.FILTER_STATUS_OVERDUE]
                    }
                ],
                formattedStartDate: dateRange.startDateString,
                formattedEndDate: dateRange.endDateString
            });
        }

        if (prevProps.localization !== localization && prevProps.payerCustomers !== payerCustomers) {
            this.updateCustomerIdOptions();
        }

        // Handle downloading a single PDF file
        if (prevProps.invoicePDFData !== invoicePDFData) {
            this.handleFileDownload(invoicePDFData);
        }

        // Handle downloading a ZIP file
        if (prevProps.invoiceZIPData !== invoiceZIPData) {
            const filename = `invoices.zip`;

            const link = document.createElement('a');
            link.href = invoiceZIPData; // this is the blobUrl from Redux
            link.download = filename;
            link.click();

            window.URL.revokeObjectURL(invoiceZIPData);
        }

        if (prevProps.localization !== localization) {
            this.updateInvoiceFiltersOptions(this.props.localization);
        }

        if (
            prevProps.localization !== this.props.localization ||
            prevProps.payerCustomers !== this.props.payerCustomers
        ) {
            this.updateCustomerIdOptions();
        }
    }

    componentWillUnmount() {
        this.initialFiltersSet = false;
        this.props.actions.resetInvoiceDetails();
        clearTimeout(this.blurTimeout);
    }

    getLocale = () => {
        const lang = getLocalization()[LOGIN_INFO.LANG];
        switch (lang) {
            case 'cs':
                return cs;
            case 'ro':
                return ro;
            case 'hu':
                return hu;
            case 'bg':
                return bg;
            case 'hr':
                return hr;
            case 'uk':
                return uk;
            case 'sk':
                return sk;
            case 'el':
                return el;
            case 'sl':
                return sl;
            case 'de':
            case 'de_AT':
                return de;
            case 'ru':
            case 'ru_BY':
                return ru;
            case 'pl':
                return pl;
            case 'mk':
                return mk;
            case 'sh':
                return sr;
            case 'sq':
                return albanianLocale;
            case 'fr':
                return fr;
            case 'lv':
                return lv;
            case 'lt':
                return lt;
            case 'et':
                return et;
            default:
                return enUS;
        }
    };

    handleFileDownload = invoicePDFData => {
        const filename = this.state.invoiceNumberToDownload
            ? `${this.state.invoiceNumberToDownload}.pdf`
            : 'invoice.pdf';

        const { userAgent } = navigator;
        if (/android/i.test(userAgent)) {
            // Android platform
            this.downloadOnAndroid(invoicePDFData);
        } else {
            // Web platform
            this.downloadOnWeb(invoicePDFData, filename);
        }
    };

    downloadOnWeb = (blobData, filename) => {
        const link = document.createElement('a');
        link.href = blobData;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(link.href);
    };

    downloadOnAndroid = async blobData => {
        if (blobData) {
            let blobInfo;
            try {
                const response = await axios.get(blobData, {
                    responseType: 'blob'
                });
                blobInfo = response.data;
            } catch (error) {
                console.error('Failed to fetch the Blob from the URL.', error);
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(blobInfo);
            reader.onloadend = () => {
                const base64data = reader.result.split(',')[1]; // Get the Base64 data
                if (window.Android && window.Android.getBase64FromBlobData) {
                    window.Android.getBase64FromBlobData(base64data);
                } else {
                    console.error('Android interface not found');
                }
            };
        }
    };

    handleCreateClaim = () => {
        const { actions, invoicesDetails } = this.props;
        const { selectedInvoices } = this.state;
        const invoiceNumbers = selectedInvoices.map(item => item.billNo);
        const amounts = selectedInvoices.map(item => formatHungaryTousends(item.amount));
        const payload = {
            data:
                selectedInvoices.length > 0
                    ? {
                          invoiceNumbers,
                          amounts,
                          currency: _get(invoicesDetails, 'totalCurrency', '')
                      }
                    : {}
        };
        actions.createClaim(payload);
        this.setState({ showModal: true, showMobileButtonsCard: false });
    };

    onClose = () => {
        this.setState({ showModal: false });
    };

    onStatusChangeHandler = option => {
        this.setState(prevState => {
            let newStatusValue;
            if (option.value === 'SELECTALL') {
                // If "selectAll" is selected and it's already in the state, clear all selections
                // Otherwise, select all options
                newStatusValue =
                    prevState.statusValue.length >= 3
                        ? ['OPEN', 'OVERDUE']
                        : prevState.statusOptions.map(opt => opt.value);
            } else {
                // If the selected option is already in the state, remove it unless it's the last remaining item and that item is OPEN
                // Otherwise, add it to the state
                newStatusValue = prevState.statusValue.includes(option.value)
                    ? prevState.statusValue.filter(value => value !== option.value).length === 0
                        ? ['OPEN', 'OVERDUE']
                        : prevState.statusValue.filter(value => value !== option.value)
                    : [...prevState.statusValue, option.value];
            }
            // remove selectAll before making api call
            return { statusValue: newStatusValue.filter(opt => opt !== 'SELECTALL'), resetFiltersPossible: true };
        });
    };

    onDocumentTypeChangeHandler = option => {
        const { documentTypeOptions } = this.state;
        this.setState(prevState => {
            let newDocumentTypeValue;
            if (option.value === 'SELECTALL') {
                // If "selectAll" is selected and it's already in the state, clear all selections
                // Otherwise, select all document type options
                newDocumentTypeValue =
                    prevState.documentTypeValue && prevState.documentTypeValue.length >= 4
                        ? null
                        : documentTypeOptions.map(opt => opt.value);
            } else {
                // If the selected option is already in the state, remove it unless it's the last remaining item and that item is OPEN
                // Otherwise, add it to the state
                newDocumentTypeValue =
                    prevState.documentTypeValue && prevState.documentTypeValue.includes(option.value)
                        ? prevState.documentTypeValue.filter(value => value !== option.value)
                        : !prevState.documentTypeValue
                        ? [option.value]
                        : [...prevState.documentTypeValue, option.value];
            }
            // remove selectAll before making api call
            return {
                documentTypeValue:
                    newDocumentTypeValue && newDocumentTypeValue.length > 0
                        ? newDocumentTypeValue.filter(opt => opt !== 'SELECTALL')
                        : null,
                resetFiltersPossible: true
            };
        });
    };

    // Time Period Util functions

    formatDate = dateString => {
        const date = new Date(dateString);
        return format(date, 'yyyy-MM-dd');
    };

    formatCustomDate = dateString => {
        const date = new Date(dateString);
        return format(date, 'dd/MM/yyyy');
    };

    compareDates = (startDate, endDate) => {
        return startDate <= endDate;
    };

    isWithinOneYearFromToday = dateFrom => {
        const givenDate = new Date(dateFrom);
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

        return givenDate > oneYearAgo;
    };

    getDateRange = monthsAgo => {
        const currentDate = new Date();
        const startDate = subMonths(currentDate, monthsAgo);

        const startDateString = this.formatDate(startDate);
        const endDateString = this.formatDate(currentDate); // End date is today's date

        return { startDateString, endDateString };
    };

    handleStartDate = date => {
        const { deviceType } = this.props;
        const formattedDate = this.formatDate(date);
        if (deviceType.isMobile) {
            this.setState({
                startDate: date,
                formattedStartDate: formattedDate,
                customDateError: false,
                dateError: false
            });
            return;
        }
        this.setState({
            startDate: date,
            formattedStartDate: formattedDate,
            customDateError: false,
            dateError: false
        });
    };

    handleEndDate = date => {
        const { deviceType } = this.props;
        const formattedDate = this.formatDate(date);
        if (deviceType.isMobile) {
            this.setState({
                endDate: date,
                formattedEndDate: formattedDate,
                customDateError: false,
                dateError: false
            });
            return;
        }
        this.setState({
            endDate: date,
            formattedEndDate: formattedDate,
            customDateError: false,
            dateError: false
        });
    };

    // Calculate the time period for the custom date range everytime the dropdown changesr
    handleCalculateInvoicePeriod = () => {
        const { timePeriodValue, startDate, endDate, formattedStartDate, formattedEndDate } = this.state;
        if (timePeriodValue === 'lastMonth') {
            const dateRange = this.getDateRange(1);
            this.setState({
                formattedStartDate: dateRange.startDateString,
                formattedEndDate: dateRange.endDateString,
                customFormattedStateDate: '',
                customFormattedEndDate: '',
                dateError: false,
                customDateError: false
            });
        } else if (timePeriodValue === 'last3Months') {
            const dateRange = this.getDateRange(3);
            this.setState({
                formattedStartDate: dateRange.startDateString,
                formattedEndDate: dateRange.endDateString,
                customFormattedStateDate: '',
                customFormattedEndDate: '',
                dateError: false,
                customDateError: false
            });
        } else if (timePeriodValue === 'last6Months') {
            const dateRange = this.getDateRange(6);
            this.setState({
                formattedStartDate: dateRange.startDateString,
                formattedEndDate: dateRange.endDateString,
                customFormattedStateDate: '',
                customFormattedEndDate: '',
                dateError: false,
                customDateError: false
            });
        } else if (timePeriodValue === 'last12Months') {
            const dateRange = this.getDateRange(12);
            this.setState({
                formattedStartDate: dateRange.startDateString,
                formattedEndDate: dateRange.endDateString,
                customFormattedStateDate: '',
                customFormattedEndDate: '',
                dateError: false,
                customDateError: false
            });
        } else if (timePeriodValue === 'customSelected') {
            const customFormattedStateDate = this.formatCustomDate(formattedStartDate);
            const customFormattedEndDate = this.formatCustomDate(formattedEndDate);

            if (this.compareDates(startDate, endDate) === false) {
                this.setState({
                    dateError: true,
                    customDateError: false
                });
                return;
            }
            if (!formattedStartDate || !formattedEndDate) {
                this.setState({
                    dateError: true,
                    customDateError: false
                });
                return;
            }
            if (this.isWithinOneYearFromToday(startDate) === false) {
                this.setState({
                    customDateError: true,
                    dateError: false
                });
                return;
            }

            this.setState({
                customFormattedStateDate,
                customFormattedEndDate
            });
        }

        this.setState({
            showCustomInvoicePeriodModal: false
        });
    };

    // Set time period values in state
    onTimePeriodChangeHandler = e => {
        if (e === 'customSelected') {
            this.setState({
                timePeriodValue: e,
                formattedStartDate: this.formatDate(new Date()),
                formattedEndDate: this.formatDate(new Date()),
                showCustomInvoicePeriodModal: true,
                resetFiltersPossible: true
            });
            return;
        }
        this.setState(
            {
                timePeriodValue: e,
                resetFiltersPossible: true
            },
            () => {
                this.handleCalculateInvoicePeriod();
            }
        );
    };

    // onChange of the bill # input field
    onBillNoChangeHandler = e => {
        this.setState({
            billNoValue: e.target.value,
            resetFiltersPossible: true
        });
    };

    // onChange of the price from input field
    onPriceFromChangeHandler = e => {
        // Prevent the user from entering any non-numeric characters. We also allow negative numbers here.
        const regex = /^-?[0-9]*(\.[0-9]+)?$/;
        if (e.target.value === '' || regex.test(e.target.value)) {
            this.setState({
                priceFromValue: e.target.value === '' ? null : e.target.value,
                priceError: false,
                resetFiltersPossible: true
            });
        }
    };

    // onChange of the price to input field
    onPriceToChangeHandler = e => {
        // Prevent the user from entering any non-numeric characters. We also allow negative numbers here.
        const regex = /^-?[0-9]*(\.[0-9]+)?$/;
        if (e.target.value === '' || regex.test(e.target.value)) {
            this.setState({
                priceToValue: e.target.value === '' ? null : e.target.value,
                priceError: false,
                resetFiltersPossible: true
            });
        }
    };

    // Style the status options in the invoice table
    getStatusClassNames = status => {
        const { localization } = this.props;
        const statusClassNameMap = {
            [localization[INVOICES_L10N.FILTER_STATUS_OPEN]]: 'statusOptionOpen',
            [localization[INVOICES_L10N.FILTER_STATUS_PAID]]: 'statusOptionPaid',
            [localization[INVOICES_L10N.FILTER_STATUS_OVERDUE]]: 'statusOptionOverdue'
        };

        return cx(statusClassNameMap[status] || '');
    };

    // We also remove the customSelected option from the dropdown if it exists once they click on the dropdown again.
    removeCustomSelectedOption = () => {
        const { timePeriodValue, showCustomInvoicePeriodModal } = this.state;
        if (timePeriodValue === 'customSelected' && !showCustomInvoicePeriodModal) {
            this.setState({
                timePeriodValue: 'lastMonth'
            });
        }
    };

    // Set customer id values in state
    onCustomerIdChangeHandler = option => {
        if (option === 'all') {
            this.setState({
                customerIdValue: [],
                resetFiltersPossible: true
            });
            return;
        }
        this.setState({
            customerIdValue: [option],
            resetFiltersPossible: true
        });
    };

    filterInvoicesAction = () => {
        const { actions } = this.props;
        const {
            statusValue,
            formattedStartDate,
            formattedEndDate,
            customerIdValue,
            documentTypeValue,
            billNoValue,
            priceFromValue,
            priceToValue,
            dateError,
            customDateError
        } = this.state;

        if (dateError || customDateError) return;

        let scrubbedPriceFrom;
        let scrubbedPriceTo;

        if (priceFromValue !== null) {
            scrubbedPriceFrom = Number(priceFromValue);
        }

        if (priceToValue !== null) {
            scrubbedPriceTo = Number(priceToValue);
        }

        if (
            (priceFromValue || priceToValue) &&
            (priceFromValue === null ||
                priceToValue === null ||
                scrubbedPriceFrom >= scrubbedPriceTo ||
                (!priceFromValue && !priceToValue))
        ) {
            this.setState({
                priceError: true
            });
            return;
        }
        const payload = {
            data: {
                statuses: statusValue,
                periodFrom: formattedStartDate,
                periodTo: formattedEndDate,
                customerIDs: customerIdValue
            }
        };
        // We only pass these props to the payload if the user has selected any
        if (documentTypeValue) {
            payload.data.documentTypes = documentTypeValue;
        }

        if (billNoValue) {
            payload.data.bill = billNoValue;
        }

        if (priceFromValue !== null) {
            payload.data.priceFrom = scrubbedPriceFrom;
        }

        if (priceToValue !== null) {
            payload.data.priceTo = scrubbedPriceTo;
        }

        actions.getFilterInvoicesDetails(payload);
        this.setState({ fillterShow: false });
    };

    resetFilters = () => {
        if (this.state.resetFiltersPossible) {
            const { actions, localization } = this.props;
            const dateRange = this.getDateRange(1);
            this.setState(
                {
                    statusValue: ['OPEN', 'OVERDUE'],
                    timePeriodValue: 'lastMonth',
                    customerIdValue: [],
                    documentTypeValue: null,
                    billNoValue: null,
                    dateError: false,
                    priceError: false,
                    customDateError: false,
                    priceFromValue: null,
                    priceToValue: null,
                    startDate: new Date(),
                    endDate: new Date(),
                    formattedStartDate: dateRange.startDateString,
                    formattedEndDate: dateRange.endDateString,
                    customFormattedStateDate: '',
                    customFormattedEndDate: '',
                    selectedInvoices: [],
                    selectedOptionsStatus: [
                        {
                            value: 'OPEN',
                            label: localization[INVOICES_L10N.FILTER_STATUS_OPEN]
                        },
                        {
                            value: 'OVERDUE',
                            label: localization[INVOICES_L10N.FILTER_STATUS_OVERDUE]
                        }
                    ],
                    selectedOptionsDocumentType: [],
                    showAdvancedFilters: false,
                    resetFiltersPossible: false
                },
                () => {
                    this.initialFiltersSet = false;
                    const { statusValue, formattedStartDate, formattedEndDate, customerIdValue } = this.state;
                    const payload = {
                        data: {
                            statuses: statusValue,
                            periodFrom: formattedStartDate,
                            periodTo: formattedEndDate,
                            customerIDs: customerIdValue
                        }
                    };
                    actions.getFilterInvoicesDetails(payload);
                }
            );
        }
    };

    onDetailsHandler = (e, id) => {
        e.preventDefault();
        const listItem = this[id].current;
        if (listItem) {
            listItem.classList.toggle('Invoices-expandedListItem');
        }
    };

    handleCheckbox = row => {
        const { selectedInvoices } = this.state;
        const isChecked = selectedInvoices.some(item => item.id === row.id);
        if (isChecked) {
            const filteredList = selectedInvoices.filter(item => item.id !== row.id);
            this.setState({ selectedInvoices: filteredList });
        } else {
            this.setState(prevState => ({
                selectedInvoices: [...prevState.selectedInvoices, row]
            }));
        }
    };

    handleFillter = () => {
        const { fillterShow } = this.state;
        this.setState({ fillterShow: !fillterShow });
    };

    handleSearchFillter = () => {
        const { fillterStatusValue, fillterTimePeriodValue } = this.state;
        this.setState({ statusValue: fillterStatusValue, timePeriodValue: fillterTimePeriodValue }, () => {
            this.filterInvoicesAction();
            this.modalOnClose();
        });
    };

    handleRadioButton = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    modalOnClose = () => {
        const { statusValue, timePeriodValue } = this.state;
        this.setState({ fillterShow: false, fillterStatusValue: statusValue, fillterTimePeriodValue: timePeriodValue });
    };

    handleCloseAccountStatement = () => {
        this.setState({ showAccountStatementModal: false });
    };

    handleCloseCustomInvoiceModal = () => {
        this.setState({
            timePeriodValue: 'lastMonth',
            dateError: false,
            customDateError: false,
            showCustomInvoicePeriodModal: false
        });
    };

    // If there's more than one invoice selected, we allow the download zip button. Otherwise, we only let them click on individual download buttons
    handleDownloadPDF = row => {
        const params = new URLSearchParams(row.pdfUrl.split('?')[1]);
        const parsedInvoiceArray = [
            {
                invoiceNumber: row.billNo,
                fiscalYear: params.get('fy'),
                documentNumber: params.get('dn')
            }
        ];
        this.setState({ selectedInvoices: [], invoiceNumberToDownload: params.get('dn') }, () => {
            this.props.actions.getInvoicePDF({ selectedInvoices: parsedInvoiceArray });
        });
    };

    handleDownloadZip = () => {
        const { selectedInvoices } = this.state;
        const newSelectedInvoicesArray = selectedInvoices
            .filter(invoice => {
                return invoice.pdfActive && invoice.pdfUrl;
            })
            .map(invoice => {
                const params = new URLSearchParams(invoice.pdfUrl.split('?')[1]);
                return {
                    documentNumber: params.get('dn'),
                    fiscalYear: params.get('fy'),
                    invoiceNumber: invoice.billNo
                };
            });

        this.props.actions.getInvoicePDF({ selectedInvoices: newSelectedInvoicesArray });
        this.setState({ selectedInvoices: [], showMobileButtonsCard: false });
    };

    updateCustomerIdOptions = () => {
        const { localization, payerCustomers } = this.props;
        if (localization && (!payerCustomers || payerCustomers.length === 0)) {
            this.setState({
                customerIdOptions: [{ value: 'all', label: localization[INVOICES_L10N.FILTER_CUSTOMER_ALL] }]
            });
        } else if (localization && payerCustomers.length > 0) {
            const newArray = (payerCustomers || []).map(customer => {
                let label = customer.name;
                if (label.length > 25) {
                    label = `${label.substring(0, 25)}...`;
                }
                return { value: customer.id, label };
            });
            this.setState({
                customerIdOptions: [
                    { value: 'all', label: localization[INVOICES_L10N.FILTER_CUSTOMER_ALL] },
                    ...newArray
                ]
            });
        }
    };

    handleMobileButtonsCardBlur = () => {
        this.blurTimeout = setTimeout(() => {
            if (
                this.mobileButtonsCardRef.current &&
                !this.mobileButtonsCardRef?.current.contains(document.activeElement)
            ) {
                this.setState({
                    showMobileButtonsCard: false
                });
            }
        }, 0);
    };

    handleMobileButtonsCardMouseDown = () => {
        clearTimeout(this.blurTimeout);
    };

    updateInvoiceFiltersOptions(localization) {
        if (localization) {
            this.setState({
                statusOptions: [
                    { value: 'SELECTALL', label: localization[INVOICES_L10N.FILTER_STATUS_SELECT_ALL] },
                    { value: 'OPEN', label: localization[INVOICES_L10N.FILTER_STATUS_OPEN] },
                    { value: 'OVERDUE', label: localization[INVOICES_L10N.FILTER_STATUS_OVERDUE] },
                    { value: 'PAID', label: localization[INVOICES_L10N.FILTER_STATUS_PAID] }
                ],
                timePeriodOptions: [
                    { value: 'lastMonth', label: localization[INVOICES_L10N.FILTER_PERIOD_LAST_MONTH] },
                    { value: 'last3Months', label: localization[INVOICES_L10N.FILTER_PERIOD_LAST_3_MONTHS] },
                    { value: 'last6Months', label: localization[INVOICES_L10N.FILTER_PERIOD_LAST_6_MONTHS] },
                    { value: 'last12Months', label: localization[INVOICES_L10N.FILTER_PERIOD_LAST_12_MONTHS] },
                    { value: 'customSelected', label: localization[INVOICES_L10N.FILTER_PERIOD_CUSTOM] }
                ],
                documentTypeOptions: [
                    { value: 'SELECTALL', label: localization[INVOICES_L10N.FILTER_STATUS_SELECT_ALL] },
                    { value: 'INVOICE', label: localization[INVOICES_L10N.FILTER_DOCUMENT_TYPE_INVOICE] },
                    { value: 'PAYMENT', label: localization[INVOICES_L10N.FILTER_DOCUMENT_TYPE_PAYMENT] },
                    { value: 'CREDIT_MEMO', label: localization[INVOICES_L10N.FILTER_DOCUMENT_TYPE_CREDIT_MEMO] },
                    { value: 'OTHER', label: localization[INVOICES_L10N.FILTER_DOCUMENT_TYPE_OTHER] }
                ]
            });
        }
    }

    renderTableHeaderRow = (value = '', styleName, symbol = '') => {
        return (
            <div className={cx('tableCell', styleName, 'column-heading')}>
                <p>{`${value} ${symbol}`}</p>
            </div>
        );
    };

    renderTableContentRow = (value = '', styleName, contentStyle) => {
        return (
            <div className={cx('tableCell', styleName)}>
                <div className={cx('tableCellContent', contentStyle)}>{value}</div>
            </div>
        );
    };

    renderTableRow = (value = '', l10nValue = '', styleName, contentStyle, symbol = '') => {
        return (
            <div className={cx('tableCell', styleName)} style={{ paddingBottom: this.siteId === 'bg' && styleName === 'amountCell' ? 0 : "10px"}}>
                <div className={cx('tableCellHeading')}>{`${l10nValue} ${symbol}`}</div>
                <div className={cx('tableCellContent', contentStyle)}>
                    <p className={cx('tableCellText')}>{value}</p>
                </div>
            </div>
        );
    };

    // Mobile filters modal
    renderMobileFilterContent = () => {
        const {
            fillterShow,
            statusValue,
            statusOptions,
            selectedOptionsStatus,
            timePeriodOptions,
            timePeriodValue,
            startDate,
            endDate,
            displayCalendarNote,
            dateError,
            customDateError,
            customerIdValue,
            customerIdOptions,
            documentTypeOptions,
            documentTypeValue,
            selectedOptionsDocumentType,
            billNoValue,
            priceFromValue,
            priceToValue,
            priceError
        } = this.state;
        const { localization, deviceType } = this.props;
        return (
            <div className={cx('mobileFilterModal')}>
                {fillterShow && (
                    <Modal
                        automationId='at-filter-overlay'
                        showModal={fillterShow}
                        modalTheme={cx('mobileInvoiceModal', deviceType.isMobile && 'mobileuserThemeModal')}
                        modalTitleTheme={cx('modalTitleTheme')}
                        themeContent={cx('themeContent')}
                        themeClose={cx('themeClose')}
                        themeModalBlock={cx('themeModalBlock')}
                        onClose={this.modalOnClose}
                    >
                        <div className={cx('mobileFiltersModalContainer')}>
                            <p className={cx('searchInvoicesFont')}>{localization[INVOICES_L10N.SEARCH_INVOICES]}</p>
                            {/* Mobile Status Dropdown */}
                            <div className={cx('clearfix', 'float-left', 'dropDownContainer')}>
                                <span className={cx('float-left', 'selectTitle')}>
                                    {localization[INVOICES_L10N.STATUS]}
                                </span>
                                <CustomSelect
                                    placeholder={localization[INVOICES_L10N.FILTER_STATUS_OPEN]}
                                    defaultOption={[
                                        {
                                            value: 'OPEN',
                                            label: localization[INVOICES_L10N.FILTER_STATUS_OPEN]
                                        },
                                        {
                                            value: 'OVERDUE',
                                            label: localization[INVOICES_L10N.FILTER_STATUS_OVERDUE]
                                        }
                                    ]}
                                    value={statusValue}
                                    options={statusOptions.map(option => ({
                                        ...option,
                                        className:
                                            /* eslint-disable */
                                            option.value === 'OPEN'
                                                ? 'statusOptionOpen'
                                                : option.value === 'OVERDUE'
                                                ? 'statusOptionOverdue'
                                                : option.value === 'PAID'
                                                ? 'statusOptionPaid'
                                                : ''
                                    }))}
                                    onChangeFunc={this.onStatusChangeHandler}
                                    selectedOptions={selectedOptionsStatus}
                                    setSelectedOptions={val => {
                                        this.setState({ selectedOptionsStatus: val });
                                    }}
                                />
                            </div>
                            {/* Mobile Time Period Dropdown */}
                            <div className={cx('clearfix', 'float-left', 'dropDownContainer')}>
                                <span className={cx('float-left', 'selectTitle')}>
                                    {localization[INVOICES_L10N.TIME_PERIOD_TITLE]}
                                </span>
                                <div
                                    className={cx('timePeriodContainerMobile')}
                                    style={{ paddingBottom: (dateError || customDateError) && '30px' }}
                                >
                                    {timePeriodOptions.map(period => {
                                        return (
                                            <RadioButton
                                                theme={cx('customRadio')}
                                                name='fillterTimePeriodValue'
                                                checked={timePeriodValue === period.value}
                                                onChange={() => {
                                                    this.onTimePeriodChangeHandler(period.value);
                                                }}
                                                labelText={period.label}
                                                customCheckMark={cx('checkmark')}
                                                value={period.value}
                                            />
                                        );
                                    })}
                                    {timePeriodValue === 'customSelected' && (
                                        <div className={cx('custom-date-container')}>
                                            <div style={{ position: 'relative', width: '100%' }}>
                                                <p className={cx('dropdown-title')}>
                                                    {localization &&
                                                        localization[INVOICES_L10N.CUSTOM_POPUP_START_DATE]}
                                                </p>
                                                <DatePicker
                                                    locale={this.getLocale()}
                                                    showPopperArrow={false}
                                                    popperClassName={cx('cool-picker')}
                                                    customInput={
                                                        <CustomDatePicker
                                                            dateError={dateError}
                                                            customDateError={customDateError}
                                                        />
                                                    }
                                                    dateFormat='dd/MM/yyyy'
                                                    calendarMessage='Select a date'
                                                    selected={startDate}
                                                    onChange={date => this.handleStartDate(date)}
                                                    withPortal
                                                    calendarClassName='custom-portal'
                                                    onCalendarOpen={() => {
                                                        this.setState({
                                                            displayCalendarNote: true
                                                        });
                                                        const currentMonthElement = document.querySelector(
                                                            '.react-datepicker__current-month'
                                                        );
                                                        if (currentMonthElement) {
                                                            currentMonthElement.style.display = 'flex';
                                                            currentMonthElement.style.alignItems = 'center';
                                                            currentMonthElement.style.justifyContent = 'center';
                                                            currentMonthElement.style.width = 'auto';
                                                            currentMonthElement.style.margin = '0';
                                                            currentMonthElement.style.padding = '10px 0';
                                                            currentMonthElement.style.flexDirection = 'column';
                                                        }
                                                        const previousMonthElement = document.querySelector(
                                                            '.react-datepicker__navigation--previous'
                                                        );
                                                        if (previousMonthElement) {
                                                            previousMonthElement.style.top = '15px';
                                                        }
                                                        const nextMonthElement = document.querySelector(
                                                            '.react-datepicker__navigation--next'
                                                        );
                                                        if (nextMonthElement) {
                                                            nextMonthElement.style.top = '15px';
                                                            nextMonthElement.style.border = 'solid #000';
                                                            nextMonthElement.style.borderWidth = '2px 0px 0px 2px';
                                                            nextMonthElement.style.transform = 'rotate(135deg)';
                                                        }
                                                    }}
                                                    onCalendarClose={() => {
                                                        this.setState({
                                                            displayCalendarNote: false
                                                        });
                                                    }}
                                                >
                                                    {displayCalendarNote && (
                                                        <p className={cx('calendar-note')}>
                                                            {localization &&
                                                                localization[
                                                                    ACCOUNT_STATEMENTS_L10N
                                                                        .TIME_PERIOD_CUSTOM_PERIOD_NOTE
                                                                ]}
                                                        </p>
                                                    )}
                                                </DatePicker>
                                                {dateError && (
                                                    <p className={cx('date-error-message')} style={{ bottom: '-35px' }}>
                                                        {localization &&
                                                            localization[
                                                                ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_ERROR_MESSAGE
                                                            ]}
                                                    </p>
                                                )}
                                                {customDateError && (
                                                    <p className={cx('date-error-message')} style={{ bottom: '-40px' }}>
                                                        {localization &&
                                                            localization[
                                                                ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_CUSTOM_PERIOD_ERROR
                                                            ]}
                                                    </p>
                                                )}
                                            </div>
                                            <span className={cx('date-picker-divider')}>-</span>
                                            <div style={{ position: 'relative', width: '100%' }}>
                                                <p className={cx('dropdown-title')}>
                                                    {localization && localization[INVOICES_L10N.CUSTOM_POPUP_END_DATE]}
                                                </p>
                                                <DatePicker
                                                    locale={this.getLocale()}
                                                    showPopperArrow={false}
                                                    popperClassName={cx('cool-picker')}
                                                    customInput={
                                                        <CustomDatePicker
                                                            dateError={dateError}
                                                            customDateError={customDateError}
                                                        />
                                                    }
                                                    dateFormat='dd/MM/yyyy'
                                                    calendarMessage='Select a date'
                                                    selected={endDate}
                                                    onChange={date => this.handleEndDate(date)}
                                                    withPortal
                                                    calendarClassName='custom-portal'
                                                    onCalendarOpen={() => {
                                                        this.setState({
                                                            displayCalendarNote: true
                                                        });
                                                        const currentMonthElement = document.querySelector(
                                                            '.react-datepicker__current-month'
                                                        );
                                                        if (currentMonthElement) {
                                                            currentMonthElement.style.display = 'flex';
                                                            currentMonthElement.style.alignItems = 'center';
                                                            currentMonthElement.style.justifyContent = 'center';
                                                            currentMonthElement.style.width = 'auto';
                                                            currentMonthElement.style.margin = '0';
                                                            currentMonthElement.style.padding = '10px 0';
                                                            currentMonthElement.style.flexDirection = 'column';
                                                        }
                                                        const previousMonthElement = document.querySelector(
                                                            '.react-datepicker__navigation--previous'
                                                        );
                                                        if (previousMonthElement) {
                                                            previousMonthElement.style.top = '15px';
                                                        }
                                                        const nextMonthElement = document.querySelector(
                                                            '.react-datepicker__navigation--next'
                                                        );
                                                        if (nextMonthElement) {
                                                            nextMonthElement.style.top = '15px';
                                                            nextMonthElement.style.border = 'solid #000';
                                                            nextMonthElement.style.borderWidth = '2px 0px 0px 2px';
                                                            nextMonthElement.style.transform = 'rotate(135deg)';
                                                        }
                                                    }}
                                                    onCalendarClose={() => {
                                                        this.setState({
                                                            displayCalendarNote: false
                                                        });
                                                    }}
                                                >
                                                    {displayCalendarNote && (
                                                        <p className={cx('calendar-note')}>
                                                            {localization &&
                                                                localization[
                                                                    ACCOUNT_STATEMENTS_L10N
                                                                        .TIME_PERIOD_CUSTOM_PERIOD_NOTE
                                                                ]}
                                                        </p>
                                                    )}
                                                </DatePicker>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* Mobile Customer ID Dropdown */}
                            <div className={cx('clearfix', 'float-left', 'dropDownContainer')}>
                                <span className={cx('float-left', 'selectTitle')}>
                                    {localization[INVOICES_L10N.FILTER_CUSTOMER]}
                                </span>
                                <SelectBox
                                    automationId='at-document-id-dropdown'
                                    theme={cx('select-md')}
                                    themeSelectBox={cx('selectperiods')}
                                    arrowTheme={cx('arrowTheme')}
                                    id='timePeriod'
                                    onChange={this.onCustomerIdChangeHandler}
                                    defaultValue={customerIdValue[0]}
                                    options={customerIdOptions}
                                />
                            </div>
                            {/* Mobile Document Type Dropdown */}
                            <div className={cx('clearfix', 'float-left', 'dropDownContainer')}>
                                <span className={cx('float-left', 'selectTitle')}>
                                    {localization[INVOICES_L10N.DOCUMENT_TYPE]}
                                </span>
                                <CustomSelect
                                    placeholder={localization[INVOICES_L10N.FILTER_SELECT]}
                                    options={documentTypeOptions}
                                    value={documentTypeValue}
                                    onChangeFunc={this.onDocumentTypeChangeHandler}
                                    selectedOptions={selectedOptionsDocumentType}
                                    setSelectedOptions={val => {
                                        this.setState({ selectedOptionsDocumentType: val });
                                    }}
                                />
                            </div>
                            {/* Mobile Bill # Dropdown */}
                            <div className={cx('clearfix', 'float-left', 'monthSelect', 'dropDownContainer')}>
                                <span className={cx('float-left', 'selectTitle')}>
                                    {localization[INVOICES_L10N.BILL_NO]}
                                </span>
                                <Input
                                    type='text'
                                    className={cx('billNoInput')}
                                    automationId='at-customer-bill-number'
                                    id='customerBillNumber'
                                    onChange={this.onBillNoChangeHandler}
                                    onKeyPress={e => {
                                        if (e.key === 'e') {
                                            e.preventDefault();
                                        }
                                    }}
                                    name='customerBillNumber'
                                    value={billNoValue || ''}
                                    placeholder={localization && localization[INVOICES_L10N.BILL_NO]}
                                />
                            </div>
                            {/* Mobile Price Range Dropdowns */}
                            <div className={cx('priceRangeRow')}>
                                <div className={cx('clearfix', 'float-left', 'monthSelect', 'dropDownContainer')}>
                                    <span className={cx('float-left', 'selectTitle')}>
                                        {localization[INVOICES_L10N.FILTER_PRICE_FROM]}
                                    </span>
                                    <Input
                                        type='number'
                                        className={cx('billNoInput', priceError ? 'date-error' : '')}
                                        automationId='at-price-from'
                                        id='priceFrom'
                                        onChange={this.onPriceFromChangeHandler}
                                        onKeyPress={e => {
                                            if (e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                        name='priceFrom'
                                        value={priceFromValue ?? ''}
                                        placeholder={`0 ${this.currency?.symbol}`}
                                    />
                                </div>
                                <div className={cx('dropDownContainer')} style={{ marginTop: '35px', width: 'auto' }}>
                                    -
                                </div>
                                <div className={cx('clearfix', 'float-left', 'monthSelect', 'dropDownContainer')}>
                                    <span className={cx('float-left', 'selectTitle')}>
                                        {localization[INVOICES_L10N.FILTER_PRICE_TO]}
                                    </span>
                                    <Input
                                        type='number'
                                        className={cx('billNoInput', priceError ? 'date-error' : '')}
                                        automationId='at-price-to'
                                        id='priceTo'
                                        onChange={this.onPriceToChangeHandler}
                                        onKeyPress={e => {
                                            if (e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                        name='priceTo'
                                        value={priceToValue ?? ''}
                                        placeholder={`20 000 ${this.currency?.symbol}`}
                                    />
                                </div>
                                {priceError && (
                                    <p className={cx('date-error-message')} style={{ bottom: '-5px' }}>
                                        {localization && localization[INVOICES_L10N.FILTER_PRICE_ERROR_MESSAGE]}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className={cx('modalButtonsRow')}>
                            <Button
                                automationId='at-reset-filters-button-mobile'
                                className={cx(
                                    'resetFilterInvoicesButton',
                                    this.state.resetFiltersPossible ? '' : 'resetFilterInvoicesButtonDisabled'
                                )}
                                type='button'
                                size='Md'
                                buttonType='Secondary'
                                onClick={this.resetFilters}
                            >
                                {localization[INVOICES_L10N.BUTTON_RESET]}
                            </Button>
                            <Button
                                automationId='at-filter-invoices-button-mobile'
                                className={cx('filterInvoicesButton')}
                                type='button'
                                size='Md'
                                buttonType='Primary'
                                onClick={async () => {
                                    this.handleCalculateInvoicePeriod();
                                    await new Promise(res => {
                                        return setTimeout(() => {
                                            res();
                                        });
                                    }, 1000);
                                    this.filterInvoicesAction();
                                }}
                            >
                                {localization[INVOICES_L10N.VIEW_RESULTS]}
                            </Button>
                        </div>
                    </Modal>
                )}
            </div>
        );
    };

    renderInvoiceHeader = (row = {}) => {
        const { localization, invoicesDetails } = this.props;
        const invoicesList = _get(invoicesDetails, 'invoicesList', []);
        const iconViewList = (
            <Icon
                className={cx('accordionArrow')}
                iconType='svg'
                width='24px'
                height='24px'
                viewBox='0 0 16 16'
                name='acc-down'
            />
        );
        return (
            <div className={cx('tableHeader')}>
                {this.renderTableHeaderRow(localization[INVOICES_L10N.DOCUMENT_TYPE], 'docCell')}
                {this.renderTableHeaderRow(localization[INVOICES_L10N.STATUS], 'statusCell')}
                {this.renderTableHeaderRow(localization[INVOICES_L10N.DUE_DATE], 'dueDateCell')}
                {this.renderTableHeaderRow(localization[INVOICES_L10N.BILL_NO], 'billCell')}
                {this.renderTableHeaderRow(localization[INVOICES_L10N.INVOICE_DATE], 'dateCell')}
                {this.renderTableHeaderRow(localization[INVOICES_L10N.CUSTOMER], 'customerCell')}
                {this.renderTableHeaderRow(formatHungaryTousends(localization[INVOICES_L10N.AMOUNT]), 'amountCell')}
                <div className={cx('tableCell', 'detailsCell', 'column-heading')}>
                    <Link
                        automation-id='at-details-accordion'
                        className={cx('detailsLink')}
                        onClick={e => this.onDetailsHandler(e, `invoices-${row.id}`)}
                    >
                        {localization[INVOICES_L10N.DETAILS]} {iconViewList}{' '}
                    </Link>
                </div>
                <div className={cx('tableHeaderAll')}>
                    <p>{localization[INVOICES_L10N.TABLE_COLUMN_ALL]}</p>
                    <label
                        role='presentation'
                        onClick={() => {
                            if (this.state.selectedInvoices.length === invoicesList.length) {
                                this.setState({ selectedInvoices: [] });
                            } else {
                                this.setState({ selectedInvoices: invoicesList });
                            }

                            buttonClickEvent(
                                buttonClickEventConfigs.invoice.actions.list,
                                buttonClickEventConfigs.invoice.labels.listSelectAll,
                                buttonClickEventConfigs.invoice.categories.invoice
                            );
                        }}
                        htmlFor='selectAllCheckbox'
                        className={cx('checkbox-label')}
                    >
                        <input
                            type='checkbox'
                            className={cx('checkbox-black')}
                            id='selectAllCheckbox'
                            checked={
                                this.state.selectedInvoices.length &&
                                this.state.selectedInvoices.length === invoicesList.length
                            }
                        />
                    </label>
                </div>
            </div>
        );
    };

    renderInvoiceTabView = () => {
        const { localization } = this.props;
        return (
            <div className={cx('tablerow', 'tableHeader', 'tabletViewStyles', 'tabletHeader')}>
                {this.renderTableHeaderRow(localization[INVOICES_L10N.DUE_DATE], 'dueDateCell')}
                {this.renderTableHeaderRow(localization[INVOICES_L10N.INVOICE_DATE], 'dateCell')}
                {this.renderTableHeaderRow(localization[INVOICES_L10N.BILL_NO], 'billCell')}
                {this.renderTableHeaderRow(localization[INVOICES_L10N.CUSTOMER], 'customerCell')}
            </div>
        );
    };

    renderMobileDotMenu = () => {
        return (
            <Icon
                iconType='svg'
                width='16px'
                height='16px'
                viewBox='0 0 16 16'
                name='dotmenu'
                className={cx('dotMenuSVG')}
            />
        );
    };

    renderMobileButtonsCard = () => {
        const { localization } = this.props;
        return (
            <div tabIndex={-1} className={cx('mobileButtonsCard')} ref={this.mobileButtonsCardRef}>
                {this.props.invoicesDetails.createClaimVisibility && (
                    <div
                        role='presentation'
                        onClick={() => {
                            if (
                                this.state.selectedInvoices.length > 0 &&
                                this.props.invoicesDetails.createClaimVisibility
                            ) {
                                this.handleCreateClaim();
                            }
                        }}
                        className={cx(
                            this.state.selectedInvoices.length > 0 && this.props.invoicesDetails.createClaimVisibility
                                ? 'mobileButtonsCardOption'
                                : 'mobileButtonsCardBlockedOption'
                        )}
                    >
                        <Icon
                            className={cx(
                                this.state.selectedInvoices.length > 0 &&
                                    this.props.invoicesDetails.createClaimVisibility
                                    ? 'active-icon-create-claim'
                                    : 'inactive-icon-create-claim'
                            )}
                            iconType='svg'
                            width='24px'
                            height='24px'
                            viewBox='0 0 24 24'
                            name='create-claim-red'
                        />
                        {localization[INVOICES_L10N.CREATE_CLAIM_TITLE]}
                    </div>
                )}
                <div
                    role='presentation'
                    onClick={() => {
                        if (this.state.selectedInvoices.length > 1) {
                            this.handleDownloadZip();
                        }
                    }}
                    className={cx(
                        this.state.selectedInvoices.length > 1
                            ? 'mobileButtonsCardOption'
                            : 'mobileButtonsCardBlockedOption'
                    )}
                >
                    <Icon
                        className={cx(
                            this.state.selectedInvoices.length > 1 ? 'active-icon-download' : 'inactive-icon-download'
                        )}
                        iconType='svg'
                        width='24px'
                        height='24px'
                        viewBox='0 0 24 24'
                        name='download-red'
                    />
                    {localization[INVOICES_L10N.DOWNLOAD_BTN]}
                </div>
                <div className={cx('mobileButtonsCardDescription')}>
                    {localization[INVOICES_L10N.SELECT_FOR_DOWNLOADING_OR_CLAIMING]}
                </div>
            </div>
        );
    };

    renderBodyTabContent = (row = {}) => {
        return (
            <div className={cx('tablerow', 'layoutContent', 'tabletViewStyles', 'tabletLayoutContent', 'visibleNone')}>
                {this.renderTableContentRow(row.dueDate, 'dueDateCell', 'dueContent')}
                {this.renderTableContentRow(row.invoiceDate, 'dateCell', 'dateContent')}
                {this.renderTableContentRow(row.billNo, 'billCell', 'billContent')}
                {this.renderTableContentRow(row.customer, 'customerCell', 'customerContent')}
            </div>
        );
    };

    renderInvoiceTableLayout = (row = {}) => {
        const { localization, invoicesDetails } = this.props;
        const status = row.status || '';
        const checkboxName = `desktopCheckbox${row.id}`;
        return (
            <div key={row.id} automation-id='at-list-of-invoices' className={cx('tablerow', 'layoutContent')}>
                {this.renderTableRow(
                    row.documentType,
                    localization[INVOICES_L10N.DOCUMENT_TYPE],
                    'docCell2',
                    'docContent'
                )}
                {row.pdfActive && row.pdfUrl && (
                    <div className='Invoices-pdfLinkCell' style={{ display: 'none' }}>
                        {row.pdfUrl}
                    </div>
                )}
                {this.renderTableRow(
                    status,
                    localization[INVOICES_L10N.STATUS],
                    'statusCell',
                    this.getStatusClassNames(status)
                )}
                {this.renderTableRow(row.dueDate, localization[INVOICES_L10N.DUE_DATE], 'dueDateCell', 'dueContent')}
                {this.renderTableRow(row.billNo, localization[INVOICES_L10N.BILL_NO], 'billCell', 'billContent')}
                {this.renderTableRow(
                    row.invoiceDate,
                    localization[INVOICES_L10N.INVOICE_DATE],
                    'dateCell',
                    'dateContent'
                )}
                {this.renderTableRow(
                    row.customer,
                    localization[INVOICES_L10N.CUSTOMER],
                    'customerCell',
                    'customerContent'
                )}
                <div className={cx('bulgarianCurrencyCol')} style={{paddingbottom: this.siteId === 'bg' ? '10px' : 0}}>
                    {this.renderTableRow(
                        `${row.currency} ${formatHungaryTousends(row.amountString)}`,
                        formatHungaryTousends(localization[INVOICES_L10N.AMOUNT]),
                        'amountCell',
                        'amountContent'
                    )}
                    {this.siteId === 'bg' && (
                        <p className={cx('conversionFontNormal')}>(€ {convertBGNToEUR(row.amount).split('€')[0].trim()})</p>
                    )}
                </div>
                <div className={cx('tableCell', 'empty-cell', 'detailsCell')}>
                    <div className={cx('tableCellContent')}> &nbsp;</div>
                </div>
                <div className={cx('tableCell')}>
                    {row.pdfActive && row.pdfUrl ? (
                        <div className={cx('tableCellContent', 'downloadIcon')}>
                            <a
                                role='presentation'
                                automation-id='at-invoice-in-pdf-format'
                                onClick={() => this.handleDownloadPDF(row)}
                            >
                                <Icon
                                    automation-id='at-download-icon'
                                    className={cx('leftDownloadIcon')}
                                    iconType='svg'
                                    width='24px'
                                    height='24px'
                                    viewBox='0 0 24 24'
                                    name='download'
                                />
                            </a>
                        </div>
                    ) : (
                        <div style={{ width: '24px' }} />
                    )}
                    <div className={cx('checkboxContent')}>
                        <Checkbox
                            automation-id='at-checkbox1'
                            name={checkboxName}
                            checked={this.state.selectedInvoices.some(item => item.id === row.id)}
                            theme={cx('Checktheme')}
                            themecheckboxCheckmark={cx('themecheckboxCheckmark')}
                            onChange={() => this.handleCheckbox(row)}
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderMobileViewContent = (row = {}) => {
        const { localization } = this.props;
        const downloadIcon = (
            <Icon
                automation-id='at-download-icon'
                className={cx('downloadArrow')}
                iconType='svg'
                width='24px'
                height='24px'
                viewBox='0 0 24 24'
                name='download'
            />
        );
        return (
            <div className={cx('col12', 'sm12', 'visibleSm')}>
                <div className={cx('mobBdr')} />
                <div className={cx('downloadWrapper')}>
                    <Button
                        type='button'
                        buttonType='Default'
                        className={cx('downloadBtn')}
                        automationId='at-download-button'
                        title='downloadBtn'
                    >
                        <a role='presentation' onClick={() => this.handleDownloadPDF(row)}>
                            {localization[INVOICES_L10N.DOWNLOAD_BTN]} {downloadIcon}
                        </a>
                    </Button>
                </div>
            </div>
        );
    };

    renderDesktopLayout = () => {
        const { invoicesDetails, commonLocalization } = this.props;
        const invoicesList = _get(invoicesDetails, 'invoicesList', []);
        return (
            <div className={cx('tableContent', 'desktopLayout')}>
                <div automation-id='at-table-invoices' className={cx('invoiceTable', 'tableWrapper')}>
                    {this.renderInvoiceHeader()}
                    {invoicesList.length > 0 ? (
                        invoicesList.map((row, index) => {
                            return this.renderInvoiceTableLayout(row);
                        })
                    ) : (
                        <li automation-id='at-no-orders-found-text' className={cx('clearfix', 'noResults')}>
                            <Icon iconType='svg' width='24' height='24' viewBox='0 0 24 24' name='no-results' />{' '}
                            {commonLocalization[GENERIC_L10N.NO_RESULTS_MSG]}
                        </li>
                    )}
                </div>
            </div>
        );
    };

    renderMobileAndTabletLayout = () => {
        const { invoicesDetails, localization, commonLocalization } = this.props;
        const invoicesList = _get(invoicesDetails, 'invoicesList', []);

        return (
            <div className={cx('mobileTabletLayout')} style={{ position: 'relative' }}>
                <div className={cx('tabletDotMenuRow')}>
                    <div
                        tabIndex={-1}
                        type='button'
                        onBlur={this.handleMobileButtonsCardBlur}
                        onClick={() => {
                            this.setState(prevState => ({
                                showMobileButtonsCard: !prevState.showMobileButtonsCard
                            }));
                        }}
                        className={cx('mobileDotMenuIcon')}
                        style={{
                            border: this.state.showMobileButtonsCard ? '1px solid #D7D7D7' : '1px solid #F6F7F6'
                        }}
                    >
                        {this.renderMobileDotMenu()}
                    </div>
                </div>
                {this.state.showMobileButtonsCard && this.renderMobileButtonsCard()}
                <div className={cx('tableContent')}>
                    {invoicesList.length > 0 ? (
                        invoicesList.map(row => {
                            const iconViewList = (
                                <Icon
                                    className={cx('accordionArrow')}
                                    iconType='svg'
                                    width='24px'
                                    height='24px'
                                    viewBox='0 0 16 16'
                                    name='acc-down'
                                />
                            );
                            const ref = `invoices-${row.id}`;
                            const checkboxName = `mobileCheckbox${row.id}`;

                            this[ref] = React.createRef();
                            return (
                                <div ref={this[ref]} key={row.id} className={cx('mobileWrapper')}>
                                    <div className={cx('sm12', 'visibleSm', 'mobileContentSpace')}>
                                        <h3 className={cx('col6', 'pad0', 'mobileTitle')}>
                                            {localization[INVOICES_L10N.INVOICE_DATE]}
                                        </h3>
                                        <a className={cx('col6', 'pad0', 'mobileDate')}>
                                            {row.invoiceDate}
                                            <Checkbox
                                                automation-id='at-checkbox'
                                                name={checkboxName}
                                                checked={this.state.selectedInvoices.some(item => item.id === row.id)}
                                                theme={cx('Checktheme')}
                                                themecheckboxCheckmark={cx('themecheckboxCheckmark')}
                                                onChange={() => this.handleCheckbox(row)}
                                            />
                                        </a>
                                        <Link
                                            automation-id='at-details-accordion'
                                            className={cx('mobileDetailsLink')}
                                            onClick={e => this.onDetailsHandler(e, `invoices-${row.id}`)}
                                        >
                                            {localization[INVOICES_L10N.DETAILS]}
                                            {iconViewList}
                                        </Link>
                                    </div>
                                    <div
                                        ref={this[ref]}
                                        className={cx(
                                            'invoiceTable',
                                            'tableWrapper',
                                            'mobileActiveTable',
                                            'visibleNone'
                                        )}
                                    >
                                        {this.renderInvoiceHeader(row)}
                                        {this.renderInvoiceTableLayout(row)}
                                        {this.renderInvoiceTabView()}
                                        {this.renderBodyTabContent(row)}
                                    </div>
                                    {row.pdfActive && row.pdfUrl && this.renderMobileViewContent(row)}
                                </div>
                            );
                        })
                    ) : (
                        <li
                            automation-id='at-no-orders-found-text'
                            className={cx('orderlistItem', 'clearfix', 'noResults')}
                        >
                            <Icon iconType='svg' width='24' height='24' viewBox='0 0 24 24' name='no-results' />{' '}
                            {commonLocalization[GENERIC_L10N.NO_RESULTS_MSG]}
                        </li>
                    )}
                </div>
            </div>
        );
    };

    /**
     * @brief      Renders the page content.
     *
     * @return     { JSX }.
     */
    // eslint-disable-next-line react/sort-comp
    getContent() {
        const {
            showModal,
            showAccountStatementModal,
            showAdvancedFilters,
            selectedInvoices,
            statusOptions,
            statusValue,
            timePeriodOptions,
            timePeriodValue,
            showCustomInvoicePeriodModal,
            displayCalendarNote,
            dateError,
            priceError,
            customDateError,
            startDate,
            endDate,
            customFormattedStateDate,
            customFormattedEndDate,
            customerIdValue,
            customerIdOptions,
            documentTypeOptions,
            documentTypeValue,
            billNoValue,
            priceFromValue,
            priceToValue,
            selectedOptionsStatus,
            selectedOptionsDocumentType
        } = this.state;
        const {
            invoicesDetails,
            localization,
            deviceType,
            message,
            createClaimData = {},
            embeded,
            accountStatementEnabled
        } = this.props;
        const invoicesList = _get(invoicesDetails, 'invoicesList', []);
        const { error = {} } = message;
        const isError = !_isEmpty(error);
        return (
            <>
                {!isError &&
                !_isEmpty(invoicesDetails) &&
                !_isEmpty(localization) &&
                localization.pageId === 'ordersinformation' ? (
                    <>
                        <div className={cx('Invoiceheader')}>
                            {!embeded && <PageHeader showIcon title={localization[INVOICES_L10N.TITLE]} />}
                            <div className={cx('hiddenSm', 'w100')}>
                                <div className={cx('invoicesButtonRow')}>
                                    <div
                                        automation-id='at-invoices-total-section'
                                        className={cx('col4', 'md4', 'pad0', deviceType.isMobile && 'totalMobile')}
                                    >
                                        <span className={cx('totalLabel')}>
                                            {localization[INVOICES_L10N.INVOICES_TOTAL]}
                                        </span>{' '}
                                        <span
                                            className={cx('font-bold', 'priceLabel', 'currency')}
                                        >{`${formatHungaryTousends(_get(invoicesDetails, 'totalCurrency', ''))}`}</span>
                                        <span className={cx('font-bold', 'priceLabel')}>{`${formatHungaryTousends(
                                            _get(invoicesDetails, 'totalAmount', '')
                                        )}`}</span>
                                    </div>
                                    {accountStatementEnabled && (
                                        <Button
                                            automationId='at-create-account-state-button'
                                            className={cx('accountStatementBtn')}
                                            type='button'
                                            size='Sm'
                                            buttonType='Secondary'
                                            onClick={() => {
                                                this.setState({ showAccountStatementModal: true });
                                            }}
                                        >
                                            {localization &&
                                                localization[ACCOUNT_STATEMENTS_L10N.GENERATE_STATEMENT_BUTTON]}
                                        </Button>
                                    )}
                                </div>
                                {showAccountStatementModal && (
                                    <Modal
                                        showModal={showAccountStatementModal}
                                        onClose={() => {
                                            this.setState({ showAccountStatementModal: false });
                                        }}
                                        modalTheme={cx('accountStatementModal')}
                                        themeModalBlock={cx('accountStatementBlock')}
                                        themeContent={cx('accountStatementModalContent')}
                                        themeClose={cx('themeClose')}
                                        defaultCloseBtn
                                        title={
                                            localization &&
                                            localization[ACCOUNT_STATEMENTS_L10N.GENERATE_STATEMENT_POPUP_TITLE]
                                        }
                                    >
                                        <AccountStatement
                                            isMobile={false}
                                            onClickFunc={this.handleCloseAccountStatement}
                                        />
                                    </Modal>
                                )}
                                {/* Invoices Custom Time Period Modal */}
                                {showCustomInvoicePeriodModal && (
                                    <Modal
                                        showModal={showCustomInvoicePeriodModal}
                                        onClose={() => {
                                            this.handleCloseCustomInvoiceModal();
                                        }}
                                        modalTheme={cx('accountStatementModal')}
                                        themeModalBlock={cx('accountStatementBlock')}
                                        themeContent={cx('accountStatementModalContent')}
                                        themeClose={cx('themeClose')}
                                        defaultCloseBtn
                                        title={localization && localization[INVOICES_L10N.CUSTOM_POPUP_TITLE]}
                                    >
                                        <>
                                            <div className={cx('dropdown-container')}>
                                                <p className={cx('dropdown-title')}>
                                                    {localization &&
                                                        localization[INVOICES_L10N.CUSTOM_POPUP_DESCRIPTION]}
                                                </p>
                                                <p
                                                    className={cx('dropdown-title')}
                                                    style={{ margin: '24px 0 5px 0px' }}
                                                >
                                                    {localization && localization[INVOICES_L10N.TIME_PERIOD_TITLE]}
                                                </p>
                                                <SelectBox
                                                    automationId='account-statement-selection-dropdown'
                                                    deaultIcon
                                                    onChange={this.onTimePeriodChangeHandler}
                                                    options={timePeriodOptions}
                                                    defaultValue={timePeriodValue}
                                                    themeSelectBox={cx('time-period-dropdown')}
                                                    arrowTheme={cx('dropdown-arrow')}
                                                />
                                                {timePeriodValue === 'customSelected' && (
                                                    <div className={cx('custom-date-container')}>
                                                        <div style={{ position: 'relative' }}>
                                                            <p className={cx('dropdown-title')}>
                                                                {localization &&
                                                                    localization[INVOICES_L10N.CUSTOM_POPUP_START_DATE]}
                                                            </p>
                                                            <DatePicker
                                                                locale={this.getLocale()}
                                                                showPopperArrow={false}
                                                                popperClassName={cx('cool-picker')}
                                                                customInput={
                                                                    <CustomDatePicker
                                                                        dateError={dateError}
                                                                        customDateError={customDateError}
                                                                    />
                                                                }
                                                                dateFormat='dd/MM/yyyy'
                                                                calendarMessage='Select a date'
                                                                selected={startDate}
                                                                onChange={date => this.handleStartDate(date)}
                                                                onCalendarOpen={() => {
                                                                    this.setState({ displayCalendarNote: true });
                                                                    const currentMonthElement = document.querySelector(
                                                                        '.react-datepicker__current-month'
                                                                    );
                                                                    if (currentMonthElement) {
                                                                        currentMonthElement.style.display = 'flex';
                                                                        currentMonthElement.style.alignItems = 'center';
                                                                        currentMonthElement.style.justifyContent =
                                                                            'center';
                                                                        currentMonthElement.style.width = 'auto';
                                                                        currentMonthElement.style.margin = '0';
                                                                        currentMonthElement.style.padding = '10px 0';
                                                                        currentMonthElement.style.flexDirection =
                                                                            'column';
                                                                    }
                                                                }}
                                                                onCalendarClose={() => {
                                                                    this.setState({ displayCalendarNote: false });
                                                                }}
                                                            >
                                                                {displayCalendarNote && (
                                                                    <p className={cx('calendar-note')}>
                                                                        {localization &&
                                                                            localization[
                                                                                ACCOUNT_STATEMENTS_L10N
                                                                                    .TIME_PERIOD_CUSTOM_PERIOD_NOTE
                                                                            ]}
                                                                    </p>
                                                                )}
                                                            </DatePicker>
                                                            {dateError && (
                                                                <p className={cx('date-error-message')}>
                                                                    {localization &&
                                                                        localization[
                                                                            ACCOUNT_STATEMENTS_L10N
                                                                                .TIME_PERIOD_ERROR_MESSAGE
                                                                        ]}
                                                                </p>
                                                            )}
                                                            {customDateError && (
                                                                <p
                                                                    style={{ bottom: '-38px' }}
                                                                    className={cx('date-error-message')}
                                                                >
                                                                    {localization &&
                                                                        localization[
                                                                            ACCOUNT_STATEMENTS_L10N
                                                                                .TIME_PERIOD_CUSTOM_PERIOD_ERROR
                                                                        ]}
                                                                </p>
                                                            )}
                                                        </div>
                                                        <span className={cx('date-picker-divider')}>-</span>
                                                        <div style={{ position: 'relative' }}>
                                                            <p className={cx('dropdown-title')}>
                                                                {localization &&
                                                                    localization[INVOICES_L10N.CUSTOM_POPUP_END_DATE]}
                                                            </p>
                                                            <DatePicker
                                                                locale={this.getLocale()}
                                                                showPopperArrow={false}
                                                                popperClassName={cx('cool-picker')}
                                                                customInput={
                                                                    <CustomDatePicker
                                                                        dateError={dateError}
                                                                        customDateError={customDateError}
                                                                    />
                                                                }
                                                                dateFormat='dd/MM/yyyy'
                                                                calendarMessage='Select a date'
                                                                selected={endDate}
                                                                onChange={date => this.handleEndDate(date)}
                                                                onCalendarOpen={() => {
                                                                    this.setState({ displayCalendarNote: true });
                                                                    const currentMonthElement = document.querySelector(
                                                                        '.react-datepicker__current-month'
                                                                    );
                                                                    if (currentMonthElement) {
                                                                        currentMonthElement.style.display = 'flex';
                                                                        currentMonthElement.style.alignItems = 'center';
                                                                        currentMonthElement.style.justifyContent =
                                                                            'center';
                                                                        currentMonthElement.style.width = 'auto';
                                                                        currentMonthElement.style.margin = '0';
                                                                        currentMonthElement.style.padding = '10px 0';
                                                                        currentMonthElement.style.flexDirection =
                                                                            'column';
                                                                    }
                                                                }}
                                                                onCalendarClose={() => {
                                                                    this.setState({ displayCalendarNote: false });
                                                                }}
                                                            >
                                                                {displayCalendarNote && (
                                                                    <p className={cx('calendar-note')}>
                                                                        {localization &&
                                                                            localization[
                                                                                ACCOUNT_STATEMENTS_L10N
                                                                                    .TIME_PERIOD_CUSTOM_PERIOD_NOTE
                                                                            ]}
                                                                    </p>
                                                                )}
                                                            </DatePicker>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className={cx(
                                                    // isMobile ? 'mobile-button-row' :
                                                    'button-row'
                                                )}
                                            >
                                                <Button
                                                    automationId='invoices-cancel-button'
                                                    className={cx('cancel-button')}
                                                    type='button'
                                                    size='Md'
                                                    buttonType='Secondary'
                                                    onClick={() => {
                                                        this.handleCloseCustomInvoiceModal();
                                                    }}
                                                >
                                                    {localization &&
                                                        localization[INVOICES_L10N.CUSTOM_POPUP_CANCEL_BUTTON]}
                                                </Button>
                                                <Button
                                                    automationId='invoices-generate-button'
                                                    className={cx('generate-button')}
                                                    type='button'
                                                    size='Md'
                                                    buttonType='Primary'
                                                    onClick={() => {
                                                        this.handleCalculateInvoicePeriod();
                                                    }}
                                                >
                                                    {localization &&
                                                        localization[INVOICES_L10N.CUSTOM_POPUP_CONFIRM_BUTTON]}
                                                </Button>
                                            </div>
                                        </>
                                    </Modal>
                                )}
                                {showModal && createClaimData.isSuccess && (
                                    <Modal
                                        modalTheme={cx(
                                            'createClaimModal',
                                            deviceType.isMobile && 'mobileuserThemeModal'
                                        )}
                                        themeClose={cx('themeClose')}
                                        showModal={showModal}
                                        onClose={this.onClose}
                                    >
                                        <CreateClaim
                                            setSelectedInvoices={() => {
                                                this.setState({ selectedInvoices: [] });
                                            }}
                                            selectedInvoices={selectedInvoices}
                                            onCancel={this.onClose}
                                        />
                                    </Modal>
                                )}
                            </div>

                            <div className={cx('sm12', 'hiddenLg', 'Invoicepadtb')}>
                                <div
                                    automation-id='at-invoices-total-section'
                                    className={cx('sm7', 'totalAmt', 'visibleSm', deviceType.isMobile && 'totalMobile')}
                                >
                                    <span className={cx('totalLabel')}>
                                        {localization[INVOICES_L10N.INVOICES_TOTAL]}:
                                    </span>{' '}
                                    <span
                                        className={cx('font-bold', 'priceLabel', 'currency')}
                                    >{`${formatHungaryTousends(_get(invoicesDetails, 'totalCurrency', ''))}`}</span>
                                    <span className={cx('font-bold', 'priceLabel')}>{`${formatHungaryTousends(
                                        _get(invoicesDetails, 'totalAmount', '')
                                    )}`}</span>
                                </div>

                                <div className={cx('sm5', 'visibleSm', 'fliterBtn')}>
                                    <Button
                                        type='button'
                                        size='Sm'
                                        buttonType='Default'
                                        onClick={this.handleFillter}
                                        className={cx('mobileBtn')}
                                    >
                                        <span className={cx('float-left', 'filterLabel')}>
                                            {localization[INVOICES_L10N.FILTERS]}
                                        </span>
                                        <span className={cx('float-left', 'filterIcon')}>
                                            <Icon
                                                automation-id='at-filter-icon'
                                                iconType='svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 16 16'
                                                name='filter'
                                            />
                                        </span>
                                    </Button>
                                    <div className={cx('mobileshowFilter')}>{this.renderMobileFilterContent()}</div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={cx('selectHeader', 'hiddenSm')}
                            style={{ paddingBottom: !showAdvancedFilters && '40px' }}
                        >
                            <p className={cx('selectHeaderText')}>{localization[INVOICES_L10N.INVOICES_PAGE_TITLE]}</p>
                            <div className={cx('mainFilterContainer')}>
                                <div className={cx('mainRow')}>
                                    <div className={cx('filtersCol')}>
                                        <div className={cx('filtersRow')}>
                                            {/* Status Dropdown Desktop */}
                                            <div className={cx('clearfix', 'float-left', 'dropDownContainer')}>
                                                <span className={cx('float-left', 'selectTitle')}>
                                                    {localization[INVOICES_L10N.STATUS]}
                                                </span>
                                                <CustomSelect
                                                    placeholder={localization[INVOICES_L10N.FILTER_STATUS_OPEN]}
                                                    defaultOption={[
                                                        {
                                                            value: 'OPEN',
                                                            label: localization[INVOICES_L10N.FILTER_STATUS_OPEN]
                                                        },
                                                        {
                                                            value: 'OVERDUE',
                                                            label: localization[INVOICES_L10N.FILTER_STATUS_OVERDUE]
                                                        }
                                                    ]}
                                                    value={statusValue}
                                                    options={statusOptions.map(option => ({
                                                        ...option,
                                                        className:
                                                            /* eslint-disable */
                                                            option.value === 'OPEN'
                                                                ? 'statusOptionOpen'
                                                                : option.value === 'OVERDUE'
                                                                ? 'statusOptionOverdue'
                                                                : option.value === 'PAID'
                                                                ? 'statusOptionPaid'
                                                                : ''
                                                    }))}
                                                    onChangeFunc={this.onStatusChangeHandler}
                                                    selectedOptions={selectedOptionsStatus}
                                                    setSelectedOptions={val => {
                                                        this.setState({ selectedOptionsStatus: val });
                                                    }}
                                                    onOpen={() => {
                                                        buttonClickEvent(
                                                            buttonClickEventConfigs.invoice.actions.search,
                                                            buttonClickEventConfigs.invoice.labels.searchStatusArrow,
                                                            buttonClickEventConfigs.invoice.categories.invoice
                                                        );
                                                    }}
                                                />
                                                <p
                                                    role='presentation'
                                                    className={cx('advanced-filters')}
                                                    onClick={() => {
                                                        this.setState(prevState => ({
                                                            showAdvancedFilters: !prevState.showAdvancedFilters
                                                        }));
                                                        buttonClickEvent(
                                                            buttonClickEventConfigs.invoice.actions.search,
                                                            buttonClickEventConfigs.invoice.labels
                                                                .searchAdvancedFilters,
                                                            buttonClickEventConfigs.invoice.categories.invoice
                                                        );
                                                    }}
                                                >
                                                    {localization[INVOICES_L10N.ADVANCED_FILTERS]}
                                                    <Icon
                                                        iconType='svg'
                                                        width='24px'
                                                        height='24px'
                                                        viewBox='0 0 24 24'
                                                        name='chevron-left'
                                                        className={cx(
                                                            'flex-icon',
                                                            showAdvancedFilters
                                                                ? 'chevron-icon-up'
                                                                : 'chevron-icon-down'
                                                        )}
                                                    />
                                                </p>
                                            </div>
                                            {/* Time Period Dropdown Desktop */}
                                            <div
                                                className={cx(
                                                    'clearfix',
                                                    'float-left',
                                                    'monthSelect',
                                                    'dropDownContainer'
                                                )}
                                            >
                                                <span className={cx('float-left', 'selectTitle')}>
                                                    {localization[INVOICES_L10N.TIME_PERIOD]}
                                                </span>
                                                <SelectBox
                                                    automationId='at-time-period-dropdown'
                                                    theme={cx('select-md')}
                                                    themeSelectBox={cx('selectperiods')}
                                                    arrowTheme={cx('arrowTheme')}
                                                    id='timePeriod'
                                                    onChange={this.onTimePeriodChangeHandler}
                                                    onClick={this.removeCustomSelectedOption}
                                                    defaultValue={timePeriodValue}
                                                    options={timePeriodOptions}
                                                    onFocus={() => {
                                                        buttonClickEvent(
                                                            buttonClickEventConfigs.invoice.actions.search,
                                                            buttonClickEventConfigs.invoice.labels
                                                                .searchTimePeriodArrow,
                                                            buttonClickEventConfigs.invoice.categories.invoice
                                                        );
                                                    }}
                                                />
                                                {(customFormattedStateDate || customFormattedEndDate) && (
                                                    <div className={cx('customFormattedDates')}>
                                                        {customFormattedStateDate && <p>{customFormattedStateDate}</p>}-
                                                        {customFormattedEndDate && <p>{customFormattedEndDate}</p>}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cx('filtersCol')}>
                                        <div className={cx('filtersRow')}>
                                            {/* Desktop Customer ID Dropdown */}
                                            <div
                                                className={cx(
                                                    'clearfix',
                                                    'float-left',
                                                    'monthSelect',
                                                    'dropDownContainer'
                                                )}
                                            >
                                                <span className={cx('float-left', 'selectTitle')}>
                                                    {localization[INVOICES_L10N.FILTER_CUSTOMER]}
                                                </span>
                                                <SelectBox
                                                    automationId='at-document-id-dropdown'
                                                    theme={cx('select-md')}
                                                    themeSelectBox={cx('selectperiods')}
                                                    arrowTheme={cx('arrowTheme')}
                                                    id='timePeriod'
                                                    onChange={this.onCustomerIdChangeHandler}
                                                    defaultValue={customerIdValue[0]}
                                                    options={customerIdOptions}
                                                    onFocus={() => {
                                                        buttonClickEvent(
                                                            buttonClickEventConfigs.invoice.actions.search,
                                                            buttonClickEventConfigs.invoice.labels.searchCustomerArrow,
                                                            buttonClickEventConfigs.invoice.categories.invoice
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className={cx('filterInvoicesButtonRow')}>
                                                {/* Filter Invoices Button & Action */}
                                                <Button
                                                    automationId='at-reset-filters-button'
                                                    className={cx(
                                                        'resetFilterInvoicesButton',
                                                        this.state.resetFiltersPossible
                                                            ? ''
                                                            : 'resetFilterInvoicesButtonDisabled'
                                                    )}
                                                    type='button'
                                                    size='Sm'
                                                    buttonType='Secondary'
                                                    onClick={e => {
                                                        this.resetFilters(e);
                                                        buttonClickEvent(
                                                            buttonClickEventConfigs.invoice.actions.search,
                                                            buttonClickEventConfigs.invoice.labels.searchReset,
                                                            buttonClickEventConfigs.invoice.categories.invoice
                                                        );
                                                    }}
                                                >
                                                    {localization && localization[INVOICES_L10N.BUTTON_RESET]}
                                                </Button>
                                                <Button
                                                    automationId='at-filter-invoices-button'
                                                    className={cx('filterInvoicesButton')}
                                                    type='button'
                                                    size='Sm'
                                                    buttonType='Secondary'
                                                    onClick={e => {
                                                        this.filterInvoicesAction(e);
                                                        buttonClickEvent(
                                                            buttonClickEventConfigs.invoice.actions.search,
                                                            buttonClickEventConfigs.invoice.labels.searchViewResults,
                                                            buttonClickEventConfigs.invoice.categories.invoice
                                                        );
                                                    }}
                                                >
                                                    {localization && localization[INVOICES_L10N.VIEW_RESULTS]}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {showAdvancedFilters && (
                                    <div className={cx('mainRow')} style={{ marginTop: '16px' }}>
                                        <div className={cx('filtersCol')}>
                                            <div className={cx('filtersRow')}>
                                                {/* Document Type Desktop */}
                                                <div
                                                    className={cx(
                                                        'clearfix',
                                                        'float-left',
                                                        'monthSelect',
                                                        'dropDownContainer'
                                                    )}
                                                >
                                                    <span className={cx('float-left', 'selectTitle')}>
                                                        {localization[INVOICES_L10N.DOCUMENT_TYPE]}
                                                    </span>
                                                    <CustomSelect
                                                        placeholder={localization[INVOICES_L10N.FILTER_SELECT]}
                                                        options={documentTypeOptions}
                                                        value={documentTypeValue}
                                                        onChangeFunc={this.onDocumentTypeChangeHandler}
                                                        selectedOptions={selectedOptionsDocumentType}
                                                        setSelectedOptions={val => {
                                                            this.setState({ selectedOptionsDocumentType: val });
                                                        }}
                                                    />
                                                </div>
                                                {/* Bill # Dropdown Desktop */}
                                                <div
                                                    className={cx(
                                                        'clearfix',
                                                        'float-left',
                                                        'monthSelect',
                                                        'dropDownContainer'
                                                    )}
                                                >
                                                    <span className={cx('float-left', 'selectTitle')}>
                                                        {localization[INVOICES_L10N.BILL_NO]}
                                                    </span>
                                                    <Input
                                                        type='text'
                                                        className={cx('billNoInput')}
                                                        automationId='at-customer-bill-number'
                                                        id='customerBillNumber'
                                                        onChange={this.onBillNoChangeHandler}
                                                        onKeyPress={e => {
                                                            if (e.key === 'e') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        name='customerBillNumber'
                                                        value={billNoValue || ''}
                                                        placeholder={
                                                            localization && localization[INVOICES_L10N.BILL_NO]
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cx('filtersCol')} style={{ justifyContent: 'flex-end' }}>
                                            {/* Price Range Desktop */}
                                            <div className={cx('priceRangeRow')}>
                                                <div
                                                    className={cx(
                                                        'clearfix',
                                                        'float-left',
                                                        'monthSelect',
                                                        'dropDownContainer'
                                                    )}
                                                >
                                                    <span className={cx('float-left', 'selectTitle')}>
                                                        {localization[INVOICES_L10N.FILTER_PRICE_FROM]}
                                                    </span>
                                                    <Input
                                                        type='number'
                                                        className={cx('billNoInput', priceError ? 'date-error' : '')}
                                                        automationId='at-price-from'
                                                        id='priceFrom'
                                                        onChange={this.onPriceFromChangeHandler}
                                                        name='priceFrom'
                                                        value={priceFromValue ?? ''}
                                                        placeholder={`0 ${this.currency?.symbol}`}
                                                    />
                                                </div>
                                                <div className={cx('dropDownContainer')} style={{ marginTop: '35px' }}>
                                                    -
                                                </div>
                                                <div
                                                    className={cx(
                                                        'clearfix',
                                                        'float-left',
                                                        'monthSelect',
                                                        'dropDownContainer'
                                                    )}
                                                >
                                                    <span className={cx('float-left', 'selectTitle')}>
                                                        {localization[INVOICES_L10N.FILTER_PRICE_TO]}
                                                    </span>
                                                    <Input
                                                        type='number'
                                                        className={cx('billNoInput', priceError ? 'date-error' : '')}
                                                        automationId='at-price-to'
                                                        id='priceTo'
                                                        onChange={this.onPriceToChangeHandler}
                                                        onKeyPress={e => {
                                                            if (e.key === 'e') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        name='priceTo'
                                                        value={priceToValue ?? ''}
                                                        placeholder={`20 000 ${this.currency?.symbol}`}
                                                    />
                                                </div>
                                                {priceError && (
                                                    <p className={cx('date-error-message')}>
                                                        {localization &&
                                                            localization[INVOICES_L10N.FILTER_PRICE_ERROR_MESSAGE]}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={cx('col2', 'md3', 'sm12', 'visibleSm', 'visibleMd', 'mobileBdrBtm')}>
                            <div className={cx('mobileButtonRow')}>
                                {accountStatementEnabled && (
                                    <Button
                                        automationId='at-create-account-state-button'
                                        className={cx('accountStatementBtn')}
                                        type='button'
                                        size='Sm'
                                        buttonType='Secondary'
                                        onClick={() => {
                                            this.setState({ showAccountStatementModal: true });
                                        }}
                                    >
                                        {localization &&
                                            localization[ACCOUNT_STATEMENTS_L10N.GENERATE_STATEMENT_BUTTON]}
                                    </Button>
                                )}
                                <div
                                    tabIndex={-1}
                                    type='button'
                                    onBlur={this.handleMobileButtonsCardBlur}
                                    onClick={() => {
                                        this.setState(prevState => ({
                                            showMobileButtonsCard: !prevState.showMobileButtonsCard
                                        }));
                                    }}
                                    className={cx('mobileDotMenuIcon')}
                                    style={{
                                        border: this.state.showMobileButtonsCard
                                            ? '1px solid #D7D7D7'
                                            : '1px solid #F6F7F6'
                                    }}
                                >
                                    {this.renderMobileDotMenu()}
                                </div>
                            </div>
                            {this.state.showMobileButtonsCard && this.renderMobileButtonsCard()}
                            {showAccountStatementModal && (
                                <div className={cx('accountStatementModal')}>
                                    <div
                                        tabIndex={-1}
                                        role='button'
                                        onKeyDown={() => {
                                            this.setState({ showAccountStatementModal: false });
                                        }}
                                        onClick={() => {
                                            this.setState({ showAccountStatementModal: false });
                                        }}
                                    >
                                        <Icon
                                            iconType='svg'
                                            width='24px'
                                            height='24px'
                                            viewBox='0 0 24 24'
                                            name='close-gray'
                                            className={cx('flex-icon', 'closeIcon')}
                                        />
                                    </div>
                                    <p className={cx('account-statement-mobile-header')}>
                                        {localization &&
                                            localization[ACCOUNT_STATEMENTS_L10N.GENERATE_STATEMENT_POPUP_TITLE]}
                                    </p>
                                    <AccountStatement isMobile onClickFunc={this.handleCloseAccountStatement} />
                                </div>
                            )}
                            {showModal && createClaimData.isSuccess && (
                                <Modal
                                    showModal={showModal}
                                    onClose={this.onClose}
                                    modalTheme={cx(deviceType.isMobile && 'mobileuserThemeModal')}
                                    themeModalBlock={cx('themeModalBlock')}
                                >
                                    <CreateClaim
                                        setSelectedInvoices={() => {
                                            this.setState({ selectedInvoices: [] });
                                        }}
                                        selectedInvoices={selectedInvoices}
                                        onCancel={this.onClose}
                                    />
                                </Modal>
                            )}
                            <div className={cx('selectAllRowMobile')}>
                                <p style={{ userSelect: 'none' }}>{localization[INVOICES_L10N.TABLE_COLUMN_ALL]}</p>
                                <label
                                    role='presentation'
                                    onClick={() => {
                                        if (this.state.selectedInvoices.length === invoicesList.length) {
                                            this.setState({ selectedInvoices: [] });
                                        } else {
                                            this.setState({ selectedInvoices: invoicesList });
                                        }
                                    }}
                                    htmlFor='selectAllCheckboxMobile'
                                    className={cx('checkbox-label')}
                                >
                                    <input
                                        type='checkbox'
                                        className={cx('checkbox-black')}
                                        id='selectAllCheckboxMobile'
                                        checked={
                                            this.state.selectedInvoices.length &&
                                            this.state.selectedInvoices.length === invoicesList.length
                                        }
                                    />
                                </label>
                            </div>
                        </div>
                        <div className={cx('tableIconsRow')}>
                            <p className={cx('tableTitle')}>
                                {localization[INVOICES_L10N.TABLE_TITLE]}{' '}
                                <span className={cx('tableTitleNumber')}>{`(${invoicesList.length})`}</span>
                            </p>
                            <div className={cx('icons-row')}>
                                {invoicesDetails.createClaimVisibility && (
                                    <div
                                        role='presentation'
                                        className={cx('tooltip-container')}
                                        onClick={() => {
                                            if (selectedInvoices.length > 0 && invoicesDetails.createClaimVisibility) {
                                                this.handleCreateClaim();
                                                buttonClickEvent(
                                                    buttonClickEventConfigs.invoice.actions.list,
                                                    buttonClickEventConfigs.invoice.labels.listCreateClaim,
                                                    buttonClickEventConfigs.invoice.categories.invoice
                                                );
                                            }
                                        }}
                                    >
                                        <Icon
                                            automation-id='at-create-claim-icon'
                                            className={cx(
                                                selectedInvoices.length > 0 && invoicesDetails.createClaimVisibility
                                                    ? 'active-icon-create-claim'
                                                    : 'inactive-icon-create-claim'
                                            )}
                                            iconType='svg'
                                            width='24px'
                                            height='24px'
                                            viewBox='0 0 24 24'
                                            name='create-claim-red'
                                        />
                                        <div className={cx('icon-tooltip')}>
                                            {localization[INVOICES_L10N.BUTTON_CREATE_CLAIM_DESCRIPTION]}
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={cx('tooltip-container')}
                                    onClick={() => {
                                        if (selectedInvoices.length > 1) {
                                            this.handleDownloadZip();
                                            buttonClickEvent(
                                                buttonClickEventConfigs.invoice.actions.list,
                                                buttonClickEventConfigs.invoice.labels.listDownload,
                                                buttonClickEventConfigs.invoice.categories.invoice
                                            );
                                        }
                                    }}
                                >
                                    <Icon
                                        automation-id='at-download-all-icon'
                                        className={cx(
                                            selectedInvoices.length > 1
                                                ? 'active-icon-download'
                                                : 'inactive-icon-download'
                                        )}
                                        iconType='svg'
                                        width='24px'
                                        height='24px'
                                        viewBox='0 0 24 24'
                                        name='download-red'
                                    />
                                    <div className={cx('icon-tooltip')}>
                                        {localization[INVOICES_L10N.BUTTON_DOWNLOAD_DESCRIPTION]}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {deviceType.isDesktop && this.renderDesktopLayout()} {/* Desktop View Layout Changes */}
                        {this.renderMobileAndTabletLayout()}{' '}
                    </>
                ) : (
                    <Loader spinner loaderClass='ordersSpinner' />
                )}
            </>
        );
    }

    /**
     * @brief      Renders the component as part of another page (in a tab for example)
     *
     * @return     {JSX}
     */
    embedableMode() {
        return (
            <div automation-id='at-invoices-screen' className={cx('invoicesContainer')}>
                {this.getContent()}
            </div>
        );
    }

    /**
     * @brief      Renders the component as full page layout
     *
     * @return     {JSX}
     */
    fillPageMode() {
        const { invoicesDetails } = this.props;
        return (
            <Layout showDashboardHeader showOutlet>
                <div automation-id='at-invoices-screen' className={cx('invoicesContainer')}>
                    <BreadCrumbs breadcrumbValues={invoicesDetails.breadcrumbsObjs} />
                    {this.getContent()}
                </div>
            </Layout>
        );
    }

    render() {
        const { embeded } = this.props;

        return <>{embeded ? this.embedableMode() : this.fillPageMode()}</>;
    }
}

const mapStateToProps = state => {
    return {
        localization: _get(state, 'pageContent.localization'),
        selectedOutlet: _get(state, 'pageContent.selectedOutlet'),
        outletList: _get(state, 'pageContent.outletList.mainPartners'),
        invoicesDetails: _get(state, 'invoicesDetails'),
        createClaimData: _get(state, 'invoicesDetails.createClaimData'),
        accountStatementEnabled: _get(state, 'invoicesDetails.accountStatementEnabled'),
        deviceType: _get(state, 'context.deviceType'),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`),
        invoicePDFData: _get(state, 'invoicesDetails.invoicePDFData'),
        invoiceZIPData: _get(state, 'invoicesDetails.invoiceZIPData'),
        message: _get(state, 'invoicesDetails.message') || {},
        accountStatementError: _get(state, 'accountStatement.error'),
        payerCustomers: _get(state, 'invoicesDetails.payerCustomers'),
        invoicePageMounted: _get(state, 'invoicesDetails.invoicePageMounted')
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getPageContent,
            getFilterInvoicesDetails,
            getInvoicesDetails,
            createClaim,
            getInvoicePDF,
            resetInvoiceDetails,
            clearAccountStatementError
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
