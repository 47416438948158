import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Link from 'react-router/lib/Link';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import Layout from '../../../components/site/Layout/Layout';
import SelectBox from '../../../components/core/SelectBox/SelectBox';
import Button from '../../../components/core/Button/Button';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import Modal from '../../../components/core/Modal/Modal';
import Icon from '../../../components/core/Icon/Icon';
import { getPageContent } from '../../../actions/PageContentAction';
import {
    getClaimTicketHistory,
    updateTicketId,
    updateClaimCreationPeriod,
    updateClaimTicketType,
    clearClaimForm,
    defaultClaimHistory,
    resetClaimHistory
} from '../../../actions/ClaimTicketHistoryAction';
import { PAGE_ID, CLAIMS_HISTORY_L10N, GENERIC_L10N } from '../../../common/Constants';
import * as styles from './ClaimsHistory.css';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

export class ClaimsHistory extends Component {
    static propTypes = {
        deviceType: PropTypes.objectOf(PropTypes.bool),
        claimHistoryData: PropTypes.objectOf(PropTypes.any),
        localization: PropTypes.objectOf(PropTypes.string),
        breadcrumbs: PropTypes.arrayOf(PropTypes.any),
        commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    };

    static defaultProps = {
        deviceType: {},
        claimHistoryData: {},
        localization: {},
        breadcrumbs: [],
        commonLocalization: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            currentStatusType: '',
            fillterShow: false
        };
    }

    componentDidMount() {
        const { onlyRenderTicketSection } = this.props;
        if (onlyRenderTicketSection) {
            this.setState({
                currentStatusType: 'All'
            });
            return;
        }
        const params = this.getQueryParams();
        const { getPageContent } = this.props.actions;
        getPageContent({ pageId: PAGE_ID.CLAIMS_HISTORY, params });
    }

    componentDidUpdate(prevProps, prevState) {
        const { claimHistoryData } = this.props;
        if (prevState.ticketId !== claimHistoryData.ticketId) {
            this.updateState('ticketId', claimHistoryData.ticketId);
        }
        if (prevState.currentStatusType !== claimHistoryData.currentStatusType) {
            this.updateState('currentStatusType', claimHistoryData?.currentStatusType);
        }
    }

    componentWillUnmount() {
        if (this.props.onlyRenderTicketSection) {
            return;
        }
        this.props.actions.resetClaimHistory();
    }

    onPeroidChangeHandler = e => {
        this.props.actions.updateClaimCreationPeriod(e);
    };

    onProcessTypeChangeHandler = e => {
        this.props.actions.updateClaimTicketType(e);
    };

    onTicketidChangeHandler = e => {
        this.props.actions.updateTicketId(e.target.value);
        this.setState({ ticketId: e.target.value });
    };

    updateState = (key, value) => {
        if (!value) return;
        this.setState({
            [key]: value
        });
    };

    onSubmitHandler = event => {
        event.preventDefault();
        const params = this.getQueryParams();
        this.props.actions.getClaimTicketHistory({ params });
        this.setState({ ticketId: '' });

        buttonClickEvent(
            buttonClickEventConfigs.claimTicketsHistory.actions.search,
            buttonClickEventConfigs.claimTicketsHistory.labels.searchSearchButton,
            buttonClickEventConfigs.claimTicketsHistory.categories.claimTicketsHistory
        );
    };

    onClearHandler = () => {
        const { defaultClaimHistory, getClaimTicketHistory } = this.props.actions;
        defaultClaimHistory();
        this.setState({ ticketId: '' }, () => {
            const params = this.getQueryParams();
            getClaimTicketHistory({ params });
            this.modalOnClose();

            buttonClickEvent(
                buttonClickEventConfigs.claimTicketsHistory.actions.search,
                buttonClickEventConfigs.claimTicketsHistory.labels.searchClearButton,
                buttonClickEventConfigs.claimTicketsHistory.categories.claimTicketsHistory
            );
        });
    };

    getQueryParams = () => {
        const { claimHistoryData } = this.props;
        const { currentStatusType, ticketId } = this.state;
        const params = {
            period: _get(claimHistoryData, 'currentPeriod', '30'),
            processType: _get(claimHistoryData, 'currentProcessType', 'All'),
            status: currentStatusType || 'All'
        };
        if (!_isEmpty(ticketId)) {
            params.ticketCode = ticketId;
        }
        return params;
    };

    onFilterChangeHandler = (type, gaItem) => {
        this.setState(
            {
                currentStatusType: type
            },
            () => {
                const params = this.getQueryParams();
                this.props.actions.getClaimTicketHistory({ params });

                if (gaItem) {
                    const gaFilters = {
                        All: buttonClickEventConfigs.claimTicketsHistory.labels.listAllSelection,
                        I1002: buttonClickEventConfigs.claimTicketsHistory.labels.listOpenSelection,
                        I1005: buttonClickEventConfigs.claimTicketsHistory.labels.listCompletedSelection
                    };

                    const foundFilter = gaFilters[gaItem.id] || gaFilters[gaItem.value];
                    if (foundFilter) {
                        buttonClickEvent(
                            buttonClickEventConfigs.claimTicketsHistory.actions.list,
                            foundFilter,
                            buttonClickEventConfigs.claimTicketsHistory.categories.claimTicketsHistory
                        );
                    }
                }
            }
        );
    };

    onDetailsHandler = (e, id) => {
        e.preventDefault();
        const listItem = this[id].current;
        if (listItem) {
            listItem.classList.toggle('ClaimsHistory-expandedListItem');
        }
    };

    handleFillter = () => {
        const { clearClaimForm } = this.props.actions;
        const { fillterShow } = this.state;
        clearClaimForm();
        this.setState({ fillterShow: !fillterShow });
    };

    modalOnClose = () => {
        this.setState({ fillterShow: false });
    };

    handleSearchFillter = () => {
        const { currentStatusType } = this.state;
        this.onFilterChangeHandler(currentStatusType);
        this.modalOnClose();

        buttonClickEvent(
            buttonClickEventConfigs.claimTicketsHistory.actions.search,
            buttonClickEventConfigs.claimTicketsHistory.labels.searchSearchButton,
            buttonClickEventConfigs.claimTicketsHistory.categories.claimTicketsHistory
        );
    };

    renderClaimHistoryHeader = id => {
        const { localization } = this.props;
        const iconViewList = (
            <Icon
                automationId='at-chevron-icon'
                className={cx('accordionArrow')}
                iconType='svg'
                width='24px'
                height='24px'
                viewBox='0 0 16 16'
                name='acc-down'
            />
        );
        return (
            <div className={cx('tablerow', 'tableHeader')}>
                <div automation-id='at-ticket-id' className={cx('tableCell', 'ticketidCell', 'column-heading')}>
                    {localization[CLAIMS_HISTORY_L10N.TICKET_ID]}
                </div>
                <div automation-id='at-ticket-type' className={cx('tableCell', 'tickettypeCell', 'column-heading')}>
                    {localization[CLAIMS_HISTORY_L10N.TICKET_TYPE]}
                </div>
                <div automation-id='at-short-description' className={cx('tableCell', 'shortCell', 'column-heading')}>
                    {localization[CLAIMS_HISTORY_L10N.SHORT_DESCRIPTION]}
                </div>
                <div
                    automation-id='at-reason-code'
                    className={cx('tableCell', 'reasonCell', 'column-heading', 'hiddenMd')}
                >
                    {localization[CLAIMS_HISTORY_L10N.REASON_CODE]}
                </div>
                <div automation-id='at-status' className={cx('tableCell', 'statusCell', 'column-heading')}>
                    {localization[CLAIMS_HISTORY_L10N.STATUS]}
                </div>
                <div automation-id='at-creation-date' className={cx('tableCell', 'creationCell', 'column-heading')}>
                    {localization[CLAIMS_HISTORY_L10N.CREATION_DATE]}
                </div>
                <div className={cx('tableCell', 'detailsCell', 'column-heading')}>
                    <Link
                        automation-id='at-details-accordion'
                        className={cx('detailsLink')}
                        onClick={e => this.onDetailsHandler(e, `invoices-${id}`)}
                    >
                        {localization[CLAIMS_HISTORY_L10N.DETAILS]} {iconViewList}{' '}
                    </Link>
                </div>
            </div>
        );
    };

    renderClaimHistoryTabView = () => {
        const { localization } = this.props;
        return (
            <div className={cx('tablerow', 'tableHeader', 'tabletViewStyles', 'tabletHeader')}>
                <div
                    automation-id='at-short-description'
                    className={cx('tableCell', 'mTabletshortCell', 'column-heading')}
                >
                    {localization[CLAIMS_HISTORY_L10N.SHORT_DESCRIPTION]}
                </div>
                <div automation-id='at-reason-code' className={cx('tableCell', 'mTabletreasonCell', 'column-heading')}>
                    {localization[CLAIMS_HISTORY_L10N.REASON_CODE]}
                </div>
            </div>
        );
    };

    renderBodyTabContent = item => {
        return (
            <div className={cx('tablerow', 'layoutContent', 'tabletViewStyles', 'tabletLayoutContent', 'visibleNone')}>
                <div className={cx('tableCell', 'mdStyle', 'mTabletShortCont')}>
                    <div automation-id='at-short-description-details' className={cx('tableCellContent', 'dueContent')}>
                        {item.shortDescription}
                    </div>
                </div>
                <div className={cx('tableCell', 'mTabletreasonCont')}>
                    <div automation-id='at-reason-code-details' className={cx('tableCellContent', 'dateContent')}>
                        {item.description}
                    </div>
                </div>
            </div>
        );
    };

    renderClaimHistoryTableLayout = item => {
        const { localization } = this.props;
        const { tabletDetailSummary } = this.state;
        return (
            <div key={item.ticketId} className={cx('tablerow', 'layoutContent', tabletDetailSummary && 'bdrBtmNone')}>
                <div className={cx('tableCell', 'ticketidCell')}>
                    <div automation-id='at-ticket-id' className={cx('tableCellHeading')}>
                        {localization[CLAIMS_HISTORY_L10N.TICKET_ID]}
                    </div>
                    <div automation-id='at-ticket-id-details' className={cx('tableCellContent', 'ticidContent')}>
                        {item.ticketId}
                    </div>
                </div>
                <div className={cx('tableCell', 'topic-cell', 'tickettypeCell')}>
                    <div automation-id='at-ticket-type' className={cx('tableCellHeading')}>
                        {localization[CLAIMS_HISTORY_L10N.TICKET_TYPE]}
                    </div>
                    <div automation-id='at-ticket-type-details' className={cx('tableCellContent', 'typeContent')}>
                        {item.processDescription}
                    </div>
                </div>
                <div className={cx('tableCell', 'shortCell')}>
                    <div automation-id='at-short-description' className={cx('tableCellHeading')}>
                        {localization[CLAIMS_HISTORY_L10N.SHORT_DESCRIPTION]}
                    </div>
                    <div
                        automation-id='at-short-description-details'
                        className={cx('tableCellContent', 'shortContent')}
                    >
                        <div className={cx('chevron-drop')}>
                            <input type='checkbox' id='chck1' />
                            <div
                                className={cx(
                                    'chevron-content',
                                    _get(item, 'shortDescription', '').length <= 20 && 'chevron-hide'
                                )}
                            >
                                {item.shortDescription}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx('tableCell', 'reasonCell', 'hiddenMd')}>
                    <div automation-id='at-reason-code' className={cx('tableCellHeading')}>
                        {localization[CLAIMS_HISTORY_L10N.REASON_CODE]}
                    </div>

                    <div automation-id='at-reason-code-details' className={cx('tableCellContent', 'reasonContent')}>
                        <div className={cx('chevron-drop')}>
                            <input type='checkbox' id='chck1' />
                            <div
                                className={cx(
                                    'chevron-content',
                                    _get(item, 'description', '').length <= 20 && 'chevron-hide'
                                )}
                            >
                                {item.description}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx('tableCell', 'statusCell')}>
                    <div automation-id='at-status' className={cx('tableCellHeading')}>
                        {localization[CLAIMS_HISTORY_L10N.STATUS]}
                    </div>
                    <div automation-id='at-status-details' className={cx('tableCellContent', 'statusContent')}>
                        {item.statusDisplay}
                    </div>
                </div>
                <div className={cx('tableCell', 'creationCell')}>
                    <div automation-id='at-creation-date' className={cx('tableCellHeading')}>
                        {localization[CLAIMS_HISTORY_L10N.CREATION_DATE]}
                    </div>
                    <div automation-id='at-creation-date-details' className={cx('tableCellContent', 'dueContent')}>
                        {item.wsPostingDate}
                    </div>
                </div>
                <div className={cx('tableCell', 'detailsCell', 'column-heading')}>&nbsp;</div>
            </div>
        );
    };

    renderMobileViewContent = () => {
        return (
            <div className={cx('col12', 'sm12', 'pad16', 'hiddenSm', 'hiddenMd')}>
                <div className={cx('mobBdr')} />
            </div>
        );
    };

    renderDesktopLayout = () => {
        const { claimHistoryData, commonLocalization } = this.props || {};
        return (
            <div automation-id='at-ticket-table-view' className={cx('tableContent', 'desktopLayout')}>
                <div className={cx('invoiceTable', 'tableWrapper')}>
                    {this.renderClaimHistoryHeader('1')}
                    {claimHistoryData && claimHistoryData.claims && claimHistoryData.claims.length > 0 ? (
                        claimHistoryData.claims.map(item => {
                            return this.renderClaimHistoryTableLayout(item);
                        })
                    ) : (
                        <li
                            automation-id='at-no-ticket-founds'
                            className={cx('orderlistItem', 'clearfix', 'noResults')}
                        >
                            <Icon iconType='svg' width='24' height='24' viewBox='0 0 24 24' name='no-results' />{' '}
                            {commonLocalization[GENERIC_L10N.NO_RESULTS_MSG]}
                        </li>
                    )}
                </div>
            </div>
        );
    };

    renderMobileAndTabletLayout = () => {
        const { claimHistoryData, localization, commonLocalization } = this.props;
        const { mobileDetailsSummary } = this.state;
        const iconArrowName = mobileDetailsSummary ? 'acc-up' : 'acc-down';
        const iconViewList = (
            <Icon
                automationId='at-chevron-icon'
                className={cx('accordionArrow')}
                iconType='svg'
                width='24px'
                height='24px'
                viewBox='0 0 16 16'
                name={iconArrowName}
            />
        );
        return (
            <div className={cx('mobileTabletLayout', 'md12', 'pad0')}>
                <div className={cx('tableContent')}>
                    {/* Tablet & Mobile Repeat - 1 */}
                    {/* {deviceType.isMobile && TO DO DeviceType */}
                    {claimHistoryData && claimHistoryData.claims && claimHistoryData.claims.length > 0 ? (
                        claimHistoryData.claims.map((item, i) => {
                            const ref = `invoices-${i}`;
                            this[ref] = React.createRef();
                            return (
                                <div ref={this[ref]} key={item.ticketId} className={cx('mobileWrapper')}>
                                    <div className={cx('sm12', 'visibleSm', 'mobileContentSpace')}>
                                        <h3 automation-id='at-ticket-id' className={cx('col6', 'pad0', 'mobileTitle')}>
                                            {localization[CLAIMS_HISTORY_L10N.TICKET_ID]}
                                        </h3>
                                        <p
                                            automation-id='at-ticket-id-details'
                                            className={cx('col6', 'pad0', 'mobileValTitle')}
                                        >
                                            {item.ticketId}
                                        </p>
                                        <Link
                                            className={cx('mobileDetailsLink')}
                                            onClick={e => this.onDetailsHandler(e, `invoices-${i}`)}
                                        >
                                            {localization[CLAIMS_HISTORY_L10N.DETAILS]} {iconViewList}{' '}
                                        </Link>
                                    </div>
                                    {/* }  TO DO DeviceType */}
                                    <div
                                        ref={this[ref]}
                                        automation-id='at-ticket-table-view'
                                        className={cx(
                                            'invoiceTable',
                                            'tableWrapper',
                                            'mobileActiveTable',
                                            'visibleNone'
                                        )}
                                    >
                                        {this.renderClaimHistoryHeader(i)}
                                        {this.renderClaimHistoryTableLayout(item)}
                                        {this.renderClaimHistoryTabView(i)}
                                        {this.renderBodyTabContent(item)}
                                    </div>
                                    {this.renderMobileViewContent(i)}
                                </div>
                            );
                        })
                    ) : (
                        <li
                            automation-id='at-no-ticket-founds'
                            className={cx('orderlistItem', 'clearfix', 'noResults')}
                        >
                            <Icon iconType='svg' width='24' height='24' viewBox='0 0 24 24' name='no-results' />{' '}
                            {commonLocalization[GENERIC_L10N.NO_RESULTS_MSG]}
                        </li>
                    )}
                </div>
            </div>
        );
    };

    renderTicketIdInput = () => {
        const { localization } = this.props;
        return (
            <div className={cx('clearfix', 'float-left', 'rgt-sp')}>
                <span className={cx('float-left', 'selectTitle', 'hiddenMd')} />
                <input
                    automation-id='at-ticket-id-input-field'
                    className={cx('inputText', 'tickId')}
                    type='text'
                    id='text'
                    placeholder={localization[CLAIMS_HISTORY_L10N.TICKET_ID]}
                    onChange={this.onTicketidChangeHandler}
                    value={this.state.ticketId}
                />
            </div>
        );
    };

    renderCreationPeriod = () => {
        const { claimHistoryData, localization } = this.props;
        return (
            <div className={cx('clearfix', 'float-left', 'rgt-sp')}>
                <span className={cx('float-left', 'selectTitle', 'subTitle')}>
                    {localization[CLAIMS_HISTORY_L10N.CREATION_PERIOD]}
                </span>
                <SelectBox
                    automation-id='at-creation-period-dropdown'
                    id='period'
                    onChange={this.onPeroidChangeHandler}
                    defaultValue={claimHistoryData.currentPeriod}
                    options={claimHistoryData.periods}
                    theme={cx('float-left')}
                    themeSelectBox={cx('selectopt')}
                    arrowTheme={cx('arrowTheme')}
                    onFocus={() => {
                        buttonClickEvent(
                            buttonClickEventConfigs.claimTicketsHistory.actions.search,
                            buttonClickEventConfigs.claimTicketsHistory.labels.searchTimePeriodArrow,
                            buttonClickEventConfigs.claimTicketsHistory.categories.claimTicketsHistory
                        );
                    }}
                />
            </div>
        );
    };

    renderTicketType = () => {
        const { claimHistoryData, localization } = this.props;
        return (
            <div className={cx('clearfix', 'float-left', 'rgt-sp')}>
                <span className={cx('float-left', 'selectTitle', 'subTitle')}>
                    {localization[CLAIMS_HISTORY_L10N.TICKET_TYPE]}
                </span>
                <SelectBox
                    automation-id='at-ticket-type-dropdown'
                    id='type'
                    theme={cx('float-left')}
                    onChange={this.onProcessTypeChangeHandler}
                    defaultValue={claimHistoryData.currentProcessType}
                    options={claimHistoryData.processTypes}
                    themeSelectBox={cx('selectopt')}
                    arrowTheme={cx('arrowTheme')}
                    onFocus={() => {
                        buttonClickEvent(
                            buttonClickEventConfigs.claimTicketsHistory.actions.search,
                            buttonClickEventConfigs.claimTicketsHistory.labels.searchTypeArrow,
                            buttonClickEventConfigs.claimTicketsHistory.categories.claimTicketsHistory
                        );
                    }}
                />
            </div>
        );
    };

    renderClearButton = () => {
        const { commonLocalization } = this.props;
        return (
            <Button
                automation-id='at-clear-button'
                id='claimHistoryClearButton'
                className={cx('clearsearch', 'clearBtn')}
                type='button'
                buttonType='Secondary'
                size='Sm'
                onClick={this.onClearHandler}
            >
                {commonLocalization[GENERIC_L10N.CLEAR_BTN]}
            </Button>
        );
    };

    renderSubmitButton = () => {
        const { commonLocalization } = this.props;
        return (
            <Button
                automation-id='at-search-button'
                id='claimHistorySearchButton'
                className={cx('clearsearch', 'clearBtn')}
                type='button'
                buttonType='Primary'
                size='Sm'
                onClick={this.onSubmitHandler}
            >
                {commonLocalization[GENERIC_L10N.SEARCH_BTN]}
            </Button>
        );
    };

    renderMobileFilterContent = () => {
        const { fillterShow } = this.state;
        const { localization, commonLocalization } = this.props;
        return (
            <div automation-id='at-filter-option' className={cx('mobileFilterModal')}>
                {fillterShow && (
                    <Modal
                        automationId='at-filter-overlay'
                        showModal={fillterShow}
                        modalTheme={cx('mobileInvoiceModal')}
                        modalTitleTheme={cx('modalTitleTheme')}
                        themeContent={cx('themeContent')}
                        themeClose={cx('themeClose')}
                        themeModalBlock={cx('themeModalBlock')}
                        onClose={this.modalOnClose}
                    >
                        <div className={cx('filterContent')}>
                            <div className={cx('boxTitleTxt')}>
                                {localization[CLAIMS_HISTORY_L10N.SEARCH_CLAIM_HISTORY]}
                            </div>
                            <div className={cx('col12', 'md12', 'sm12', 'pad0')}>
                                {this.renderTicketIdInput()}
                                {this.renderCreationPeriod()}
                                {this.renderTicketType()}
                            </div>
                            <div className={cx('col3', 'md12', 'sm12', 'searchBtnfit', 'padt4')}>
                                <Button
                                    type='button'
                                    buttonType='Primary'
                                    className={cx('searchBtnMob', 'btn', 'btnsm', 'sm12')}
                                    automationId='at-search-button'
                                    title='searchBtn'
                                    size='Sm'
                                    onClick={this.handleSearchFillter}
                                >
                                    {commonLocalization[GENERIC_L10N.SEARCH_BTN]}
                                </Button>
                                <Button
                                    type='button'
                                    buttonType='Secondary'
                                    className={cx('searchBtnMob', 'btn', 'btnsm', 'sm12')}
                                    automationId='at-clear-button'
                                    title='searchBtn'
                                    size='Sm'
                                    onClick={this.onClearHandler}
                                >
                                    {commonLocalization[GENERIC_L10N.CLEAR_BTN]}
                                </Button>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        );
    };

    renderFilterOptions = () => {
        const { claimHistoryData, localization } = this.props;
        return (
            <div className={cx('filterDetail', 'clear-float', 'col12')}>
                <div className={cx('sm9', 'tab-link')}>
                    {claimHistoryData &&
                        claimHistoryData.statusTypesList &&
                        claimHistoryData.statusTypesList.map(item => {
                            return (
                                <div key={item.value} className={cx('float-left', 'pointer')}>
                                    <span
                                        automation-id={`at-${item.label.toString().toLowerCase()}-filter-button`}
                                        role='presentation'
                                        id='filterLink'
                                        className={cx(
                                            'filLink',
                                            `${item.value === this.state.currentStatusType ? 'active' : ''}`
                                        )}
                                        onClick={() => this.onFilterChangeHandler(item.value, item)}
                                    >
                                        {item.label}
                                    </span>
                                </div>
                            );
                        })}
                </div>
                <div className={cx('sm3', 'visibleSm', 'fliterBtn')}>
                    <Button
                        type='button'
                        id='filterIconId'
                        size='Sm'
                        buttonType='Default'
                        className={cx('mobileBtn')}
                        onClick={this.handleFillter}
                    >
                        <span className={cx('float-left', 'filterLabel')}>
                            {localization[CLAIMS_HISTORY_L10N.FILTER]}
                        </span>
                        <span className={cx('float-left', 'filterIcon')}>
                            <Icon
                                automation-id='at-filter-icon'
                                iconType='svg'
                                width='24'
                                height='24'
                                viewBox='0 0 16 16'
                                name='filter'
                            />
                        </span>
                    </Button>
                    <div className={cx('mobileshowFilter')}>{this.renderMobileFilterContent()}</div>
                </div>
            </div>
        );
    };

    ticketSection = () => {
        const { deviceType, localization, onlyRenderTicketSection } = this.props;
        const { fillterShow } = this.state;

        return (
            <div className={cx('claimsHisContainer', onlyRenderTicketSection ? 'reduceMargin' : '')}>
                <div
                    automation-id='at-search-ticket-section'
                    className={cx('col12', 'boxsel', 'hiddenSm', fillterShow && 'fillterShow')}
                >
                    <div className={cx('boxTitleTxt')}>{localization[CLAIMS_HISTORY_L10N.SEARCH_CLAIM_HISTORY]}</div>
                    <div className={cx('col9', 'md12', 'pad0')}>
                        {this.renderTicketIdInput()}
                        {this.renderCreationPeriod()}
                        {this.renderTicketType()}
                    </div>
                    <div className={cx('col3', 'md12', 'float-right', 'padt4')}>
                        <div className={cx('clearfix', 'float-right', 'mrgRgt')}>
                            {this.renderClearButton()}
                            {this.renderSubmitButton()}
                        </div>
                    </div>
                </div>
                {this.renderFilterOptions()}
                {deviceType.isDesktop && this.renderDesktopLayout()} {/* Desktop View Layout Changes */}
                {this.renderMobileAndTabletLayout()}
            </div>
        );
    };

    render() {
        const { localization, breadcrumbs, claimHistoryData, onlyRenderTicketSection } = this.props;

        return onlyRenderTicketSection ? (
            this.ticketSection()
        ) : (
            <Layout showDashboardHeader showOutlet>
                {!_isEmpty(claimHistoryData) && !_isEmpty(localization) && (
                    <div automation-id='at-user-screen' className={cx('claimsHisContainer')}>
                        <BreadCrumbs breadcrumbValues={breadcrumbs} />
                        <div className={cx('Claimstitle', 'col12', 'md12', 'sm12', 'pad0', 'claimsHis')}>
                            <div className={cx('float-left', 'titleIcon')}>
                                <Icon width='40' height='40' viewBox='0 0 34 34' name='claims-tickets' />
                            </div>

                            <div className={cx('float-left', 'titleCenter')}>
                                <h1 automation-id='at-ticket-list-page-title' className={cx('claim-head')}>
                                    {localization[CLAIMS_HISTORY_L10N.CLAIM_HISTORY_TITLE]}
                                </h1>
                            </div>
                        </div>
                        {this.ticketSection()}
                    </div>
                )}
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        localization: _get(state, 'pageContent.localization'),
        deviceType: _get(state, 'context.deviceType'),
        claimHistoryData: _get(state, 'claimHistoryData'),
        ticketId: _get(state, 'claimHistoryData.ticketId') || '',
        breadcrumbs: _get(state, 'pageContent.breadcrumbs', []),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getPageContent,
            getClaimTicketHistory,
            updateTicketId,
            updateClaimCreationPeriod,
            updateClaimTicketType,
            clearClaimForm,
            defaultClaimHistory,
            resetClaimHistory
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsHistory);
