import _find from 'lodash/find';
import _get from 'lodash/get';
import { LOCALIZATION, UNIQUE_ID } from '../common/Constants';
import { filterCountryData } from '../service/CountryFilter';
import {
    GET_LANGUAGE_SUCCESS,
    COUNTRY_CONTENT_SUCCESS,
    UPDATE_COUNTRY_REQUEST,
    UPDATE_LANGUAGE_REQUEST
} from '../actionTypes/SelectLanguageActionTypes';

const initialState = {};
export default function SelectLanguageReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_LANGUAGE_SUCCESS: {
            const transformedData = filterCountryData(action.data);

            return {
                ...state,
                ...transformedData
            };
        }
        case COUNTRY_CONTENT_SUCCESS: {
            const data = _get(action, 'data.components');

            return {
                ...state,
                pageContent: {
                    pageTitle: _get(action, 'data.title'),
                    backgroundImage: _find(data, [UNIQUE_ID, LOCALIZATION.BANNER_COMPONENT]),
                    logoImage: _find(data, [UNIQUE_ID, LOCALIZATION.LOGO_COMPONENT])
                }
            };
        }
        case UPDATE_COUNTRY_REQUEST: {
            const { value } = action.payload;
            const newState = { ...state };
            const selectedCountry = _find(newState.countryList, ['value', value]) || {};
            const selectedLangList = newState.lanaguageList[selectedCountry.value];
            const selectedLanguage = _find(selectedLangList, ['default', true]) || selectedLangList[0];

            return {
                ...state,
                selectedCountry,
                selectedLanguage
            };
        }

        case UPDATE_LANGUAGE_REQUEST: {
            const { value } = action.payload;
            const newState = { ...state };
            const selectedLanguage = _find(newState.lanaguageList[newState.selectedCountry.value], ['value', value]);

            return {
                ...state,
                selectedLanguage
            };
        }

        default:
            return state;
    }
}
