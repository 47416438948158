export const GET_ORDER_TEMPLATE_DETAILS_REQUEST = 'GET_ORDER_TEMPLATE_DETAILS_REQUEST';
export const GET_ORDER_TEMPLATE_DETAILS_SUCCESS = 'GET_ORDER_TEMPLATE_DETAILS_SUCCESS';
export const GET_ORDER_TEMPLATE_DETAILS_ERROR = 'GET_ORDER_TEMPLATE_DETAILS_ERROR';
export const UPDATE_ORDER_TEMPLATE_DETAILS_QUANTITY_REQUEST = 'UPDATE_ORDER_TEMPLATE_DETAILS_QUANTITY_REQUEST';
export const UPDATE_ORDER_TEMPLATE_DETAILS_REQUEST = 'UPDATE_ORDER_TEMPLATE_DETAILS_REQUEST';
export const UPDATE_ORDER_TEMPLATE_DETAILS_ITEM_QUANTITY = 'UPDATE_ORDER_TEMPLATE_DETAILS_ITEM_QUANTITY';
export const UPDATE_ORDER_DETAILS_TEMPLATE_NAME = 'UPDATE_ORDER_DETAILS_TEMPLATE_NAME';
export const UPDATE_ORDER_DETAILS_TEMPLATE_DESCRIPTION = 'UPDATE_ORDER_DETAILS_TEMPLATE_DESCRIPTION';
export const CALCEL_ORDER_TEMPLATE_DETAILS = 'CALCEL_ORDER_TEMPLATE_DETAILS';
export const UPDATE_ORDER_TEMPLATE_SUCCESS = 'UPDATE_ORDER_TEMPLATE_SUCCESS';
export const CLEAR_ORDER_TEMPLATE_SUCCESS = 'CLEAR_ORDER_TEMPLATE_SUCCESS';
export const RESET_ORDER_TEMPLATE_DETAILS = 'RESET_ORDER_TEMPLATE_DETAILS';
export const DELETE_ORDER_TEMPLATE_ITEM_REQUEST = 'DELETE_ORDER_TEMPLATE_ITEM_REQUEST';
export const SET_ZERO_QTY_ERR = 'SET_ZERO_QTY_ERR';
export const UPDATE_ITEM_SEQUENCE = 'UPDATE_ITEM_SEQUENCE';
export const UPDATE_SORTING_ORDER_TEMPLATE_ITEM_REQUEST = 'UPDATE_SORTING_ORDER_TEMPLATE_ITEM_REQUEST';
export const GET_ORDER_ITEM_TEMPLATE_DETAILS_SUCCESS = 'GET_ORDER_ITEM_TEMPLATE_DETAILS_SUCCESS';
