/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import { getLoyaltyDashboardData } from '../../../actions/LoyaltyAction';
import MyRewardsOnboardingCard from '../../../components/site/MyRewardsOnboardingCard/MyRewardsOnboardingCard';
import MyRewardsLoyaltyCard from '../../../components/site/MyRewardsLoyaltyCard/MyRewardsLoyaltyCard';
import * as styles from './MyRewards.css';
import MyRewardsNavigationCard from '../../../components/site/MyRewardsNavigationCard/MyRewardsNavigationCard';
import Icon from '../../../components/core/Icon/Icon';
import { DEVICE_TYPES, MY_REWARDS, PAGE_ID, URL_PATHS } from '../../../common/Constants';
import { getPageContent } from '../../../actions/PageContentAction';
import { getCurrency, getLocalization } from '../../../common/UserManager/UserManager';
import Loader from '../../../components/site/Loader/Loader';
import { useDeviceType } from '../../../common/Device/Device';
import Layout from '../../../components/site/Layout/Layout';
import MyRewardsSelfRedCard from '../../../components/site/MyRewardsSelfRedCard/MyRewardsSelfRedCard';
import { redirectTo } from '../../../common/Utils/Utils';

const LoyaltyOrders = React.lazy(() => import('./templates/LoyaltyOrders'));
const Onboarding = React.lazy(() => import('./templates/Onboarding'));
const SelfRed = React.lazy(() => import('./templates/SelfRed'));

const cx = classNames.bind(styles);

const MyRewards = props => {
    const dispatch = useDispatch();
    const { template } = props.params;

    const pageId = useMemo(() => {
        switch (template) {
            case 'loyalty-orders':
                return PAGE_ID.LOYALTY_ORDERS;
            case 'onboarding':
                return PAGE_ID.ONBOARDING;
            case 'selfred':
                return PAGE_ID.SELF_RED;
            default:
                return PAGE_ID.MY_REWARDS;
        }
    }, [template]);

    const { breadcrumbs, localization } = useSelector(state => ({
        breadcrumbs: state.pageContent.breadcrumbs,
        localization: state.pageContent.localization
    }));

    const loyaltyDashboardData = useSelector(state => state.loyalty.loyaltyDashboardData);
    const loading = useSelector(state => state.isLoading);
    const showSelfREDSection = useSelector(state => state.pageContent.showSelfREDSection);

    const deviceType = useDeviceType();

    const loyaltyLink = `${URL_PATHS.ACCOUNT_MY_REWARDS}/loyalty-orders`;
    const onboardingLink = `${URL_PATHS.ACCOUNT_MY_REWARDS}/onboarding`;
    const selfRedLink = `${URL_PATHS.ACCOUNT_MY_REWARDS}/selfred`;

    useEffect(() => {
        dispatch(getLoyaltyDashboardData());
    }, []);

    useEffect(() => {
        dispatch(getPageContent({ pageId }));
    }, [dispatch, pageId]);

    // Automatically redirect to my account page if it's North Macedonian user
    useEffect(() => {
        const localization = getLocalization();
        if (localization) {
            const siteId = localization?.siteId
            if (siteId === 'nm') {
                redirectTo(URL_PATHS.MY_ACCOUNT);
            }
        }
    }, []);

    let TemplateComponent = null;
    switch (template) {
        case 'loyalty-orders':
            TemplateComponent = LoyaltyOrders;
            break;
        case 'onboarding':
            TemplateComponent = Onboarding;
            break;
        case 'selfred':
            TemplateComponent = SelfRed;
            break;
        default:
            TemplateComponent = ({ children }) => <>{children}</>;
    }

    const createLink = title =>
        title ? `${URL_PATHS.ACCOUNT_MY_REWARDS}/${title.toLowerCase().replace('-', '').split(/\s+/).join('-')}` : null;

    const onboardingCompleted = loyaltyDashboardData?.programStatus === MY_REWARDS.ONBOARDING_COMPLETED_STATUS;

    const currency = getCurrency();

    const navigationCardProps = [
        {
            titleKey: MY_REWARDS.SUBCARD_ONBOARDING,
            iconKey: MY_REWARDS.SUBCARD_ONBOARDING_ICON,
            statusTextKey: MY_REWARDS.ONBOARDING_CARD_COMPLETED,
            isOnboardingCard: true && loyaltyDashboardData?.consecutiveOrders,
            isCompleted: loyaltyDashboardData?.programStatus === MY_REWARDS.ONBOARDING_COMPLETED_STATUS,
            link: onboardingLink
        },
        {
            titleKey: MY_REWARDS.SUBCARD_LOYALTY_ORDERS,
            iconKey: MY_REWARDS.SUBCARD_LOYALTY_ORDERS_ICON,
            statusTextKey: MY_REWARDS.LOYALTY_CARD_COMING_SOON,
            isLoyaltyCard: true && loyaltyDashboardData?.consecutiveOrders,
            isCompleted:
                loyaltyDashboardData?.programStatus === MY_REWARDS.LOYALTY_PROGRAM_NOT_STARTED ||
                (loyaltyDashboardData?.programStatus === MY_REWARDS.ONBOARDING_COMPLETED_STATUS &&
                    loyaltyDashboardData?.remainingOrdersUntilDiscount === '0'),
            link: loyaltyLink
        },
        {
            titleKey: MY_REWARDS.SUBCARD_REVENUE_INCENTIVE,
            iconKey: MY_REWARDS.SUBCARD_REVENUE_INCENTIVE_ICON,
            link: null
        },
        {
            titleKey: MY_REWARDS.SUBCARD_SELF_RED,
            iconKey: MY_REWARDS.SUBCARD_SELF_RED_ICON,
            isSelfRedCard: showSelfREDSection,
            link: selfRedLink
        },
        { titleKey: MY_REWARDS.SUBCARD_REFERRAL, iconKey: MY_REWARDS.SUBCARD_REFERRAL_ICON, link: null },
        { titleKey: MY_REWARDS.SUBCARD_SKU_ASSORTMENT, iconKey: MY_REWARDS.SUBCARD_SKU_ASSORTMENT_ICON, link: null }
    ];

    navigationCardProps.sort((a, b) => {
        // Prioritize onboarding and loyalty cards over others
        const aPriority = a.isOnboardingCard || a.isLoyaltyCard ? 1 : a.isSelfRedCard ? 2 : 3;
        const bPriority = b.isOnboardingCard || b.isLoyaltyCard ? 1 : b.isSelfRedCard ? 2 : 3;

        return aPriority - bPriority;
    });

    return (
        <div>
            <Layout showDashboardHeader showOutlet theme={cx('dashboard-layout-container')}>
                {loading ? (
                    <div className={cx('my-rewards-spinner-container')}>
                        <Loader spinner />
                    </div>
                ) : (
                    <>
                        <TemplateComponent
                            loyaltyDashboardData={loyaltyDashboardData}
                            breadcrumbs={breadcrumbs}
                            localization={localization}
                            currency={currency}
                        >
                            <div className={cx('my-rewards-container')}>
                                {deviceType === DEVICE_TYPES.MOBILE ? (
                                    <div className={cx('mobile-back-link')}>
                                        <a href={URL_PATHS.MY_ACCOUNT}>
                                            <Icon
                                                iconType='svg'
                                                width='16px'
                                                height='16px'
                                                viewBox='0 0 16 16'
                                                name='down'
                                                className={cx('left-arrow')}
                                            />
                                            {localization?.[MY_REWARDS.MY_REWARDS_PAGE_MY_ACCOUNT]?.toUpperCase()}
                                        </a>
                                    </div>
                                ) : (
                                    <BreadCrumbs breadcrumbValues={breadcrumbs} />
                                )}
                                <div className={cx('my-rewards-headline')}>
                                    {localization?.[MY_REWARDS.PAGE_TITLE]}{' '}
                                    {loyaltyDashboardData?.totalRewards && (
                                        <span>
                                            {loyaltyDashboardData?.totalRewards}
                                            {currency?.symbol}
                                        </span>
                                    )}
                                </div>
                                <div
                                    className={cx(
                                        'my-rewards-cards',
                                        loyaltyDashboardData?.consecutiveOrders &&
                                            !showSelfREDSection &&
                                            'horizontal-cards'
                                    )}
                                >
                                    {loyaltyDashboardData?.consecutiveOrders && (
                                        <div
                                            className={cx(
                                                'my-rewards-cards-col',
                                                loyaltyDashboardData?.consecutiveOrders && !showSelfREDSection && !onboardingCompleted
                                                    ? 'horizontal-cards' : 
                                                    loyaltyDashboardData?.consecutiveOrders && !showSelfREDSection && onboardingCompleted
                                                     ? 'horizontal-cards-onboardingCompleted' 
                                                     : loyaltyDashboardData?.consecutiveOrders && showSelfREDSection && onboardingCompleted
                                                     ? ''
                                                     : loyaltyDashboardData?.consecutiveOrders && showSelfREDSection && !onboardingCompleted
                                                     ? 'vertical-cards'
                                                     : !loyaltyDashboardData?.consecutiveOrders ? ''
                                                     : ''
                                            )}
                                        >
                                            <MyRewardsLoyaltyCard
                                                title={localization?.[MY_REWARDS.LOYALTY_CARD_TITLE]}
                                                ordersText={localization?.[MY_REWARDS.LOYALTY_CARD_CONSECUTIVE_ORDERS]}
                                                ordersValue={loyaltyDashboardData?.consecutiveOrders}
                                                discountText={
                                                    localization?.[MY_REWARDS.LOYALTY_CARD_ORDERS_UNTIL_DISCOUNT]
                                                }
                                                discountValue={loyaltyDashboardData?.remainingOrdersUntilDiscount}
                                                discountReceived={
                                                    loyaltyDashboardData?.remainingOrdersUntilDiscount === '3' &&
                                                    loyaltyDashboardData?.consecutiveOrders !== '0'
                                                        ? localization?.[MY_REWARDS.LOYALTY_CARD_DISCOUNT_RECEIVED]
                                                        : ''
                                                }
                                                maxSteps={MY_REWARDS.LOYALTY_MAX_CONSECUTIVE_ORDERS_STEPS}
                                                currentStep={loyaltyDashboardData?.consecutiveOrders}
                                                status={loyaltyDashboardData?.programStatus}
                                                link={loyaltyLink}
                                                statusText={localization?.[MY_REWARDS.LOYALTY_CARD_COMING_SOON]}
                                            />
                                            <MyRewardsOnboardingCard
                                                title={localization?.[MY_REWARDS.ONBOARDING_CARD_TITLE]}
                                                savingsText={localization?.[MY_REWARDS.ONBOARDING_CARD_SAVINGS]}
                                                savingsValue={loyaltyDashboardData?.savings}
                                                ordersText={localization?.[MY_REWARDS.ONBOARDING_CARD_ORDERS_COMPLETED]}
                                                ordersValue={loyaltyDashboardData?.ordersCompleted}
                                                footerText={localization?.[MY_REWARDS.ONBOARDING_CARD_FOOTNOTE]}
                                                maxSteps={MY_REWARDS.ONBOARDING_MAX_ORDERS_STEPS}
                                                currentStep={loyaltyDashboardData?.ordersCompleted}
                                                status={loyaltyDashboardData?.programStatus}
                                                statusText={localization?.[MY_REWARDS.ONBOARDING_CARD_COMPLETED]}
                                                link={onboardingLink}
                                                discountAmount={loyaltyDashboardData?.discountAmount}
                                            />
                                        </div>
                                    )}
                                    {showSelfREDSection && (
                                        <div className={cx('my-rewards-cards-col')}>
                                            <MyRewardsSelfRedCard
                                                title={localization?.[MY_REWARDS.MY_REWARDS_SELF_RED_TITLE]}
                                                link={selfRedLink}
                                                isInnerPage={false}
                                                messageUnderTitle={
                                                    localization?.[MY_REWARDS.MY_REWARDS_SELF_RED_MESSAGE_UNDER_TITLE]
                                                }
                                                currentDisountText={
                                                    localization?.[MY_REWARDS.MY_REWARDS_SELF_RED_CURRENT_DISCOUNT]
                                                }
                                                currentDiscountPeriodText={
                                                    localization?.[
                                                        MY_REWARDS.MY_REWARDS_SELF_RED_CURRENT_DISCOUNT_PERIOD
                                                    ]
                                                }
                                                nextDiscountText={
                                                    localization?.[MY_REWARDS.MY_REWARDS_SELF_RED_NEXT_DISCOUNT]
                                                }
                                                nextDiscountPeriodText={
                                                    localization?.[MY_REWARDS.MY_REWARDS_SELF_RED_NEXT_DISCOUNT_PERIOD]
                                                }
                                                captureNowText=''
                                                getYourRewardText=''
                                                setShowOnboardingSelfREDPopUp={() => {}}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={cx(
                                        'loyalty-navigation-container',
                                        onboardingCompleted && 'swap-positions'
                                    )}
                                >
                                    <h1>{localization?.[MY_REWARDS.OUR_LOYALTY_TITLE]}</h1>
                                    <h3>{localization?.[MY_REWARDS.OUR_LOYALTY_SUBTITLE]}</h3>
                                    <div className={cx('loyalty-navigation-cards')}>
                                        {navigationCardProps.map((cardProps, index) => (
                                            <MyRewardsNavigationCard
                                                key={index}
                                                title={localization?.[cardProps.titleKey]}
                                                icon={cardProps.iconKey}
                                                link={cardProps.link}
                                                buttonText={localization?.[MY_REWARDS.LOYALTY_BUTTON_LEARN]}
                                                statusText={localization?.[cardProps.statusTextKey]}
                                                isLoyaltyCard={cardProps.isLoyaltyCard}
                                                isOnboardingCard={cardProps.isOnboardingCard}
                                                isSelfRedCard={cardProps.isSelfRedCard}
                                                isCompleted={cardProps.isCompleted}
                                            />
                                        ))}
                                    </div>
                                </div>

                                <div className={cx('loyalty-footer')}>
                                    <h1>{localization?.[MY_REWARDS.BOTTOM_COMPONENT_TITLE]}</h1>
                                    <Icon
                                        iconType='svg'
                                        width='55px'
                                        height='45px'
                                        viewBox='0 0 55 45'
                                        name='loyalty-footer'
                                    />
                                    <h3>{localization?.[MY_REWARDS.BOTTOM_COMPONENT_TEXT]}</h3>
                                </div>
                            </div>
                        </TemplateComponent>
                    </>
                )}
            </Layout>
        </div>
    );
};

MyRewards.propTypes = {
    params: PropTypes.shape({
        template: PropTypes.string
    })
};

export default MyRewards;
