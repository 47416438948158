import React from 'react';

const NotificationBell = ({ color, width, height, active }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 19.77 22.887'>
            <g id='Group_48737' data-name='Group 48737' transform='translate(4190 -17314)'>
                <path
                    id='Path_3722'
                    data-name='Path 3722'
                    d='M19.55,15.4a17.843,17.843,0,0,1-1.2-2.114c-.733-1.52-.873-2.315-1.247-7.093a6.368,6.368,0,0,0-2.771-4.8,7.766,7.766,0,0,0-8.9,0A6.36,6.36,0,0,0,2.666,6.2c-.374,4.778-.514,5.573-1.247,7.093A18,18,0,0,1,.221,15.4a1.31,1.31,0,0,0,1.094,2.037H18.456A1.311,1.311,0,0,0,19.55,15.4m-17,.037c.213-.372.443-.8.674-1.282.911-1.885,1.064-2.992,1.441-7.806A4.364,4.364,0,0,1,6.6,3.029a5.737,5.737,0,0,1,6.575,0A4.363,4.363,0,0,1,15.11,6.351c.377,4.814.531,5.921,1.441,7.806.232.48.462.91.675,1.282Z'
                    transform='translate(-4190 17314)'
                    fill={color || '#ffffff'}
                />
                <path
                    id='Path_3723'
                    data-name='Path 3723'
                    d='M12.081,19.766a1,1,0,0,0-1.375.333c-.355.582-.651.788-.8.788h0c-.255,0-.677-.45-.866-.771a1,1,0,0,0-1.731,1,3.441,3.441,0,0,0,2.571,1.768h.03a3.017,3.017,0,0,0,2.5-1.747,1,1,0,0,0-.333-1.374'
                    transform='translate(-4190 17314)'
                    fill={color || '#ffffff'}
                />
                <path
                    id='Path_3761'
                    data-name='Path 3761'
                    d='M-4186.61,17322.494s-.464-4.363,1.737-5.674,3.917-1.838,5.011-1.756,3.221.523,4.1,1.377a6.8,6.8,0,0,1,1.55,3.2c.4,1.311.331,3.336.838,5.17a20.347,20.347,0,0,0,1.23,3.766,9.171,9.171,0,0,1,.7,2.053s-7.264-.074-8.875,0-5.2,0-5.2,0-4.089.254-3.431-.967,1.407-2.232,1.7-2.961a12.666,12.666,0,0,0,.633-2.781C-4186.574,17323.363-4186.61,17322.494-4186.61,17322.494Z'
                    fill={active ? color || '#ffffff' : 'none'}
                />
            </g>
        </svg>
    );
};

export default NotificationBell;
