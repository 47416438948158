export const hr_BA = {
  "hr_BA": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
    "callus": {
      "CallUsTitle": "Nazovite nas",
      "ContactsHeader": "Možete nas kontaktirati na bilo koji od sljedećih brojeva",
      "CancelButtonText": "Otkaži",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Already on a call? Let us <a role='link' tabindex='0' class='cx-cobrowse-link'>browse with you</a></span>",
      "CoBrowse": "Započnite zajedničko pretraživanje",
      "CoBrowseWarning": "Zajedničko pregledavanje omogućuje vašem agentu da vidi i kontrolira vašu radnu površinu kako bi vam pomogao. Kada razgovarate s agentom uživo, zatražite kôd za pokretanje zajedničkog pregledavanja i unesite ga ispod. Još niste na pozivu? Samo otkažite ovaj zaslon da biste se vratili na stranicu 'Nazovite nas'",
      "SubTitle": "Možete nas kontaktirati na bilo koji od sljedećih brojeva.",
      "AriaWindowLabel": "Prozor Nazovite nas",
      "AriaCallUsClose": "Zatvorite Nazovite nas",
      "AriaBusinessHours": "Radno vrijeme",
      "AriaCallUsPhoneApp": "Otvara telefonsku aplikaciju",
      "AriaCobrowseLink": "Otvara sesiju zajedničkog pregledavanja",
      "AriaCancelButtonText": "Otkaži Nazovite nas"
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector": {
      "Title": "Pomoć uživo",
      "SubTitle": "Kako biste željeli stupiti u kontakt?",
      "WaitTimeTitle": "Vrijeme čekanja",
      "AvailableTitle": "Dostupno",
      "AriaAvailableTitle": "Dostupno",
      "UnavailableTitle": "Nedostupno",
      "CobrowseButtonText": "ZajedničkopretraživanjePodnapis",
      "CallbackTitle": "Zaprimanje poziva",
      "CobrowseSubTitle": "Za ovo je potrebno spajanje Agenta.",
      "AriaClose": "Zatvori Pomoć uživo",
      "AriaWarning": "Upozorenje",
      "AriaAlert": "Upozorenje",
      "minute": "min",
      "minutes": "min",
      "AriaWindowLabel": "Prozor Pomoć Uživo"
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse": {
      "agentJoined": "Predstavnik se pridružio razgovoru.",
      "youLeft": "Napoustili ste razgovor. Zajedničko pretraživanje je sada završeno.",
      "sessionTimedOut": "Session timed out. Co-browse is now terminated.",
      "sessionInactiveTimedOut": "Sesija je istekla. Zajedničko pretraživanje je sada završeno.",
      "agentLeft": "Predstavnik je napustio razgovor. Zajedničko pretraživanje je sada završeno.",
      "sessionError": "Došlo je do neočekivane pogreške. Zajedničko pregledavanje sada je završeno.",
      "sessionStarted": "Započela je sesija zajedničkog pregledavanja. Čekanje predstavnika da se uključi u sesiju...",
      "navRefresh": "Predstavnik je osvježio stranicu. Ponovno učitavanje.",
      "navBack": "Predstavnik je pritisnuo gumb Natrag. Ponovno učitavanje stranice.",
      "navForward": "Predstavnik je pritisnuo gumb Naprijed. Ponovno učitavanje stranice..",
      "navUrl": "Predstavnik je zatražio navigaciju. Ponovno učitavanje stranice.",
      "navFailed": "Zahtjev za navigaciju od strane predstavnika nije uspio.",
      "toolbarContent": "Oznaka sesije: {sessionId}",
      "contentMasked": "Sadržaj je skriven od prestavnika.",
      "contentMaskedPartially": "Dio sadržaja je skriven od predstavnika.",
      "exitBtnTitle": "Izlaz iz sesije Zajedničko pretraživanje",
      "areYouOnPhone": "Razgovarate sa našim predstavnikom?",
      "areYouOnPhoneOrChat": "Razgovarate li s našim predstavnikom putem telefona ili chata?",
      "connectBeforeCobrowse":"Morate biti povezani s našim predstavnikom da biste nastavili s istim pregledavanjem. Nazovite nas ili započnite chat uživo s nama, a zatim ponovo pokrenite zajedničko pregledavanje.",
      "sessionStartedAutoConnect": "Započela je sesija zajedničkog pregledavanja. Čekanje da se predstavnik poveže sa sesijom ...",
      "browserUnsupported": "Nažalost, vaš preglednik trenutno nije podržan.<br><br> Podržani preglednici: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 and above</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 and above</a></li></ul>",
      "chatIsAlreadyRunning": "Chat je već pokrenut na drugoj stranici.",
      "modalYes": "Da",
      "modalNo": "Ne"
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar": {
      "SidebarTitle": "Potrebna vam je pomoć?",
      "ChannelSelectorName": "Pomoć uživo",
      "ChannelSelectorTitle": "Odmah potražite pomoć jednog od naših agenata",
      "SearchName": "Pretraživanje",
      "SearchTitle": "Pretraživanje",
      "CallUsName": "Nazovite nas",
      "CallUsTitle": "Nazovite nas Detalji",
      "CallbackName": "Uzvraćanje poziva",
      "CallbackTitle": "Zaprimanje poziva",
      "ClickToCallName": "KlikniteZaPoziv",
      "ClickToCallTitle": "Zatražite telefonski broj Službe za korisnike",
      "SendMessageName": "Pošaljite poruku",
      "SendMessageTitle": "Pošaljite poruku",
      "WebChatName": "Chat uživo",
      "WebChatTitle": "Chat uživo",
      "AriaClose": "Zatvorite izbornik Trebate pomoć"
    },
    "webchat": {
      "ChatButton": "Chat",
      "ChatStarted": "Chat je započeo",
      "ChatEnded": "Chat je završio",
      "AgentNameDefault": "Agent",
      "AgentConnected": "<%Agent%> Spojen",
      "AgentDisconnected": "<%Agent%> Odspojen",
      "BotNameDefault": "Bot",
      "BotConnected": "<%Bot%> Spojen",
      "BotDisconnected": "<%Bot%> Odspojen",
      "SupervisorNameDefault": "Supervizor",
      "SupervisorConnected": "<%Agent%> Spojen",
      "SupervisorDisconnected": "<%Agent%> Odspojen",
      "AgentTyping": "...",
      "AriaAgentTyping": "Agent tipka.",
      "AgentUnavailable": "Žao nam je. Nema raspoloživih agenata. Pokušajte kasnije.",
      "ChatTitle": "Chat uživo",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Min",
      "ChatFormFirstName": "Ime",
      "ChatFormLastName": "Prezime",
      "ChatFormNickname": "Nadimak",
      "ChatFormPlaceholderName": "Neobavezno",
      "ChatFormCustomerId": "Šifra kupca",
      "ChatFormPlaceholderCustomerId": "Neobavezno",
      "ChatFormSubjectPassword": "Zaporka",
      "ChatFormSubjectService": "Usluga",
      "ChatFormSubjectOrder": "Narudžba",
      "ChatFormSubjectFinance": "Financije",
      "ChatFormName": "Ime",
      "ChatFormEmail": "e-mail",
      "ChatFormSubject": "Naslov",
      "ChatFormPlaceholderFirstName": "Obvezno",
      "ChatFormPlaceholderLastName": "Obvezno",
      "ChatFormPlaceholderNickname": "Neobavezno",
      "ChatFormPlaceholderEmail": "Obvezno",
      "ChatFormPlaceholderSubject": "Neobavezno",
      "ChatFormSubmit": "Start Chat",
      "AriaChatFormSubmit": "Započnite Chat",
      "ChatFormCancel": "Otkaži",
      "AriaChatFormCancel": "Otkaži Chat",
      "ChatFormClose": "Zatvori",
      "ChatInputPlaceholder": "Upišite svoju poruku ovdje",
      "ChatInputSend": "Pošalji",
      "AriaChatInputSend": "Pošalji",
      "ChatEndQuestion": "Jeste li sigurni da želite završiti ovu sesiju chata?",
      "ChatEndCancel": "Otkaži",
      "ChatEndConfirm": "Zatvori Chat",
      "AriaChatEndCancel": "Otkaži",
      "AriaChatEndConfirm": "Završetak",
      "ConfirmCloseWindow":"Jeste li sigurni da želite zatvoriti chat?",
      "ConfirmCloseCancel": "Otkaži",
      "ConfirmCloseConfirm": "Zatvori",
      "AriaConfirmCloseCancel": "Otkaži",
      "AriaConfirmCloseConfirm": "Zatvori",
      "ActionsDownload": "Preuzmi prijepis",
      "ActionsEmail": "e-mail Prijepisa",
      "ActionsPrint": "Ispis prijepisa",
      "ActionsCobrowseStart": "Započni zajedničko pretraživanje",
      "AriaActionsCobrowseStartTitle": "Otvara sesiju zajedničkog pregledavanja",
      "ActionsSendFile": "Priloži datoteke",
      "AriaActionsSendFileTitle": "Otvara dijalog za prijenos datoteka",
      "ActionsEmoji": "Pošalji emoji",
      "ActionsCobrowseStop": "Izađi iz zajedničkog pretraživanja",
      "ActionsVideo": "Pozovi u Video chat",
      "ActionsTransfer": "Prijenos",
      "ActionsInvite": "Pozovi",
      "InstructionsTransfer": "Otvorite ovu poveznicu na drugom uređaju da biste prenijeli chat sesiju</br></br><%link%>",
      "InstructionsInvite": "Podijelite ovu poveznicu s drugom osobom da biste je dodali u ovu sesiju chata</br></br><%link%>",
      "InviteTitle": "Trebate pomoć?",
      "InviteBody": "Javite nam ako možemo pomoći.",
      "InviteReject": "Ne hvala",
      "InviteAccept": "Započni chat",
      "AriaInviteAccept": "Prihvati pozivnicu i započni chat",
      "AriaInviteReject": "Odbij pozivnicu",
      "ChatError": "Došlo je do problema prilikom pokretanja sesije chata. Pokušajte ponovo.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Da",
      "ChatErrorDefaultButton": "Ne",
      "AriaChatErrorPrimaryButton": "Da",
      "AriaChatErrorDefaultButton": "Ne",
      "DownloadButton": "Preuzimanje",
      "AriaDownloadButton": "Preuzimanje datoteke",
      "FileSent": "poslano",
      "FileTransferRetry": "Pokušaj ponovno",
      "AriaFileTransferRetry": "Pokušaj ponovno prenijeti datoteku",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Otkaži",
      "AriaFileTransferCancel": "Otkaži prijenos datoteka",
      "RestoreTimeoutTitle": "Chat je završio",
      "RestoreTimeoutBody": "Vaša prethodna sesija chata je istekla. Želite li započeti novu?",
      "RestoreTimeoutReject": "Ne hvala",
      "RestoreTimeoutAccept": "Započni chat",
      "AriaRestoreTimeoutAccept": "Prihvati pozivnicu i započni chat",
      "AriaRestoreTimeoutReject": "Odbij pozivnicu",
      "EndConfirmBody": "Želite li zaista završiti sesiju chata?",
      "EndConfirmAccept": "Završi chat",
      "EndConfirmReject": "Otkaži",
      "AriaEndConfirmAccept": "Završi chat",
      "AriaEndConfirmReject": "Otkaži",
      "SurveyOfferQuestion": "Želite li sudjelovati u anketi?",
      "ShowSurveyAccept": "Da",
      "ShowSurveyReject": "Ne",
      "AriaShowSurveyAccept": "Da",
      "AriaShowSurveyReject": "Ne",
      "UnreadMessagesTitle": "nepročitano",
      "AriaYouSaid": "Rekli ste",
      "AriaSaid": "rekao",
      "AriaSystemSaid": "sustav je rekao",
      "AriaWindowLabel": "Prozor Chat uživo",
      "AriaMinimize": "Chat uživo Smanji",
      "AriaMaximize": "Chat uživo Povećaj",
      "AriaClose": "Chat uživo Zatvori",
      "AriaEmojiStatusOpen": "Otvoren je dijaloški okvir za odabir emotikona",
      "AriaEmojiStatusClose": "Dijalog za odabir emotikona je zatvoren",
      "AriaEmoji": "emotikoni",
      "AriaEmojiPicker": "Odabir emotikona",
      "AriaCharRemaining": "Preostalo znakova",
      "AriaMessageInput": "Okvir za poruke",
      "AsyncChatEnd": "Završi chat",
      "AsyncChatClose": "Zatvori prozor",
      "AriaAsyncChatEnd": "Završi chat",
      "AriaAsyncChatClose": "Zatvori prozor",
      "DayLabels": [
        "Ned",
        "Pon",
        "Uto",
        "Sri",
        "Čet",
        "Pet",
        "Sub"
      ],
      "MonthLabels": [
        "Sij",
        "Velj",
        "Ožu",
        "Tra",
        "Svi",
        "Lip",
        "Srp",
        "Kol",
        "Ruj",
        "Lis",
        "Stu",
        "Pro"
      ],
      "todayLabel": "Danas",
      "Errors": {
        "102": "Potrebno je Ime.",
        "103": "Potrebno je Prezime.",
        "161": "Molimo unesite svoje ime.",
        "201": "Datoteku nije moguće poslati. <br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Premašen maksimalan broj priloženih datoteka (<%MaxFilesAllowed%>).</p>",
        "202": "Datoteku nije moguće poslati .<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Premašen je limit za prijenos i / ili maksimalni broj privitaka(<%MaxAttachmentsSize%>).</p>",
        "203": "Datoteku nije moguće poslati.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Vrsta datoteke nije dopuštena. </p> ",
        "204": "Žao nam je, ali vaša je poruka predugačka. Upišite kraću poruku.",
        "240": "Žao nam je, ali trenutno ne možemo započeti novi chat. Pokušajte ponovo kasnije.",
        "364": "Nevažeća adresa e-pošte.",
        "401": "Žao nam je, ali nismo u mogućnosti autorizirati sesiju chata. Želite li započeti novi chat?",
        "404": "Žao nam je, ali ne možemo pronaći vašu prethodnu sesiju chata. Želite li započeti novi chat?",
        "500": "Žao nam je, dogodila se neočekivana pogreška sa uslugom. Želite li zatvoriti i započeti novi chat?",
        "503": "Žao nam je, usluga trenutno nije dostupna ili je zauzeta. Želite li zatvoriti i ponovo pokrenuti novi chat?",
        "ChatUnavailable": "Žao nam je, ali trenutno ne možemo započeti novi chat. Pokušajte ponovo kasnije.",
        "CriticalFault": "Vaša sesija chata neočekivano je završila zbog nepoznatog problema. Ispričavamo se zbog neugodnosti.",
        "StartFailed": "Nastao je problem sa Vašom chat sesijom. Molimo Vas, provjerite svoju konekciju i da li ste pravilno osigurali sve potrebne informacije, pa pokušajte ponovo.",
        "MessageFailed": "Došlo je do problema prilikom pokretanja vaše sesije chata. Provjerite vezu i jeste li pravilno poslali sve potrebne podatke, a zatim pokušajte ponovo.",
        "RestoreFailed": "Žao nam je, ali nismo uspjeli vratiti vašu sesiju chata zbog nepoznate pogreške.",
        "TransferFailed": "Trenutno nije moguće prenijeti chat. Pokušajte ponovo kasnije.",
        "FileTransferSizeError": "Datoteku nije moguće poslati.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Veličina datoteke veća je od dopuštene (<%MaxSizePerFile%>).</p>",
        "InviteFailed": "Trenutno nije moguće generirati pozivnicu. Pokušajte ponovo kasnije.",
        "ChatServerWentOffline": "Prijenos poruka trenutno traje dulje od uobičajenog. Žao nam je zbog kašnjenja.",
        "RestoredOffline": "Prolaz poruka trenutno traje dulje od uobičajenog. Žao nam je zbog kašnjenja.",
        "Disconnected": "<div style='text-align:center'>Veza izgubljena</div>",
        "Reconnected": "<div style='text-align:center'>Veza uspostavljena</div>",
        "FileSendFailed": "Datoteku nije moguće poslati.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>Došlo je do neočekivanog prekida veze. Pokušati ponovno?</p>",
        "Generic": "<div style='text-align:center'>Dogodila se neočekivana pogreška. </div> ",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Nevaljana vrsta datoteke. Dopuštene su samo slike.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "Veličina datoteke je veća od dopuštene.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "Maksimalan broj priloženih datoteka premašio je ograničenje.",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Neispravna konfiguracija prijenosa x-api-ključa.",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Nevaljana konfiguracija prijenosa krajnje točke.",
        "pureengage-v3-rest-INVALID_NICKNAME": "Potrebno je Ime",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "Žao nam je, ali nismo u mogućnosti odobriti sesiju chata.",
        "purecloud-v2-sockets-400": "Žao nam je, nešto je pošlo po zlu. Provjerite vezu i jeste li ispravno poslali sve potrebne podatke, a zatim pokušajte ponovo.",
        "purecloud-v2-sockets-500": "Žao nam je, dogodila se neočekivana pogreška sa uslugom.",
        "purecloud-v2-sockets-503": "Žao nam je, usluga trenutno nije dostupna."
      }
    }
  }
}

export const hr = {
  "hr": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
    "callus": {
      "CallUsTitle": "Nazovite nas",
      "ContactsHeader": "Možete nas kontaktirati na bilo koji od sljedećih brojeva",
      "CancelButtonText": "Otkaži",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Already on a call? Let us <a role='link' tabindex='0' class='cx-cobrowse-link'>browse with you</a></span>",
      "CoBrowse": "Započnite zajedničko pretraživanje",
      "CoBrowseWarning": "Zajedničko pregledavanje omogućuje vašem agentu da vidi i kontrolira vašu radnu površinu kako bi vam pomogao. Kada razgovarate s agentom uživo, zatražite kôd za pokretanje zajedničkog pregledavanja i unesite ga ispod. Još niste na pozivu? Samo otkažite ovaj zaslon da biste se vratili na stranicu 'Nazovite nas'",
      "SubTitle": "Možete nas kontaktirati na bilo koji od sljedećih brojeva.",
      "AriaWindowLabel": "Prozor Nazovite nas",
      "AriaCallUsClose": "Zatvorite Nazovite nas",
      "AriaBusinessHours": "Radno vrijeme",
      "AriaCallUsPhoneApp": "Otvara telefonsku aplikaciju",
      "AriaCobrowseLink": "Otvara sesiju zajedničkog pregledavanja",
      "AriaCancelButtonText": "Otkaži Nazovite nas"
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector": {
      "Title": "Pomoć uživo",
      "SubTitle": "Kako biste željeli stupiti u kontakt?",
      "WaitTimeTitle": "Vrijeme čekanja",
      "AvailableTitle": "Dostupno",
      "AriaAvailableTitle": "Dostupno",
      "UnavailableTitle": "Nedostupno",
      "CobrowseButtonText": "ZajedničkopretraživanjePodnapis",
      "CallbackTitle": "Zaprimanje poziva",
      "CobrowseSubTitle": "Za ovo je potrebno spajanje Agenta.",
      "AriaClose": "Zatvori Pomoć uživo",
      "AriaWarning": "Upozorenje",
      "AriaAlert": "Upozorenje",
      "minute": "min",
      "minutes": "min",
      "AriaWindowLabel": "Prozor Pomoć Uživo"
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse": {
      "agentJoined": "Predstavnik se pridružio razgovoru.",
      "youLeft": "Napoustili ste razgovor. Zajedničko pretraživanje je sada završeno.",
      "sessionTimedOut": "Session timed out. Co-browse is now terminated.",
      "sessionInactiveTimedOut": "Sesija je istekla. Zajedničko pretraživanje je sada završeno.",
      "agentLeft": "Predstavnik je napustio razgovor. Zajedničko pretraživanje je sada završeno.",
      "sessionError": "Došlo je do neočekivane pogreške. Zajedničko pregledavanje sada je završeno.",
      "sessionStarted": "Započela je sesija zajedničkog pregledavanja. Čekanje predstavnika da se uključi u sesiju...",
      "navRefresh": "Predstavnik je osvježio stranicu. Ponovno učitavanje.",
      "navBack": "Predstavnik je pritisnuo gumb \"Natrag \". Ponovno učitavanje stranice.",
      "navForward": "Predstavnik je pritisnuo gumb \"Naprijed \". Ponovno učitavanje stranice..",
      "navUrl": "Predstavnik je zatražio navigaciju. Ponovno učitavanje stranice.",
      "navFailed": "Zahtjev za navigaciju od strane predstavnika nije uspio.",
      "toolbarContent": "Oznaka sesije: {sessionId}",
      "contentMasked": "Sadržaj je skriven od prestavnika.",
      "contentMaskedPartially": "Dio sadržaja je skriven od predstavnika.",
      "exitBtnTitle": "Izlaz iz sesije Zajedničko pretraživanje",
      "areYouOnPhone": "Razgovarate sa našim predstavnikom?",
      "areYouOnPhoneOrChat": "Razgovarate li s našim predstavnikom putem telefona ili chata?",
      "connectBeforeCobrowse":"Morate biti povezani s našim predstavnikom da biste nastavili s istim pregledavanjem. Nazovite nas ili započnite chat uživo s nama, a zatim ponovo pokrenite zajedničko pregledavanje.",
      "sessionStartedAutoConnect": "Započela je sesija zajedničkog pregledavanja. Čekanje da se predstavnik poveže sa sesijom ...",
      "browserUnsupported": "Nažalost, vaš preglednik trenutno nije podržan.<br><br> Podržani preglednici: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 and above</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 and above</a></li></ul>",
      "chatIsAlreadyRunning": "Chat je već pokrenut na drugoj stranici.",
      "modalYes": "Da",
      "modalNo": "Ne"
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar": {
      "SidebarTitle": "Potrebna vam je pomoć?",
      "ChannelSelectorName": "Pomoć uživo",
      "ChannelSelectorTitle": "Odmah potražite pomoć jednog od naših agenata",
      "SearchName": "Pretraživanje",
      "SearchTitle": "Pretraživanje",
      "CallUsName": "Nazovite nas",
      "CallUsTitle": "Nazovite nas Detalji",
      "CallbackName": "Uzvraćanje poziva",
      "CallbackTitle": "Zaprimanje poziva",
      "ClickToCallName": "KlikniteZaPoziv",
      "ClickToCallTitle": "Zatražite telefonski broj Službe za korisnike",
      "SendMessageName": "Pošaljite poruku",
      "SendMessageTitle": "Pošaljite poruku",
      "WebChatName": "Chat uživo",
      "WebChatTitle": "Chat uživo",
      "AriaClose": "Zatvorite izbornik Trebate pomoć"
    },
    "webchat": {
      "ChatButton": "Chat",
      "ChatStarted": "Chat je započeo",
      "ChatEnded": "Chat je završio",
      "AgentNameDefault": "Agent",
      "AgentConnected": "<%Agent%> Spojen",
      "AgentDisconnected": "<%Agent%> Odspojen",
      "BotNameDefault": "Bot",
      "BotConnected": "<%Bot%> Spojen",
      "BotDisconnected": "<%Bot%> Odspojen",
      "SupervisorNameDefault": "Supervizor",
      "SupervisorConnected": "<%Agent%> Spojen",
      "SupervisorDisconnected": "<%Agent%> Odspojen",
      "AgentTyping": "...",
      "AriaAgentTyping": "Agent tipka.",
      "AgentUnavailable": "Žao nam je. Nema raspoloživih agenata. Pokušajte kasnije.",
      "ChatTitle": "Chat uživo",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Min",
      "ChatFormFirstName": "Ime",
      "ChatFormLastName": "Prezime",
      "ChatFormNickname": "Nadimak",
      "ChatFormName": "Ime",
      "ChatFormEmail": "e-mail",
      "ChatFormSubject": "Naslov",
      "ChatFormPlaceholderName": "Neobavezno",
      "ChatFormCustomerId": "Šifra kupca",
      "ChatFormPlaceholderCustomerId": "Neobavezno",
      "ChatFormSubjectPassword": "Zaporka",
      "ChatFormSubjectService": "Usluga",
      "ChatFormSubjectOrder": "Narudžba",
      "ChatFormSubjectFinance": "Financije",
      "ChatFormPlaceholderFirstName": "Obvezno",
      "ChatFormPlaceholderLastName": "Obvezno",
      "ChatFormPlaceholderNickname": "Neobavezno",
      "ChatFormPlaceholderEmail": "Obvezno",
      "ChatFormPlaceholderSubject": "Neobavezno",
      "ChatFormSubmit": "Start Chat",
      "AriaChatFormSubmit": "Započnite Chat",
      "ChatFormCancel": "Otkaži",
      "AriaChatFormCancel": "Otkaži Chat",
      "ChatFormClose": "Zatvori",
      "ChatInputPlaceholder": "Upišite svoju poruku ovdje",
      "ChatInputSend": "Pošalji",
      "AriaChatInputSend": "Pošalji",
      "ChatEndQuestion": "Jeste li sigurni da želite završiti ovu sesiju chata?",
      "ChatEndCancel": "Otkaži",
      "ChatEndConfirm": "Zatvori Chat",
      "AriaChatEndCancel": "Otkaži",
      "AriaChatEndConfirm": "Završetak",
      "ConfirmCloseWindow":"Jeste li sigurni da želite zatvoriti chat?",
      "ConfirmCloseCancel": "Otkaži",
      "ConfirmCloseConfirm": "Zatvori",
      "AriaConfirmCloseCancel": "Otkaži",
      "AriaConfirmCloseConfirm": "Zatvori",
      "ActionsDownload": "Preuzmi prijepis",
      "ActionsEmail": "e-mail Prijepisa",
      "ActionsPrint": "Ispis prijepisa",
      "ActionsCobrowseStart": "Započni zajedničko pretraživanje",
      "AriaActionsCobrowseStartTitle": "Otvara sesiju zajedničkog pregledavanja",
      "ActionsSendFile": "Priloži datoteke",
      "AriaActionsSendFileTitle": "Otvara dijalog za prijenos datoteka",
      "ActionsEmoji": "Pošalji emoji",
      "ActionsCobrowseStop": "Izađi iz zajedničkog pretraživanja",
      "ActionsVideo": "Pozovi u Video chat",
      "ActionsTransfer": "Prijenos",
      "ActionsInvite": "Pozovi",
      "InstructionsTransfer": "Otvorite ovu poveznicu na drugom uređaju da biste prenijeli chat sesiju</br></br><%link%>",
      "InstructionsInvite": "Podijelite ovu poveznicu s drugom osobom da biste je dodali u ovu sesiju chata</br></br><%link%>",
      "InviteTitle": "Trebate pomoć?",
      "InviteBody": "Javite nam ako možemo pomoći.",
      "InviteReject": "Ne hvala",
      "InviteAccept": "Započni chat",
      "AriaInviteAccept": "Prihvati pozivnicu i započni chat",
      "AriaInviteReject": "Odbij pozivnicu",
      "ChatError": "Došlo je do problema prilikom pokretanja sesije chata. Pokušajte ponovo.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Da",
      "ChatErrorDefaultButton": "Ne",
      "AriaChatErrorPrimaryButton": "Da",
      "AriaChatErrorDefaultButton": "Ne",
      "DownloadButton": "Preuzimanje",
      "AriaDownloadButton": "Preuzimanje datoteke",
      "FileSent": "poslano",
      "FileTransferRetry": "Pokušaj ponovno",
      "AriaFileTransferRetry": "Pokušaj ponovno prenijeti datoteku",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Otkaži",
      "AriaFileTransferCancel": "Otkaži prijenos datoteka",
      "RestoreTimeoutTitle": "Chat je završio",
      "RestoreTimeoutBody": "Vaša prethodna sesija chata je istekla. Želite li započeti novu?",
      "RestoreTimeoutReject": "Ne hvala",
      "RestoreTimeoutAccept": "Započni chat",
      "AriaRestoreTimeoutAccept": "Prihvati pozivnicu i započni chat",
      "AriaRestoreTimeoutReject": "Odbij pozivnicu",
      "EndConfirmBody": "Želite li zaista završiti sesiju chata?",
      "EndConfirmAccept": "Završi chat",
      "EndConfirmReject": "Otkaži",
      "AriaEndConfirmAccept": "Završi chat",
      "AriaEndConfirmReject": "Otkaži",
      "SurveyOfferQuestion": "Želite li sudjelovati u anketi?",
      "ShowSurveyAccept": "Da",
      "ShowSurveyReject": "Ne",
      "AriaShowSurveyAccept": "Da",
      "AriaShowSurveyReject": "Ne",
      "UnreadMessagesTitle": "nepročitano",
      "AriaYouSaid": "Rekli ste",
      "AriaSaid": "rekao",
      "AriaSystemSaid": "sustav je rekao",
      "AriaWindowLabel": "Prozor Chat uživo",
      "AriaMinimize": "Chat uživo Smanji",
      "AriaMaximize": "Chat uživo Povećaj",
      "AriaClose": "Chat uživo Zatvori",
      "AriaEmojiStatusOpen": "Otvoren je dijaloški okvir za odabir emotikona",
      "AriaEmojiStatusClose": "Dijalog za odabir emotikona je zatvoren",
      "AriaEmoji": "emotikoni",
      "AriaEmojiPicker": "Odabir emotikona",
      "AriaCharRemaining": "Preostalo znakova",
      "AriaMessageInput": "Okvir za poruke",
      "AsyncChatEnd": "Završi chat",
      "AsyncChatClose": "Zatvori prozor",
      "AriaAsyncChatEnd": "Završi chat",
      "AriaAsyncChatClose": "Zatvori prozor",
      "DayLabels": [
        "Ned",
        "Pon",
        "Uto",
        "Sri",
        "Čet",
        "Pet",
        "Sub"
      ],
      "MonthLabels": [
        "Sij",
        "Velj",
        "Ožu",
        "Tra",
        "Svi",
        "Lip",
        "Srp",
        "Kol",
        "Ruj",
        "Lis",
        "Stu",
        "Pro"
      ],
      "todayLabel": "Danas",
      "Errors": {
        "102": "Potrebno je Ime.",
        "103": "Potrebno je Prezime.",
        "161": "Molimo unesite svoje ime.",
        "201": "Datoteku nije moguće poslati. <br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Premašen maksimalan broj priloženih datoteka (<%MaxFilesAllowed%>).</p>",
        "202": "Datoteku nije moguće poslati .<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Premašen je limit za prijenos i / ili maksimalni broj privitaka(<%MaxAttachmentsSize%>).</p>",
        "203": "Datoteku nije moguće poslati.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Vrsta datoteke nije dopuštena. </p> ",
        "204": "Žao nam je, ali vaša je poruka predugačka. Upišite kraću poruku.",
        "240": "Žao nam je, ali trenutno ne možemo započeti novi chat. Pokušajte ponovo kasnije.",
        "364": "Nevažeća adresa e-pošte.",
        "401": "Žao nam je, ali nismo u mogućnosti autorizirati sesiju chata. Želite li započeti novi chat?",
        "404": "Žao nam je, ali ne možemo pronaći vašu prethodnu sesiju chata. Želite li započeti novi chat?",
        "500": "Žao nam je, dogodila se neočekivana pogreška sa uslugom. Želite li zatvoriti i započeti novi chat?",
        "503": "Žao nam je, usluga trenutno nije dostupna ili je zauzeta. Želite li zatvoriti i ponovo pokrenuti novi chat?",
        "ChatUnavailable": "Žao nam je, ali trenutno ne možemo započeti novi chat. Pokušajte ponovo kasnije.",
        "CriticalFault": "Vaša sesija chata neočekivano je završila zbog nepoznatog problema. Ispričavamo se zbog neugodnosti.",
        "StartFailed": "Nastao je problem sa Vašom chat sesijom. Molimo Vas, provjerite svoju konekciju i da li ste pravilno osigurali sve potrebne informacije, pa pokušajte ponovo.",
        "MessageFailed": "Došlo je do problema prilikom pokretanja vaše sesije chata. Provjerite vezu i jeste li pravilno poslali sve potrebne podatke, a zatim pokušajte ponovo.",
        "RestoreFailed": "Žao nam je, ali nismo uspjeli vratiti vašu sesiju chata zbog nepoznate pogreške.",
        "TransferFailed": "Trenutno nije moguće prenijeti chat. Pokušajte ponovo kasnije.",
        "FileTransferSizeError": "Datoteku nije moguće poslati.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Veličina datoteke veća je od dopuštene (<%MaxSizePerFile%>).</p>",
        "InviteFailed": "Trenutno nije moguće generirati pozivnicu. Pokušajte ponovo kasnije.",
        "ChatServerWentOffline": "Prijenos poruka trenutno traje dulje od uobičajenog. Žao nam je zbog kašnjenja.",
        "RestoredOffline": "Prolaz poruka trenutno traje dulje od uobičajenog. Žao nam je zbog kašnjenja.",
        "Disconnected": "<div style='text-align:center'>Veza izgubljena</div>",
        "Reconnected": "<div style='text-align:center'>Veza uspostavljena</div>",
        "FileSendFailed": "Datoteku nije moguće poslati.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>Došlo je do neočekivanog prekida veze. Pokušati ponovno?</p>",
        "Generic": "<div style='text-align:center'>Dogodila se neočekivana pogreška. </div> ",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Nevaljana vrsta datoteke. Dopuštene su samo slike.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "Veličina datoteke je veća od dopuštene.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "Maksimalan broj priloženih datoteka premašio je ograničenje.",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Neispravna konfiguracija prijenosa x-api-ključa.",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Nevaljana konfiguracija prijenosa krajnje točke.",
        "pureengage-v3-rest-INVALID_NICKNAME": "Potrebno je Ime",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "Žao nam je, ali nismo u mogućnosti odobriti sesiju chata.",
        "purecloud-v2-sockets-400": "Žao nam je, nešto je pošlo po zlu. Provjerite vezu i jeste li ispravno poslali sve potrebne podatke, a zatim pokušajte ponovo.",
        "purecloud-v2-sockets-500": "Žao nam je, dogodila se neočekivana pogreška sa uslugom.",
        "purecloud-v2-sockets-503": "Žao nam je, usluga trenutno nije dostupna."
      }
    }
  }
}
