import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { AUTH, PARAMS, CHECKOUT_API } from '../common/Api';
import { getActiveWholeSaler, getDeliveryType, isIndirectUser } from '../../common/UserManager/UserManager';
import { DELIVERY_TYPES } from '../../common/Constants';

export default class CheckoutApi {
    static getCheckoutData(payload) {
        const { baseInfo = {}, cartId } = payload;
        const params = {
            cartId,
            [PARAMS.IS_RETURN_EMPTIES]: baseInfo[PARAMS.IS_RETURN_EMPTIES],
            [PARAMS.IS_MARKETING_MATRIALS]: baseInfo[PARAMS.IS_MARKETING_MATRIALS]
        };
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}${AUTH.USERS}/${baseInfo.userId}${CHECKOUT_API.CHECKOUT}`;

        if (baseInfo[PARAMS.IS_PICKUP_ORDER]) {
            params[PARAMS.IS_PICKUP_ORDER] = baseInfo[PARAMS.IS_PICKUP_ORDER];
        }

        config.params = params;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static submitPlaceOrder(payload) {
        const { baseInfo, data = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        const wholeSalerId = getActiveWholeSaler();
        const isIndirectCustomer = isIndirectUser();

        config.url = `/${baseInfo.siteId}${AUTH.USERS}/${baseInfo.userId}${CHECKOUT_API.CHECKOUT}`;

        if (baseInfo[PARAMS.IS_RETURN_EMPTIES]) {
            data[PARAMS.IS_RETURN_EMPTIES] = baseInfo[PARAMS.IS_RETURN_EMPTIES];
        }

        if (baseInfo[PARAMS.IS_MARKETING_MATRIALS]) {
            data[PARAMS.IS_MARKETING_MATRIALS] = baseInfo[PARAMS.IS_MARKETING_MATRIALS];
        }

        if (baseInfo[PARAMS.IS_PICKUP_ORDER]) {
            data[PARAMS.IS_PICKUP_ORDER] = baseInfo[PARAMS.IS_PICKUP_ORDER];

            const deliveryType = getDeliveryType();
            if (deliveryType !== DELIVERY_TYPES.PICKUP && !isIndirectCustomer) {
                data[PARAMS.PLANT_CODE] = deliveryType;
            }
        }

        if (isIndirectCustomer && wholeSalerId) {
            data[PARAMS.WHOLE_SALER_ID] = wholeSalerId;
        }


        config.data = data;

        return RestClient.post(config).then(json => {
            return json;
        });
    }
}
