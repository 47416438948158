import { put, takeLatest, call } from 'redux-saga/effects';
import FactorySaga from "./FactorySaga";
import ProductListingApi from "../interfaces/PLP/PLPApi";
import {SUBSCRIBE_TO_PRODUCT, SUBSCRIBE_TO_PRODUCT_SUCCESS} from "../actionTypes/NotifyMeActionTypes";

export function* subscribeToProductSaga(action = {}) {
    try {
        action.payload.params = { ...action.payload.params};

        const result = yield call(FactorySaga, ProductListingApi.subscribeToProduct, action);

        if (result.isSuccess) {
            yield put({ type: SUBSCRIBE_TO_PRODUCT_SUCCESS, params:action.payload.params});
        }
    } catch (err) {
        console.log(err)
    }
}
export default function* watchNotifyMeRequest() {
    yield takeLatest(SUBSCRIBE_TO_PRODUCT, subscribeToProductSaga);
}