import {
    CLEAR_LOGIN_MESSAGE,
    CLEAR_LOGIN_PAGE,
    USER_LOGIN,
    USER_VERIFY,
    NEW_REGISTER_USER,
    USER_LOGOUT,
    SET_PICKUPALLOWED,
    UPDATE_USER_LOCALE
} from '../actionTypes/LoginActionTypes';

export const clearLoginMessage = payload => ({
    type: CLEAR_LOGIN_MESSAGE,
    payload
});

export const clearLoginPage = payload => ({
    type: CLEAR_LOGIN_PAGE,
    payload
});

export const submitLogin = payload => ({
    type: USER_LOGIN,
    payload
});

export const submitVerify = payload => ({
    type: USER_VERIFY,
    payload
});

export const submitNewRegisterUser = payload => ({
    type: NEW_REGISTER_USER,
    payload
});

export const logoutUser = payload => ({
    type: USER_LOGOUT,
    payload
});

export const setPickUpIsAllowed = payload => ({
    type: SET_PICKUPALLOWED,
    payload
});

export const updateUserLocale = payload => ({
    type: UPDATE_USER_LOCALE,
    payload
});