import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _get from 'lodash/get';

export default class AutoSuggest {
    /**
     * This is to filter the result set based on search keyword.
     *
     * @param {Object} event
     * @param {Array} list
     * @param {Array} properties
     * @param {Function} callback
     */
    static parse = (event, list, properties, callback) => {
        const keyword = (event.target.value || '').toLowerCase();
        let filteredItems = list;

        if (keyword.length > 2) {
            filteredItems = _filter(list, item => {
                const isMatched = _find(properties, property => {
                    const propValue = _get(item, property, '').trim().toLowerCase();
                    return propValue.includes(keyword);
                });
                return isMatched;
            });
        }

        if (callback) {
            callback(event.target.value, filteredItems);
        } else {
            return filteredItems;
        }
    };
}
