import { fork } from 'redux-saga/effects';
import watchPageContentSaga from './PageContentSaga';
import watchLoginRequest from './LoginSaga';
import watchHomeRequest from './HomeSaga';
import watchUserInfoRequest from './UserInfoSaga';
import watchContactInfoRequest from './ContactMeFormSaga';
import watchProductDetail from './ProductDetailSaga';
import watchOrderListRequest from './OrderListSaga';
import watchOrderDetailsRequest from './OrderDetailsSaga';
import watchProductListRequest from './ProductListSaga';
import watchOrderTemplateRequest from './OrderTemplateSaga';
import watchOrderTemplateDetailsSaga from './OrderTemplateDetailsSaga';
import watchSearchRequest from './SearchSaga';
import watchSelectLanuageRequest from './SelectLanguageSaga';
import watchFavouriteRequest from './FavouriteSaga';
import watchGetLoyaltyNotifications from './LoyaltySaga';
import watchGetAccountStatement from './AccountStatementSaga';
import watchCartRequest from './CartSaga';
import watchDeliveryRequest from './DeliverySaga';
import watchOutletSagaRequest from './OutletSaga';
import watchPromotionSaga from './PromotionSaga';
import watchOrderConfirmSagaRequest from './OrderConfirmSaga';
import watchInvoicesDetail from './InvoicesSaga';
import watchCreditRequest from './CreditInfoSaga';
import watchCheckoutSagaRequest from './CheckoutSaga';
import watchUserMaintenanceRequest from './UserMaintenanceSaga';
import watchAccountDataSagaRequest from './AccountDataSaga';
import watchClaimHistoryRequest from './ClaimHistorySaga';
import marketingSagaRequest from './MarketingSaga';
import watchCoolerRequest from './CoolerDashboardSaga';
import watchMessageRequest from './MessageSaga';
import watchNotifyMeRequest from './NotifyMeSaja';
import watchGetPowerBIToken from './MySalesSaga';
import watchMicrosoftChatBot from './MicrosoftChatBotSaga';

export default function* rootSaga() {
    yield* [
        fork(watchMessageRequest),
        fork(watchPageContentSaga),
        fork(watchLoginRequest),
        fork(watchHomeRequest),
        fork(watchUserInfoRequest),
        fork(watchContactInfoRequest),
        fork(watchProductDetail),
        fork(watchOrderListRequest),
        fork(watchOrderDetailsRequest),
        fork(watchProductListRequest),
        fork(watchOrderTemplateRequest),
        fork(watchOrderTemplateDetailsSaga),
        fork(watchSearchRequest),
        fork(watchSelectLanuageRequest),
        fork(watchFavouriteRequest),
        fork(watchGetLoyaltyNotifications),
        fork(watchGetPowerBIToken),
        fork(watchMicrosoftChatBot),
        fork(watchCartRequest),
        fork(watchDeliveryRequest),
        fork(watchOutletSagaRequest),
        fork(watchPromotionSaga),
        fork(watchOrderConfirmSagaRequest),
        fork(watchInvoicesDetail),
        fork(watchCreditRequest),
        fork(watchCheckoutSagaRequest),
        fork(watchUserMaintenanceRequest),
        fork(watchAccountDataSagaRequest),
        fork(watchClaimHistoryRequest),
        fork(marketingSagaRequest),
        fork(watchCoolerRequest),
        fork(watchNotifyMeRequest),
        fork(watchGetAccountStatement)
    ];
}
