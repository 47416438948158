import {
    GET_ACCOUNT_INFO_REQUEST,
    GET_ACCOUNT_DATA_SUCCESS,
    RESET_ACCOUNT_DATA
} from '../actionTypes/AccountDataActionTypes';
import { PAGE_ID } from '../common/Constants';
import companyFillteredData from '../service/CompanyDataFilter';
import personalFillteredData from '../service/PersonalDataFilter';
import factSheetFillteredData from '../service/FactSheetDataFilter';

const initialState = {};
export default function CommonDataReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_ACCOUNT_INFO_REQUEST:
            return {
                ...initialState
            };
        case GET_ACCOUNT_DATA_SUCCESS: {
            let filteredData = {};
            let { factSheet } = state;

            if (action.data.pageId === PAGE_ID.COMPANY) {
                filteredData = companyFillteredData(action.data.data);
            } else if (action.data.pageId === PAGE_ID.PERSONAL) {
                filteredData = personalFillteredData(action.data.data);
            } else {
                filteredData = factSheetFillteredData(action.data.data);
                factSheet = filteredData.data;
            }

            return {
                ...initialState,
                data: filteredData.data,
                creditData: filteredData.creditData,
                footerData: filteredData.footerData,
                title: filteredData.title,
                factSheet
            };
        }
        case RESET_ACCOUNT_DATA:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
