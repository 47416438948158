import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import * as styles from './Footer.css';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

function OnTopButton({ visible, setVisible, isPlpPage }) {
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        const viewportHeight = document.documentElement.clientHeight;

        if (scrolled > viewportHeight * 4) {
            setVisible(true);
            document.body.classList.add('onTopScrollEnabled');
        } else if (scrolled <= viewportHeight * 4) {
            setVisible(false);
            document.body.classList.remove('onTopScrollEnabled');
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        if (isPlpPage) {
            buttonClickEvent(
                buttonClickEventConfigs.products.actions.footer,
                buttonClickEventConfigs.products.labels.footerBackToTop,
                buttonClickEventConfigs.products.categories.products
            );
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);
        return () => window.removeEventListener('scroll', toggleVisible);
    }, []);

    return (
        <div
            className={cx('onTopButton')}
            onClick={scrollToTop}
            style={{ visibility: visible ? 'visible' : 'hidden' }}
            onKeyPress={scrollToTop}
            role='button'
            tabIndex='-1'
            htmlFor='topLabel'
        >
            <span id='topLabel'>Scroll on top</span>
        </div>
    );
}

export default OnTopButton;
