import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import StepOnePromotionTypeB from './StepOnePromotionTypeB';
import StepTwoPromotionTypeB from './StepTwoPromotionTypeB';

function PromotionTypeB(props) {
    const promotions = useSelector(state => state.promotionsData.promotions)
    const { selectedDeals, activeTabPanelIndex, activeDealIndex, mobilePromotionSummary, selectGroup, isStepThreeActive, mechanicType, key, row } = props;
    const [activePromotion, setActivePromotion] = useState(promotions[activeTabPanelIndex])
    const conditionalGroups = activeDealIndex != null && selectedDeals.conditionalGroups ? selectedDeals.conditionalGroups : [];
    const groupItemsArray = [];
    let achievedPromotionDealIndex = -1

    conditionalGroups.map( group => {
        const groupItems = props.getItemsQuantityPromoB(group);
        groupItemsArray.push(groupItems)
    })

    activePromotion.deals.map((deal, dealIndex) => {
        const dealConditionalGroups = deal.conditionalGroups

        let isNewPromoAchieved = false;

        for (let index = 0; index < dealConditionalGroups.length; index++) {
            const dealGroup = dealConditionalGroups[index];
           

            if(groupItemsArray[index] >= dealGroup.itemAmount){
                isNewPromoAchieved = true;
            }else{
                isNewPromoAchieved = false;
                break;
            }
        }

        if(isNewPromoAchieved){
            achievedPromotionDealIndex = dealIndex
        }
    })

    useEffect(() => {
        setActivePromotion(promotions[activeTabPanelIndex]);

    }, [promotions, activeTabPanelIndex])

    useEffect(() => {
        let isNewPromoAchieved = false;
        let achievedDealText = '';

        if(achievedPromotionDealIndex > -1 ) {
            achievedDealText = props.isLastPromotionActive || promotions.length === 1 ? activePromotion.deals[achievedPromotionDealIndex].onVergeDealMessageLastPromotion : activePromotion.deals[achievedPromotionDealIndex].onVergeDealMessage;
            isNewPromoAchieved = true
        }

        props.handleNewPromotionDiscount(isNewPromoAchieved, achievedDealText)
    }, [achievedPromotionDealIndex])

    return (
        <>
            <StepOnePromotionTypeB
                deals={row.deals}
                showInitial={row.showInitial}
                promotionKey={key}
                activeTabPanelIndex={activeTabPanelIndex}
                mechanicType={mechanicType}
                activeDealIndex={activeDealIndex}
                setPreSelectedDeal={props.setPreSelectedDeal}
                handleStepOneRadioChange={props.handleStepOneRadioChange}
            />
            <StepTwoPromotionTypeB
                mobilePromotionSummary={mobilePromotionSummary}
                selectGroup={selectGroup}
                activeDealIndex={activeDealIndex}
                selectedDeals={selectedDeals}
                isStepThreeActive={isStepThreeActive}
                mechanicType={mechanicType}
                achievedDeal={row.isDealAchieved && activePromotion.achievedDeal}
                getItemsQuantityPromoB={props.getItemsQuantityPromoB}
                getSelectedItemsCount={props.getSelectedItemsCount}
                getNeedToSelectItemsCount={
                    props.getNeedToSelectItemsCount
                }
                onDetailsHandler={props.onDetailsHandler}
                changeConditionalQuantity={
                    props.changeConditionalQuantity
                }
                isStep3Active={
                   props.isStep3Active
                }
                refThis={props.refThis}
            />
            {/* <StepThreePromotion></StepThreePromotion> */}
        </>
    )
}

export default PromotionTypeB
