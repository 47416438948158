import { put, takeLatest, call } from 'redux-saga/effects';

import {
    GET_SEARCH_DATA_REQUEST,
    GET_SEARCH_DATA_SUCCESS,
    GET_SEARCH_DATA_ERROR
} from '../actionTypes/SearchActionTypes';
// import data from '../mock/searchMock'

import FactorySaga from './FactorySaga';
import SearchApi from '../interfaces/Search/SearchApi';

function* searchSaga(action) {
    try {
        const result = yield call(FactorySaga, SearchApi.getSearchPage, action);
        if (result.isSuccess) {
            yield put({
                type: GET_SEARCH_DATA_SUCCESS,
                searchData: { data: result.response.data || [], searchValue: action.searchValue }
            });
        } else {
            yield put({ type: GET_SEARCH_DATA_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_SEARCH_DATA_ERROR });
    }
}

export default function* watchUserInfoRequest() {
    yield takeLatest(GET_SEARCH_DATA_REQUEST, searchSaga);
}
