import _isEmpty from 'lodash/isEmpty';
import { SMART_EDIT } from "../Constants";

/* istanbul ignore file - JSDOM does not support. */
class Storage {
    static storeKey = '__cchbc';

    constructor(isLocalStorage) {
        this.storage = null;

        try {
            if (this.isStorageSupported()) {
                this.storage = isLocalStorage ? localStorage : sessionStorage;
            }
        } catch (ex) {
            // eslint-disable-next-line no-console
            console.error('Web Browser Storage is not available', ex);
        }
    }

    /**
     * To check client side storage is supported or not.
     */
    isStorageSupported = () => {
        const testData = 'testData';

        try {
            sessionStorage.setItem(testData, testData);
            sessionStorage.removeItem(testData);
            return true;
        } catch (e) {
            return false;
        }
    };

    /**
     * To store data in master store key or the given key in Local/Session storage based on the instance used.
     *
     * @param {string} key
     * @param {string} value
     * @param {boolean} storeWithKey
     */
    setData(key, value, storeWithKey) {
        let isSuccess = false;

        if (this.storage) {
            if (storeWithKey) {
                this.storage.setItem(key, JSON.stringify(value));
            } else {
                const storageMap = this.getStorageMap();
                storageMap[key] = value;
                this.storage.setItem(Storage.storeKey, JSON.stringify(storageMap));
            }
            isSuccess = true;
        }

        return isSuccess;
    }

    /**
     * To get data using master store key or the given key from Local/Session storage based on the instance used.
     *
     * @param {string} key
     * @param {boolean} fetchWithKey
     */
    getData(key, fetchWithKey) {
        let value;

        if (this.storage) {
            if (fetchWithKey) {
                const data = this.storage.getItem(key);
                value = !_isEmpty(data) ? JSON.parse(data) : null;
            } else {
                const storageMap = this.getStorageMap();
                value = !_isEmpty(storageMap) ? storageMap[key] : null;
            }
        }

        return value;
    }

    /**
     * To remove data using master store key or the given key from Local/Session storage based on the instance used.
     *
     * @param {string} key
     * @param {boolean} withKey
     */
    removeData(key, withKey) {
        if (this.storage) {
            if (withKey) {
                this.storage.removeItem(key);
            } else {
                const storageMap = this.getStorageMap();
                if (storageMap[key]) {
                    delete storageMap[key];
                    this.storage.setItem(Storage.storeKey, JSON.stringify(storageMap));
                }
            }
        }
    }

    /**
     * To get storage map using master store key from Local/Session storage based on the instance used.
     */
    getStorageMap() {
        if (this.storage) {
            const storageMap = this.storage.getItem(Storage.storeKey);
            return !_isEmpty(storageMap) ? JSON.parse(storageMap) : {};
        }
        return {};
    }

    /*
     * Clears all the items both from Local/Session Storage.
     */
    clearAll() {
        if (this.storage && this.storage.clear) {
            this.storage.clear();
        }
    }

    /*
     * Gets the cmsTicketId from Local Storage for SmartEdit
     */
    getCmsTicketId() {
        let params = '';
        if (window !== window.parent) {
            const cmsTicketId = localStorage.getItem(SMART_EDIT.CMS_TICKET_ID);
            params = `&cmsTicketId=${cmsTicketId}&ehcacheFlush=true`;
        }

        return params;
    }
}

/*
    If you know for sure, later your implemenattion won't get changed to consume between Local/Session storage
    then use this instance.

    Usuage:
    1) import { SessionStorage as Storage } from '../common/Storage/Storage';
    2) import { LocalStorage as Storage } from '../common/Storage/Storage';
*/
export const SessionStorage = new Storage();
export const LocalStorage = new Storage(true);

/*
    TODO: This export will be controlled via application context shortly.
    
    Use this for all general purpose. So later, if we decide to change the entire application usuage between
    Local/Session Storage we can do it here.

    import Storage from '../common/Storage/Storage';
*/
export default LocalStorage;
