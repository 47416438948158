import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _cloneDeep from 'lodash/cloneDeep';
import { CLASIFICATION_BRAND, DATA_LAYER_LISTS, GA_BANNERS_PROPS } from '../common/Constants';
import { getCurrency } from '../common/UserManager/UserManager';

export const getProductPrice = price => {
    if (!price || typeof price === 'number') {
        return price;
    }

    const foundPrice = price.split(' ').join('').match(/\d{1,9}(?:[.,]\d{3})*(?:[.,]\d{2})?/);
    if (!foundPrice) {
        return 0;
    }

    return +Number((`${foundPrice}`).replace(/,/g, '.')).toFixed(2);
};

export const pdpDataLayerFillteredData = pdpData => {
    const priceValue = _filter(pdpData.crmProductInformations, { code: 'stNetValue' });
    let brand = '';

    const { classifications = [] } = pdpData;
    for (let q = 0; q < classifications.length; q += 1) {
        const { features } = classifications[q];
        for (let l = 0; l < features.length; l += 1) {
            if (CLASIFICATION_BRAND === features[l].code) {
                const { featureValues } = classifications[q].features[l];
                for (let n = 0; n < featureValues.length; n += 1) {
                    brand = _get(classifications[q].features[l].featureValues[n], 'value', '');
                }
            }
        }
    }

    return {
        id: pdpData.code.substring(22),
        name: pdpData.name,
        price: _get(priceValue[0], 'value', ''),
        brand
    };
};

export const productImpressionsDataLayerFillterGA4 = (products, listName) => {
    return products.map((product, i) => {
        return {
            item_id: product.sku.id,
            item_name: product.name,
            index: i,
            item_list_id: listName || '',
            item_list_name: listName || '',
            price: product.sku?.price?.formattedPrice
                ? getProductPrice(product.sku?.price?.formattedPrice)
                : getProductPrice(product.listPrice) || 0,
            item_variant: product.baseUnit,
            currency: getCurrency().isocode,
            quantity: 1
        };
    });
};

export const productImpressionsProductListDataLayerFillterGA4 = (products, listName) => {
    return products.map((product, i) => {
        return {
            item_id: product.sku.id,
            item_name: product.name,
            index: i,
            item_list_id: listName || '',
            item_list_name: listName || '',
            price: getProductPrice(product.listPrice) || 0,
            item_variant: product.baseUnit,
            currency: product.currency,
            quantity: 1
        };
    });
};

export const productImpressionsOrderTemplateDataLayerFillterGA4 = (products, listName) => {
    return products.map((product, i) => {
        return {
            item_id: product.id.substring(22),
            item_name: product.name,
            index: i,
            item_list_id: listName || '',
            item_list_name: listName || '',
            item_variant: product.unitCode,
            quantity: product.qty,
            price: 0 // if price is present in the data to change it
        };
    });
};

export const productImpressionsOrderHistoryDataLayerFillterGA4 = (orderEntries, listName) => {
    return orderEntries.map(({ product, totalPrice, qtyType, quantity }, i) => {
        return {
            item_id: product.codeShort,
            item_name: product.name,
            index: i,
            item_list_id: listName || '',
            item_list_name: listName || '',
            item_variant: qtyType,
            price: !!totalPrice?.value && !!quantity ? getProductPrice((totalPrice.value / quantity).toFixed(2)) : 0,
            quantity,
            currency: totalPrice?.currencyIso || getCurrency().isocode
        };
    });
};

export const productImpressionsBdaaDataLayerFilterGA4 = products => {
    return products.map((p, i) => ({
        item_id: p.code.replace(/\b0+/g, ''),
        item_name: p.name,
        index: i,
        item_list_id: DATA_LAYER_LISTS.BDAA_LIST,
        item_list_name: DATA_LAYER_LISTS.BDAA_LIST,
        item_variant: p.baseUnit,
        quantity: 1,
        currency: p.currency,
        price: p.listPrice ? getProductPrice(p.listPrice) : 0
    }));
};

export const productClickHomePageDataLayerFilterGA4 = ({ skuId, name, listPrice, currency, baseUnit, sku }) => {
    return {
        item_id: skuId || sku?.id,
        item_name: name,
        price: getProductPrice(listPrice) || 0,
        quantity: 1,
        currency,
        item_variant: baseUnit
    };
};

export const productClickOrderTemplatDataLayerFilterGA4 = ({ id, name, price, qty, unitCode, currency }) => {
    return {
        item_id: id,
        item_name: name,
        price: price || 0,
        quantity: qty,
        currency,
        item_variant: unitCode
    };
};

export const productClickOrderDetailDataLayerFilterGA4 = ({ code, name }, { value, currencyIso } = {}, quantity) => {
    return {
        item_id: code,
        item_name: name,
        price: value && quantity ? getProductPrice((value / quantity).toFixed(2)) : 0,
        quantity,
        currency: currencyIso
    };
};

export const productClickOrderConfirmDataLayerFilterGA4 = ({
    product: { codeShort, name },
    qtyType,
    quantity,
    totalPrice: { value, currencyIso } = {}
}) => {
    return {
        item_id: codeShort,
        item_name: name,
        price: value && quantity ? getProductPrice((value / quantity).toFixed(2)) : 0,
        quantity,
        item_variant: qtyType,
        currency: currencyIso
    };
};

export const productClickCartItemDetailDataLayerFilterGA4 = ({ codeShort, name }, quantity, qtyType, price, list) => {
    return {
        item_id: codeShort,
        item_name: name,
        price: price || 0,
        quantity,
        item_variant: qtyType,
        item_list_id: list,
        item_list_name: list
    };
};

export const productClickOptimizedCartItemDetailDataLayerFilterGA4 = ({
    codeShort,
    quantity,
    quantityType,
    name,
    price
}) => {
    let calcPrice = getProductPrice(price.formattedValue || price.value) || 0;
    if (calcPrice && quantity) {
        calcPrice = +(calcPrice / quantity).toFixed(2);
    }

    return {
        item_id: codeShort,
        item_name: name,
        price: calcPrice,
        quantity,
        item_variant: quantityType
    };
};

export const removeFromCartOptimizedCartItemDetailDataLayerFilterGA4 = ({
    codeShort,
    quantity,
    quantityType,
    name,
    price,
    index
}) => {
    let calcPrice = getProductPrice(price.formattedValue || price.value) || 0;
    if (calcPrice && quantity) {
        calcPrice = +(calcPrice / quantity).toFixed(2);
    }

    return {
        item_id: codeShort,
        item_name: name,
        price: calcPrice,
        quantity,
        item_list_id: DATA_LAYER_LISTS.MINI_CART_LIST,
        item_list_name: DATA_LAYER_LISTS.MINI_CART_LIST,
        item_variant: quantityType,
        index: index || 0
    };
};

export const cartProductsCheckoutDataLayerFilterGA4 = (index, data) => {
    const { product, totalPrice, quantity, qtyType } = _cloneDeep(data);

    return {
        item_id: product.codeShort,
        item_name: product.name,
        index,
        price:
            getProductPrice(
                !!totalPrice?.value && !!quantity ? (totalPrice.value / quantity).toFixed(2) : totalPrice?.value
            ) || 0,
        quantity,
        currency: totalPrice?.currencyIso,
        item_variant: qtyType
    };
};

export const cartProductsPurchaseDataLayerFilterGA4 = (index, { product, totalPrice, quantity, qtyType }) => {
    return {
        item_id: product.codeShort,
        item_name: product.name,
        index,
        price: totalPrice?.value ? getProductPrice((totalPrice.value / quantity).toFixed(2)) : 0,
        quantity,
        item_variant: qtyType,
        currency: totalPrice?.currencyIso
    };
};

export const homePromotionBannerImpressionsDataLayerFilterGA4 = (banners, blueprint) => {
    if (!blueprint) blueprint = GA_BANNERS_PROPS.STANDARD;
    const { id, name, creative, position } = blueprint;

    const deleteNulls = obj => {
        Object.keys(obj).forEach(key => {
            if (obj[key] === null) delete obj[key];
        });
        return obj;
    };

    if (!Array.isArray(banners)) {
        banners = [banners];
    }

    return banners.map((banner, index) => {
        return deleteNulls({
            item_id: banner[id] || null,
            item_name: banner[name] || null,
            promotion_id: banner[id] || null,
            promotion_name: banner[name] || null,
            creative_name: banner[creative] || null,
            creative_slot: banner[creative] || null,
            index: banner[position] || null
        });
    });
};

export const getGTMAddToCartProductGA4 = (
    index = 0,
    item_name = '',
    item_id = '',
    item_variant = '',
    quantity = 0,
    price = '',
    list = '',
    currency,
    item_brand,
    item_category
) => {
    return {
        item_id,
        item_name,
        index,
        item_brand,
        item_category,
        item_list_id: list,
        item_list_name: list,
        item_variant,
        price: getProductPrice(price) || 0,
        quantity,
        currency
    };
};

export const getGTMRemoveFromCartProductGA4 = (
    index = 0,
    name = '',
    id = '',
    uom = '',
    quantity = 0,
    price = '',
    list = '',
    currency,
    brand,
    category
) => {
    return {
        item_id: id,
        item_name: name,
        index,
        item_brand: brand,
        item_category: category,
        item_list_id: list,
        item_list_name: list,
        price: getProductPrice(price) || 0,
        item_variant: uom,
        quantity,
        currency
    };
};

export const assignProductListIDsGA4 = (() => {
    const assignID = (productData = {}) => {
        productData.item_id = productData.item_id || productData.id;
        if (productData.item_id.length > 18) {
            productData.item_id = productData.item_id.replace(/\b0+/g, '');
        }

        if (productData.item_id.length < 18) {
            productData.item_id = '0'.repeat(18 - productData.item_id.length) + productData.item_id;
        }

        return productData;
    };

    const assignToList = (productList = []) => {
        productList.forEach(assignID);
        return productList;
    };

    const assignToProduct = (productData = {}) => {
        assignID(productData);
        return [productData];
    };

    return productData => (Array.isArray(productData) ? assignToList : assignToProduct)(productData);
})();

export const assignProductListCurrency = (() => {
    const assignCurrency = () => {
        const currentCurrency = getCurrency().isocode;
        return (productData = {}) => {
            productData.currency = productData.currency || currentCurrency;
        };
    };

    const assignToList = (productList = []) => {
        productList.forEach(assignCurrency());
        return productList;
    };

    const assignToProduct = (productData = {}) => {
        assignCurrency()(productData);
        return [productData];
    };

    return productData => (Array.isArray(productData) ? assignToList : assignToProduct)(productData);
})();
