import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Icon from '../../core/Icon/Icon';
import * as styles from './PageHeader.css';

const cx = classNames.bind(styles);

export const PageHeader = props => {
    const { themeIcon, title, showIcon, automationId, themeTitle } = props;

    const addIcon = (
        <Icon className={cx(themeIcon, 'iconLeft')} width='24' height='24' viewBox='0 0 24 24' name='financial-info' />
    );

    return (
        <div className={cx('title', 'col7', 'md7', 'sm12', 'pad0')} automation-id={automationId}>
            {showIcon && <div className={cx('float-left', 'hiddenSm')}>{addIcon}</div>}
            <div className={cx('float-left', 'titleCenter', themeTitle)}>
                <h1>{title}</h1>
            </div>
        </div>
    );
};

PageHeader.propTypes = {
    automationId: PropTypes.string,
    title: PropTypes.string,
    showIcon: PropTypes.bool,
    themeTitle: PropTypes.string
};

PageHeader.defaultProps = {
    automationId: '',
    title: '',
    showIcon: false,
    themeTitle: ''
};

export default PageHeader;
