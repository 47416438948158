export const GET_CONTACT_INFO_REQUEST = 'GET_CONTACT_INFO_REQUEST';
export const GET_CONTACT_INFO_SUCCESS = 'GET_CONTACT_INFO_SUCCESS';
export const GET_CONTACT_INFO_ERROR = 'GET_CONTACT_INFO_ERROR';
export const UPDATE_CONTACT_INFO = 'UPDATE_CONTACT_INFO';
export const UPDATE_CONTACT_INFO_CONCERN = 'UPDATE_CONTACT_INFO_CONCERN';
export const SUBMIT_CONTACT_INFO_FORM = 'SUBMIT_CONTACT_INFO_FORM';
export const HIDE_CONTACT_INFO_FORM = 'HIDE_CONTACT_INFO_FORM';
export const SUBMIT_CONTACT_INFO_FORM_ERROR = 'SUBMIT_CONTACT_INFO_FORM_ERROR';
export const SHOW_CONTACT_INFO_FORM = 'SHOW_CONTACT_INFO_FORM';
export const CLEAR_REDUCER = 'CLEAR_REDUCER';
