import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Tabs from '../../core/Tabs/Tabs';
import Button from '../../core/Button/Button';
import TabPanel from '../../core/Tabs/TabPanel';
import { getMultiCart, openMiniCart } from '../../../actions/CartAction';
import { isIndirectUser, isReturnEmpties, isOrderSplitIndirect } from '../../../common/UserManager/UserManager';
import { formatHungaryTousends, trimString } from '../../../common/Utils/Utils';
import * as styles from './PromotionCartSummary.css';
import Icon from '../../core/Icon/Icon';

const cx = classNames.bind(styles);

export const PromotionCartSummary = props => {
    const {
        title,
        totalExTax,
        checkoutBtnText,
        skuText,
        freeText,
        iconName,
        setActive,
        promotionDetails,
        cartDetails,
        notificationMessageList,
        onClickCheckoutBtn,
        activeCartName
    } = props;

    const faviLabelIcon = <Icon width='24px' height='24px' viewBox='0 0 24 24' name='promo-badge' />;

    const [activeCart, setActiveCart] = useState(0);
    const [isIndirectCustomer, setIsIndirectCustomer] = useState(0);
    const [isReturnEmpty, setIsReturnEmpty] = useState(0);
    const [orderSplitIndirect, setorderSplitIndirect] = useState(0);
    const [refList, setRefList] = useState([]);
    const [toolTipX, setToolTipx] = useState(0);
    const [toolTipY, setToolTipY] = useState(0);
    const [shoppingCartX, setShoppingCartX] = useState(0);
    const [shoppingCartY, setShoppingCartY] = useState(0);
    const [offset, setOffset] = useState(0, 0);

    const toolTipStyles = {
        top: toolTipY,
        left: toolTipX
    };

    const getOffset = e => {
        const el = e.target;
        if (el !== null) {
            const rect = el.getBoundingClientRect();
            const left = rect.left - shoppingCartX;
            const subtractMessageY = notificationMessageList.length > 0 ? 42 : 0;
            const offsetAdjustmentY = 30;
            const top = rect.top - shoppingCartY + offset + offsetAdjustmentY - subtractMessageY;
            setToolTipx(left);
            setToolTipY(top);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';

        setIsIndirectCustomer(isIndirectUser());
        setIsReturnEmpty(isReturnEmpties());
        setorderSplitIndirect(isOrderSplitIndirect);

        if (_isEmpty(cartDetails)) {
            //props.actions.getMultiCart();
			props.actions.openMiniCart();
        }
    }, []);

    useEffect(() => {
        if (!_isEmpty(cartDetails)) {
            window.scrollTo(0, 0);
            document.body.style.overflow = 'hidden';

            const cartHTMLElement = document.querySelector('.PromotionCartSummary-promotionSummaryWrapper');
            if (cartHTMLElement !== null) {
                const rect = cartHTMLElement.getBoundingClientRect();
                const { left } = rect;
                const { top } = rect;
                setShoppingCartX(left);
                setShoppingCartY(top);

                cartHTMLElement.style.position = 'relative';
            }

            window.onscroll = () => {
                setOffset(window.pageYOffset);
            };

            document.body.style.overflow = 'auto';
            setOffset(window.pageYOffset);
            window.scrollTo(0, 0);
        }

        if (!_isEmpty(activeCartName) && !_isEmpty(cartDetails) && cartDetails.cartList.length > 0) {
            const itemIndex = _findIndex(cartDetails.cartList, cart => {
                return cart.cartName.indexOf(activeCartName) !== -1;
            });
            if (itemIndex !== -1) refList[`cartTab-${itemIndex}`].current.props.onSelect(itemIndex);
        }
    }, [activeCartName, cartDetails.cartList]);

    const renderCartSubTotal = () => {
        const subTotal = formatHungaryTousends(_get(cartDetails, `cartList[${activeCart}].cartDetails.subTotal.formattedValue`));

        return !_isEmpty(subTotal) ? (
            <div className={cx('col12', 'md12', 'sm12', 'pad0', 'totalDetails', 'priceContent')}>
                <h3 className={cx('col6')}>{totalExTax}:</h3>
                <span automation-id='at-total-ex-tax-text' className={cx('col6', 'price')}>
                    {subTotal}
                </span>
            </div>
        ) : null;
    };

    const renderPricingTotal = () => {
        return (cartDetails.cartList || []).map(cartInfo => {
            const pricingDetails =
                _find(cartInfo.cchPricingDataList, pricingData => !_isEmpty(pricingData.pricingConditions)) || {};
            const { pricingConditions } = pricingDetails;

            return pricingConditions &&
                pricingConditions.length > 0 &&
                pricingConditions
                .filter(pc => pc?.key.includes('LF_'))
                .map(pc => {
                    return (
                    <div className={cx('pricing')} automation-id='at-list-of-pricing'>
                        <div className={cx('col12', 'md12', 'sm12', 'pad0', 'pricingDetails')} key={pc?.key}>
                            <h3 className={cx('col7', 'title')}>{trimString(pc?.key)}</h3>
                            <span className={cx('totPrice', 'col4')}>{formatHungaryTousends(pc?.value.formattedValue)}</span>
                        </div>
                    </div>
                )
            });
        });
    };

    return (
        <div className={cx(promotionDetails && 'promotionSummaryWrapper')} automation-id='at-shopping-cart-summary'>
            <button type='button' className={cx('mobileViewBtn', setActive && 'visibleClick')}>
                <Icon className={cx('leftIcon')} width='24px' height='24px' viewBox='0 0 20 20' name={iconName} />
                <h1 className={cx('title', 'heading')}>{title}</h1>
            </button>
            {promotionDetails && cartDetails.cartList && (
                <div>
                    <Tabs
                        activeTabPanelIndex={activeCart}
                        themeTabContainerClass={cx('themeTabContainerClass')}
                        automationId='cart-tabs'
                        customTabPanelContentClass={cx('contentWrapper')}
                        onTabChange={setActiveCart}
                    >
                        {cartDetails.cartList.map((cartInfo, index) => {
                            const orderEntries = _get(cartInfo, 'cartDetails.entries.orderEntries', []);

                            const ref = `cartTab-${index}`;
                            if (!refList[ref] && cartInfo.cartItemCount > 0) {
                                refList[ref] = React.createRef();
                                setRefList(refList);
                            }

                            return cartInfo.cartItemCount > 0 ? (
                                <TabPanel
                                    key={`cartName-${index.toString()}`}
                                    title={
                                        (orderSplitIndirect || !isIndirectCustomer) &&
                                        !isReturnEmpty &&
                                        cartInfo.cartTranslatedName &&
                                        `${cartInfo.cartTranslatedName} (${cartInfo.cartItemCount})`
                                    }
                                    index={index}
                                    customTabButtonClass={cx('customTabButtonClass')}
                                    customContainerClass={cx('customTabClass')}
                                    ref={refList[ref]}
                                >
                                    <div>
                                        {orderEntries.length > 0 &&
                                            orderEntries.map((orderEntry, pindex) => (
                                                <div
                                                    key={`${index.toString()}-${pindex.toString()}`}
                                                    className={cx('tabPanList')}
                                                >
                                                    <div className={cx('rightContentDetails')}>
                                                        <div className={cx('col12', 'md12', 'sm12', 'pad0')}>
                                                            <h4
                                                                automation-id='at-product-details-text'
                                                                className={cx('col8')}
                                                            >
                                                                {orderEntry.freeItem && (
                                                                    <span className={cx('freeTxt', 'textBrand')}>
                                                                        {' '}
                                                                        {freeText}{' '}
                                                                    </span>
                                                                )}{' '}
                                                                {orderEntry.product.name}
                                                                {orderEntry.promotion !== undefined &&
                                                                    (orderEntry.promotion.promotionMechanic ===
                                                                        undefined ||
                                                                        orderEntry.promotion.mechanicType === 'B') && (
                                                                        <>
                                                                            <span
                                                                                className={cx('promoInfo-tooltip')}
                                                                                onMouseEnter={e => getOffset(e)}
                                                                            >
                                                                                {faviLabelIcon}
                                                                                <span
                                                                                    style={toolTipStyles}
                                                                                    className={cx(
                                                                                        'promoInfo-tooltip-text'
                                                                                    )}
                                                                                >
                                                                                    {!_isEmpty(
                                                                                        orderEntry.promotion
                                                                                    ) && (
                                                                                        <>
                                                                                            {!_isEmpty(
                                                                                                orderEntry.promotion
                                                                                                    .description
                                                                                            ) && (
                                                                                                <h5>
                                                                                                    <p>
                                                                                                        {
                                                                                                            orderEntry
                                                                                                                .promotion
                                                                                                                .description
                                                                                                        }
                                                                                                    </p>
                                                                                                </h5>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </span>
                                                                            </span>
                                                                        </>
                                                                    )}
                                                            </h4>
                                                            {orderEntry.stockLevel && !cartDetails.hideATP && (
                                                                <span
                                                                    automation-id='at-availability-text'
                                                                    className={cx(
                                                                        'col4',
                                                                        'inStock',
                                                                        `${orderEntry.stockColour || 'default'}bgATP`,
                                                                        orderEntry.stockLevel.length >= 15 &&
                                                                            'availStockLevel'
                                                                    )}
                                                                >
                                                                    {orderEntry.stockLevel}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div
                                                            automation-id='at-product-details-text'
                                                            className={cx('col12', 'md12', 'sm12', 'pad0')}
                                                        >
                                                            <p className={cx('productdes')}>
                                                                {skuText} {orderEntry.product.codeShort}
                                                            </p>
                                                        </div>
                                                        <div className={cx('col12', 'md12', 'sm12', 'pad0')}>
                                                            <h3
                                                                automation-id='at-number-of-cases-text'
                                                                className={cx('col6')}
                                                            >
                                                                {orderEntry.quantity} {orderEntry.qtyTypeName}
                                                            </h3>
                                                            <span
                                                                automation-id='at-price-of-the-products'
                                                                className={cx('col6', 'price')}
                                                            >
                                                                {formatHungaryTousends((orderEntry.basePrice || {}).formattedValue)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </TabPanel>
                            ) : null;
                        })}
                    </Tabs>
                    {renderCartSubTotal()}
                    <div className={cx('logisticFees')}>{renderPricingTotal()}</div>
                    <div className={cx('mobileContinueBtn')}>
                        <Button
                            automationId='at-continue-to-check-out-button'
                            type='button'
                            size='Sm'
                            buttonType='Primary'
                            className={cx('continueBtn', 'sm12', 'visibleSm')}
                            title='checkoutBtn'
                            onClick={onClickCheckoutBtn}
                        >
                            {checkoutBtnText}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

PromotionCartSummary.propTypes = {
    title: PropTypes.string,
    checkoutBtnText: PropTypes.string,
    skuText: PropTypes.string,
    freeText: PropTypes.string,
    iconName: PropTypes.string,
    promotionDetails: PropTypes.bool,
    cartDetails: PropTypes.objectOf(PropTypes.any),
    onClickCheckoutBtn: PropTypes.func
};

PromotionCartSummary.defaultProps = {
    title: '',
    checkoutBtnText: '',
    skuText: '',
    freeText: '',
    iconName: '',
    promotionDetails: false,
    cartDetails: {},
    onClickCheckoutBtn: () => {}
};

const mapStateToProps = state => {
    return {
        cartDetails: _get(state, 'cartDetails.cart'),
        notificationMessageList: _get(state, 'notification.messageList')
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getMultiCart,
			openMiniCart
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(PromotionCartSummary);
