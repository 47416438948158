import {
    SUBMIT_MARKETING_CONSENT,
    GET_MARKETING_CONSENT,
    SET_MARKETING_VISABILITY,
    CLEAR_MARKETING,
    SET_MARKETING_COMMUNICATION_CHANNELS
} from '../actionTypes/MarketingActionTypes';

export const clearMarketingReducer = () => ({
    type: CLEAR_MARKETING
})

export const setMarketingConsent = payload => ({
    type: SUBMIT_MARKETING_CONSENT,
    payload
});

export const getMarketingConsent = paylaod => ({
    type: GET_MARKETING_CONSENT,
    paylaod
});

export const hideMarketingConsent = _ => ({
    type: SET_MARKETING_VISABILITY,
    data: false,
})

export const setMarketingCommunicationChannels = payload => ({
    type: SET_MARKETING_COMMUNICATION_CHANNELS,
    payload
})