import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import classNames from 'classnames/bind';
import Icon from '../../core/Icon/Icon';
import AutoSuggest from '../../../common/AutoSuggest/AutoSuggest';
import { getOutletList, submitSelectedOutlet, getDeliveryInfo } from '../../../actions/PageContentAction';
import { PAGE_ID, GENERIC_L10N } from '../../../common/Constants';
import * as styles from './ChangeOutlet.css';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);
const filterProperties = [
    'mainPartnerAddress.companyName',
    'mainPartnerAddress.city',
    'mainPartnerAddress.street',
    'mainPartnerAddress.district',
    'mainPartnerId'
];

export const ChangeOutlet = props => {
    const {
        actions,
        outletList: { mainPartners = [] },
        id,
        customOutletList,
        customItemList,
        customeOutletContainer,
        commonLocalization
    } = props;
    const [outletList, setOutletList] = useState(mainPartners);
    const [searchOutlet, setSearchOutlet] = useState('');

    useEffect(() => {
        if (_isEmpty(mainPartners)) {
            props.actions.getOutletList();
            props.actions.getDeliveryInfo();
        }
    }, []);

    useEffect(() => {
        if (!_isEqual(outletList, mainPartners)) {
            setOutletList(mainPartners);
        }
    }, [mainPartners]);

    const selectOutlet = (e, partnerId) => {
        if (document.getElementsByClassName('cx-widget')[0]) {
            window._genesys.widgets.bus.command('WebChat.endChat').then(() => {
                window._genesys.widgets.bus.command('WebChat.close');
            });
        }
        e.preventDefault();
        actions.submitSelectedOutlet({ params: { partnerId } });
        buttonClickEvent(
            buttonClickEventConfigs.header.actions.header,
            buttonClickEventConfigs.header.labels.headerOutletChoice,
            buttonClickEventConfigs.header.categories.header
        );
    };

    const filterOutlet = (searchWord = '', outlets = mainPartners) => {
        setSearchOutlet(searchWord);
        setOutletList(outlets);
    };

    const onSearchOutlet = event => {
        setSearchOutlet(event.target.value);
        AutoSuggest.parse(event, mainPartners, filterProperties, filterOutlet);
    };

    const onClose = () => {
        filterOutlet();
    };

    const renderDialog = () => {
        return (
            <div className={cx('outletWrapper')} automation-id='at-list-of-outlet' onMouseLeave={onClose}>
                <div className={cx('searchItems')}>
                    <span className={cx('outLetSearch')}>
                        <Icon
                            className={cx('customSearch')}
                            iconType='svg'
                            wcodeShortth='22px'
                            height='16px'
                            viewBox='0 0 22 16'
                            name='magnifier'
                        />
                    </span>
                    <input
                        type='text'
                        name='filterOutlet'
                        id='filterOutlet'
                        value={searchOutlet}
                        onChange={onSearchOutlet}
                        placeholder={commonLocalization[GENERIC_L10N.SEARCH_PLACE_HOLDER]}
                        autoComplete='off'
                        data-automation-id='at-filter-outlet-text'
                    />
                </div>
                {outletList.map((item, i) => {
                    const { companyName, district, postalcode, city, country } = item.mainPartnerAddress || {};
                    const activeClass = id === item.mainPartnerId && 'activeClass';

                    return (
                        <a
                            automation-id='at-one-of-outlet-list'
                            href='./'
                            className={cx('outletList', customOutletList, activeClass)}
                            key={i.toString()}
                            onClick={e => {
                                selectOutlet(e, item.mainPartnerId);
                            }}
                        >
                            <div className={cx('itemlistSecond', customItemList)}>
                                <ul className={cx('address')}>
                                    <div className={cx('companyName')}>{companyName}</div>
                                    <div className={cx('street')}>{district}</div>
                                    {(postalcode || city || country) && (
                                        <div className={cx('city')}>
                                            {postalcode} {city}, {country}
                                        </div>
                                    )}
                                    <div>{item.mainPartnerId}</div>
                                </ul>
                            </div>
                            {id === item.mainPartnerId && (
                                <div className={cx('itemlist')}>
                                    <div className={cx('tickcontainer')}>
                                        <div className={cx('tickbg')}>
                                            <div className={cx('tick')} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </a>
                    );
                })}
                {outletList.length === 0 && (
                    <div className={cx('noResults')}>
                        <Icon
                            className={cx('noResultsIcon')}
                            iconType='svg'
                            width='18px'
                            height='18px'
                            viewBox='0 0 24 24'
                            name='no-resultsIcon'
                        />{' '}
                        {commonLocalization[GENERIC_L10N.NO_RESULTS_MSG]}
                    </div>
                )}
            </div>
        );
    };

    return <div className={cx('outletContainer', customeOutletContainer)}>{renderDialog()}</div>;
};

ChangeOutlet.propTypes = {
    id: PropTypes.string,
    outletList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    customOutletList: PropTypes.string,
    customItemList: PropTypes.string,
    customeOutletContainer: PropTypes.string,
    commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

ChangeOutlet.defaultProps = {
    id: '',
    outletList: {},
    customOutletList: '',
    customItemList: '',
    customeOutletContainer: '',
    commonLocalization: {}
};

// No of outlets to have scroll.
ChangeOutlet.SCROLL_OUTLET_NO = 5;

const mapStateToProps = state => ({
    outletList: _get(state, 'pageContent.outletList'),
    commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
});

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators({ getOutletList, submitSelectedOutlet, getDeliveryInfo }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeOutlet);
