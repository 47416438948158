import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Icon from '../Icon/Icon';
import * as styles from './Input.css';

const cx = classNames.bind(styles);

function Input(props) {
    const textInput = React.createRef();
    const [showPassword, setShowPassword] = useState(false);

    const handleKeyUp = e => {
        if (e.keyCode === 13 && props.onEnterSubmit) {
            props.onEnterSubmit();
        }
    };

    const onChangeHandler = e => {
        const {
            target: { value }
        } = e;
        if (!value && showPassword) {
            setShowPassword(false);
        }
        props.onChange(e);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const iconName = showPassword ? 'pwd-eye-active' : 'pwd-eye';
    const type = props.passwordIcon && showPassword && 'text';
    return (
        <div className={cx('inputContainer', props.theme)}>
            <input
                className={cx('inputField', props.className)}
                id={props.id}
                automation-id={props.automationId}
                type={type || props.type}
                value={props.value}
                name={props.name}
                placeholder={props.placeholder}
                onChange={onChangeHandler}
                onBlur={props.onBlur}
                onKeyPress={props.onKeyPress}
                onKeyUp={handleKeyUp}
                maxLength={props.maxlength}
                ref={textInput}
                readOnly={props.readOnly}
                disabled={props.disabled}
            />
            {props.passwordIcon && (
                <div className={cx('pwdIcon')}>
                    <button
                        className={cx('passwordBtn')}
                        automation-id='at-show-password-icon'
                        type='button'
                        onClick={toggleShowPassword}
                    >
                        <Icon
                            iconType='svg'
                            width='22px'
                            height='16px'
                            viewBox='0 0 22 16'
                            name={iconName}
                            key={iconName}
                            pathClassName={styles.iconPath}
                        />
                    </button>
                </div>
            )}
        </div>
    );
}

Input.propTypes = {
    id: PropTypes.string,
    theme: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    type: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    passwordIcon: PropTypes.bool,
    automationId: PropTypes.string,
    maxlength: PropTypes.string
};

Input.defaultProps = {
    id: '',
    theme: '',
    type: 'text',
    value: '',
    name: '',
    placeholder: '',
    passwordIcon: false,
    automationId: '',
    maxlength: ''
};

export default Input;
