import React, { useState } from 'react';
import * as styles from './AccountMyServices.css';
import Layout from '../../../components/site/Layout/Layout';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import _get from 'lodash/get';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { browserHistory } from 'react-router';

import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import { ACCOUNT_MY_SERVICES_L10N, MY_ACCOUNT_SERVICES_TABS, PAGE_ID } from '../../../common/Constants';
const cx = classNames.bind(styles);

import { AccountMyServicesClaims } from './AccountMyServicesClaims/AccountMyServicesClaims';
import { AccountMyServicesContactMe } from './AccountMyServicesContactMe/AccountMyServicesContactMe';
import { getPageContent } from '../../../actions/PageContentAction';
import { buttonClickEventConfigs, buttonClickEvent } from '../../../common/GoogleTagManager/GoogleTagManager';

export const AccountMyServices = props => {
    const dispatch = useDispatch();

    const [currentSectionTab, setCurrentSectionTab] = useState('');

    const components = useSelector(state => state.pageContent.components);
    const tabs = React.useMemo(() => {
        const uidsComponents = components.map(c => c?.uid || '');
        const allowedTabs = Object.keys(MY_ACCOUNT_SERVICES_TABS)
            .filter(key => uidsComponents.includes(MY_ACCOUNT_SERVICES_TABS[key].uid))
            .reduce((obj, key) => {
                obj[key] = key;
                return obj;
            }, {});

        return allowedTabs;
    }, [components]);

    const commonLocalization = useSelector(state => state.pageContent.commonLocalization.cchgeneric);
    const localization = useSelector(state => state.pageContent.localization);

    const breadcrumbs = useSelector(state => state.pageContent.breadcrumbs);
    React.useEffect(() => {
        dispatch(getPageContent({ pageId: PAGE_ID.MY_SERVICES }));
    }, []);

    React.useEffect(() => {
        if (props?.params?.section) {
            setCurrentSectionTab(tabs[props?.params?.section]);
        }
    }, [props?.params, tabs]);

    const findLocalizationForTabs = key => {
        if (!commonLocalization || !localization) return '';
        switch (key) {
            case 'claimsTickets':
                return localization[ACCOUNT_MY_SERVICES_L10N?.CLAIM_HISTORY_TITLE];
            case 'requestSupport':
                return commonLocalization[ACCOUNT_MY_SERVICES_L10N?.REQUEST_SUPPORT_TAB];

            default:
                return '';
        }
    };

    const panels = React.useMemo(() => {
        const uidsComponents = components.map(c => c?.uid || '');
        return (
            <>
                {uidsComponents.includes(MY_ACCOUNT_SERVICES_TABS.claimsTickets.uid) && (
                    <TabPanel className={cx('react-tabs__tab-panel')}>
                        <AccountMyServicesClaims />
                    </TabPanel>
                )}

                {uidsComponents.includes(MY_ACCOUNT_SERVICES_TABS.requestSupport.uid) && (
                    <TabPanel className={cx('react-tabs__tab-panel')}>
                        <AccountMyServicesContactMe />
                    </TabPanel>
                )}
            </>
        );
    }, [components]);

    return (
        <Layout showDashboardHeader showOutlet>
            <div>
                <BreadCrumbs breadcrumbValues={breadcrumbs} />
                <div className={cx('commonWidget', 'clearfix', 'accountShoppingList')}>
                    <h2 className={cx('headlineContainer')}>
                        <span className={cx('pageTitle')}>
                            {localization && localization[ACCOUNT_MY_SERVICES_L10N.TITLE]}
                        </span>
                    </h2>
                    {/* <h3 className={cx('subText')}>
                        {localization && localization[ACCOUNT_MY_SERVICES_L10N.SUBTITLE]}
                    </h3> */}
                </div>
                <div className={cx('tabs-holder')}>
                    <Tabs
                        className={cx('react-tabs')}
                        selectedTabClassName={cx('react-tabs__tab--selected')}
                        selectedTabPanelClassName={cx('react-tabs__tab-panel--selected')}
                        selectedIndex={Object.values(tabs).findIndex(tab => tab === currentSectionTab)}
                        onSelect={index => {
                            const tab = Object.keys(tabs)[index];
                            browserHistory.push(`/account/myservices/${tab}`);

                            const gaTabs = {
                                [MY_ACCOUNT_SERVICES_TABS.claimsTickets.name]:
                                    buttonClickEventConfigs.myServices.labels.claimsTickets,
                                [MY_ACCOUNT_SERVICES_TABS.requestSupport.name]:
                                    buttonClickEventConfigs.myServices.labels.claimsTicketsRequestSupport
                            };
                            const foundTab = gaTabs[tab];
                            if (foundTab) {
                                buttonClickEvent(
                                    buttonClickEventConfigs.myServices.actions.claimsTickets,
                                    foundTab,
                                    buttonClickEventConfigs.myServices.categories.myServices
                                );
                            }
                        }}
                    >
                        <TabList className={cx('react-tabs__tab-list')}>
                            {Object.values(tabs).map(tabName => {
                                return (
                                    <Tab key={tabName} className={cx('react-tabs__tab')}>
                                        {findLocalizationForTabs(tabName)}
                                    </Tab>
                                );
                            })}
                        </TabList>

                        {panels}
                    </Tabs>
                </div>
            </div>
        </Layout>
    );
};
