import React from 'react';

export default ({ color, width = '100%' }) => {
    return (
        <svg style={{ height: '24px' }} width={width} height="100%" viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Fill 3</title>
            <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g id="Artboard" transform="translate(-1.000000, -1.000000)" fill={color || '#fff'}>
                    <path
                        d="M10.9455071,28.2047754 C9.12216428,28.2047754 7.64691423,29.7337009 7.64691423,31.6023875 C7.64691423,33.4710742 9.12216428,34.9999996 10.9455071,34.9999996 C12.7688507,34.9999996 14.2606761,33.4710742 14.2606761,31.6023875 C14.2606761,29.7337009 12.7688507,28.2047754 10.9455071,28.2047754 Z M27.5213522,28.2047754 C25.6980086,28.2047754 24.2227594,29.7337009 24.2227594,31.6023875 C24.2227594,33.4710742 25.6980102,34.9999996 27.5213522,34.9999996 C29.3446919,34.9999996 30.8365212,33.4710742 30.8365212,31.6023875 C30.8365212,29.7337009 29.3446952,28.2047754 27.5213522,28.2047754 Z M6.79275187,1 L13.7716895,16.3114355 L24.1033369,16.3131333 C24.6336729,16.3131333 25.1276385,16.0534897 25.4376575,15.6222801 L25.5450039,15.4527064 L32.1166659,3.583112 L35,5.25987056 L28.428338,17.129465 C27.6002188,18.6251961 26.0966928,19.585135 24.437168,19.6992846 L24.1033369,19.7107455 L12.559,19.71 L10.685851,23.4637194 L30.8365212,23.4637194 L30.8365212,26.8613317 L10.685851,26.8613317 C10.1606851,26.8613317 9.64303259,26.7334635 9.17553212,26.4882594 C7.62327029,25.674098 6.96424476,23.7785109 7.62554975,22.1568643 L7.73470137,21.9158413 L10.313,16.75 L4.68149552,4.39600919 L1,4.39761228 L1,1 L6.79275187,1 Z"
                        id="Fill-3" />
                </g>
            </g>
        </svg>
    )
}