import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import * as styles from './Checkbox.css';

const cx = classNames.bind(styles);

const Checkbox = props => {
    const {
        name,
        checked,
        disabled,
        labelText,
        theme,
        onChange,
        themecheckboxCheckmark,
        visibleCount,
        counts,
        id,
        disabledClass,
        isFilter
    } = props;
    const translateValue = isFilter ? "no" : "yes";

    return (
        <label translate={translateValue} className={`${cx('checkboxContainer', disabledClass, theme)}`}>
            {' '}
            {labelText} {visibleCount && <span className={cx('counts')}>({counts})</span>}
            <input
                id={id}
                type='checkbox'
                checked={checked}
                name={name}
                disabled={disabled}
                onChange={e => onChange(e)}
            />
            <span className={cx('checkboxCheckmark', themecheckboxCheckmark)} />
        </label>
    );
};

Checkbox.propTypes = {
    name: PropTypes.string,
    labelText: PropTypes.string,
    disabled: PropTypes.bool,
    isFilter: PropTypes.bool,
    theme: PropTypes.string,
    onChange: PropTypes.func,
    themecheckboxCheckmark: PropTypes.string,
    visibleCount: PropTypes.bool,
    counts: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    checked: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    id: PropTypes.string
};

Checkbox.defaultProps = {
    name: '',
    labelText: '',
    disabled: false,
    theme: '',
    onChange: () => {},
    themecheckboxCheckmark: '',
    visibleCount: false,
    isFilter: false,
    counts: '',
    id: ''
};

export default Checkbox;
