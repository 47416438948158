import React, {useState} from 'react';
import waterIcon from '../../../assets/svg/water.svg';
import waterIconClose from '../../../assets/svg/water-icon-close.svg';
import waterListBG from '../../../assets/svg/water-list-bg.svg';
import waterListIcon from '../../../assets/svg/water-list-icon.svg';
import classNames from 'classnames/bind';
import * as styles from './ProductCards.css';


const cx = classNames.bind(styles);
export const ProductCardsWater = ({deviceType, mode, heightOffset, show, tooltipMessage }) => {
    const [showContent, setShowContent] = useState(false)

    if(!show) return null
    return (
        <div
            style={{top: heightOffset}}
            onClick={e => {
                if (e.target.id === 'closeWater' || deviceType.isDesktop) return;
                setShowContent(true)
                
            }}
            className={cx('WaterIconContainer', mode === 'list' && 'WaterIconContainer-list', showContent && 'enlarge')}
        >
            {mode === 'grid' && (
                <img className={cx('WaterIcon')} src={waterIcon} />
            )}
             {mode === 'list' && (
                <ListWaterImg />
             )}
            <div
                className={cx(
                    'WaterIconTooltipContainer',
                    deviceType.isDesktop ? 'WaterIconTooltipContainer-desktop' : 'WaterIconTooltipContainer-mobile',
                    showContent && 'showWater'
                )}
            >
                <div className={cx('WaterIconTooltipTriangle')}></div>
                <div className={cx('WaterIconTooltipRectangle')}>
                    <p>{tooltipMessage}</p>
                    {!deviceType.isDesktop && (
                        <img
                            className={cx('WaterIconClose')}
                            id='closeWater'
                            onClick={() => {
                                setShowContent(false)
                                
                            }}
                            src={waterIconClose}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};


const ListWaterImg = ()=> {

    return (
        <div className={cx('WaterIconDropContainer')}>
            <img src={waterListBG}  />
            <img className={cx('WaterIconDrop')} src={waterListIcon}  />
        </div>
    )
}