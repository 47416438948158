import { all, put, takeLatest, call } from 'redux-saga/effects';
import _get from 'lodash/get';
import PromotionApi from '../interfaces/Promotion/PromotionApi';
import FactorySaga from './FactorySaga';
import {
    GET_PROMOTIONS_LIST_REQUEST,
    GET_PROMOTIONS_LIST_SUCCESS,
    GET_PROMOTIONS_LIST_ERROR,
    GET_PROMOTION_DEAL_REQUEST,
    GET_PROMOTION_DEAL_SUCCESS,
    GET_PROMOTION_DEAL_ERROR,
    GET_PROMO_CALENDER_LIST_SUCCESS,
    ADD_TO_CART_PROMO,
    ADD_TO_CART_PROMO_ERROR,
    ADD_PROMO_TO_CART_SUCCESS,
    GET_PROMO_CALENDER_LIST_REQUEST,
    REMOVE_PROMO_FROM_CART,
    REMOVE_PROMO_FROM_CART_SUCCESS,
    REMOVE_PROMO_FROM_CART_ERROR,
    GET_PRE_CHECKOUT_UPDATE,
    GET_PRE_CHECKOUT_UPDATE_SUCCESS,
    GET_PRE_CHECKOUT_UPDATE_ERROR
} from '../actionTypes/PromotionsActionTypes';
import { GET_MULTI_CART_SUCCESS } from '../actionTypes/CartActionTypes';
import { notifyWarning, notifyError} from '../actions/NotificationAction';
import { redirectTo } from '../common/Utils/Utils';
import { URL_PATHS } from '../common/Constants';

export function* getPromotionsListSaga(action) {
    try {
        const result = yield call(FactorySaga, PromotionApi.getPromotionsList, action);
        
        if (result.isSuccess && result.response.data.promotionList.length > 0) {
            yield put({ type: GET_PROMOTIONS_LIST_SUCCESS, data: result.response.data });
            redirectTo(URL_PATHS.CHECKOUT_PROMOTIONS);
           
            const messages = [];
           
            const { promoDisclaimerMessage } = result.response.data;
            if (promoDisclaimerMessage) {
                messages.push(promoDisclaimerMessage);
            }
            
            if (messages) {
                yield put(notifyError({ messages, isAutoClose: false }));
            }

        } else {
            yield put({ type: GET_PROMOTIONS_LIST_SUCCESS, data: [] });
            redirectTo(URL_PATHS.CHECKOUT);
        }
    } catch (err) {
        yield put({ type: GET_PROMOTIONS_LIST_ERROR });
    }
}

export function* getPromotionDealSaga(action) {
    try {
        const result = yield call(FactorySaga, PromotionApi.getPromotionDeal, action.payload);

        if (result.isSuccess) {
            yield put({
                type: GET_PROMOTION_DEAL_SUCCESS,
                data: { preselectedDeal: result.response.data, selectedIndex: action.payload.selectedIndex }
            });
        }
    } catch (err) {
        yield put({ type: GET_PROMOTION_DEAL_ERROR });
    }
}

function* addToCartPromoSaga(action) {
    try {
        const result = yield call(FactorySaga, PromotionApi.addToCartPromo, action);

        if (result.isSuccess) {
            yield all([
                put({ type: GET_MULTI_CART_SUCCESS, data: _get(result, 'response.data.multiCartSummary') }),
                put(notifyWarning({ message: _get(result, 'response.data.message'), isAutoClose: true })),
                put({ type: ADD_PROMO_TO_CART_SUCCESS, data: result.response.data.promotionList })
            ]);
        }
    } catch (err) {
        yield put({ type: ADD_TO_CART_PROMO_ERROR });
    }
}

function* removePromoFromCartSaga(action) {
    try {
        const result = yield call(FactorySaga, PromotionApi.removePromoFromCart, action);
        const isResultValid = result => {
            return (
                result.isSuccess &&
                result.response.data.promotionList.promotionList !== undefined &&
                result.response.data.promotionList.promotionList.length > 0
            );
        };

        if (isResultValid(result)) {
            yield all([
                put({ type: REMOVE_PROMO_FROM_CART_SUCCESS, data: result.response.data.promotionList }),
                put(
                    notifyWarning({
                        message: _get(result, 'response.data.message'),
                        isAutoClose: true,
                        autoCloseTime: 7500
                    })
                ),
                put({ type: GET_MULTI_CART_SUCCESS, data: _get(result, 'response.data.multiCartSummary') })
            ]);
        } else {
            redirectTo(URL_PATHS.CHECKOUT);
        }
    } catch (err) {
        yield put({ type: REMOVE_PROMO_FROM_CART_ERROR });
    }
}

export function* getPromotionsCalenderListSaga(action) {
    try {
        const result = yield call(FactorySaga, PromotionApi.getPromotionsCalenderList, action);

        if (result.isSuccess) {
            yield put({ type: GET_PROMO_CALENDER_LIST_SUCCESS, data: result.response.data });
        } else {
            yield put({ type: GET_PROMO_CALENDER_LIST_SUCCESS, data: [] });
        }
    } catch (err) {
        yield put({ type: GET_PROMOTIONS_LIST_ERROR });
    }
}

export function* getPreCheckoutUpdate(action) {
    try {
        const result = yield call(FactorySaga, PromotionApi.getPreCheckoutUpdate, action);

        if (result.isSuccess) {
            yield put({ type: GET_PRE_CHECKOUT_UPDATE_SUCCESS, data: result.response.data });
        } else {
            yield put({ type: GET_PRE_CHECKOUT_UPDATE_ERROR, data: [] });
        }
    } catch (err) {
        yield put({ type: GET_PRE_CHECKOUT_UPDATE_ERROR });
    }
}

export default function* watchPromotionSaga() {
    yield takeLatest(GET_PROMOTIONS_LIST_REQUEST, getPromotionsListSaga);
    yield takeLatest(GET_PRE_CHECKOUT_UPDATE, getPreCheckoutUpdate);
    yield takeLatest(GET_PROMOTION_DEAL_REQUEST, getPromotionDealSaga);
    yield takeLatest(ADD_TO_CART_PROMO, addToCartPromoSaga);
    yield takeLatest(REMOVE_PROMO_FROM_CART, removePromoFromCartSaga);
    yield takeLatest(GET_PROMO_CALENDER_LIST_REQUEST, getPromotionsCalenderListSaga);
}
