export const GET_ORDER_LIST_REQUEST = 'GET_ORDER_LIST_REQUEST';
export const GET_ORDER_LIST_SUCCESS = 'GET_ORDER_LIST_SUCCESS';
export const GET_QUICK_ORDER_LIST_SUCCESS = 'GET_QUICK_ORDER_LIST_SUCCESS';
export const GET_ORDER_LIST_ERROR = 'GET_ORDER_LIST_ERROR';
export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST';
export const REORDER_ORDER_REQUEST = 'REORDER_ORDER_REQUEST';
export const REORDER_ORDER_ERROR = 'REORDER_ORDER_ERROR';
export const UPDATE_ORDER_ID_REQUEST = 'UPDATE_ORDER_ID_REQUEST';
export const UPDATE_CREATION_PERIOD_REQUEST = 'UPDATE_CREATION_PERIOD_REQUEST';
export const UPDATE_PROCESS_TYPE_REQUEST = 'UPDATE_PROCESS_TYPE_REQUEST';
export const CLEAR_ORDER_SEARCH_FORM = 'CLEAR_ORDER_SEARCH_FORM';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const RESET_ORDER_LIST = 'RESET_ORDER_LIST';
export const CLEAR_REDUCER = 'CLEAR_REDUCER';
