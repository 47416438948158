import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import { IMAGE_FORMAT_TYPES } from '../common/Constants';
import { getCurrency } from '../common/UserManager/UserManager';

export const pdpFillteredData = pdpData => {
    const units = [];
    const { conversionFactors, depositProduct, depositProductLogoUrl } = pdpData;
    const itemDetails = [];
    const breadcrumbsObjs = [];
    const priceValue = _filter(pdpData.crmProductInformations, { code: 'pricePerCs' });
    const imagesObjs = _filter(pdpData.images, { format: IMAGE_FORMAT_TYPES.ZOOM });

    const { availableUnits = [] } = pdpData;
    for (let i = 0; i < availableUnits.length; i += 1) {
        const unitsObj = {
            id: i,
            value: availableUnits[i].id,
            label: availableUnits[i].description
        };
        units.push(unitsObj);
    }

    const { classifications = [] } = pdpData;
    for (let q = 0; q < classifications.length; q += 1) {
        const { features } = classifications[q];
        for (let l = 0; l < features.length; l += 1) {
            const { featureValues } = classifications[q].features[l];
            for (let n = 0; n < featureValues.length; n += 1) {
                const code = _get(classifications[q].features[l], 'code', '');
                const name = _get(classifications[q].features[l], 'name', '');
                const value = _get(classifications[q].features[l].featureValues[n], 'value', '');
                const valueUnits = _get(classifications[q].features[l], 'featureUnit.symbol', '');
                const itemDetailObj = {
                    id: q,
                    value: code.includes('capacity') !== true ? `${value}${valueUnits}` : `${value}`,
                    label: name,
                    code
                };
                itemDetails.push(itemDetailObj);
            }
        }
    }

    const { crmProductInformations = [], baseUnit } = pdpData;
    const { length } = itemDetails;
    const currency = getCurrency();
    for (let k = 0; k < crmProductInformations.length; k += 1) {
        const code = _get(crmProductInformations[k], 'code', '');
        const value = _isEmpty(crmProductInformations[k].value) ? '-' : crmProductInformations[k].value;
        const itemDetailObj = {
            id: k + length,
            value: code !== 'pricePerCs' ? `${value}` : `${value} ${currency.symbol}/${baseUnit}`,
            label: _get(crmProductInformations[k], 'name', ''),
            code
        };
        itemDetails.push(itemDetailObj);
    }

    const { breadcrumbs = [] } = pdpData;
    for (let m = 0; m < breadcrumbs.length; m += 1) {
        const breadcrumbObj = {
            id: m,
            url: breadcrumbs[m].url,
            name: breadcrumbs[m].name
        };
        breadcrumbsObjs.push(breadcrumbObj);
    }

    const { description, name, promotion, stockFlag, stockNotified, blockAddToCartEnabled } = pdpData;

    const promotionData = promotion
        ? {
              description: pdpData.promotion.description,
              startDate: pdpData.promotion.formattedStartDate,
              endDate: pdpData.promotion.formattedEndDate,
              note: pdpData.promotion.note,
              conditionalGroup: _get(pdpData, 'promotion.promotionMechanic.conditionalGroups', []),
              freeGroup: _get(pdpData, 'promotion.promotionMechanic.freeGroup', []),
              listOfLimitedProductCodes: pdpData?.promotion?.listOfLimitedProductCodes
          }
        : [];
    return {
        breadcrumbsObjs,
        SKU: pdpData.codeShort,
        code: pdpData.code,
        name,
        description,
        units,
        conversionFactors,
        imagesObjs,
        isFavourite: pdpData.favourite,
        isPromoProduct: !!promotion,
        promotions: promotionData,
        price: priceValue,
        stockFlag,
        stockNotified,
        itemDetails,
        blockAddToCartEnabled,
        depositProduct,
        depositProductLogoUrl
    };
};
