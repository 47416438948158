import {
    GET_PROMOTIONS_LIST_REQUEST,
    GET_PROMOTION_DEAL_REQUEST,
    ADD_TO_CART_PROMO,
    REMOVE_PROMO_FROM_CART,
    RESET_REMOVE_PROMO_FROM_CART_SUCCESS,
    RESET_ADD_PROMO_TO_CART_SUCCESS,
    REMOVE_PRESELECTED_DEAL,
    RESET_ACTIVE_DEAL_INDEX_SUCCESS,
    RESET_PROMOTIONS_LIST,
    GET_PROMO_CALENDER_LIST_REQUEST,
    RESET_PROMO_CALENDER,
    GET_PRE_CHECKOUT_UPDATE,
    CLEAR_REDUCER
} from '../actionTypes/PromotionsActionTypes';

export const clearPromotionsReducer = () => ({
    type: CLEAR_REDUCER
})

export const getPromotionsList = payload => ({
    type: GET_PROMOTIONS_LIST_REQUEST,
    payload
});

export const getPromotionDeal = payload => ({
    type: GET_PROMOTION_DEAL_REQUEST,
    payload
});

export const addToCartPromo = payload => ({
    type: ADD_TO_CART_PROMO,
    payload
});

export const removePromoFromCart = payload => ({
    type: REMOVE_PROMO_FROM_CART,
    payload
});

export const resetRemovePromoFromCart = payload => ({
    type: RESET_REMOVE_PROMO_FROM_CART_SUCCESS,
    payload
});

export const resetAddToCartPromo = payload => ({
    type: RESET_ADD_PROMO_TO_CART_SUCCESS,
    payload
});

export const removePreselectedDeal = payload => ({
    type: REMOVE_PRESELECTED_DEAL,
    payload
});

export const resetReqActiveDealIndex = () => ({
    type: RESET_ACTIVE_DEAL_INDEX_SUCCESS
});

export const resetPromotionList = () => ({
    type: RESET_PROMOTIONS_LIST
});

export const getPromotionCalendarList = payload => ({
    type: GET_PROMO_CALENDER_LIST_REQUEST,
    payload
});

export const resetPromotionCalendar = () => ({
    type: RESET_PROMO_CALENDER
});

export const preCheckoutUpdate = (payload) => ({
    type: GET_PRE_CHECKOUT_UPDATE,
    payload
});

