import {
    GET_PRODUCT_DETAIL_REQUEST,
    GET_PRODUCT_DETAIL_SUCCESS,
    GET_PRODUCT_DETAIL_ERROR,
    RESET_PRODUCT_DETAIL
} from '../actionTypes/ProductDetailTypes';

export const getProductDetails = payload => ({
    type: GET_PRODUCT_DETAIL_REQUEST,
    payload
});

export const getProductSuccess = payload => ({
    type: GET_PRODUCT_DETAIL_SUCCESS,
    payload
});

export const getProductFailure = error => ({
    type: GET_PRODUCT_DETAIL_ERROR,
    error
});

export const resetProductDetails = () => ({
    type: RESET_PRODUCT_DETAIL
});
