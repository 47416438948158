import { all, put, call, takeLatest, select } from 'redux-saga/effects';
import _get from 'lodash/get';
import OrderTemplateApi from '../interfaces/OrderTemplate/OrderTemplateApi';
import FactorySaga from './FactorySaga';
import { getTemplateDetailsSaga } from './OrderTemplateDetailsSaga';
import { SessionStorage } from '../common/Storage/Storage';
import {
    GET_ORDER_TEMPLATE_REQUEST,
    GET_ORDER_TEMPLATE_SUCCESS,
    GET_ORDER_TEMPLATE_ERROR,
    CREATE_ORDER_TEMPLATE_REQUEST,
    DELETE_ORDER_TEMPLATE_REQUEST,
    RESTORE_ORDER_TEMPLATE_REQUEST,
    ADD_ITEM_TO_TEMPLATE,
    RESET_ORDER_TEMPLATE,
    GET_ORDER_TEMPLATE_EXCEL,
    POST_ORDER_TEMPLATE_EXCEL,
    POST_ORDER_TEMPLATE_EXCEL_ERROR
} from '../actionTypes/OrderTemplateActionTypes';
import { GET_MULTI_CART_SUCCESS, CLEAR_MINI_CART } from '../actionTypes/CartActionTypes';
import { notifySuccess, showOrderNotifications } from '../actions/NotificationAction';
import { getActiveCartId, setActiveCartId } from '../common/UserManager/UserManager';
import { redirectTo } from '../common/Utils/Utils';
import { addToCartEventGA4 } from '../common/GoogleTagManager/GoogleTagManager';
import { getGTMAddToCartProductGA4, getProductPrice } from '../service/DataLayerFilter';
import { URL_PATHS, CART_L10N, DATA_LAYER_LISTS, PAGE_ID } from '../common/Constants';
import { getStockAllocation } from '../actions/PageContentAction';

const getAddedToCartItems = state => _get(state, 'cartDetails.miniCartItems');

export function* postOrderTemplateExcel(action) {
    try {
        const result = yield call(FactorySaga, OrderTemplateApi.postOrderTemplateExcel, action);

        const { isSuccess, response } = result;

        if (isSuccess) {
            if (response.data.errors) {
                yield put({
                    type: POST_ORDER_TEMPLATE_EXCEL_ERROR,
                    data: response.data
                });
            } else {
                //TODO: Go to cart page
                if (response.data.message === 'SUCCESS') {
                    setActiveCartId(response.data.cartId);
    
                    yield put({ type: GET_MULTI_CART_SUCCESS, data: response.data.multiCartSummary });
                    SessionStorage.setData(CART_L10N.IS_SKIP_GET_CART, true, true);
                    redirectTo(URL_PATHS.CART);

                    yield put({ type: CLEAR_MINI_CART });
                }
            }
        } else {
            //TODO: show some error;
        }
        
    } catch (err) {
        console.log(err);
    }
}

export function* getOrderTemplateExcel(action) {
    try {
        const result = yield call(FactorySaga, OrderTemplateApi.getOrderTemplateExcel, action);

        const blob = new Blob([result.response.data], {type: 'application/xls'});
        const filename = 'orders_template.xls'
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        }
    } catch (err) {
        console.log(err);
    }
}

export function* getOrderTemplateSaga(action = {}) {
    try {
        const result = yield call(FactorySaga, OrderTemplateApi.getOrderTemplates, action);

        if (result.isSuccess) {
            const { isAllTemplates } = action.payload || {};
            let data;

            if (isAllTemplates) {
                data = { allTemplateList: result.response.data };
            } else {
                data = { templateList: result.response.data };
            }

            yield put({ type: GET_ORDER_TEMPLATE_SUCCESS, data });
        } else {
            yield put({ type: GET_ORDER_TEMPLATE_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_ORDER_TEMPLATE_ERROR });
    }
}

function* createOrderTemplate(action) {
    try {
        const result = yield call(FactorySaga, OrderTemplateApi.createOrderTemplate, action);

        if (result.isSuccess) {
            yield put({ type: RESET_ORDER_TEMPLATE });

            if (_get(action.payload, 'fromTemplateDetails', false)) {
                yield call(getTemplateDetailsSaga, action.payload);
            } else {
                yield call(getOrderTemplateSaga, action);
            }
        }
    } catch (err) {
        yield put({ type: GET_ORDER_TEMPLATE_ERROR });
    }
}

export function* deleteOrderTemplate(action) {
    try {
        const result = yield call(FactorySaga, OrderTemplateApi.deleteOrderTemplate, action);

        if (result.isSuccess) {
            yield put({ type: RESET_ORDER_TEMPLATE });
            yield call(getOrderTemplateSaga, action);
        }
    } catch (err) {
        yield put({ type: GET_ORDER_TEMPLATE_ERROR });
    }
}

export function* restoreOrderTemplate(action) {
    try {
        //if items exist add to existing list... or create new one.
        // let items = yield select(getAddedToCartItems);
        // if(items) {
            // action.payload.postData.products = [
                // ...action.payload.postData.products.reduce((all, item) => [
                //     ...all,
                //     (() => {
                //         let repeatingItem;
                //         items = items.filter((currentItem) => {
                //             if (currentItem.code === item.productCode) {
                //                 repeatingItem = currentItem;
                //             }
                //             return currentItem.code !== item.productCode;
                //         });

                //         if (repeatingItem) {
                //             return {
                //                 ...item,
                //                 quantity: parseInt(item.quantity, 10) + repeatingItem.quantity
                //             }
                //         }
                        
                //         return item
                //     })()
                // ], []),
            //     ...items.reduce((a, e) => [
            //         ...a,
            //         {
            //             ...e,
            //             productCode: e.code
            //         }
            //     ], [])
            // ];
            // action.payload.postData.products = [
            //     ...action.payload.postData.products,
            //     ...items
            // ]
        // }
        //action.payload.postData.products

        const restoreApi = getActiveCartId()
            ? OrderTemplateApi.restoreTemplateToCart
            : OrderTemplateApi.restoreOrderTemplate;
        const result = yield call(FactorySaga, restoreApi, action);

        if (result.isSuccess) {
            if (result?.response?.data?.cartItems?.length) { // GTM AddToCart from /order/template
                try {
                    addToCartEventGA4(result.response.data.cartItems.map(({ qtyType, quantity, entry: { product: { code, name, category, brand }, totalPrice: { value, currencyIso } = {} } }, index) => getGTMAddToCartProductGA4(index, name, code.replace(/\b0+/g, ''), qtyType, quantity, value && quantity ? getProductPrice((value / quantity).toFixed(2)) : 0, DATA_LAYER_LISTS.TEMPLATE_LIST, currencyIso, category, brand)));
                } catch (ex) {
                    console.error(ex);
                }
            }

            const data = _get(result, 'response.data.multiCartSummary', {});
            const cartId = _get(result, 'response.data.cartId');
            const loyaltyDiscountAddedMessage = _get(result, 'response.data.loyaltyDiscountAddedMessage');
            setActiveCartId(cartId);
            yield put(showOrderNotifications({...result.response.data, saveLocal: true}))


            yield put({ type: GET_MULTI_CART_SUCCESS, data });
            yield  put(getStockAllocation({pageId: PAGE_ID.CART}))
            // let messages = [];
            // if (data.promoDisclaimerMessage) {
            //     setPromoDisclaimerMessage(data.promoDisclaimerMessage);
            //         messages.push({
            //             type: MESSAGE_TYPES.WARN,
            //             content: data.promoDisclaimerMessage
            //         })
            // }

            // if (data.loyaltyDiscountAddedMessage || loyaltyDiscountAddedMessage) {
            //     setLoyaltyCartMessage(data.loyaltyDiscountAddedMessage || loyaltyDiscountAddedMessage);
            //         messages.push({
            //             type: MESSAGE_TYPES.BLUE_NOTIFICATION,
            //             content: loyaltyDiscountAddedMessage || data.loyaltyDiscountAddedMessage
            //         })
            // }

            // if (messages.length > 0) {
            //     yield put(showMultipleNotifications({ messages }));
            // }

            SessionStorage.setData(CART_L10N.IS_SKIP_GET_CART, true, true);
            SessionStorage.setData(CART_L10N.SHOW_STOCK_ALLOCATION_MODAL, true, true);
            redirectTo(URL_PATHS.CART);

            //clean store and browser storage
            yield put({ type: CLEAR_MINI_CART });
        }
    } catch (err) {
        yield put({ type: GET_ORDER_TEMPLATE_ERROR });
    }
}

export function* addProductToTemplate(action) {
    try {
        const result = yield call(FactorySaga, OrderTemplateApi.addToTemplate, action);

        if (result.isSuccess) {
            yield all([put({ type: RESET_ORDER_TEMPLATE }), put(notifySuccess(result.response.data))]);
        }
    } catch (err) {
        yield put({ type: GET_ORDER_TEMPLATE_ERROR });
    }
}

export default function* watchOrderTemplateRequest() {
    yield takeLatest(GET_ORDER_TEMPLATE_REQUEST, getOrderTemplateSaga);
    yield takeLatest(CREATE_ORDER_TEMPLATE_REQUEST, createOrderTemplate);
    yield takeLatest(DELETE_ORDER_TEMPLATE_REQUEST, deleteOrderTemplate);
    yield takeLatest(RESTORE_ORDER_TEMPLATE_REQUEST, restoreOrderTemplate);
    yield takeLatest(ADD_ITEM_TO_TEMPLATE, addProductToTemplate);
    yield takeLatest(GET_ORDER_TEMPLATE_EXCEL, getOrderTemplateExcel);
    yield takeLatest(POST_ORDER_TEMPLATE_EXCEL, postOrderTemplateExcel);
}
