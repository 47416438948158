import React from 'react';

import classNames from 'classnames/bind';
import * as styles from './StockQuantity.css';
import { PAGE_ID, GENERIC_L10N } from '../../../common/Constants';
import { useSelector } from 'react-redux';

const cx = classNames.bind(styles);

export const StockQuantity = ({ product }) => {
    const commonLocalization = useSelector(state => state.pageContent.commonLocalization[PAGE_ID.GENERIC]);

    const { isInStock } = product;

    if (typeof isInStock !== 'undefined') {
        return (
            <div className={cx('StockContainer', isInStock ? 'InStock' : 'OutOfStock')}>
                {isInStock ? commonLocalization[GENERIC_L10N.IN_STOCK] : commonLocalization[GENERIC_L10N.OUT_OF_STOCK]}
            </div>
        );
    }
    return <div />;
};
