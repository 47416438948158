export const GET_USER_MAINTENANCE_REQUEST = 'GET_USER_MAINTENANCE_REQUEST';
export const GET_USER_MAINTENANCE_SUCCESS = 'GET_USER_MAINTENANCE_SUCCESS';
export const GET_USER_MAINTENANCE_ERROR = 'GET_USER_MAINTENANCE_ERROR';

export const ADD_NEW_USER_ACCOUNT = 'ADD_NEW_USER_ACCOUNT';

export const EDIT_USER_ACCOUNT = 'EDIT_USER_ACCOUNT';
export const EDIT_USER_ACCOUNT_SUCCESS = 'EDIT_USER_ACCOUNT_SUCCESS';
export const EDIT_USER_ACCOUNT_ERROR = 'EDIT_USER_ACCOUNT_ERROR';

export const DELETE_FROM_USER = 'DELETE_FROM_USER';
export const UPDATE_SOFT_CONSENT_FLAG = 'UPDATE_SOFT_CONSENT_FLAG';

export const RESET_EDIT_SUCCESS = 'RESET_EDIT_SUCCESS';

export const CLEAR_REDUCER = 'CLEAR_REDUCER';
