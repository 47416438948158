export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_ERROR = 'GET_INVOICES_ERROR';
export const CREATE_CLAIM_REQUEST = 'CREATE_CLAIM_REQUEST';
export const CREATE_CLAIM_SUCCESS = 'CREATE_CLAIM_SUCCESS';
export const CREATE_CLAIM_ERROR = 'CREATE_CLAIM_ERROR';
export const GET_FILTER_INVOICES_REQUEST = 'GET_FILTER_INVOICES_REQUEST';
export const GET_FILTER_INVOICES_SUCCESS = 'GET_FILTER_INVOICES_SUCCESS';
export const GET_FILTER_INVOICES_ERROR = 'GET_FILTER_INVOICES_ERROR';
export const SUMBIT_CLAIM_REQUEST = 'SUMBIT_CLAIM_REQUEST';
export const GET_INVOICE_PDF_REQUEST = 'GET_INVOICE_PDF_REQUEST';
export const GET_INVOICE_PDF_SUCCESS = 'GET_INVOICE_PDF_SUCCESS';
export const GET_INVOICE_ZIP_SUCCESS = 'GET_INVOICE_ZIP_SUCCESS';
export const GET_INVOICE_PDF_ERROR = 'GET_INVOICE_PDF_ERROR';
export const RESET_CLAIMS = 'RESET_CLAIMS';
export const CLEAR_INVOICES_PDF_DATA = 'CLEAR_INVOICES_PDF_DATA';
export const RESET_INVOICES = 'RESET_INVOICES';
