/* eslint-disable no-plusplus */
import { call, put } from 'redux-saga/effects';
import {
    getCurrency,
    getDeliveryType,
    getLocalization,
    getToken,
    getUserId,
    logout,
    getActiveCartId,
    isReturnEmpties,
    isUseMarketingMaterials,
    setLogoutReason
} from '../common/UserManager/UserManager';
import { showLoader, removeLoader } from '../actions/LoaderAction';
import { notifyError } from '../actions/NotificationAction';
import { getErrorInfo } from '../common/Utils/Utils';

import { LOGIN_INFO, URL_PATHS, DELIVERY_TYPES } from '../common/Constants';
import { ERROR_CODES } from '../common/MessageCodes';

let counter = 0;

export default function* FactorySaga(api, action = {}) {
    const result = {};
    try {
        if (!action.isSkipLoader && !action?.payload?.isSkipLoader) {
            counter++;
            yield put(showLoader());
        }

        const requestPayload = { baseInfo: {}, ...action, ...action.payload };
        const {
            ACCESS_TOKEN,
            REFRESH_TOKEN,
            CURRENCY,
            ISO_CODE,
            LANG,
            SITE_ID,
            USER_ID,
            CART_ID,
            IS_PICKUP_ORDER,
            IS_RETURN_EMPTIES,
            IS_MARKETING_MATERIALS
        } = LOGIN_INFO;
        const localization = getLocalization();
        const deliveryType = getDeliveryType();
        const tokenInfo = getToken(true);

        requestPayload.baseInfo[ACCESS_TOKEN] = tokenInfo[ACCESS_TOKEN];
        requestPayload.baseInfo[REFRESH_TOKEN] = tokenInfo[REFRESH_TOKEN];
        requestPayload.baseInfo[SITE_ID] = localization[SITE_ID];
        requestPayload.baseInfo[LANG] = localization[LANG];
        requestPayload.baseInfo[CURRENCY] = getCurrency()[ISO_CODE];
        requestPayload.baseInfo[USER_ID] = getUserId();
        requestPayload.baseInfo[CART_ID] = getActiveCartId();
        requestPayload.baseInfo[IS_PICKUP_ORDER] = deliveryType && deliveryType !== DELIVERY_TYPES.ORDER_DRINKS;
        requestPayload.baseInfo[IS_RETURN_EMPTIES] = isReturnEmpties();
        requestPayload.baseInfo[IS_MARKETING_MATERIALS] = isUseMarketingMaterials();

        delete requestPayload.payload;

        result.response = yield call(api, requestPayload);

        switch (result.response.status) {
            case 200:
            case 201:
            case 202:
            case 204: {
                result.isSuccess = true;
                break;
            }
            case 401:
            case 403: {
                result.isSuccess = false;
                // If token expired redirect the user to login page.
                const errorInfo = getErrorInfo(result);
                if (
                    errorInfo.type === ERROR_CODES.INVALID_TOKEN ||
                    errorInfo.type === ERROR_CODES.UNAUTH_ACCESS ||
                    errorInfo.type === ERROR_CODES.FORBIDDEN_ERROR
                ) {
                    logout();
                    window.location.href = URL_PATHS.LOGIN;
                }
                break;
            }
            default: {
                result.isSuccess = false;

                if (!action.isSkipNotifyError) {
                    const error = getErrorInfo(result);

                    if (error.type !== ERROR_CODES.UNKNOWN_ERROR) {
                        yield put(notifyError(getErrorInfo(result)));
                    }
                }
            }
        }
        return result;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('FactorySaga: Error in invoking API: ', error);
        result.isSuccess = false;
        return result;
    } finally {
        if (!action?.payload?.isSkipLoader && !action?.isSkipLoader) {
            counter--;
            if (counter === 0) {
                yield put(removeLoader());
            }

            if (counter < 0) {
                counter = 0;
                yield put(removeLoader());
            }
        }
    }
}
