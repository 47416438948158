export const lv = {
  "lv": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
    "callus": {
      "CallUsTitle": "Sazinaties ar mums",
      "ContactsHeader": "Jūs varat sazināties ar mums izmantojot jebkuru no šiem numuriem...",
      "CancelButtonText": "Atcelt",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Already on a call? Let us <a role='link' tabindex='0' class='cx-cobrowse-link'>browse with you</a></span>",
      "CoBrowse": "Sākt kopīgu pārlūkošanu",
      "CoBrowseWarning": "Kopīga pārlūkošana ļauj jūsu aģentam redzēt un kontrolēt jūsu darba virsmu, palīdz jums ar norādēm. Kad esat tiešajā zvanā ar Aģentu, pieprasiet kodu, la variet uzsākt kopīgu pārlūkošanu un ievadiet to zemāk.  Vēl nezvaniet? vienkārši izejiet no šī ekrāna, lai atgrieztos uz Sazinieties ar mums lapu.",
      "SubTitle": "Jūs varat sazināties ar mums izmantojot jebkuru no šiem numuriem...",
      "AriaWindowLabel": "Sazinieties ar mums logs",
      "AriaCallUsClose": "Aizvērt Sazinieties ar mums",
      "AriaBusinessHours": "Darba laiks",
      "AriaCallUsPhoneApp": "Atver tālruņa lietojumprogrammu",
      "AriaCobrowseLink": "Atver kopīgas pārlūkošanas sesiju",
      "AriaCancelButtonText": "Atcet Sazinieties ar mums"
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector": {
      "Title": "Tiešais asistents",
      "SubTitle": "Kā jūs vēlaties sazināties?",
      "WaitTimeTitle": "Gaidīšanas laiks",
      "AvailableTitle": "Pieejams",
      "AriaAvailableTitle": "Pieejams",
      "UnavailableTitle": "Nepieejams",
      "CobrowseButtonText": "Kopīgās pārlūkošanas apakšnosaukums",
      "CallbackTitle": "Saņemt zvanu",
      "CobrowseSubTitle": "Šim nolūkam nepieciešams aģenta savienojums.",
      "AriaClose": "Aizvērt Tiešais asistents",
      "AriaWarning": "Brīdinājums",
      "AriaAlert": "Brīdināt",
      "minute": "min",
      "minutes": "min",
      "AriaWindowLabel": "Tiešā asistenta logs"
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse": {
      "agentJoined": "Pārstāvis ir pievienojies sesijai.",
      "youLeft": "Jūs izgājāt no sesijas. Kopīgā pārlūkošana pārtraukta",
      "sessionTimedOut": "Sesija pārtraukta. Kopīgā pārlūkošana pārtraukta.",
      "sessionInactiveTimedOut": "Sesija pārtraukta. Kopīgā pārlūkošana pārtraukta.",
      "agentLeft": "Parstāvis ir izgājis no sesijas. Kopīgā pārlūkošana pārtraukta.",
      "sessionError": "Radās negaidīta kļūda. Kopīgā pārlūkošana pārtraukta.",
      "sessionStarted": "Uzsākta kopīgās pārlūkošanas sesija. Gaida pārstāvja pievienošanos sesijai…",
      "navRefresh": "Pārstāvis ir atjaunojis lapu. Pārlādē.",
      "navBack": "Pārstāvis nospieda \"Atpakaļ\" pogu. Pārlādē lapu.",
      "navForward": "Pārstāvis nospieda \"Uz priekšu\" pogu. Pārlādē lapu.",
      "navUrl": "Pārstāvis pieprasīja navigāciju. Pārlādē lapu.",
      "navFailed": "Pārstāvja navigācijas pieprasījums neizdevās.",
      "toolbarContent": "Sesijas ID: {sessionId}",
      "contentMasked": "Saturs pārstāvim ir slēpts.",
      "contentMaskedPartially": "Daļa no satura pārstāvim ir slēpta.",
      "exitBtnTitle": "Iziet no Kopīgās pārlūkošanas sesijas",
      "areYouOnPhone": "Vai jūs esat telefonsarunā ar mūsu pārstāvi?",
      "areYouOnPhoneOrChat": "Vai jūs esat telefonsarunā vai arī tērzēšanā ar mūsu pārstāvi?",
      "connectBeforeCobrowse": "Jums ir jāsazinās ar musu pārstāvi lai turpinātu kopīgo pārlūkošanu. Lūdzu sazinieties ar mums zvanot vai uzsākot tērzēšanu, un tad atkal sāciet kopīgo pārlūkošanu.",
      "sessionStartedAutoConnect": "Kopīgās pārlūkošanas sesija ir uzsākta. Gaida pārstāvja pievienošanos sesijai...",
      "browserUnsupported": "Diemžēl jūsu pārlūks šobrīd netiek atbalstīts.<br><br> Atbalstītie pārlūki ir: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 and above</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 and above</a></li></ul>",
      "chatIsAlreadyRunning": "Tērzēšana jau notiek citā lapā.",
      "modalYes": "Jā",
      "modalNo": "Nē"
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar": {
      "SidebarTitle": "Nepieciešama palīdzība?",
      "ChannelSelectorName": "Tiešais asistents",
      "ChannelSelectorTitle": "Saņemt atbalstu no mūsu aģenta tagad",
      "SearchName": "Meklē",
      "SearchTitle": "Meklē",
      "CallUsName": "Sazinaties ar mums",
      "CallUsTitle": "Sazinaties ar mums sīkāk",
      "CallbackName": "Atzvans",
      "CallbackTitle": "Saņemt zvanu",
      "ClickToCallName": "Nospiest lai zvanītu",
      "ClickToCallTitle": "Pieprasīt klientu servisa tālruņa numuru",
      "SendMessageName": "Sūtīt ziņu",
      "SendMessageTitle": "Sūtīt ziņu",
      "WebChatName": "Tiešsaistes tērzēšana",
      "WebChatTitle": "Tiešsaistes tērzēšana",
      "AriaClose": "Aizvērt izvēlni Nepieciešama palīdzība"
    },
    "webchat": {
      "ChatButton": "Tērzēšana",
      "ChatStarted": "Tērzēšana uzsākta",
      "ChatEnded": "Tērzēšana pabeigta",
      "AgentNameDefault": "Pārstāvis",
      "AgentConnected": "<%Agent%> Savienots",
      "AgentDisconnected": "<%Agent%> Atvienots",
      "BotNameDefault": "Robots",
      "BotConnected": "<%Bot%> Savienots",
      "BotDisconnected": "<%Bot%> Atvienots",
      "SupervisorNameDefault": "Uzraugs",
      "SupervisorConnected": "<%Agent%>Savienots",
      "SupervisorDisconnected": "<%Agent%> Atvienots",
      "AgentTyping": "...",
      "AriaAgentTyping": "Pārstāvis raksta",
      "AgentUnavailable": "Atvainojiet, nevies pārstāvis nav pieejams. Lūdzu mēģiniet vēlāk.",
      "ChatTitle": "Tiešsaistes tērzēšana",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Min",
      "ChatFormFirstName": "Vārds",
      "ChatFormLastName": "Uzvārds",
      "ChatFormNickname": "Iesauka",
      "ChatFormName": "Vārds",
      "ChatFormCustomerId": "Klienta ID",
      "ChatFormEmail": "E-pasts",
      "ChatFormSubject": "Priekšmets",
	    "ChatFormSubjectPassword": "Parole",
	    "ChatFormSubjectService": "Pakalpojums",
	    "ChatFormSubjectOrder": "Pasūtījums",
	    "ChatFormSubjectFinance": "Finanses",
      "ChatFormPlaceholderFirstName": "Nepieciešams",
      "ChatFormPlaceholderLastName": "Nepieciešams",
      "ChatFormPlaceholderName": "Pēc izvēles",
      "ChatFormPlaceholderCustomerId": "Pēc izvēles",
      "ChatFormPlaceholderNickname": "Pēc izvēles",
      "ChatFormPlaceholderEmail": "Nepieciešams",
      "ChatFormPlaceholderSubject": "Pēc izvēles",
      "ChatFormSubmit": "Uzsākt tērzēšanu",
      "AriaChatFormSubmit": "Uzsākt tērzēšanu",
      "ChatFormCancel": "Atcelt",
      "AriaChatFormCancel": "Atcelt tērzēšanu",
      "ChatFormClose": "Aizvērt",
      "ChatInputPlaceholder": "Rakstiet ziņojumu šeit",
      "ChatInputSend": "Sūtīt",
      "AriaChatInputSend": "Sūtīt",
      "ChatEndQuestion": "Vai esiet pārliecināts, ka vēlaties pārtraukt šo tērzēšanas sesiju?",
      "ChatEndCancel": "Atcelt",
      "ChatEndConfirm": "Beigt tērzēšanu",
      "AriaChatEndCancel": "Atcelt",
      "AriaChatEndConfirm": "Beigas",
      "ConfirmCloseWindow": "Vai esiet pārliecināts, ka vēlaties aizvērt tērzēšanu?",
      "ConfirmCloseCancel": "Atcelt",
      "ConfirmCloseConfirm": "Aizvērt",
      "AriaConfirmCloseCancel": "Atcelt",
      "AriaConfirmCloseConfirm": "Aizvērt",
      "ActionsDownload": "Lejupielādēt norakstu",
      "ActionsEmail": "Nosūtīt norakstu uz e-pastu",
      "ActionsPrint": "Izdrukāt norakstu",
      "ActionsCobrowseStart": "Uzsākt kopīgo pārlūkošanu",
      "AriaActionsCobrowseStartTitle": "Atvērt kopīgās pārlūkošanas sesiju",
      "ActionsSendFile": "Pievienot failus",
      "AriaActionsSendFileTitle": "Atvērt faila augšupielādes dialoglogu",
      "ActionsEmoji": "Sūtīt emocijzimi",
      "ActionsCobrowseStop": "Iziet no kopīgās pārlūkošanas",
      "ActionsVideo": "Uzaicināt uz Video tērzēšanu",
      "ActionsTransfer": "Pārsūtīt",
      "ActionsInvite": "Uzaicināt",
      "InstructionsTransfer": "Atveriet šo saiti uz citas iekārtas, lai pārsūtītu jūsu tērzēšanas sesiju</br></br><%link%>",
      "InstructionsInvite": "Kopīgojiet šo saiti ar citu personu, lai pievienotu viņu šai tērzēšanas sesijai</br></br><%link%>",
      "InviteTitle": "Nepieciešama palīdzība?",
      "InviteBody": "Informējiet, ja varam jums palīdzēt.",
      "InviteReject": "Nē paldies",
      "InviteAccept": "Sākt tērzēšanu",
      "AriaInviteAccept": "Pieņemt uzaicinājumu un sākt tērzēšanu",
      "AriaInviteReject": "Noraidīt uzaicinājumu",
      "ChatError": "Radās kļūda uzsākot tērzēšanas sesiju. Lūdzu mēģiniet vēlreiz.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Jā",
      "ChatErrorDefaultButton": "Nē",
      "AriaChatErrorPrimaryButton": "Jā",
      "AriaChatErrorDefaultButton": "Nē",
      "DownloadButton": "Lejupielādet",
      "AriaDownloadButton": "Lejupielādēt failu",
      "FileSent": "ir nosūtīts:",
      "FileTransferRetry": "Atkārtot",
      "AriaFileTransferRetry": "Atkārtot faila pārsūtīšanu",
      "FileTransferError": "Labi",
      "AriaFileTransferError": "Labi",
      "FileTransferCancel": "Atcelt",
      "AriaFileTransferCancel": "Atcelt faila pārsūtīšanu",
      "RestoreTimeoutTitle": "Tērzēšana pārtraukta",
      "RestoreTimeoutBody": "Jūsu iepriekšējā tērzēšanas sesija ir pārtraukta. Vai vēlaties sākt jaunu?",
      "RestoreTimeoutReject": "Nē paldies",
      "RestoreTimeoutAccept": "Sākt tērzēšanu",
      "AriaRestoreTimeoutAccept": "Pieņemt uzaicinājumu un sākt tērzēšanu",
      "AriaRestoreTimeoutReject": "Noraidīt uzaicinājumu",
      "EndConfirmBody": "Vai jūs tiešām vēlaties pārtraukt savu tērzēšanas sesiju?",
      "EndConfirmAccept": "Beigt tērzēšanu",
      "EndConfirmReject": "Atcelt",
      "AriaEndConfirmAccept": "Beigt tērzēšanu",
      "AriaEndConfirmReject": "Atcelt",
      "SurveyOfferQuestion": "Vai jūs vēlaties piedalīties aptaujā?",
      "ShowSurveyAccept": "Jā",
      "ShowSurveyReject": "Nē",
      "AriaShowSurveyAccept": "Jā",
      "AriaShowSurveyReject": "Nē",
      "UnreadMessagesTitle": "neizlasīts",
      "AriaYouSaid": "Jūs teicāt",
      "AriaSaid": "Teikts",
      "AriaSystemSaid": "Sistēma teica",
      "AriaWindowLabel": "Tiešsaistes tērzēšanas logs",
      "AriaMinimize": "Tiešsaistes tērzēšana samazināt",
      "AriaMaximize": "Tiešsaistes tērzēšana palielināt",
      "AriaClose": "Tiešsaistes tērzēšana Aizvērt",
      "AriaEmojiStatusOpen": "Emocijzīmju izvēlne ir atvērta",
      "AriaEmojiStatusClose": "Emocijzīmju izvēlne ir aizvērta",
      "AriaEmoji": "emocijzīme",
      "AriaEmojiPicker": "Emocijzīmes izvēlne",
      "AriaCharRemaining": "Atlikušais zīmju skaits",
      "AriaMessageInput": "Ziņas logs",
      "AsyncChatEnd": "Beigt tērzēšanu",
      "AsyncChatClose": "Aizvērt Logu",
      "AriaAsyncChatEnd": "Beigt tērzēšanu",
      "AriaAsyncChatClose": "Aizvērt Logu",
      "DayLabels": [
        "Svētd",
        "Pirmd",
        "Otrd",
        "Trešd",
        "Ceturtd",
        "Piektd",
        "Sestd"
      ],
      "MonthLabels": [
        "Janv",
        "Febr",
        "Marts",
        "Apr",
        "Maijs",
        "Jūn",
        "Jūl",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "todayLabel": "Šodien",
      "Errors": {
        "102": "Nepieciešams vārds.",
        "103": "Nepieciešams uzvārds.",
        "161": "Lūdzu ierakstiet savu vārdu.",
        "201": "Failu nevar nosūtīt.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Tiktu pārsniegts maksimāli pieļaujamias failu skaits(<%MaxFilesAllowed%>).</p>",
        "202": "Failu nevar nosūtīt.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Tiks pārsniegts augšupielādes ierobežojums un/ vai maksimālais pielikumu skaits.(<%MaxAttachmentsSize%>).</p>",
        "203": "Failu nevar nosūtīt.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Faila veids nav atļauts.</p>",
        "204": "Atvainojiet, bet jūsu ziņojums ir par garu. Lūdzu uzrakstiet īsāku ziņojumu.",
        "240": "Atvainojiet, bet mēs šobrīd nevaram uzsākt jaunu tērzēšanu. Lūdzu mēģiniet vēlreiz vēlāk",
        "364": "Nepareiza e-pasta adrese.",
        "401": "Aivainojiet, bet mēs nevaram autorizēt tērzēšanas sesiju. Vai jūs vēlētos uzsākt jaunu tērzēšanu?",
        "404": "Aivainojiet, bet mēs nevaram atrast jūsu iepriekšējo tērzēšanas sesiju. Vai jūs vēlaties sākt jaunu tērzēšanu?",
        "500": "Aivainojiet, bet radās neparedzēta kļūda ar pakalpojumu. Vai vēlaties aizvērt un sākt jaunu Tērzēšanu?",
        "503": "Atvainojiet, pakalpojums šobrīd nav pieejams vai ir aizņemts. Vai vēlaties aizvērt un sākt jaunu tērzēšanu atkal",
        "ChatUnavailable": "Atvainojiet, bet mēs nevaram šobrīd uzsākt jaunu tērzēšanu. Lūdzu mēģiniet vēlāk.",
        "CriticalFault": "Nezināmu iemeslu dēļ jūsu tērzēšanas sesija ir pārtraukta. Atvainojamies par sagādātajām neērtībām",
        "StartFailed": "Sākot tērzēšanas sesiju radās problēma. Lūdzu pārbaudiet savu savienojumu un vai esat iesnieguši pareizi visu nepieciešamo informāciju, tad mēģiniet vēlreiz  ",
        "MessageFailed": "Jūsu ziņa netika veiksmīgi saņemta. Lūdzu mēģiniet vēlreiz.",
        "RestoreFailed": "Atvainojamies, bet nezināmas kļūdas dēļ mums neizdevās atjaunot jūsu tērzēšanas sesiju",
        "TransferFailed": "Šobrīd nav iespējams pārsūtīt tērzēšanu. Lūdzu mēģiniet atkal vēlāk",
        "FileTransferSizeError": "Failu nevar nosūtīt.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Ir pārsniegts pieļaujamais faila lielums (<%MaxSizePerFile%>).</p>",
        "InviteFailed": "Pašlaik nevar izveidot ielūgumu. Lūdzu mēģiniet vēlāk.",
        "ChatServerWentOffline": "Šobrīd ziņu nosūtīšana aizņem ilgāku laiku nekā ierasts. Mēs atvainojamies par kavēšanos.",
        "RestoredOffline": "Šobrīd ziņu nosūtīšana aizņem ilgāku laiku nekā ierasts. Mēs atvainojamies par kavēšanos.",
        "Disconnected": "<div style='text-align:center'>Zaudēts savienojums</div>",
        "Reconnected": "<div style='text-align:center'>Atjaunots savienojums</div>",
        "FileSendFailed": "Failu nevar nosūtīt.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>Bija negaidīta atvienošanās. Mēģināt vēlreiz?</p>",
        "Generic": "<div style='text-align:center'>Uzradās negaidīta kļūda.</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Nederīgs faila veids. Atļauti tikai attēli",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "Fails ir lielāks par atļauto izmēru",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "Ir pārsniegts maksimāli pieļaujamais failu skaits",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Nederīga x-api-key pārsūtīšanas konfigurācija.",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Nederīga galapunkta pārsūtīšanas konfigurācija.",
        "pureengage-v3-rest-INVALID_NICKNAME": "Nepieciešams vārds.",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "Atvainojamies, bet nevaram autorizēt tērzēšanas sesiju.",
        "purecloud-v2-sockets-400": "Atvainojiet kaut kas nogāja greizi. Lūdzu pārbaudiet savu savienojumu un vai esat iesnieguši pareizi visu nepieciešamo informāciju, tad mēģiniet vēlreiz",
        "purecloud-v2-sockets-500": "Aivainojiet, bet radās neparedzēta kļūda ar pakalpojumu.",
        "purecloud-v2-sockets-503": "Atvainojiet, pakalpojums šobrīd nav pieejams."
      }
    }
  }
}
