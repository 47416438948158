import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import classNames from 'classnames/bind';
import Button from '../../../core/Button/Button';
import RadioButton from '../../../core/RadioButton/RadioButton';
import CreateNewTemplate from '../../CreateNewTemplate/CreateNewTemplate';
import { addItemsToTemplate } from '../../../../actions/OrderTemplateAction';
import { PAGE_ID, GENERIC_L10N } from '../../../../common/Constants';
import * as styles from './OrderTemplateModalContent.css';

const cx = classNames.bind(styles);

export const OrderTemplateModalContent = props => {
    const [ selectedTemplate, setSelectedTemplate ] = useState();

    const changeSelectedTemplate = template => {
        setSelectedTemplate(template);
    }


    const handleSelect = () => {
        const { actions, basicProductDetails, orderTemplates } = props;
        // const template = document.getElementsByName('template');

        if (selectedTemplate) {
            const payload = {
                data: {
                    code: selectedTemplate.code,
                    products: basicProductDetails
                }
            };
            actions.addItemsToTemplate(payload);
        }

        // for (let i = 0; i < template.length; i += 1) {
        //     /* istanbul ignore next */
        //     if (template[i].checked) {
        //         const templates = _get(orderTemplates, 'carts', []);
        //         const selectedTemplate = templates.find(item => item.name === template[i].value);
        //         const payload = {
        //             data: {
        //                 code: selectedTemplate.code,
        //                 products: basicProductDetails
        //             }
        //         };
        //         actions.addItemsToTemplate(payload);
        //     }
        // }
        props.modalClose();
    };

    const { orderTemplates, customOrderTemplate, basicProductDetails, modalClose, commonLocalization } = props;
    const templatesList = _get(orderTemplates, 'carts', []);

    return (
        <div
            className={cx('modalContentContainer')}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation()
            }}>
            <div className={cx('myTemplates')}>
                <h3 automation-id='at-my-templates-text' className={cx('subTitle')}>
                    {commonLocalization[GENERIC_L10N.TEMP_TITLE]}
                </h3>
                <div className={cx('innerContent')}>
                    <p automation-id='at-add-item-to-existing-template-text ' className={cx('content')}>
                        {commonLocalization[GENERIC_L10N.TEMP_SELECT]}
                    </p>
                    <div className={cx('listItems')}>
                        <ul className={cx('templateList')}>
                            {templatesList.map((item, i) => (
                                <li
                                    key={`${item.name}-${Math.random()}`}
                                    automation-id='at-list-of-order-template-created'
                                >
                                    <span
                                        automation-id='at-name-of-created-template'
                                        className={cx('linkpad', 'pointer')}
                                    >
                                        <RadioButton
                                            theme={cx('customRadio')}
                                            name='template'
                                            labelText={item.name}
                                            id={`tempRadio${i}`}
                                            customCheckMark={cx('checkmark')}
                                            value={item.name}
                                            checked={selectedTemplate && selectedTemplate.code === item.code}
                                            onChange={() => {
                                                changeSelectedTemplate(item)
                                            }}
                                        />
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={cx('selBtn')}>
                    <Button
                        automation-id='Select Button'
                        className={cx('selectButton')}
                        type='button'
                        size='Sm'
                        buttonType='Primary'
                        onClick={handleSelect}
                    >
                        {commonLocalization[GENERIC_L10N.TEMP_MY_DESC]}
                    </Button>
                </div>
            </div>
            <div className={cx('visibleSm', 'sm12')}>
                <p className={cx('lineOr')}>
                    <span>OR</span>
                </p>
            </div>
            <div className={cx('createNewTemplate', customOrderTemplate)}>
                <h3 automation-id='at-create-new-template-text' className={cx('subTitle')}>
                    {commonLocalization[GENERIC_L10N.TEMP_NEW_TITLE]}
                </h3>
                <CreateNewTemplate fromAddToOrderTemplate basicProductDetails={basicProductDetails} />
            </div>
            <div className={cx('cancleBtn')}>
                <Button
                    automation-id='cancelButton'
                    className={cx('cancelButton', 'float-right')}
                    type='button'
                    size='Sm'
                    buttonType='Default'
                    onClick={modalClose}
                >
                    {commonLocalization[GENERIC_L10N.CANCEL_BTN]}
                </Button>
            </div>
        </div>
    );
};

OrderTemplateModalContent.propTypes = {
    orderTemplates: PropTypes.objectOf(PropTypes.any),
    customOrderTemplate: PropTypes.string,
    basicProductDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    modalClose: PropTypes.func,
    commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

OrderTemplateModalContent.defaultProps = {
    orderTemplates: {},
    customOrderTemplate: '',
    basicProductDetails: {},
    modalClose: () => {},
    commonLocalization: {}
};

const mapStateToProps = state => ({
    orderTemplates: _get(state, 'orderTemplate.allTemplateList'),
    commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
});

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators({ addItemsToTemplate }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderTemplateModalContent);
