import React from 'react';

import _find from 'lodash/find';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames/bind';
import Countdown from 'react-countdown';
import { useSelector } from 'react-redux';

import moment from 'moment-timezone';
import { PLP_L10N, PAGE_ID, GENERIC_L10N, PROMO_CALENDAR_L10N } from '../../../common/Constants';
import * as styles from './PromotionsCard.css';
import Button from '../../../components/core/Button/Button';
import Icon from '../../../components/core/Icon/Icon';
import { mainPartnerCode } from '../../../common/UserManager/UserManager';
import countriesWithTimeZones from '../../../countries_with_timeZones.json';
import { getUpdatedURL } from '../../../common/Utils/Utils';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

const PromotionsCard = props => {
    const { promo, promoBanner } = props;
    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);
    const deviceType = useSelector(state => state.context.deviceType);
    const localization = useSelector(state => state.pageContent.localization);
    const commonLocalization = useSelector(state => state.pageContent.commonLocalization[PAGE_ID.GENERIC]);

    const mainPartnerCountryCode = mainPartnerCode();

    const timeZone =
        Intl.DateTimeFormat()?.resolvedOptions()?.timeZone ||
        countriesWithTimeZones[mainPartnerCountryCode]?.TimeZones[0];

    const endDate = moment.tz(promo?.endDate, timeZone);

    let bannerImg;

    if (promo?.banners?.desktopImage) {
        bannerImg = promo.banners.desktopImage;
    }

    if (promo?.banners?.tabletImage && deviceType.isTablet) {
        bannerImg = promo.banners.tabletImage;
    }
    if (promo?.banners?.mobileImage && deviceType.isMobile) {
        bannerImg = promo.banners.mobileImage;
    }
    const imgUrl = bannerImg
        ? `${assetBaseUrl}/${bannerImg}`
        : promoBanner?.url
        ? `${assetBaseUrl}/${promoBanner?.url}`
        : '/assets/images/promo-banner-desktop.png';

    return (
        <div className={cx('cardContainer')}>
            <div className={cx('cardBanner', (bannerImg || promoBanner?.url) && 'cardBannerImg')}>
                <img
                    className={cx('promoImg', (bannerImg || promoBanner?.url) && 'promoImgFull')}
                    src={imgUrl}
                    alt={promoBanner?.altText || ''}
                />
            </div>

            {promo && (
                <div className={cx('titleContainer')}>
                    <p className={cx('title')}>{promo.description}</p>
                    {promo?.listOfLimitedProductCodes?.length > 0 && (
                        <div className={cx('limitedQuantityContainer')}>
                            <Icon iconType='svg' width='16px' height='16px' viewBox='0 0 22 22' name='info-red' />
                            <p className={cx('limitedQuantityLabel')}>
                                {commonLocalization && commonLocalization[GENERIC_L10N.LIMITED_QUANTITY]}
                            </p>
                        </div>
                    )}
                    {promo.note && (
                        <h5 className={cx('description')}>
                            {commonLocalization && commonLocalization[GENERIC_L10N.DETAILS]}:{' '}
                            <span className={cx('description')}>{promo.note}</span>
                        </h5>
                    )}
                    <div className={cx('productInfoContainer')}>
                        {promo?.conditionalGroups.map((group, index) => {
                            return (
                                <div key={`promotion-link-${index + 1}`}>
                                    <h5 className={cx('description')}>
                                        {localization[PLP_L10N.GROUP]} {index + 1}:
                                    </h5>

                                    {group?.productInfos.map(({ name, codeShort }) => {
                                        return (
                                            <p className={cx('description', 'point')} key={name}>
                                                {name} - {codeShort}
                                            </p>
                                        );
                                    })}
                                </div>
                            );
                        })}
                        {promo?.freeGroup?.productInfos?.length && (
                            <div>
                                <h5 className={cx('description')}>{localization[GENERIC_L10N.FREE]}:</h5>
                                {promo.freeGroup.productInfos.map(({ name, codeShort }) => (
                                    <p className={cx('description', 'point')} key={name}>
                                        {name} - {codeShort}
                                    </p>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}

            <div className={cx('endContent')}>
                {promo.formattedStartDate && promo.formattedEndDate && promo.endDate && (
                    <div className={cx('countdownContainer')}>
                        <p className={cx('endsIn')}>{localization[PROMO_CALENDAR_L10N.ENDS_IN]}</p>

                        <Countdown
                            date={endDate}
                            renderer={({ days, hours, minutes }) => {
                                return (
                                    <div className={cx('countdown')}>
                                        {days}
                                        {commonLocalization?.day} : {hours > 9 ? hours : `0${hours}`}
                                        {commonLocalization?.hour} : {minutes > 9 ? minutes : `0${minutes}`}
                                        {commonLocalization?.minute}
                                    </div>
                                );
                            }}
                        />
                        <p className={cx('validFrom')}>
                            {localization[PROMO_CALENDAR_L10N.VALID_FROM]} {promo?.formattedStartDate}{' '}
                            {localization[PROMO_CALENDAR_L10N.UNTIL]} {promo?.formattedEndDate}
                        </p>
                    </div>
                )}
                <div className={cx('buttonContainer')}>
                    <Button
                        className={cx('addtoCart')}
                        type='button'
                        size='Sm'
                        buttonType='addIcon'
                        onClick={e => {
                            getUpdatedURL('order', '', {
                                data: {
                                    page: [1],
                                    promotion: [promo.description]
                                }
                            });

                            buttonClickEvent(
                                buttonClickEventConfigs.promotions.actions.promotionTitle,
                                buttonClickEventConfigs.promotions.labels.promotionTitleShopProducts,
                                buttonClickEventConfigs.promotions.categories.promotions
                            );
                        }}
                    >
                        {localization[PROMO_CALENDAR_L10N.SHOP_PRODUCTS]}
                        <Icon
                            className={cx('addIcon')}
                            width='20px'
                            height='18px'
                            viewBox='0 -2 19 17'
                            name='addCart-Icon'
                        />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PromotionsCard;
