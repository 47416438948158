import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Icon from '../Icon/Icon';
import * as styles from './MessageBox.css';
import Link from 'react-router/lib/Link';
import { MESSAGE_TYPES, MESSAGE_LEVELS } from '../../../common/Constants';

const cx = classNames.bind(styles);

const BOX_TYPES = Object.values(MESSAGE_TYPES);
const BOX_LEVEL = Object.values(MESSAGE_LEVELS);

/** @description render the MessageBox field */
function MessageBox(props) {
    const {
        theme,
        id,
        type,
        level,
        showMessage,
        title,
        children,
        closeBtn,
        onClose,
        customMsgContent,
        infoIcon,
        isAutoClose,
        autoCloseTime,
        iconStyle,
        fullWidth
    } = props;

    if (isAutoClose) {
        setTimeout(() => {
            onClose(id);
        }, autoCloseTime);
    }

    return (
        <section className={cx('messageBoxContainer')}>
            {showMessage ? (
                <div
                    id={id}
                    className={cx('messageBoxWrapper', `${type}Message`, type, theme)}
                    automation-id='at-message-box'
                >
                    <div style={{ width: fullWidth && "100%" }} className={cx('messageBoxBlock', `${level}Level`)} automation-id='at-msgbox-block'>
                        <div className={cx('sm11', 'md11', 'lg11', 'xl11', 'messageGrid')}>
                            <div className={cx('messageContent')} automation-id='at-message-content'>
                                <p className={cx('messageTitle')} automation-id='title'>
                                    {title}
                                </p>
                                {children ? (
                                    <div className={cx('messageText', customMsgContent)} automation-id='at-message-Txt'>
                                        {infoIcon && (
                                            <Icon
                                                className={cx('globalInfo', iconStyle)}
                                                iconType='svg'
                                                width='16px'
                                                height='16px'
                                                viewBox='0 0 40 40'
                                                name='info'
                                            />
                                        )}
                                        {children}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        {closeBtn ? (
                            <div className={cx('sm1', 'md1', 'lg1', 'xl1')}>
                                <button
                                    onClick={() => onClose(id)}
                                    type='button'
                                    id='close'
                                    className={cx('closebtn', `closebtn${type}Message`)}
                                    aria-label='close'
                                >
                                    <Icon
                                        className={cx('globalClose', type)}
                                        iconType='svg'
                                        width='25px'
                                        height='25px'
                                        viewBox='0 0 24 25'
                                        name='close-global'
                                    />
                                </button>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            ) : (
                ''
            )}
        </section>
    );
}

MessageBox.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.oneOf(BOX_TYPES),
    level: PropTypes.oneOf(BOX_LEVEL),
    showMessage: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    closeBtn: PropTypes.bool,
    theme: PropTypes.string,
    onClose: PropTypes.func,
    isAutoClose: PropTypes.bool,
    autoCloseTime: PropTypes.number,
    customMsgContent: PropTypes.string,
    infoIcon: PropTypes.bool,
    fullWidth: PropTypes.bool
};

MessageBox.defaultProps = {
    id: '',
    type: MESSAGE_TYPES.INFO,
    level: MESSAGE_LEVELS.PAGE,
    showMessage: true,
    title: '',
    children: null,
    closeBtn: true,
    theme: '',
    onClose: () => { },
    isAutoClose: false,
    autoCloseTime: 3000,
    customMsgContent: '',
    infoIcon: false,
    fullWidth: false
};

export default MessageBox;
