import React from 'react';
import {useSelector} from "react-redux";
import {NotificationTypes} from "../types";
import {CreditIssueSnippet} from "../Snippet/CreditIssueSnippet";
import {CreditIssuePopup} from "../CreditIssuePopup/CreditIssuePopup";
import PropTypes from "prop-types";
import {useDeviceType} from "../../../../common/Device/Device";


export const CreditIssueNotification = (props) => {
    const notification = useSelector(store => store.creditInformation.creditLimitNotification);
    const showNotification = useSelector(store => store.creditInformation.showNotification);
    const deviceType = useDeviceType();

    if (!notification) return null;

    if (props.mobileOnly === true && deviceType !== "mobile") return null;
    const { notificationType, notificationLoginMessage, messageHeader, showInvoiceButton } = notification;

    return <>
        {notificationType === NotificationTypes.SNIPPET &&
            <CreditIssueSnippet text={notificationLoginMessage} isOpen={showNotification}
                                fixed={props.fixed} />}
        {notificationType === NotificationTypes.POPUP &&
            <CreditIssuePopup text={notificationLoginMessage} isOpen={showNotification}
                              title={messageHeader} checkInvoicesButton={showInvoiceButton} />}
    </>
}
CreditIssueNotification.propTypes = {
    mobileOnly: PropTypes.bool,
    fixed: PropTypes.bool
}