import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { AUTH, CART_API, PARAMS } from '../common/Api';

export default class OrderHistoryApi {
    static getQuickOrders(payload) {
        const { baseInfo } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}/users/${baseInfo.userId}/quickorders`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static getOrderList(payload) {
        const { baseInfo, orderId, creationPeriod, processType, statusType, pageNumber, pageItemSize } = payload;
        const period = creationPeriod !== undefined ? creationPeriod : '30';
        const process = processType !== undefined ? processType : 'all';
        const status = statusType !== undefined ? statusType : '';
        const page = pageNumber !== undefined ? pageNumber : '0';
        const itemSize = pageItemSize !== undefined ? pageItemSize : '25';
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}/users/${baseInfo.userId}/orders?fields=DEFAULT&page=${page}&pageItemSize=${itemSize}&sort=sortCode&period=${period}&processType=${process}&status=${status}`;

        if (orderId) {
            config.url = `${config.url}&orderId=${orderId}`;
        }

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static getOrderDetail(payload) {
        const { baseInfo, orderId } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}/users/${baseInfo.userId}/orders/${orderId}`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static cancelOrder(payload) {
        const { baseInfo, id } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}/order/cancel/${id}`;

        config.params = {
            code: id
        };

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static reOrder(payload) {
        const { baseInfo = {}, data } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo.userId}${CART_API.RE_ORDER}`;
        config.data = data;

        return RestClient.post(config).then(json => {
            return json;
        });
    }
}
