import { all, put, takeLatest, call } from 'redux-saga/effects';
import CheckoutApi from '../interfaces/Checkout/CheckoutApi';
import FactorySaga from './FactorySaga';
import { resetDeliveryDateSaga } from './CartSaga';
import {
    getActiveCartId,
    setActiveCartId,
    setReturnEmpties,
    setMarketingMaterials
} from '../common/UserManager/UserManager';
import { redirectTo } from '../common/Utils/Utils';
import { URL_PATHS,MESSAGE_TYPES } from '../common/Constants';
import { ERROR_CODES } from '../common/MessageCodes';
import { notifySuccess, notifyError, showMultipleNotifications, showOrderNotifications } from '../actions/NotificationAction';
import {
    GET_CHECKOUT_DATA_REQUEST,
    GET_CHECKOUT_DATA_SUCCESS,
    GET_CHECKOUT_DATA_ERROR,
    POST_PLACE_ORDER_DATA_REQUEST,
    POST_PLACE_ORDER_DATA_ERROR,
    POST_PLACE_ORDER_DATA_SUCCESS
} from '../actionTypes/CheckoutActionTypes';
import { GET_ORDER_CONFIRMATION_SUCCESS } from '../actionTypes/OrderConfirmActionType';
import { CLEAR_ALL_CART_SUCCESS, CLEAR_MINI_CART } from '../actionTypes/CartActionTypes';
import { RESET_ORDER_LIST } from '../actionTypes/OrderListActionTypes';
import { GET_FINANCIAL_INFO_SUCCESS } from '../actionTypes/FinancialInfoActionTypes';
import { RESET_INVOICES } from '../actionTypes/InvoicesActionTypes';
import { cartProductsPurchaseDataLayerFilterGA4 } from '../service/DataLayerFilter';
import { purchaseEvent } from '../common/GoogleTagManager/GoogleTagManager';
import { clearMiniCart, updateMiniCartCount, updateMiniCartList } from '../actions/CartAction';


export function* getCheckoutSaga(action) {
    try {
        const cartId = getActiveCartId();

        if (cartId) {
            action.payload = { ...(action.payload || {}), cartId };
            yield put({ type: GET_CHECKOUT_DATA_SUCCESS, data: {} });
            const result = yield call(FactorySaga, CheckoutApi.getCheckoutData, action);
            const { isSuccess, response } = result;

            if (isSuccess) {
                const data = response.data || {};
                yield put({ type: GET_CHECKOUT_DATA_SUCCESS, data });

                const { cartList } = data;

                let entries = [];
                let totalItems = 0;
                cartList.forEach(cart => {
                    const { cartItemCount, cartDetails } = cart;
                    const { orderEntries }= cartDetails.entries;

                    //totalItems += cartItemCount
                    // entries = [
                    //     ...entries,
                    //     ...orderEntries.reduce((a, {quantity, qtyType, product}) => [
                    //         ...a,
                    //         {
                    //             code: product.code,
                    //             quantity,
                    //             quantityType: qtyType
                    //         }
                    //     ],
                    //]
                    
                })

                // yield all([
                //     // put(updateMiniCartList({ entry: entries })),//
                //     // put(updateTempMiniCart({...action.payload.data, quantity})),
                //     put(updateMiniCartCount({override: true, qtyAdded: totalItems })),
                // ])

                // Display Credit Check Message.
                if (data.creditCheckMessage) {
                    yield put(notifySuccess({ message: data.creditCheckMessage }));
                }
            } else if (response.data.type === ERROR_CODES.INVALID_CHECKOUT) {
                redirectTo(URL_PATHS.CART);
            }
            const messages = [];         
            const { promoDisclaimerMessage } = response.data;
            if (promoDisclaimerMessage) {
                messages.push(promoDisclaimerMessage);                   
            }  
            if (messages?.length > 0) {
                yield put(notifyError({ messages, isAutoClose: false }));
            }
        }
    } catch (err) {
        yield put({ type: GET_CHECKOUT_DATA_ERROR });
    }
}

export function* submitPlaceOrderSaga(action) {
    try {
        const cartId = getActiveCartId();

        if (cartId) {
            action.payload = { ...action.payload, data: { ...action.payload.data, cartId } };
            const result = yield call(FactorySaga, CheckoutApi.submitPlaceOrder, action);
            const { isSuccess, response } = result;

            if (isSuccess) {
                setActiveCartId();
                setReturnEmpties();
                setMarketingMaterials();

                try {
                    const orderData = response.data.orderConfirmationList[0].orderData;

                    purchaseEvent(
                        orderData.entries.map((product, index) => cartProductsPurchaseDataLayerFilterGA4(index, product)),
                        orderData.code,
                        "CCH Online Store",
                        orderData?.totalPrice?.value,
                        orderData?.totalTax?.value,
                        orderData?.deliveryCost?.value,
                        "");
                } catch (ex) {
                    console.error(ex);
                }

                yield all([
                    put(clearMiniCart()),
                    put({ type: CLEAR_ALL_CART_SUCCESS }), // Reset Cart
                    put({ type: RESET_ORDER_LIST }), // Reset Order List
                    put({ type: GET_FINANCIAL_INFO_SUCCESS }), // Reset Finiancial Info
                    put({ type: RESET_INVOICES }), // Reset Invoice List
                    put({ type: POST_PLACE_ORDER_DATA_SUCCESS, data: response.data }),
                    put({ type: GET_ORDER_CONFIRMATION_SUCCESS, data: response.data }), // To update order confirm page.
                    put(showOrderNotifications(response.data))
                ]);
            

                // Reset delivery date in the background.
                yield call(resetDeliveryDateSaga);
            } else {
                yield put({ type: POST_PLACE_ORDER_DATA_ERROR });
            }
        }
    } catch (err) {
        yield put({ type: POST_PLACE_ORDER_DATA_ERROR });
    }
}

export default function* watchCheckoutSagaRequest() {
    yield takeLatest(GET_CHECKOUT_DATA_REQUEST, getCheckoutSaga);
    yield takeLatest(POST_PLACE_ORDER_DATA_REQUEST, submitPlaceOrderSaga);
}
