// Common Message Code Constants for Application.

export const SUCCESS_CODES = {};

export const ERROR_CODES = {
    CART_EMPTY_ERROR: 'CartModelEmptyError',
    CART_DELETE_EMPTY_ERROR: 'JaloObjectNoLongerValidError',
    CREDENTIALS_EXPIRED: 'Credentials_Expired',
    INVALID_CHECKOUT: 'INVALID_CHECKOUT',
    UNAUTH_ACCESS: 'UnauthorizedError',
    INVALID_TOKEN: 'InvalidTokenError',
    UNKNOWN_ERROR: 'UnknownResourceError',
    FORBIDDEN_ERROR: 'ForbiddenError'
};
