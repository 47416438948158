import {
    GET_CONTACT_INFO_SUCCESS,
    UPDATE_CONTACT_INFO,
    UPDATE_CONTACT_INFO_CONCERN,
    SHOW_CONTACT_INFO_FORM,
    HIDE_CONTACT_INFO_FORM,
    CLEAR_REDUCER
} from '../actionTypes/ContactMeActionTypes';

export default function ContactInfoReducer(state = { showContactForm: true }, action = {}) {
    switch (action.type) {
        case GET_CONTACT_INFO_SUCCESS: {
            const options = action.data.entries;
            options[0].selected = true;
            return {
                options
            };
        }

        case SHOW_CONTACT_INFO_FORM: {
            return {
                ...state,
                showContactForm: true
            };
        }
        case UPDATE_CONTACT_INFO: {
            const selectedId = action.payload.id;
            const options = state.options.map(item => {
                return {
                    ...item,
                    selected: item.code === selectedId
                };
            });
            return { ...state, options };
        }

        case UPDATE_CONTACT_INFO_CONCERN: {
            const {
                payload: { value }
            } = action;
            const newState = { ...state };
            newState.concern = value;
            return newState;
        }

        case HIDE_CONTACT_INFO_FORM: {
            return {
                ...state,
                showContactForm: false,
                concern: '',
                options: state.options.map((item, index) =>
                    index === 0 ? { ...item, selected: true } : { ...item, selected: false }
                )
            };
        }
        case CLEAR_REDUCER:
            return {}
        default:
            return state;
    }
}
