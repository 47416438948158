import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import styles from './Icon.css';

const cx = classNames.bind(styles);

class Icon extends PureComponent {
    static iconTypes = {
        ICON: 'icon',
        SVG: 'svg',
        SVG_THEME: 'svgTheme'
    };

    static propTypes = {
        className: PropTypes.string,
        pathClassName: PropTypes.string,
        iconType: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
        name: PropTypes.string,
        viewBox: PropTypes.string,
        automationId: PropTypes.string
    };

    static defaultProps = {
        iconType: Icon.iconTypes.SVG_THEME,
        className: '',
        width: '16px',
        height: '16px',
        viewBox: '0 0 1024 1024',
        name: '',
        pathClassName: '',
        automationId: ''
    };

    render() {
        const { className, width, height, viewBox, name, pathClassName, iconType, automationId } = this.props;
        const themeIcon = iconType === Icon.iconTypes.SVG_THEME ? 'iconTheme' : '';
        const iconClasses = cx('iconMain', themeIcon, ...className.split(' '));

        // TODO: To externalize the spritemap.svg path.
        const xlinkHrefName = `/spritemap.svg#sprite-${name.toLowerCase()}`;

        const svgIcon = (
            <svg
                data-automation-id={automationId}
                className={iconClasses}
                width={width}
                height={height}
                viewBox={viewBox}
            >
                <use xlinkHref={xlinkHrefName} className={pathClassName} />
            </svg>
        );
        return (
            <span className={iconClasses}>
                {iconType === Icon.iconTypes.SVG || iconType === Icon.iconTypes.SVG_THEME ? (
                    svgIcon
                ) : (
                    <span className={cx('imgClass')} />
                )}
            </span>
        );
    }
}

export default Icon;
