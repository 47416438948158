const months = [
    'Janar',
    'Shkurt',
    'marsh',
    'Prill',
    'Mund',
    'Qershor',
    'Korrik',
    'Gusht',
    'Shtator',
    'Tetor',
    'Nëntori',
    'Dhjetor'
];
const days = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

export const albanianLocale = {
    localize: {
        month: n => months[n],
        day: n => days[n]
    },
    formatLong: {}
};
