import { GET_HOME_PAGE_DATA_REQUEST, CLEAR_REDUCER, SET_MINICART_MESSAGE_SHOWN } from '../actionTypes/HomeActionTypes';

export const clearHomePageReducer = () => ({
    type: CLEAR_REDUCER
})

export const getHomePageData = isSkipLoader => ({
    type: GET_HOME_PAGE_DATA_REQUEST,
    isSkipLoader
});

export const setMinicartMessageShown = data => ({
    type: SET_MINICART_MESSAGE_SHOWN,
    data
});

export default getHomePageData;
