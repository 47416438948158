import {
    GET_CLAIM_TICKET_HISTORY_REQUEST,
    UPDATE_TICKET_ID_REQUEST,
    UPDATE_CLAIM_CREATION_PERIOD_REQUEST,
    UPDATE_CLAIM_TICKET_TYPE_REQUEST,
    CLEAR_CLAIM_HISTORY_SEARCH_FORM,
    DEFAULT_CLAIM_HISTORY_VALUES,
    RESET_CLAIM_HISTORY
} from '../actionTypes/ClaimTicketHistoryActionTypes';

export const getClaimTicketHistory = payload => ({
    type: GET_CLAIM_TICKET_HISTORY_REQUEST,
    payload
});

export const updateTicketId = payload => ({
    type: UPDATE_TICKET_ID_REQUEST,
    payload
});

export const updateClaimCreationPeriod = payload => ({
    type: UPDATE_CLAIM_CREATION_PERIOD_REQUEST,
    payload
});

export const updateClaimTicketType = payload => ({
    type: UPDATE_CLAIM_TICKET_TYPE_REQUEST,
    payload
});

export const clearClaimForm = payload => ({
    type: CLEAR_CLAIM_HISTORY_SEARCH_FORM,
    payload
});

export const defaultClaimHistory = payload => ({
    type: DEFAULT_CLAIM_HISTORY_VALUES,
    payload
});

export const resetClaimHistory = () => ({
    type: RESET_CLAIM_HISTORY
});
