import {
    SET_COOLER_DASHBOARD_OVERVIEW,
    CLEAR_REDUCER
} from "../actionTypes/CoolerDashboardActionTypes";

const initialState = {}

export default function CoolerReducer(state = initialState, action = {}) {
    const { type, payload } = action;

    switch (type) {
        case SET_COOLER_DASHBOARD_OVERVIEW:          
            return {
                ...state,
                ...payload
            }
        case CLEAR_REDUCER:
            return initialState;
        default:
            return state;
    }
}