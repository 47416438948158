export const PUB_SUB_EVENTS = {
    TOGGLE_MAIN_SCROLL: 'TOGGLE_MAIN_SCROLL'
};

export const PUB_SUB_COMPONENTS = {
    LAYOUT: 'Layout'
};

const pubsub = {};
export const pubSubManager = {
    subscribe: (eventId = '', componentId = '', func = () => { }) => {
        pubsub[eventId] = { ...(pubsub[eventId] || {}), [componentId]: func };
    },
    unsubscribe: (eventId = '', componentId = '') => {
        delete pubsub[eventId][componentId];
    },
    publish: (eventId = '', data) => {
        Object.values(pubsub[eventId] || {}).forEach((func) => func(data));
    }
};