import {
    CLOSE_CREDIT_LIMIT_EXCEEDED_POPUP,
    GET_CREDIT_INFO_REQUEST, NOTIFICATION_READ, SHOW_NOTIFICATION,
    SHOW_CREDIT_LIMIT_EXCEEDED_POPUP, CLEAR_REDUCER
} from '../actionTypes/CreditInfoActionTypes';

export const clearCreditInfoReducer = () => ({
    type: CLEAR_REDUCER
})

export const getCreditInfoDetails = isSkipLoader => ({
    type: GET_CREDIT_INFO_REQUEST,
    isSkipLoader
});

export const showNotification = () => ({
    type: SHOW_NOTIFICATION
})

export const notificationRead = () => ({
    type: NOTIFICATION_READ
})
export const showCreditLimitExceededPopup = ({message, header}) => ({
    type: SHOW_CREDIT_LIMIT_EXCEEDED_POPUP,
    data: {
        message,
        header
    }
})
export const closeCreditLimitExceededPopup = () => ({
    type: CLOSE_CREDIT_LIMIT_EXCEEDED_POPUP
})

export default getCreditInfoDetails;
