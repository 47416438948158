export const et = {
  "et": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
    "callus": {
      "CallUsTitle": "Helista meile",
      "ContactsHeader": "Saate meiega ühendust võtta järgmistel numbritel...",
      "CancelButtonText": "Tühista",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Already on a call? Let us <a role='link' tabindex='0' class='cx-cobrowse-link'>browse with you</a></span>",
      "CoBrowse": "Alusta sirvimist",
      "CoBrowseWarning": "Koos sirvimine võimaldab Teie agendil näha ja hallata Teie töölauda, ​​et aidata. Vestluse ajal agendiga taotlege ühis sirvimise alustamiseks koodi ja sisestage see alla poole. Kas Te pole veel helistanud? Lihtsalt väljuge sellelt ekraanilt, et naasta lehele ",
      "SubTitle": "Meiega saate ühendust võtta mis tahes järgmistel numbritel ...",
      "AriaWindowLabel": "Helista meile aken",
      "AriaCallUsClose": "Helista meile sulge",
      "AriaBusinessHours": "Tööaeg",
      "AriaCallUsPhoneApp": "Avab telefonirakenduse ",
      "AriaCobrowseLink": "Avab ühis sirvimise sessiooni",
      "AriaCancelButtonText": "Helista meiel tühista"
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector": {
      "Title": "Live otseabi",
      "SubTitle": "Kuidas soovite ühendust võtta ?",
      "WaitTimeTitle": "Ooteaeg",
      "AvailableTitle": "Saadaval",
      "AriaAvailableTitle": "Saadaval",
      "UnavailableTitle": "Unavailable",
      "CobrowseButtonText": "Koos sirvimise alapealkiri ",
      "CallbackTitle": "Vasta kõnele",
      "CobrowseSubTitle": "Ühendus agendiga on nõutud selle jaoks. ",
      "AriaClose": "Live Assistance Close",
      "AriaWarning": "Hoiatus",
      "AriaAlert": "Hoiatus",
      "minute": "min",
      "minutes": "minuteid",
      "AriaWindowLabel": "Reaalajas abiaken"
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse": {
      "agentJoined": "Müügiesindaja on liitunud.",
      "youLeft": "Olete seansilt lahkunud. Koos sirvimine on nüüd lõpetatud. .",
      "sessionTimedOut": "Sessioon aegus. Koos sirvimine on nüüd lõpetatud. .",
      "sessionInactiveTimedOut": "Sessioon aegus. Koos sirvimine on nüüd lõpetatud..",
      "agentLeft": "Agent on istungilt lahkunud. Koos sirvimine on nüüd lõpetatud.",
      "sessionError": "Ilmnes ootamatu viga. Koos sirvimine on nüüd lõpetatud.",
      "sessionStarted": "Algas ühis sirvimise seanss. Ootan agendi ühendust seansiga …",
      "navRefresh": "Agent on lehte värskendanud. Värskendame lehte .",
      "navBack": "Agent vajutas \"Tagasi\" nuppu. Värskendame lehte.",
      "navForward": "Agent vajutas \"Edasi\" nuppu. Värskendame lehte.",
      "navUrl": "Agent on taodelnud navigeerimist. Värsskename lehte.",
      "navFailed": "Agendi navigeerimistaotlus ebaõnnestus .",
      "toolbarContent": "Sessiooni ID: {sessionId}",
      "contentMasked": "Sisu on agendi eest varjatud .",
"contentMaskedPartially": "Osa sisust on agendi eest varjatud .",
      "exitBtnTitle": "Väljuge ühis sirvimise seansist ",
      "areYouOnPhone": "Kas olete telefonitsi meie agendiga?",
      "areYouOnPhoneOrChat": "Kas olete telefonis või vestlete meie agendiga chatis?",
      "connectBeforeCobrowse": "Koos sirvimise jätkamiseks peate olema meie agendiga ühendatud. Helistage meile või alustage meiega otsevestlust ja alustage seejärel uuesti sirvimist .",
      "sessionStartedAutoConnect": "Algas ühis sirvimise seanss. Ootan agendi ühendust seansiga …",
      "browserUnsupported": "Kahjuks ei toetata Teie brauserit praegu .<br><br> Toetatud brauserid on: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 and above</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 and above</a></li></ul>",
      "chatIsAlreadyRunning": "Vestlus töötab juba teisel lehel .",
      "modalYes": "Jah",
      "modalNo": "Ei"
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar": {
      "SidebarTitle": "Vajad abi?",
      "ChannelSelectorName": "Otseabi ",
      "ChannelSelectorTitle": "Hankige kohe abi ühelt meie agendilt ",
      "SearchName": "Otsi",
      "SearchTitle": "Otsi",
      "CallUsName": "Helista meile",
      "CallUsTitle": "Helista meile detailid",
      "CallbackName": "Helista tagasi",
      "CallbackTitle": "Kõne vastuvõtmine ",
      "ClickToCallName": "Köõpsake valikut",
      "ClickToCallTitle": "Taotlege klienditeeninduse telefoninumbrit ",
      "SendMessageName": "Saada sõnum",
      "SendMessageTitle": "Saada sõnum",
      "WebChatName": "Otsevestlus",
      "WebChatTitle": "Otsevestlus",
      "AriaClose": "Sulgege menüü"
    },
    "webchat": {
      "ChatButton": "Vestlus",
      "ChatStarted": "Vestlus algas",
      "ChatEnded": "Vestlus lõppes",
      "AgentNameDefault": "Agent",
      "AgentConnected": "<%Agent%> Ühendatud",
      "AgentDisconnected": "<%Agent%> Ühendus katkestatud ",
      "BotNameDefault": "Robotid",
      "BotConnected": "<%Bot%> Ühendatud",
      "BotDisconnected": "<%Bot%> Ühendus katkestatud",
      "SupervisorNameDefault": "Juhendajar",
      "SupervisorConnected": "<%Agent%> Ühendatud",
      "SupervisorDisconnected": "<%Agent%> Ühendus katkestatud ",
      "AgentTyping": "...",
      "AriaAgentTyping": "Agent trükib",
      "AgentUnavailable": "Vabandust. Agente pole saadaval. Proovige hiljem. ",
      "ChatTitle": "Otsevestlus",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Min",
      "ChatFormFirstName": "Eesnimi",
      "ChatFormLastName": "Perekonnanimi",
      "ChatFormNickname": "Hüüdnimi",
      "ChatFormName": "Nimi",
      "ChatFormCustomerId": "Kliendikood",
      "ChatFormEmail": "E-mail",
      "ChatFormSubject": "Pealkiri",
	    "ChatFormSubjectPassword": "Parool",
	    "ChatFormSubjectService": "Teenus",
      "ChatFormSubjectOrder": "Tellimus",
      "ChatFormSubjectFinance": "Arved",
      "ChatFormPlaceholderFirstName": "Nõutud",
      "ChatFormPlaceholderLastName": "Nõutud",
      "ChatFormPlaceholderName": "Valikuline",
      "ChatFormPlaceholderCustomerId": "Valikuline",
      "ChatFormPlaceholderNickname": "Valikuline",
      "ChatFormPlaceholderEmail": "Nõutud",
      "ChatFormPlaceholderSubject": "Valikulinel",
      "ChatFormSubmit": "Alusta vestlust",
      "AriaChatFormSubmit": "Alusta vestlust",
      "ChatFormCancel": "Tühista",
      "AriaChatFormCancel": "Tühista vestlus",
      "ChatFormClose": "Sulge",
      "ChatInputPlaceholder": "Sisesta oma sõnum siia",
      "ChatInputSend": "Saada",
      "AriaChatInputSend": "Saada",
      "ChatEndQuestion": "Oled sa kindel, et soovid selle vestluse sulgeda?",
      "ChatEndCancel": "Tühista",
      "ChatEndConfirm": "Sulge vestlus",
      "AriaChatEndCancel": "Tühista",
      "AriaChatEndConfirm": "Lõpp",
      "ConfirmCloseWindow": "Oled sa kindel, et soovid vestluse sulgeda?",
      "ConfirmCloseCancel": "Tühista",
      "ConfirmCloseConfirm": "Sulge",
      "AriaConfirmCloseCancel": "Tühista",
      "AriaConfirmCloseConfirm": "Sulge",
      "ActionsDownload": "Lae alla koopia",
      "ActionsEmail": "E-maili koopia",
      "ActionsPrint": "Prindi koopia",
      "ActionsCobrowseStart": "Alustage ühis sirvimist ",
      "AriaActionsCobrowseStartTitle": "Avab ühis sirvimise seansi",
      "ActionsSendFile": "Lisa failid",
      "AriaActionsSendFileTitle": "Avab faili üleslaadimise dialoogi",
      "ActionsEmoji": "Saada emotikon",
      "ActionsCobrowseStop": "Välju ühis sirvimisest",
      "ActionsVideo": "Kutsu video vestlusele",
      "ActionsTransfer": "Edasta",
      "ActionsInvite": "Kutsu",
      "InstructionsTransfer": "Vestlusseansi edastamiseks avage see link teises seadmes</br></br><%link%>",
      "InstructionsInvite": "Jagage seda linki teise inimesega, et teda sellesse vestlusseanssi lisada </br></br><%link%>",
      "InviteTitle": "Vajad abi?",
      "InviteBody": "Andke teada, kui saame aidata .",
      "InviteReject": "Ei, aitäh",
      "InviteAccept": "Alusta vestlust",
      "AriaInviteAccept": "Võta kutse vastu ja alusta vestlust ",
      "AriaInviteReject": "Tühista kutse",
      "ChatError": "Vestlusseansi alustamisel ilmnes probleem. Palun proovige uuesti. .",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Jah",
      "ChatErrorDefaultButton": "Ei",
      "AriaChatErrorPrimaryButton": "Jah",
      "AriaChatErrorDefaultButton": "Ei",
      "DownloadButton": "Lae alla",
      "AriaDownloadButton": "Lae fail alla",
      "FileSent": "saadetud:",
      "FileTransferRetry": "Proovi uuesti",
      "AriaFileTransferRetry": "Proovige failiedastust uuesti",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Tühista",
      "AriaFileTransferCancel": "Tühista failiedastus",
      "RestoreTimeoutTitle": "Vestlus lõpetatud",
      "RestoreTimeoutBody": "Teie eelmine vestlussessioon on aegunud. Kas soovite alustada uut?",
      "RestoreTimeoutReject": "Ei aitäh",
      "RestoreTimeoutAccept": "Alustage vestlust ",
      "AriaRestoreTimeoutAccept": "Võta kutse vastu ja alusta vestlust ",
      "AriaRestoreTimeoutReject": "Keeldu kutsest",
      "EndConfirmBody": "Kas soovite tõesti oma vestlusseansi lõpetada??",
      "EndConfirmAccept": "Lõpeta vestlus",
      "EndConfirmReject": "Tühista",
      "AriaEndConfirmAccept": "Lõpeta vestlus",
      "AriaEndConfirmReject": "Tühista",
      "SurveyOfferQuestion": "Kas soovite osaleda uuringus?",
      "ShowSurveyAccept": "Jah",
      "ShowSurveyReject": "Ei",
      "AriaShowSurveyAccept": "Jah",
      "AriaShowSurveyReject": "Ei",
      "UnreadMessagesTitle": "lugemata",
      "AriaYouSaid": "Sina ütlesid",
      "AriaSaid": "ütles",
      "AriaSystemSaid": "Süsteem ütles",
      "AriaWindowLabel": "Otsevestluse aken ",
      "AriaMinimize": "Minimeeri reaalajas vestlus ",
      "AriaMaximize": "Reaalajas vestluse maksimeerimine",
      "AriaClose": "Sulge vestlus",
      "AriaEmojiStatusOpen": "Emotikonide valija dialoog on avatud",
      "AriaEmojiStatusClose": "Emotikonide valija dialoog on suletud",
      "AriaEmoji": "emotikon",
      "AriaEmojiPicker": "Emotikonide valija",
      "AriaCharRemaining": "Jäänud tähemärgid",
      "AriaMessageInput": "Sõnumikast",
      "AsyncChatEnd": "Lõpeta vestlus",
      "AsyncChatClose": "Sulge aken",
      "AriaAsyncChatEnd": "Lõpeta vestlus",
      "AriaAsyncChatClose": "Sulge aken",
      "DayLabels": [
        "P",
        "E",
        "T",
        "K",
        "N",
        "R",
        "L"
      ],
      "MonthLabels": [
        "Jan",
        "Veb",
        "Mär",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Okt",
        "Nov",
        "Det"
      ],
      "todayLabel": "Täna",
      "Errors": {
        "102": "Eesnimi on kohustuslik.",
        "103": "Perekonnanimi on kohustuslik.",
        "161": "Palun sisestage oma nimi.",
        "201": "Faili ei õnnestunud saata.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Lisatud failide maksimaalne arv ületatakse (<%MaxFilesAllowed%>).</p>",
        "202": "Faili ei õnnestunud saata.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Üleslaadimise limiit ja / või maksimaalne manuste arv ületatakse (<%MaxAttachmentsSize%>).</p>",
        "203": "Faili ei õnnestunud saata.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Failitüüp pole lubatud.</p>",
        "204": "Vabandame, kuid teie sõnum on liiga pikk. Palun kirjutage lühem sõnum.",
        "240": "Vabandame, kuid me ei saa praegu uut vestlust alustada. Palun proovi hiljem uuesti.",
        "364": "Vale emaili aadress.",
        "401": "Vabandame, kuid meil ei ole võimalik vestlusseanssi lubada. Kas soovite alustada uut vestlust?",
        "404": "Vabandame, kuid me ei leia teie eelmist vestlusseanssi. Kas soovite alustada uut vestlust?",
        "500": "Vabandame, teenusega ilmnes ootamatu viga. Kas soovite sulgeda ja alustada uut vestlust?",
        "503": "Vabandame, teenus pole praegu saadaval või hõivatud. Kas soovite sulgeda ja uuesti uut vestlust alustada?",
        "ChatUnavailable": "Vabandame, kuid me ei saa praegu uut vestlust alustada. Palun proovi hiljem uuesti.",
        "CriticalFault": "Teie vestlusseanss on tundmatu probleemi tõttu ootamatult lõppenud. Vabandame ebamugavuste pärast.",
        "StartFailed": "Teie vestlusseansi käivitamisel ilmnes probleem. Kontrollige oma ühendust ja et esitasite kogu vajaliku teabe õigesti, seejärel proovige uuesti.",
        "MessageFailed": "Teie sõnumit ei saadud. Palun proovi uuesti.",
        "RestoreFailed": "Vabandame, kuid meil ei õnnestunud teie vestlusseansi taastada tundmatu vea tõttu.",
        "TransferFailed": "Praegu ei saa vestlust üle kanda. Palun proovi hiljem uuesti.",
        "FileTransferSizeError": "Faili ei õnnestunud saata.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Faili suurus on lubatud suurusest suurem (<%MaxSizePerFile%>).</p>",
        "InviteFailed": "Kutset ei saa praegu genereerida. Palun proovi hiljem uuesti.",
        "ChatServerWentOffline": "Sõnumite läbimine võtab praegu tavapärasest kauem aega. Vabandame viivituse pärast.",
        "RestoredOffline": "Sõnumite läbimine võtab praegu tavapärasest kauem aega. Vabandame viivituse pärast.",
        "Disconnected": "<div style='text-align:center'>Ühendus katkes</div>",
        "Reconnected": "<div style='text-align:center'>Ühendus taastatud</div>",
        "FileSendFailed": "Faili ei õnnestunud saata.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>Tekkis ootamatu ühendus. Proovi uuesti?</p>",
        "Generic": "<div style='text-align:center'>Ilmnes ootamatu viga.</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Vale failitüüp. Lubatud on ainult pildid.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "Faili suurus on lubatud suurusest suurem.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "The maximum number of attached files exceeded the limit.",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Vale x-api-võtme transpordikonfiguratsioon.",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Vale lõpp-punkti transpordikonfiguratsioon.",
        "pureengage-v3-rest-INVALID_NICKNAME": "Eesnimi on kohustuslik.",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "Vabandame, kuid meil ei ole võimalik vestlusseanssi lubada.",
        "purecloud-v2-sockets-400": "Vabandust, midagi läks valesti. Kontrollige oma ühendust ja kas esitasite kogu vajaliku teabe õigesti, seejärel proovige uuesti..",
        "purecloud-v2-sockets-500": "Vabandame, teenusega ilmnes ootamatu viga.",
        "purecloud-v2-sockets-503": "Vabandame, teenus pole praegu saadaval."
      }
    }
  }
}
