import React from 'react';

export default ({ color, active }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20.7' height='20.7' viewBox='0 0 20.7 20.7'>
            <g id='Group_48584' data-name='Group 48584' transform='translate(-21.65 -594.65)'>
                <g id='Group_48545' data-name='Group 48545'>
                    <g id='Icons_Navigation_Home' data-name='Icons/Navigation/Home' transform='translate(22 595)'>
                        <path
                            id='HomeHouse'
                            d='M12.25,2.25a.768.768,0,0,1,.462.154l8.821,6.615a1.8,1.8,0,0,1,.718,1.436v10a1.8,1.8,0,0,1-1.795,1.795H15.913a1.8,1.8,0,0,1-1.795-1.795V14.338H10.382v6.117A1.8,1.8,0,0,1,8.587,22.25H4.045A1.8,1.8,0,0,1,2.25,20.455v-10a1.8,1.8,0,0,1,.718-1.436L11.788,2.4A.768.768,0,0,1,12.25,2.25Zm8.205,18.462a.257.257,0,0,0,.256-.256v-10a.258.258,0,0,0-.1-.205L12.25,3.981,3.891,10.25a.258.258,0,0,0-.1.205v10a.257.257,0,0,0,.256.256H8.587a.257.257,0,0,0,.256-.256V13.569a.769.769,0,0,1,.769-.769h5.275a.769.769,0,0,1,.769.769v6.886a.257.257,0,0,0,.256.256Z'
                            transform='translate(-2.25 -2.25)'
                            fill={color || '#ffffff'}
                            stroke={color || '#ffffff'}
                            strokeLinecap='round'
                            strokeWidth='0.7'
                        />
                    </g>
                </g>
                <path
                    id='Path_4727'
                    data-name='Path 4727'
                    d='M23.314,602.464l8.87-6.515,8.86,6.515V614H34.628v-7.859H28.976V614h-6.1Z'
                    fill={color === '#616161' ? 'transparent' : color}
                />
            </g>
        </svg>
    );
};
