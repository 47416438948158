import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PowerBIEmbed } from 'powerbi-client-react';
import classNames from 'classnames/bind';
import * as styles from "./ReportPowerBI.css";
import { getMySales } from '../../../actions/MySalesAction';
import Loader from '../../../components/site/Loader/Loader';

const PowerBIReport = () => {
    const dispatch = useDispatch();
    const cx = classNames.bind(styles);

    const powerBIAccessToken = useSelector(state => state.mySales.powerBIAccessToken);

    const [loadingPowerBIReport, setLoadingPowerBIReport] = useState(false);

    const fetchPowerBiReport = () => {
        setLoadingPowerBIReport(true);
        try {
            dispatch(getMySales());
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingPowerBIReport(false);
        }
    };

    useEffect(() => {
        fetchPowerBiReport();
    }, []);

    const reportLoadConfig = {
        type: 'report',
        tokenType: 1,
        // TODO-Justin fetch the token from BE, from /power-bi
        accessToken: powerBIAccessToken,
        embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=a802c597-f1dd-48b5-8760-508dbb74f214',
        settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false
        },
        filters: [
            {
                $schema: 'http://powerbi.com/product/schema#basic',
                target: {
                    table: 'POC_translations',
                    column: 'Language'
                },
                operator: 'Eq',
                //TODO-justin take the langauge from the state
                values: ['English']
            }
        ]
    };

    return (
        <div className={cx("power-bi-report")}>
            {/* The container for the Power BI report */}
            {loadingPowerBIReport ? <Loader /> : <PowerBIEmbed embedConfig={reportLoadConfig} />}
        </div>
    );
};

export default PowerBIReport;