import _get from 'lodash/get';
import { getLocalization } from '../UserManager/UserManager';
import { LOGIN_INFO } from '../Constants';

let storifyMeConfig = {};
export const initializeStorifyMe = state => {
    const siteId = getLocalization()[LOGIN_INFO.SITE_ID] || '';
    storifyMeConfig = _get(state, 'context.environment.storifyMe', {});
    
    if (storifyMeConfig.enabledCountries.indexOf(siteId) !== -1 && storifyMeConfig.script !== undefined && document.getElementById('storifyme-script') === null) {

        const script = document.createElement('script');

        script.id = 'storifyme-script';
        script.src = storifyMeConfig.script;
        script.type = "text/javascript";

        document.body.appendChild(script);
    }
};