import { GET_POWER_BI_TOKEN_SUCCESS } from '../actionTypes/MySalesActionTypes';

const initialState = {};

export default function MySalesReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_POWER_BI_TOKEN_SUCCESS: {
            return {
                ...state,
                powerBIAccessToken: action.data
            };
        }
        default:
            return state;
    }
}
