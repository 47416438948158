import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';

export default class FavouriteApi {
    static saveFavourite(payload) {
        const { code } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.data = { code };
        config.url = `/${config.baseInfo.siteId}/favourites/save`;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static removeFavourite(payload) {
        const { code } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.data = { code };
        config.url = `/${config.baseInfo.siteId}/favourites/remove`;

        return RestClient.post(config).then(json => {
            return json;
        });
    }
}
