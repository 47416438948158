import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames/bind';
import _get from 'lodash/get';
import _find from 'lodash/find';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import Image from '../Image/Image';
import Icon from '../Icon/Icon';
import * as styles from './SearchBox.css';
import { getPageContent } from '../../../actions/PageContentAction';
import { getSearchDetails, updateData, resetSearchData } from '../../../actions/SearchAction';
import { DEBOUNCE_DELAY, SEARCHBOX_L10N, URL_PATHS, PAGE_ID, GENERIC_L10N } from '../../../common/Constants';
import { redirectTo, goToPDP, isReturnEmptiesPage, isMarketingMaterialsPage } from '../../../common/Utils/Utils';
import { searchEvent } from '../../../common/GoogleTagManager/GoogleTagManager';
const cx = classNames.bind(styles);

const productLabel = (stockFlag, commonLocalization) => {
    if (stockFlag === "OUT_OF_STOCK") {
        return <div className={cx('product-label', 'oos-label')}>{commonLocalization[GENERIC_L10N.OUT_STOCK_LABEL]}</div>
    }
    return <></>
}

export class SearchBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            hideSearchResults: false,
            value: ''
        };
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.changeSearch = debounce(this.props.searchActions.getSearchDetails, DEBOUNCE_DELAY);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        this.setState({ hideSearchResults: true })
    }

    handleChange = e => {
        const inputValue = e.currentTarget.value;
        this.setState({ value: inputValue });
        if (inputValue.length > 2) {
            const isReturnEmpties = isReturnEmptiesPage();
            const isMarketingMaterials = isMarketingMaterialsPage();
            const data = {
                searchValue: encodeURIComponent(inputValue),
                isReturnEmpties,
                isMarketingMaterials
            };
            this.changeSearch(data);
            this.handleListner();
        }

        this.props.searchActions.updateData(inputValue);
    };

    handleKeyUp = e => {
        if (e.keyCode === 13) {
            const inputValue = e.currentTarget.value;
            if (inputValue && inputValue.length > 0) {
                this.goToProductListingPage(inputValue);
            }
        }
    };

    goToProductPage = item => {
        // TODO: To get API work on serving using codeShort in PDP.
        this.setState({ value: '' });
        if (this.props.deviceType.isDevice) {
            this.props.toggleDetails(true);
        }
        goToPDP(item.url, item.codeShort);
    };

    clearText = () => {
        this.setState({ value: '' });
        this.props.searchActions.resetSearchData();
    };

    goToProductListingPage = text => {
        try {
            searchEvent(text);
        } catch (ex) {
            console.error(ex);
        }
        
        const freeText = encodeURIComponent(text);
        this.handleListner();
        this.clearText();
        if (this.props.deviceType.isDevice) {
            this.props.toggleDetails(true);
        }
        const pathName = window.location.pathname;
        if (pathName.includes(URL_PATHS.PLP_SEARCH)) {
            let queryString = `&freeTextQuery=${freeText}`;
            let isReturnEmpties = false;
            let isMarketingMaterials = false;

            if (isReturnEmptiesPage()) {
                queryString += `&useEmpties=true`;
                isReturnEmpties = true;
            }
            if (isMarketingMaterialsPage()) {
                queryString += `&useMarketingMaterials=true`;
                isMarketingMaterials = true;
            }

            this.props.searchActions.getPageContent({
                pageId: PAGE_ID.PLP,
                queryString,
                isReturnEmpties,
                isMarketingMaterials,
                isPageSpinner: true
            });
        }

        let urlParam = `?text=${freeText}`;

        if (isReturnEmptiesPage()) {
            urlParam = `?text=${freeText}&re=true`;
        }

        if (isMarketingMaterialsPage()) {
            urlParam = `?text=${freeText}&ma=true`;
        }

        window.scrollTo(0, 0);
        redirectTo(`${URL_PATHS.PLP_SEARCH}${urlParam}`);
    };

    handleListner() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible
        }));
    }

    handleOutsideClick(e) {
        // ignore clicks on the component itself
        if (e.target && this.node && this.node.contains(e.target)) {
            return;
        }
        this.handleListner();
        this.props.searchActions.updateData(this.state.value);
    }

    render() {
        const { theme, placeholder, searchDetails, showSearchIcon, customSearch, id, commonLocalization } = this.props;
        const { value, hideSearchResults } = this.state;
        let mobileSearchCont = false;
        if (value && value.length > 0) {
            mobileSearchCont = true;
        } else {
            mobileSearchCont = false;
        }

        const searchData = _get(searchDetails, 'data.products', []);
        const suggestions = _get(searchDetails, 'data.suggestions', []);

        return (
            <div
                role="presentation"
                ref={node => {
                    this.node = node;
                }}
                automation-id='at-search-bar'
                className={cx('searchboxContainer', mobileSearchCont ? 'mobileSearchCont' : '', `${theme}`)}
                onKeyDown={() => this.setState({ hideSearchResults: false })}
                onClick={() => this.setState({ hideSearchResults: false })}
            >
                {showSearchIcon && (
                    <span className={cx('')}>
                        <Icon
                            className={cx(customSearch)}
                            iconType='svg'
                            wcodeShortth='22px'
                            height='16px'
                            viewBox='0 0 22 16'
                            name='magnifier'
                        />
                    </span>
                )}
                <input
                    type='text'
                    name='searchBox'
                    enterKeyHint='search'
                    id={`searchbox${id}`}
                    className={cx('searchboxInput')}
                    onChange={e => this.handleChange(e)}
                    onKeyUp={e => this.handleKeyUp(e)}
                    placeholder={placeholder}
                    autoComplete='off'
                    data-automation-id='at-search-bar-text'
                    value={value}
                />
                {value && value.length > 0 && (
                    <span role='presentation' onClick={() => this.clearText()} className={`${cx('clearSearchIcon')}`}>
                        <Icon
                            className={cx('globalDelete')}
                            iconType='svg'
                            width='22px'
                            height='16px'
                            viewBox='0 0 22 16'
                            name='global-delete'
                        />
                    </span>
                )}

                {searchData && searchData.length > 0 && value && value.length > 2 && searchDetails.isSuccess && !hideSearchResults && (
                    <ul automation-id='at-recommended-products' className={cx('searchbarList')}>
                        {suggestions &&
                            suggestions.map(sName => (
                                <li
                                    className={cx('ui-menu-item', 'padmenu')}
                                    key={sName.value}
                                    role='presentation'
                                    onClick={() => this.goToProductListingPage(sName.value)}
                                >
                                    {sName.value}
                                </li>
                            ))}

                        <div className={cx('noSearch')}>{commonLocalization[GENERIC_L10N.SEARCH_RESULTS]}</div>
                        {searchData.map(item => {
                            const image = _find(item.images, {
                                format: SEARCHBOX_L10N.THUMBNAIL,
                                imageType: SEARCHBOX_L10N.PRIMARY
                            }) || { url: '' };
                            return (
                                <li
                                    className={cx('ui-menu-item')}
                                    key={item.codeShort}
                                    role='presentation'
                                    onClick={() => this.goToProductPage(item)}
                                >
                                    <div automation-id='at-thumbnail-image' className={cx('thumb')}>
                                        {image.url !== '' && (
                                            <Image src={image.url} alt={`thumb - ${item.codeShort}`} />
                                        )}
                                    </div>
                                    <div className={cx('labels-container')}>
                                        <div automation-id='at-product-list' className={cx('productData')}>
                                            <div className={cx('name')}>{item.name}</div>
                                        </div>
                                        {productLabel(item.stockFlag, commonLocalization)}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                )}
                {searchData && searchData.length === 0 && value && value.length > 2 && searchDetails.isSuccess && !hideSearchResults && (
                    <ul className={cx('searchbarList')}>
                        <div className={cx('noSearch')}>{commonLocalization[GENERIC_L10N.SEARCH_RESULTS]}</div>
                        <li className={cx('')}>
                            <div className={cx('noSearchIcon')}>
                                <Icon
                                    className={cx('leftCalIcon')}
                                    iconType='svg'
                                    width='18px'
                                    height='18px'
                                    viewBox='0 0 24 24'
                                    name='no-resultsIcon'
                                />{' '}
                                {commonLocalization[GENERIC_L10N.NO_RESULTS_MSG]}
                            </div>
                        </li>
                    </ul>
                )}
            </div>
        );
    }
}

SearchBox.defaultProps = {
    deviceType: {},
    theme: null,
    placeholder: '',
    showSearchIcon: false,
    customSearch: '',
    searchDetails: [],
    toggleDetails: () => { },
    id: '',
    commonLocalization: {}
};

SearchBox.propTypes = {
    deviceType: PropTypes.objectOf(PropTypes.bool),
    theme: PropTypes.string,
    placeholder: PropTypes.string,
    showSearchIcon: PropTypes.bool,
    customSearch: PropTypes.string,
    searchDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    toggleDetails: PropTypes.func,
    id: PropTypes.string,
    commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

const mapStateToProps = state => {
    return {
        deviceType: _get(state, 'context.deviceType'),
        searchDetails: _get(state, 'searchDetails'),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        searchActions: bindActionCreators(
            {
                getSearchDetails,
                getPageContent,
                updateData,
                resetSearchData
            },
            dispatch
        )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
