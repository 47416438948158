import React, { Component } from 'react';
import classNames from 'classnames/bind';
import * as styles from './CoolerAverage.css';
import Icon from '../../core/Icon/Icon';

const cx = classNames.bind(styles);

class CoolerAverage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: '',
            description: '',
            value: '',
            color: '#ff0000',
            valueDetails: '',
            type: 'default'
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { color, description, rating, targetValue, title, value, valueDetails, type } = props;

        return {
            ...state,
            rating,
            targetValue,
            title,
            description,
            value,
            color,
            valueDetails,
            type
        }
    }

    render() {
        const { color, description, targetValue, title, value, valueDetails, type } = this.state;

        const outletAverages = {
            doorOpenings: () => { return `${value}/${targetValue}` },
            temperature: () => { return value + ' °' + valueDetails },
            lastDownload: () => { return `${value}\n${valueDetails}` },
            default: () => { return '' }
        }

        return (
            <div className={cx('ItemContainer')}>
                <div className={cx('ItemInfoContainer')}>
                    <Icon
                        className={cx('ItemInfoIcon')}
                        iconType='svg'
                        width='40px'
                        height='40px'
                        viewBox='0 0 40 40'
                        name={title.replace(' ', '-').toLocaleLowerCase()}
                    />
                    <div className={cx('ItemTitle')}>{title}</div>
                    <div className={cx('ItemDescription')}>{description}</div>
                </div>
                <div
                    className={cx('ItemValuePreview')}
                    style={{
                        backgroundColor: color,
                        whiteSpace: 'pre-wrap'
                    }}>
                    {outletAverages[type]()}
                </div>
            </div>
        );
    }
}

export default CoolerAverage;