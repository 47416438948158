export const GET_PRODUCT_LIST_REQUEST = 'GET_PRODUCT_LIST_REQUEST';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_ERROR = 'GET_PRODUCT_LIST_ERROR';
export const APPLY_PRODUCT_FILTER = 'APPLY_PRODUCT_FILTER';
export const REMOVE_PRODUCT_FILTER = 'REMOVE_PRODUCT_FILTER';
export const TOGGLE_PRODUCT_VIEW = 'TOGGLE_PRODUCT_VIEW';
export const UPDATE_FAVOURITE_IN_PRODUCT_LIST = 'UPDATE_FAVOURITE_IN_PRODUCT_LIST';
export const RESET_PRODUCT_LIST = 'RESET_PRODUCT_LIST';
export const GET_BDAA_SUGGESTION = 'GET_BDAA_SUGGESTION';
export const GET_BDAA_SUGGESTION_SUCCESS = 'GET_BDAA_SUGGESTION_SUCCESS';
export const GET_BDAA_SUGGESTION_ERROR = 'GET_BDAA_SUGGESTION_ERROR';
export const SET_BDAA_ISACTIVE = 'SET_BDAA_ISACTIVE';
export const UPDATE_FAVOURITE_IN_CART = 'UPDATE_FAVOURITE_IN_CART';
export const CLEAR_REDUCER = 'CLEAR_REDUCER';
export const UPDATE_PRODUCT_SORT = 'UPDATE_PRODUCT_SORT';
export const SET_PLP_VIEW_CHOICE = 'SET_PLP_VIEW_CHOICE';
export const SET_PLP_VIEW_CHOICE_SUCCESS = 'SET_PLP_VIEW_CHOICE_SUCCESS';
