import {
    GET_COOLER_DASHBOARD_OVERVIEW,
    SET_COOLER_DASHBOARD_OVERVIEW,
    CLEAR_REDUCER
} from '../actionTypes/CoolerDashboardActionTypes';

export const clearCoolerReducer = () => ({
    type: CLEAR_REDUCER
})

export const getDashboardOverview = payload => ({
    type: GET_COOLER_DASHBOARD_OVERVIEW,
    payload
});

export const setDashboardOverview = payload => ({
    type: SET_COOLER_DASHBOARD_OVERVIEW,
    payload
});