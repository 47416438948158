import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { PersistGate } from 'redux-persist/integration/react';
import {store, persistor} from './redux/Store';
import routes from '../src/routes';
import { getGaMutableConfig, initializeGTAG, userLoggedInEvent } from '../src/common/GoogleTagManager/GoogleTagManager';
import { initializeGA } from '../src/common/GoogleAnalytics/GoogleAnalytics';
import { initializeStorifyMe } from '../src/common/StorifyMe/StorifyMe';
import { initializeFullStory, initializeSmartEdit } from '../src/common/Utils/Utils';
import { HelmetProvider } from 'react-helmet-async';

/**
 * Initializing Google Analytics, if the site needs to be tracked.
 */
try {
    getGaMutableConfig();
    initializeGA(store.getState());
    initializeGTAG(store.getState());
    userLoggedInEvent();
} catch (err) {
    console.error(err);
}

/**
 * Initializing SmartEdit
 */
initializeSmartEdit(store);

/*
 * Initializin fullStory
*/
initializeFullStory(store.getState());

initializeStorifyMe(store.getState());

ReactDOM.render(
    <Provider store={store} key="provider">
        {/* <PersistGate persistor={persistor}> */}
        <HelmetProvider>
            <Router
                history={browserHistory}
                routes={routes}
            />
         {/* </PersistGate> */}
         </HelmetProvider>
    </Provider>,
    document.getElementById('root')
);