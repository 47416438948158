export const el_CY = {
  "el_CY": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
    "callus": {
      "CallUsTitle": "Επικοινωνήστε μαζί μας",
      "ContactsHeader": "Μπορείτε να επικοινωνήσετε μαζί μας σε οποιονδήποτε από τους ακόλουθους αριθμούς ...",
      "CancelButtonText": "Ακύρωση",

      "CoBrowse": "Εκκίνηση κοινής πλοήγησης",
      "CoBrowseWarning": "Η κοινή πλοήγηση επιτρέπει στον εκπρόσωπο της εταιρίας μας να βλέπει και να ελέγχει το desktop σας, με σκοπό να σας καθοδηγήσει. Όταν είστε σε live call με ένα εκπρόσωπό μας , μπορείτε να ζητήσετε κωδικό για να ξεκινήσετε την κοινή πλοήγηση και να τον καταχωρήσετε παρακάτω. Στην περίπτωση που δεν είστε ήδη σε call με εκπρόσωπό μας μπορείτε απλά να επιστρέψετε στην σελίδα Επικοινωνήστε μαζί μας.",
"SubTitle": "Μπορείτε να επικοινωνήσετε μαζί μας σε οποιονδήποτε από τους ακόλουθους αριθμούς ...",
      "AriaWindowLabel": "Παράθυρο Επικοιωνήστε μαζί μας",
      "AriaCallUsClose": "Κλείσιμο Επικοινωνήστε μαζί μας",
      "AriaBusinessHours": "Ώρες Λειτουργίας",
      "AriaCallUsPhoneApp": "Άνοιγμα εφαρμογής τηλεφώνου",
      "AriaCobrowseLink": "Άνοιγμα Κοινής Πλοήγησης",
      "AriaCancelButtonText": "Ακύρωση Επικοινωνήστε μαζί μας"
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector": {
      "Title": "Live Εξυπηρέτηση",
      "SubTitle": "Πως θα θέλατε να επικοινωνήσετε;",
      "WaitTimeTitle": "Χρόνος αναμονής",
      "AvailableTitle": "Διαθέσιμο",
      "AriaAvailableTitle": "Διαθέσιμο",
      "UnavailableTitle": "Μη Διαθέσιμο",
      "CobrowseButtonText": "Τίτλος Κοινής Πλοήγησης",
      "CallbackTitle": "Λήψη κλήσης",
"CobrowseSubTitle": "Απαιτείται επικοινωνία με εκπρόσωπο της εταιρείας για αυτό",
      "AriaClose": "Κλείσιμο Live Εξυπηρέτησης",
      "AriaWarning": "Προειδοποίηση",
      "AriaAlert": "Προσοχή",
      "minute": "λεπτό",
      "minutes": "λεπτά",
      "AriaWindowLabel": "Παράθυρο Live Εξυπηρέτησης"
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse": {
      "agentJoined": "Εκπρόσωπος της εταιρείας έχει συνδεθεί στη συνομιλία",
      "youLeft": "Έχετε αποχωρήσει από την συνομιλία. Η κοινή πλοήγηση τερματίστηκε. ",
      "sessionTimedOut": "Η συνομίλα έχει λήξει.  Η κοινή πλοήγηση τερματίστηκε",
      "sessionInactiveTimedOut": "Η συνομιλία έχει λήξει. Η κοινή πλοήγηση τερματίστηκε",
      "agentLeft": "Ο εκπρόσωπος της εταιρείας μας αποχώρησε από την συνομιλία.  Η κοινή πλοήγηση τερματίστηκε",
      "sessionError": "Προέκυψε απρόοπτο σφάλμα. Η κοινή πλοήγηση τερματίστηκε.",
      "sessionStarted": "Η κοινή πλοήγηση έχει ξεκινήσει.  Παρακαλούμε περιμένετε να συνδεθεί στην συνομιλία εκπρόσωπος της εταιρείας…",
      "navRefresh": "Εκπρόσωπος της εταιρείας έχει ανανεώσει την σελίδα. Επαναφόρτωση.",
      "navBack": "Εκπρόσωπος της εταιρείας πάτησε την ένδειξη Πίσω. Επαναφόρτωση σελίδας.",
      "navForward": "Εκπρόσωπος της εταιρείας πάτησε την ένδειξη Προώθηση.  Επαναφόρτωση σελίδας.",
      "navUrl": "Εκπρόσωπος της εταιρείας έχει αιτηθεί πλοήγηση.  Επαναφόρτωση σελίδας.",
      "navFailed": "Το αίτημα πλοήγησης από τον εκπρόσωπο της εταιρείας έχει αποτύχει.",
      "toolbarContent": "Κωδικός Συνομιλίας: {sessionId}",
      "contentMasked": "Το περιεχόμενο που προσπαθείτε να δείτε έχει οριστεί ως μη αναγνώσιμο.",
      "contentMaskedPartially": "Μέρος από το περιεχόμενο που προσπαθείτε να δείτε έχει οριστεί ως κρυφό από εκπρόσωπο της εταιρείας μας.",
      "exitBtnTitle": "Έξοδος Κοινής Πλοήγησης",
      "areYouOnPhone": "Έχετε συνδεθεί τηλεφωνικώς με τον εκπρόσωπό μας;",
      "areYouOnPhoneOrChat": "Έχετε συνδεθεί τηλεφωνικώς ή μέσω chat με τον εκπρόσωπό μας;",
      "connectBeforeCobrowse": "Θα πρέπει να συνδεθείτε με εκπρόσωπό μας για να συνεχίσετε την κοινή πλοήγηση.  Παρακαλώ καλέστε μας ή ξεκινήσετε Live συνομιλία μαζι μας και έπειτα πατήστε την επιλογή Κοινή Πλοήγηση.",
      "sessionStartedAutoConnect": "Η Κοινή Πλοήγηση έχει ξεκινήση.  Παρακαλούμε όπως περιμένετε τον εκπρόσωπό μας να συνδεθεί στην συνομιλία…",
      "browserUnsupported": "Δυστυχώς το πρόγραμμα πλοήγησης δεν ανταποκρίνεται προσωρινά.<br><br> Τα υποστηριζόμενα προγράμματα πλοήγησης είναι: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 and above</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 and above</a></li></ul>",
      "chatIsAlreadyRunning": "Η συνομιλία εκτελείται ήδη σε άλλη σελίδα. ",
      "modalYes": "Ναι",
      "modalNo": "Όχι"
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar": {
      "SidebarTitle": "Χρειάζεστε βοήθεια;",
      "ChannelSelectorName": "Live Εξυπηρέτηση",
      "ChannelSelectorTitle": "Θα λάβετε άμεσα εξυπηρέτηση από ένα από τους εκπροσώπους μας",
      "SearchName": "Αναζήτηση",
      "SearchTitle": "Αναζήτηση",
      "CallUsName": "Επικοινωνήστε μαζί μας",
      "CallUsTitle": "Επικοινωνήστε μαζί μας: Λεπτομέρειες",
      "CallbackName": "Καλέστε πίσω",
      "CallbackTitle": "Δεχτείτε κλήση",
      "ClickToCallName": "Πατήστε για κλήση",
      "ClickToCallTitle": "Παρακαλούμε ζητήστε τον αριθμό τηλεφώνου εξυπηρέτησης πελατών",
      "SendMessageName": "Στείλτε μήνυμα",
      "SendMessageTitle": "Στείλτε μήνυμα",
      "WebChatName": "Live Συνομιλία",
      "WebChatTitle": "Live Συνομιλία",
      "AriaClose": "Κλείστε το παράθυρο Χρειάζεστε Βοήθεια"
    },
    "webchat": {
      "ChatButton": "Συνομιλία",
      "ChatStarted": "Έναρξη συνομιλίας",
      "ChatEnded": "Τέλος συνομιλίας",
      "AgentNameDefault": "Εκπρόσωπος της εταιρείας",
      "AgentConnected": "<%Agent%> Συνδεδεμένο",
      "AgentDisconnected": "<%Agent%> Αποσυνδεδεμένο",
      "BotNameDefault": "Bot",
      "BotConnected": "<%Bot%> Συνδεδεμένο",
      "BotDisconnected": "<%Bot%> Αποσυνδεδεμένο",
      "SupervisorNameDefault": "Προϊστάμενος",
      "SupervisorConnected": "<%Agent%> Συνδεδεμένο",
      "SupervisorDisconnected": "<%Agent%> Αποσυνδεδεμένο",
      "AgentTyping": "...",
      "AriaAgentTyping": "Εκπρόσωπος της εταιρείας πληκτρολογεί",
      "AgentUnavailable": "Απολογούμαστε, δεν υπάρχουν διαθέσιμοι εκπροσώποι της εταιρείας.  Παρακαλούμε δοκιμάστε αργότερα.",
      "ChatTitle": "Live Συνομιλία",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Ελαχιστοποίηση",
      "ChatFormFirstName": "Όνομα",
      "ChatFormLastName": "Επώνυμο",
      "ChatFormNickname": "Nickname",
      "ChatFormName": "Όνομα",
      "ChatFormCustomerId": "Κωδικός Πελάτη",
      "ChatFormEmail": "Email",
      "ChatFormSubject": "Θέμα",
	    "ChatFormSubjectPassword": "Κωδικός πρόσβασης",
	    "ChatFormSubjectService": "Τεχνικό πρόβλημα παγίου",
	    "ChatFormSubjectOrder": "Παραγγελία",
	    "ChatFormSubjectFinance": "Λογιστήριο",
      "ChatFormPlaceholderFirstName": "Υποχρεωτικό",
      "ChatFormPlaceholderLastName": "Υποχρεωτικό",
      "ChatFormPlaceholderName": "Προαιρετικό",
      "ChatFormPlaceholderCustomerId": "Προαιρετικό",
      "ChatFormPlaceholderNickname": "Προαιρετικό",
      "ChatFormPlaceholderEmail": "Υποχρεωτικό",
      "ChatFormPlaceholderSubject": "΄Προαιρετικό",
      "ChatFormSubmit": "Έναρξη συνομιλίας",
      "AriaChatFormSubmit": "Έναρξη συνομιλίας",
      "ChatFormCancel": "Ακύρωση",
      "AriaChatFormCancel": "Ακύρωση συνομιλίας",
      "ChatFormClose": "Κλείσιμο",
      "ChatInputPlaceholder": "Πληκτρολογήστε το μήνυμά σας εδω",
      "ChatInputSend": "Αποστολή",
      "AriaChatInputSend": "Αποστολή",
      "ChatEndQuestion": "Είστε σίγουρος ότι θέλετε να τερματίσετε την συνομιλία;",
      "ChatEndCancel": "Ακύρωση",
      "ChatEndConfirm": "Τέλος συνομιλίας",
      "AriaChatEndCancel": "Ακύρωση",
      "AriaChatEndConfirm": "Τέλος",
      "ConfirmCloseWindow": "Είστε σίγουρος ότι θέλετε να τερματίσετε την συνομιλία?",
      "ConfirmCloseCancel": "Ακύρωση",
      "ConfirmCloseConfirm": "Κλείσιμο",
      "AriaConfirmCloseCancel": "Ακύρωση",
      "AriaConfirmCloseConfirm": "Κλείσιμο",
      "ActionsDownload": "Λήψη αντιγράφου",
      "ActionsEmail": "Λήψη αντιγράφου μέσω email",
      "ActionsPrint": "Εκτύπωση αντιγράφου",
      "ActionsCobrowseStart": "Εκκίνηση Κοινής Πλοήγησης",
      "AriaActionsCobrowseStartTitle": "Άνοιγμα Κοινής Πλοήγησης",
      "ActionsSendFile": "Επισύναψη αρχείων",
      "AriaActionsSendFileTitle": "Άνοιγμα παραθύρου για φόρτωση αρχείου",
      "ActionsEmoji": "Αποστολή Emoji",
      "ActionsCobrowseStop": "Έξοδος Κοινής Πλοήγησης",
      "ActionsVideo": "Πρόσκληση σε συνομιλία μέσω video",
      "ActionsTransfer": "Μεταφορά",
      "ActionsInvite": "Πρόσκληση",
      "InstructionsTransfer":  "Για να μεταφέρετε την συνομιλία σας μπορείτε να ανοίξετε αυτό το link από άλλη συσκευή</br></br><%link%>",
      "InstructionsInvite": "Για να μπορέσετε να προσθέσετε κάποιο άλλο άτομο θα πρέπει να μοιραστείτε αυτό το link μαζί του</br></br><%link%>",
      "InviteTitle": "Χρειάζεστε βοήθεια;",
      "InviteBody": "Παρακαλούμε ενημερώστε μας αν μπορούμε να βοηθήσουμε.",
      "InviteReject": "Όχι, ευχαριστώ",
      "InviteAccept": "Έναρξη συνομιλίας",
      "AriaInviteAccept": "Αποδοχή πρόσκλησης και έναρξη συνομιλίας",
      "AriaInviteReject": "Απόρριψη πρόσκλησης",
      "ChatError": "Προέκυψε πρόβλημα κατά την έναρξη της συνομιλίας.  Παρακαλούμε προσπαθήστε ξανά.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Ναι",
      "ChatErrorDefaultButton": "Όχι",
      "AriaChatErrorPrimaryButton": "Ναι",
      "AriaChatErrorDefaultButton": "Όχι",
      "DownloadButton": "Λήψη",
      "AriaDownloadButton": "Λήψη αρχείου",
      "FileSent": "έχει σταλεί:",
      "FileTransferRetry": "Επανάληψη",
      "AriaFileTransferRetry": "Επανάληψη μεταφοράς αρχείου",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Ακύρωση",
      "AriaFileTransferCancel": "Ακύρωση μεταφοράς αρχείου",
      "RestoreTimeoutTitle": "Τέλος συνομιλίας",
      "RestoreTimeoutBody": "Η προηγούμενη σας συνομιλία έχει τερματιστεί.  Θα θέλατε να ξεκινήσετε μία καινούρια;",
      "RestoreTimeoutReject": "Όχι, ευχαριστώ",
      "RestoreTimeoutAccept": "Εναρξη συνομιλίας",
      "AriaRestoreTimeoutAccept": "Αποδοχή πρόσκλησης και έναρξη συνομιλίας",
      "AriaRestoreTimeoutReject": "Απόρριψη πρόσκλησης",
      "EndConfirmBody": "Είστε σίγουρος ότι θέλετε να τερματίσετε την συνομιλία;",
      "EndConfirmAccept": "Τέλος συνομιλίας",
      "EndConfirmReject": "Ακύρωση",
      "AriaEndConfirmAccept": "Τέλος συνομιλίας",
      "AriaEndConfirmReject": "Ακύρωση",
      "SurveyOfferQuestion": "Θα θέλατε να συμμετέχετε σε μία έρευνα;",
      "ShowSurveyAccept": "Ναι",
      "ShowSurveyReject": "Όχι",
      "AriaShowSurveyAccept": "Ναι",
      "AriaShowSurveyReject": "Όχι",
      "UnreadMessagesTitle": "μη αναγνωσμένο",
      "AriaYouSaid": "Αναφέρατε",
      "AriaSaid": "αναφέρθηκε",
      "AriaSystemSaid": "Το σύστημα ανέφερε",
      "AriaWindowLabel": "Παράθυρο Live Συνομιλίας",
      "AriaMinimize": "Ελαχιστοποίηση Live Συνομιλίας",
      "AriaMaximize": "Μεγιστοποίηση Live Συνομιλίας",
      "AriaClose": "Κλείσιμο Live Συνομιλίας",
      "AriaEmojiStatusOpen": "Το πάραθυρο επιλογής Emoji άνοιξε",
      "AriaEmojiStatusClose": "Το παράθυρο επιλογής Emoji έκλεισε",
      "AriaEmoji": "emoji",
      "AriaEmojiPicker": "Επιλογή Emoji",
      "AriaCharRemaining": "Χαρακτήρες που απομένουν",
      "AriaMessageInput": "Πλαίσιο μηνυμάτων",
      "AsyncChatEnd": "Τερματισμός συνομιλίας",
      "AsyncChatClose": "Κλείσιμο παραθύρου",
      "AriaAsyncChatEnd": "Τέλος συνομιλίας",
      "AriaAsyncChatClose": "Κλείσιμο παραθύρου",
      "DayLabels": [
        "Κυρ",
        "Δευτ",
        "Τρι",
        "Τετ",
        "Πεμ",
        "Παρ",
        "Σαβ"
      ],
      "MonthLabels": [
        "Ιαν",
        "Φεβ",
        "Μάρ",
        "Απρ",
        "Μάιος",
        "Ιούν",
        "Ιούλ",
        "Αύγ",
        "Σεπτ",
        "Οκτ",
        "Νοέμ.",
        "Δεκ"
      ],
      "todayLabel": "Σήμερα",
      "Errors": {
        "102": "Το όνομα είναι υποχρεωτικό.",
        "103": "Το επώνυμο είναι υποχρεωτικό",
        "161": "Παρακαλώ όπως καταχωρήσετε το όνομά σας.",
        "201": "Το αρχείο δεν μπορεί να αποσταλλεί.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Έχετε υπερβεί τον μέγιστο αριθμό αρχείων που μπορείτε να επισυνάψετε. (<%MaxFilesAllowed%>).</p>",
        "202": "Το αρχείο δεν μπορεί να αποσταλλεί.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'> Έχετε υπερβεί το όριο ή/και τον μέγιστο αριθμό αρχείων που μπορείτε να επισυνάψετε(<%MaxAttachmentsSize%>).</p>",
        "203": "Το αρχείο δεν μπορεί να αποσταλλεί.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Δεν επιτρέπεται ο συγκεκριμένος τύπος αρχείου.</p>",
        "204": "Λυπούμαστε αλλά το μήνυμά σας είναι πολύ μεγάλο. Παρακαλούμε όπως πληκτρολογήσετε ένα μικρότερο μήνυμα.",
        "240": "Λυπούμαστε πολύ αλλά αυτή τη στιγμή δεν μπορούμε να ξεκινήσουμε νέα συνομιλία μαζί σας. Παρακαλούμε δοκιμάστε αργότερα. ",
        "364": "Μη έγκυρη διεύθυνση email",
        "401": "Λυπούμαστε αλλά δεν μπορούμε να εγκρίνουμε την συνομιλία. Θα θέλατε να ξεκινήσετε καινούρια;?",
        "404": "Λυπούμαστε αλλά δεν μπορούμε να βρούμε το προηγούμενο παράθυρο συνομιλίας σας. Θα θέλατε να ξεκινήσετε νέα συνομιλία; ",
        "500": "Λυπούμαστε αλλά ένα πρέκυψε ένα απρόοπτο σφάλμα με την υπηρεσία. θα θέλατε να τερματίσετε τη συνομιλία και να ξεκινήσετε νέα; ",
        "503": "Λυπούμαστε αλλά η υπηρεσία είναι προσωρινά μη διαθέσιμη ή απασχολημένη. θα θέλατε να τερματήσετε τη συνομιλία και να ξεκινήσετε νέα; ",
        "ChatUnavailable": "Λυπούμαστε αλλά αυτή τη στιγμή δεν μπορούμε να σας εξυπηρετήσουμε. Παρακαλούμε προσπαθείστε  αργότερα. ",
        "CriticalFault": "Η συνομιλία σας τερματίστηκε απροσδόκητα εξαιτίας αγνώστου σφάλματος. Λυπούμαστε για την αναστάτωση. ",
        "StartFailed": "Προέκυψε σφάλμα κατά την έναρξη της συνομιλίας σας. Παρακαλούμε όπως ελέγξετε τη σύνδεσή σας και ότι έχετε υποβάλει  επιτυχώς όλες τις απαιτούμενες πληροφορίες κι έπειτα προσπαθείστε ξανά.",
        "MessageFailed": "Το μήνυμά σας δεν έχει ληφθεί επιτυχώς. Παρακαλούμε δοκιμάστε ξανά.",
        "RestoreFailed": "Λυπούμαστε αλλά δεν μπορούμε να επαναφέρουμε τη συνομιλία σας λόγω άγνωστου λάθους.",
        "TransferFailed": "Αδυναμία μεταφοράς της συνομιλίας σας αυτή τη στιγμή. Παρακαλούμε δοκιμάστε αργότερα. ",
  "InviteFailed": "Το αρχείο δεν μπορεί να αποσταλλεί. <br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Το αρχείο είναι μεγαλύτερο από το επιτρεπτό μέγεθος.(<%MaxSizePerFile%>).</p>",
"InviteFailed": "Αυτή τη στιγμή δεν είναι δυνατή η δημιουργία πρόσκλησης. Παρακαλούμε δοκιμάστε ξανά αργότερα.",
  "ChatServerWentOffline": "Προς το παρόν τα μηνύματα απαιτούν περισσότερο χρόνο να παραδοθούν σε σχέση με το συνηθισμένο. Λυπούμαστε για την καθυστέρηση.",
  "RestoredOffline": "Προς το παρόν τα μηνύματα απαιτούν περισσότερο χρόνο να παραδοθούν σε σχέση με το συνηθισμένο. Λυπούμαστε για την καθυστέρηση.",
        "Disconnected": "<div style='text-align:center'>Χάθηκε η σύνδεση</div>",
        "Reconnected": "<div style='text-align:center'>Η συνδεση έχει αποκατασταθεί</div>",
"FileSendFailed": "Το αρχείο δεν μπορεί να αποσταλλεί..<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>   Προέκυψε μια απρόοπτη αποσύνδεση. Παρακαλούμε δοκιμάστε ξανά.Try again?</p>",
        "Generic": "<div style='text-align:center'>Προέκυψε ένα απρόοπτο σφάλμα.</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Μη έγκυρος τύπος αρχείου. Επιτρέπονται μόνο εικόνες.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "Το μέγεθος του αρχείου είναι μεγαλύτερο από το επιτρεπόμενο μέγεθος.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": " Έχετε υπερβεί τον μέγιστο αριθμό αρχείων που μπορείτε να επισυνάψετε.",
"pureengage-v3-rest-INVALID_CONTACT_CENTER": "Λανθασμένη διαμόρφωση μεταφοράς κλειδιού (API Key)" ,
"pureengage-v3-rest-INVALID_ENDPOINT": "Μη έγκυρη διαμόρφωση μεταφοράς τελικού σημείου",
        "pureengage-v3-rest-INVALID_NICKNAME": "Το όνομα είναι υποχρεωτικό.",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "Λυπούμαστε πολύ αλλά δεν μπορούμε να εγκρίνουμε τη συνομιλία.",
        "purecloud-v2-sockets-400": "Λυπούμαστε πολύ αλλά κάτι πήγε λάθος. Παρακαλούμε επιβεβαιώστε την σύνδεσή σας και ότι έχετε υπαβάλλει σωστά όλες τις απαραίτητες πληροφορίες κι έπειτα δοκιμάστε ξανά.",
        "purecloud-v2-sockets-500": "Λυπούμαστε πολύ. Ένα απρόοπτο σφάλμα προέκυψε με την υπηρεσία.",
        "purecloud-v2-sockets-503": "Λυπούμαστε πολύ. Η υπηρεσία είναι προς το παρόν μη διαθέσιμη."
      }
    }
  }
}

export const el = {
  "el": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
    "callus": {
      "CallUsTitle": "Επικοινωνήστε μαζί μας",
      "ContactsHeader": "Μπορείτε να επικοινωνήσετε μαζί μας σε οποιονδήποτε από τους ακόλουθους αριθμούς ...",
      "CancelButtonText": "Ακύρωση",

      "CoBrowse": "Εκκίνηση κοινής πλοήγησης",
      "CoBrowseWarning": "Η κοινή πλοήγηση επιτρέπει στον εκπρόσωπο της εταιρίας μας να βλέπει και να ελέγχει το desktop σας, με σκοπό να σας καθοδηγήσει. Όταν είστε σε live call με ένα εκπρόσωπό μας , μπορείτε να ζητήσετε κωδικό για να ξεκινήσετε την κοινή πλοήγηση και να τον καταχωρήσετε παρακάτω. Στην περίπτωση που δεν είστε ήδη σε call με εκπρόσωπό μας μπορείτε απλά να επιστρέψετε στην σελίδα Επικοινωνήστε μαζί μας.",
"SubTitle": "Μπορείτε να επικοινωνήσετε μαζί μας σε οποιονδήποτε από τους ακόλουθους αριθμούς ...",
      "AriaWindowLabel": "Παράθυρο Επικοιωνήστε μαζί μας",
      "AriaCallUsClose": "Κλείσιμο Επικοινωνήστε μαζί μας",
      "AriaBusinessHours": "Ώρες Λειτουργίας",
      "AriaCallUsPhoneApp": "Άνοιγμα εφαρμογής τηλεφώνου",
      "AriaCobrowseLink": "Άνοιγμα Κοινής Πλοήγησης",
      "AriaCancelButtonText": "Ακύρωση Επικοινωνήστε μαζί μας"
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector": {
      "Title": "Live Εξυπηρέτηση",
      "SubTitle": "Πως θα θέλατε να επικοινωνήσετε;",
      "WaitTimeTitle": "Χρόνος αναμονής",
      "AvailableTitle": "Διαθέσιμο",
      "AriaAvailableTitle": "Διαθέσιμο",
      "UnavailableTitle": "Μη Διαθέσιμο",
      "CobrowseButtonText": "Τίτλος Κοινής Πλοήγησης",
      "CallbackTitle": "Λήψη κλήσης",
"CobrowseSubTitle": "Απαιτείται επικοινωνία με εκπρόσωπο της εταιρείας για αυτό",
      "AriaClose": "Κλείσιμο Live Εξυπηρέτησης",
      "AriaWarning": "Προειδοποίηση",
      "AriaAlert": "Προσοχή",
      "minute": "λεπτό",
      "minutes": "λεπτά",
      "AriaWindowLabel": "Παράθυρο Live Εξυπηρέτησης"
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse": {
      "agentJoined": "Εκπρόσωπος της εταιρείας έχει συνδεθεί στη συνομιλία",
      "youLeft": "Έχετε αποχωρήσει από την συνομιλία. Η κοινή πλοήγηση τερματίστηκε. ",
      "sessionTimedOut": "Η συνομίλα έχει λήξει.  Η κοινή πλοήγηση τερματίστηκε",
      "sessionInactiveTimedOut": "Η συνομιλία έχει λήξει. Η κοινή πλοήγηση τερματίστηκε",
      "agentLeft": "Ο εκπρόσωπος της εταιρείας μας αποχώρησε από την συνομιλία.  Η κοινή πλοήγηση τερματίστηκε",
      "sessionError": "Προέκυψε απρόοπτο σφάλμα. Η κοινή πλοήγηση τερματίστηκε.",
      "sessionStarted": "Η κοινή πλοήγηση έχει ξεκινήσει.  Παρακαλούμε περιμένετε να συνδεθεί στην συνομιλία εκπρόσωπος της εταιρείας…",
      "navRefresh": "Εκπρόσωπος της εταιρείας έχει ανανεώσει την σελίδα. Επαναφόρτωση.",
      "navBack": "Εκπρόσωπος της εταιρείας πάτησε την ένδειξη Πίσω. Επαναφόρτωση σελίδας.",
      "navForward": "Εκπρόσωπος της εταιρείας πάτησε την ένδειξη Προώθηση.  Επαναφόρτωση σελίδας.",
      "navUrl": "Εκπρόσωπος της εταιρείας έχει αιτηθεί πλοήγηση.  Επαναφόρτωση σελίδας.",
      "navFailed": "Το αίτημα πλοήγησης από τον εκπρόσωπο της εταιρείας έχει αποτύχει.",
      "toolbarContent": "Κωδικός Συνομιλίας: {sessionId}",
      "contentMasked": "Το περιεχόμενο που προσπαθείτε να δείτε έχει οριστεί ως μη αναγνώσιμο.",
      "contentMaskedPartially": "Μέρος από το περιεχόμενο που προσπαθείτε να δείτε έχει οριστεί ως κρυφό από εκπρόσωπο της εταιρείας μας.",
      "exitBtnTitle": "Έξοδος Κοινής Πλοήγησης",
      "areYouOnPhone": "Έχετε συνδεθεί τηλεφωνικώς με τον εκπρόσωπό μας;",
      "areYouOnPhoneOrChat": "Έχετε συνδεθεί τηλεφωνικώς ή μέσω chat με τον εκπρόσωπό μας;",
      "connectBeforeCobrowse": "Θα πρέπει να συνδεθείτε με εκπρόσωπό μας για να συνεχίσετε την κοινή πλοήγηση.  Παρακαλώ καλέστε μας ή ξεκινήσετε Live συνομιλία μαζι μας και έπειτα πατήστε την επιλογή Κοινή Πλοήγηση.",
      "sessionStartedAutoConnect": "Η Κοινή Πλοήγηση έχει ξεκινήση.  Παρακαλούμε όπως περιμένετε τον εκπρόσωπό μας να συνδεθεί στην συνομιλία…",
      "browserUnsupported": "Δυστυχώς το πρόγραμμα πλοήγησης δεν ανταποκρίνεται προσωρινά.<br><br> Τα υποστηριζόμενα προγράμματα πλοήγησης είναι: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 and above</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 and above</a></li></ul>",
      "chatIsAlreadyRunning": "Η συνομιλία εκτελείται ήδη σε άλλη σελίδα. ",
      "modalYes": "Ναι",
      "modalNo": "Όχι"
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar": {
      "SidebarTitle": "Χρειάζεστε βοήθεια;",
      "ChannelSelectorName": "Live Εξυπηρέτηση",
      "ChannelSelectorTitle": "Θα λάβετε άμεσα εξυπηρέτηση από ένα από τους εκπροσώπους μας",
      "SearchName": "Αναζήτηση",
      "SearchTitle": "Αναζήτηση",
      "CallUsName": "Επικοινωνήστε μαζί μας",
      "CallUsTitle": "Επικοινωνήστε μαζί μας: Λεπτομέρειες",
      "CallbackName": "Καλέστε πίσω",
      "CallbackTitle": "Δεχτείτε κλήση",
      "ClickToCallName": "Πατήστε για κλήση",
      "ClickToCallTitle": "Παρακαλούμε ζητήστε τον αριθμό τηλεφώνου εξυπηρέτησης πελατών",
      "SendMessageName": "Στείλτε μήνυμα",
      "SendMessageTitle": "Στείλτε μήνυμα",
      "WebChatName": "Live Συνομιλία",
      "WebChatTitle": "Live Συνομιλία",
      "AriaClose": "Κλείστε το παράθυρο Χρειάζεστε Βοήθεια"
    },
    "webchat": {
      "ChatButton": "Συνομιλία",
      "ChatStarted": "Έναρξη συνομιλίας",
      "ChatEnded": "Τέλος συνομιλίας",
      "AgentNameDefault": "Εκπρόσωπος της εταιρείας",
      "AgentConnected": "<%Agent%> Συνδεδεμένο",
      "AgentDisconnected": "<%Agent%> Αποσυνδεδεμένο",
      "BotNameDefault": "Bot",
      "BotConnected": "<%Bot%> Συνδεδεμένο",
      "BotDisconnected": "<%Bot%> Αποσυνδεδεμένο",
      "SupervisorNameDefault": "Προϊστάμενος",
      "SupervisorConnected": "<%Agent%> Συνδεδεμένο",
      "SupervisorDisconnected": "<%Agent%> Αποσυνδεδεμένο",
      "AgentTyping": "...",
      "AriaAgentTyping": "Εκπρόσωπος της εταιρείας πληκτρολογεί",
      "AgentUnavailable": "Απολογούμαστε, δεν υπάρχουν διαθέσιμοι εκπροσώποι της εταιρείας.  Παρακαλούμε δοκιμάστε αργότερα.",
      "ChatTitle": "Live Συνομιλία",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Ελαχιστοποίηση",
      "ChatFormFirstName": "Όνομα",
      "ChatFormLastName": "Επώνυμο",
      "ChatFormNickname": "Nickname",
      "ChatFormName": "Όνομα",
      "ChatFormCustomerId": "Κωδικός Πελάτη",
      "ChatFormEmail": "Email",
      "ChatFormSubject": "Θέμα",
	    "ChatFormSubjectPassword": "Κωδικός πρόσβασης",
	    "ChatFormSubjectService": "Τεχνικό πρόβλημα παγίου",
	    "ChatFormSubjectOrder": "Παραγγελία",
	    "ChatFormSubjectFinance": "Λογιστήριο",
      "ChatFormPlaceholderFirstName": "Υποχρεωτικό",
      "ChatFormPlaceholderLastName": "Υποχρεωτικό",
      "ChatFormPlaceholderName": "Προαιρετικό",
      "ChatFormPlaceholderCustomerId": "Προαιρετικό",
      "ChatFormPlaceholderNickname": "Προαιρετικό",
      "ChatFormPlaceholderEmail": "Υποχρεωτικό",
      "ChatFormPlaceholderSubject": "΄Προαιρετικό",
      "ChatFormSubmit": "Έναρξη συνομιλίας",
      "AriaChatFormSubmit": "Έναρξη συνομιλίας",
      "ChatFormCancel": "Ακύρωση",
      "AriaChatFormCancel": "Ακύρωση συνομιλίας",
      "ChatFormClose": "Κλείσιμο",
      "ChatInputPlaceholder": "Πληκτρολογήστε το μήνυμά σας εδω",
      "ChatInputSend": "Αποστολή",
      "AriaChatInputSend": "Αποστολή",
      "ChatEndQuestion": "Είστε σίγουρος ότι θέλετε να τερματίσετε την συνομιλία;",
      "ChatEndCancel": "Ακύρωση",
      "ChatEndConfirm": "Τέλος συνομιλίας",
      "AriaChatEndCancel": "Ακύρωση",
      "AriaChatEndConfirm": "Τέλος",
      "ConfirmCloseWindow": "Είστε σίγουρος ότι θέλετε να τερματίσετε την συνομιλία?",
      "ConfirmCloseCancel": "Ακύρωση",
      "ConfirmCloseConfirm": "Κλείσιμο",
      "AriaConfirmCloseCancel": "Ακύρωση",
      "AriaConfirmCloseConfirm": "Κλείσιμο",
      "ActionsDownload": "Λήψη αντιγράφου",
      "ActionsEmail": "Λήψη αντιγράφου μέσω email",
      "ActionsPrint": "Εκτύπωση αντιγράφου",
      "ActionsCobrowseStart": "Εκκίνηση Κοινής Πλοήγησης",
      "AriaActionsCobrowseStartTitle": "Άνοιγμα Κοινής Πλοήγησης",
      "ActionsSendFile": "Επισύναψη αρχείων",
      "AriaActionsSendFileTitle": "Άνοιγμα παραθύρου για φόρτωση αρχείου",
      "ActionsEmoji": "Αποστολή Emoji",
      "ActionsCobrowseStop": "Έξοδος Κοινής Πλοήγησης",
      "ActionsVideo": "Πρόσκληση σε συνομιλία μέσω video",
      "ActionsTransfer": "Μεταφορά",
      "ActionsInvite": "Πρόσκληση",
      "InstructionsTransfer":  "Για να μεταφέρετε την συνομιλία σας μπορείτε να ανοίξετε αυτό το link από άλλη συσκευή</br></br><%link%>",
      "InstructionsInvite": "Για να μπορέσετε να προσθέσετε κάποιο άλλο άτομο θα πρέπει να μοιραστείτε αυτό το link μαζί του</br></br><%link%>",
      "InviteTitle": "Χρειάζεστε βοήθεια;",
      "InviteBody": "Παρακαλούμε ενημερώστε μας αν μπορούμε να βοηθήσουμε.",
      "InviteReject": "Όχι, ευχαριστώ",
      "InviteAccept": "Έναρξη συνομιλίας",
      "AriaInviteAccept": "Αποδοχή πρόσκλησης και έναρξη συνομιλίας",
      "AriaInviteReject": "Απόρριψη πρόσκλησης",
      "ChatError": "Προέκυψε πρόβλημα κατά την έναρξη της συνομιλίας.  Παρακαλούμε προσπαθήστε ξανά.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Ναι",
      "ChatErrorDefaultButton": "Όχι",
      "AriaChatErrorPrimaryButton": "Ναι",
      "AriaChatErrorDefaultButton": "Όχι",
      "DownloadButton": "Λήψη",
      "AriaDownloadButton": "Λήψη αρχείου",
      "FileSent": "έχει σταλεί:",
      "FileTransferRetry": "Επανάληψη",
      "AriaFileTransferRetry": "Επανάληψη μεταφοράς αρχείου",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Ακύρωση",
      "AriaFileTransferCancel": "Ακύρωση μεταφοράς αρχείου",
      "RestoreTimeoutTitle": "Τέλος συνομιλίας",
      "RestoreTimeoutBody": "Η προηγούμενη σας συνομιλία έχει τερματιστεί.  Θα θέλατε να ξεκινήσετε μία καινούρια;",
      "RestoreTimeoutReject": "Όχι, ευχαριστώ",
      "RestoreTimeoutAccept": "Εναρξη συνομιλίας",
      "AriaRestoreTimeoutAccept": "Αποδοχή πρόσκλησης και έναρξη συνομιλίας",
      "AriaRestoreTimeoutReject": "Απόρριψη πρόσκλησης",
      "EndConfirmBody": "Είστε σίγουρος ότι θέλετε να τερματίσετε την συνομιλία;",
      "EndConfirmAccept": "Τέλος συνομιλίας",
      "EndConfirmReject": "Ακύρωση",
      "AriaEndConfirmAccept": "Τέλος συνομιλίας",
      "AriaEndConfirmReject": "Ακύρωση",
      "SurveyOfferQuestion": "Θα θέλατε να συμμετέχετε σε μία έρευνα;",
      "ShowSurveyAccept": "Ναι",
      "ShowSurveyReject": "Όχι",
      "AriaShowSurveyAccept": "Ναι",
      "AriaShowSurveyReject": "Όχι",
      "UnreadMessagesTitle": "μη αναγνωσμένο",
      "AriaYouSaid": "Αναφέρατε",
      "AriaSaid": "αναφέρθηκε",
      "AriaSystemSaid": "Το σύστημα ανέφερε",
      "AriaWindowLabel": "Παράθυρο Live Συνομιλίας",
      "AriaMinimize": "Ελαχιστοποίηση Live Συνομιλίας",
      "AriaMaximize": "Μεγιστοποίηση Live Συνομιλίας",
      "AriaClose": "Κλείσιμο Live Συνομιλίας",
      "AriaEmojiStatusOpen": "Το πάραθυρο επιλογής Emoji άνοιξε",
      "AriaEmojiStatusClose": "Το παράθυρο επιλογής Emoji έκλεισε",
      "AriaEmoji": "emoji",
      "AriaEmojiPicker": "Επιλογή Emoji",
      "AriaCharRemaining": "Χαρακτήρες που απομένουν",
      "AriaMessageInput": "Πλαίσιο μηνυμάτων",
      "AsyncChatEnd": "Τερματισμός συνομιλίας",
      "AsyncChatClose": "Κλείσιμο παραθύρου",
      "AriaAsyncChatEnd": "Τέλος συνομιλίας",
      "AriaAsyncChatClose": "Κλείσιμο παραθύρου",
      "DayLabels": [
        "Κυρ",
        "Δευτ",
        "Τρι",
        "Τετ",
        "Πεμ",
        "Παρ",
        "Σαβ"
      ],
      "MonthLabels": [
        "Ιαν",
        "Φεβ",
        "Μάρ",
        "Απρ",
        "Μάιος",
        "Ιούν",
        "Ιούλ",
        "Αύγ",
        "Σεπτ",
        "Οκτ",
        "Νοέμ.",
        "Δεκ"
      ],
      "todayLabel": "Σήμερα",
      "Errors": {
        "102": "Το όνομα είναι υποχρεωτικό.",
        "103": "Το επώνυμο είναι υποχρεωτικό",
        "161": "Παρακαλώ όπως καταχωρήσετε το όνομά σας.",
        "201": "Το αρχείο δεν μπορεί να αποσταλλεί.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Έχετε υπερβεί τον μέγιστο αριθμό αρχείων που μπορείτε να επισυνάψετε. (<%MaxFilesAllowed%>).</p>",
        "202": "Το αρχείο δεν μπορεί να αποσταλλεί.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'> Έχετε υπερβεί το όριο ή/και τον μέγιστο αριθμό αρχείων που μπορείτε να επισυνάψετε(<%MaxAttachmentsSize%>).</p>",
        "203": "Το αρχείο δεν μπορεί να αποσταλλεί.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Δεν επιτρέπεται ο συγκεκριμένος τύπος αρχείου.</p>",
        "204": "Λυπούμαστε αλλά το μήνυμά σας είναι πολύ μεγάλο. Παρακαλούμε όπως πληκτρολογήσετε ένα μικρότερο μήνυμα.",
        "240": "Λυπούμαστε πολύ αλλά αυτή τη στιγμή δεν μπορούμε να ξεκινήσουμε νέα συνομιλία μαζί σας. Παρακαλούμε δοκιμάστε αργότερα. ",
        "364": "Μη έγκυρη διεύθυνση email",
        "401": "Λυπούμαστε αλλά δεν μπορούμε να εγκρίνουμε την συνομιλία. Θα θέλατε να ξεκινήσετε καινούρια;?",
        "404": "Λυπούμαστε αλλά δεν μπορούμε να βρούμε το προηγούμενο παράθυρο συνομιλίας σας. Θα θέλατε να ξεκινήσετε νέα συνομιλία; ",
        "500": "Λυπούμαστε αλλά ένα πρέκυψε ένα απρόοπτο σφάλμα με την υπηρεσία. θα θέλατε να τερματίσετε τη συνομιλία και να ξεκινήσετε νέα; ",
        "503": "Λυπούμαστε αλλά η υπηρεσία είναι προσωρινά μη διαθέσιμη ή απασχολημένη. θα θέλατε να τερματήσετε τη συνομιλία και να ξεκινήσετε νέα; ",
        "ChatUnavailable": "Λυπούμαστε αλλά αυτή τη στιγμή δεν μπορούμε να σας εξυπηρετήσουμε. Παρακαλούμε προσπαθείστε  αργότερα. ",
        "CriticalFault": "Η συνομιλία σας τερματίστηκε απροσδόκητα εξαιτίας αγνώστου σφάλματος. Λυπούμαστε για την αναστάτωση. ",
        "StartFailed": "Προέκυψε σφάλμα κατά την έναρξη της συνομιλίας σας. Παρακαλούμε όπως ελέγξετε τη σύνδεσή σας και ότι έχετε υποβάλει  επιτυχώς όλες τις απαιτούμενες πληροφορίες κι έπειτα προσπαθείστε ξανά.",
        "MessageFailed": "Το μήνυμά σας δεν έχει ληφθεί επιτυχώς. Παρακαλούμε δοκιμάστε ξανά.",
        "RestoreFailed": "Λυπούμαστε αλλά δεν μπορούμε να επαναφέρουμε τη συνομιλία σας λόγω άγνωστου λάθους.",
        "TransferFailed": "Αδυναμία μεταφοράς της συνομιλίας σας αυτή τη στιγμή. Παρακαλούμε δοκιμάστε αργότερα. ",
  "InviteFailed": "Το αρχείο δεν μπορεί να αποσταλλεί. <br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Το αρχείο είναι μεγαλύτερο από το επιτρεπτό μέγεθος.(<%MaxSizePerFile%>).</p>",
"InviteFailed": "Αυτή τη στιγμή δεν είναι δυνατή η δημιουργία πρόσκλησης. Παρακαλούμε δοκιμάστε ξανά αργότερα.",
  "ChatServerWentOffline": "Προς το παρόν τα μηνύματα απαιτούν περισσότερο χρόνο να παραδοθούν σε σχέση με το συνηθισμένο. Λυπούμαστε για την καθυστέρηση.",
  "RestoredOffline": "Προς το παρόν τα μηνύματα απαιτούν περισσότερο χρόνο να παραδοθούν σε σχέση με το συνηθισμένο. Λυπούμαστε για την καθυστέρηση.",
        "Disconnected": "<div style='text-align:center'>Χάθηκε η σύνδεση</div>",
        "Reconnected": "<div style='text-align:center'>Η συνδεση έχει αποκατασταθεί</div>",
"FileSendFailed": "Το αρχείο δεν μπορεί να αποσταλλεί..<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>   Προέκυψε μια απρόοπτη αποσύνδεση. Παρακαλούμε δοκιμάστε ξανά.Try again?</p>",
        "Generic": "<div style='text-align:center'>Προέκυψε ένα απρόοπτο σφάλμα.</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Μη έγκυρος τύπος αρχείου. Επιτρέπονται μόνο εικόνες.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "Το μέγεθος του αρχείου είναι μεγαλύτερο από το επιτρεπόμενο μέγεθος.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": " Έχετε υπερβεί τον μέγιστο αριθμό αρχείων που μπορείτε να επισυνάψετε.",
"pureengage-v3-rest-INVALID_CONTACT_CENTER": "Λανθασμένη διαμόρφωση μεταφοράς κλειδιού (API Key)" ,
"pureengage-v3-rest-INVALID_ENDPOINT": "Μη έγκυρη διαμόρφωση μεταφοράς τελικού σημείου",
        "pureengage-v3-rest-INVALID_NICKNAME": "Το όνομα είναι υποχρεωτικό.",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "Λυπούμαστε πολύ αλλά δεν μπορούμε να εγκρίνουμε τη συνομιλία.",
        "purecloud-v2-sockets-400": "Λυπούμαστε πολύ αλλά κάτι πήγε λάθος. Παρακαλούμε επιβεβαιώστε την σύνδεσή σας και ότι έχετε υπαβάλλει σωστά όλες τις απαραίτητες πληροφορίες κι έπειτα δοκιμάστε ξανά.",
        "purecloud-v2-sockets-500": "Λυπούμαστε πολύ. Ένα απρόοπτο σφάλμα προέκυψε με την υπηρεσία.",
        "purecloud-v2-sockets-503": "Λυπούμαστε πολύ. Η υπηρεσία είναι προς το παρόν μη διαθέσιμη."
      }
    }
  }
}
