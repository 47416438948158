import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';

export default class MicrosoftChatBotApi {
    static getMicrosoftChatBotToken(payload) {
        const { baseInfo } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}/microsoft/chatbot`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }
}