import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _get from 'lodash/get';
import Icon from '../../core/Icon/Icon';
import Tooltip from '../../core/Tooltip/Tooltip';
import { DELIVERY_TYPES, CART_L10N } from '../../../common/Constants';
import { getPlantType, submitSelectedPlants, activateDeliveryType } from '../../../actions/DeliveryAction';

import * as styles from './DeliveryType.css';

const cx = classNames.bind(styles);

export const DeliveryType = props => {
    const { actions, deliveryType, pickupPlantList, localization, isPickUpAllowed, isReturnEmpties } = props;

    let displayDeliveryType = '';
    if ((deliveryType === DELIVERY_TYPES.ORDER_DRINKS || !isPickUpAllowed) && !isReturnEmpties) {
        displayDeliveryType = localization[CART_L10N.DELIVERY_TYPE];
    } else if (isPickUpAllowed) {
        if (deliveryType === DELIVERY_TYPES.PICKUP) {
            displayDeliveryType = localization[CART_L10N.PICK_UP];
        } else if (deliveryType && !isReturnEmpties) {
            const selectedPlant = _find(pickupPlantList, ['plantId', deliveryType]) || {};
            displayDeliveryType = selectedPlant.plantDescription || '';
        }
    }
    const [pickupPlants, setPickupPlants] = useState(deliveryType !== DELIVERY_TYPES.ORDER_DRINKS && !isReturnEmpties);

    useEffect(() => {
        if (pickupPlants && _isEmpty(pickupPlantList)) {
            actions.getPlantType();
        }
    }, [pickupPlants]);

    useEffect(() => {
        setPickupPlants(deliveryType !== DELIVERY_TYPES.ORDER_DRINKS && !isReturnEmpties);
    });

    const onChangeDeliveryType = (event, deliveryType) => {
        event.preventDefault();

        if (
            props.deliveryType !== deliveryType &&
            (deliveryType === DELIVERY_TYPES.ORDER_DRINKS ||
                (deliveryType === DELIVERY_TYPES.PICKUP && props.deliveryType === DELIVERY_TYPES.ORDER_DRINKS))
        ) {
            actions.activateDeliveryType({ deliveryType, isClearCart: true });
            setPickupPlants(deliveryType !== DELIVERY_TYPES.ORDER_DRINKS);
        }
    };

    const selectPickupAddress = (event, plantInfo) => {
        event.preventDefault();
        const { plantId } = plantInfo;
        props.actions.submitSelectedPlants({ data: { plantId } });
    };

    const renderDialog = () => {
        return (
            <div className={cx('miniOutletWrapper')} automation-id='at-list-of-DeliveryType-outlet'>
                <ul className={cx('deliveryText')}>
                    {!isReturnEmpties && (
                        <li>
                            <a
                                onClick={e => {
                                    onChangeDeliveryType(e, DELIVERY_TYPES.ORDER_DRINKS);
                                }}
                                role='presentation'
                                id='delivery'
                            >
                                {localization[CART_L10N.DELIVERY_TYPE]}
                            </a>
                        </li>
                    )}
                    {isPickUpAllowed && (
                        <li>
                            <a
                                onClick={e => {
                                    onChangeDeliveryType(e, DELIVERY_TYPES.PICKUP);
                                }}
                                id='pickUp'
                                role='presentation'
                            >
                                {localization[CART_L10N.PICK_UP]}
                            </a>
                        </li>
                    )}
                </ul>
                {isPickUpAllowed &&
                    !isReturnEmpties &&
                    (pickupPlantList || []).map(plantInfo => {
                        return (
                            <a
                                className={cx('outletList', pickupPlants && 'visible')}
                                href='./'
                                role='button'
                                key={plantInfo.plantId}
                                onClick={e => {
                                    selectPickupAddress(e, plantInfo);
                                }}
                            >
                                <div className={cx('customItemList', 'itemlistSecond')}>
                                    <ul className={cx('address')}>
                                        <div className={cx('companyName')}>{plantInfo.plantDescription}</div>
                                    </ul>
                                </div>
                            </a>
                        );
                    })}
            </div>
        );
    };

    return (
        <div className={cx('deliveryTypeContainer')}>
            <Tooltip
                renderTooltipContents={renderDialog()}
                CustomClassName={cx('customClassName')}
                customTooltipContent={cx('customTooltipContent')}
            >
                <div className={cx('deliveryContent')} automation-id='at-delivery-information'>
                    <span className={cx('textEllipsis')}>{displayDeliveryType}</span>
                    <span automation-id='at-delivery-name-dropdown'>
                        <Icon
                            className={cx('downArrowSvg')}
                            iconType='svg'
                            width='16px'
                            height='16px'
                            viewBox='0 0 16 16'
                            name='down'
                        />
                    </span>
                </div>
            </Tooltip>
        </div>
    );
};

DeliveryType.propTypes = {
    pickupPlantList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    deliveryType: PropTypes.string,
    isPickUpAllowed: PropTypes.bool
};

DeliveryType.defaultProps = {
    pickupPlantList: [],
    deliveryType: '',
    isPickUpAllowed: false
};

const mapStateToProps = state => {
    return {
        pickupPlantList: _get(state, 'cartDetails.plantDetails.pickupPlants'),
        deliveryType: _get(state, 'cartDetails.deliveryType'),
        isReturnEmpties: _get(state, 'cartDetails.isReturnEmpties'),
        isPickUpAllowed: _get(state, 'loginPage.pickUpAllowed')
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators({ getPlantType, submitSelectedPlants, activateDeliveryType }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryType);
