import {
    GET_LOYALTY_NOTIFICATIONS_SUCCESS,
    READ_LOYALTY_NOTIFICATION_SUCCESS,
    MUTE_LOYALTY_NOTIFICATIONS_SUCCESS,
    UNMUTE_LOYALTY_NOTIFICATIONS_SUCCESS,
    CLEAR_LOYALTY_NOTIFICATIONS_SUCCESS,
    GET_LOYALTY_DASHBOARD_DATA_SUCCESS,
    SHOW_NOTIFICATION_BANNER
} from '../actionTypes/LoyaltyActionTypes';

const INITIAL_STATE = {
    loyaltyNotifications: [],
    unreadLoyaltyNotifications: [],
    unreadCount: 0,
    muted: false,
    notificationBannerShown: false
};

export default function loyaltyReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_LOYALTY_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loyaltyNotifications: action.data.loyaltyNotifications,
                unreadLoyaltyNotifications: action.data.unreadNotifications,
                unreadCount: action.data.unreadCount,
                muted: action.data.isMuted
            };
        case READ_LOYALTY_NOTIFICATION_SUCCESS: {
            const notificationId = action.payload;
            return {
                ...state,
                unreadCount: state.unreadCount - 1,
                unreadLoyaltyNotifications: state.unreadLoyaltyNotifications.filter(notification => {
                    return notification.messageId !== notificationId;
                }),
                loyaltyNotifications: state.loyaltyNotifications.map(notification =>
                    notification.messageId === notificationId ? { ...notification, isRead: true } : notification
                )
            };
        }
        case MUTE_LOYALTY_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                muted: true
            };
        case UNMUTE_LOYALTY_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                muted: false
            };
        case CLEAR_LOYALTY_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                unreadCount: 0,
                loyaltyNotifications: [],
                unreadLoyaltyNotifications: []
            };
        case GET_LOYALTY_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                loyaltyDashboardData: action.data
            };
        case SHOW_NOTIFICATION_BANNER:
            return {
                ...state,
                notificationBannerShown: action.payload
            };
        default:
            return state;
    }
}
