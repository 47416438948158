export const ru = {
  "ru": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
    "callus": {
      "CallUsTitle": "Связаться с нами",
      "ContactsHeader": "Вы можете связаться с нами по номеру....",
      "CancelButtonText": "Отменить",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Уже звоните? Разрешите нам  <a role='link' tabindex='0' class='cx-cobrowse-link'>browse with you</a></span>",
      "CoBrowse": "Начать совместный просмотр",
      "CoBrowseWarning": "Совместный просмотр позволяет специалисту видеть ваш рабочий стол и управлять им, чтобы помочь вам. Во время разговора со специалистом в режиме реального времени запросите код для запуска совместного просмотра и введите его ниже. Еще не звоните? Просто закройте экран, чтобы вернуться на страницу «Связаться с нами».",
      "SubTitle": "Вы можете связаться с нами по любому из следующих номеров ...",
      "AriaWindowLabel": "Окно Связаться с нами",
      "AriaCallUsClose": "Закрыть Связаться с нами",
      "AriaBusinessHours": "Рабчие часы",
      "AriaCallUsPhoneApp": "Открывается приложение",
      "AriaCobrowseLink": "Открывается сеанс совместного просмотра ",
      "AriaCancelButtonText": "Отменить Связаться с нами"
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector": {
      "Title": "Онлайн ассистент",
      "SubTitle": "Как бы вы хотели связаться с нами?",
      "WaitTimeTitle": "Время ожидания",
      "AvailableTitle": "Доступен",
      "AriaAvailableTitle": "Доступен",
      "UnavailableTitle": "Недоступен",
      "CobrowseButtonText": "CobrowseSubTitle",
      "CallbackTitle": "Плучить звонок",
      "CobrowseSubTitle": "Для этого требуется подключение специалиста.",
      "AriaClose": "Закрыть Онлайн ассистент",
      "AriaWarning": "Предупреждение ",
      "AriaAlert": "Alert",
      "minute": "минута",
      "minutes": "минут",
      "AriaWindowLabel": "Окно Онлайн ассистент"
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse": {
      "agentJoined": "Специалист присоединился к сессии.",
      "youLeft": "Вы покинули сеанс. Совместный просмотр завершен.",
      "sessionTimedOut": "Время сеанса истекло. Совместный просмотр завершен.",
      "sessionInactiveTimedOut": "Время сеанса истекло. Совместный просмотр завершен.",
      "agentLeft": "Специалист покинул сессию. Совместный просмотр завершен.",
      "sessionError": "Произошла непредвиденная ошибка. Совместный просмотр завершен.",
      "sessionStarted": "Сеанс совместного просмотра запущен. Ожидание подключения специалиста к сеансу… ",
      "navRefresh": "Специалист обновил страницу. Перезагрузка.",
      "navBack": "Специалист нажал кнопку Назад. Перезагрузка страницы.",
      "navForward": "Специалист нажал кнопку Вперед. Перезагрузка страницы.",
      "navUrl": "Специалист запросил навигацию. Перезагрузка страницы.",
      "navFailed": "Запрос на навигацию от специалиста не выполнен.",
      "toolbarContent": "Код сессии: {sessionId}",
      "contentMasked": "Контент скрыт от специалиста.",
      "contentMaskedPartially": "Некоторый контент скрыт от специалиста.",
      "exitBtnTitle": "Выйти из сеанса совместного просмотра",
      "areYouOnPhone": "Вы разговариваете по телефону с нашим специалистом?",
      "areYouOnPhoneOrChat": "Вы разговариваете по телефону или в чате с нашим специалистом?",
      "connectBeforeCobrowse": "Вам необходимо связаться с нашим специалистом, чтобы продолжить совместный просмотр. Пожалуйста, позвоните нам или начните с нами чат, а затем снова запустите совместный просмотр.",
      "sessionStartedAutoConnect": "Сеанс совместного просмотра запущен. Ожидание подключения специалиста к сеансу…",
      "browserUnsupported": "К сожалению, ваш браузер в настоящее время не поддерживается.<br><br> Поддерживаемые браузеры: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 and above</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 and above</a></li></ul>",
      "chatIsAlreadyRunning": "Чат уже запущен на другой странице.",
      "modalYes": "Да",
      "modalNo": "Нет"
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar": {
      "SidebarTitle": "Нужна помощь?",
      "ChannelSelectorName": "Онлайн ассистент",
      "ChannelSelectorTitle": "Получите помощь от одного из наших специалистов прямо сейчас",
      "SearchName": "Поиск",
      "SearchTitle": "Поиск",
      "CallUsName": "Связаться с нами",
      "CallUsTitle": "Связаться с нами подробнее",
      "CallbackName": "Обратный звонок",
      "CallbackTitle": "Принять звонок",
      "ClickToCallName": "Нажмите, чтобы позвонить",
      "ClickToCallTitle": "Запросить номер телефона службы поддержки клиентов",
      "SendMessageName": "Отправить сообщение",
      "SendMessageTitle": "Отправить сообщение",
      "WebChatName": "Онлайн чат",
      "WebChatTitle": "Онлайн чат",
      "AriaClose": "Закрыть меню Нужна помощь?"
    },
    "webchat": {
      "ChatButton": "Чат",
      "ChatStarted": "Чат начат",
      "ChatEnded": "Чат завершен",
      "AgentNameDefault": "Специалист",
      "AgentConnected": "<%Agent%> присоединился к чату",
      "AgentDisconnected": "<%Agent%> Отключился",
      "BotNameDefault": "Робот",
      "BotConnected": "<%Bot%> присоединился к чату",
      "BotDisconnected": "<%Bot%> Отключился",
      "SupervisorNameDefault": "Супервайзер",
      "SupervisorConnected": "<%Agent%> присоединился к чату",
      "SupervisorDisconnected": "<%Agent%> Отключился",
      "AgentTyping": "...",
      "AriaAgentTyping": "Специалист печатает",
      "AgentUnavailable": "Ксожалению, сейчас нет доступных специалистов. Пожалуйста, попробуйте позднее.",
      "ChatTitle": "Онлайн чат",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Минута",
      "ChatFormFirstName": "Имя",
      "ChatFormLastName": "Фамилия",
      "ChatFormNickname": "Псевдоним",
      "ChatFormName": "Имя",
      "ChatFormCustomerId": "Номер клиента CCH",
      "ChatFormEmail": "Электронная почта",
      "ChatFormSubject": "Тема",
	  "ChatFormSubjectPassword": "Пароль",
	  "ChatFormSubjectService": "Услуга",
	  "ChatFormSubjectOrder": "Заказ",
	  "ChatFormSubjectFinance": "Финансы",
      "ChatFormPlaceholderFirstName": "Обязательно",
      "ChatFormPlaceholderLastName": "Обязательно",
      "ChatFormPlaceholderName": "По желанию",
      "ChatFormPlaceholderCustomerId": "По желанию",
      "ChatFormPlaceholderNickname": "По желанию",
      "ChatFormPlaceholderEmail": "Обязательно",
      "ChatFormPlaceholderSubject": "По желанию",
      "ChatFormSubmit": "Начать чат",
      "AriaChatFormSubmit": "Начать чат",
      "ChatFormCancel": "Отменить",
      "AriaChatFormCancel": "Завершить",
      "ChatFormClose": "Закрыть",
      "ChatInputPlaceholder": "Пишите ваше сообщение здесь",
      "ChatInputSend": "Отправить",
      "AriaChatInputSend": "Отправить",
      "ChatEndQuestion": "Вы уверены, что хотите завершить этот сеанс чата?",
      "ChatEndCancel": "Отменить",
      "ChatEndConfirm": "Завершить",
      "AriaChatEndCancel": "Отменить",
      "AriaChatEndConfirm": "Завершить",
      "ConfirmCloseWindow": "Вы уверены, что хотите завершить чат?",
      "ConfirmCloseCancel": "Отменить",
      "ConfirmCloseConfirm": "Закрыть",
      "AriaConfirmCloseCancel": "Отменить",
      "AriaConfirmCloseConfirm": "Закрыть",
      "ActionsDownload": "Загрузить диалог",
      "ActionsEmail": "Отправить на почту",
      "ActionsPrint": "Распечатать",
      "ActionsCobrowseStart": "Начать совместный просмотр",
      "AriaActionsCobrowseStartTitle": "Открывается сеанс совместного просмотра",
      "ActionsSendFile": "Приложить файл",
      "AriaActionsSendFileTitle": "Открывается диалог загрузки файла ",
      "ActionsEmoji": "Отправить эмодзи",
      "ActionsCobrowseStop": "Выход из совместного просмотра",
      "ActionsVideo": "Пригласить в видеочат",
      "ActionsTransfer": "Передача",
      "ActionsInvite": "Пригласить",
      "InstructionsTransfer": "Откройте эту ссылку на другом устройстве, чтобы перенести сеанс чата</br></br><%link%>",
      "InstructionsInvite": "Поделитесь этой ссылкой с другим человеком, чтобы добавить его в этот сеанс чата</br></br><%link%>",
      "InviteTitle": "Нужна помощь?",
      "InviteBody": "Сообщите нам, если нужна помощь",
      "InviteReject": "Нет, спасибо",
      "InviteAccept": "Начать чат",
      "AriaInviteAccept": "Принять приглашение и начать чат",
      "AriaInviteReject": "Отклонить приглашение",
      "ChatError": "Проблема с запуском сеанса чата. Пожалуйста, попробуйте еще раз.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Да",
      "ChatErrorDefaultButton": "Нет",
      "AriaChatErrorPrimaryButton": "Да",
      "AriaChatErrorDefaultButton": "Нет",
      "DownloadButton": "Загрузить",
      "AriaDownloadButton": "Загрузить файл",
      "FileSent": "Отправлено:",
      "FileTransferRetry": "Повторить",
      "AriaFileTransferRetry": "Повторите передачу файла",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Отменить",
      "AriaFileTransferCancel": "Отменить передачу файла",
      "RestoreTimeoutTitle": "Чат завершен",
      "RestoreTimeoutBody": "Время вашего предыдущего сеанса чата истекло. Вы бы хотели начать новый чат?",
      "RestoreTimeoutReject": "Нет, спасибо",
      "RestoreTimeoutAccept": "Начать чат",
      "AriaRestoreTimeoutAccept": "Принять приглашение и начать чат",
      "AriaRestoreTimeoutReject": "Отклонить приглашение",
      "EndConfirmBody": "Вы действительно хотите завершить сеанс чата?",
      "EndConfirmAccept": "Завершить",
      "EndConfirmReject": "Отменить",
      "AriaEndConfirmAccept": "Завершить",
      "AriaEndConfirmReject": "Отменить",
      "SurveyOfferQuestion": "Хотели бы Вы принять участие в опросе?",
      "ShowSurveyAccept": "Да",
      "ShowSurveyReject": "Нет",
      "AriaShowSurveyAccept": "Да",
      "AriaShowSurveyReject": "Нет",
      "UnreadMessagesTitle": "Непрочитанный",
      "AriaYouSaid": "Вы сказали",
      "AriaSaid": "сказали",
      "AriaSystemSaid": "Система сказала",
      "AriaWindowLabel": "Окно Онлайн чат",
      "AriaMinimize": "Уменьшить Онлайн чат",
      "AriaMaximize": "Увеличить Онлайн чат",
      "AriaClose": "Закрыть Онлайн чат",
      "AriaEmojiStatusOpen": "Набор эмодзи открыт",
      "AriaEmojiStatusClose": "Набор эмодзи закрыт",
      "AriaEmoji": "Эмодзи",
      "AriaEmojiPicker": "Набор эмодзи",
      "AriaCharRemaining": "Осталось символов",
      "AriaMessageInput": "Окно сообщения",
      "AsyncChatEnd": "Чат завершен",
      "AsyncChatClose": "Закрыть окно",
      "AriaAsyncChatEnd": "Чат завершен",
      "AriaAsyncChatClose": "Закрыть окно",
      "DayLabels": [
        "Воскресенье",
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Суббота"
      ],
      "MonthLabels": [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
      ],
      "todayLabel": "Сегодня",
      "Errors": {
        "102": "Имя обязательно.",
        "103": "Фамилия обязательно.",
        "161": "Пожалуйста, введите Ваше имя.",
        "201": "Файл не может быть отправлен.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Превышено максимальное количество прикрепленных файлов (<%MaxFilesAllowed%>).</p>",
        "202": "Файл не может быть отправлен.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Превышен лимит загрузки или максимальное количество прикрепленных файлов (<%MaxAttachmentsSize%>).</p>",
        "203": "Файл не может быть отправлен.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Недопустимый фрмат файла.</p>",
        "204": "К сожалению, ваше сообщение слишком длинное. Пожалуйста, напишите сообщение короче.",
        "240": "К сожалению, мы не можем начать чат в настоящее время. Пожалуйста, попробуйте позже.",
        "364": "Недопустимый адрес электронной почты.",
        "401": "К сожалению, мы не можем начать сеанс. Вы бы хотели начать новый чат?",
        "404": "К сожалению, мы не можем найти ваш предыдущий сеанс чата. Вы бы хотели начать новый чат?",
        "500": "К сожалению, произошла непредвиденная ошибка. Хотите закрыть текущий и начать новый чат?",
        "503": "К сожалению, сервис в настоящее время недоступен. Вы бы хотели закрыть текущий и начать новый чат? ",
        "ChatUnavailable": "К сожалению, мы не можем начать чат в настоящее время. Пожалуйста, попробуйте позже.",
        "CriticalFault": "Ваш сеанс чата неожиданно завершился из-за неизвестной проблемы. Приносим свои извинения за доставленные неудобства.",
        "StartFailed": "При запуске сеанса чата возникла проблема. Пожалуйста, проверьте своё интернет-соединение и правильно ли вы отправили всю необходимую информацию, затем повторите попытку.",
        "MessageFailed": "Ваше сообщение не было получено. Пожалуйста, попробуйте еще раз.",
        "RestoreFailed": "К сожалению, нам не удалось восстановить сеанс чата из-за неизвестной ошибки.",
        "TransferFailed": "В настоящее время невозможно перенести чат. Пожалуйста, повторите попытку позже.",
        "FileTransferSizeError": "Файл не может быть отправлен.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Размер файла больше допустимого (<%MaxSizePerFile%>).</p>",
        "InviteFailed": "В настоящее время невозможно создать приглашение. Пожалуйста, повторите попытку позже.",
        "ChatServerWentOffline": "Сообщения в настоящее время обрабатываются дольше обычного. Приносим извинения за задержку.",
        "RestoredOffline": "Сообщения в настоящее время обрабатываются дольше обычного. Приносим извинения за задержку.",
        "Disconnected": "<div style='text-align:center'>Соединение потеряно</div>",
        "Reconnected": "<div style='text-align:center'>Соединение восстановлено</div>",
        "FileSendFailed": "Файл не может быть отправлен.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>Произошло неожиданное отключение. Попробовать снова?</p>",
        "Generic": "<div style='text-align:center'>Произошла непредвиденная ошибка.</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Неверный тип файла. Разрешены только изображения.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "Размер файла больше допустимого.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "Превышено максимальное количество прикрепленных файлов.",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Invalid x-api-key transport configuration.",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Invalid endpoint transport configuration.",
        "pureengage-v3-rest-INVALID_NICKNAME": "Имя обязательно.",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "К сожалению, мы не можем авторизовать сеанс чата.",
        "purecloud-v2-sockets-400": "К сожалению, что-то пошло не так. Пожалуйста, проверьте своё соединение и правильно ли вы отправили всю необходимую информацию, затем повторите попытку.",
        "purecloud-v2-sockets-500": "К сожалению, произошла непредвиденная ошибка.",
        "purecloud-v2-sockets-503": "К сожалению, сервис в настоящее время недоступен ."
      }
    }
  }
}

export const ru_BY = {
  "ru_BY": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
    "callus": {
      "CallUsTitle": "Связаться с нами",
      "ContactsHeader": "Вы можете связаться с нами по номеру....",
      "CancelButtonText": "Отменить",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Уже звоните? Разрешите нам  <a role='link' tabindex='0' class='cx-cobrowse-link'>browse with you</a></span>",
      "CoBrowse": "Начать совместный просмотр",
      "CoBrowseWarning": "Совместный просмотр позволяет специалисту видеть ваш рабочий стол и управлять им, чтобы помочь вам. Во время разговора со специалистом в режиме реального времени запросите код для запуска совместного просмотра и введите его ниже. Еще не звоните? Просто закройте экран, чтобы вернуться на страницу «Связаться с нами».",
      "SubTitle": "Вы можете связаться с нами по любому из следующих номеров ...",
      "AriaWindowLabel": "Окно Связаться с нами",
      "AriaCallUsClose": "Закрыть Связаться с нами",
      "AriaBusinessHours": "Рабчие часы",
      "AriaCallUsPhoneApp": "Открывается приложение",
      "AriaCobrowseLink": "Открывается сеанс совместного просмотра ",
      "AriaCancelButtonText": "Отменить Связаться с нами"
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector": {
      "Title": "Онлайн ассистент",
      "SubTitle": "Как бы вы хотели связаться с нами?",
      "WaitTimeTitle": "Время ожидания",
      "AvailableTitle": "Доступен",
      "AriaAvailableTitle": "Доступен",
      "UnavailableTitle": "Недоступен",
      "CobrowseButtonText": "CobrowseSubTitle",
      "CallbackTitle": "Плучить звонок",
      "CobrowseSubTitle": "Для этого требуется подключение специалиста.",
      "AriaClose": "Закрыть Онлайн ассистент",
      "AriaWarning": "Предупреждение ",
      "AriaAlert": "Alert",
      "minute": "минута",
      "minutes": "минут",
      "AriaWindowLabel": "Окно Онлайн ассистент"
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse": {
      "agentJoined": "Специалист присоединился к сессии.",
      "youLeft": "Вы покинули сеанс. Совместный просмотр завершен.",
      "sessionTimedOut": "Время сеанса истекло. Совместный просмотр завершен.",
      "sessionInactiveTimedOut": "Время сеанса истекло. Совместный просмотр завершен.",
      "agentLeft": "Специалист покинул сессию. Совместный просмотр завершен.",
      "sessionError": "Произошла непредвиденная ошибка. Совместный просмотр завершен.",
      "sessionStarted": "Сеанс совместного просмотра запущен. Ожидание подключения специалиста к сеансу… ",
      "navRefresh": "Специалист обновил страницу. Перезагрузка.",
      "navBack": "Специалист нажал кнопку Назад. Перезагрузка страницы.",
      "navForward": "Специалист нажал кнопку Вперед. Перезагрузка страницы.",
      "navUrl": "Специалист запросил навигацию. Перезагрузка страницы.",
      "navFailed": "Запрос на навигацию от специалиста не выполнен.",
      "toolbarContent": "Код сессии: {sessionId}",
      "contentMasked": "Контент скрыт от специалиста.",
      "contentMaskedPartially": "Некоторый контент скрыт от специалиста.",
      "exitBtnTitle": "Выйти из сеанса совместного просмотра",
      "areYouOnPhone": "Вы разговариваете по телефону с нашим специалистом?",
      "areYouOnPhoneOrChat": "Вы разговариваете по телефону или в чате с нашим специалистом?",
      "connectBeforeCobrowse": "Вам необходимо связаться с нашим специалистом, чтобы продолжить совместный просмотр. Пожалуйста, позвоните нам или начните с нами чат, а затем снова запустите совместный просмотр.",
      "sessionStartedAutoConnect": "Сеанс совместного просмотра запущен. Ожидание подключения специалиста к сеансу…",
      "browserUnsupported": "К сожалению, ваш браузер в настоящее время не поддерживается.<br><br> Поддерживаемые браузеры: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 and above</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 and above</a></li></ul>",
      "chatIsAlreadyRunning": "Чат уже запущен на другой странице.",
      "modalYes": "Да",
      "modalNo": "Нет"
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar": {
      "SidebarTitle": "Нужна помощь?",
      "ChannelSelectorName": "Онлайн ассистент",
      "ChannelSelectorTitle": "Получите помощь от одного из наших специалистов прямо сейчас",
      "SearchName": "Поиск",
      "SearchTitle": "Поиск",
      "CallUsName": "Связаться с нами",
      "CallUsTitle": "Связаться с нами подробнее",
      "CallbackName": "Обратный звонок",
      "CallbackTitle": "Принять звонок",
      "ClickToCallName": "Нажмите, чтобы позвонить",
      "ClickToCallTitle": "Запросить номер телефона службы поддержки клиентов",
      "SendMessageName": "Отправить сообщение",
      "SendMessageTitle": "Отправить сообщение",
      "WebChatName": "Онлайн чат",
      "WebChatTitle": "Онлайн чат",
      "AriaClose": "Закрыть меню Нужна помощь?"
    },
    "webchat": {
      "ChatButton": "Чат",
      "ChatStarted": "Чат начат",
      "ChatEnded": "Чат завершен",
      "AgentNameDefault": "Специалист",
      "AgentConnected": "<%Agent%> присоединился к чату",
      "AgentDisconnected": "<%Agent%> Отключился",
      "BotNameDefault": "Робот",
      "BotConnected": "<%Bot%> присоединился к чату",
      "BotDisconnected": "<%Bot%> Отключился",
      "SupervisorNameDefault": "Супервайзер",
      "SupervisorConnected": "<%Agent%> присоединился к чату",
      "SupervisorDisconnected": "<%Agent%> Отключился",
      "AgentTyping": "...",
      "AriaAgentTyping": "Специалист печатает",
      "AgentUnavailable": "Ксожалению, сейчас нет доступных специалистов. Пожалуйста, попробуйте позднее.",
      "ChatTitle": "Онлайн чат",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Минута",
      "ChatFormFirstName": "Имя",
      "ChatFormLastName": "Фамилия",
      "ChatFormNickname": "Псевдоним",
      "ChatFormName": "Имя",
      "ChatFormCustomerId": "Номер клиента CCH",
      "ChatFormEmail": "Электронная почта",
      "ChatFormSubject": "Тема",
	    "ChatFormSubjectPassword": "Пароль",
	    "ChatFormSubjectService": "Услуга",
	    "ChatFormSubjectOrder": "Заказ",
	    "ChatFormSubjectFinance": "Финансы",
      "ChatFormPlaceholderFirstName": "Обязательно",
      "ChatFormPlaceholderLastName": "Обязательно",
      "ChatFormPlaceholderName": "По желанию",
      "ChatFormPlaceholderCustomerId": "По желанию",
      "ChatFormPlaceholderNickname": "По желанию",
      "ChatFormPlaceholderEmail": "Обязательно",
      "ChatFormPlaceholderSubject": "По желанию",
      "ChatFormSubmit": "Начать чат",
      "AriaChatFormSubmit": "Начать чат",
      "ChatFormCancel": "Отменить",
      "AriaChatFormCancel": "Завершить",
      "ChatFormClose": "Закрыть",
      "ChatInputPlaceholder": "Пишите ваше сообщение здесь",
      "ChatInputSend": "Отправить",
      "AriaChatInputSend": "Отправить",
      "ChatEndQuestion": "Вы уверены, что хотите завершить этот сеанс чата?",
      "ChatEndCancel": "Отменить",
      "ChatEndConfirm": "Завершить",
      "AriaChatEndCancel": "Отменить",
      "AriaChatEndConfirm": "Завершить",
      "ConfirmCloseWindow": "Вы уверены, что хотите завершить чат?",
      "ConfirmCloseCancel": "Отменить",
      "ConfirmCloseConfirm": "Закрыть",
      "AriaConfirmCloseCancel": "Отменить",
      "AriaConfirmCloseConfirm": "Закрыть",
      "ActionsDownload": "Загрузить диалог",
      "ActionsEmail": "Отправить на почту",
      "ActionsPrint": "Распечатать",
      "ActionsCobrowseStart": "Начать совместный просмотр",
      "AriaActionsCobrowseStartTitle": "Открывается сеанс совместного просмотра",
      "ActionsSendFile": "Приложить файл",
      "AriaActionsSendFileTitle": "Открывается диалог загрузки файла ",
      "ActionsEmoji": "Отправить эмодзи",
      "ActionsCobrowseStop": "Выход из совместного просмотра",
      "ActionsVideo": "Пригласить в видеочат",
      "ActionsTransfer": "Передача",
      "ActionsInvite": "Пригласить",
      "InstructionsTransfer": "Откройте эту ссылку на другом устройстве, чтобы перенести сеанс чата</br></br><%link%>",
      "InstructionsInvite": "Поделитесь этой ссылкой с другим человеком, чтобы добавить его в этот сеанс чата</br></br><%link%>",
      "InviteTitle": "Нужна помощь?",
      "InviteBody": "Сообщите нам, если нужна помощь",
      "InviteReject": "Нет, спасибо",
      "InviteAccept": "Начать чат",
      "AriaInviteAccept": "Принять приглашение и начать чат",
      "AriaInviteReject": "Отклонить приглашение",
      "ChatError": "Проблема с запуском сеанса чата. Пожалуйста, попробуйте еще раз.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Да",
      "ChatErrorDefaultButton": "Нет",
      "AriaChatErrorPrimaryButton": "Да",
      "AriaChatErrorDefaultButton": "Нет",
      "DownloadButton": "Загрузить",
      "AriaDownloadButton": "Загрузить файл",
      "FileSent": "Отправлено:",
      "FileTransferRetry": "Повторить",
      "AriaFileTransferRetry": "Повторите передачу файла",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Отменить",
      "AriaFileTransferCancel": "Отменить передачу файла",
      "RestoreTimeoutTitle": "Чат завершен",
      "RestoreTimeoutBody": "Время вашего предыдущего сеанса чата истекло. Вы бы хотели начать новый чат?",
      "RestoreTimeoutReject": "Нет, спасибо",
      "RestoreTimeoutAccept": "Начать чат",
      "AriaRestoreTimeoutAccept": "Принять приглашение и начать чат",
      "AriaRestoreTimeoutReject": "Отклонить приглашение",
      "EndConfirmBody": "Вы действительно хотите завершить сеанс чата?",
      "EndConfirmAccept": "Завершить",
      "EndConfirmReject": "Отменить",
      "AriaEndConfirmAccept": "Завершить",
      "AriaEndConfirmReject": "Отменить",
      "SurveyOfferQuestion": "Хотели бы Вы принять участие в опросе?",
      "ShowSurveyAccept": "Да",
      "ShowSurveyReject": "Нет",
      "AriaShowSurveyAccept": "Да",
      "AriaShowSurveyReject": "Нет",
      "UnreadMessagesTitle": "Непрочитанный",
      "AriaYouSaid": "Вы сказали",
      "AriaSaid": "сказали",
      "AriaSystemSaid": "Система сказала",
      "AriaWindowLabel": "Окно Онлайн чат",
      "AriaMinimize": "Уменьшить Онлайн чат",
      "AriaMaximize": "Увеличить Онлайн чат",
      "AriaClose": "Закрыть Онлайн чат",
      "AriaEmojiStatusOpen": "Набор эмодзи открыт",
      "AriaEmojiStatusClose": "Набор эмодзи закрыт",
      "AriaEmoji": "Эмодзи",
      "AriaEmojiPicker": "Набор эмодзи",
      "AriaCharRemaining": "Осталось символов",
      "AriaMessageInput": "Окно сообщения",
      "AsyncChatEnd": "Чат завершен",
      "AsyncChatClose": "Закрыть окно",
      "AriaAsyncChatEnd": "Чат завершен",
      "AriaAsyncChatClose": "Закрыть окно",
      "DayLabels": [
        "Воскресенье",
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Суббота"
      ],
      "MonthLabels": [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
      ],
      "todayLabel": "Сегодня",
      "Errors": {
        "102": "Имя обязательно.",
        "103": "Фамилия обязательно.",
        "161": "Пожалуйста, введите Ваше имя.",
        "201": "Файл не может быть отправлен.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Превышено максимальное количество прикрепленных файлов (<%MaxFilesAllowed%>).</p>",
        "202": "Файл не может быть отправлен.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Превышен лимит загрузки или максимальное количество прикрепленных файлов (<%MaxAttachmentsSize%>).</p>",
        "203": "Файл не может быть отправлен.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Недопустимый фрмат файла.</p>",
        "204": "К сожалению, ваше сообщение слишком длинное. Пожалуйста, напишите сообщение короче.",
        "240": "К сожалению, мы не можем начать чат в настоящее время. Пожалуйста, попробуйте позже.",
        "364": "Недопустимый адрес электронной почты.",
        "401": "К сожалению, мы не можем начать сеанс. Вы бы хотели начать новый чат?",
        "404": "К сожалению, мы не можем найти ваш предыдущий сеанс чата. Вы бы хотели начать новый чат?",
        "500": "К сожалению, произошла непредвиденная ошибка. Хотите закрыть текущий и начать новый чат?",
        "503": "К сожалению, сервис в настоящее время недоступен. Вы бы хотели закрыть текущий и начать новый чат? ",
        "ChatUnavailable": "К сожалению, мы не можем начать чат в настоящее время. Пожалуйста, попробуйте позже.",
        "CriticalFault": "Ваш сеанс чата неожиданно завершился из-за неизвестной проблемы. Приносим свои извинения за доставленные неудобства.",
        "StartFailed": "При запуске сеанса чата возникла проблема. Пожалуйста, проверьте своё интернет-соединение и правильно ли вы отправили всю необходимую информацию, затем повторите попытку.",
        "MessageFailed": "Ваше сообщение не было получено. Пожалуйста, попробуйте еще раз.",
        "RestoreFailed": "К сожалению, нам не удалось восстановить сеанс чата из-за неизвестной ошибки.",
        "TransferFailed": "В настоящее время невозможно перенести чат. Пожалуйста, повторите попытку позже.",
        "FileTransferSizeError": "Файл не может быть отправлен.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Размер файла больше допустимого (<%MaxSizePerFile%>).</p>",
        "InviteFailed": "В настоящее время невозможно создать приглашение. Пожалуйста, повторите попытку позже.",
        "ChatServerWentOffline": "Сообщения в настоящее время обрабатываются дольше обычного. Приносим извинения за задержку.",
        "RestoredOffline": "Сообщения в настоящее время обрабатываются дольше обычного. Приносим извинения за задержку.",
        "Disconnected": "<div style='text-align:center'>Соединение потеряно</div>",
        "Reconnected": "<div style='text-align:center'>Соединение восстановлено</div>",
        "FileSendFailed": "Файл не может быть отправлен.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>Произошло неожиданное отключение. Попробовать снова?</p>",
        "Generic": "<div style='text-align:center'>Произошла непредвиденная ошибка.</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Неверный тип файла. Разрешены только изображения.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "Размер файла больше допустимого.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "Превышено максимальное количество прикрепленных файлов.",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Invalid x-api-key transport configuration.",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Invalid endpoint transport configuration.",
        "pureengage-v3-rest-INVALID_NICKNAME": "Имя обязательно.",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "К сожалению, мы не можем авторизовать сеанс чата.",
        "purecloud-v2-sockets-400": "К сожалению, что-то пошло не так. Пожалуйста, проверьте своё соединение и правильно ли вы отправили всю необходимую информацию, затем повторите попытку.",
        "purecloud-v2-sockets-500": "К сожалению, произошла непредвиденная ошибка.",
        "purecloud-v2-sockets-503": "К сожалению, сервис в настоящее время недоступен ."
      }
    }
  }
}
