import React from 'react';
import styles from './CreditIssueMessage.pcss';
import Button from '../../../core/Button/Button';
import { GENERIC_L10N, PAGE_ID, URL_PATHS } from '../../../../common/Constants';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../../common/GoogleTagManager/GoogleTagManager';

/**
 * Safely renders HTML string removing potentially dangerous content
 * @param htmlString
 * @returns {JSX.Element}
 * @constructor
 */
const HtmlRenderer = ({ htmlString }) => {
    return <div>{parse(htmlString)}</div>;
};
HtmlRenderer.propTypes = {
    htmlString: PropTypes.string
};

export const CreditIssueMessage = props => {
    const commonLocalization = useSelector(state => state.pageContent.commonLocalization[PAGE_ID.GENERIC]);
    if (!commonLocalization) return <></>;

    const phoneNumber = commonLocalization[GENERIC_L10N.PHONE_NO];
    return (
        <>
            <HtmlRenderer htmlString={props.text} />
            {phoneNumber && (
                <a
                    href={`tel:${phoneNumber.replace(/\s/g, '')}`}
                    onClick={() => {
                        buttonClickEvent(
                            buttonClickEventConfigs.home.actions.creditIssue,
                            buttonClickEventConfigs.home.labels.creditIssueCallUs,
                            buttonClickEventConfigs.home.categories.home
                        );
                    }}
                >
                    {phoneNumber}
                </a>
            )}
            {props.checkInvoicesButton && (
                <div className={styles.actions}>
                    <Link to={URL_PATHS.MY_ACCOUNT_INVOICES}>
                        <Button
                            buttonType={'Primary'}
                            type={'button'}
                            onClick={e => {
                                props.onClose(e);
                                buttonClickEvent(
                                    buttonClickEventConfigs.home.actions.creditIssue,
                                    buttonClickEventConfigs.home.labels.creditIssueCheckYourInvoices,
                                    buttonClickEventConfigs.home.categories.home
                                );
                            }}
                            className={styles.invoiceButton}
                        >
                            {commonLocalization[GENERIC_L10N.CHECK_INVOICES]}
                        </Button>
                    </Link>
                </div>
            )}
        </>
    );
};
CreditIssueMessage.propTypes = {
    checkInvoicesButton: PropTypes.bool,
    text: PropTypes.string,
    onClose: PropTypes.func
};
