import React, { useEffect, useState } from 'react';
import Slider from 'rc-slider';
import classNames from 'classnames/bind';
import * as styles from './RangeInput.css';
import { usePrevious } from '../../../common/Utils/Utils';
import { isNumber } from 'lodash';

export default ({
    min = 0,
    max,
    step = 1,
    suffix = "",
    labelMin = "Min",
    labelMax = "Max",
    fromValue = 0,
    toValue = 0,
    showInputs = true,
    initialValue,
    onChange
}) => {

    const cx = classNames.bind(styles);
    const [value, setValue] = useState(initialValue);

    const convertNumber = (value) => {
        const floatSymb = (value.split(" ")[0].slice(-1) === "," || value.split(" ")[0].slice(-1) === ".") ? value.split(" ")[0].slice(-1) : ""
        if (Number.isFinite(parseFloat(value))) {
            return parseFloat(value) + floatSymb
        }
        return ""

    }

    const onChangeSlider = (v) => {
        setValue(v)
        onChange(v)
    }

    const setValueFrom = (fromValue) => {
        if (fromValue >= min && fromValue <= max && fromValue <= value[1]) {
            setValue((prevState) => [ fromValue, prevState[1]])
            onChange([fromValue, value[1]])
        }
        if (fromValue > max || fromValue > value[1]) {
            setValue((prevState) => [ min, prevState[1]])
            onChange([min, value[1]])
        }
    }
    const setValueTo = (toValue) => {
        if (toValue >= min && toValue <= max && toValue >= value[0]) {
            setValue((prevState) => [prevState[0], toValue])
            onChange([value[0], toValue])
        }
        if (toValue > max || toValue < value[0]) {
            setValue((prevState) => [prevState[0], max])
            onChange([value[0], max])
        }
    }

    const onSetFrom = ({ target: { value } }) => {
        setValueFrom(convertNumber(value) || min);
    }

    const onSetTo = ({ target: {value} }) => {
        setValueTo(convertNumber(value) || max)
    }
    
    useEffect(()=> {
        onChangeSlider(initialValue)
    }, [])

    useEffect(()=>{
        
        let arrayFrom = min;
        let arrayTo = max;

        if (toValue > 0 || (fromValue != min && fromValue > 0)){
            arrayFrom = fromValue;
            arrayTo = toValue;
        }else if (!isNumber(min) || !isNumber(max)) {
            return;
        }   

        setValue([arrayFrom,arrayTo])
        onChange([arrayFrom,arrayTo])
    }, [min, max, toValue, fromValue])

return <>
    <div className={cx('sliderWrapper')}>
        <Slider
            range {...{
                min,
                max,
                step,
                value,
                allowCross: false,
                onChange: onChangeSlider,
            }} 
        />
    </div>
    {showInputs && <div className={cx('inputs')}>
        <div className={cx('inputWrapper')}>
            <label className={cx('label')} htmlFor="minInputRange">{labelMin}</label>
            <input
                id="inputRangeFrom"
                className={cx('input')}
                value={`${value[0]} ${suffix}`}
                onChange={({target}) => setValue([convertNumber(target.value), value[1]])}
                onBlur={(e) => onSetFrom(e)}
            />
        </div>
        <div className={cx('inputWrapper')}>
            <label className={cx('label')} htmlFor="minInputRange">{labelMax}</label>
            <input
                id="inputRangeTo"
                className={cx('input')}
                value={`${value[1]} ${suffix}`}
                onChange={({target}) => setValue([value[0], convertNumber(target.value)])}
                onBlur={(e) => onSetTo(e)}
            />
        </div>
    </div>}
    </>
}
