import React from 'react';
import classNames from 'classnames/bind';
import styles from './ReadyToStartComponent.css';
import Button from '../../../../components/core/Button/Button';
import { useSelector } from 'react-redux';
import { FSA } from '../../../../common/Constants';

const cx = classNames.bind(styles);

const ReadyToStartComponent = ({ onGetStartedClicked, onTipsClicked }) => {
    const localization = useSelector(state => state.pageContent.localization);
    return (
        <div className={cx('readyToStartContainer')}>
            <div className={cx('readyToStartInner')}>
                <h1>{localization[FSA.SECTION_SEVEN_TEXT_1]}</h1>
                <div className={cx('readyToStartContent')}>
                    <div className={cx('readyToStartLeft')}>
                        <p>{localization[FSA.SECTION_SEVEN_TEXT_2]}</p>
                        <Button type='button' className={cx('button')} onClick={onGetStartedClicked}>
                            {localization[FSA.SECTION_SEVEN_TEXT_3]}
                        </Button>
                    </div>
                    <div className={cx('lineContainer')}>
                        <div className={cx('line')} />
                        <p className={cx('or-text')}>{localization[FSA.SECTION_SEVEN_TEXT_4]}</p>
                    </div>
                    <div className={cx('readyToStartRight')}>
                        <p>{localization[FSA.SECTION_SEVEN_TEXT_5]}</p>
                        <Button
                            type='button'
                            className={cx('button', 'buttonOutline')}
                            onClick={onTipsClicked}
                        >
                            {localization[FSA.SECTION_SEVEN_TEXT_6]}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReadyToStartComponent;
