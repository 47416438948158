import { put, takeLatest, call } from 'redux-saga/effects';
import FactorySaga from './FactorySaga';
import ProductListingApi from '../interfaces/PLP/PLPApi';
import {
    GET_PRODUCT_LIST_REQUEST,
    GET_PRODUCT_LIST_SUCCESS,
    GET_PRODUCT_LIST_ERROR,
    RESET_PRODUCT_LIST,
    APPLY_PRODUCT_FILTER,
    REMOVE_PRODUCT_FILTER,
    GET_BDAA_SUGGESTION,
    GET_BDAA_SUGGESTION_SUCCESS,
    GET_BDAA_SUGGESTION_ERROR,
    SET_PLP_VIEW_CHOICE,
    SET_PLP_VIEW_CHOICE_SUCCESS
} from '../actionTypes/ProductListActionTypes';
import { GET_FAVORITES_REQUEST_SUCCESS } from '../actionTypes/FavouriteActionTypes';
import { SET_SPINNER } from '../actionTypes/LoaderActionTypes';

export function* getProductListSaga(action = {}) {
    try {
        // Setting page section spinner when the main loader is removed for search flow.
        if (action.payload.isPageSpinner) {
            yield put({ type: SET_SPINNER, data: true });
        }

        const queryResult = action.payload.queryString;
        const result = yield call(FactorySaga, ProductListingApi.getProductListing, action);

        if (result.isSuccess) {
            yield put({ type: GET_PRODUCT_LIST_SUCCESS, data: { ...result.response.data, queryResult } });

            if (action.payload?.fromFavorites) {
                yield put({ type: GET_FAVORITES_REQUEST_SUCCESS, data: { ...result.response.data } });
            }
        } else {
            yield put({ type: GET_PRODUCT_LIST_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_PRODUCT_LIST_ERROR });
    } finally {
        if (action.payload.isPageSpinner) {
            yield put({ type: SET_SPINNER, data: false });
        }
    }
}

function* applyProductFilterSaga() {
    // TODO: read current filter values from store and add action.payload.filter to it and send to get api
    yield call(getProductListSaga);
}

function* removeProductFilterSaga() {
    // TODO: read current filter values from store and remove action.payload.filter to it and send to get api
    yield call(getProductListSaga);
}

export function* resetProductList() {
    // TODO: read current filter values from store and remove action.payload.filter to it and send to get api
    yield put({ RESET_PRODUCT_LIST });
}

export function* getBdaaSuggestionSaga(action) {
    try {
        action.isSkipLoader = true;

        const result = yield call(FactorySaga, ProductListingApi.getBdaaSuggestion, action);

        if (result.isSuccess) {
            yield put({ type: GET_BDAA_SUGGESTION_SUCCESS, data: result.response.data });
        } else {
            yield put({ type: GET_BDAA_SUGGESTION_ERROR, data: [] });
        }
    } catch (err) {
        yield put({ type: GET_BDAA_SUGGESTION_ERROR });
    }
}

export function* setPLPViewChoiceSaga(action) {
    try {
        yield call(FactorySaga, ProductListingApi.setPLPViewChoice, action);
        yield put({ type: SET_PLP_VIEW_CHOICE_SUCCESS, payload: action.payload });
    } catch (err) {
        console.error(err);
    }
}

export default function* watchProductListRequest() {
    yield takeLatest(GET_PRODUCT_LIST_REQUEST, getProductListSaga);
    yield takeLatest(APPLY_PRODUCT_FILTER, applyProductFilterSaga);
    yield takeLatest(REMOVE_PRODUCT_FILTER, removeProductFilterSaga);
    yield takeLatest(GET_BDAA_SUGGESTION, getBdaaSuggestionSaga);
    yield takeLatest(SET_PLP_VIEW_CHOICE, setPLPViewChoiceSaga);
}
