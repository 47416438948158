import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import Button from '../Button/Button';
import * as styles from './QtyInput.css';

const cx = classNames.bind(styles);

const QtyInput = props => {
    const {
        theme,
        value = 1,
        name,
        minValue,
        onChange,
        onDecrement,
        onIncrement,
        themeIncreBtn,
        themeDescrBtn,
        maxValue,
        automationId,
        id,
        isDecrementDisable,
        isIncrementDisable,
        isQtyError
    } = props;
    const [quantity, setQuantity] = useState(value);

    useEffect(() => {
        setQuantity(value);
    }, [value]);

    const isValidQuantity = (val, isInput = false) => {
        const numbers = /^[-+]?[0-9]+$/;

        if (isInput === false && val.toString().match(numbers) && val >= minValue && val <= maxValue) {
            return true;
        }
        if ((isInput === true && val === '') || (val.toString().match(numbers) && val >= minValue && val <= maxValue)) {
            return true;
        }

        return false;
    };

    const handleButtonClick = type => {
        let val = quantity !== '' ? parseInt(quantity, 10) : 1;
        val = type === 'Decrease' ? val - 1 : val + 1;
        if (isValidQuantity(val)) {
            // setQuantity(val); //Qty input state should be managed from outside
            if (!onDecrement && !onIncrement) {
                onChange && onChange(val, type === 'Decrease' ? 'remove' : 'add');
            }
            type === 'Decrease' && onDecrement && onDecrement('remove');
            type !== 'Decrease' && onIncrement && onIncrement('add');
        }
    };

    const handleInputChange = (event, isBlur = false) => {
        const val = (event.target.value || '').trim();
        if (isBlur === true) {
            const qty = isValidQuantity(val) ? parseInt(val, 10) : minValue;
            setQuantity(qty);
            onChange && onChange(qty, 'input');
        } else if (isValidQuantity(val, true)) {
            setQuantity(val);
        }
    };
    const handleKeyUp = e => {
        if (e.keyCode === 13) {
            e.target.blur();
        }
    };

    return (
        <div className={cx('inputQtyContainer', theme)}>
            <Button
                className={`${cx('decr-btn', themeDescrBtn)}`}
                automationId='at-decrease-qty-button'
                title='Decrease'
                onClick={e => {
                    e.preventDefault();
                    handleButtonClick('Decrease');
                }}
                isDisabled={quantity === minValue || isDecrementDisable}
            >
                -
            </Button>
            <input
                automation-id={automationId}
                type='text'
                id={id}
                name={name}
                value={quantity}
                className={cx(isQtyError && quantity < 1 ? 'error' : '')}
                onChange={e => handleInputChange(e)}
                onBlur={e => handleInputChange(e, true)}
                onKeyUp={e => handleKeyUp(e)}
            />
            <Button
                className={`${cx('incre-btn', themeIncreBtn)}`}
                automationId='at-increase-qty-button'
                title='Increase'
                isDisabled={quantity === maxValue || isIncrementDisable}
                onClick={e => {
                    e.preventDefault();
                    handleButtonClick('Increase');
                }}
            >
                +
            </Button>
        </div>
    );
};

QtyInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    automationId: PropTypes.string,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    theme: PropTypes.string,
    themeDescrBtn: PropTypes.string,
    themeIncreBtn: PropTypes.string,
    onChange: PropTypes.func,
    isDecrementDisable: PropTypes.bool,
    isIncrementDisable: PropTypes.bool,
    isQtyError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

QtyInput.defaultProps = {
    id: '',
    name: '',
    value: 1,
    automationId: 'at-qty-input',
    minValue: 1,
    maxValue: 99999,
    theme: '',
    themeDescrBtn: '',
    themeIncreBtn: '',
    onChange: null,
    isDecrementDisable: false,
    isIncrementDisable: false,
    isQtyError: false
};

export default QtyInput;
