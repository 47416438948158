import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { AUTH, PARAMS } from '../common/Api';
export default class HomeApi {
    static getCreditInfo(params) {
        const config = RestConfig.v2ComApiConfig(params);
        config.url = `/${config.baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${config.baseInfo[PARAMS.USER_ID]}${
            AUTH.CREDIT_LIMIT
        }`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }
}
