import React from 'react'
import classNames from 'classnames/bind';
import * as styles from "./PromotionNotification.css";

const cx = classNames.bind(styles);

function PromotionNotification({ isDealAchieved, isNewDiscountAchieved = false, achievedDealText, isLastPromotionActive, row, promotionsLength }) {
    const statusMessageText = () => {
        let statusMessage = row.promotionStatusMessage;
        if ((isLastPromotionActive || promotionsLength === 1 ) && !isDealAchieved ){
            statusMessage = row.promotionStatusMessageLastPromotion;
        }
        return statusMessage;
    }

    return (
        <div className={cx('promotionNotificationContainer', isNewDiscountAchieved ? 'yellowPromotionNotificationContainer' : isDealAchieved ? 'greenPromotionNotificationContainer' : 'redPromotionNotificationContainer')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                <g id="circle" transform="translate(0.5 0.5)">
                    <g id="Group_5498" data-name="Group 5498">
                        <g id="Group_5497" data-name="Group 5497">
                            <path id="Shape" d="M0,12A12,12,0,1,1,12,24,11.993,11.993,0,0,1,0,12Z" fill="none" stroke="#333" strokeWidth="1" />
                            <path id="Path" d="M.938,0A.937.937,0,0,0,0,.938V6.975a.938.938,0,1,0,1.875,0V.938A.937.937,0,0,0,.938,0Z" transform="translate(11.063 10.047)" fill="#333" />
                            <circle id="Oval" cx="1.266" cy="1.266" r="1.266" transform="translate(10.734 6.367)" fill="#333" />
                        </g>
                    </g>
                </g>
            </svg>
            <p className={cx('promotionNotificationText')}>
                {isNewDiscountAchieved ? achievedDealText : statusMessageText()}
            </p>
        </div>
    )
}

export default PromotionNotification
