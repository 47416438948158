export const GET_MINI_CART = 'GET_MINI_CART';
export const GET_MINI_CART_SUCCESS = 'GET_MINI_CART_SUCCESS';
export const GET_CART = 'GET_CART';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_MULTI_CART = 'GET_MULTI_CART';
export const GET_MULTI_CART_SUCCESS = 'GET_MULTI_CART_SUCCESS';
export const ADD_TO_CART = 'ADD_TO_CART';
export const UPDATE_SAP_CART = 'UPDATE_SAP_CART';
export const ADD_PRODUCTS_TO_CART_FAILED = 'ADD_PRODUCTS_TO_CART_FAILED';
export const UPDATE_CART = 'UPDATE_CART';
export const DELETE_FROM_CART = 'DELETE_FROM_CART';
export const DELETE_SAVED_CART_ENTRY = 'DELETE_SAVED_CART_ENTRY';
export const CLEAR_ALL_CART = 'CLEAR_ALL_CART';
export const CLEAR_ALL_CART_SUCCESS = 'CLEAR_ALL_CART_SUCCESS';
export const CLEAR_SAVED_CART = 'CLEAR_SAVED_CART';
export const CLEAR_SAVED_CART_SUCCESS = 'CLEAR_SAVED_CART_SUCCESS';
export const UPDATE_DELIVERY_DATE = 'UPDATE_DELIVERY_DATE';
export const UPDATE_MINI_CART_LIST = 'UPDATE_MINI_CART_LIST';
export const UPDATE_SAVED_MINI_CART_TOTAL_PRICE = 'UPDATE_SAVED_MINI_CART_TOTAL_PRICE';
export const UPDATE_TEMPORARY_PLP_CART = 'UPDATE_TEMPORARY_PLP_CART';
export const CREATE_TEMP_MINI_CART = 'CREATE_TEMP_MINI_CART';
export const UPDATE_MINI_CART_COUNT = 'UPDATE_MINI_CART_COUNT';
export const CLEAR_MINI_CART_ENTRY  = 'CLEAR_MINI_CART_ENTRY';
export const CLEAR_MINI_CART = 'CLEAR_MINI_CART';
export const UPDATE_RETURN_EMPTIES = 'UPDATE_RETURN_EMPTIES';
export const UPDATE_MARKETING_MATERIALS = 'UPDATE_MARKETING_MATERIALS';
export const ADD_TO_CART_SAP_UPDATE = 'ADD_TO_CART_SAP_UPDATE';
export const OPEN_MINI_CART = 'OPEN_MINI_CART';
export const CART_PAGE_LOAD = 'CART_PAGE_LOAD';
export const CART_ERROR = 'CART_ERROR';
export const CLEAR_REDUCER = 'CLEAR_REDUCER';

export const CHANGE_ORDER_TYPE = 'CHANGE_ORDER_TYPE';
export const RESTORE_CART = 'RESTORE_CART';
export const REMOVE_MANY_FROM_CART = 'REMOVE_MANY_FROM_CART'


