import {
    GET_CREDIT_INFO_REQUEST,
    GET_CREDIT_INFO_SUCCESS,
    CLEAR_CREDIT_INFO,
    CLEAR_REDUCER,
    NOTIFICATION_READ,
    SHOW_CREDIT_LIMIT_EXCEEDED_POPUP,
    CLOSE_CREDIT_LIMIT_EXCEEDED_POPUP,
    SHOW_NOTIFICATION
} from '../actionTypes/CreditInfoActionTypes';

const initialState = {};
export default function CreditInformationReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_CREDIT_INFO_REQUEST:
        case CLEAR_CREDIT_INFO:
            return {
                ...initialState
            };
        case GET_CREDIT_INFO_SUCCESS:
            return {
                ...state,
                ...action.data,
                showNotification: !!action.data.creditLimitNotification
            };
        case NOTIFICATION_READ:
            return {
                ...state,
                notificationRead: true,
                showNotification: false
            }
        case SHOW_NOTIFICATION:
            return {
                ...state,
                showNotification: true
            }
        case SHOW_CREDIT_LIMIT_EXCEEDED_POPUP:
            return {
                ...state,
                creditLimitExceededMessage: action.data.message,
                creditLimitExceededHeader: action.data.header,
                showCreditLimitExceededPopup: true,
                creditLimitNotificationRead: false
            }
        case CLOSE_CREDIT_LIMIT_EXCEEDED_POPUP:
            return {
                ...state,
                showCreditLimitExceededPopup: false,
                creditLimitNotificationRead: true
            }
        case CLEAR_REDUCER: {
            return initialState
        }
        default:
            return state;
    }
}
