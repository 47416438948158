import { AUTH, COOLER_API, PARAMS } from "../common/Api";
import RestClient from "../RestClient/RestClient";
import RestConfig from "../RestClient/RestConfig";


export default class CoolerAPI {
    static getCoolerDashboard(payload) {
        const { baseInfo = {}  } = payload;
        const config = RestConfig.v2ComApiConfig(payload);

        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${COOLER_API.COOLER_DASHBOARD}`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }
}