import React from 'react';
import _get from 'lodash/get';
import * as styles from '../AccountMyServices.css';
import classNames from 'classnames/bind';
import ContactMe from '../../../../components/site/ContactMe/ContactMe';

const cx = classNames.bind(styles);

export const AccountMyServicesContactMe = props => {


    return (
        <div className={cx('contactMeWrapper')}>
            <ContactMe from='accountMyServices' onCancelEvent={() => {}} />
        </div>
    );
};
