import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

export const promotionListFilteredData = (serviceRespData, isFromRemove = false) => {
    const transformedData = {};
    transformedData.promotions = [];
    const promotionList = _get(serviceRespData, 'promotionList', []);

    if (promotionList.length > 0) {
        for (let i = 0; i < promotionList.length; i += 1) {
            const promotionData = {};
            promotionData.showInitial = promotionList[i].showInitial;
            promotionData.promotionName = promotionList[i].promotionName;
            promotionData.promotionId = isFromRemove ? promotionList[i].promotion.id : promotionList[i].promotionId;
            promotionData.deals = [];
            promotionData.mechanicType = promotionList[i].promotion.mechanicType;
            const promotionMechanicContainers = promotionList[i];
            promotionData.cartName = _get(promotionList[i], 'cartName', '');
            promotionData.isDealAchieved = promotionList[i].isDealAchieved;
            promotionData.achievedDeal = promotionList[i].isDealAchieved && promotionList[i].achievedDeal;

            if (promotionList[i].promotion.mechanicType === 'B') {
                promotionData.promotionStatusMessage = promotionList[i].promotionStatusMessage;
                promotionData.promotionStatusMessageLastPromotion =
                    promotionList[i].promotionStatusMessageLastPromotion;
            }

            promotionData.deals = _get(promotionMechanicContainers, 'deals', []);
            promotionData.preselectedDeal = _get(promotionMechanicContainers, 'preselectedDeal', {});
            promotionData.promotionGroups = _get(promotionMechanicContainers, 'promotionMechanic', {});

         

            if (promotionData?.deals) {
                promotionData.deals.forEach(deal => {
                    deal?.conditionalGroups?.forEach(cg => {
                        cg.items.forEach((item, index) => {
                            promotionData?.promotionGroups?.conditionalGroups?.forEach((pgcg, pgcgIndex) => {
                                const findPromo = pgcg.value.productInfos.find(pi => pi?.code === item?.productCode);

                                if (findPromo) {
                                    deal.conditionalGroups[pgcgIndex].items[index].isInStock = findPromo?.isInStock;
                                }
                            });
                        });
                    });

                    deal?.freeGroup?.items?.forEach((cg, cgi) => {
                        promotionData?.promotionGroups?.freeGroup?.productInfos?.forEach(pgcg => {
                            if (pgcg?.code === cg?.productCode) {
                                deal.freeGroup.items[cgi].isInStock = pgcg?.isInStock;
                            }
                        });
                    });
                });

                
                
            }

            if(promotionData.preselectedDeal){
                promotionData.preselectedDeal?.conditionalGroups?.forEach((cg, cgi) => {
               
                    cg?.items.forEach((item, index) => {
                        promotionData?.promotionGroups?.conditionalGroups?.forEach((pgcg, pgcgIndex) => {
                            const findPromo = pgcg.value.productInfos.find(pi => pi?.code === item?.productCode);

                            if (findPromo) {
                                promotionData.preselectedDeal.conditionalGroups[cgi].items[index].isInStock = findPromo?.isInStock;
                            }
                        });
                    });
                });

                promotionData.preselectedDeal?.freeGroup?.items?.forEach((cg, cgi) => {
                    promotionData?.promotionGroups?.freeGroup?.productInfos?.forEach(pgcg => {
                        if (pgcg?.code === cg?.productCode) {
                            promotionData.preselectedDeal.freeGroup.items[cgi].isInStock = pgcg?.isInStock;
                        }
                    });
                });
            }
                
          

            if (
                promotionData.showInitial &&
                promotionData.deals[0] &&
                promotionData.preselectedDeal &&
                promotionData.deals[0].id !== promotionData.preselectedDeal.id &&
                promotionData.mechanicType !== 'B'
            )
                promotionData.deals.unshift(promotionData.preselectedDeal);

            promotionData.promotionGuid = _get(promotionMechanicContainers, 'promotionMechanic.promotionGuid', null);
            promotionData.note = _get(promotionMechanicContainers, 'promotionMechanic.note', '');

            transformedData.promotions.push(promotionData);
        }
    }

    if (serviceRespData.breadcrumbs) {
        transformedData.breadcrumbs = serviceRespData.breadcrumbs;
    }

    return {
        ...transformedData
    };
};

export const promotionDealFilteredData = serviceRespData => {
    return {
        ...serviceRespData
    };
};

export const promoCalendarListFilteredData = serviceRespData => {
    const transformedData = [];
    const promotionList = _get(serviceRespData, 'promotion', []);

    for (let i = 0; i < promotionList.length; i += 1) {
        const promotionData = {};
        promotionData.description = promotionList[i].description;
        promotionData.key = `promo-${i}`;
        promotionData.formattedStartDate = promotionList[i].formattedStartDate;
        promotionData.formattedEndDate = promotionList[i].formattedEndDate;
        promotionData.startDate = promotionList[i].startDate;
        promotionData.endDate = promotionList[i].endDate;
        promotionData.note = promotionList[i].note;
        promotionData.conditionalGroups = [];
        promotionData.freeGroup = {};
        promotionData.banners = promotionList[i]?.banners || null
        promotionData.listOfLimitedProductCodes = promotionList[i]?.listOfLimitedProductCodes || [];

        if (promotionList[i].promotionMechanic) {
            const conditionalGroups = _get(promotionList[i], 'promotionMechanic.conditionalGroups', []);

            for (let k = 0; k < conditionalGroups.length; k += 1) {
                const groupData = conditionalGroups[k].value;

                promotionData.conditionalGroups.push({
                    description: groupData.description,
                    key: `cond-${i}-${k}`,
                    groupType: groupData.groupType,
                    productInfos: _get(groupData, 'productInfos', [])
                });
            }

            const freeGroup = _get(promotionList[i], 'promotionMechanic.freeGroup', {});
            if (_isEmpty(freeGroup) === false) {
                promotionData.freeGroup = {
                    description: freeGroup.description,
                    key: `free-${i}`,
                    productInfos: _get(freeGroup, 'productInfos', [])
                };
            }
        } else {
            const conditionalGroups = promotionList[i];
            promotionData.conditionalGroups.push({
                description: conditionalGroups.description,
                productInfos: _get(conditionalGroups, 'productInfos', [])
            });
        }
        transformedData.push(promotionData);
    }

    return transformedData;
};
