import React from 'react';
import classNames from 'classnames/bind';
import * as styles from './Switcher.css';

export default ({ controlledValue, onChange, isDisabled }) => {
    const cx = classNames.bind(styles);

    const handleClick = () => {
        if(isDisabled) return
        onChange(!controlledValue)
    }

    return <span className={cx('switcher', controlledValue ? 'switcher_active' : '')} onClick={handleClick} />
}
