import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';

export default class OrderConfirmApi {
    static getOrderConfirmtData(payload) {
        const { baseInfo, orderId } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}/users/${baseInfo.userId}/checkout/orderconfirmation/${orderId}`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static changeSurveyLastClickedDay(payload) {
        const { baseInfo } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}/users/${baseInfo.userId}/openmicrosoftsurvey`;

        return RestClient.post(config).then(json => {
            return json;
        });
    }
}
