import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import Button from '../../core/Button/Button';
import Checkbox from '../../core/Checkbox/Checkbox';
import * as styles from './MarketConsent.css';
import { setMarketingConsent, hideMarketingConsent } from '../../../actions/MarketingAction';
import { PLP_L10N } from '../../../common/Constants';

const cx = classNames.bind(styles);

const MarketConsent = (props) => {
    const [newsletter, toggleNewsletter] = useState(props.newsletterSubscription);
    const [offers, toggleOffers] = useState(props.tradingOffersSubscription);
    const { localization, onClose, actions } = props;
    
    const buttons = [
        {
            label: localization[PLP_L10N.CONSENT_ALLOW],
            buttonType: 'Ordinary',
        },
        {
            label: localization[PLP_L10N.CONSENT_DENY],
            buttonType: 'Primary',
        }
    ];

    useEffect(() => {
        toggleNewsletter(props.newsletterSubscription);
    }, [props.newsletterSubscription])

    useEffect(() => {
        toggleOffers(props.tradingOffersSubscription);
    }, [props.tradingOffersSubscription])

    const sendResults = (allow) => {
        const consentDate = Math.floor((new Date()).getTime() / 1000);
        actions.setMarketingConsent({
            newsletter: allow ? newsletter : false,
            offers: allow ? offers : false,
            consentDate
        });
    }

    return (
        <div className={cx('marketConsent')}>
            <h3>{localization[PLP_L10N.CONSENT_TITLE]}</h3>
            <div className={cx('description')}>
                {localization[PLP_L10N.CONSENT_DESCRIPTION]}    
            </div>
            <div className={cx('marketConsent-controlsContainer')}>
                <div className={cx('marketConsent-controlsCheckbox')}>
                    <ul>
                        <li>
                            <div className={cx('formGroup')}>
                                <Checkbox
                                    checked={newsletter}
                                    name="newslater-checkbox"
                                    theme={cx('theme')}
                                    themecheckboxCheckmark={cx('themecheckboxCheckmark')}
                                    onChange={() => {toggleNewsletter(!newsletter)}}
                                    labelText={localization[PLP_L10N.CONSENT_NEWSLETTER]}
                                />
                            </div>
                            <div className={cx('marketingCheckboxDescription')}>{localization[PLP_L10N.CONSENT_NEWSLETTER_DESCRIPTION]}</div>
                        </li>
                        <li>
                            <div className={cx('formGroup')}>
                                <Checkbox
                                    checked={offers}
                                    name="offeres-checkbox"
                                    theme={cx('theme')}
                                    themecheckboxCheckmark={cx('themecheckboxCheckmark')}
                                    onChange={() => {toggleOffers(!offers)}}
                                    labelText={localization[PLP_L10N.CONSENT_TRADE_OFFERS]}
                                />
                            </div>
                            <div className={cx('marketingCheckboxDescription')}>{localization[PLP_L10N.CONSENT_TRADE_OFFERS_DESCRIPTION]}</div>
                        </li>
                    </ul>
                </div>
                <div className={cx('marketConsent-controlsButtons')}>
                    {buttons.map(({label, buttonType}, i) =>
                        <Button
                            size="Md"
                            buttonType={buttonType}
                            onClick={() => {
                                sendResults(i == 0);
                                actions.hideMarketingConsent()
                            }}
                        >
                            {label}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}

MarketConsent.defaultProps = {
    onClose: () => {},
};

const mapStateToProps = state => {
    return {
        localization: _get(state, 'pageContent.localization'),
        newsletterSubscription: _get(state, 'marketing.newsletterSubscription'),
        tradingOffersSubscription: _get(state, 'marketing.tradingOffersSubscription'),
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ 
        setMarketingConsent,
        hideMarketingConsent
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketConsent);