/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import * as styles from './CoolerDashboard.css';
import Layout from '../../../components/site/Layout/Layout';
import CoolerAverage from '../../../components/site/CoolerAverage/CoolerAverage';
import MyCooler from '../../../components/site/MyCooler/MyCooler';
import PerformanceTips from './PerformanceTips';
import { getDashboardOverview } from '../../../actions/CoolerDashboardAction';

const cx = classNames.bind(styles);

class CoolerDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showMarketing: false,
            outletAverages: {},
            legendDoorOpenings: {},
            coolers: [],
            tableHeaders: {},
            backgroundColors: ['#E51E2D', '#3E3E3C', '#9D9D9D'],
            currentColor: 0,
            currentPerformanceTip: 0
        }
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getDashboardOverview();
    }

    static getDerivedStateFromProps(props, state) {
        const {
            tableHeaders,
            marketing,
            outletAverages,
            legendDoorOpenings,
            coolers,
            coolerPerformanceTips,
            coolerTipNext,
            coolerTipTitle,
            pageTitle
        } = props;

        return {
            ...state,
            pageTitle,
            tableHeaders: tableHeaders || {},
            outletAverages: outletAverages || {},
            legendDoorOpenings: legendDoorOpenings || {},
            coolers: coolers || [],
            coolerPerformanceTips: coolerPerformanceTips || [],
            showMarketing: marketing.visible,
            coolerTipNext: coolerTipNext || 'Next tip',
            coolerTipTitle: coolerTipTitle || 'COOLERS TIPS:'
        }
    }

    sectionTitle = (title) => {
        return (
            <h2 className={cx('SectionTitle')}>{title}</h2>
        )
    }

    renderLegendItem = ({description, percentage, title, color = '#ff0000'}, index) => {
        return (
            <div key={index +'legend-item'} className={cx('LegendItemContainer')}>
                <div
                    className={cx('LegendItem')}
                    style={{backgroundColor: color}}
                >
                    <div>{title}</div>
                    <div>{percentage}</div>
                </div>
                <div className={cx('LegendItemLabel')} style={{ color }}>
                    {description}
                </div>
            </div>
        )
    }

    customSorting = (inputObj) => {
        const sortingOrder = {
            doorOpenings: 1,
            temperature: 2,
            lastDownload: 3
        };
        const sortedArr = [];
        Object.entries(inputObj).forEach(el=> {
            const index = sortingOrder[el[0]];
            sortedArr[index] = [el[0], el[1]]}
        );
        return sortedArr
    }

    changeTipBackground = (num) => {
        //if the last background color in the array is already reached the loop is reset
        if (this.state.currentColor + num > this.state.backgroundColors.length-1) {
            this.setState({currentColor: 0});
            return;
        }
        this.setState(prevState => { return {currentColor: prevState.currentColor + num}}) 
    }

    selectRandomTip = () => {
        if(this.state.coolerPerformanceTips?.length) {
            let currentPerformanceTip = Number(Math.floor(Math.random() * this.state.coolerPerformanceTips.length));
            while (currentPerformanceTip === this.state.currentPerformanceTip) {
                currentPerformanceTip = Number(Math.floor(Math.random() * this.state.coolerPerformanceTips.length));
            }
            this.setState({currentPerformanceTip});
        }
    }

    render() {
        const {
            tableHeaders,
            showMarketing,
            outletAverages,
            legendDoorOpenings,
            coolers,
            coolerPerformanceTips,
            pageTitle
        } = this.state;

        return (
            <Layout showMarketing={showMarketing} showDashboardHeader showOutlet>
                <section className={cx('Section', 'PageTitleSection')}>
                    <h1>{pageTitle}</h1>
                </section>
                <section className={cx('Section', 'ContentSection')}>
                    {this.sectionTitle(outletAverages.title)}
                    <div className={cx('OutletAverages')}>
                        {this.customSorting(outletAverages).map((([key, value]) => 
                            key !== 'title' && <CoolerAverage key={value.title} type={key} {...value}/>))}
                    </div>
                </section>
                <section className={cx('Section', 'ContentSection')}>
                    {this.sectionTitle(legendDoorOpenings.title)}
                    <div className={cx('LegendContainer')}>
                        {Object.entries(legendDoorOpenings)
                            .sort(([keyA], [keyB]) => keyA < keyB ? -1 : 1)
                            .map(([key, value], index) => 
                                key !== 'title' && this.renderLegendItem(value, index))
                        }
                    </div>
                </section>
                <section className={cx('Section', 'ContentSection', 'CoolerSection')}>
                    {this.sectionTitle(tableHeaders.tableTitle)}
                    <div className={cx('CoolersContainer')}>
                        <div className={cx('CoolerListHeader')}>
                            <div className={cx('CoolerListNameHeader')}>{tableHeaders.coolerHeader}</div>
                            <div className={cx('CoolerListAverageHeader')}>{tableHeaders.averagesHeader}</div>
                        </div>
                        {coolers.map(cooler => <MyCooler key={cooler.macAddress} cooler={cooler} customSortingFn={this.customSorting} />)}
                    </div>
                </section>
                <PerformanceTips
                    changeTipBackground={this.changeTipBackground}
                    selectRandomTip={this.selectRandomTip}
                    backgroundColor={this.state.backgroundColors[this.state.currentColor]}
                    currentPerformanceTip={this.state.currentPerformanceTip}
                    coolerPerformanceTips={coolerPerformanceTips}
                    coolerTipNext={this.state.coolerTipNext}
                    coolerTipTitle={this.state.coolerTipTitle}
                />
            </Layout>
        )
    }
}

const mapStateToProps = ({marketing, coolers}) => ({
    ...coolers,
    marketing,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        getDashboardOverview
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CoolerDashboard);
