import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MessageBox from '../MessageBox/MessageBox';
import { clearNotification, clearAllNotification } from '../../../actions/NotificationAction';
import { MESSAGE_TYPES, MESSAGE_LEVELS } from '../../../common/Constants';

function Notification(props) {
    const { cutOffNotifications, messageList } = props.notification;

    useEffect(() => {
        return () => {
            props.actions.clearAllNotification();
        };
    }, []);

    return (
        <>
            {(cutOffNotifications || []).map(
                (cutOff, index) =>
                    cutOff && (
                        <MessageBox
                            key={`${cutOff.message}:${index.toString()}`}
                            id={index}
                            level={MESSAGE_LEVELS.SECTION}
                            type={MESSAGE_TYPES.WARN}
                            closeBtn
                            onClose={id => props.actions.clearNotification(id, true)}
                        >
                            {cutOff.message}
                        </MessageBox>
                    )
            )}
            {(messageList || []).map(
                (msg, index) =>
                    msg && (
                        <MessageBox
                            key={`${msg}:${index.toString()}`}
                            id={index}
                            level={MESSAGE_LEVELS.SECTION}
                            type={msg.type || MESSAGE_TYPES.INFO}
                            closeBtn
                            onClose={props.actions.clearNotification}
                            isAutoClose={!!msg.isAutoClose}
                            autoCloseTime={msg.autoCloseTime}
                        >
                            < p dangerouslySetInnerHTML={{__html: msg?.content}} />
                        </MessageBox>
                    )
            )}
        </>
    );
}

Notification.propTypes = {
    notification: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

Notification.defaultProps = {
    notification: {}
};

const mapStateToProps = state => {
    return {
        notification: state.notification
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            clearNotification,
            clearAllNotification
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
