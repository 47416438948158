import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import Button from '../../core/Button/Button';
import * as styles from './ShowPriceTemplate.css';
import { PLP_L10N, PAGE_ID, GENERIC_L10N } from '../../../common/Constants';
import { getProductList } from '../../../actions/ProductListAction';
import { productListGetURL, getUpdatedURL } from '../../../common/Utils/Utils';
const cx = classNames.bind(styles);

export class ShowPriceTemplate extends Component {
    static propTypes = {
        onCancel: PropTypes.func,
        appliedFacets: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        localization: PropTypes.objectOf(PropTypes.string),
        commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    };

    static defaultProps = {
        onCancel: () => {},
        appliedFacets: {},
        localization: {},
        commonLocalization: {}
    };

    handleCreate = () => {
        const { showPriceActions, appliedFacets, onCancel, params, location } = this.props;
        if (!appliedFacets.data[PLP_L10N.SHOW_PRICE]) {
            appliedFacets.data[PLP_L10N.SHOW_PRICE] = [];
        }
        if (!isEmpty(appliedFacets)) {
            appliedFacets.data[PLP_L10N.SHOW_PRICE][0] = 'true';
        }

        getUpdatedURL(params.plpType, location.search, appliedFacets);
        const queryString = productListGetURL(appliedFacets);
        showPriceActions.getProductList({ queryString });
        onCancel(true);
    };

    render() {
        const { localization, commonLocalization } = this.props;

        return (
            <div automation-id='at-show-price-confirmation-popup' className={cx('NewTemplateContainer')}>
                <div className={cx('createNewTemplate', 'createnewModalTitle')}>
                    <h4 automation-id='at-show-price-information-text' className={cx('text-center', 'title-txt')}>
                        {localization[PLP_L10N.PRICE_TEXT]}
                    </h4>
                    <div className={cx('buttonSection', `float-right`)}>
                        <Button
                            automationId='at-show-button-popup'
                            className={cx('createButton')}
                            type='button'
                            size='Sm'
                            buttonType='Primary'
                            onClick={this.handleCreate}
                        >
                            {localization[PLP_L10N.SHOW]}
                        </Button>
                        <Button
                            automationId='at-cancel-button-popup'
                            className={cx('cancelButton')}
                            type='button'
                            size='Sm'
                            buttonType='Primary'
                            onClick={() => this.props.onCancel(false)}
                        >
                            {commonLocalization[GENERIC_L10N.CANCEL_BTN]}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    //orderTemplateDetails: _get(state, 'orderTemplateDetails'),
    localization: _get(state, 'pageContent.localization'),
    commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
});

const mapDispatchToProps = dispatch => {
    return {
        showPriceActions: bindActionCreators(
            {
                getProductList
            },
            dispatch
        )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowPriceTemplate);
