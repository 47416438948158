import { CLEAR_ACCOUNT_STATEMENT_ERROR, GET_ACCOUNT_STATEMENT_ERROR } from '../actionTypes/AccountStatementActionTypes';

const INITIAL_STATE = {
    error: null
};

export default function AccountStatementReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_ACCOUNT_STATEMENT_ERROR: {
            const { error } = action.data;
            return {
                ...state,
                error: error.message
            };
        }
        case CLEAR_ACCOUNT_STATEMENT_ERROR: {
            return {
                ...state,
                error: null
            };
        }
        default:
            return state;
    }
}
