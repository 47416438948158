import React from 'react';
import Link from 'react-router/lib/Link';

import classNames from 'classnames/bind';
import styles from './BestSellingComponent.css';
import { useSelector } from 'react-redux';
import { FSA, FSA_IMAGES } from '../../../../common/Constants';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

const BestSellingComponent = () => {
    const localization = useSelector(state => state.pageContent.localization);
    const components = useSelector(state => state.pageContent.components);
    const bestSellingSrc = components
        .find(component => component.uid === FSA_IMAGES.BEST_SELLING)
        ?.url?.replace('"', '');
    const brandLogosSrc = components.find(component => component.uid === FSA_IMAGES.BRAND_LOGOS)?.url?.replace('"', '');
    const brandLogosLink = components.find(component => component.uid === FSA_IMAGES.BRAND_LOGOS)?.urlLink;
    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);

    return (
        <div className={cx('bestSellingContainer')}>
            <div className={cx('bestSellingInner')}>
                <div className={cx('bestSellingLeft')}>
                    <h1>{localization[FSA.SECTION_FIVE_TEXT_1]}</h1>
                    <p>{localization[FSA.SECTION_FIVE_TEXT_2]}</p>
                    <Link
                        target='_blank'
                        to={brandLogosLink}
                        onClick={() => {
                            try {
                                buttonClickEvent(
                                    buttonClickEventConfigs.fsa.actions.downloadImages,
                                    buttonClickEventConfigs.fsa.labels.downloadImages,
                                    buttonClickEventConfigs.fsa.categories.tipsNTricks
                                );
                            } catch (ex) {
                                console.error(ex);
                            }
                        }}
                    >
                        <img src={`${assetBaseUrl}${brandLogosSrc}`} alt='Brands' />
                    </Link>
                </div>
                <div className={cx('bestSellingRight')}>
                    <img src={`${assetBaseUrl}${bestSellingSrc}`} alt='Best Selling' />
                </div>
            </div>
        </div>
    );
};

export default BestSellingComponent;
