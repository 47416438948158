import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import * as styles from './StockNotificationModal.css';
import Modal from '../Modal/Modal';
import Input from '../Input/Input';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import {GENERIC_L10N} from '../../../common/Constants';
import { getUserEmail } from '../../../common/UserManager/UserManager';

const cx = classNames.bind(styles);

const StockNotificationModal = props => {
    const {
        showNotifyPopup,
        subscribeCustomerToTheProduct,
        onCloseModal,
        localization
    } = props;

    const [email, setEmail] = useState(getUserEmail());
    const [isSubmitButtonDisabled, setButtonDisability] = useState(!getUserEmail());

    const onEmailChange = event => {
        const {
            target: { value = '' }
        } = event;

        setButtonDisability(value==='')
        setEmail(value);
    };
    
    return (
        <div className={cx('contactUsModal')}>
            <Modal
                showModal={showNotifyPopup}
                modalTheme={cx('userThemeModal')}
                modalTitleTheme={cx('modalTitleTheme')}
                themeContent={cx('themeContent')}
                themeClose={cx('themeClose')}
                themeModalBlock={cx('themeModalBlock')}
                defaultCloseBtn={true}
                onClose={() => onCloseModal()}
            >
                <div className={cx('clearContent')}>

                    <div className={cx('contentText')}>
                        <br /> <br />
                        <h1>{localization[GENERIC_L10N.STOCK_NOTIFICATION.TITLE]}</h1><br />
                        <p >
                            {localization[GENERIC_L10N.STOCK_NOTIFICATION.DESCRIPTION]}
                        </p> <br />

                    </div>
                    <Input
                        automationId='at-email-textfield'
                        id='notify-email'
                        type='email'
                        placeholder= {localization[GENERIC_L10N.STOCK_NOTIFICATION.EMAIL]}
                        onChange={onEmailChange}
                        value={email}
                    />
                    <div className={cx('bottomBtns')}>
                        <Button
                            type='button'
                            size={cx('Sm')}
                            isDisabled={isSubmitButtonDisabled}
                            className={cx('userBtns', 'notifyButtonRed')}
                            onClick={() => subscribeCustomerToTheProduct(email)}>
                            {localization[GENERIC_L10N.STOCK_NOTIFICATION.NOTIFY_ME]}

                            <span className={cx('addtoCardIcon')}>
                                <Icon
                                    className={cx('notifyIcon')}
                                    width='17px'
                                    height='16px'
                                    viewBox='0 0 17 16'
                                    name='white-envelop'
                                />
                            </span>
                        </Button>
                        <Button
                            type='button'
                            size={cx('Sm')}
                            className={cx('userBtns', 'cancelButton')}
                            onClick={() => onCloseModal()}>
                            {localization[GENERIC_L10N.STOCK_NOTIFICATION.CANCEL]}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

StockNotificationModal.propTypes = {
    showNotifyPopup: PropTypes.bool.isRequired,
    onClose: PropTypes.func
};

StockNotificationModal.defaultProps = {
    defaultCloseBtn: true,
    titleAutomationId: ''
};

export default StockNotificationModal;
