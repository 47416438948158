import React from 'react';
import { Link } from 'react-router';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { PRE_LOGIN_UID } from '../../../common/Constants';
import { findComponent } from '../../../common/Utils/Utils';
import { getLocalization } from '../../../common/UserManager/UserManager';

import * as styles from './OurCommitment.css';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

const OurCommitment = ({ className, cmsComponents, readMoreBtn }) => {
    if (!cmsComponents) {
        return null;
    }
    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);
    const bannerComponent = findComponent(cmsComponents, PRE_LOGIN_UID.OURCOMMITMENT_COMPONENTS.BANNER_UID);
    const titleComponent = findComponent(cmsComponents, PRE_LOGIN_UID.OURCOMMITMENT_COMPONENTS.TITLE_UID);
    const paragraph1Component = findComponent(cmsComponents, PRE_LOGIN_UID.OURCOMMITMENT_COMPONENTS.PARAGRAPH1_UID);
    const paragraph2Component = findComponent(cmsComponents, PRE_LOGIN_UID.OURCOMMITMENT_COMPONENTS.PARAGRAPH2_UID);
    const readMoreLinkComponent = findComponent(
        cmsComponents,
        PRE_LOGIN_UID.OURCOMMITMENT_COMPONENTS.READ_MORE_LINK_UID
    );
    const localization = getLocalization();

    return (
        <section className={cx(className)}>
            {localization?.siteId !== 'russia' && (
                <div className={cx('commitment')}>
                    <div className={cx('commitment-img-container')}>
                        {bannerComponent && (
                            <img src={`${assetBaseUrl}/${bannerComponent.url}`} alt={bannerComponent.altText} />
                        )}
                    </div>
                    <div className={cx('commitment-info')}>
                        <h2>{titleComponent?.content}</h2>
                        <p>
                            {paragraph1Component?.content} {paragraph2Component?.content}
                        </p>
                        <Link
                            className={cx('read-more')}
                            target='_blank'
                            rel='noopener noreferrer'
                            to={
                                readMoreLinkComponent?.url ||
                                'https://www.coca-colahellenic.com/en/a-more-sustainable-future/netzeroby40'
                            }
                            onClick={() =>
                                buttonClickEvent(
                                    buttonClickEventConfigs.preLogin.actions.preLoginFourthBanner,
                                    buttonClickEventConfigs.preLogin.labels.preLoginFourthBannerReadMore,
                                    buttonClickEventConfigs.preLogin.categories.preLogin
                                )
                            }
                        >
                            {readMoreBtn?.content}
                        </Link>
                    </div>
                </div>
            )}
        </section>
    );
};

export default OurCommitment;
