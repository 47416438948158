import React from 'react';
import Link from 'react-router/lib/Link';
import * as styles from './MyRewardsLoyaltyCard.css';
import classNames from 'classnames/bind';
import Icon from '../../core/Icon/Icon';
import { default as IconStepper } from '../../core/Stepper/Stepper';
import { MY_REWARDS } from '../../../common/Constants';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

const cx = classNames.bind(styles);

const MyRewardsLoyaltyCard = ({
    title,
    ordersText,
    ordersValue,
    discountText,
    discountValue,
    discountReceived,
    maxSteps,
    currentStep,
    link,
    status,
    statusText,
    className,
    headingClassName
}) => {
    const isCompleted = status === MY_REWARDS.ONBOARDING_COMPLETED_STATUS;
    const loyaltyProgramNotStarted = status === MY_REWARDS.LOYALTY_PROGRAM_NOT_STARTED;
    return (
        <div
            className={cx(
                'loyalty-orders-container',
                (!isCompleted || (isCompleted && discountValue === '0')) && 'coming-soon',
                className
            )}
        >
            <div className={cx('loyalty-orders-heading', headingClassName)}>
                <h1 className={cx('loyalty-orders-title')}>{title}</h1>
                <Icon iconType='svg' width='40px' height='40px' viewBox='0 0 40 40' name='loyalty-orders' />
            </div>
            <div className={cx('loyalty-orders-inner')}>
                <p className={cx('loyalty-orders-text')}>{ordersText}</p>
                <p className={cx('loyalty-orders-value')}>
                    {loyaltyProgramNotStarted || (isCompleted && discountValue === '0') ? '0' : ordersValue}
                </p>
            </div>
            <div className={cx('loyalty-orders-inner-div')}>
                {discountReceived && !loyaltyProgramNotStarted ? <p className={cx('loyalty-orders-text', 'discount-text')}>{parse(discountReceived)}</p> : (
                    <p className={cx('loyalty-orders-text', 'discount-text')}>
                        <span className={cx('loyalty-orders-value', 'discount-value')}>
                            {loyaltyProgramNotStarted || (isCompleted && discountValue === '0') ? '3' : discountValue}
                        </span>
                        {discountText}
                    </p>
                )}
                <IconStepper
                    totalSteps={maxSteps}
                    currentStep={loyaltyProgramNotStarted || (isCompleted && discountValue === '0') ? '0' : currentStep}
                    icon='loyalty-orders-cart'
                />
            </div>
            {link && (
                <div className={cx('loyalty-orders-footer')}>
                    <Link
                        to={link}
                        className={cx((!isCompleted || (isCompleted && discountValue === '0')) && 'disabled-click')}
                    >
                        <Icon
                            className={cx('arrow-right')}
                            iconType='svg'
                            width='35px'
                            height='35px'
                            viewBox='0 0 35 35'
                            name='loyalty-arrow-right'
                        />
                    </Link>
                </div>
            )}
            {(!isCompleted || (isCompleted && discountValue === '0')) && (
                <div className={cx('status')}>
                    <p>{statusText}</p>
                </div>
            )}
        </div>
    );
};

MyRewardsLoyaltyCard.propTypes = {
    title: PropTypes.string,
    ordersText: PropTypes.string,
    ordersValue: PropTypes.string,
    discountText: PropTypes.string,
    discountValue: PropTypes.string,
    discountReceived: PropTypes.string,
    maxSteps: PropTypes.number,
    currentStep: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    link: PropTypes.string,
    status: PropTypes.string,
    statusText: PropTypes.string,
    className: PropTypes.string,
    headingClassName: PropTypes.string
};

export default MyRewardsLoyaltyCard;
