import {
    GET_CLAIM_TICKET_HISTORY_SUCCESS,
    UPDATE_TICKET_ID_REQUEST,
    UPDATE_CLAIM_CREATION_PERIOD_REQUEST,
    UPDATE_CLAIM_TICKET_TYPE_REQUEST,
    CLEAR_CLAIM_HISTORY_SEARCH_FORM,
    DEFAULT_CLAIM_HISTORY_VALUES,
    RESET_CLAIM_HISTORY
} from '../actionTypes/ClaimTicketHistoryActionTypes';
import filterClaimHistoryData from '../service/ClaimHistoryFilter';

export default function ClaimHistoryReducer(state = {}, action = {}) {
    switch (action.type) {
        case GET_CLAIM_TICKET_HISTORY_SUCCESS: {
            const transformedData = filterClaimHistoryData(action.claimHistoryData);
            return {
                ...state,
                ...transformedData,
                defualtProcessType: transformedData.currentProcessType,
                defualtPeriod: transformedData.currentPeriod,
                defaultStatus: transformedData.currentStatusType
            };
        }
        case UPDATE_TICKET_ID_REQUEST:
            return {
                ...state,
                ticketId: action.payload
            };
        case UPDATE_CLAIM_CREATION_PERIOD_REQUEST: {
            const newState = { ...state };
            newState.currentPeriod = action.payload;
            return newState;
        }
        case UPDATE_CLAIM_TICKET_TYPE_REQUEST: {
            const newState = { ...state };
            newState.currentProcessType = action.payload;
            return newState;
        }
        case CLEAR_CLAIM_HISTORY_SEARCH_FORM: {
            const newState = { ...state };
            newState.currentProcessType = state.defualtProcessType;
            newState.currentPeriod = state.defualtPeriod;
            newState.ticketId = '';
            return newState;
        }
        case DEFAULT_CLAIM_HISTORY_VALUES: {
            const newState = { ...state };
            newState.currentProcessType = 'All';
            newState.currentPeriod = '30';
            newState.ticketId = '';
            return newState;
        }
        case RESET_CLAIM_HISTORY: {
            return {};
        }
        default:
            return state;
    }
}
