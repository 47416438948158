import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { LOCALIZATION } from '../common/Api';

export default function getLocalization(params) {
    const config = RestConfig.v2NoAuthComApiConfig();
    config.url = LOCALIZATION.COUNTRY_SELECT;
    config.params = params;

    return RestClient.get(config).then(json => {
        return json;
    });
}
