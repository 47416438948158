const filterOrderlistData = list => {
    const processTypeList = [];
    const periodList = [];
    const statusList = [];
    let defaultPeriod = '';
    let defaultProcessType = '';
    let defaultStatus = '';

    if (!list.currentPeriod) {
        for (let i = 0; i < list.periods.length; i += 1) {
            const periodObj = {
                id: list.periods[i].code,
                value: list.periods[i].code,
                label: list.periods[i].name
            };
            if (list.periods[i].selected === 'true') {
                defaultPeriod = list.periods[i].code;
            }
            periodList.push(periodObj);
        }
    }

    if (!list.currentProcessType) {
        for (let i = 0; i < list.processTypes.length; i += 1) {
            const processTypeObj = {
                id: list.processTypes[i].code,
                value: list.processTypes[i].code,
                label: list.processTypes[i].name
            };
            if (list.processTypes[i].selected === 'true') {
                defaultProcessType = list.processTypes[i].code;
            }
            processTypeList.push(processTypeObj);
        }
    }

    if (!list.currentStatusType) {
        for (let i = 0; i < list.statusTypes.length; i += 1) {
            const statusTypeObj = {
                id: list.statusTypes[i].code,
                value: list.statusTypes[i].code,
                label: list.statusTypes[i].name
            };
            if (list.statusTypes[i].selected === 'true') {
                defaultStatus = list.statusTypes[i].code;
            }
            statusList.push(statusTypeObj);
        }
    }

    if (periodList.length > 0 && processTypeList.length > 0) {
        list.periods = periodList;
        list.processTypes = processTypeList;
        list.statusTypesList = statusList;
        list.currentProcessType = defaultProcessType;
        list.currentPeriod = defaultPeriod;
        list.currentStatusType = defaultStatus;
    }

    // Setting empty array for no results.
    list.orders = list.orders || [];

    return {
        list
    };
};

export default filterOrderlistData;
