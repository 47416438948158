import {
    GET_PAGE_CONTENT_REQUEST,
    CLEAR_PAGE_CONTENT,
    CLEAR_ALL_PAGE_CONTENT,
    GET_SELECTED_OUTLET_REQUEST,
    GET_OUTLET_LIST_REQUEST,
    SUBMIT_SELECTED_OUTLET,
    SET_PAGE_TITLE_PREFIX,
    SET_CHAT_PLUGIN_LOADED,
    GET_STOCK_ALLOCATION,
    GET_DELIVERY_INFO
} from '../actionTypes/PageContentActionTypes';

export const getPageContent = payload => ({
    type: GET_PAGE_CONTENT_REQUEST,
    payload
});

export const clearPageContent = (pageIds) => ({
    type: CLEAR_PAGE_CONTENT,
    payload: Array.isArray(pageIds) ? pageIds : [pageIds],
});

export const clearAllPageContent = () => ({
    type: CLEAR_ALL_PAGE_CONTENT
});

export const getSelectedOutlet = payload => ({
    type: GET_SELECTED_OUTLET_REQUEST,
    payload
});

export const getOutletList = payload => ({
    type: GET_OUTLET_LIST_REQUEST,
    payload
});

export const getStockAllocation = payload => ({
    type: GET_STOCK_ALLOCATION,
    payload
});

export const submitSelectedOutlet = payload => ({
    type: SUBMIT_SELECTED_OUTLET,
    payload
});

export const setPageTitlePrefix = payload => ({
    type: SET_PAGE_TITLE_PREFIX,
    payload
});

export const setChatPluginLoaded = () => ({
    type: SET_CHAT_PLUGIN_LOADED
});

export const getDeliveryInfo = () => ({
    type: GET_DELIVERY_INFO
});
