import { CLEAR_ACCOUNT_STATEMENT_ERROR, GET_ACCOUNT_STATEMENT } from '../actionTypes/AccountStatementActionTypes';

export const fetchAccountStatement = (from, to) => ({
    type: GET_ACCOUNT_STATEMENT,
    payload: {
        from,
        to
    }
});

export const clearAccountStatementError = () => ({
    type: CLEAR_ACCOUNT_STATEMENT_ERROR
});
