import favoritesProducts from '../service/FavoritiesProductService';
import {
    GET_HOME_PAGE_DATA_REQUEST_SUCCESS,
    UPDATE_FAVOURITE_IN_HOME,
    CLEAR_FAVOURITE_IN_HOME,
    SET_HOME_SPINNER,
    CLEAR_REDUCER,
    SET_MINICART_MESSAGE_SHOWN,
} from '../actionTypes/HomeActionTypes';
import {SUBSCRIBE_TO_PRODUCT_SUCCESS} from "../actionTypes/NotifyMeActionTypes";

export default function HomeReducer(state = {}, action = {}) {
    switch (action.type) {
        case GET_HOME_PAGE_DATA_REQUEST_SUCCESS: {
            const products = favoritesProducts(action.data.products, action.data);
            const {blockAddToCartEnabled} = action.data;
            return {
                ...state,
                blockAddToCartEnabled: blockAddToCartEnabled,
                favProducts: products,
                isMoreFavorite: products.length === 25 //To identify whether Search API need to be invoked if the products are 25 or more.
            };
        }

        case UPDATE_FAVOURITE_IN_HOME: {
            const newData = { ...state };
            const { favProducts } = newData;
            newData[favProducts] = favProducts.filter(item => item.sku.code !== action.code);

            return {
                ...state,
                favProducts: newData[favProducts]
            };
        }

        case CLEAR_FAVOURITE_IN_HOME: {
            return {
                ...state,
                favProducts: undefined
            };
        }

        case SET_MINICART_MESSAGE_SHOWN: {
            return {
                ...state,
                minicartJourneyMessageShown: action.data
            };
        }

        case SET_HOME_SPINNER: {
            let spinner;

            if (action.data) {
                spinner = { ...state.spinner, ...action.data };
            }

            return {
                ...state,
                spinner
            };
        }

        case SUBSCRIBE_TO_PRODUCT_SUCCESS: {
            if (!state.favProducts) {
                return state;
            }
            const products = state.favProducts;
            const index = products.findIndex(item => item.sku.code === action.params.code);

            products[index] = {
                ...products[index],
                stockNotified: true
            };

            return {
                ...state,
                favProducts: products
            };
        }

        case CLEAR_REDUCER: {
            return {}
        }

        default:
            return state;
    }
}
