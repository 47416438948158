import {
    GET_PRODUCT_LIST_REQUEST,
    APPLY_PRODUCT_FILTER,
    REMOVE_PRODUCT_FILTER,
    TOGGLE_PRODUCT_VIEW,
    GET_BDAA_SUGGESTION,
    RESET_PRODUCT_LIST,
    CLEAR_REDUCER,
    UPDATE_PRODUCT_SORT,
    SET_BDAA_ISACTIVE,
    SET_PLP_VIEW_CHOICE
} from '../actionTypes/ProductListActionTypes';
import { SessionStorage } from '../common/Storage/Storage';

export const getProductList = payload => ({
    type: GET_PRODUCT_LIST_REQUEST,
    payload
});

export const resetProductList = payload => ({
    type: RESET_PRODUCT_LIST,
    payload
});

export const applyProductFilter = payload => ({
    type: APPLY_PRODUCT_FILTER,
    payload
});

export const removeProductFilter = payload => ({
    type: REMOVE_PRODUCT_FILTER,
    payload
});

export const toggleGridView = () => ({
    type: TOGGLE_PRODUCT_VIEW
});

export const getBdaaSuggestion = payload => ({
    type: GET_BDAA_SUGGESTION,
    payload
});

export const setBdaaIsActive = payload => ({
    type: SET_BDAA_ISACTIVE,
    payload
});

export const clearAllProducts = () => ({
    type: CLEAR_REDUCER
});

export const updateProductsSort = payload => {
    SessionStorage.setData(UPDATE_PRODUCT_SORT, payload);
    return {
        type: UPDATE_PRODUCT_SORT,
        payload
    };
};

export const setPLPViewChoiceFunc = viewchoice => ({
    type: SET_PLP_VIEW_CHOICE,
    payload: { viewchoice }
});
