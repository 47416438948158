import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';

export default class ClaimHistoryApi {
    static getClaimTicketHistory(payload) {
        const { baseInfo, params } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}/my-account/tickets`;
        config.params = params;

        return RestClient.get(config).then(json => {
            return json;
        });
    }
}
