import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Loader from '../Loader/Loader';
import Notification from '../../core/Notification/Notification';
import ScrollToTop from '../../core/ScrollToTop/ScrollToTop';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import {
    getLocalization,
    getSmartOrderMessagesHome,
    getSmartOrderMessagesPLP,
    setSmartOrderMessagesHome,
    setSmartOrderMessagesPLP
} from '../../../common/UserManager/UserManager';
import { getElement } from '../../../common/Utils/Utils';
import { LOGIN_INFO, PAGE_TYPES, MESSAGE_LEVELS } from '../../../common/Constants';
import * as styles from './Layout.css';
import MarketConsent from '../MarketConsent/MarketConsent';
import MessageBox from '../../core/MessageBox/MessageBox';
import LoyaltyNotificationCenter from '../Notification/Notification';
import MobileTabbar from '../MobileTabbar/MobileTabbar';
import { pubSubManager, PUB_SUB_COMPONENTS, PUB_SUB_EVENTS } from '../../../common/UIPubSub';
import LoginHeader from '../Header/LoginHeader';
import { CreditIssueNotification } from '../CreditLimit/Notification/CreditIssueNotification';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

class Layout extends Component {
    static defaultProps = {
        isSkipLoader: false,
        hideHeader: false,
        showDashboardHeader: false,
        showOutlet: false,
        theme: '',
        isLoginFooter: false,
        hideFooter: false,
        isNoSticky: false,
        hideNotification: false
    };

    static propTypes = {
        isSkipLoader: PropTypes.bool,
        hideHeader: PropTypes.bool,
        showDashboardHeader: PropTypes.bool,
        showOutlet: PropTypes.bool,
        theme: PropTypes.string,
        children: PropTypes.node,
        isLoginFooter: PropTypes.bool,
        hideFooter: PropTypes.bool,
        isNoSticky: PropTypes.bool,
        hideNotification: PropTypes.bool
    };

    constructor() {
        super();

        const showSmartOrderMessagesPLP = getSmartOrderMessagesPLP();
        const showSmartOrderMessagesHOME = getSmartOrderMessagesHome();

        this.state = {
            displayPLP: showSmartOrderMessagesPLP ? 'block' : 'none',
            displayHome: showSmartOrderMessagesHOME ? 'block' : 'none',
            showAlerts: false
        };
    }

    componentDidMount() {
        const localization = getLocalization();
        document.body.classList.add(localization[LOGIN_INFO.SITE_ID]);
        document.body.classList.add(localization[LOGIN_INFO.LANG]);
        !this.props.isNoSticky && window.addEventListener('scroll', this.handleScroll);
        pubSubManager.subscribe(PUB_SUB_EVENTS.TOGGLE_MAIN_SCROLL, PUB_SUB_COMPONENTS.LAYOUT, this.toggleLockScroll);
    }

    componentWillUnmount() {
        !this.props.isNoSticky && window.removeEventListener('scroll', this.handleScroll);
        pubSubManager.unsubscribe(PUB_SUB_EVENTS.TOGGLE_MAIN_SCROLL, PUB_SUB_COMPONENTS.LAYOUT);
    }

    handleScroll = () => {
        // The property pageYOffset is compatable with all browsers.
        const currentScrollTop = window.pageYOffset;
        const headerEle = getElement('#stickyHeader');
        const notificationEle = getElement('#notificationContainer');
        const headerHight = headerEle.height;
        const notificationHeight = headerHight + notificationEle.height;
        let isSticky = false;

        // Including outlet header height 32px in the calculation as well.
        if (currentScrollTop > headerEle.top + 32) {
            isSticky = true;
        }

        // Setting sticky state along with header/notification height for freezing the top header.
        this.setState({
            isSticky,
            headerHight,
            notificationHeight
        });
    };

    toggleSubmenu = val => {
        this.setState({ lockScroll: val });
    };

    toggleLockScroll = val => {
        document.body.style.overflow = val ? 'hidden' : 'scroll';
    };

    showRequestModal = val => {
        this.setState({ showRequestModal: val, showMiniCart: false });
    };

    toggleMiniCart = val => {
        this.setState({
            showMiniCart: val
        });
    };

    toggleLoyaltyNotifications = bool => {
        this.setState({ showAlerts: bool });
        document.body.style.overflowY = this.state.showAlerts ? 'hidden' : 'auto';
    };

    hideSmartOrderMessages = page => {
        if (page === PAGE_TYPES.PLP) {
            this.setState({ displayPLP: 'none' });
            setSmartOrderMessagesPLP(false);
        } else {
            this.setState({ displayHome: 'none' });
            setSmartOrderMessagesHome(false);
        }
    };

    render() {
        const {
            hideFooter,
            hideHeader,
            isLoginHeader,
            showDashboardHeader,
            showOutlet,
            theme,
            isLoginFooter,
            showMarketing,
            hideNotification
        } = this.props;
        const {
            isSticky,
            headerHight,
            notificationHeight,
            lockScroll,
            showRequestModal,
            showMiniCart,
            showAlerts
        } = this.state;

        const { bdaaMsgHome, bdaaMsgPlp, isBdaaActive } = this.props;
        return (
            <main className={cx(lockScroll && 'lockscroll')}>
                <ScrollToTop />
                <div
                    className={cx(
                        'mainContainer',
                        isSticky && 'stickyLayout',
                        showMarketing && !isLoginFooter && 'withMarketing'
                    )}
                >
                    <CreditIssueNotification mobileOnly fixed />
                    {!hideHeader && (
                        <Header
                            toggleMiniCart={this.toggleMiniCart}
                            showMiniCart={showMiniCart}
                            setShowRequestModal={this.showRequestModal}
                            showRequestSupport={showRequestModal}
                            showDashboardHeader={showDashboardHeader}
                            showOutlet={showOutlet}
                            isSticky={isSticky}
                        />
                    )}
                    {isLoginHeader && <LoginHeader isSticky={isSticky} />}
                    <div
                        id='notificationContainer'
                        className={cx('notificationMsgBox', 'stickyNotification')}
                        style={{ top: `${headerHight}px` }}
                    >
                        {!hideNotification && <Notification />}
                        {isBdaaActive && bdaaMsgHome && (
                            <div style={{ display: this.state.displayHome }}>
                                <MessageBox
                                    key={bdaaMsgHome.bdaaMsgLinkText}
                                    id={bdaaMsgHome.bdaaMsgLinkText}
                                    level={MESSAGE_LEVELS.SECTION}
                                    type='blueNotification'
                                    closeBtn
                                    onClose={() => {
                                        this.hideSmartOrderMessages(PAGE_TYPES.HOME);
                                        buttonClickEvent(
                                            buttonClickEventConfigs.home.actions.smartOrderNotification,
                                            buttonClickEventConfigs.home.labels.smartOrderNotificationExit,
                                            buttonClickEventConfigs.home.categories.home
                                        );
                                    }}
                                    isAutoClose={false}
                                >
                                    <>
                                        <b>{bdaaMsgHome.bdaaMsgBoldText} </b>
                                        <span>{bdaaMsgHome.bdaaMsgRegularText} </span>
                                        <u
                                            onClick={e => {
                                                bdaaMsgHome.scrollToSmartSection(e);
                                                buttonClickEvent(
                                                    buttonClickEventConfigs.home.actions.smartOrderNotification,
                                                    buttonClickEventConfigs.home.labels
                                                        .smartOrderNotificationSmartOrder,
                                                    buttonClickEventConfigs.home.categories.home
                                                );
                                            }}
                                        >
                                            {bdaaMsgHome.bdaaMsgLinkText}
                                        </u>
                                    </>
                                </MessageBox>
                            </div>
                        )}
                        {isBdaaActive && bdaaMsgPlp && (
                            <div style={{ display: this.state.displayPLP }}>
                                <MessageBox
                                    key={bdaaMsgPlp.bdaaMsgBoldText}
                                    id={bdaaMsgPlp.bdaaMsgBoldText}
                                    level={MESSAGE_LEVELS.SECTION}
                                    type='blueNotification'
                                    closeBtn
                                    onClose={() => {
                                        this.hideSmartOrderMessages(PAGE_TYPES.PLP);
                                    }}
                                    isAutoClose={false}
                                >
                                    <>
                                        <b>{bdaaMsgPlp.bdaaMsgBoldText} </b>
                                        <span>{bdaaMsgPlp.bdaaMsgRegularText}</span>
                                    </>
                                </MessageBox>
                            </div>
                        )}
                    </div>
                    <article
                        id='mainContainer'
                        className={cx('contentContainer', theme, 'stickyContainer')}
                        style={{ top: `${notificationHeight}px` }}
                    >
                        {this.props.children}
                    </article>
                    {!hideFooter && (
                        <div className={cx('stickyContainer')} style={{ top: `${notificationHeight}px` }}>
                            <Footer isLoginFooter={isLoginFooter} />
                        </div>
                    )}
                    {!this.props.isSkipLoader && <Loader />}
                </div>
                {!isLoginFooter && (
                    <MobileTabbar
                        showMiniCart={showMiniCart}
                        showAlerts={this.state.showAlerts}
                        setShowAlerts={val => this.toggleLoyaltyNotifications(val)}
                        toggleMiniCart={this.toggleMiniCart}
                        toggleSubmenu={this.toggleSubmenu}
                        showRequestModal={this.showRequestModal}
                    />
                )}
                {showMarketing && !isLoginFooter && <MarketConsent />}
                {showAlerts && !isLoginFooter && (
                    <LoyaltyNotificationCenter
                        mobileAlerts
                        setShowAlerts={() => this.toggleLoyaltyNotifications(false)}
                    />
                )}
            </main>
        );
    }
}

export default Layout;
