import {
    GET_ACCOUNT_INFO_REQUEST,
    RECEIVE_EMAIL_REQUEST,
    RESET_ACCOUNT_DATA,
    CHANGE_PRICES_PREF_REQUEST,
    SET_ORDER_NOTIFICATIONS,
    SET_MINI_CART_PREFERENCES
} from '../actionTypes/AccountDataActionTypes';

export const getAccountData = payload => ({
    type: GET_ACCOUNT_INFO_REQUEST,
    payload
});

export const receiveEmailAlert = payload => ({
    type: RECEIVE_EMAIL_REQUEST,
    payload
});

export const changePricesPreferences = payload => ({
    type: CHANGE_PRICES_PREF_REQUEST,
    payload
});

export const resetAccountData = () => ({
    type: RESET_ACCOUNT_DATA
});

export const setOrderNotifications = payload => ({
    type: SET_ORDER_NOTIFICATIONS,
    payload
})

export const setMiniCartPreferences = payload => ({
    type: SET_MINI_CART_PREFERENCES,
    payload
})