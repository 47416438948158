import React, { useMemo, useState } from 'react';
import Modal from '../../core/Modal/Modal';
import step1 from '../../../assets/images/loyalty-step-1.webp';
import step2 from '../../../assets/images/loyalty-step-2.webp';
import step3 from '../../../assets/images/loyalty-step-3.webp';
import * as styles from './LoyaltyPopUp.css';
import classNames from 'classnames/bind';
import Button from '../../core/Button/Button';
import Icon from '../../core/Icon/Icon';
import { redirectTo } from '../../../common/Utils/Utils';
import { URL_PATHS } from '../../../common/Constants';
import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

const LoyaltyPopUp = ({
    isLoyaltyEnabled,
    loyaltyPopupHeader1,
    loyaltyPopupHeader2,
    loyaltyPopupHeader3,
    loyaltyPopupText1,
    loyaltyPopupText2,
    loyaltyPopupText3,
    loyaltyPopupLearnMoreBtn
}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    useMemo(() => setIsPopupVisible(isLoyaltyEnabled), [isLoyaltyEnabled]);

    const handleNextButtonClick = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrevButtonClick = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleXButtonClick = () => {
        setIsPopupVisible(false);
    };

    const screensData = [
        {
            title: loyaltyPopupHeader1 || '',
            description: loyaltyPopupText1 || '',
            image: step1
        },
        {
            title: loyaltyPopupHeader2 || '',
            description: loyaltyPopupText2 || '',
            image: step2
        },
        {
            title: loyaltyPopupHeader3 || '',
            description: loyaltyPopupText3 || '',
            image: step3
        }
    ];

    return (
        <Modal
            themeModalBlock={cx('loyaltyModalBlock')}
            themeClose={cx('loyaltyCloseButton')}
            modalTheme={cx('loyaltyModalTheme')}
            showModal={isPopupVisible}
            onClose={handleXButtonClick}
            className={cx('loyaltyModalWrapper')}
        >
            <div className={cx('loyaltyPopUpModal')}>
                <h1 className={cx('loyaltyTitle')}>{screensData[currentStep].title}</h1>
                <div className={cx('loyaltyImage')}>
                    <img src={screensData[currentStep].image} alt={`step-${currentStep}-image`} />
                </div>
                <p className={cx('loyaltyText')}>{screensData[currentStep].description}</p>
                <div className={cx('loyaltyButtonGroup')}>
                    {currentStep === 1 && (
                        <Button type='linkbutton' onClick={handlePrevButtonClick} className={cx('leftArrow')}>
                            <Icon
                                iconType='svg'
                                width='35'
                                height='35'
                                viewBox='0 0 35 35'
                                name='loyalty-arrow-right'
                            />
                        </Button>
                    )}
                    {currentStep !== 2 && (
                        <Button type='linkbutton' onClick={handleNextButtonClick}>
                            <Icon
                                iconType='svg'
                                width='35'
                                height='35'
                                viewBox='0 0 35 35'
                                name='loyalty-arrow-right'
                            />
                        </Button>
                    )}
                    {currentStep === 2 && (
                        <Button
                            type='button'
                            size='Md'
                            buttonType='Primary'
                            onClick={() => redirectTo(URL_PATHS.ACCOUNT_MY_REWARDS)}
                        >
                            {loyaltyPopupLearnMoreBtn.toUpperCase()}
                        </Button>
                    )}
                </div>
                <div className={cx('loyaltyDots')}>
                    <span className={cx('dot', { active: currentStep === 0 })} />
                    <span className={cx('dot', { active: currentStep === 1 })} />
                    <span className={cx('dot', { active: currentStep === 2 })} />
                </div>
            </div>
        </Modal>
    );
};

LoyaltyPopUp.propTypes = {
    isLoyaltyEnabled: PropTypes.bool,
    loyaltyPopupHeader1: PropTypes.string,
    loyaltyPopupHeader2: PropTypes.string,
    loyaltyPopupHeader3: PropTypes.string,
    loyaltyPopupText1: PropTypes.string,
    loyaltyPopupText2: PropTypes.string,
    loyaltyPopupText3: PropTypes.string,
    loyaltyPopupLearnMoreBtn: PropTypes.string
};

export default LoyaltyPopUp;
