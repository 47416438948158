import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames/bind';
import Button from '../../core/Button/Button';
import * as styles from './DeleteTemplateModalContent.css';
import { deleteOrderTemplate } from '../../../actions/OrderTemplateAction';
import { TEMP_POPUP_L10N, PAGE_ID, GENERIC_L10N } from '../../../common/Constants';

const cx = classNames.bind(styles);

export class DeleteTemplateModalContent extends Component {
    static propTypes = {
        row: PropTypes.shape({
            name: PropTypes.string,
            code: PropTypes.string
        }),
        onCancel: PropTypes.func,
        pageNumber: PropTypes.string,
        pageSize: PropTypes.string,
        commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    };

    static defaultProps = {
        row: {},
        onCancel: () => {},
        pageNumber: '',
        pageSize: '',
        commonLocalization: {}
    };

    handleDelete = () => {
        const { row, pageNumber, pageSize } = this.props;
        const payload = {
            code: row.code
        };

        if (!isEmpty(pageNumber)) {
            payload.pageNumber = pageNumber;
        }

        if (!isEmpty(pageSize)) {
            payload.pageSize = pageSize;
        }

        this.props.actions.deleteOrderTemplate(payload);
        this.props.onCancel();
    };

    handleCancel = () => {
        this.props.onCancel();
    };

    renderCartDetails = () => {
        const { row, localization } = this.props;
        return (
            <div className={cx('padtb')}>
                <div className={cx('bdrbBottom', 'innerpad')}>
                    <div automation-id='at-cart-name' className={cx('col6')}>
                        <h4 className={cx('font-bold')}>{localization[TEMP_POPUP_L10N.CART_NAME_TITLE]}</h4>
                    </div>

                    <div automation-id='at-cart-name' className={cx('col6', 'text-right')}>
                        <h4 className={cx('greyclr')}>{row.name}</h4>
                    </div>
                </div>

                <div className={cx('padtb', 'innerpad')}>
                    <div automation-id='at-number-of-products' className={cx('col6')}>
                        <h4 className={cx('font-bold')}>{localization[TEMP_POPUP_L10N.NO_OF_PRODS]}</h4>
                    </div>

                    <div automation-id='at-number-of-products' className={cx('col6', 'text-right')}>
                        <h4 className={cx('greyclr')}>{row.totalItems}</h4>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { localization, commonLocalization } = this.props;
        return (
            <div className={cx('DelectModalContainer')}>
                <div className={cx('deleteModalTitle')}>
                    <h4 automation-id='at-delete-template-popup-title' className={cx('text-center')}>
                        {localization[TEMP_POPUP_L10N.DEL_TEMP_TITLE]}
                    </h4>
                    <p automation-id='at-delete-template-popup-text' className={cx('text-center')}>
                        {localization[TEMP_POPUP_L10N.DEL_TEMP_DESC]}{' '}
                    </p>
                </div>
                <div>{this.renderCartDetails()}</div>
                <div className={cx('float-right')}>
                    <Button
                        automation-id='at-delete-button'
                        className={cx('deleteButton')}
                        type='button'
                        size='Sm'
                        buttonType='Primary'
                        onClick={this.handleDelete}
                    >
                        {localization[TEMP_POPUP_L10N.DELETE_BTN]}
                    </Button>
                    <Button
                        automation-id='at-cancel-button'
                        className={cx('cancelButton')}
                        type='button'
                        size='Sm'
                        onClick={this.handleCancel}
                    >
                        {commonLocalization[GENERIC_L10N.CANCEL_BTN]}
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    localization: _get(state, 'pageContent.localization'),
    commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            deleteOrderTemplate
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteTemplateModalContent);
