import React from 'react';
import * as styles from './MyAccount.css';
import Layout from '../../../components/site/Layout/Layout';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import _get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import { getPageContent } from '../../../actions/PageContentAction';
import {
    ACCOUNT_TABS_RESTRICTIVE,
    MY_ACCOUNT_L10N,
    MY_ACCOUNT_ORDER_INFORMATION_TABS,
    MY_ACCOUNT_SERVICES_TABS,
    MY_ACCOUNT_SETTINGS_TABS,
    PAGE_ID,
    SHOPPING_LIST_SECTION_TABS,
    URL_PATHS
} from '../../../common/Constants';
import { isBdaaActive, mainPartnerCode, mainPartnerId } from '../../../common/UserManager/UserManager';
import { AccountCard } from './AccountCard';
import { getBdaaSuggestion } from '../../../actions/ProductListAction';
import Loader from '../../../components/site/Loader/Loader';

const cx = classNames.bind(styles);

const MyAccount = () => {
    const dispatch = useDispatch();

    const localization = useSelector(state => state.pageContent.localization);
    const breadcrumbs = useSelector(state => state.pageContent.breadcrumbs);
    const components = useSelector(state => state.pageContent.components);
    const isDesktop = useSelector(state => state.context.deviceType.isDesktop);
    const bdaaSuggestion = useSelector(state => state.products.bdaaSuggestion);
    const enableMyReports = useSelector(state => state.pageContent.enableMyReports)

    const isBdaaActiveVar = isBdaaActive();
    const mainPartnerIdVar = mainPartnerId();
    const mainPartnerCodeVar = mainPartnerCode();

    const getPageContentFunc = React.useCallback(() => {
        dispatch(getPageContent({ pageId: PAGE_ID.MY_ACCOUNT }));
    }, [dispatch, getPageContent]);

    React.useEffect(() => {
        getPageContentFunc();
    }, [getPageContentFunc]);

    const getBdaa = React.useCallback(() => {
        if (isBdaaActiveVar && mainPartnerCodeVar && mainPartnerIdVar) {
            dispatch(getBdaaSuggestion({ data: { countryCode: mainPartnerCodeVar, customerId: mainPartnerIdVar } }));
        }
    }, [dispatch, mainPartnerCodeVar, getBdaaSuggestion, mainPartnerIdVar]);

    React.useEffect(() => {
        getBdaa();
    }, [getBdaa]);

    const accountCardsData = React.useMemo(() => {
        if (!localization || !components) return [];

        const uidsComponents = components.map(c => c?.uid || '');

        let myReportsSection = {
            uid: "My-Reports-MyAccount-React",
            name: localization[MY_ACCOUNT_L10N.MY_REPORTS],
            childComponents: [
                {
                    url: URL_PATHS.MY_SALES,
                    name: localization[MY_ACCOUNT_L10N.MY_SALES],
                    uid: ACCOUNT_TABS_RESTRICTIVE.MY_SALES_TAB
                }
            ].filter(c => uidsComponents.includes(c.uid))
        }

        let orderInformationSection = {
            uid: 'Order-Information-MyAccount-React',
            name: localization[MY_ACCOUNT_L10N.ORDER_INFORMATION],
            childComponents: [
                {
                    url: URL_PATHS.ORDER_INFORMATION + '/' + MY_ACCOUNT_ORDER_INFORMATION_TABS.orderHistory,
                    name: localization[MY_ACCOUNT_L10N.ORDER_HISTORY],
                    uid: ACCOUNT_TABS_RESTRICTIVE.ORDER_HISTORY_TAB
                },
                {
                    url: URL_PATHS.ORDER_INFORMATION + '/' + MY_ACCOUNT_ORDER_INFORMATION_TABS.invoices,
                    name: localization[MY_ACCOUNT_L10N.INVOICES],
                    uid: ACCOUNT_TABS_RESTRICTIVE.INVOICES_TAB
                }
            ].filter(c => uidsComponents.includes(c.uid))
        };

        let shoppingListSectionChildComponents = [
            {
                url: URL_PATHS.ACCOUNT_SHOPPING_LIST + '/' + SHOPPING_LIST_SECTION_TABS.favorites,
                name: localization[MY_ACCOUNT_L10N.MY_FAVORITES],
                uid: ACCOUNT_TABS_RESTRICTIVE.MY_FAVOURITES_TAB
            },
            {
                url: URL_PATHS.ACCOUNT_SHOPPING_LIST + '/' + SHOPPING_LIST_SECTION_TABS.smartOrders,
                name: localization[MY_ACCOUNT_L10N.MY_RECOMMENDATIONS],
                uid: ACCOUNT_TABS_RESTRICTIVE.MY_RECOMMENDATIONS_TAB
            },
            {
                url: URL_PATHS.ACCOUNT_SHOPPING_LIST + '/' + SHOPPING_LIST_SECTION_TABS.savedCarts,
                name: localization[MY_ACCOUNT_L10N.MY_SAVED_CARTS],
                uid: ACCOUNT_TABS_RESTRICTIVE.MY_SAVED_CARTS_TAB
            },
            {
                url: URL_PATHS.ACCOUNT_SHOPPING_LIST + '/' + SHOPPING_LIST_SECTION_TABS.quickOrder,
                name: localization[MY_ACCOUNT_L10N.QUICK_ORDER],
                uid: ACCOUNT_TABS_RESTRICTIVE.QUICK_ORDER_TAB
            }
        ].filter(c => uidsComponents.includes(c.uid));

        if (!isDesktop) {
            shoppingListSectionChildComponents = shoppingListSectionChildComponents.filter(
                comp => comp.uid !== ACCOUNT_TABS_RESTRICTIVE.QUICK_ORDER_TAB
            );
        }

        if (!isBdaaActiveVar || !bdaaSuggestion || bdaaSuggestion?.length === 0) {
            shoppingListSectionChildComponents = shoppingListSectionChildComponents.filter(
                comp => comp.uid !== ACCOUNT_TABS_RESTRICTIVE.MY_RECOMMENDATIONS_TAB
            );
        }
        let shoppingListSection = {
            uid: 'Shopping-List-MyAccount-React',
            name: localization[MY_ACCOUNT_L10N.SHOPPING_LIST],
            childComponents: shoppingListSectionChildComponents
        };

        let servicesListSection = {
            uid: 'My-Services-MyAccount-React',
            name: localization[MY_ACCOUNT_L10N.MY_SERVICES],
            childComponents: [
                {
                    url: URL_PATHS.ACCOUNT_MY_SERVICES + '/' + MY_ACCOUNT_SERVICES_TABS.claimsTickets.name,
                    name: localization[MY_ACCOUNT_L10N.CLAIMS],
                    uid: ACCOUNT_TABS_RESTRICTIVE.CLAIMS_AND_TICKETS_TAB
                },
                {
                    url: URL_PATHS.ACCOUNT_MY_SERVICES + '/' + MY_ACCOUNT_SERVICES_TABS.requestSupport.name,
                    name: localization[MY_ACCOUNT_L10N.SUPPORT],
                    uid: ACCOUNT_TABS_RESTRICTIVE.REQUEST_SUPPORT_TAB
                },                
                {
                    url: URL_PATHS.ACCOUNT_MY_SERVICES_FSA,
                    name: localization[MY_ACCOUNT_L10N.TIPS],
                    uid: ACCOUNT_TABS_RESTRICTIVE.TIPS_TAB
                }
            ].filter(c => uidsComponents.includes(c.uid))
        };
        let settingListSection = {
            uid: 'Account-Settings-MyAccount-React',
            name: localization[MY_ACCOUNT_L10N.ACCOUNT_SETTINGS],
            childComponents: [
                {
                    url: URL_PATHS.ACCOUNT_SETTINGS + '/' + MY_ACCOUNT_SETTINGS_TABS.personalData.name,
                    name: localization[MY_ACCOUNT_L10N.PERSONAL_DATA_TITLE],
                    uid: ACCOUNT_TABS_RESTRICTIVE.PERSONAL_DATA_TAB
                },
                {
                    url: URL_PATHS.ACCOUNT_SETTINGS + '/' + MY_ACCOUNT_SETTINGS_TABS.updatePassword.name,
                    name: localization[MY_ACCOUNT_L10N.EDIT_PASSWORD],
                    uid: ACCOUNT_TABS_RESTRICTIVE.EDIT_PASSWORD_TAB
                },
                {
                    url: URL_PATHS.ACCOUNT_SETTINGS + '/' + MY_ACCOUNT_SETTINGS_TABS.customerFactSheet.name,
                    name: localization[MY_ACCOUNT_L10N.CUSTOMER_FACT_SHEET],
                    uid: ACCOUNT_TABS_RESTRICTIVE.CUSTOMER_FACT_SHEET_TAB
                },
                {
                    url: URL_PATHS.ACCOUNT_SETTINGS + '/' + MY_ACCOUNT_SETTINGS_TABS.companyData.name,
                    name: localization[MY_ACCOUNT_L10N.COMPANY_DATA],
                    uid: ACCOUNT_TABS_RESTRICTIVE.COMPANY_DATA_TAB
                },
                {
                    url: URL_PATHS.ACCOUNT_SETTINGS + '/' + MY_ACCOUNT_SETTINGS_TABS.userManagement.name,
                    name: localization[MY_ACCOUNT_L10N.USER_MANAGEMENTE],
                    uid: ACCOUNT_TABS_RESTRICTIVE.USER_MAINTENANCE_TAB
                }
            ].filter(c => uidsComponents.includes(c.uid))
        };
        let equipmentSection = {
            uid: 'My-Equipment-MyAccount-React',
            name: localization[MY_ACCOUNT_L10N.MY_EQUIPMENT],
            childComponents: [
                {
                    url: URL_PATHS.COOLER_DASHBOARD,
                    name: localization[MY_ACCOUNT_L10N.CONNECTED_COOLERS],
                    uid: ACCOUNT_TABS_RESTRICTIVE.COOLER_DASHBOARD_TAB
                }
            ].filter(c => uidsComponents.includes(c.uid))
        };
        let rewardsSection = {
            uid: 'My-Rewards-MyAccount-React',
            name: localization[MY_ACCOUNT_L10N.MY_REWARDS],
            childComponents: [
                {
                    url: URL_PATHS.ACCOUNT_MY_REWARDS,
                    name: localization[MY_ACCOUNT_L10N.EARN_REWARDS],
                    uid: ACCOUNT_TABS_RESTRICTIVE.MY_REWARDS_TAB
                }
            ].filter(c => uidsComponents.includes(c.uid))
        };
        if (equipmentSection.childComponents.length === 0) {
            equipmentSection = null;
        }
        if (settingListSection.childComponents.length === 0) {
            settingListSection = null;
        }
        if (servicesListSection.childComponents.length === 0) {
            servicesListSection = null;
        }
        if (shoppingListSection.childComponents.length === 0) {
            shoppingListSection = null;
        }
        if (orderInformationSection.childComponents.length === 0) {
            orderInformationSection = null;
        }
        if (rewardsSection.childComponents.length === 0) {
            rewardsSection = null;
        }
        if (myReportsSection.childComponents.length === 0) {
            myReportsSection = null;
        }

        const combinedSections = [
            orderInformationSection,
            shoppingListSection,
            rewardsSection,
            equipmentSection,
            servicesListSection,
            settingListSection,
            myReportsSection
        ];

        return combinedSections;
    }, [localization, components, isBdaaActiveVar, bdaaSuggestion]);

    return (
        <Layout showDashboardHeader showOutlet>
            <div>
                <BreadCrumbs breadcrumbValues={breadcrumbs} />
                <div style={{paddingTop: "6px"}} className={cx('commonWidget', 'clearfix', 'myAccountPage')}>
                    <h2 className={cx('pageTitle')}>
                        {localization && localization[MY_ACCOUNT_L10N.MY_ACCOUNT_TITLE]}
                    </h2>
                    {isBdaaActiveVar && bdaaSuggestion === undefined ? (
                        <div className={cx('accountSpinner')}>
                            <Loader spinner />
                        </div>
                    ) : (
                        <div className={cx('confirmwidgetInfo', 'clearfix')}>
                            <div className={cx('gridParent')}>
                                {accountCardsData
                                    .filter(ad => ad !== null && !(ad?.uid === "My-Reports-MyAccount-React" && !enableMyReports))
                                    .map(component => {
                                        return (
                                            <AccountCard
                                                key={component.uid}
                                                name={component.name}
                                                childComponents={component.childComponents}
                                                uid={component.uid}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default MyAccount;
