import {
    SET_MARKETING_CONSENT,
    SET_MARKETING_VISABILITY,
    MARKETING_ERROR,
    CLEAR_MARKETING
} from '../actionTypes/MarketingActionTypes'

const initialState = {
    visible: false,
    newsletters: false,
    offers: false,
}

export default function MarketingReducer(state = initialState, action = {}) {
    const { type, data } = action;

    switch (type) {
        case SET_MARKETING_CONSENT:
            return {
                ...state,
                ...data,
            };
        case SET_MARKETING_VISABILITY:
            return {
                ...state,
                visible: data,
            };
        case MARKETING_ERROR:
            return {
                ...state,
                errors: data
            }
        case CLEAR_MARKETING:
            return initialState
        default:
            return state;
    }
}