//checkout-badge

import React from 'react';

export default ({ color }) => {
    if (color === '#616161') {
        return (
            <svg xmlns='http://www.w3.org/2000/svg' width='19.439' height='19.439' viewBox='0 0 19.439 19.439'>
                <g id='Group_48546' data-name='Group 48546' transform='translate(-173.28 -594.281)'>
                    <path
                        id='Path_3433'
                        data-name='Path 3433'
                        d='M12.091,19.938a1.725,1.725,0,0,1-1.229-.507L3.015,11.586A1.738,1.738,0,0,1,2.5,10.347v-6.1A1.746,1.746,0,0,1,4.243,2.5h6.1A1.725,1.725,0,0,1,11.577,3l7.848,7.847a1.764,1.764,0,0,1,.514,1.237,1.7,1.7,0,0,1-.514,1.23l-6.1,6.1A1.7,1.7,0,0,1,12.091,19.938Z'
                        transform='translate(171.78 592.781)'
                        fill='none'
                        stroke={color || '#ffffff'}
                        strokeWidth='2'
                    />
                    <path
                        id='Path_3434'
                        data-name='Path 3434'
                        d='M57.173,73.025a2.183,2.183,0,0,0-3.087,0l-.635.635-.636-.636a2.182,2.182,0,0,0-3.086,3.086l.636.636h0l3.087,3.087,3.722-3.722a2.182,2.182,0,0,0,0-3.086'
                        transform='translate(130.423 530.743)'
                        fill={color || '#ffffff'}
                    />
                    <path
                        id='Path_3435'
                        data-name='Path 3435'
                        d='M20.653,19.336a1.308,1.308,0,1,1-1.308-1.308,1.308,1.308,0,0,1,1.308,1.308'
                        transform='translate(157.988 578.996)'
                        fill={color || '#ffffff'}
                    />
                </g>
            </svg>
        );
    }
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='19.439' height='19.439' viewBox='0 0 19.439 19.439'>
            <g id='Group_48546' data-name='Group 48546' transform='translate(1 1)'>
                <path
                    id='Path_3433'
                    data-name='Path 3433'
                    d='M12.091,19.938a1.725,1.725,0,0,1-1.229-.507L3.015,11.586A1.738,1.738,0,0,1,2.5,10.347v-6.1A1.746,1.746,0,0,1,4.243,2.5h6.1A1.725,1.725,0,0,1,11.577,3l7.848,7.847a1.764,1.764,0,0,1,.514,1.237,1.7,1.7,0,0,1-.514,1.23l-6.1,6.1A1.7,1.7,0,0,1,12.091,19.938Z'
                    transform='translate(-2.5 -2.5)'
                    fill='#e90000'
                    stroke='#e90000'
                    strokeWidth='2'
                />
                <path
                    id='Path_3434'
                    data-name='Path 3434'
                    d='M57.173,73.025a2.183,2.183,0,0,0-3.087,0l-.635.635-.636-.636a2.182,2.182,0,0,0-3.086,3.086l.636.636h0l3.087,3.087,3.722-3.722a2.182,2.182,0,0,0,0-3.086'
                    transform='translate(-43.857 -64.538)'
                    fill='#fff'
                />
                <path
                    id='Path_3435'
                    data-name='Path 3435'
                    d='M20.653,19.336a1.308,1.308,0,1,1-1.308-1.308,1.308,1.308,0,0,1,1.308,1.308'
                    transform='translate(-16.292 -16.285)'
                    fill='#fff'
                />
            </g>
        </svg>
    );
};
