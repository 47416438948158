/* eslint-disable import/prefer-default-export */
import _find from 'lodash/find';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import { URL_PATHS, SEARCHBOX_L10N, IMAGE_FORMAT_TYPES } from '../common/Constants';
import { formatHungaryTousends } from '../common/Utils/Utils';
import countriesWithTimeZones from '../countries_with_timeZones.json';
import { getCountryLocaleCodeIso, mainPartnerCode } from '../common/UserManager/UserManager';

export const filterProductListingData = val => {
    let data = {};
    const mainPartnerCountryCode = mainPartnerCode();
    const timeZone =
        Intl.DateTimeFormat()?.resolvedOptions()?.timeZone ||
        countriesWithTimeZones[mainPartnerCountryCode]?.TimeZones[0];
    const products =
        val &&
        val.products &&
        val.products.map(item => {
            const image = _find(item.images, {
                format: IMAGE_FORMAT_TYPES.PRODUCT,
                imageType: SEARCHBOX_L10N.PRIMARY
            }) || { url: '' };

            const startDate = moment.tz(item.promotion?.startDate, timeZone);
            const endDate = moment.tz(item.promotion?.endDate, timeZone);
            return {
                baseUnit: item.baseUnit,
                name: item.name,
                imageUrl: image.url,
                url: item.url,
                code: item.code,
                sku: {
                    id: item.codeShort
                },
                pricePerPiece: formatHungaryTousends(
                    item.pricePerPiece && item.pricePerPiece.formattedValue ? item.pricePerPiece.formattedValue : ''
                ),
                listPrice: formatHungaryTousends(
                    item.price && item.price.formattedValue ? item.price.formattedValue : ''
                ),
                promoPrice: formatHungaryTousends(
                    item.price && item.price.value ? `${item.price.value} ${item.price.currencyIso}` : ''
                ),
                currency: item.price && item.price.currencyIso ? item.price.currencyIso : '',
                units: (item.availableUnits || []).map(val => ({
                    id: val.id,
                    label: val ? val.description.toUpperCase() : val.description,
                    value: val.id
                })),
                conversionFactors: item.conversionFactors,
                stockFlag: item.stockFlag,
                stockNotified: item.stockNotified,
                productUrl: `${URL_PATHS.PDP}/${item.code}`,
                isFav: item.favourite,
                depositProduct: item.depositProduct,
                depositProductLogoUrl: item.depositProductLogoUrl,
                isPromoProduct: !!item.promotion,
                promotion: item.promotion
                    ? {
                          description: item.promotion.description,
                          startDate,
                          endDate,
                          rawStartDate: item.promotion.startDate,
                          rawEndDate: item.promotion.endDate,
                          formattedEndDate: item.promotion.formattedEndDate,
                          formattedStartDate: item.promotion.formattedStartDate,
                          note: item.promotion.note,
                          conditionalGroup: _get(item, 'promotion.promotionMechanic.conditionalGroups', []),
                          freeGroup: _get(item, 'promotion.promotionMechanic.freeGroup', []),
                          listOfLimitedProductCodes: item?.promotion?.listOfLimitedProductCodes || []
                      }
                    : [],
                shippingInfo: '',
                description: item.description ? item.description : '',
                waterCategory: val.trackAndTraceEnabled ? item.waterCategory : false,
                quantity: item?.quantity ? item.quantity : null,
                reason: item?.reason ? item.reason : '',
                price: item?.price && item.price
            };
        });
    if (products === undefined) {
        data.products = [];
    } else {
        data.products = products;
    }
    const filterData =
        val.facets &&
        val.facets.map(item => ({
            type: item.name,
            categoryCode: item.code,
            items: item.values.map(subItem => ({
                name: subItem.name,
                code: subItem.code,
                totalItem: subItem.count,
                imageUrl: '',
                selected: subItem.selected,
                count: subItem.count
            }))
        }));
    data.filters = filterData;
    data.appliedFilters =
        val.breadcrumbs &&
        val.breadcrumbs.map(item => ({
            facetCode: item.facetCode,
            facetName: item.facetName,
            facetValueName: item.facetValueName,
            facetValueCode: item.facetValueCode
        }));
    data.breadcrumbs =
        val.breadCrumbValues &&
        val.breadCrumbValues.map(item => ({
            name: item.name !== '' ? item.name.toUpperCase() : '',
            url: item.url
        }));
    data.priceFilter = val.priceFilter;
    data.viewChoice = val.viewChoice;
    data.customerHasSavedFilter = val.customerHasSavedFilter;
    data.isSavedFilterDefault = val.isSavedFilterDefault;
    data.savedFilters = val.savedFilters;
    const pageContent = {
        totalItems: val.pagination?.totalResults,
        totalPages: val.pagination?.totalPages,
        currentPage: val.pagination?.currentPage,
        pageSize: val.pagination?.pageSize,
        freeTextSearch: val.freeTextSearch ? val.freeTextSearch : '',
        isShowProdPrice: val.showProdPrice ? val.showProdPrice : false,
        returnEmpties: val.returnEmpties,
        marketingMaterials: val.marketingMaterials,
        itemsPerPage: val.pagination?.itemsPerPage,
        showShortListView: !!val.showShortListView
    };
    data = { ...data, ...pageContent };
    return data;
};
