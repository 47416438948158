import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import { Link } from 'react-router';
import parse from 'html-react-parser';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _get from 'lodash/get';
import Datepicker from '../../core/Datepicker/Datepicker';
import SlidePanel from '../../core/SlidePanel/SlidePanel';
import Tabs from '../../core/Tabs/Tabs';
import TabPanel from '../../core/Tabs/TabPanel';
import CartItemDetails from '../CartItemDetails/CartItemDetails';
import Button from '../../core/Button/Button';
import DeliveryType from '../DeliveryType/DeliveryType';
import WholesalerReports from '../WholesalerReports/WholesalerReports';
import { getPageContent } from '../../../actions/PageContentAction';
import { getMultiCart, clearAll, updateDeliveryDate, openMiniCart } from '../../../actions/CartAction';
import { SessionStorage } from '../../../common/Storage/Storage';
import { convertBGNToEUR, formatHungaryTousends, getSysDate, redirectTo, trimString } from '../../../common/Utils/Utils';
import {
    isIndirectUser,
    getActiveCartId,
    isReturnEmpties,
    isOrderSplitIndirect,
    isUseMarketingMaterials,
    getDeliveryDatePreference,
    setDeliveryDatePreference,
    clearBdaaRecommendedCheckboxInfo,
    getInitialDeliveryDate,
    getSiteId
} from '../../../common/UserManager/UserManager';
import {
    PAGE_ID,
    URL_PATHS,
    CART_L10N,
    DELIVERY_TYPES,
    DATA_LAYER_LISTS,
    GENERIC_L10N
} from '../../../common/Constants';
import ClearAllCartsModal from '../common/ClearAllCartsModal/ClearAllCartsModal';
import * as styles from './MiniCart.css';
import { buttonClickEventConfigs, buttonClickEvent } from '../../../common/GoogleTagManager/GoogleTagManager';
import Icon from '../../core/Icon/Icon';

const cx = classNames.bind(styles);

export const MiniCart = props => {
    const {
        isCartPage,
        localization,
        cartDetails = {},
        deliveryType,
        commonLocalization,
        stockAllocation,
        isPickUpAllowed,
        isZADOrderType
    } = props;
    const isIndirectCustomer = isIndirectUser();
    const isReturnEmpty = isReturnEmpties();
    const isMarketingMaterials = isUseMarketingMaterials();
    const activeCartId = getActiveCartId();
    const orderSplitIndirect = isOrderSplitIndirect();
    const siteId = getSiteId();
    const isCartEmpty = !activeCartId || cartDetails.totalCartCount === 0;
    const lsDeliveryDatePreference = getDeliveryDatePreference();
    const initialDeliveryDate = getInitialDeliveryDate();
    const [isClearAllCartsModal, setClearAllCartsModal] = useState(false);
    const [showCartSlider, setShowCartSlider] = useState(true);
    const [showDatePicker, setshowDatePicker] = useState(false);
    const [activeCart, setActiveCart] = useState(0);
    const [deliveryPreference, setDeliveryPreference] = useState('');
    const [logisticExists, setLogisticExists] = useState(false);
    const activeCartDetails = _get(cartDetails, `cartList[${activeCart}].cartDetails`, {});
    const emptyDeliveryMenu = !isIndirectCustomer && !isPickUpAllowed && !isReturnEmpty && !isMarketingMaterials;
    const showGrossPrice = cartDetails[CART_L10N.SHOW_GROSS_PRICE];

    let changeDateLink = '';
    let calendarTitle = '';
    const calendarMessage = isIndirectCustomer
        ? localization[CART_L10N.WHOLESALER_DATE_MSG]
        : localization[CART_L10N.DATE_PICK_MSG];

    if (!_isEmpty(localization)) {
        if (deliveryType === DELIVERY_TYPES.ORDER_DRINKS && deliveryPreference) {
            calendarTitle =
                deliveryPreference === 'standard'
                    ? commonLocalization[GENERIC_L10N.DELIVERY_DATES.STANDARD_DELIVERY_DATE]
                    : commonLocalization[GENERIC_L10N.DELIVERY_DATES.NEXT_DAY_DELIVERY_DATE];
            changeDateLink = `${localization[CART_L10N.CHANGE_TXT]} ${localization[CART_L10N.DELIVERY_DATE]}`;
        } else if (isIndirectCustomer || !deliveryPreference) {
            calendarTitle = localization[CART_L10N.DELIVERY_DATE];
            changeDateLink = `${localization[CART_L10N.CHANGE_TXT]} ${calendarTitle}`;
        } else {
            calendarTitle = localization[CART_L10N.PICKUP_DATE];
            changeDateLink = `${localization[CART_L10N.CHANGE_TXT]} ${calendarTitle}`;
        }
    }

    const tabRef = [];

    useEffect(() => {
        if (!isCartEmpty && cartDetails.cartList && activeCart > cartDetails.cartList.length - 1)
            tabRef[`cartTab-0`].current.props.onSelect(0);
    }, [cartDetails.totalCartCount]);

    useEffect(() => {
        if (_isEmpty(localization)) {
            props.actions.getPageContent({ pageId: PAGE_ID.MINI_CART });
        } else if (!isCartPage) {
            //props.actions.getMultiCart({ isOpenMiniCartFlow: true });
            props.actions.openMiniCart({ isOpenMiniCartFlow: true });
        }
    }, []);

    useEffect(() => {
        if (cartDetails && cartDetails.cartList) {
            const logistic = cartDetails.cartList[activeCart]?.cchPricingDataList || [];
            const logisticValue = logistic.some(
                pricingData => pricingData.pricingConditions && pricingData.pricingConditions.length > 0
            );
            setLogisticExists(logisticValue);
        }
    }, [cartDetails, activeCart]);

    // Check if the cart has nextDeliveryDate. If so, show 'standard delivery' as default, but only if there's no value in local storage already. If there is, we take that value. If both are false, we show 'Delivery Date' as default
    useEffect(() => {
        if (
            cartDetails &&
            cartDetails?.nextDeliveryDate &&
            initialDeliveryDate !== cartDetails?.nextDeliveryDate &&
            !deliveryPreference
        ) {
            if (lsDeliveryDatePreference) {
                setDeliveryPreference(lsDeliveryDatePreference);
            } else {
                setDeliveryPreference('standard');
                setDeliveryDatePreference('standard');
            }
        }
    }, [cartDetails]);

    const continueToCart = () => {
        SessionStorage.setData(CART_L10N.IS_SKIP_GET_CART, true, true);
        redirectTo(URL_PATHS.CART);
        try {
            buttonClickEvent(
                buttonClickEventConfigs.minicart.actions.continueToCart,
                buttonClickEventConfigs.minicart.labels.continueToCart,
                buttonClickEventConfigs.minicart.categories.minicart
            );
        } catch (ex) {
            console.error(ex);
        }
    };

    const onCancelEvent = () => {
        setShowCartSlider(false);
        props.onClose(false);
    };

    const onClearAllCarts = () => {
        setClearAllCartsModal(true);
        setShowCartSlider(false);
    };

    const onClearAll = () => {
        props.actions.clearAll({
            data: {
                list: DATA_LAYER_LISTS.MINI_CART_LIST,
                origin_element: buttonClickEventConfigs.minicart.labels.clearAllCarts
            }
        });
        setClearAllCartsModal(false);
        if (cartDetails?.nextDayDeliveryDate && (deliveryPreference === 'nextDay' || deliveryPreference === 'standard')) {
            setDeliveryPreference('standard');
        } else {
            setDeliveryPreference('');
        }
        clearBdaaRecommendedCheckboxInfo();
        props.onClose(false);
    };

    const onCloseClearAll = () => {
        setClearAllCartsModal(false);
        props.onClose(false);
    };

    const onDatePicker = () => {
        try {
            if (!showDatePicker) {
                buttonClickEvent(
                    buttonClickEventConfigs.minicart.actions.changeDeliveryDate,
                    buttonClickEventConfigs.minicart.labels.changeDeliveryDate,
                    buttonClickEventConfigs.minicart.categories.minicart
                );
            }
        } catch (ex) {
            console.error(ex);
        }

        setshowDatePicker(true);
    };

    const onCloseClick = () => {
        setshowDatePicker(false);
    };

    const onDateChange = selectedDate => {
        try {
            props.actions.updateDeliveryDate({
                data: {
                    deliveryDate: `${selectedDate.getDate()}/${
                        selectedDate.getMonth() + 1
                    }/${selectedDate.getFullYear()}`,
                    basketId: _get(cartDetails, `cartList[${activeCart}].cartName`, '')
                }
            });
        } finally {
            setshowDatePicker(false);
        }
    };

    const getDeliveryInfo = () => {
        const deliveryDate = activeCartDetails.updatedDeliveryDate || activeCartDetails.deliveryDate || '';
        const cutOffDate = activeCartDetails.updatedCutoffDateTime || activeCartDetails.defaultCutOffDateTime || '';
        const nextDayDeliveryDate = cartDetails?.nextDeliveryDate;
        const newCutOffDate = cartDetails?.newCutOffDate;
        const isShowIndirectDate = activeCartDetails.isIndirectZorSplit;
        let deliveryInfo = '';

        if (isIndirectCustomer && calendarTitle && deliveryDate && !isShowIndirectDate) {
            if (
                ((isZADOrderType && (cartDetails.deliverDateInfo || '').indexOf(cutOffDate) !== -1) ||
                    isMarketingMaterials) &&
                cartDetails.showCutOffDate &&
                cutOffDate
            ) {
                deliveryInfo = `${calendarTitle} ${deliveryDate}, ${
                    localization[CART_L10N.ORDER_BEFORE_TXT]
                } ${cutOffDate}`;
            } else {
                deliveryInfo = `${calendarTitle} ${deliveryDate}, ${calendarMessage}`;
            }
        } else if (calendarTitle && deliveryDate && deliveryPreference === 'standard') {
            deliveryInfo = `${calendarTitle} ${deliveryDate}`;

            if (cartDetails.showCutOffDate && cutOffDate) {
                deliveryInfo = `${deliveryInfo}, ${localization[CART_L10N.ORDER_BEFORE_TXT]} ${cutOffDate}`;
            }
        } else if (calendarTitle && nextDayDeliveryDate && deliveryPreference === 'nextDay') {
            deliveryInfo = `${calendarTitle} ${nextDayDeliveryDate}`;

            if (cartDetails.showCutOffDate && newCutOffDate) {
                deliveryInfo = `${deliveryInfo}, ${localization[CART_L10N.ORDER_BEFORE_TXT]} ${newCutOffDate}`;
            }
        } else {
            deliveryInfo = `${calendarTitle} ${deliveryDate}`;

            if (cartDetails.showCutOffDate && cutOffDate) {
                deliveryInfo = `${deliveryInfo}, ${localization[CART_L10N.ORDER_BEFORE_TXT]} ${cutOffDate}`;
            }
        }

        return deliveryInfo;
    };

    const getStartDate = () => {
        //const defaultDate = activeCartDetails.deliveryDate;
        const availableDates = _get(props.cartDetails, 'availableDeliveryDates', '').split(','); //HYB-462 fix
        if (cartDetails?.nextDeliveryDate) {
            availableDates.push(cartDetails.nextDeliveryDate);
            const newDates = availableDates
                .map(dateString => ({ dateString, date: getSysDate(dateString) }))
                .sort((a, b) => a.date - b.date);
            const defaultDate = newDates[0].dateString;
            return getSysDate(defaultDate);
        }
        const defaultDate = !_isEmpty(availableDates) ? availableDates[0] : ''; //HYB-462 fix
        return getSysDate(defaultDate);
    };

    const getSelectedDate = () => {
        const selectedDate = activeCartDetails.updatedDeliveryDate || activeCartDetails.deliveryDate;

        return getSysDate(selectedDate);
    };

    const enableDates = () => {
        const availableDates = _get(props.cartDetails, 'availableDeliveryDates', '').split(',');
        if (cartDetails?.nextDeliveryDate) {
            availableDates.push(cartDetails.nextDeliveryDate);
        }
        return availableDates.map(getSysDate);
    };

    const renderDeliveryMenu = () => {
        return (
            isPickUpAllowed &&
            !isReturnEmpty &&
            !isMarketingMaterials &&
            !_isEmpty(localization) && <DeliveryType localization={localization} />
        );
    };

    const renderWholeSalerMenu = () => {
        return !isZADOrderType && !isMarketingMaterials && <WholesalerReports />;
    };

    const returnToShop = () => {
        setShowCartSlider(false);
        props.onClose(false);
        redirectTo(URL_PATHS.PLP_ORDER);
        buttonClickEvent(
            buttonClickEventConfigs.minicart.actions.returnToShopping,
            buttonClickEventConfigs.minicart.labels.returnToShopping,
            buttonClickEventConfigs.minicart.categories.minicart
        );
    };

    const renderPricingTotal = () => {
        return (cartDetails.cartList || []).map(cartInfo => {
            const pricingDetails =
                _find(cartInfo.cchPricingDataList, pricingData => !_isEmpty(pricingData.pricingConditions)) || {};

            const { pricingConditions } = pricingDetails;

            return (
                pricingConditions &&
                pricingConditions.length > 0 &&
                pricingConditions
                    .filter(pc => pc?.key.includes('LF_'))
                    .map(pc => {
                        return (
                            <div className={cx('pricing')} automation-id='at-list-of-pricing'>
                                <div className={cx('col12', 'md12', 'sm12', 'pad0', 'pricingDetails')} key={pc?.key}>
                                    <h3 className={cx('col6', 'totTax', 'pad0')}>{trimString(pc?.key)}</h3>
                                    <span className={cx('prodAmout', 'col6', 'pad0')}>
                                        {formatHungaryTousends(pc?.value.formattedValue)}
                                    </span>
                                </div>
                            </div>
                        );
                    })
            );
        });
    };

    return (
        <div automation-id='at-mini-cart' className={cx('row', 'headerCont', 'miniCartSlider')}>
            <SlidePanel
                isOpenPanel={showCartSlider}
                panelIcon='close-gray'
                onClosePanel={onCancelEvent}
                slidePanelHeaderTheme={cx('slideHeaderTheme')}
                themePanelContent={cx('sliderContent')}
                automationId='at-cart-Panel'
            >
                <div className={cx('popupSliderContainer')}>
                    <div className={cx('sliderWrapper', 'sliderBG')}>
                        <div className={cx('popupTitle', emptyDeliveryMenu && 'cartTitle')}>
                            <div className={cx('col7', 'sm12', 'cartWrapper')}>
                                <h3>{localization[CART_L10N.MINI_CART_TITLE]}</h3>
                            </div>
                            <div className={cx('col5', 'sm12', 'deliveryOptionWrapper')}>
                                {isIndirectCustomer ? renderWholeSalerMenu() : renderDeliveryMenu()}
                            </div>
                        </div>
                        {!isCartEmpty ? (
                            <div className={cx('QuickViewContent')}>
                                {' '}
                                {/* TODO Cart is EMPTY Display None */}
                                <div className={cx('cartBtn')}>
                                    <Button
                                        className={cx('continueCart')}
                                        type='button'
                                        buttonType='Primary'
                                        onClick={continueToCart}
                                    >
                                        {localization[CART_L10N.CONT_TO_CART]}
                                    </Button>
                                </div>
                                <div className={cx('bdrBtmCart')} />
                                <Tabs
                                    activeTabPanelIndex={activeCart}
                                    themeTabContainerClass={cx('themeTabContainerClass')}
                                    customTabPanelContentClass={cx('contentWrapper')}
                                    onTabChange={setActiveCart}
                                    className={cx('display-none')}
                                >
                                    {(cartDetails.cartList || []).map((cartInfo, index) => {
                                        if (cartInfo.cartItemCount > 0) {
                                            const ref = `cartTab-${index}`;
                                            if (!tabRef[ref]) {
                                                tabRef[ref] = React.createRef();
                                            }
                                            return (
                                                <TabPanel
                                                    key={cartInfo.cartId}
                                                    ref={tabRef[ref]}
                                                    title={
                                                        (orderSplitIndirect || !isIndirectCustomer) &&
                                                        !isReturnEmpty &&
                                                        cartInfo.cartTranslatedName &&
                                                        `${cartInfo.cartTranslatedName} (${cartInfo.cartItemCount})`
                                                    }
                                                    index={index}
                                                    customTabButtonClass={cx('customTabButtonClass')}
                                                    customContainerClass={cx('customContainerClass')}
                                                >
                                                    <p>
                                                        <CartItemDetails
                                                            customAddCartDetailsWrapper={cx('cartItemDetailsWrapper')}
                                                            isFrom={DATA_LAYER_LISTS.MINI_CART_LIST}
                                                            customCartLevels={cx('customCartLevels')}
                                                            cartInfo={cartInfo.cartDetails}
                                                            localization={localization}
                                                            commonLocalization={commonLocalization}
                                                            stockAllocation={stockAllocation}
                                                            hideATP={
                                                                isIndirectCustomer &&
                                                                orderSplitIndirect &&
                                                                !isMarketingMaterials &&
                                                                !activeCartDetails.isZAvailabilityActive
                                                                    ? !activeCartDetails.isIndirectZorSplit
                                                                    : cartDetails.hideATP
                                                            }
                                                            showGrossPrice={cartDetails?.showGrossPrice}
                                                        />
                                                    </p>
                                                </TabPanel>
                                            );
                                        }
                                        return null;
                                    })}
                                </Tabs>
                                <div
                                    className={cx(
                                        'taxFooterBtm',
                                        isCartPage && 'mobileCartSummary',
                                        props.deviceType.isDevice && 'deviceFooterBtm',
                                        logisticExists && 'setDetails'
                                    )}
                                >
                                    <div className={cx('priceBox')}>
                                        {activeCartDetails.subTotal && (
                                            <div className={cx('flexRow')}>
                                                <div className={cx('col5', 'sm5', 'pad0')}>
                                                    <h5 className={cx('totTax')}>
                                                        {showGrossPrice
                                                            ? localization[CART_L10N.TOTAL_WITH_TAX]
                                                            : localization[CART_L10N.TOTAL_WITHOUT_TAX]}
                                                    </h5>
                                                </div>
                                                    
                                                <div className={cx('col7', 'sm7', 'pad0', siteId === 'bg'
                                                 ? 'bulgarianCurrencyCol' : '')}>
                                                    <h5 className={cx('prodAmt')}>
                                                        {formatHungaryTousends(
                                                            showGrossPrice
                                                                ? _get(
                                                                      activeCartDetails,
                                                                      `totalPriceWithTax.formattedValue`,
                                                                      ''
                                                                  )
                                                                : _get(activeCartDetails, `subTotal.formattedValue`, '')
                                                        )}
                                                    </h5>
                                                    {siteId === 'bg' && (
                                                    <p>
                                                        (€ {convertBGNToEUR(showGrossPrice 
                                                        ? _get(activeCartDetails,`totalPriceWithTax.value`,'')
                                                        : _get(activeCartDetails, `subTotal.value`, ''))
                                                        .split('€')[0].trim()})
                                                    </p>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {renderPricingTotal()}
                                        <div className={cx('delivery')}>
                                            <span className={cx('deliveryDetail')}>
                                                {deliveryPreference === 'nextDay' && !isIndirectCustomer && (
                                                    <Icon
                                                        className={cx('flex-icon', 'icon-flash')}
                                                        iconType='svg'
                                                        width='16px'
                                                        height='16px'
                                                        viewBox='-3 0 16 16'
                                                        name='flash'
                                                    />
                                                )}
                                                {parse(getDeliveryInfo())}
                                            </span>
                                            {cartDetails.showRddCalender && (
                                                <>
                                                    <Link className={cx('changeDate')} onClick={onDatePicker}>
                                                        {changeDateLink}
                                                    </Link>
                                                    {showDatePicker && (
                                                        <div className={cx('datePickerInline')}>
                                                            <Datepicker
                                                                includeDates={enableDates()}
                                                                calendarTitle={
                                                                    deliveryType === DELIVERY_TYPES.ORDER_DRINKS ||
                                                                    isIndirectCustomer
                                                                        ? localization[CART_L10N.DELIVERY_DATE]
                                                                        : localization[CART_L10N.PICKUP_DATE]
                                                                }
                                                                calendarNote={localization[CART_L10N.PLZ_NOTE_MSG]}
                                                                calendarMessage={calendarMessage}
                                                                inline
                                                                footerText
                                                                minDate={getStartDate()}
                                                                selectedDate={getSelectedDate()}
                                                                dateFormat='DD/MM/YYYY'
                                                                onCloseClick={onCloseClick}
                                                                onChange={onDateChange}
                                                                popoverAttachment='top right'
                                                                popoverTargetAttachment='top right'
                                                                deliveryPreference={deliveryPreference}
                                                                setDeliveryPreference={setDeliveryPreference}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <div className={cx('checkoutbdrBtmShort')}>
                                        <Button
                                            className={cx('checkoutcontinueCart')}
                                            type='button'
                                            buttonType='Primary'
                                            onClick={continueToCart}
                                        >
                                            {localization[CART_L10N.CONT_TO_CART]}
                                        </Button>
                                    </div>

                                    <div className={cx('clearCart')}>
                                        <Button className={cx('clearcartBtn')} type='button' onClick={onClearAllCarts}>
                                            {localization[CART_L10N.CLEAR_ALL_CART]}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                {cartDetails.isClearCart ? (
                                    <p className={cx('emptyDeliveryCart')}>
                                        {localization[CART_L10N.DELIVERY_TYPE_ERROR]}
                                    </p>
                                ) : (
                                    <p className={cx('emptyCart')}>{localization[CART_L10N.CART_EMPTY_ERROR]}</p>
                                )}
                                <div className={cx('returnWrapper')}>
                                    <Button
                                        automation-id='at-return-shopping'
                                        className={cx('returnBtn')}
                                        type='button'
                                        size='Sm'
                                        buttonType='Primary'
                                        onClick={returnToShop}
                                    >
                                        {localization[CART_L10N.RETURN_TO_SHOP]}
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </SlidePanel>

            {isClearAllCartsModal && (
                <ClearAllCartsModal
                    showModal={isClearAllCartsModal}
                    onOk={onClearAll}
                    onCancel={onCloseClearAll}
                    localization={localization}
                    isFromMiniCart
                />
            )}
        </div>
    );
};

MiniCart.propTypes = {
    deviceType: PropTypes.objectOf(PropTypes.bool),
    isCartPage: PropTypes.bool,
    cartDetails: PropTypes.objectOf(PropTypes.any),
    localization: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    deliveryType: PropTypes.string,
    isPickUpAllowed: PropTypes.bool,
    isZADOrderType: PropTypes.bool,
    commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

MiniCart.defaultProps = {
    deviceType: {},
    isCartPage: false,
    cartDetails: {},
    localization: {},
    deliveryType: '',
    isPickUpAllowed: false,
    isZADOrderType: false,
    commonLocalization: {}
};

const mapStateToProps = state => {
    const isCartPage = window.location.pathname === URL_PATHS.CART;
    const localization = isCartPage
        ? _get(state, 'pageContent.localization')
        : _get(state, `pageContent.commonLocalization[${PAGE_ID.MINI_CART}]`);

    return {
        deviceType: _get(state, 'context.deviceType'),
        isCartPage,
        cartDetails: _get(state, 'cartDetails.cart'),
        localization,
        deliveryType: _get(state, 'cartDetails.deliveryType'),
        isPickUpAllowed: _get(state, 'loginPage.pickUpAllowed'),
        isZADOrderType: _get(state, 'loginPage.isZADOrderType'),
        stockAllocation: _get(state, `pageContent.stockAllocation`),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getPageContent,
            getMultiCart,
            clearAll,
            updateDeliveryDate,
            openMiniCart
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(MiniCart);
