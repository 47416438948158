import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import Button from '../../core/Button/Button';
import Image from '../../core/Image/Image';
import QtyInput from '../../core/QtyInput/QtyInput';
import Icon from '../../core/Icon/Icon';
import * as styles from './TemplateDetailsOrderItem.css';
import { DATA_LAYER_LISTS, IMAGE_COMING_SOON } from '../../../common/Constants';
import { goToPDP } from '../../../common/Utils/Utils';
import { addToCartEventGA4, productClickEventGA4 } from '../../../common/GoogleTagManager/GoogleTagManager';
import { getGTMAddToCartProductGA4, productClickOrderTemplatDataLayerFilterGA4 } from '../../../service/DataLayerFilter';

const cx = classNames.bind(styles);

const TemplateDetailsOrderItem = props => {
    const {
        arrIndex,
        itemData,
        templateId,
        onRemoveItem,
        onChangeQtyInput,
        onUpdateQty,
        onAddToCart,
        localization,
        isEditMode,
        onOrderSequenceChange
    } = props;
    const removeOrderItem = e => {
        e.preventDefault();
        const reqData = {
            templateId,
            item: itemData
        };
        onRemoveItem(reqData);
    };

    const changeItemQty = value => {
        const reqData = {
            id: itemData.id,
            qty: value
        };
        onChangeQtyInput(reqData);
    };

    const orderSequence = (orderType, arrIndex) => {
        onOrderSequenceChange(orderType, arrIndex, itemData);
    };

    const updateItemQty = () => {
        const reqData = {
            templateId,
            item: itemData
        };
        onUpdateQty(reqData);
    };

    const addToCartItem = () => {
        const reqData = {
            templateId,
            item: itemData
        };

        onAddToCart(reqData);

        try {
            addToCartEventGA4([getGTMAddToCartProductGA4(0, itemData.name, itemData.id.replace(/\b0+/g, ''), itemData.unitCode, itemData.displayQty, itemData.price || 0, DATA_LAYER_LISTS.TEMPLATE_LIST, itemData.currency, itemData.brand, itemData.category)]);
        } catch (ex) {
            console.error(ex);
        }
    };

    const handleProductClickEvent = () => {
        try {
            productClickEventGA4(productClickOrderTemplatDataLayerFilterGA4(_cloneDeep(itemData)), DATA_LAYER_LISTS.TEMPLATE_LIST);
        } catch (ex) {
            console.error(ex);
        }
    };

    const goToProductPage = () => {
        handleProductClickEvent();
        goToPDP(itemData.link);
    };

    const renderUpdateQtyRemoveButton = () => {
        const addCartIcon = (
            <span className={cx('addtoCardIcon')}>
                <Icon className={cx('addIcon')} width='17px' height='16px' viewBox='0 0 17 16' name='addCart-red' />
            </span>
        );

        const deleteIcon = (
            <span className={cx('iconTheme', 'hiddenSm')}>
                <Icon
                    className={cx('deleteIcon')}
                    iconType='svg'
                    width='24px'
                    height='25px'
                    viewBox='0 0 23 23'
                    name='delete-product'
                />
            </span>
        );

        return (
            <div className={cx('link-btn', isEditMode ? 'col3' : 'col3', 'sm12')}>
                {!isEditMode && (
                    <Button
                        automationId='at-add-button'
                        type='button'
                        buttonType='Ordinary'
                        size='Sm'
                        onClick={() => addToCartItem()}
                        className={cx('addtoCartBtn')}
                    >
                        {localization.addBtn}
                        {addCartIcon}
                    </Button>
                )}
                {isEditMode && (
                    <Button
                        automationId='at-remove-button'
                        className={cx('remove')}
                        type='button'
                        buttonType='Ordinary'
                        size='Sm'
                        onClick={e => removeOrderItem(e)}
                    >
                        <span className={cx('visibleSm')}>{localization.deleteBtn}</span>
                        {deleteIcon}
                    </Button>
                )}
            </div>
        );
    };

    return (
        <li className={`${cx('itemRow')}`}>
            <div className={cx('col1', 'sm12', 'thunbimg')}>
                <div className={cx('productImage', 'pointer')} role='presentation' onClick={goToProductPage}>
                    {_isEmpty(itemData.imageUrl) ? (
                        <img src={IMAGE_COMING_SOON} alt='product coming soon' />
                    ) : (
                        <Image
                            automationId='at-item-image'
                            key={itemData.imageUrl}
                            src={itemData.imageUrl}
                            alt={itemData.altText}
                        />
                    )}
                </div>
            </div>
            <div className={cx('col4', 'sm12')}>
                <h4
                    automation-id='at-item-name'
                    className={cx('pointer')}
                    role='presentation'
                    onClick={goToProductPage}
                >
                    {itemData.name}
                </h4>
                <p automation-id='at-item-description' className={`${cx('itemDescription')}`}>
                    {itemData.description}
                </p>
            </div>
            {isEditMode && (
                <div className={cx('col1', 'sm12', 'hiddenSm', 'arrowContent')}>
                    <div className={cx('arrowTopBtn')}>
                        <Button
                            type='linkbutton'
                            className={cx('textBrand')}
                            onClick={() => orderSequence('up', arrIndex, itemData)}
                        >
                            <i className={cx('arrow', 'up')} />
                        </Button>
                    </div>
                    <div className={cx('arrowBottomBtn')}>
                        <Button
                            type='linkbutton'
                            className={cx('textBrand')}
                            onClick={() => orderSequence('down', arrIndex, itemData)}
                        >
                            <i className={cx('arrow', 'down')} />
                        </Button>
                    </div>
                </div>
            )}
            <div className={cx('col2', 'sm12')}>
                <p>
                    {itemData.displayQty} {itemData.unitCode}
                </p>
            </div>
            <div className={cx('col2', 'sm12')}>
                <div className={`${cx('qtyinput')}`}>
                    <QtyInput
                        automationId='at-item-qty'
                        id={`qty-${itemData.id}`}
                        value={itemData.qty}
                        minValue={0}
                        onChange={changeItemQty}
                        isQtyError={itemData.isZeroQty === true}
                    />
                </div>
            </div>
            {renderUpdateQtyRemoveButton()}
        </li>
    );
};

TemplateDetailsOrderItem.propTypes = {
    itemData: PropTypes.objectOf(PropTypes.any),
    templateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onRemoveItem: PropTypes.func,
    onChangeQtyInput: PropTypes.func,
    onUpdateQty: PropTypes.func,
    localization: PropTypes.objectOf(PropTypes.any)
};

TemplateDetailsOrderItem.defaultProps = {
    itemData: null,
    templateId: null,
    onRemoveItem: null,
    onChangeQtyInput: null,
    onUpdateQty: null,
    localization: null
};

export default TemplateDetailsOrderItem;
