import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import SlidePanel from '../../core/SlidePanel/SlidePanel';
import Tabs from '../../core/Tabs/Tabs';
import TabPanel from '../../core/Tabs/TabPanel';
import OptimizedCartItemDetails from '../OptimizedCartItemDetails/OptimizedCartItemDetails';
import Button from '../../core/Button/Button';
import DeliveryType from '../DeliveryType/DeliveryType';
import WholesalerReports from '../WholesalerReports/WholesalerReports';
import { getPageContent } from '../../../actions/PageContentAction';
import { getMultiCart, clearSavedAll, updateDeliveryDate, openMiniCart, restoreCart } from '../../../actions/CartAction';
import { formatHungaryTousends, redirectTo } from '../../../common/Utils/Utils';
import {
    isIndirectUser,
    isReturnEmpties,
    isUseMarketingMaterials
} from '../../../common/UserManager/UserManager';
import { PAGE_ID, URL_PATHS, CART_L10N, DATA_LAYER_LISTS } from '../../../common/Constants';
import ClearAllCartsModal from '../common/ClearAllCartsModal/ClearAllCartsModal';
import * as styles from './OptimizedMiniCart.css';
import { removeFromCartEventGA4, buttonClickEventConfigs, buttonClickEvent } from '../../../common/GoogleTagManager/GoogleTagManager';
import { getGTMRemoveFromCartProductGA4, getProductPrice } from '../../../service/DataLayerFilter';

const cx = classNames.bind(styles);

export const OptimizedMiniCart = props => {
    const {
        isCartPage,
        localization,
        cartDetails = {},
        commonLocalization,
        isPickUpAllowed,
        isZADOrderType,
        savedCartTotalPrice = {},
        showGrossPrice,
        stockAllocation,
    } = props;
    const isIndirectCustomer = isIndirectUser();
    const isReturnEmpty = isReturnEmpties();
    const isMarketingMaterials = isUseMarketingMaterials();
    const isCartEmpty = cartDetails.length === 0;
    const [isClearAllCartsModal, setClearAllCartsModal] = useState(false);
    const [showCartSlider, setShowCartSlider] = useState(true);
    const [activeCart, setActiveCart] = useState(0);
    const emptyDeliveryMenu = !isIndirectCustomer && !isPickUpAllowed && !isReturnEmpty && !isMarketingMaterials;

    const logistic = '';

    const tabRef = [];

    useEffect(() => {
        if (!isCartEmpty && cartDetails.cartList && activeCart > cartDetails.cartList.length - 1)
            tabRef[`cartTab-0`].current.props.onSelect(0);
    }, [cartDetails.totalCartCount]);

    useEffect(() => {
        if (_isEmpty(localization)) {
            props.actions.getPageContent({ pageId: PAGE_ID.MINI_CART });
        } else if (!isCartPage) {
			props.actions.restoreCart({isOptmizedMiniCartFlow: true});

        }
    }, []);

    const continueToCart = () => {
        try {
            buttonClickEvent(buttonClickEventConfigs.minicart.actions.continueToCart, buttonClickEventConfigs.minicart.labels.continueToCart, buttonClickEventConfigs.minicart.categories.minicart);
        } catch (ex) {
            console.error(ex);
        }

        redirectTo(URL_PATHS.CART);
    };

    const onCancelEvent = () => {
        setShowCartSlider(false);
        props.onClose(false);
    };

    const onClearAllCarts = () => {
        setClearAllCartsModal(true);
        setShowCartSlider(false);
    };

    const onClearAll = () => {
        let analyticsCartDetails = [];
        try {
            analyticsCartDetails = _cloneDeep(cartDetails);
        } catch (err) {
            console.error(err);
        }

        props.actions.clearSavedAll();
        setClearAllCartsModal(false);
        props.onClose(false);

        try {
            if (!analyticsCartDetails?.length) {
                return;
            }

            removeFromCartEventGA4(analyticsCartDetails.map((p, i) => getGTMRemoveFromCartProductGA4(
                i,
                p?.name,
                p?.codeShort,
                p?.quantityType,
                p?.quantity,
                (p?.price?.formattedValue || p?.price?.value) && p?.quantity ? +(getProductPrice(p?.price?.formattedValue || p?.price?.value) / p.quantity).toFixed(2) : 0,
                DATA_LAYER_LISTS.MINI_CART_LIST,
                p?.price?.currencyIso,
                p?.brand,
                p?.category
            )), buttonClickEventConfigs.minicart.labels.clearAllCarts);
        } catch (err) {
            console.error(err);
        }
    };

    const onCloseClearAll = () => {
        setClearAllCartsModal(false);
        props.onClose(false);
    };

   const renderDeliveryMenu = () => {
        return (
            isPickUpAllowed &&
            !isReturnEmpty &&
            !isMarketingMaterials &&
            !_isEmpty(localization) && <DeliveryType localization={localization} />
        );
    };

    const renderWholeSalerMenu = () => {
        return !isZADOrderType && !isMarketingMaterials && <WholesalerReports />;
    };

    const returnToShop = () => {
        setShowCartSlider(false);
        props.onClose(false);
        redirectTo(URL_PATHS.PLP_ORDER);
        buttonClickEvent(
            buttonClickEventConfigs.minicart.actions.returnToShopping,
            buttonClickEventConfigs.minicart.labels.returnToShopping,
            buttonClickEventConfigs.minicart.categories.minicart
        );
    };

    return (
        <div automation-id='at-mini-cart' className={cx('row', 'headerCont', 'miniCartSlider')}>
            <SlidePanel
                isOpenPanel={showCartSlider}
                panelIcon='close-gray'
                onClosePanel={onCancelEvent}
                slidePanelHeaderTheme={cx('slideHeaderTheme')}
                themePanelContent={cx('sliderContent')}
                automationId='at-cart-Panel'
            >
                <div className={cx('popupSliderContainer')}>
                    <div className={cx('sliderWrapper', 'sliderBG')}>
                        <div className={cx('popupTitle', emptyDeliveryMenu && 'cartTitle')}>
                            <div className={cx('col7', 'sm12', 'cartWrapper')}>
                                <h3>{localization[CART_L10N.MINI_CART_TITLE]}</h3>
                            </div>
                            <div className={cx('col5', 'sm12', 'deliveryOptionWrapper')}>
                                {isIndirectCustomer ? renderWholeSalerMenu() : renderDeliveryMenu()}
                            </div>
                        </div>
                        {!isCartEmpty ? (
                            <div className={cx('QuickViewContent')}>
                                {' '}
                                {/* TODO Cart is EMPTY Display None */}
                                <div className={cx('cartBtn')}>
                                    <Button
                                        className={cx('continueCart')}
                                        type='button'
                                        buttonType='Primary'
                                        onClick={continueToCart}
                                    >
                                        {localization[CART_L10N.CONT_TO_CART]}
                                    </Button>
                                </div>
                                <div className={cx('bdrBtmCart')} />
                                <Tabs
                                    activeTabPanelIndex={0}
                                    themeTabContainerClass={cx('themeTabContainerClass')}
                                    customTabPanelContentClass={cx('contentWrapper')}
                                    onTabChange={setActiveCart}
                                    className={cx('display-none')}
                                >
                                  
                                  <TabPanel
                                                 
                                                    index={0}
                                                    customTabButtonClass={cx('customTabButtonClass')}
                                                    customContainerClass={cx('customContainerClass')}
                                                >
                                                    <p>
                                                        <OptimizedCartItemDetails
                                                            customAddCartDetailsWrapper={cx('cartItemDetailsWrapper')}
                                                            isFrom={DATA_LAYER_LISTS.MINI_CART_LIST}
                                                            customCartLevels={cx('customCartLevels')}
                                                            cartInfo={cartDetails}
                                                            localization={localization}
                                                            commonLocalization={commonLocalization}
                                                            stockAllocation={stockAllocation}
                                                        />
                                                    </p>
                                                </TabPanel>       
                                            
                                    
                                </Tabs>
                                <div
                                    className={cx(
                                        'taxFooterBtm',
                                        isCartPage && 'mobileCartSummary',
                                        props.deviceType.isDevice && 'deviceFooterBtm',
                                        logistic && 'setDetails'
                                    )}
                                >
                                    <div className={cx('priceBox')}>
                                        {savedCartTotalPrice.value > 0 && (
                                            <>
                                                <div className={cx('col5', 'sm5', 'pad0')}>
                                                    <h5 className={cx('totTax')}>
                                                    {showGrossPrice 
                                                            ? localization[CART_L10N.TOTAL_WITH_TAX]
                                                            : localization[CART_L10N.TOTAL_WITHOUT_TAX]
                                                        }
                                                    </h5>
                                                </div>

                                                <div className={cx('col7', 'sm7', 'pad0')}>
                                                    <h5 className={cx('prodAmt')}>          
                                                        {formatHungaryTousends(savedCartTotalPrice.formattedValue)}
                                                    </h5>
                                                </div>
                                            </>
                                        )}

                                       </div>

                                    <div className={cx('checkoutbdrBtmShort')}>
                                        <Button
                                            className={cx('checkoutcontinueCart')}
                                            type='button'
                                            buttonType='Primary'
                                            onClick={continueToCart}
                                        >
                                            {localization[CART_L10N.CONT_TO_CART]}
                                        </Button>
                                    </div>

                                    <div className={cx('clearCart')}>
                                        <Button className={cx('clearcartBtn')} type='button' onClick={onClearAllCarts}>
                                            {localization[CART_L10N.CLEAR_ALL_CART]}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                {cartDetails.isClearCart ? (
                                    <p className={cx('emptyDeliveryCart')}>
                                        {localization[CART_L10N.DELIVERY_TYPE_ERROR]}
                                    </p>
                                ) : (
                                    <p className={cx('emptyCart')}>{localization[CART_L10N.CART_EMPTY_ERROR]}</p>
                                )}
                                <div className={cx('returnWrapper')}>
                                    <Button
                                        automation-id='at-return-shopping'
                                        className={cx('returnBtn')}
                                        type='button'
                                        size='Sm'
                                        buttonType='Primary'
                                        onClick={returnToShop}
                                    >
                                        {localization[CART_L10N.RETURN_TO_SHOP]}
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </SlidePanel>

            {isClearAllCartsModal && (<ClearAllCartsModal
                showModal={isClearAllCartsModal}
                onOk={onClearAll}
                onCancel={onCloseClearAll}
                localization={localization}
                isFromMiniCart
            />)}
        </div>
    );
};

OptimizedMiniCart.propTypes = {
    deviceType: PropTypes.objectOf(PropTypes.bool),
    isCartPage: PropTypes.bool,
    cartDetails: PropTypes.objectOf(PropTypes.any),
    savedCartTotalPrice: PropTypes.objectOf(PropTypes.any),
    localization: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    isPickUpAllowed: PropTypes.bool,
    isZADOrderType: PropTypes.bool,
    showGrossPrice: PropTypes.bool,
    commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

OptimizedMiniCart.defaultProps = {
    deviceType: {},
    isCartPage: false,
    cartDetails: {},
    localization: {},
    isPickUpAllowed: false,
    isZADOrderType: false,
    commonLocalization: {},
    savedCartTotalPrice: {},
    showGrossPrice: true
};

const mapStateToProps = state => {
    const isCartPage = window.location.pathname === URL_PATHS.CART;
    const localization = isCartPage
        ? _get(state, 'pageContent.localization')
        : _get(state, `pageContent.commonLocalization[${PAGE_ID.MINI_CART}]`);

    return {
        deviceType: _get(state, 'context.deviceType'),
        isCartPage,
        cartDetails: _get(state, 'cartDetails.miniCartItems'),
        savedCartTotalPrice: _get(state, 'cartDetails.miniCart.savedCartTotalPrice'),
        showGrossPrice: _get(state, 'cartDetails.miniCart.showGrossPrice'),
        localization,
        isPickUpAllowed: _get(state, 'loginPage.pickUpAllowed'),
        isZADOrderType: _get(state, 'loginPage.isZADOrderType'),
        stockAllocation: _get(state, `pageContent.stockAllocation`),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getPageContent,
            getMultiCart,
            clearSavedAll,
            updateDeliveryDate,
			openMiniCart,
            restoreCart
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(OptimizedMiniCart);
