import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import HomeApi from '../interfaces/Home/HomeApi';
import ProductListingApi from '../interfaces/PLP/PLPApi';
import FactorySaga from './FactorySaga';
import { getQuickOrderListSaga } from './OrderListSaga';
import {
    GET_HOME_PAGE_DATA_REQUEST,
    GET_HOME_PAGE_DATA_REQUEST_SUCCESS,
    GET_HOME_PAGE_DATA_REQUEST_ERROR,
    SET_HOME_SPINNER
} from '../actionTypes/HomeActionTypes';
import {
    GET_CUSTOMER_REPORT_SUCCESS,
    GET_CUSTOMER_REPORT_ERROR,
    GET_CUSTOMER_REPORT_REQUEST
} from '../actionTypes/CustomerReportActionTypes';
import {
    GET_FINANCIAL_INFO_REQUEST,
    GET_FINANCIAL_INFO_SUCCESS,
    GET_FINANCIAL_INFO_ERROR
} from '../actionTypes/FinancialInfoActionTypes';
import {
    GET_DISCOUNT_INFO_REQUEST,
    GET_DISCOUNT_INFO_SUCCESS,
    GET_DISCOUNT_INFO_ERROR
} from '../actionTypes/DiscountInfoActionTypes';
import { notifyBlueMessage } from '../actions/NotificationAction';

export const getFavoriteProductData = state => _get(state, 'homePageData', {});
const getQuickOrderList = state => _get(state, 'orderListData.quickOrders');
const getFinancialInfo = state => _get(state, 'financialInfoData');
const getDiscountInfo = state => _get(state, 'discountInfoData');
const getCustomerReport = state => _get(state, 'customerReport');

export function* getFavoriteProductSaga(action = {}) {
    try {
        action.payload = {
            ...action.payload,
            queryString: '&isFavorite=true'
        };
        const result = yield call(FactorySaga, ProductListingApi.getProductListing, action);

        if (result.isSuccess) {
            yield put({ type: GET_HOME_PAGE_DATA_REQUEST_SUCCESS, data: _get(result, 'response.data', []) });
        } else {
            yield put({ type: GET_HOME_PAGE_DATA_REQUEST_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_HOME_PAGE_DATA_REQUEST_ERROR });
    }
}

function* customerReportSaga(action) {
    try {
        const result = yield call(FactorySaga, HomeApi.getCustomReports, action);

        if (result.isSuccess) {
            yield put({ type: GET_CUSTOMER_REPORT_SUCCESS, data: result.response.data });
        } else {
            yield put({ type: GET_CUSTOMER_REPORT_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_CUSTOMER_REPORT_ERROR });
    }
}

function* finacialInfoSaga(action) {
    try {
        const result = yield call(FactorySaga, HomeApi.getFiniancialInfo, action);

        if (result.isSuccess) {
            yield put({ type: GET_FINANCIAL_INFO_SUCCESS, data: result.response.data });
        } else {
            yield put({ type: GET_FINANCIAL_INFO_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_FINANCIAL_INFO_ERROR });
    }
}

function* discountInfoSaga(action) {
    try {
        const result = yield call(FactorySaga, HomeApi.getDiscountInfo, action);
        if (result.isSuccess) {
        
            const messages = [];
             const selfiredDiscountMessage = _get(result, 'response.data.selfiRedDiscountMessage');
             if(selfiredDiscountMessage){
                messages.push(selfiredDiscountMessage);
            }

            if (messages) {
                yield put(notifyBlueMessage({ messages, isAutoClose: false}));
            }
            

            yield put({ type: GET_DISCOUNT_INFO_SUCCESS, data: result.response.data });
        } else {
            yield put({ type: GET_DISCOUNT_INFO_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_DISCOUNT_INFO_ERROR });
    }
}

function* getHomePageSaga(action) {
    try {
        let isFavSpinner = false;
        let isBottomSpinner = false;

        const favoriteProductData = yield select(getFavoriteProductData);
        const quickOrderList = yield select(getQuickOrderList);
        const financialInfo = yield select(getFinancialInfo);
        const customerReport = yield select(getCustomerReport);
        const discountInfo = yield select(getDiscountInfo);
        const homeSagas = [];

        if (_isEmpty(favoriteProductData.favProducts)) {
            homeSagas.push(call(getFavoriteProductSaga, action));
            isFavSpinner = true;
        }

        if (_isEmpty(quickOrderList)) {
            homeSagas.push(call(getQuickOrderListSaga, action));
            isBottomSpinner = true;
        }

        if (_isEmpty(financialInfo)) {
            homeSagas.push(call(finacialInfoSaga, action));
            isBottomSpinner = true;
        }

        if (_isEmpty(customerReport)) {
            homeSagas.push(call(customerReportSaga, action));
            isBottomSpinner = true;
        }

        if (_isEmpty(discountInfo)) {
            homeSagas.push(call(discountInfoSaga, action));
            isBottomSpinner = true;
        }

        yield put({ type: SET_HOME_SPINNER, data: { isFavSpinner, isBottomSpinner } });
        yield all(homeSagas);
    } catch (err) {
        yield put({ type: GET_HOME_PAGE_DATA_REQUEST_ERROR });
    } finally {
        yield put({ type: SET_HOME_SPINNER });
    }
}

export default function* watchHomeRequest() {
    yield takeLatest(GET_CUSTOMER_REPORT_REQUEST, customerReportSaga);
    yield takeLatest(GET_FINANCIAL_INFO_REQUEST, finacialInfoSaga);
    yield takeLatest(GET_DISCOUNT_INFO_REQUEST, discountInfoSaga);
    yield takeLatest(GET_HOME_PAGE_DATA_REQUEST, getHomePageSaga);
}
