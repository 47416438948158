export const GET_PROMOTIONS_LIST_REQUEST = 'GET_PROMOTIONS_LIST_REQUEST';
export const GET_PROMOTIONS_LIST_SUCCESS = 'GET_PROMOTIONS_LIST_SUCCESS';
export const GET_PROMOTIONS_LIST_ERROR = 'GET_PROMOTIONS_LIST_ERROR';
export const GET_PROMOTION_DEAL_REQUEST = 'GET_PROMOTION_DEAL_REQUEST';
export const GET_PROMOTION_DEAL_SUCCESS = 'GET_PROMOTION_DEAL_SUCCESS';
export const GET_PROMOTION_DEAL_ERROR = 'GET_PROMOTION_DEAL_ERROR';
export const ADD_TO_CART_PROMO = 'ADD_TO_CART_PROMO';
export const ADD_TO_CART_PROMO_ERROR = 'ADD_TO_CART_PROMO_ERROR';
export const ADD_PROMO_TO_CART_SUCCESS = 'ADD_PROMO_TO_CART_SUCCESS';
export const REMOVE_PROMO_FROM_CART = 'REMOVE_PROMO_FROM_CART';
export const REMOVE_PROMO_FROM_CART_SUCCESS = 'REMOVE_PROMO_FROM_CART_SUCCESS';
export const REMOVE_PROMO_FROM_CART_ERROR = 'REMOVE_PROMO_FROM_CART_ERROR';
export const RESET_REMOVE_PROMO_FROM_CART_SUCCESS = 'RESET_REMOVE_PROMO_FROM_CART_SUCCESS';
export const RESET_ADD_PROMO_TO_CART_SUCCESS = 'RESET_ADD_PROMO_TO_CART_SUCCESS';
export const REMOVE_PRESELECTED_DEAL = 'REMOVE_PRESELECTED_DEAL';
export const RESET_ACTIVE_DEAL_INDEX_SUCCESS = 'RESET_ACTIVE_DEAL_INDEX_SUCCESS';
export const RESET_PROMOTIONS_LIST = 'RESET_PROMOTIONS_LIST';
export const GET_PROMO_CALENDER_LIST_REQUEST = 'GET_PROMO_CALENDER_LIST_REQUEST';
export const GET_PROMO_CALENDER_LIST_SUCCESS = 'GET_PROMO_CALENDER_LIST_SUCCESS';
export const RESET_PROMO_CALENDER = 'RESET_PROMO_CALENDER';
export const GET_PRE_CHECKOUT_UPDATE = "GET_PRE_CHECKOUT_UPDATE";
export const GET_PRE_CHECKOUT_UPDATE_SUCCESS = "GET_PRE_CHECKOUT_UPDATE_SUCCESS";
export const GET_PRE_CHECKOUT_UPDATE_ERROR = "GET_PRE_CHECKOUT_UPDATE_ERROR";
export const CLEAR_REDUCER = "CLEAR_REDUCER";