import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames/bind';
import * as styles from './PromotionCard.css';
import QtyInput from '../../core/QtyInput/QtyInput';
import Image from '../../core/Image/Image';
import { IMAGE_COMING_SOON } from '../../../common/Constants';
import { StockQuantity } from './StockQuantity';

const cx = classNames.bind(styles);

const PromotionCard = props => {
    const { title, showCard, customClass, items, changeQuantity, skuText, inCartText, mechanicType, isDecrementDisable, groupItemAmount } = props;

    const getItemQuantity = item => {
        const result =
            mechanicType === 'B'
                ? item.quantity !== undefined
                    ? item.quantity
                    : item.inCartAmount
                : item.quantity
                    ? item.quantity
                    : 0;

        return result;
    };

    // TODO Refactor and check again
    const getSumOfItemExcludingCurrent = (item) => {
        const itemsWithoutCurrentItem = items.filter(itemMap => itemMap.productCode !== item.productCode)

        let sum = 0;
        itemsWithoutCurrentItem.forEach(currentItem => {
            sum += getItemQuantity(currentItem);
        });

        return sum;
    }

    

   

    return (
        <div
            title={title}
            className={cx(
                'promotionsCardsWrapper',
                showCard ? 'mobileViewCard' : 'hiddenMobileView',
                customClass && 'spaceInner'
            )}
            automation-id='promotion-summary'
        >
            <ul className={cx('cardsContent')}>
                {items.map((item, key) => {
                    return (
                        <li key={item.productCodeShort} className={cx('col3', 'md3', 'sm12', 'listItems')}>
                            <div className={cx('thumbImg')}>
                                <Link className={cx('imageLink')}>
                                    {_isEmpty(item.imageUrl) ? (
                                        <img src={IMAGE_COMING_SOON} alt={item.productName} />
                                    ) : (
                                        <Image
                                            automation-id='at-prodimg'
                                            key={item.imageUrl}
                                            src={item.imageUrl}
                                            alt={item.productName}
                                        />
                                    )}
                                </Link>
                            </div>
                            <div className={cx('description')}>
                                <h4 className={cx('subTitle')}>{item.productName}</h4>
                                <p className={cx('sku')}>
                                    {skuText} {item.productCodeShort}
                                </p>
                                <p className={cx('pdContent')}>
                                    {item.inCartAmount} {inCartText}
                                </p>
                            </div>
                            <QtyInput
                                id={`qty-${item.productCodeShort}`}
                                theme={cx(`inputTheme`, item.inCartAmount === 0 ? '' : 'inputThemeItemInCart')}
                                themeIncreBtn={cx('themeDescrBtn')}
                                themeDescrBtn={cx('themeIncreBtn')}
                                onChange={e => changeQuantity(e, key)}
                                isDecrementDisable={isDecrementDisable}
                                minValue={mechanicType === "B" ? groupItemAmount - getSumOfItemExcludingCurrent(item) : 0}
                                maxValue={mechanicType === 'B' ? 99999 : parseInt(item.maxAmount, 10)}
                                value={getItemQuantity(item) || 0}
                            />
                            <StockQuantity product={item} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

PromotionCard.propTypes = {
    title: PropTypes.string,
    showCard: PropTypes.bool,
    customClass: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.any),
    changeQuantity: PropTypes.func,
    skuText: PropTypes.string,
    inCartText: PropTypes.string,
    isDecrementDisable: PropTypes.bool
};

PromotionCard.defaultProps = {
    isDecrementDisable: false,
    title: '',
    showCard: false,
    customClass: false,
    items: [],
    changeQuantity: () => { },
    skuText: '',
    inCartText: ''
};

export default PromotionCard;

