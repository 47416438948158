import { all, put, takeLatest, call } from 'redux-saga/effects';
import _get from 'lodash/get';
import {
    GET_ORDER_TEMPLATE_DETAILS_REQUEST,
    GET_ORDER_TEMPLATE_DETAILS_SUCCESS,
    GET_ORDER_TEMPLATE_DETAILS_ERROR,
    UPDATE_ORDER_TEMPLATE_DETAILS_QUANTITY_REQUEST,
    UPDATE_ORDER_TEMPLATE_DETAILS_REQUEST,
    UPDATE_SORTING_ORDER_TEMPLATE_ITEM_REQUEST,
    GET_ORDER_ITEM_TEMPLATE_DETAILS_SUCCESS
} from '../actionTypes/OrderTemplateDetailsActionTypes';
import { RESET_ORDER_TEMPLATE } from '../actionTypes/OrderTemplateActionTypes';
import FactorySaga from './FactorySaga';
import OrderTemplateApi from '../interfaces/OrderTemplate/OrderTemplateApi';
import { notifySuccess } from '../actions/NotificationAction';

export function* getTemplateDetailsSaga(action) {
    try {
        const result = yield call(FactorySaga, OrderTemplateApi.getOrderTemplateDetails, action);

        if (result.isSuccess) {
            yield put({ type: GET_ORDER_TEMPLATE_DETAILS_SUCCESS, data: result.response.data });
        } else {
            yield put({ type: GET_ORDER_TEMPLATE_DETAILS_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_ORDER_TEMPLATE_DETAILS_ERROR });
    }
}

function* updateOrderDetailsQtySaga(action) {
    try {
        const result = yield call(FactorySaga, OrderTemplateApi.updateOrderTemplate, action);

        if (result.isSuccess) {
            yield all([put({ type: RESET_ORDER_TEMPLATE }), put(notifySuccess(result.response.data))]);
            yield call(getTemplateDetailsSaga, action.payload);
        }
    } catch (err) {
        yield put({ type: GET_ORDER_TEMPLATE_DETAILS_ERROR });
    }
}

function* updateSortingOrderSaga(action) {
    try {
        const result = yield call(FactorySaga, OrderTemplateApi.updateSortingOrder, action);

        if (result.isSuccess) {
            const templateData = _get(result.response, 'data.cartWsdto', {});
            yield put({ type: RESET_ORDER_TEMPLATE });
            yield put({ type: GET_ORDER_ITEM_TEMPLATE_DETAILS_SUCCESS, data: templateData });
        }
    } catch (err) {
        yield put({ type: GET_ORDER_TEMPLATE_DETAILS_ERROR });
    }
}

function* updateOrderDetailsSaga(action) {
    try {
        const result = yield call(FactorySaga, OrderTemplateApi.updateOrderTemplateDetails, action);

        if (result.isSuccess) {
            yield all([put({ type: RESET_ORDER_TEMPLATE }), put(notifySuccess(result.response.data))]);
            yield call(getTemplateDetailsSaga, action.payload);
        }
    } catch (err) {
        yield put({ type: GET_ORDER_TEMPLATE_DETAILS_ERROR });
    }
}

export default function* watchOrderTemplateDetailsSaga() {
    yield takeLatest(GET_ORDER_TEMPLATE_DETAILS_REQUEST, getTemplateDetailsSaga);
    yield takeLatest(UPDATE_ORDER_TEMPLATE_DETAILS_QUANTITY_REQUEST, updateOrderDetailsQtySaga);
    yield takeLatest(UPDATE_ORDER_TEMPLATE_DETAILS_REQUEST, updateOrderDetailsSaga);
    yield takeLatest(UPDATE_SORTING_ORDER_TEMPLATE_ITEM_REQUEST, updateSortingOrderSaga);
}
