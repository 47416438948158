import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { PARAMS } from '../common/Api';

export default class LoyaltyApi {
    static getAllLoyaltyNotifications(payload) {
        const { baseInfo } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}/users/${baseInfo.userId}/loyalty/all`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static sendReadLoyaltyNotification(payload) {
        const { baseInfo, messageId, params = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}/users/${baseInfo.userId}/loyalty/read`;
        params[PARAMS.MESSAGE_ID] = messageId;
        config.params = params;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static muteLoyaltyNotificationsFunc(payload) {
        const { baseInfo } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}/users/${baseInfo.userId}/loyalty/mute`;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static unmuteLoyaltyNotificationsFunc(payload) {
        const { baseInfo } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}/users/${baseInfo.userId}/loyalty/unmute`;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static clearAllLoyaltyNotifications(payload) {
        const { baseInfo } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}/users/${baseInfo.userId}/loyalty/deleteall`;
        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static getLoyaltyDashboardData(payload) {
        const { baseInfo } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}/users/${baseInfo.userId}/loyalty/data`;
        return RestClient.get(config).then(json => {
            return json;
        });
    }
}
