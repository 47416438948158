export const GET_LOYALTY_NOTIFICATIONS = 'GET_LOYALTY_NOTIFICATIONS';
export const GET_LOYALTY_NOTIFICATIONS_SUCCESS = 'GET_LOYALTY_NOTIFICATIONS_SUCCESS';
export const GET_LOYALTY_NOTIFICATIONS_ERROR = 'GET_LOYALTY_NOTIFICATIONS_ERROR';
export const READ_LOYALTY_NOTIFICATION = 'READ_LOYALTY_NOTIFICATION';
export const READ_LOYALTY_NOTIFICATION_SUCCESS = 'READ_LOYALTY_NOTIFICATION_SUCCESS';
export const READ_LOYALTY_NOTIFICATION_ERROR = 'READ_LOYALTY_NOTIFICATION_ERROR';
export const MUTE_LOYALTY_NOTIFICATIONS = 'MUTE_LOYALTY_NOTIFICATIONS';
export const MUTE_LOYALTY_NOTIFICATIONS_SUCCESS = 'MUTE_LOYALTY_NOTIFICATIONS_SUCCESS';
export const MUTE_LOYALTY_NOTIFICATIONS_ERROR = 'MUTE_LOYALTY_NOTIFICATIONS_ERROR';
export const UNMUTE_LOYALTY_NOTIFICATIONS = 'UNMUTE_LOYALTY_NOTIFICATIONS';
export const UNMUTE_LOYALTY_NOTIFICATIONS_SUCCESS = 'UNMUTE_LOYALTY_NOTIFICATIONS_SUCCESS';
export const UNMUTE_LOYALTY_NOTIFICATIONS_ERROR = 'UNMUTE_LOYALTY_NOTIFICATIONS_ERROR';
export const CLEAR_LOYALTY_NOTIFICATIONS = 'CLEAR_LOYALTY_NOTIFICATIONS';
export const CLEAR_LOYALTY_NOTIFICATIONS_SUCCESS = 'CLEAR_LOYALTY_NOTIFICATIONS_SUCCESS';
export const CLEAR_LOYALTY_NOTIFICATIONS_ERROR = 'CLEAR_LOYALTY_NOTIFICATIONS_ERROR';
export const GET_LOYALTY_DASHBOARD_DATA_SUCCESS = 'GET_LOYALTY_DASHBOARD_DATA_SUCCESS';
export const GET_LOYALTY_DASHBOARD_DATA_ERROR = 'GET_LOYALTY_DASHBOARD_DATA_ERROR';
export const GET_LOYALTY_DASHBOARD_DATA = 'GET_LOYALTY_DASHBOARD_DATA';
export const SHOW_NOTIFICATION_BANNER = 'SHOW_NOTIFICATION_BANNER';
