import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import classNames from 'classnames/bind';
import { getPageContent } from '../../../actions/PageContentAction';
import { clearLoginMessage } from '../../../actions/LoginAction';
import { CDC_CONTAINER_IDS, CDC_SCREEN_SET_IDS, PAGE_ID } from '../../../common/Constants';
import * as styles from './UpdatePassword.css';
import { loadCDCSDK } from '../../../common/Utils/Utils';
import { store } from '../../../../__runtime/redux/Store';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);
export class UpdatePassword extends Component {
    static propTypes = {
        actions: PropTypes.objectOf(PropTypes.func),
        localization: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        message: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    };

    static defaultProps = {
        actions: {},
        localization: {},
        message: {}
    };

    constructor(props) {
        super(props);
        props.actions.getPageContent({ pageId: PAGE_ID.UPDATE_PASSWORD });
    }

    componentDidMount() {
        loadCDCSDK(store.getState())
            .then(() => {
                gigya.accounts.showScreenSet({
                    screenSet: CDC_CONTAINER_IDS.PROFILE_UPDATE,
                    startScreen: CDC_SCREEN_SET_IDS.CHANGE_PW,
                    containerID: 'update-password-screen-set-container'
                });
            })
            .catch(error => {
                console.log(error);
            });

        document.addEventListener('click', this.onGigayaClick, { capture: true });
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onGigayaClick, { capture: true });
    }

    onGigayaClick(e) {
        if (
            e.target.parentElement.className ===
            'gigya-composite-control gigya-composite-control-submit cch-changepw-btn'
        ) {
            buttonClickEvent(
                buttonClickEventConfigs.accountSettings.actions.editPassword,
                buttonClickEventConfigs.accountSettings.labels.editPasswordUpdatePassword,
                buttonClickEventConfigs.accountSettings.categories.accountSettings
            );
            return;
        }

        if (
            e.target.parentElement.className ===
            'gigya-composite-control gigya-composite-control-submit cch-changepw-cancelbtn'
        ) {
            buttonClickEvent(
                buttonClickEventConfigs.accountSettings.actions.editPassword,
                buttonClickEventConfigs.accountSettings.labels.editPasswordCancel,
                buttonClickEventConfigs.accountSettings.categories.accountSettings
            );
            return;
        }
    }

    render() {
        return (
            <div className={cx('loginContainer')}>
                <div className={cx('col12', 'pad0')}>
                    <section className={cx('updateLoginBlock')}>
                        <div className={cx('col6', 'md7', 'sm12', 'loginSection', 'loginInner')}>
                            <div id='update-password-screen-set-container' />
                        </div>
                        <div className={cx('col6', 'sm12', 'hiddenSm', 'rgtBanner')} />
                    </section>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        localization: _get(state, 'pageContent.localization'),
        message: _get(state, 'loginPage.message')
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            clearLoginMessage,
            getPageContent
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
