import {
    CLOSE_SUBSCRIPTION_POPUP,
    OPEN_SUBSCRIPTION_POPUP,
    SUBSCRIBE_TO_PRODUCT_SUCCESS
} from "../actionTypes/NotifyMeActionTypes";

export default function NotifyMeSubscriptionReducer(state = {}, action = {}) {
    switch (action.type) {
        case OPEN_SUBSCRIPTION_POPUP:
            return {
                ...state,
                showPopup: true,
                productCode: action.productCode
            }
        case CLOSE_SUBSCRIPTION_POPUP:
            return {
                ...state,
                showPopup: false,
                productCode: null
            }
        case SUBSCRIBE_TO_PRODUCT_SUCCESS:
            return {
                ...state,
                showPopup: false,
                productCode: null
            }
        default:
            return state;
    }
}