import { put, takeLatest, call } from 'redux-saga/effects';
import FactorySaga from './FactorySaga';
import CreditApi from '../interfaces/CreditInfo/CreditInfoApi';

import {
    GET_CREDIT_INFO_REQUEST,
    GET_CREDIT_INFO_SUCCESS,
    GET_CREDIT_INFO_ERROR
} from '../actionTypes/CreditInfoActionTypes';

export function* getCreditInfoSaga(action) {
    try {
        const result = yield call(FactorySaga, CreditApi.getCreditInfo, action);
        if (result.isSuccess) {
            yield put({
                type: GET_CREDIT_INFO_SUCCESS,
                data: result.response.data || { isAPIInvoked: result.isSuccess }
            });
        } else {
            yield put({ type: GET_CREDIT_INFO_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_CREDIT_INFO_ERROR });
    }
}

export default function* watchCreditRequest() {
    yield takeLatest(GET_CREDIT_INFO_REQUEST, getCreditInfoSaga);
}
