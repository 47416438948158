import React, { useMemo } from 'react';

import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { PLP_L10N } from '../../../../common/Constants';
import List from '../../ProductList/List';

export const AccountShoppingBdaa = () => {
    const deviceType = useSelector(state => state.context.deviceType);
    const bdaaSuggestion = useSelector(state => state.products.bdaaSuggestion);

    const localization = useSelector(state => state.pageContent.localization);

    const bdaaSuggestionObj = useMemo(() => {
        return {
            products: bdaaSuggestion,
            currentPage: 0,
            totalPages: 1
        };
    }, [bdaaSuggestion]);

    return (
        <List
            productList={bdaaSuggestionObj}
            selectedView={PLP_L10N.GRID_VIEW}
            currentPage={0}
            bdaaSuggestion={bdaaSuggestion}
            localization={localization}
            isShowFilter={false}
            isShowPrice={false}
            isGrid={true}
            isReturnEmpties={false}
            appliedMobileView=''
            isSearch={false}
            deviceType={deviceType}
            isMarketingMaterials={false}
            plpType={'order'}
            isFrom='shopping-list'
        />
    );
};
