import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router';
import { useSelector } from 'react-redux';
import styles from './DigitalMenusComponent.css';
import { FSA, FSA_IMAGES } from '../../../../common/Constants';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

const DigitalMenusComponent = () => {
    const localization = useSelector(state => state.pageContent.localization);
    const components = useSelector(state => state.pageContent.components);
    const imageComponents = components.find(component => component.uid === FSA_IMAGES.DELIVERY_PARTNERS_IMAGE);
    const imageUrls =
        imageComponents &&
        imageComponents.childComponents &&
        imageComponents.childComponents.map(image => {
            let partner = '';
            try {
                partner = new URL(image.urlLink).hostname;
            } catch (error) {
                console.error(error);
            }

            return {
                imageUrl: image.url,
                link: image.urlLink,
                partner
            };
        });
    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);

    return (
        <div className={cx('digitalMenusContainer')}>
            <div className={cx('digitalMenusInner')}>
                <h1>{localization[FSA.SECTION_SIX_TEXT_1]}</h1>
                <p>{localization[FSA.SECTION_SIX_TEXT_2]}</p>
                <div className={cx('deliveryCards')}>
                    {imageUrls &&
                        imageUrls.map((image, index) => (
                            <Link
                                target='_blank'
                                key={index}
                                to={image.link}
                                onClick={() => {
                                    try {
                                        buttonClickEvent(
                                            buttonClickEventConfigs.fsa.actions.optionalGuidance,
                                            buttonClickEventConfigs.fsa.labels.operationalGuidancePrefix + image.partner,
                                            buttonClickEventConfigs.fsa.categories.tipsNTricks
                                        );
                                    } catch (ex) {
                                        console.error(ex);
                                    }
                                }}
                            >
                                <img src={`${assetBaseUrl}${image.imageUrl}`} alt='delivery partner' />
                            </Link>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default DigitalMenusComponent;
