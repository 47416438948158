/* eslint-disable no-console */
import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import _isEmpty from 'lodash/isEmpty';
import Countdown from 'react-countdown';
import Icon from '../../core/Icon/Icon';
import { PLP_L10N, PAGE_ID, GENERIC_L10N } from '../../../common/Constants';
import * as styles from './ProductCards.css';
import { useSelector } from 'react-redux';

const cx = classNames.bind(styles);

export const ProductCardsPromoHeader = ({
    skuId,
    promotion,
    localization,
    isInBdaaSlider,
    isFrom,
    updateFilters,
    isPromo
}) => {
    if (!isPromo) {
        return null;
    }

    const [displayPromoDetails, setDisplayPromoDetails] = useState(false);
    const commonLocalization = useSelector(state => state.pageContent.commonLocalization[PAGE_ID.GENERIC]);

    /**
     * @brief      Promotion details
     *
     * @return     {JSX}
     */
    const getPromoDetails = () => {
        const item = document.getElementsByClassName(skuId)[0];
        const itemHeight = item?.clientHeight - 29;
        const itemWidth = item?.clientWidth;
        const descriptionMaxHeight = itemHeight - 220;
        return (
            <div className={cx('promoBody')} style={{ width: `${itemWidth}px`, height: `${itemHeight}px` }}>
                <div className={cx('promoDetails')}>
                    <p className={cx('promoName')}>{promotion.description}</p>
                    <div className={cx('promoDescription')}>
                        {promotion &&
                            (promotion.conditionalGroup.length > 0 ||
                                promotion.freeGroup.length > 0 ||
                                !_isEmpty(promotion.note)) && (
                                <div>
                                    {!_isEmpty(promotion.note) && (
                                        <div>
                                            <h5>
                                                {commonLocalization[GENERIC_L10N.DETAILS]}:{' '}
                                                <span className={cx('details')}>{promotion.note}</span>
                                            </h5>
                                        </div>
                                    )}
                                    {promotion.conditionalGroup.map((group, index) => {
                                        return (
                                            <div key={`promotion-link-${index + 1}`}>
                                                <h5>
                                                    {localization[PLP_L10N.GROUP]} {index + 1}:{' '}
                                                    {group.value.description}
                                                </h5>
                                                {group.value.productInfos.map(info => {
                                                    return <p key={info.name}>{info.name}</p>;
                                                })}
                                            </div>
                                        );
                                    })}
                                    {promotion && promotion.freeGroup && promotion.freeGroup.productInfos && (
                                        <>
                                            <h5>
                                                {localization[PLP_L10N.FREE_GROUP]}: {promotion.freeGroup.description}
                                            </h5>
                                            {promotion.freeGroup.productInfos.map(info => {
                                                return <p key={info.name}>{info.name}</p>;
                                            })}
                                        </>
                                    )}
                                </div>
                            )}
                    </div>
                    {updateFilters && (
                        <p className={cx('allPromosLink')}>
                            <a onClick={() => updateFilters('promotion', promotion.description, null, true)}>
                                {localization[PLP_L10N.PROMO_VIEW_ALL_PRODUCTS]}
                            </a>
                        </p>
                    )}
                    {promotion.rawEndDate && promotion.formattedStartDate && promotion.formattedEndDate && (
                        <div className={cx('promoTimer')}>
                            <p className={cx('promoPeriod')}>{localization[PLP_L10N.ENDS_IN]} </p>
                            <Countdown
                                date={promotion.rawEndDate}
                                renderer={({ days, hours, minutes }) => {
                                    return (
                                        <div>
                                            {days}
                                            {commonLocalization.day} : {hours > 9 ? hours : `0${hours}`}
                                            {commonLocalization.hour} : {minutes > 9 ? minutes : `0${minutes}`}
                                            {commonLocalization.minute}
                                        </div>
                                    );
                                }}
                            />
                        </div>
                    )}

                    <p className={cx('promoPeriod')}>
                        {localization[PLP_L10N.VALID_FROM]}{' '}
                        <span className={cx('promoDate')}>{promotion.formattedStartDate} </span>
                        {localization[PLP_L10N.VALID_UNTIL]}{' '}
                        <span className={cx('promoDate')}>{promotion.formattedEndDate}</span>
                    </p>
                </div>
                <div>
                    {promotion?.listOfLimitedProductCodes.length > 0 &&
                        promotion.listOfLimitedProductCodes.includes(skuId) && (
                            <div className={cx('limitedQuantityContainer')}>
                                <Icon iconType='svg' width='16px' height='16px' viewBox='0 0 22 22' name='info-red' />
                                <p className={cx('limitedQuantityLabel')}>
                                    {commonLocalization[GENERIC_L10N.LIMITED_QUANTITY]}
                                </p>
                            </div>
                        )}
                </div>
            </div>
        );
    };

    return (
        <div className={cx('promoHolder')}>
            <div className={cx('promoHeader')} onClick={() => setDisplayPromoDetails(!displayPromoDetails)}>
                <p className={cx('promoTitle', displayPromoDetails ? 'isOpen' : '')}>
                    {localization[PLP_L10N.PROMO_PRODUCT_TILE]}
                    <Icon
                        className={cx('downIcon', 'expandPromo')}
                        iconType='svg'
                        color='#ffffff'
                        width='16px'
                        height='16px'
                        viewBox='0 0 16 16'
                        name='menu-down'
                    />
                </p>
            </div>
            {displayPromoDetails && getPromoDetails()}
        </div>
    );
};

ProductCardsPromoHeader.propTypes = {
    skuId: PropTypes.string,
    updateFilters: PropTypes.func,
    promotion: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isPromo: PropTypes.bool,
    isInBdaaSlider: PropTypes.bool,
    isFrom: PropTypes.string,
    localization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

ProductCardsPromoHeader.defaultProps = {
    skuId: '',
    updateFilters: null,
    promotion: {},
    isPromo: false,
    isInBdaaSlider: false,
    isFrom: '',
    localization: {}
};

export default React.memo(ProductCardsPromoHeader);
