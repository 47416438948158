import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enUS, cs, ro, hu, bg, hr, uk, sk, el, sl, de, ru, pl, mk, fr, sr, lv, lt, et } from 'date-fns/locale';
import classNames from 'classnames/bind';
import DatePicker from 'react-datepicker';
import { format, isFriday, previousFriday } from 'date-fns';
import { fetchAccountStatement } from '../../../actions/AccountStatementAction';
import { albanianLocale } from '../../core/Datepicker/CustomLocale';
import SelectBox from '../../core/SelectBox/SelectBox';
import Button from '../../core/Button/Button';
import Icon from '../../core/Icon/Icon';
import * as styles from './AccountStatement.css';
import { getLocalization } from '../../../common/UserManager/UserManager';
import { LOGIN_INFO, INVOICES_L10N, ACCOUNT_STATEMENTS_L10N, ORDERS_L10N } from '../../../common/Constants';
import Accordion from '../../core/Accordion/Accordion';
import RadioButton from '../../core/RadioButton/RadioButton';

const cx = classNames.bind(styles);

const AccountStatement = ({ isMobile, onClickFunc }) => {
  const lang = getLocalization()[LOGIN_INFO.LANG];

  const dispatch = useDispatch();

  const localization = useSelector(state => state.pageContent.localization);

  const formatDate = dateString => {
    const date = new Date(dateString);
    return format(date, 'dd.MM.yyyy');
  };

  const getLocale = () => {
    switch (lang) {
      case 'cs':
        return cs;
      case 'ro':
        return ro;
      case 'hu':
        return hu;
      case 'bg':
        return bg;
      case 'hr':
        return hr;
      case 'uk':
        return uk;
      case 'sk':
        return sk;
      case 'el':
        return el;
      case 'sl':
        return sl;
      case 'de':
      case 'de_AT':
        return de;
      case 'ru':
      case 'ru_BY':
        return ru;
      case 'pl':
        return pl;
      case 'mk':
        return mk;
      case 'sh':
        return sr;
      case 'sq':
        return albanianLocale;
      case 'fr':
        return fr;
      case 'lv':
        return lv;
      case 'lt':
        return lt;
      case 'et':
        return et;
      default:
        return enUS;
    }
  };

  const [periodOptions] = useState([
    { label: localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_LAST_WEEK], value: 'Last Week' },
    { label: localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_TWO_WEEKS_AGO], value: '2 Weeks Ago' },
    { label: localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_THREE_WEEKS_AGO], value: '3 Weeks Ago' },
    { label: localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_FOUR_WEEKS_AGO], value: '4 Weeks Ago' },
    { label: localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_CUSTOM_PERIOD], value: 'Custom' }
  ]);

  const [selectedPeriod, setSelectedPeriod] = useState(periodOptions[0]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [formattedStartDate, setFormattedStartDate] = useState(formatDate(new Date()));
  const [formattedEndDate, setFormattedEndDate] = useState(formatDate(new Date()));
  const [dateError, setDateError] = useState(false);
  const [customDateError, setCustomDateError] = useState(false);
  const [displayCalendarNote, setDisplayCalendarNote] = useState(false);

  // Custom input component with icon
  const CustomDatePickerInput = ({ value, onClick }) => (
    <div
      role='presentation'
      onKeyDown={onClick}
      className={cx((dateError || customDateError) && 'date-error', 'custom-date-picker')}
      onClick={onClick}
    >
      {value}
      <Icon iconType='svg' width='20px' height='auto' viewBox='0 0 22 22' name='calender' className={cx("custom-date-picker-icon")} />
    </div>
  );

  // Util functions
  const compareDates = (startDate, endDate) => {
    return startDate < endDate;
  };

  const isWithinOneYearFromToday = (dateFrom) => {
    const givenDate = new Date(dateFrom);
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    return givenDate > oneYearAgo;
  }

  const getDateRange = weeksAgo => {
    const currentDate = new Date();
    if (isFriday(currentDate)) {
      const previousSaturday = new Date(currentDate.getTime());
      const startDate = previousSaturday.setDate(currentDate.getDate() - weeksAgo * 7 + 1);
      const endDate = currentDate;
      const startDateString = formatDate(new Date(startDate));
      const endDateString = formatDate(endDate);
      return { startDateString, endDateString };
    }

    const startDate = new Date(previousFriday(currentDate).getTime());
    startDate.setDate(startDate.getDate() - weeksAgo * 7 + 1);
    const endDate = previousFriday(currentDate);
    const startDateString = formatDate(startDate);
    const endDateString = formatDate(endDate);
    return { startDateString, endDateString };
  };

  const handleStartDate = date => {
    const formattedDate = formatDate(date);
    setStartDate(date);
    setFormattedStartDate(formattedDate);
  };

  const handleEndDate = date => {
    const formattedDate = formatDate(date);
    setEndDate(date);
    setFormattedEndDate(formattedDate);
  };

  const handleGenerateStatement = () => {
    if (selectedPeriod.value === 'Last Week') {
      const dateRange = getDateRange(1);
      dispatch(fetchAccountStatement(dateRange.startDateString, dateRange.endDateString));
      onClickFunc();
    } else if (selectedPeriod.value === '2 Weeks Ago') {
      const dateRange = getDateRange(2);
      dispatch(fetchAccountStatement(dateRange.startDateString, dateRange.endDateString));
      onClickFunc();
    } else if (selectedPeriod.value === '3 Weeks Ago') {
      const dateRange = getDateRange(3);
      dispatch(fetchAccountStatement(dateRange.startDateString, dateRange.endDateString));
      onClickFunc();
    } else if (selectedPeriod.value === '4 Weeks Ago') {
      const dateRange = getDateRange(4);
      dispatch(fetchAccountStatement(dateRange.startDateString, dateRange.endDateString));
      onClickFunc();
    } else if (selectedPeriod.value === 'Custom') {
      if (compareDates(startDate, endDate) === false) {
        setDateError(true);
        setCustomDateError(false);
        return;
      }
      if (!formattedStartDate || !formattedEndDate) {
        setDateError(true);
        setCustomDateError(false);
        return;
      }
      if (isWithinOneYearFromToday(startDate) === false) {
        setCustomDateError(true);
        setDateError(false);
        return;
      }
      dispatch(fetchAccountStatement(formattedStartDate, formattedEndDate));
      onClickFunc();
    } else {
      return null;
    }
  };

  return (
    <div className={cx('mobile-modal-body')}>
      <div className={cx('sub-header')}>
        {localization && localization[ACCOUNT_STATEMENTS_L10N.GENERATE_STATEMENT_POPUP_DESCRIPTION]}
      </div>
      <>
        {isMobile ? (
          <>
            <Accordion
              title={localization && localization[INVOICES_L10N.TIME_PERIOD]}
              customTitle={cx('mobile-accordion-title')}
              className={cx('accordionHeader')}
              iconTheme={cx('iconThemes')}
              themesetActive={cx('setActive')}
              themeScrollContent={cx('themeScrollContent')}
              setActive
            >
              {periodOptions.map(period => {
                return (
                  <RadioButton
                    theme={cx('customRadio')}
                    name='fillterTimePeriodValue'
                    checked={selectedPeriod === period}
                    onChange={() => {
                      setSelectedPeriod(period);
                    }}
                    labelText={period.label}
                    customCheckMark={cx('checkmark')}
                    value={period.value}
                  />
                );
              })}
            </Accordion>
            {selectedPeriod.value === 'Custom' && (
              <div className={cx('custom-date-container')}>
                <div style={{ position: 'relative' }}>
                  <p className={cx('dropdown-title')}>
                    {localization && localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_FROM]}
                  </p>
                  <DatePicker
                    locale={getLocale()}
                    showPopperArrow={false}
                    popperClassName={cx('cool-picker')}
                    customInput={<CustomDatePickerInput />}
                    dateFormat='dd/MM/yyyy'
                    calendarMessage='Select a date'
                    selected={startDate}
                    onChange={date => handleStartDate(date)}
                    withPortal
                    calendarClassName='custom-portal'
                    onCalendarOpen={() => {
                      setDisplayCalendarNote(true);
                      const currentMonthElement = document.querySelector('.react-datepicker__current-month');
                      if (currentMonthElement) {
                        currentMonthElement.style.display = 'flex';
                        currentMonthElement.style.alignItems = 'center';
                        currentMonthElement.style.justifyContent = 'center';
                        currentMonthElement.style.width = 'auto';
                        currentMonthElement.style.margin = '0';
                        currentMonthElement.style.padding = '10px 0';
                        currentMonthElement.style.flexDirection = 'column';
                      }
                      const previousMonthElement = document.querySelector('.react-datepicker__navigation--previous');
                      if (previousMonthElement) {
                        previousMonthElement.style.top = '15px';
                      }
                      const nextMonthElement = document.querySelector('.react-datepicker__navigation--next');
                      if (nextMonthElement) {
                        nextMonthElement.style.top = '15px';
                        nextMonthElement.style.border = 'solid #000';
                        nextMonthElement.style.borderWidth = '2px 0px 0px 2px';
                        nextMonthElement.style.transform = 'rotate(135deg)';
                      }
                    }}
                    onCalendarClose={() => {
                      setDisplayCalendarNote(false);
                    }}
                  >
                    {displayCalendarNote && (
                      <p className={cx('calendar-note')}>
                        {localization &&
                          localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_CUSTOM_PERIOD_NOTE]}
                      </p>
                    )}
                  </DatePicker>
                  {dateError && (
                    <p className={cx('date-error-message')}>
                      {localization &&
                        localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_ERROR_MESSAGE]}
                    </p>
                  )}
                  {customDateError && (
                    <p className={cx('date-error-message')}>
                      {localization &&
                        localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_CUSTOM_PERIOD_ERROR]}
                    </p>
                  )}
                </div>
                <span className={cx('date-picker-divider')}>-</span>
                <div style={{ position: 'relative' }}>
                  <p className={cx('dropdown-title')}>
                    {localization && localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_TO]}
                  </p>
                  <DatePicker
                    showPopperArrow={false}
                    popperClassName={cx('cool-picker')}
                    customInput={<CustomDatePickerInput />}
                    dateFormat='dd/MM/yyyy'
                    calendarMessage='Select a date'
                    selected={endDate}
                    onChange={date => handleEndDate(date)}
                    withPortal
                    calendarClassName='custom-portal'
                    onCalendarOpen={() => {
                      setDisplayCalendarNote(true);
                      const currentMonthElement = document.querySelector('.react-datepicker__current-month');
                      if (currentMonthElement) {
                        currentMonthElement.style.display = 'flex';
                        currentMonthElement.style.alignItems = 'center';
                        currentMonthElement.style.justifyContent = 'center';
                        currentMonthElement.style.width = 'auto';
                        currentMonthElement.style.margin = '0';
                        currentMonthElement.style.padding = '10px 0';
                        currentMonthElement.style.flexDirection = 'column';
                      }
                      const previousMonthElement = document.querySelector('.react-datepicker__navigation--previous');
                      if (previousMonthElement) {
                        previousMonthElement.style.top = '15px';
                      }
                      const nextMonthElement = document.querySelector('.react-datepicker__navigation--next');
                      if (nextMonthElement) {
                        nextMonthElement.style.top = '15px';
                        nextMonthElement.style.border = 'solid #000';
                        nextMonthElement.style.borderWidth = '2px 0px 0px 2px';
                        nextMonthElement.style.transform = 'rotate(135deg)';
                      }
                    }}
                    onCalendarClose={() => {
                      setDisplayCalendarNote(false);
                    }}
                  >
                    {displayCalendarNote && (
                      <p className={cx('calendar-note')}>
                        {localization &&
                          localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_CUSTOM_PERIOD_NOTE]}
                      </p>
                    )}
                  </DatePicker>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className={cx('dropdown-container')}>
            <p className={cx('dropdown-title')}>
              {localization && localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_TITLE]}
            </p>
            <SelectBox
              automationId='account-statement-selection-dropdown'
              deaultIcon
              onChange={val => {
                const option = periodOptions.find(({ value }) => value === val);
                setSelectedPeriod(option);
              }}
              options={periodOptions}
              defaultValue={selectedPeriod.value}
              themeSelectBox={cx('dropdown')}
              arrowTheme={cx('dropdown-arrow')}
            />
            {selectedPeriod.value === 'Custom' && (
              <div className={cx('custom-date-container')}>
                <div style={{ position: 'relative' }}>
                  <p className={cx('dropdown-title')}>
                    {localization && localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_FROM]}
                  </p>
                  <DatePicker
                    locale={getLocale()}
                    showPopperArrow={false}
                    popperClassName={cx('cool-picker')}
                    customInput={<CustomDatePickerInput />}
                    dateFormat='dd/MM/yyyy'
                    calendarMessage='Select a date'
                    selected={startDate}
                    onChange={date => handleStartDate(date)}
                    onCalendarOpen={() => {
                      setDisplayCalendarNote(true);
                      const currentMonthElement = document.querySelector('.react-datepicker__current-month');
                      if (currentMonthElement) {
                        currentMonthElement.style.display = 'flex';
                        currentMonthElement.style.alignItems = 'center';
                        currentMonthElement.style.justifyContent = 'center';
                        currentMonthElement.style.width = 'auto';
                        currentMonthElement.style.margin = '0';
                        currentMonthElement.style.padding = '10px 0';
                        currentMonthElement.style.flexDirection = 'column';
                      }
                    }}
                    onCalendarClose={() => {
                      setDisplayCalendarNote(false);
                    }}
                  >
                    {displayCalendarNote && (
                      <p className={cx('calendar-note')}>
                        {localization &&
                          localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_CUSTOM_PERIOD_NOTE]}
                      </p>
                    )}
                  </DatePicker>
                  {dateError && (
                    <p className={cx('date-error-message')}>
                      {localization &&
                        localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_ERROR_MESSAGE]}
                    </p>
                  )}
                  {customDateError && (
                    <p className={cx('date-error-message')}>
                      {localization &&
                        localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_CUSTOM_PERIOD_ERROR]}
                    </p>
                  )}
                </div>
                <span className={cx('date-picker-divider')}>-</span>
                <div style={{ position: 'relative' }}>
                  <p className={cx('dropdown-title')}>
                    {localization && localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_TO]}
                  </p>
                  <DatePicker
                    showPopperArrow={false}
                    popperClassName={cx('cool-picker')}
                    customInput={<CustomDatePickerInput />}
                    dateFormat='dd/MM/yyyy'
                    calendarMessage='Select a date'
                    selected={endDate}
                    onChange={date => handleEndDate(date)}
                    onCalendarOpen={() => {
                      setDisplayCalendarNote(true);
                      const currentMonthElement = document.querySelector('.react-datepicker__current-month');
                      if (currentMonthElement) {
                        currentMonthElement.style.display = 'flex';
                        currentMonthElement.style.alignItems = 'center';
                        currentMonthElement.style.justifyContent = 'center';
                        currentMonthElement.style.width = 'auto';
                        currentMonthElement.style.margin = '0';
                        currentMonthElement.style.padding = '10px 0';
                        currentMonthElement.style.flexDirection = 'column';
                      }
                    }}
                    onCalendarClose={() => {
                      setDisplayCalendarNote(false);
                    }}
                  >
                    {displayCalendarNote && (
                      <p className={cx('calendar-note')}>
                        {localization &&
                          localization[ACCOUNT_STATEMENTS_L10N.TIME_PERIOD_CUSTOM_PERIOD_NOTE]}
                      </p>
                    )}
                  </DatePicker>
                </div>
              </div>
            )}
          </div>
        )}
      </>
      <div className={cx(isMobile ? 'mobile-button-row' : 'button-row')}>
        <Button
          automationId='account-statement-cancel-button'
          className={cx('cancel-button')}
          type='button'
          size='Md'
          buttonType='Secondary'
          onClick={onClickFunc}
        >
          {localization && localization[ORDERS_L10N.REORDER_POPUP_CANCEL_BUTTON]}
        </Button>
        <Button
          automationId='account-statement-generate-button'
          className={cx('generate-button')}
          type='button'
          size='Md'
          buttonType='Primary'
          onClick={() => {
            handleGenerateStatement();
          }}
        >
          <Icon
            iconType='svg'
            width='20px'
            height='auto'
            viewBox='0 0 20 20'
            name='chevron-down-circle'
            className={cx('generate-icon')}
          />
          {localization && localization[ACCOUNT_STATEMENTS_L10N.STATEMENT_BUTTON]}
        </Button>
      </div>
    </div>
  );
};

export default AccountStatement;
