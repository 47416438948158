import _isEmpty from 'lodash/isEmpty';
import {
    GET_CHECKOUT_DATA_SUCCESS,
    POST_PLACE_ORDER_DATA_SUCCESS,
    POST_PLACE_ORDER_DATA_ERROR,
    CLEAR_REDUCER
} from '../actionTypes/CheckoutActionTypes';

export default function CheckoutReducer(state = {}, action = {}) {
    switch (action.type) {
        case GET_CHECKOUT_DATA_SUCCESS:
            return {
                ...action.data,
                placeOrder: false
            };
        case POST_PLACE_ORDER_DATA_SUCCESS:
            return {
                ...state,
                ...action.data,
                placeOrder: true
            };
        case POST_PLACE_ORDER_DATA_ERROR:
            return {
                ...state,
                placeOrder: false
            };
        case CLEAR_REDUCER:
            return {}
        default:
            return state;
    }
}
