import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { PARAMS } from '../common/Api';

export default class ProductApi {
    static getProductPage(payload) {
        const { pdpId, params = {}, baseInfo } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        const isMarketingMaterials = baseInfo.isMarketingMaterialsMode;
        config.url = `/${config.baseInfo.siteId}/products/${pdpId}`;

        if (config.baseInfo[PARAMS.CART_ID]) {
            params[PARAMS.CART_ID] = config.baseInfo[PARAMS.CART_ID];
        }

        if (isMarketingMaterials) {
            params.useMarketingMaterials = isMarketingMaterials;
        }

        config.params = params;

        return RestClient.get(config).then(json => {
            return json;
        });
    }
}
