import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import Link from 'react-router/lib/Link';
import Layout from '../../../components/site/Layout/Layout';
import * as styles from './MobileAccountPage.css';
import NavLink from '../../../components/core/NavLink/NavLink';
import Image from '../../../components/core/Image/Image';

const cx = classNames.bind(styles);

class MobileAccountPage extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    createListItem = (menuItem, idx) => {
        const { childComponents } = menuItem;

        if (childComponents) {
            return (
                <li
                    className={cx('DropdownMenuItem')}
                    id={`at-account-dropdown-${menuItem.linkUrl}-${idx}`}
                >
                    <p className={cx('MobileAccountMenuLabel')}>
                        {menuItem.linkName}
                    </p>
                    <div>
                        {childComponents.map((child, i) => this.createListItem(child, i))}
                    </div>
                </li>
            )
        }

        return (
            <li id={`at-account-dropdown-${menuItem.linkUrl}-${idx}`}>
                <Link
                    to={menuItem.linkUrl}
                    alt={menuItem.altText}
                >
                    <div className={cx('listItemInnerLevel')}>
                        <Image
                            theme={cx('mobSeconLevelMenuItemLogo')}
                            src={menuItem.imageUrl}
                            alt={menuItem.altText}
                        />
                    </div>
                    <p className={cx('MobileAccountMenuLabel')}>
                        {menuItem.name}
                    </p>
                </Link>
            </li>
        )
    }

    render() {
        const { headerData } = this.props;
        const { mobileMenuList = [] } = headerData;

        return (
            <Layout showDashboardHeader showOutlet theme={cx('homeContainer')}>
                <div className={cx('MobileAccountPageContainer')}>
                    <ul className={cx('MobileAccountPageList')}>
                        {mobileMenuList.map((menuItem, idx) => 
                            this.createListItem(menuItem, idx)
                        )}
                    </ul>
                </div>
            </Layout>
        )
    }
}

const mapStateToProps = ({ pageContent }) => {
    return {
        headerData: pageContent.headerData
    }
}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MobileAccountPage);