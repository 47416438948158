import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { AUTH, PARAMS, DELIVERY_API } from '../common/Api';

export default class DeliveryApi {
    static activateDeliveryType(payload) {
        const { baseInfo = {}, deliveryType } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${DELIVERY_API.ACTIVATE_DELIVERY}/${deliveryType}`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static getPlantsTypeList(payload) {
        const { baseInfo = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${DELIVERY_API.GET_PLANTS}`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static changePlants(payload) {
        const { baseInfo = {}, data } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${
            DELIVERY_API.CHANGE_PLANT
        }`;
        config.data = data;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static getWholesalerList(payload) {
        const { baseInfo = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${DELIVERY_API.WHOLE_SALER}`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static changeWholeSaler(payload) {
        const { baseInfo = {}, data } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${
            DELIVERY_API.CHANGE_WHOLE_SALER
        }/${baseInfo[PARAMS.CART_ID]}`;
        config.data = data;

        return RestClient.post(config).then(json => {
            return json;
        });
    }
}
