export const sk = {
  "sk": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
    "callus": {
      "CallUsTitle": "Zavolejte nám",
      "ContactsHeader": "Můžete nás kontaktovat na kterémkoli z následujících čísel ...",
      "CancelButtonText": "Zrušit",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Already on a call? Let us <a role='link' tabindex='0' class='cx-cobrowse-link'>browse with you</a></span>",
      "CoBrowse": "Sdílet obrazovku",
      "CoBrowseWarning": "Chceme Vám pomoci prostřednictvím Sdílení obrazovky, díky které umožníte vašemu operátorovi vidět a ovládat vaši plochu. Při živém hovoru s operátorem si vyžádejte kód pro spuštění funkce Sdílet obrazovku a zadejte jej níže do řádku. Ještě nejste ve spojení s operátorem? stačí zrušit tuto obrazovku a vrátit se na stránku Zavolejte nám.",
      "SubTitle": "Můžete nás kontaktovat na kterémkoli z následujících čísel ...",
      "AriaWindowLabel": "Call Us Window",
      "AriaCallUsClose": "Call Us Close",
      "AriaBusinessHours": "Pracovní doba",
      "AriaCallUsPhoneApp": "Otevřete mobililní aplikaci",
      "AriaCobrowseLink": "Zahajte sdílení obrazovky",
      "AriaCancelButtonText": "Call Us Cancel"
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector": {
      "Title": "Živá podpora",
      "SubTitle": "Jak Vás můžeme kontaktovat?",
      "WaitTimeTitle": "Čekací doba",
      "AvailableTitle": "Dostupný",
      "AriaAvailableTitle": "Dostupný",
      "UnavailableTitle": "Nedostupný",
      "CobrowseButtonText": "CobrowseSubTitle",
      "CallbackTitle": "Přijměte hovor",
      "CobrowseSubTitle": "K tomu je nutné připojení operátora.",
      "AriaClose": "Ukončit živou podporu",
      "AriaWarning": "Varování",
      "AriaAlert": "Výstraha",
      "minute": "minuta",
      "minutes": "minuty",
      "AriaWindowLabel": "Okno živé asistence"
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse": {
      "agentJoined": "Připojil se k Vám  operátor.",
      "youLeft": "Opustil jste relaci. Sdílení obrazovky bylo ukončeno",
      "sessionTimedOut": "Relace vypršela. Sdílení obrazovky je nyní ukončeno.",
      "sessionInactiveTimedOut": "Relace vypršela. Sdílení obrazovky je nyní ukončeno.",
      "agentLeft": "Operátor opustil relaci. Sdílení obrazovky je nyní ukončeno.",
      "sessionError": "Omlouváme se, ale došlo k neočekávané chybě. Sdílení obrazovky je nyní ukončeno.",
      "sessionStarted": "Bylo zahájeno Sdílení obrazovky. Čekáme na připojení operátora k relaci ...",
      "navRefresh": "Operátor musel obnovit stránku. Vydržte prosím.",
      "navBack": "Operátor stiskl tlačítko 'Zpět'. Došlo k načítání stránky.",
      "navForward": "Operátor stiskl tlačítko 'Vpřed'. Došlo k načítání stránky.",
      "navUrl": "Operátor požádal o navigaci. Došlo k načítání stránky.",
      "navFailed": "Žádost operátora o navigaci se nezdařila.",
      "toolbarContent": "Označení relace {sessionId}",
      "contentMasked": "Obsah je skrytý před operátorem",
      "contentMaskedPartially": "Část obsahu je skrytá před operátorem.",
      "exitBtnTitle": "Ukončete relaci Sdílení obrazovky",
      "areYouOnPhone": "Telefonujete s naším operátorem?",
      "areYouOnPhoneOrChat": "Jste na telefonu nebo chatujete s naším operátorem?",
      "connectBeforeCobrowse": "Abyste mohli pokračovat ve Sdílení obrazovky, musíte být ve spojení s naším operátorem. Zavolejte nám nebo s námi spusťte živý chat a poté znovu spusťte Sdílení obrazovky.",
      "sessionStartedAutoConnect": "Zahájili jste Sdílení obrazovky. Vyčkejte na připojení operátora ...",
      "browserUnsupported": "Omlouváme se, ale Váš internetový prohlížeč není podporován.<br><br> Podporované internetové prohlížeče jsou: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 and above</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 and above</a></li></ul>",
      "chatIsAlreadyRunning": "S velkou pravděpodobností máte Chat otevřený v jiném okně internetového prohlížeče",
      "modalYes": "Ano",
      "modalNo": "Ne"
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar": {
      "SidebarTitle": "Live Chat",
      "ChannelSelectorName": "Živá asistence",
      "ChannelSelectorTitle": "Získejte okamžitou pomoct od našeho operátora",
      "SearchName": "Vyhledávání",
      "SearchTitle": "Vyhledávání",
      "CallUsName": "Zavolejte nám",
      "CallUsTitle": "Zavolejte nám podrobnosti",
      "CallbackName": "Zavolat zpět",
      "CallbackTitle": "Přijměte hovor",
      "ClickToCallName": "Zahajte hovor ",
      "ClickToCallTitle": "Vyžádejte si telefonní číslo zákaznického servisu",
      "SendMessageName": "Poslat zprávu",
      "SendMessageTitle": "Poslat zprávu",
      "WebChatName": "Live Chat",
      "WebChatTitle": "Live Chat",
      "AriaClose": "Prosím ukončete nabídku 'Potřebujete pomoct'"
    },
    "webchat": {
      "ChatButton": "Chat",
      "ChatStarted": "Zahájili jste Chat",
      "ChatEnded": "Chat byl ukončen",
      "AgentNameDefault": "Operátor",
      "AgentConnected": "Dobrý deň, moje meno je <%Agent%>. Ako vám môžem pomôcť?",
      "AgentDisconnected": "<%Agent%> sa odpojil/a",
      "BotNameDefault": "Bot",
      "BotConnected": "<%Bot%> Připojeno",
      "BotDisconnected": "<%Bot%> Odpojeno",
      "SupervisorNameDefault": "Vedoucí",
      "SupervisorConnected": "<%Agent%> Připojeno",
      "SupervisorDisconnected": "<%Agent%> Odpojeno",
      "AgentTyping": "...",
      "AriaAgentTyping": "Operátor píše",
      "AgentUnavailable": "Omlouváme se ale aktuálně nejsou k dispozici žádní opeřátoři. Prosím zkuste to později.",
      "ChatTitle": "Live Chat",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Minuta", 
      "ChatFormFirstName": "Jméno", 
      "ChatFormLastName": "Přijmení", 
      "ChatFormNickname": "Přezdívka", 
      "ChatFormName": "Meno", 
      "ChatFormCustomerId": "Zákaznícke číslo", 
      "ChatFormEmail": "E-mail", 
      "ChatFormSubject": "Predmet", 
      "ChatFormSubjectPassword": "Všeobecná otázka", 
      "ChatFormSubjectService": "Všeobecná otázka",
      "ChatFormSubjectOrder": "Otázka na objednávanie", 
      "ChatFormSubjectFinance": "Faktúry a ceny", 
      "ChatFormPlaceholderFirstName": "Požadované", 
      "ChatFormPlaceholderLastName": "Požadované", 
      "ChatFormPlaceholderName": "Voliteľný", 
      "ChatFormPlaceholderCustomerId": "Voliteľný", 
      "ChatFormPlaceholderNickname": "Volitelné", 
      "ChatFormPlaceholderEmail": "Požadované", 
      "ChatFormPlaceholderSubject": "Volitelné", 
      "ChatFormSubmit": "Začať chat",
      "ChatFormCancel": "Zrušiť",
      "AriaChatFormCancel": "Zrušit Chat",
      "ChatFormClose": "Zavřít",
      "ChatInputPlaceholder": "Zde začněte psát zprávu",
      "ChatInputSend": "Poslat",
      "AriaChatInputSend": "Poslat",
      "ChatEndQuestion": "Opravdu si přejete ukončit relaci?",
      "ChatEndCancel": "Zrušit",
      "ChatEndConfirm": "Ukončit Chat",
      "AriaChatEndCancel": "Zrušit",
      "AriaChatEndConfirm": "Ukončit",
      "ConfirmCloseWindow": "Opravdu si přejete uzavřít Chat?",
      "ConfirmCloseCancel": "Zrušit",
      "ConfirmCloseConfirm": "Zavřít",
      "AriaConfirmCloseCancel": "Zrušit",
      "AriaConfirmCloseConfirm": "Zavřít",
      "ActionsDownload": "Stáhnout přepis",
      "ActionsEmail": "Poslat přepis e-mailem",
      "ActionsPrint": "Vytisknout přepis",
      "ActionsCobrowseStart": "Zahájit Sdílení obrazovky",
      "AriaActionsCobrowseStartTitle": "Otevře relaci Sdílení obrazovky",
      "ActionsSendFile": "Připojit soubory",
      "AriaActionsSendFileTitle": "Otevřte dialogové okno pro nahrání souboru",
      "ActionsEmoji": "Poslat Emodži",
      "ActionsCobrowseStop": "Ukončit Sdílení obrazovky",
      "ActionsVideo": "Pozvat k videochatu",
      "ActionsTransfer": "Převod",
      "ActionsInvite": "Pozvat",
      "InstructionsTransfer": "K přenesení relaci Chatu, otevřete tento odkaz na jiném zařízení</br></br><%link%>",
      "InstructionsInvite": "Sdílejte tento odkaz s jinou osobou a přidejte ji do této relace chatu</br></br><%link%>",
      "InviteTitle": "Potřebujete pomoct?",
      "InviteBody": "Dejte nám vědět, jestli můžeme pomoci.",
      "InviteReject": "Ne, děkuji",
      "InviteAccept": "Zahájit chat",
      "AriaInviteAccept": "Přijměte pozvání a zahajte chat",
      "AriaInviteReject": "Odmítněte pozvání",
      "ChatError": "Omlouváme se, ale při zahájení chatu došlo k problému. Zkuste to prosím znovu.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Ano",
      "ChatErrorDefaultButton": "Ne",
      "AriaChatErrorPrimaryButton": "Ano",
      "AriaChatErrorDefaultButton": "Ne",
      "DownloadButton": "Stáhnout",
      "AriaDownloadButton": "Stáhnout soubor",
      "FileSent": "bylo zasláno:",
      "FileTransferRetry": "Zkuste to znovu",
      "AriaFileTransferRetry": "Opakujte zaslání souboru",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Zrušiť",
      "AriaFileTransferCancel": "Zrušit zasílání souboru",
      "RestoreTimeoutTitle": "Chat byl ukončen",
      "RestoreTimeoutBody": "Vaše předchozí relace chatu vypršela. Chcete zahájit novou?",
      "RestoreTimeoutReject": "Ne, děkuji",
      "RestoreTimeoutAccept": "Začať chat",
      "AriaRestoreTimeoutAccept": "Přijměte pozvání a zahajte chat",
      "AriaRestoreTimeoutReject": "Odmítnout pozvání",
      "EndConfirmBody": "Opravdu chcete ukončit relaci chatu?",
      "EndConfirmAccept": "Ukončit chat",
      "EndConfirmReject": "Zrušit",
      "AriaEndConfirmAccept": "Ukončit chat",
      "AriaEndConfirmReject": "Zrušit",
      "SurveyOfferQuestion": "Chcete se zúčastnit průzkumu?",
      "ShowSurveyAccept": "Ano",
      "ShowSurveyReject": "Ne",
      "AriaShowSurveyAccept": "Ano",
      "AriaShowSurveyReject": "Ne",
      "UnreadMessagesTitle": "nepřečtený",
      "AriaYouSaid": "Řekl jste",
      "AriaSaid": "řekl",
      "AriaSystemSaid": "Systém řekl",
      "AriaWindowLabel": "Live Chat okno",
      "AriaMinimize": "Live Chat Minimalizovat",
      "AriaMaximize": "Live Chat Maximalizovat",
      "AriaClose": "Zavřít Live Chat",
      "AriaEmojiStatusOpen": "Otevře se dialog pro výběr emodži",
      "AriaEmojiStatusClose": "Výběr emodži je uzavřen",
      "AriaEmoji": "emodži",
      "AriaEmojiPicker": "Výběr emodži",
      "AriaCharRemaining": "Zbývající znaky",
      "AriaMessageInput": "Schránka se zprávou",
      "AsyncChatEnd": "Ukončit Chat",
      "AsyncChatClose": "Uzavřít okno",
      "AriaAsyncChatEnd": "Ukončit Chat",
      "AriaAsyncChatClose": "Uzavřít okno",
      "DayLabels": [
        "Ne",
        "Po",
        "Út",
        "St",
        "Čt",
        "Pá",
        "So"
      ],
      "MonthLabels": [
        "Leden",
        "Únor",
        "Březen",
        "Duben",
        "Květen",
        "Červen",
        "Červenec",
        "Srpen",
        "Září",
        "Říjen",
        "Listopad",
        "Prosinec"
      ],
      "todayLabel": "Dnes",
      "Errors": {
        "102": "Jméno je povinné.",
        "103": "Příjmení je povinné.",
        "161": "Zadejte své jméno.",
        "201": "Soubor se nepodařilo odeslat.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Byl by překročen maximální počet připojených souborů (<%MaxFilesAllowed%>).</p>",
        "202": "Soubor se nepodařilo odeslat.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Byl by překročen limit nahrávání a / nebo maximální počet příloh (<%MaxAttachmentsSize%>).</p>",
        "203": "Soubor se nepodařilo odeslat<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Typ souboru není podporován.</p>",
        "204": "Je nám líto, ale vaše zpráva je příliš dlouhá. Napište prosím kratší obsah.",
        "240": "Je nám líto, ale v tuto chvíli nemůžeme zahájit nový chat. Prosím zkuste to znovu později.",
        "364": "Neplatná emailová adresa.",
        "401": "Je nám líto, ale nemůžeme autorizovat relaci chatu. Chcete zahájit nový chat?",
        "404": "Je nám líto, ale nemůžeme najít vaši předchozí relaci chatu. Chcete zahájit nový chat?",
        "500": "Je nám líto, ale došlo k neočekávané chybě. Chcete zavřít a zahájit nový chat?",
        "503": "Je nám líto,  ale systém je momentálně nedostupný. Chcete zavřít a znovu zahájit nový chat?",
        "ChatUnavailable": "Je nám líto, ale v tuto chvíli nemůžeme zahájit nový chat. Prosím zkuste to znovu později.",
        "CriticalFault": "Vaše relace chatu neočekávaně skončila kvůli neznámému problému. Omlouváme se za nepříjemnosti.",
        "StartFailed": "Při zahájení relace chatu došlo k problému. Ověřte prosím své připojení a to, že jste správně zadali všechny požadované informace, a potom to zkuste znovu.",
        "MessageFailed": "Vaše zpráva nebyla úspěšně přijata. Prosím zkuste to znovu.",
        "RestoreFailed": "Je nám líto, ale kvůli neznámé chybě jsme nemohli obnovit vaši relaci chatu.",
        "TransferFailed": "V tuto chvíli nelze chat přenést. Prosím zkuste to znovu později.",
        "FileTransferSizeError": "Soubor se nepodařilo odeslat.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Velikost souboru je větší než povolená velikost (<%MaxSizePerFile%>).</p>",
        "InviteFailed": "V tuto chvíli nelze vygenerovat pozvánku. Prosím zkuste to znovu později.",
        "ChatServerWentOffline": "Zpracování zpráv trvá v současné době déle než obvykle. Omlouváme se za zpoždění.",
        "RestoredOffline": "Zpracování zpráv trvá v současné době déle než obvykle. Omlouváme se za zpoždění.",
        "Disconnected": "<div style='text-align:center'>Spojení ztraceno</div>",
        "Reconnected": "<div style='text-align:center'>Připojení obnoveno</div>",
        "FileSendFailed": "Soubor se nepodařilo odeslat.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>Došlo k neočekávanému odpojení. Zkus to znovu?</p>",
        "Generic": "<div style='text-align:center'>Vyskytla se neočekávaná chyba.</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Neplatný typ souboru. Povoleny jsou pouze obrázky.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "Velikost souboru je větší než povolená velikost.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "Maximální počet připojených souborů překročil limit.",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Neplatná konfigurace přenosu klíče x-api.",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Neplatná konfigurace přenosu koncového bodu.",
        "pureengage-v3-rest-INVALID_NICKNAME": "Jméno je povinné.",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "Je nám líto, ale nemůžeme autorizovat relaci chatu.",
        "purecloud-v2-sockets-400": "Promiň, něco se pokazilo. Ověřte prosím své připojení a to, že jste správně zadali všechny požadované informace, a potom to zkuste znovu.",
        "purecloud-v2-sockets-500": "Je nám líto, u služby došlo k neočekávané chybě.",
        "purecloud-v2-sockets-503": "Je nám líto, služba je momentálně nedostupná."
      }
    }
  }
}
