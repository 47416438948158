import React from 'react';
import classNames from 'classnames/bind';
import styles from './CardsComponent.css';
import { useSelector } from 'react-redux';
import { FSA } from '../../../../common/Constants';
import bulb from '../../../../assets/svg/bulb.svg';

const cx = classNames.bind(styles);

const Card = ({ cardLetter, title, description, subTitle, topTips, tips }) => {
    return (
        <div className={cx('card')}>
            <div className={cx('cardLetter')}>
                <p>{cardLetter}</p>
            </div>
            <div className={cx('topHalf')}>
                <div className={cx('cardTitle')}>{title}</div>
                <div className={cx('cardSubTitle')}>{subTitle}</div>
                <div className={cx('cardDescription')}>{description}</div>
            </div>
            <div className={cx('cardTopTips')}>
                <img src={bulb} alt='Top Tips' />
                <p>{topTips}</p>
            </div>
            <div className={cx('cardTips')}>
                <ul>
                    {tips.map((tip, index) => (
                        <li key={index} className={cx('cardTip')}>
                            {tip}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

const CardsComponent = ({ tipsRef }) => {
    const localization = useSelector(state => state.pageContent.localization);

    const cardsData = [
        {
            cardLetter: localization[FSA.SECTION_THREE_TEXT_3],
            title: localization[FSA.SECTION_THREE_TEXT_4],
            subTitle: localization[FSA.SECTION_THREE_TEXT_5],
            description: localization[FSA.SECTION_THREE_TEXT_6],
            topTips: localization[FSA.SECTION_THREE_TEXT_7],
            tips: [
                localization[FSA.SECTION_THREE_TEXT_8],
                localization[FSA.SECTION_THREE_TEXT_9],
                localization[FSA.SECTION_THREE_TEXT_10]
            ]
        },
        {
            cardLetter: localization[FSA.SECTION_THREE_TEXT_11],
            title: localization[FSA.SECTION_THREE_TEXT_12],
            subTitle: localization[FSA.SECTION_THREE_TEXT_13],
            description: localization[FSA.SECTION_THREE_TEXT_14],
            topTips: localization[FSA.SECTION_THREE_TEXT_15],
            tips: [localization[FSA.SECTION_THREE_TEXT_16], localization[FSA.SECTION_THREE_TEXT_17]]
        },
        {
            cardLetter: localization[FSA.SECTION_THREE_TEXT_18],
            title: localization[FSA.SECTION_THREE_TEXT_19],
            subTitle: localization[FSA.SECTION_THREE_TEXT_20],
            description: localization[FSA.SECTION_THREE_TEXT_21],
            topTips: localization[FSA.SECTION_THREE_TEXT_22],
            tips: [localization[FSA.SECTION_THREE_TEXT_23], localization[FSA.SECTION_THREE_TEXT_24]]
        }
    ];
    return (
        <div className={cx('cardsContainer')} ref={tipsRef}>
            <div className={cx('cardsInner')}>
                <div className={cx('cardsTitle')}>
                    {localization[FSA.SECTION_THREE_TEXT_1]} <span>{localization[FSA.SECTION_THREE_TEXT_2]}</span>
                </div>
                <div className={cx('cards')}>
                    {cardsData.map((card, index) => (
                        <Card key={index} {...card} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CardsComponent;
