import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import {
    GET_PAGE_CONTENT_SUCCESS,
    GET_SELECTED_OUTLET_SUCCESS,
    GET_OUTLET_LIST_SUCCESS,
    GET_STOCK_ALLOCATION_SUCCESS,
    UPDATE_PAGE_CONTENT,
    UPDATE_COMMON_LOCALIZATION,
    CLEAR_PAGE_CONTENT,
    CLEAR_ALL_PAGE_CONTENT,
    SET_PAGE_TITLE_PREFIX,
    LOGIN_CONTENT_SUCCESS,
    SET_CHAT_PLUGIN_LOADED,
} from '../actionTypes/PageContentActionTypes';
import { COUNTRY_CONTENT_SUCCESS } from "../actionTypes/SelectLanguageActionTypes";
import { filterCommonData } from '../service/CommonDataService';

const initialState = {
    catalogID: '',
    catalogVersion: '',
    pageId: '',
    pageUid: '',
    pageUuid: '',
    pageTitle: '',
    pageTitlePrefix: '',
    headerData: {},
    footerData: {},
    mobileMenuData: {},
    localization: {}, // All page specific localization content.
    commonLocalization: {}, // Gerneric component localization content.
    bannerData: {},
    selectedOutlet: {}, // Currently selected outlet.
    outletList: {}, // List of available outlets.
    components: [], // List of page components.
    payload: {}, // Current page content payload used for outlet change call.
    message: {
        isSuccess: false,
        content: '',
        error: {}
    },
    isChatPluginLoaded: false,
};

export default function PageContentReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_PAGE_CONTENT_SUCCESS: {

            const transformedData = filterCommonData(action);
            if (action.pageId) {
                transformedData[action.pageId] = { ...transformedData };
            }
            
            state.pageId = action.pageId;
            state.pageUid = _get(action, 'data.uid');
            state.pageUuid = _get(action, 'data.uuid');
            state.catalogID = _get(action, 'data.catalogID');
            state.catalogVersion = _get(action, 'data.catalogVersion');
            
            return {
                ...state,
                ...transformedData,
                // Only the home page data returns the menu list and it is being overridden by other pages
                ...(state.mobileMenuData.menuList
                    && {mobileMenuData: state.mobileMenuData}
                    || {})
            };
        }

        case UPDATE_PAGE_CONTENT: {
            return {
                ...state,
                ...action.data
            };
        }

        case UPDATE_COMMON_LOCALIZATION: {
            const transformedData = filterCommonData(action);
            const pageContent = {};
            const commonLocalization = { ...state.commonLocalization };

            if (action.parentPageId) {
                pageContent[action.parentPageId] = { ...transformedData };
            }

            if (action.pageId) {
                commonLocalization[action.pageId] = { ...transformedData.localization };
            }

            return {
                ...state,
                ...pageContent,
                commonLocalization
            };
        }

        case SET_PAGE_TITLE_PREFIX: {
            return {
                ...state,
                pageTitlePrefix: action.payload || ''
            };
        }

        case CLEAR_PAGE_CONTENT: {
            const newState = _cloneDeep({...state})
            action.payload.forEach(pageId => {
                delete newState[pageId];
            });
            return newState;
        }

        case CLEAR_ALL_PAGE_CONTENT: {
            return {
                ...initialState,
                isChatPluginLoaded: state.isChatPluginLoaded
            };
        }

        case GET_SELECTED_OUTLET_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                selectedOutlet: { products: [], ...data }
            };
        }

        case GET_STOCK_ALLOCATION_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                stockAllocation: { products: [], ...data }
            };
        }

        case GET_OUTLET_LIST_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                outletList: data
            };
        }

        case LOGIN_CONTENT_SUCCESS:
        case COUNTRY_CONTENT_SUCCESS: {
            state.pageId = action.pageId;
            state.pageUid = _get(action, 'data.uid');
            state.pageUuid = _get(action, 'data.uuid');
            state.catalogID = _get(action, 'data.catalogID');
            state.catalogVersion = _get(action, 'data.catalogVersion');

            return { ...state };
        }
        
        case SET_CHAT_PLUGIN_LOADED: {
            return {
                ...state,
                isChatPluginLoaded: true
            }
        }

        default:
            return state;
    }
}
