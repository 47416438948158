/* eslint-disable import/prefer-default-export */
import React from 'react';
import classNames from 'classnames/bind';
import styles from './MarketingComponent.css';
import { useSelector } from 'react-redux';
import { FSA, FSA_IMAGES } from '../../../../common/Constants';
import { redirectTo } from '../../../../common/Utils/Utils';
import Button from '../../../../components/core/Button/Button';

const cx = classNames.bind(styles);

export const MarketingComponent = () => {
    const components = useSelector(state => state.pageContent.components);
    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);
    const marketingImage1 = components
        .find(component => component.uid === FSA_IMAGES.MARKETING_IMAGE_1)
        ?.url?.replace('"', '');
    const marketingImage2 = components
        .find(component => component.uid === FSA_IMAGES.MARKETING_IMAGE_2)
        ?.url?.replace('"', '');
    // Function to recursively search for the component by uid
    const findComponentByUid = (components, uid) => {
        if (!components) {
            return null;
        }
        let foundComponent = null;
        components.some((component) => {
            if (component.uid === uid) {
                foundComponent = component;
                return true;
            }
            if (component.childComponents) {
                foundComponent = findComponentByUid(component.childComponents, uid);
                if (foundComponent) {
                    return true;
                }
            }
            return false;
        });
        return foundComponent;
    }

    const marketingButtonComponent = findComponentByUid(components, FSA.TIPS_CALL_TO_ACTION_LINK);
    const marketingButtonText = marketingButtonComponent?.name;
    const marketingButtonUrl = marketingButtonComponent?.url;
    const marketingTextComponent = components.find(component => component.uid === FSA.TIPS_MARKETING_CAMPAIGN);
    const marketingTextTitle = marketingTextComponent?.localizedName;
    const marketingTextContent = marketingTextComponent?.content;

    return (
        <div className={cx('marketingContainer')}>
            <div className={cx('marketingSubContainer')}>
                <div className={cx('marketingImageContainer')}>
                    {marketingImage1 && (
                        <img
                            className={cx('marketingImage')}
                            src={`${assetBaseUrl}${marketingImage1}`}
                            alt='Marketing 1'
                        />
                    )}
                    {marketingImage2 && (
                        <img
                            className={cx('marketingImage')}
                            src={`${assetBaseUrl}${marketingImage2}`}
                            alt='Marketing 2'
                        />
                    )}
                </div>
                {marketingTextComponent && marketingButtonComponent && (
                    <div className={cx('marketingTextContainer')}>
                        <p className={cx('marketingTextTitle')}>{marketingTextTitle}</p>
                        <p className={cx('marketingTextContent')}>{marketingTextContent}</p>
                        <Button
                            automationId='fsa-marketing-button'
                            className={cx('marketingButton')}
                            type='button'
                            size='md'
                            buttonType='Primary'
                            onClick={() => {
                                redirectTo(marketingButtonUrl);
                            }}
                        >
                            {marketingButtonText}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};
