export const ACTIVATE_DELIVERY_TYPE_REQUEST = 'ACTIVATE_DELIVERY_TYPE_REQUEST';
export const GET_PLANT_TYPE = 'GET_PLANT_TYPE';
export const GET_PLANT_TYPE_SUCCESS = 'GET_PLANT_TYPE_SUCCESS';
export const GET_SELECTED_PLANT_TYPE_REQUEST = 'GET_SELECTED_PLANT_TYPE_REQUEST';
export const UPDATE_DELIVERY_TYPE = 'UPDATE_DELIVERY_TYPE';
export const GET_WHOLE_SALER = 'GET_WHOLE_SALER';
export const GET_WHOLE_SALER_SUCCESS = 'GET_WHOLE_SALER_SUCCESS';
export const GET_SELECTED_WHOLE_SALER_REQUEST = 'GET_SELECTED_WHOLE_SALER_REQUEST';
export const UPDATE_WHOLESALER_OUTLET = 'UPDATE_WHOLESALER_OUTLET';
export const SET_DELIVERY_DATE = 'SET_DELIVERY_DATE';
export const DELIVERY_ERROR = 'DELIVERY_ERROR';
