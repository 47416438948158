import { call, put, takeLatest } from 'redux-saga/effects';
import FactorySaga from './FactorySaga';
import MySalesApi from '../interfaces/MySales/MySalesApi';
import { GET_POWER_BI_TOKEN, GET_POWER_BI_TOKEN_SUCCESS, GET_POWER_BI_TOKEN_ERROR } from '../actionTypes/MySalesActionTypes';

function* getPowerBiTokenFunc(action) {
    try {
        const result = yield call(FactorySaga, MySalesApi.getPowerBiToken, action);
        if (result.isSuccess) {
            yield put({ type: GET_POWER_BI_TOKEN_SUCCESS, data: { ...result.response.data } });
        } else {
            yield put({ type: GET_POWER_BI_TOKEN_ERROR });
        }
    } catch (err) {
        console.error(err);
        yield put({ type: GET_POWER_BI_TOKEN_ERROR });
    }
}

// watch for GET_POWER_BI_TOKEN action to trigger the MySales saga
export default function* watchGetPowerBIToken() {
    yield takeLatest(GET_POWER_BI_TOKEN, getPowerBiTokenFunc);
}
