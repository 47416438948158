import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { INVOICES_L10N } from '../../../common/Constants';
import * as customStyles from './CustomSelect.css';
import Icon from '../Icon/Icon';
import '../assets/styles/base/base.css';

const cz = classNames.bind(customStyles);

/* If you're passing down options to this reusable component, and the user has the ability to select all from the options, ensure your 'all' option value has the string "all" within it. Also make sure you're passing down an array of objects with keys value and label for the defaultOption. Value must be an array as well. For options, you can also include a className object key, but it's not obligatory. You must also pass down selectedOptions and setSelectedOptions from parent, or else side-effects can cause the Checkbox component to become unchecked even if the value array hasn't changed */

const CustomSelect = ({
    options,
    value,
    defaultOption,
    placeholder,
    onChangeFunc,
    selectedOptions,
    setSelectedOptions,
    onOpen
}) => {
    const localization = useSelector(state => state.pageContent.localization);

    const [isOpen, setIsOpen] = useState(false);

    const customSelectRef = useRef(null);

    const handleOnBlur = event => {
        const isChildOfNotificationCenter = customSelectRef.current.contains(event.relatedTarget);
        if (isChildOfNotificationCenter) {
            return;
        }
        setIsOpen(false);
    };

    const toggleOption = option => {
        // handle the case when user clicks on "All" option in the dropdown, or something similar
        if (option.value.toLowerCase().includes('all')) {
            if (selectedOptions.length > 0 && selectedOptions.some(item => item.value.toLowerCase().includes('all'))) {
                setSelectedOptions([]);
            } else {
                setSelectedOptions(options);
            }
            return;
        }
        const existingOption = selectedOptions.find(item => item.value === option.value);
        if (existingOption) {
            setSelectedOptions(
                selectedOptions.filter(item => item.value !== option.value && !item.value.toLowerCase().includes('all'))
            );
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
    };

    // If all options are selected, select "All" option automatically if it exists. If none are selected, select the defaultOption if it exists
    useEffect(() => {
        if (
            selectedOptions.length === options.filter(opt => !opt.value.toLowerCase().includes('all')).length &&
            options.some(opt => opt.value.toLowerCase().includes('all'))
        ) {
            setSelectedOptions([...selectedOptions, options.filter(opt => opt.value.toLowerCase().includes('all'))[0]]);
        }
        if (selectedOptions.length === 0 && defaultOption && defaultOption.length > 0) {
            setSelectedOptions(defaultOption);
        }
    }, [selectedOptions]);

    // Logic to determine what to show in the dropdown on initial render
    const initialDisplayed = useMemo(() => {
        const getInitialDisplayed = (defaultOption, placeholder) => {
            if (defaultOption && defaultOption.length > 0) {
                const hasAll = defaultOption.some(
                    option => option.value.includes('all') || option.label.includes('all')
                );
                if (defaultOption.length === 1 && !hasAll) {
                    return defaultOption[0]?.label;
                }
                if (defaultOption.length === 1 && hasAll) {
                    return `${options.length - 1} ${localization && localization[INVOICES_L10N.FILTER_SELECTED]}`;
                }
                if (defaultOption.length > 1 && hasAll) {
                    return `${defaultOption.length - 1} ${localization && localization[INVOICES_L10N.FILTER_SELECTED]}`;
                }
                if (defaultOption.length > 1 && !hasAll) {
                    return `${defaultOption.length} ${localization && localization[INVOICES_L10N.FILTER_SELECTED]}`;
                }
            } else {
                return placeholder;
            }
        };
        return getInitialDisplayed(defaultOption, placeholder);
    }, [defaultOption, placeholder, value]);

    const displayFormattedValues = valueArray => {
        const hasAll = valueArray.length > 0 && valueArray.some(option => option.includes('all'));
        if (valueArray.length === 1 && !hasAll) {
            return selectedOptions[0]?.label;
        }
        if (valueArray.length === 1 && hasAll) {
            return `${options.length - 1} ${localization && localization[INVOICES_L10N.FILTER_SELECTED]}`;
        }
        if (valueArray.length > 1 && hasAll) {
            return `${valueArray.length - 1} ${localization && localization[INVOICES_L10N.FILTER_SELECTED]}`;
        }
        if (valueArray.length > 1 && !hasAll) {
            return `${valueArray.length} ${localization && localization[INVOICES_L10N.FILTER_SELECTED]}`;
        }
    };

    return (
        <div tabIndex={-1} className={cz('container')} ref={customSelectRef} onBlur={handleOnBlur}>
            <div
                className={cz('custom-select')}
                role='presentation'
                onClick={() => {
                    const newValue = !isOpen;
                    setIsOpen(newValue);

                    if (newValue && typeof onOpen === 'function') {
                        onOpen();
                    }
                }}
            >
                <p className={cz('placeholder')} style={{ opacity: selectedOptions.length === 0 ? 0.6 : 1 }}>
                    {value && value.length > 0 ? displayFormattedValues(value) : initialDisplayed}
                </p>
                <Icon
                    className={cz('arrowTheme')}
                    iconType='svg'
                    width='14px'
                    height='14px'
                    viewBox='0 0 14 14'
                    name='down'
                />
            </div>
            {isOpen && (
                <div tabIndex={-1} className={cz('options-col')}>
                    {options.map(option => (
                        <div
                            role='presentation'
                            className={cz('custom-select-option', option?.className || '')}
                            key={option.value}
                            onClick={e => {
                                e.stopPropagation();
                                onChangeFunc(option);
                                toggleOption(option);
                            }}
                        >
                            <p>{option.label}</p>
                            <label
                                role='presentation'
                                onClick={e => {
                                    e.stopPropagation();
                                    onChangeFunc(option);
                                    toggleOption(option);
                                }}
                                htmlFor={option.value}
                                className={cz('checkbox-label')}
                            >
                                <input
                                    type='checkbox'
                                    className={cz('checkbox-flex', 'checkbox-black')}
                                    id={option.value}
                                    checked={
                                        selectedOptions.length > 0 &&
                                        selectedOptions.some(opt => opt.value === option.value)
                                    }
                                />
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomSelect;
