export const bg = {
  "bg": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
    "callus": {
      "CallUsTitle": "Обадете ни се",
      "ContactsHeader": "Можете да се свържете с нас на 0700 17 117 или 02 467 2000 от понеделник до петък  08:00 - 17:30 и събота 08:00 - 16:00.",
      "CancelButtonText": "Отказ",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Already on a call? Let us <a role='link' tabindex='0' class='cx-cobrowse-link'>browse with you</a></span>",
      "CoBrowse": "Започнете съвместно сърфиране",
      "CoBrowseWarning": "Съвместното сърфиране позволява на обслужващият Ви агент да има достъп и контрол върху Вашия десктоп, да Ви насочва и подпомага ефективно. За да започнете съвместно сърфиране, по време на разговор с агент, поискайте код и го въведете по-долу. Ако все още не провеждате разговор, затворете този екран и се върнете към страницата \"Обадете ни се\".",
      "SubTitle": "Можете да се свържете с нас на 0700 17 117 или 02 467 2000 от понеделник до петък  08:00 - 17:30 и събота 08:00 - 16:00",
      "AriaWindowLabel": " Прозорец: \"Обадете ни се\"",
      "AriaCallUsClose": "Приключване на разговор",
      "AriaBusinessHours": "Работно време",
      "AriaCallUsPhoneApp": "Отваряне на приложение за разговор",
      "AriaCobrowseLink": "Отваряне на приложение за съвместно сърфиране",
      "AriaCancelButtonText": "Отказ от обаждане"
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector": {
      "Title": "Съдействие от агент",
      "SubTitle": "Възможности за свързване:",
      "WaitTimeTitle": "Изчакване",
      "AvailableTitle": "На разположение",
      "AriaAvailableTitle": "На разположение",
      "UnavailableTitle": "Не е на линия",
      "CobrowseButtonText": "CobrowseSubTitle",
      "CallbackTitle": "Получаване на обаждане",
      "CobrowseSubTitle": "Необходима е връзка с агент.",
      "AriaClose": "Прилкючване \"Съдействие от агент\"",
      "AriaWarning": "Внимание",
      "AriaAlert": "Напомняне",
      "minute": "минута",
      "minutes": "минути",
      "AriaWindowLabel": "Прозорец \"Съдействие от агент\""
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse": {
      "agentJoined": "Агент се присъедини към сесията.",
      "youLeft": "Напуснахте сесията. Съвместното сърфиране е прекратено.",
      "sessionTimedOut": "Сесията изтече. Съвместното сърфиране е прекратено.",
      "sessionInactiveTimedOut": "Сесията изтече. Съвместното сърфиране е прекратено.",
      "agentLeft": "Агентът напусна сесията. Съвместното сърфиране е прекратено.",
      "sessionError": "Възникна неочаквана грешка. Съвместното сърфиране е прекратено.",
      "sessionStarted": "Сесията за съвместно сърфиране започна. Изчаква се агент да се присъедини..",
      "navRefresh": "Агентът презареди страницата. Моля, изчакайте.",
      "navBack": "Агентът избра бутон \"Назад\". Моля, изчакайте зареждане на страницата.",
      "navForward": "Агентът избра бутон \"Напред\". Моля, изчакайте зареждане на страницата.",
      "navUrl": "Агентът пое навигацията.  Моля, изчакайте зареждане на страницата..",
      "navFailed": "Заявката за контрол навигацията е неуспешна.",
      "toolbarContent": "Сесия ID: {sessionId}",
      "contentMasked": "Съдържанието е скрито от агента.",
      "contentMaskedPartially": "Част от съдържанието е скрито от Агента.",
      "exitBtnTitle": "Изход от сесията за съвместно сърфиране",
      "areYouOnPhone": "В разговор ли сте с наш агент?",
      "areYouOnPhoneOrChat": "В разговор ли сте или в чат с наш агент?",
      "connectBeforeCobrowse": "Трябва да сте свързани с наш агент, за да продължите към съвместно сърфиране. Моля, обадете ни се или започнете Live chat, за да стартира отново съвместно сърфиране.",
      "sessionStartedAutoConnect": "Сесията за съвместно сърфиране започна. Изчакване за присъединяване на агент...",
      "browserUnsupported": "За съжаление вашият браузър е несъвместим.<br><br> Поддържаните браузъри са: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 and above</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 and above</a></li></ul>",
      "chatIsAlreadyRunning": "Чатът вече е активен на друга страница.",
      "modalYes": "Да",
      "modalNo": "Не"
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar": {
      "SidebarTitle": "Нуждаете се от помощ?",
      "ChannelSelectorName": "Помощ на живо",
      "ChannelSelectorTitle": "Потърсете помощ от наш агент веднага.",
      "SearchName": "Търсене",
      "SearchTitle": "Търсене",
      "CallUsName": "Обадете ни се",
      "CallUsTitle": "Обадете ни се, детайли",
      "CallbackName": "Обратно обаждане",
      "CallbackTitle": "Получаване на обаждане",
      "ClickToCallName": "Кликнете за обаждане",
      "ClickToCallTitle": "Заявете телефонен номер за обслужване на клиенти",
      "SendMessageName": "Изпратете съобщение",
      "SendMessageTitle": "Изпратете съобщение",
      "WebChatName": "Live Chat",
      "WebChatTitle": "Live Chat",
      "AriaClose": "Затвори менюто \"Нуждаеш се от помощ?\""
    },
    "webchat": {
      "ChatButton": "Чат",
      "ChatStarted": "Чатът започна",
      "ChatEnded": "Чатът завърши",
      "AgentNameDefault": "Агент",
      "AgentConnected": "<%Agent%> Агент на линия",
      "AgentDisconnected": "<%Agent%> Агент извън линия",
      "BotNameDefault": "Bot",
      "BotConnected": "<%Bot%> Свързан",
      "BotDisconnected": "<%Bot%> Чатът е прекратен.",
      "SupervisorNameDefault": "Чатът е прекратен.",
      "SupervisorConnected": "Агент на линия",
      "SupervisorDisconnected": "<%Agent%> Чатът е прекратен.",
      "AgentTyping": "...",
      "AriaAgentTyping": "Агентът пише",
      "AgentUnavailable": "Съжаляваме, в момента няма свободен оператор. Моля опитайте по-късно.",
      "ChatTitle": "Live chat",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Смали",
      "ChatFormFirstName": "Име",
      "ChatFormLastName": "Фамилия",
      "ChatFormNickname": "Псевдоним",
      "ChatFormName": "Име",
      "ChatFormCustomerId": "CCH клиентски номер",
      "ChatFormEmail": "Имейл",
      "ChatFormSubject": "Тема",
      "ChatFormPlaceholderFirstName": "Задължително",
      "ChatFormPlaceholderLastName": "Задължително",
      "ChatFormPlaceholderName": "По избор",
      "ChatFormPlaceholderCustomerId": "По избор",
      "ChatFormSubjectPassword": "Парола",
      "ChatFormSubjectFinance": "Финанси",
      "ChatFormSubjectService": "Услуга",
      "ChatFormSubjectOrder": "Поръчка",
      "ChatFormPlaceholderNickname": "По избор",
      "ChatFormPlaceholderEmail": "Задължително",
      "ChatFormPlaceholderSubject": "",
      "ChatFormSubmit": "Започнете разговор",
      "AriaChatFormSubmit": "Започнете разговор",
      "ChatFormCancel": "Отказ",
      "AriaChatFormCancel": "Отказ",
      "ChatFormClose": "Затвори",
      "ChatInputPlaceholder": "Напишете съобщението си тук",
      "ChatInputSend": "Изпрати",
      "AriaChatInputSend": "Изпрати",
      "ChatEndQuestion": "Наистина ли искате да прекратите тази чат сесия?",
      "ChatEndCancel": "Отказ",
      "ChatEndConfirm": "Край на чата",
      "AriaChatEndCancel": "Отказ",
      "AriaChatEndConfirm": "Край",
      "ConfirmCloseWindow": "Наистина ли искате да затворите чата?",
      "ConfirmCloseCancel": "Отказ",
      "ConfirmCloseConfirm": "Затвори",
      "AriaConfirmCloseCancel": "Отказ",
      "AriaConfirmCloseConfirm": "Затвори",
      "ActionsDownload": "Download transcript",
      "ActionsEmail": "Изпрати запис",
      "ActionsPrint": "Разпечатай запис",
      "ActionsCobrowseStart": "Започни съвместно сърфиране",
      "AriaActionsCobrowseStartTitle": "Opens the Co-browse session",
      "ActionsSendFile": "Приложи файлове",
      "AriaActionsSendFileTitle": "Opens a file upload dialog",
      "ActionsEmoji": "Изпрати Emoji",
      "ActionsCobrowseStop": "Изход от съвместното сърфиране",
      "ActionsVideo": "Покани към видео чат",
      "ActionsTransfer": "Прехвърляне",
      "ActionsInvite": "Покани",
      "InstructionsTransfer": "Отворете тази връзка на друго устройство, за да прехвърлите чат сесията</br></br><%link%>",
      "InstructionsInvite": "Споделете тази връзка с друг , за да го добавите към този чат</br></br><%link%>",
      "InviteTitle": "Нуждаете се от помощ?",
      "InviteBody": "Потърсете ни, ако се нуждаете от помощ.",
      "InviteReject": "Не, благодаря",
      "InviteAccept": "Започнете чат",
      "AriaInviteAccept": "Приемете поканата и започнете чат",
      "AriaInviteReject": "Отказ на поканата",
      "ChatError": "Възникна проблем при стартиране на чат сесията. Моля, опитайте отново.",
      "ChatErrorButton": "ОК",
      "AriaChatErrorButton": "ОК",
      "ChatErrorPrimaryButton": "Да",
      "ChatErrorDefaultButton": "Не",
      "AriaChatErrorPrimaryButton": "Да",
      "AriaChatErrorDefaultButton": "Не",
      "DownloadButton": "Изтегли",
      "AriaDownloadButton": "Изтегли файла",
      "FileSent": "Изпратено",
      "FileTransferRetry": "Опитайте отново",
      "AriaFileTransferRetry": "Опитайте отново прехвърлянето на файлове",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Отказ",
      "AriaFileTransferCancel": "Отказ от прехвърляне на файлове",
      "RestoreTimeoutTitle": "Чатът завърши",
      "RestoreTimeoutBody": "Предишната чат сесия изтече. Желаете ли да стартирате нова сесия?",
      "RestoreTimeoutReject": "Не, благодаря",
      "RestoreTimeoutAccept": "Започнете чат",
      "AriaRestoreTimeoutAccept": "Приемане на поканата и стартиране на чат.",
      "AriaRestoreTimeoutReject": "Отказ на поканата",
      "EndConfirmBody": "Сигурни ли сте, че искате да приключите чат сесията?",
      "EndConfirmAccept": "Край на чата",
      "EndConfirmReject": "Отказ",
      "AriaEndConfirmAccept": "Край на чата",
      "AriaEndConfirmReject": "Отказ",
      "SurveyOfferQuestion": "Желаете ли да участвате в анкета?",
      "ShowSurveyAccept": "Да",
      "ShowSurveyReject": "Не",
      "AriaShowSurveyAccept": "Да",
      "AriaShowSurveyReject": "Не",
      "UnreadMessagesTitle": "Непрочетени",
      "AriaYouSaid": "Вие",
      "AriaSaid": "Отговор",
      "AriaSystemSaid": "Системен отговор",
      "AriaWindowLabel": "Прозорец Live Chat",
      "AriaMinimize": "Минимизирай Live chat",
      "AriaMaximize": "Увеличи Live chat",
      "AriaClose": "Затвори Live chat",
      "AriaEmojiStatusOpen": "Отворен е прозорец за избор на Emoji",
      "AriaEmojiStatusClose": "Затворен е прозореца за избор на Emoji",
      "AriaEmoji": "Emoji",
      "AriaEmojiPicker": "Избор на Emoji",
      "AriaCharRemaining": "Оставащи символи",
      "AriaMessageInput": "Кутия за съобщения",
      "AsyncChatEnd": "Край на чата",
      "AsyncChatClose": "Затваряне на прозореца",
      "AriaAsyncChatEnd": "Край на чата",
      "AriaAsyncChatClose": "Затваряне на прозореца",
      "DayLabels": [
        "неделя",
        "понеделник",
        "вторник",
        "сряда",
        "четвъртък",
        "петък",
        "събота"
      ],
      "MonthLabels": [
        "януари",
        "февруари",
        "март",
        "април",
        "май",
        "юни",
        "юли",
        "август",
        "септември",
        "октомври",
        "ноември",
        "декември"
      ],
      "todayLabel": "днес",
      "Errors": {
        "102": "Името е задължително.",
        "103": "Фамилията е задължителна.",
        "161": "Моля за име.",
        "201": "Файлът не може да бъде изпратен.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Максималният брой прикачени файлове е надвишен (<%MaxFilesAllowed%>).</p>",
        "202": "Файлът не може да бъде изпратен.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Надвишен е лимитът за размер на файл или максималният брой прикачени файлове (<%MaxAttachmentsSize%>).</p>",
        "203": "Файлът не може да бъде изпратен.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Типът на файла не е разрешен.</p>",
        "204": "Съжаляваме, но съобщението е твърде дълго. Моля, напишете по-кратко съобщение.",
        "240": "За съжаление в момента не можем да започнем нов чат. Моля, опитайте отново по-късно.",
        "364": "Невалиден имейл.",
        "401": "Съжаляваме, но не сме в състояние да потвърдим чат сесията. Желаете ли да започнете нов чат?",
        "404": "Съжаляваме, но не можем да намерим предишната ви чат сесия. Желаете ли да започнете нов чат?",
        "500": "Съжаляваме, възникна неочаквана грешка с услугата. Желаете ли да затворите и да стартирате нов чат?",
        "503": "За съжаление услугата в момента е натоварена. Желаете ли да затворите и да стартирате нов чат?",
        "ChatUnavailable": "За съжаление в момента не можем да започнем нов чат. Моля, опитайте отново по-късно.",
        "CriticalFault": "Чат сесията приключи неочаквано поради възникнал проблем. Извиняваме се за неудобството.",
        "StartFailed": "Възникна проблем при стартирането на чат сесията. Моля, проверете връзката си, дали необходимата информация е подадена правилно и опитайте отново.",
        "MessageFailed": "Съобщението не е получено. Моля, опитайте отново.",
        "RestoreFailed": "За съжаление не успяхме да възстановим чат сесията поради възникнала грешка.",
        "TransferFailed": "В момента чатът не може да се прехвърли. Моля, опитайте отново по-късно.",
        "FileTransferSizeError": "Файлът не можа да бъде изпратен.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Размерът на файла е по-голям от разрешения (<%MaxSizePerFile%>).</p>",
        "InviteFailed": "В момента не може да се стартира сесия. Моля, опитайте отново по-късно.",
        "ChatServerWentOffline": "В момента изпращането на съобщения отнема повече време. Съжаляваме за забавянето!",
        "RestoredOffline": "В момента изпращането на съобщения отнема повече време. Съжаляваме за забавянето!",
        "Disconnected": "<div style='text-align:center'>Връзката е прекъсната</div>",
        "Reconnected": "<div style='text-align:center'>Връзката е възстановена</div>",
        "FileSendFailed": "Файлът не може да бъде изпратен.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>Настъпи неочаквано прекъсване. Желаете ли да опитате отново?</p>",
        "Generic": "<div style='text-align:center'>Възникна неочаквана грешка.</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Невалиден тип на файл. Разрешени са само изображения.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "Размерът на файла е по-голям от разрешения.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "Максималният брой прикачени файлове е надвишен.",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Invalid x-api-key transport configuration.",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Invalid endpoint transport configuration.",
        "pureengage-v3-rest-INVALID_NICKNAME": "Потребителското име е задължително.",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "Съжаляваме, но в момента нямаме възможност да потвърдим чат сесията.",
        "purecloud-v2-sockets-400": "Съжаляваме, но възникна грешка. Моля, проверете връзката си, дали необходимата информация е подадена правилно и опитайте отново.",
        "purecloud-v2-sockets-500": "Съжаляваме, възникна неочаквана грешка с услугата.",
        "purecloud-v2-sockets-503": "За съжаление услугата в момента не е достъпна."
      }
    }
  }
}
