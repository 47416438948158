import React, { lazy } from 'react';
import Route from 'react-router/lib/Route';
import Redirect from 'react-router/lib/Redirect';
import AppGuard from './components/site/AppGuard/AppGuard';
import Localization from './containers/Pages/Localization/LocalizationLazy';
import LoginPage from './containers/Pages/Login/LoginPage';
import PreLoginPage from './containers/Pages/PreLogin/PreLoginPage';
import Home from './containers/Pages/Home/Home';
import ProductDetail from './containers/Pages/PDP/ProductDetail';
import OrderDetail from './containers/Pages/OrderDetail/OrderDetail';
import OrderList from './containers/Pages/OrderList/OrderList';
import ProductList from './containers/Pages/ProductList/ProductList';
import OrderTemplate from './containers/Pages/OrderTemplate/OrderTemplate';
import OrderTemplateDetails from './containers/Pages/OrderTemplateDetails/OrderTemplateDetails';
import Invoices from './containers/Pages/Invoices/Invoices';
import Cart from './containers/Pages/Cart/Cart';
import Promotions from './containers/Pages/Promotions/Promotions';
import OrderConfirm from './containers/Pages/OrderConfirm/OrderConfirm';
import Checkout from './containers/Pages/Checkout/Checkout';
import PromotionsCalendar from './containers/Pages/PromotionsCalendar/PromotionsCalendar';
import ClaimsHistory from './containers/Pages/ClaimsHistory/ClaimsHistory';
import { URL_PATHS } from './common/Constants';
import { logout } from './common/UserManager/UserManager';
import CoolerDashboard from './containers/Pages/CoolerDashboard/CoolerDashboard';
import Campaigns from './containers/Pages/Campaigns/Campaigns';
import MobileAccountPage from './containers/Pages/MobileAccountPage/MobileAccountPage';
import MyAccount from './containers/Pages/MyAccount/MyAccount';
import CookieListPage from './containers/Pages/CookieListPage/CookieListPage';
import { AccountMyServices } from './containers/Pages/AccountMyServices/AccountMyServices';
import OrderInformation from './containers/Pages/OrderInformation/OrderInformation';
import { AccountSettings } from './containers/Pages/AccountSettings/AccountSettings';
import { ShoppingList } from './containers/Pages/AccountShoppingList/AccountShoppingList';
import MyRewards from './containers/Pages/MyRewards/MyRewards';
import FoodServiceAggregator from './containers/Pages/FoodServiceAggregator/FoodServiceAggregator';
import ReportPowerBI from './containers/Pages/ReportPowerBI/ReportPowerBI';

// Lazy loading of page components configured here.
const UpdatePassword = lazy(() =>
    import(
        /* webpackChunkName: "UpdatePassword", webpackPrefetch: true */ './containers/Pages/UpdatePassword/UpdatePassword'
    )
);
const MySales = lazy(() =>
    import(
        /* webpackChunkName: "MySales", webpackPrefetch: true */ './containers/Pages/MySales/MySales'
    )
);
const AccountData = lazy(() =>
    import(/* webpackChunkName: "AccountData", webpackPrefetch: true */ './containers/Pages/AccountData/AccountData')
);
const UserMaintenance = lazy(() =>
    import(
        /* webpackChunkName: "UserMaintenance", webpackPrefetch: true */ './containers/Pages/UserMaintenance/UserMaintenance'
    )
);
const FAQ = lazy(() => import(/* webpackChunkName: "FAQ" */ './containers/Pages/FAQ/FAQ'));
const Imprint = lazy(() => import(/* webpackChunkName: "Imprint" */ './containers/Pages/Imprint/Imprint'));
const ErrorPage = lazy(() => import(/* webpackChunkName: "ErrorPage" */ './containers/Pages/ErrorPage/ErrorPage'));

const routes = (
    <Route>
        <Route path={URL_PATHS.LOCALIZATION} component={Localization} />
        <Redirect exact from='/l10n' to={URL_PATHS.LOCALIZATION} />
        <Route path={URL_PATHS.COOKIE_LIST} component={CookieListPage} />
        <Route path={URL_PATHS.REPORT_POWER_BI} component={ReportPowerBI} />

        {/* Existing Storefront URL should be redirected to Home Page. */}
        <Redirect from={URL_PATHS.STORE_FRONT} to={URL_PATHS.HOME} />
        {/* For Forgot/Reset Password pages, no need to route via AppGaurd */}
        <Route path={`${URL_PATHS.LOGIN}/:tmplType`} component={LoginPage} />
        <Route path={URL_PATHS.LOGIN} component={AppGuard(LoginPage)} />
        <Route path={URL_PATHS.PRE_LOGIN} component={PreLoginPage} />
        <Redirect exact from='/' to={URL_PATHS.HOME} />
        <Route path={URL_PATHS.HOME} component={AppGuard(Home)} />
        <Route path={`${URL_PATHS.PLP}/:plpType(/:filterTypes/:filterValues)`} component={AppGuard(ProductList)} />
        <Route path={`${URL_PATHS.PDP}/:productId`} component={AppGuard(ProductDetail)} />
        <Route path={URL_PATHS.ORDER_TEMPLATE} component={AppGuard(OrderTemplate)} />
        <Route path={`${URL_PATHS.ORDER_TEMPLATE_DETAILS}/:templateId`} component={AppGuard(OrderTemplateDetails)} />
        <Route path={`${URL_PATHS.ORDER_DETAILS}/:orderId`} component={AppGuard(OrderDetail)} />
        <Route path={URL_PATHS.CART} component={AppGuard(Cart)} />
        <Route path={URL_PATHS.CHECKOUT_PROMOTIONS} component={AppGuard(Promotions)} />
        <Route path={URL_PATHS.CHECKOUT} component={AppGuard(Checkout)} />
        <Route path={`${URL_PATHS.ORDER_CONFIRMATION}/:orderId`} component={AppGuard(OrderConfirm)} />
        <Route path={URL_PATHS.PROMOTIONS_CALENDAR} component={AppGuard(PromotionsCalendar)} />
        <Route path={URL_PATHS.CAPMPAIGNS_URL} component={AppGuard(Campaigns)} />
        <Route path={URL_PATHS.CAPMPAIGNS_VALENTINE_URL} component={AppGuard(Campaigns)} />
        <Route path={URL_PATHS.CAPMPAIGNS_EASTER_URL} component={AppGuard(Campaigns)} />
        <Route path={URL_PATHS.CAPMPAIGNS_SUMMER_URL} component={AppGuard(Campaigns)} />
        <Route path={URL_PATHS.CAPMPAIGNS_BACKTOSCHOOL_URL} component={AppGuard(Campaigns)} />
        <Route path={URL_PATHS.CAPMPAIGNS_BLACKFRIDAY_URL} component={AppGuard(Campaigns)} />
        <Route path={URL_PATHS.CAPMPAIGNS_CHRISTMAS_URL} component={AppGuard(Campaigns)} />
        <Route path={URL_PATHS.CAPMPAIGNS_WOMENDAY_URL} component={AppGuard(Campaigns)} />
        <Route path={URL_PATHS.CAPMPAIGNS_HALLOWEEN_URL} component={AppGuard(Campaigns)} />
        <Route path={URL_PATHS.CAPMPAIGNS_SAINTPATRICKDAY_URL} component={AppGuard(Campaigns)} />
        <Route path={URL_PATHS.CAPMPAIGNS_RAMADAN_URL} component={AppGuard(Campaigns)} />
        <Route path={URL_PATHS.CAPMPAIGNS_CONSTITUTIONDAY_URL} component={AppGuard(Campaigns)} />
        <Route path={URL_PATHS.CAPMPAIGNS_INDEPENDANCEDAY_URL} component={AppGuard(Campaigns)} />
        <Route path={URL_PATHS.CAPMPAIGNS_LABOURDAY_URL} component={AppGuard(Campaigns)} />
        <Route path={URL_PATHS.CAPMPAIGNS_RUSSIADAY_URL} component={AppGuard(Campaigns)} />
        <Route path={URL_PATHS.INVOICES} component={AppGuard(Invoices)} />
        <Route path={URL_PATHS.CLAIMS_HISTORY} component={AppGuard(ClaimsHistory)} />
        <Route path={URL_PATHS.UPDATE_PASSWORD} component={AppGuard(UpdatePassword)} />
        <Route path={URL_PATHS.MOBILE_ACCOUNT_PAGE} component={AppGuard(MobileAccountPage)} />
        <Route path={URL_PATHS.MY_ACCOUNT} component={AppGuard(MyAccount)} />
        <Route path={URL_PATHS.ACCOUNT_MY_REWARDS} component={AppGuard(MyRewards)} />
        <Route path={`${URL_PATHS.ACCOUNT_MY_REWARDS}/:template`} component={AppGuard(MyRewards)} />
        <Route path={URL_PATHS.USER_MAINTENANCE} component={AppGuard(UserMaintenance)} />
        <Route path={`${URL_PATHS.ACCOUNT_MY_SERVICES_FSA}`} component={AppGuard(FoodServiceAggregator)} />
        <Route path={`${URL_PATHS.ACCOUNT_MY_SERVICES}/:section`} component={AppGuard(AccountMyServices)} />
        <Route path={`${URL_PATHS.ACCOUNT_SHOPPING_LIST}/:section`} component={AppGuard(ShoppingList)} />
        <Route path={`${URL_PATHS.ACCOUNT_DATA}/:tmplType`} component={AppGuard(AccountData)} />
        <Route path={URL_PATHS.FAQ} component={AppGuard(FAQ)} />
        <Route path={URL_PATHS.IMPRINT} component={AppGuard(Imprint)} />
        <Route path={URL_PATHS.COOLER_DASHBOARD} component={AppGuard(CoolerDashboard)} />
        <Route path={URL_PATHS.MY_SALES} component={AppGuard(MySales)} />
        <Route path={URL_PATHS.CX_PREVIEW} component={AppGuard(Home)} />
        <Route path={`${URL_PATHS.ORDER_INFORMATION}/:section`} component={AppGuard(OrderInformation)} />
        <Route path={`${URL_PATHS.ACCOUNT_SETTINGS}/:section`} component={AppGuard(AccountSettings)} />
        <Route
            path={URL_PATHS.LOGOUT}
            onEnter={() => {
                // Remove the current session.
                // Hard refresh to clear all the store data of the current session.
                logout(() => window.location.href = URL_PATHS.LOGIN);                
            }}
        />
        {/* Error Route 404 Page. */}
        <Route path='*' component={AppGuard(ErrorPage)} />
    </Route>
);

export default routes;
