import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames/bind';
import * as styles from './FreeProducts.css';
import QtyInput from '../../core/QtyInput/QtyInput';
import Image from '../../core/Image/Image';
import { IMAGE_COMING_SOON } from '../../../common/Constants';
import { StockQuantity } from './StockQuantity';

const cx = classNames.bind(styles);

const FreeProducts = props => {
    const { title, showCard, items, changeQuantity, disabledIncreBtn, maxValue, inCartText, skuText } = props;

    

       
    return (
        <div
            title={title}
            className={cx('freeCardsWrapper', showCard && 'mobileViewCard')}
            automation-id='free-summary'
        >
            <ul className={cx('cardsContent')}>
                {items.map((item, key) => {
                    return (
                        <li
                            key={item.productCodeShort}
                            className={cx('col3', 'md3', 'sm12', 'listItems', `${item.quantity ? 'active' : ''}`)}
                        >
                            <div className={cx('topListView')}>
                                <div className={cx('thumbImg')}>
                                    <Link className={cx('imageLink')}>
                                        {_isEmpty(item.imageUrl) ? (
                                            <img src={IMAGE_COMING_SOON} alt={item.productName} />
                                        ) : (
                                            <Image
                                                automation-id='at-prodimg'
                                                key={item.imageUrl}
                                                src={item.imageUrl}
                                                alt={item.productName}
                                            />
                                        )}
                                    </Link>
                                </div>
                            </div>
                            <div className={cx('description')}>
                                <h4 className={cx('subTitle')}>{item.productName}</h4>
                                <p className={cx('sku')}>
                                    {skuText} {item.productCodeShort}
                                </p>
                                <p className={cx('pdContent')}>
                                    {item.inCartAmount} {inCartText}
                                </p>
                            </div>
                            <QtyInput
                                id={`free-qty-${item.productCodeShort}`}
                                theme={cx('inputTheme')}
                                themeIncreBtn={cx('themeDescrBtn')}
                                themeDescrBtn={cx('themeIncreBtn')}
                                onChange={e => changeQuantity(e, key)}
                                minValue={0}
                                maxValue={maxValue + (item.quantity || 0)}
                                value={item.quantity || 0}
                                isIncrementDisable={disabledIncreBtn}
                            />
                             <StockQuantity product={item} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

FreeProducts.propTypes = {
    title: PropTypes.string,
    showCard: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.any),
    changeQuantity: PropTypes.func,
    disabledIncreBtn: PropTypes.bool,
    maxValue: PropTypes.number,
    inCartText: PropTypes.string,
    skuText: PropTypes.string
};

FreeProducts.defaultProps = {
    title: '',
    showCard: false,
    items: [],
    changeQuantity: () => {},
    disabledIncreBtn: false,
    maxValue: 1,
    inCartText: '',
    skuText: ''
};

export default FreeProducts;

