import { GET_CUSTOMER_REPORT_REQUEST, GET_CUSTOMER_REPORT_SUCCESS } from '../actionTypes/CustomerReportActionTypes';

const initialState = {};
export default function CustomerReportReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_CUSTOMER_REPORT_REQUEST:
            return {
                ...initialState
            };
        case GET_CUSTOMER_REPORT_SUCCESS:
            return {
                ...action.data
            };
        default:
            return state;
    }
}
