import { put, takeLatest, call, all } from 'redux-saga/effects';
import _get from 'lodash/get';
import FactorySaga from './FactorySaga';
import OrderHistoryApi from '../interfaces/OrderHistory/OrderHistoryApi';
import { SessionStorage } from '../common/Storage/Storage';
import { URL_PATHS, CART_L10N, DATA_LAYER_LISTS, PAGE_ID } from '../common/Constants';
import { redirectTo } from '../common/Utils/Utils';
import { setActiveCartId } from '../common/UserManager/UserManager';
import {
    GET_ORDER_LIST_REQUEST,
    GET_ORDER_LIST_SUCCESS,
    GET_QUICK_ORDER_LIST_SUCCESS,
    GET_ORDER_LIST_ERROR,
    CANCEL_ORDER_REQUEST,
    REORDER_ORDER_REQUEST,
    REORDER_ORDER_ERROR
} from '../actionTypes/OrderListActionTypes';
import { GET_MULTI_CART_SUCCESS, CLEAR_MINI_CART } from '../actionTypes/CartActionTypes';
import { notifySuccess, showOrderNotifications } from '../actions/NotificationAction';
import { addToCartEventGA4 } from '../common/GoogleTagManager/GoogleTagManager';
import { getGTMAddToCartProductGA4, getProductPrice } from '../service/DataLayerFilter';
import { getStockAllocation } from '../actions/PageContentAction';

export function* getQuickOrderListSaga(action = {}) {
    try {
        const result = yield call(FactorySaga, OrderHistoryApi.getQuickOrders, action);

        if (result.isSuccess) {
            yield put({ type: GET_QUICK_ORDER_LIST_SUCCESS, data: result.response.data });
        } else {
            yield put({ type: GET_ORDER_LIST_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_ORDER_LIST_ERROR });
    }
}

export function* getOrderListSaga(action = {}) {
    try {
        const result = yield call(FactorySaga, OrderHistoryApi.getOrderList, action);

        if (result.isSuccess) {
            yield put({ type: GET_ORDER_LIST_SUCCESS, orderList: result.response.data, payload: action.payload });
        } else {
            yield put({ type: GET_ORDER_LIST_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_ORDER_LIST_ERROR });
    }
}

export function* reOrderSaga(action) {
    try {
        const result = yield call(FactorySaga, OrderHistoryApi.reOrder, action);

        if (result.isSuccess) {
            try {
                const gtmOrderEntries = result?.response?.data?.multiCartSummary?.cartList?.[0]?.cartDetails?.entries?.orderEntries;
                if (gtmOrderEntries?.length) { // GTM AddToCart from /order/list
                    addToCartEventGA4(gtmOrderEntries.map(({ qtyType, quantity, product: { code, name, category, brand }, totalPrice: { value, currencyIso } = {} }, index) => getGTMAddToCartProductGA4(index, name, code.replace(/\b0+/g, ''), qtyType, quantity, value && quantity ? getProductPrice((value / quantity).toFixed(2)) : 0, DATA_LAYER_LISTS.REORDER_LIST, currencyIso, brand, category)), action?.payload?.data?.origin_element);
                }
            } catch (ex) {
                console.error(ex);
            }

            const data = _get(result, 'response.data.multiCartSummary', {});
            const productValidationMessage = _get(result, 'response.data.productValidationMessage', '');
            const cartId = _get(result, 'response.data.cartId');
            setActiveCartId(cartId);
            yield all([
                put({ type: GET_MULTI_CART_SUCCESS, data: {
                    ...data, 
                    productValidationMessage,
                }}),
                put(showOrderNotifications({ 
                    ...result.response.data, 
                    saveLocal: true 
                })),
                put(getStockAllocation({pageId: PAGE_ID.CART}))
            ]);

            SessionStorage.setData(CART_L10N.IS_SKIP_GET_CART, true, true);
            SessionStorage.setData(CART_L10N.SHOW_STOCK_ALLOCATION_MODAL, true, true);
            redirectTo(URL_PATHS.CART);
            // If cartId not available then show the message from the API once land on Cart Page.
            if (!cartId) {
                yield put(notifySuccess(result.response.data));
            }

            //clean redux store and browser storage
            yield put({ type: CLEAR_MINI_CART });
        } else {
            yield put({ type: REORDER_ORDER_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_ORDER_LIST_ERROR });
    }
}

export function* cancelOrderSaga(action) {
    try {
        const result = yield call(FactorySaga, OrderHistoryApi.cancelOrder, action);

        if (result.isSuccess) {
            //yield put({ type: CANCEL_ORDER_SUCCESS, messageContent: result.response.data.message });
            // call get order list again
            yield call(getOrderListSaga);
            yield put(notifySuccess(result.response.data));
        }
    } catch (err) {
        yield put({ type: GET_ORDER_LIST_ERROR });
    }
}

export default function* watchOrderListRequest() {
    yield takeLatest(GET_ORDER_LIST_REQUEST, getOrderListSaga);
    yield takeLatest(REORDER_ORDER_REQUEST, reOrderSaga);
    yield takeLatest(CANCEL_ORDER_REQUEST, cancelOrderSaga);
}
