import { getUserEmail, getUserName, getCountryLocaleCode } from '../../UserManager/UserManager';
import * as lang from '../i18n/index'

 const removeElementsByClass =   className => {
       const elements = document.getElementsByClassName(className);
       while (elements.length > 0) {
           elements[0].parentNode.removeChild(elements[0]);
       }
   }

export const setInitialSetting = async (chatLanguage, chatCountry, chatEmail, chatCurrentOutletId, chatCurrentCompanyName, content, assetBaseUrl ) => {

  window._genesys = {
    "widgets": {
      "main": {
        "lang": getCountryLocaleCode(),
        "i18n": lang[`${getCountryLocaleCode()}`],
        "themes": {"cch": "cx-theme-cch"},
        "theme": "cch",
        "debug": false,
        "preload": [
          "webchat",
          "webchatservice",
          "sidebar"
        ]
      },
      "webchat": {
        "transport": {
          "type": "purecloud-v2-sockets",
          "dataURL": "https://api.mypurecloud.de",
          "deploymentKey": "3a862ff6-a401-474a-b799-c76838b8a176",
          "orgGuid": "6d235ade-a72c-4656-a855-52bb42e02ec4",
          "interactionData": {
            "routing": {
              "targetType": "QUEUE",
              "targetAddress": "GenesysTest1",
              "priority": 2
            }
          }
        },
        "userData": {
          "OutletNumber": chatCurrentOutletId || "",
          "CompanyName": chatCurrentCompanyName  || "",
          "customField1" : chatEmail || "",
          "LoggedUserEmailAddress": getUserEmail() || "",
          "Country": chatCountry,
          "Language": chatLanguage
        }
      },
      "sidebar": {
        "showOnStartup": true,
        "position": 'right',
        "expandOnHover": false,
        "channels": [{
          "name": 'chat',
          "clickCommand": 'WebChat.open',
          "readyEvent": 'WebChat.ready',
          "displayName": "@i18n:webchat.ChatTitle",
          "icon": "chat",
          onClick: () => {
            CXBus.command('WebChat.open').then(() => {
              if(content && window.location.pathname.includes('login')){
                const privacyContainer = document.createElement('div');
                privacyContainer.setAttribute('class', 'chatPrivacyNoticeContainer')
                const hyperLink = document.createElement('a');
                hyperLink.setAttribute('class', 'chatPrivacyLink');
                hyperLink.setAttribute('href', `${assetBaseUrl  }/${  content.chatPrivacyLink}`);
                hyperLink.setAttribute('target', '_blank');
                const textNodeLink = document.createTextNode(content.chatPrivacyMessageLink);
                hyperLink.appendChild(textNodeLink);
  
                const policyMessage = document.createElement('div');
                policyMessage.setAttribute('class', 'chatPrivacyMessage');
                const textNodeMessage = document.createTextNode(content.chatPrivacyMessage);
                policyMessage.appendChild(textNodeMessage);
  
                privacyContainer.appendChild(policyMessage)
                policyMessage.appendChild(hyperLink)
  
                document.getElementsByClassName("cx-form")[0].lastElementChild.before(privacyContainer);
              } else{
                removeElementsByClass("chatPrivacyNoticeContainer");
              }
            });
          }
        }]
      }
    }
  }

  window._genesys.widgets.webchat.form = {
    wrapper: "<table></table>",
    inputs: [
      {
        id: "cx_webchat_form_firstname",
        name: "firstName",
        maxlength: "100",
        placeholder: "@i18n:webchat.ChatFormPlaceholderName",
        label: "@i18n:webchat.ChatFormName"
      },
      {
        id: "cx_webchat_form_customerid",
        name: "OutletNumber",
        maxlength: "100",
        placeholder: "@i18n:webchat.ChatFormPlaceholderCustomerId",
        label: "@i18n:webchat.ChatFormCustomerId"
      },
      {
        id: "cx_webchat_form_email",
        name: "LoggedUserEmailAddress",
        maxlength: "100",
        placeholder: "@i18n:webchat.ChatFormPlaceholderEmail",
        label: "@i18n:webchat.ChatFormEmail",
        type: "text",
        validateWhileTyping: false,
        validate (event, form, input, label, $, CXBus, Common) {
          if (input) {
            if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(input.val()))
              return true;
            return false;
          }
          return false;
        }
      },
      {
        id: "cx_webchat_form_subject",
        name: "Subject",
        placeholder: "@i18n:webchat.ChatFormPlaceholderSubject",
        label: "@i18n:webchat.ChatFormSubject",
        type: "select",
        options: [
          {
            disabled: "disabled",
            selected: "selected",
            hidden: "hidden"
          },
          {
            text: "@i18n:webchat.ChatFormSubjectPassword",
            value: "password",
            "selected":true
          }
        ],
        wrapper: "<tr><th>{label}</th><td>{input}</td></tr>"
      }
    ]
  };
}

export default setInitialSetting;