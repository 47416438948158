import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import isEmpty from 'lodash/isEmpty';
import { Carousel } from 'react-responsive-carousel';
import Button from '../Button/Button';
import NavLink from '../NavLink/NavLink';
import Image from '../Image/Image';

import * as styles from './SlickCarousel.css';
import { GA_BANNERS_PROPS } from '../../../common/Constants';

const cx = classNames.bind(styles);

function SlickCarousel(props) {
    const { carouselData, carouselActionData, onClick, autoPlay, interval, infiniteLoop, handleGAPromotionClick } = props;
    const handleBtnClick = () => {
        onClick();
    };
    return (
        <div className={cx('carouselContainer')}>
            <Carousel
                selectedItem={0}
                autoPlay={autoPlay}
                showThumbs={false}
                interval={interval}
                infiniteLoop={infiniteLoop}
            >
                {carouselData.map(item => {
                    return (
                        <div key={item.id}>
                            {item.url ? (
                                <NavLink onClick={() => {
                                        if(handleGAPromotionClick) handleGAPromotionClick(item, GA_BANNERS_PROPS.STANDARD)
                                    }
                                } linkUrl={item.url} theme={cx('imageNavLink')}>
                                    <Image src={item.src} id={item.id} alt={item.altText} />
                                </NavLink>
                            ) : (
                                <Image src={item.src} id={item.id} alt={item.altText} />
                            )}
                        </div>
                    );
                })}
            </Carousel>
            {!isEmpty(carouselActionData) && (
                <div className={cx('carouselActionContainer')}>
                    <h3 className={cx('carouselTitle')}>{carouselActionData.carouselHeading}</h3>
                    <Button className={cx('carosulBtn')} onClick={handleBtnClick}>
                        {carouselActionData.carouselBtn}
                    </Button>
                </div>
            )}
        </div>
    );
}

SlickCarousel.propTypes = {
    carouselData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    carouselActionData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClick: PropTypes.func,
    autoPlay: PropTypes.bool,
    interval: PropTypes.number,
    infiniteLoop: PropTypes.bool
};

SlickCarousel.defaultProps = {
    carouselData: [],
    carouselActionData: {},
    autoPlay: false,
    interval: 5000,
    infiniteLoop: true
};

export default SlickCarousel;
