import React from 'react';
import * as styles from './Stepper.css';
import classNames from 'classnames/bind';
import Icon from '../Icon/Icon';
import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

const Step = ({ isEven, isDone, isActive, step, icon, totalSteps }) => {
    const evenClass = isEven ? 'circle' : 'dot';
    const doneClass = isDone ? 'done' : '';
    const activeClass = isActive ? 'active' : '';

    if (!icon)
        return <div className={cx(`step`, evenClass, doneClass, activeClass)}>{isEven && <span>{step}</span>}</div>;

    return (
        <div className={cx('icon-step', activeClass, doneClass)}>
            <p>
                {step + 1}/{totalSteps}
            </p>
            <Icon iconType='svg' width='35px' height='30px' viewBox='0 0 35 30' name={icon} />
        </div>
    );
};

Step.propTypes = {
    isEven: PropTypes.bool,
    isDone: PropTypes.bool,
    isActive: PropTypes.bool,
    step: PropTypes.number.isRequired,
    icon: PropTypes.string,
    totalSteps: PropTypes.number.isRequired,
};

const StepperLine = ({ isDone, isNext, icon }) => {
    const doneClass = isDone ? (icon ? 'done-icon' : 'done') : '';
    const nextClass = isNext ? (icon ? 'next-icon' : 'next') : '';

    return <div className={cx('stepper-line', doneClass, nextClass)}></div>;
};

StepperLine.propTypes = {
    isDone: PropTypes.bool,
    isNext: PropTypes.bool,
    icon: PropTypes.string,
};

const Stepper = ({ totalSteps, currentStep, icon, isCompleted }) => {
    const steps = [];

    for (let i = 0; i < totalSteps; i++) {
        const isDone = i < currentStep || isCompleted;
        const isActive = icon ? currentStep !== 0 && i < currentStep : i === parseInt(currentStep);
        steps.push(
            <Step
                key={i}
                isEven={i % 2 === 0}
                isDone={isDone || (!icon && i === 0)}
                isActive={isActive}
                step={i}
                icon={icon}
                totalSteps={totalSteps}
            />
        );
        if (i < totalSteps - 1) {
            let isDone, isNext;
            if (icon) {
                isDone = i < currentStep - 1 || isCompleted;
                isNext = i === currentStep - 1;
            } else {
                isDone = i < currentStep || isCompleted;
                isNext = (i === currentStep - 1 && currentStep % 2 !== 0) || (i === currentStep && currentStep % 2 !== 0);
            }
            steps.push(<StepperLine key={`line-${i}`} isDone={isDone} isNext={isNext} icon={icon} />);
        }
    }

    return <div className={cx('stepper', icon && 'icon-stepper')}>{steps}</div>;
};

Stepper.propTypes = {
    totalSteps: PropTypes.number.isRequired,
    currentStep: PropTypes.number.isRequired,
    icon: PropTypes.string,
    isCompleted: PropTypes.bool,
};


export default Stepper;
