import { put, takeLatest, call, all } from 'redux-saga/effects';
import FactorySaga from './FactorySaga';
import OrderConfirmApi from '../interfaces/OrderConfirm/OrderConfirmApi';
import { showOrderNotifications } from '../actions/NotificationAction';
import {
    GET_ORDER_CONFIRMATION_REQUEST,
    GET_ORDER_CONFIRMATION_SUCCESS,
    GET_ORDER_CONFIRMATION_ERROR,
    UPDATE_SURVEY_LAST_CLICKED_DATE_SUCCESS,
    UPDATE_SURVEY_LAST_CLICKED_DATE_ERROR,
    UPDATE_SURVEY_LAST_CLICKED_DATE_REQUEST
} from '../actionTypes/OrderConfirmActionType';

export function* OrderConfirmSaga(action) {
    try {
        const result = yield call(FactorySaga, OrderConfirmApi.getOrderConfirmtData, action);
        const { isSuccess, response } = result;
        if (isSuccess) {
            yield all([
                put({ type: GET_ORDER_CONFIRMATION_SUCCESS, data: response.data }),
                put(showOrderNotifications(response.data))
            ])
        }
    } catch (err) {
        yield put({ type: GET_ORDER_CONFIRMATION_ERROR });
    }
}

export function* SurveyDateUpdate(action) {
    try {
        const result = yield call(FactorySaga, OrderConfirmApi.changeSurveyLastClickedDay, action);
        const { isSuccess } = result;

        if (isSuccess) {
            yield put({ type: UPDATE_SURVEY_LAST_CLICKED_DATE_SUCCESS });
        }
    } catch (err) {
        yield put({ type: UPDATE_SURVEY_LAST_CLICKED_DATE_ERROR });
    }
}

export default function* watchOrderConfirmSagaRequest() {
    yield takeLatest(GET_ORDER_CONFIRMATION_REQUEST, OrderConfirmSaga);
    yield takeLatest(UPDATE_SURVEY_LAST_CLICKED_DATE_REQUEST, SurveyDateUpdate);
}
