import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { CONTENT, PARAMS, AUTH, CART_API } from '../common/Api';
import { mainPartnerCode } from '../../common/UserManager/UserManager';
import { store } from '../../../__runtime/redux/Store';
import { PLP_SORTS_VALUES } from '../../common/Constants';
export default class ProductApi {
    static getProductListing(payload) {
        const { baseInfo, queryString, params = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}/products/search${CONTENT.QUERY}${queryString}`;

        const sortValue = store.getState().products.orderBy;
        if (sortValue !== PLP_SORTS_VALUES.DEFAULT) {
            config.url += `&sort=${sortValue}`;
        }

        if (config.url.includes('&bdaa=true') || config.url.includes('&sort=bdaa')) {
            const code = mainPartnerCode();
            config.url += `&countryCode=${code}`;
        }

        if (baseInfo[PARAMS.CART_ID]) {
            params[PARAMS.CART_ID] = baseInfo[PARAMS.CART_ID];
        }
        config.params = params;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static setPLPViewChoice(payload) {
        const { baseInfo, viewchoice, params = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}/products/update/viewchoice`;
        params[PARAMS.VIEW_CHOICE] = viewchoice;
        config.params = params;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static getBdaaSuggestion(payload) {
        const {
            data: { countryCode, customerId }
        } = payload;

        const config = RestConfig.v2ComApiConfig(payload);

        config.url = `/${config.baseInfo.siteId}/products/bdaa?countryCode=${countryCode}&customerID=${customerId}`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static subscribeToProduct(payload) {
        const { baseInfo = {}, params = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${CART_API.SUBSCRIBE_TO_PRODUCT}?code=${params.code}&email=${
            params.email
        }`;

        return RestClient.post(config).then(json => {
            return json;
        });
    }
}
