import { GET_ORDER_DETAILS_SUCCESS, RESET_ORDER_DETAILS } from '../actionTypes/OrderDetailsActionTypes';

/**
{
    createdDate: string,
    status: string,
    orderNumber: number,
    name: string,
    total: string,
    shippingAddress: string,
    billingAddress: string,
    deliveryDate: string,
    shippingStatus: string,
    billingStatus: string,
    totalItems: string,
    paymentTerms: string,
    pallets: string,
    weight: string,
    documents: string,
    items: [
        {
            imageUrl: string,
            name: string,
            skuId: string,
            avaliablity: string,
            status: string,
            qty: string,
            price: {
                exclTax: string,
                inclTax: string
            },
            isFree: true,
        }
    ],
    orderSummary: {
        totalItems: number,
        subtotal: string,
        tax: string,
        total: {
            beforeTax: string,
            afterTax: string
        },
        environmentFee: {
            can: string,
            pet: string,
        },
        upchargeFee: string,
        volRevFee: string,
        totalPallets: string,
        totalWeight: string,
        promo: string,
        dataSharing: string

    }
} 
 */
const initialState = {};
export default function OrderDetailsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_ORDER_DETAILS_SUCCESS:
            return {
                ...action.orderDetail
            };
        case RESET_ORDER_DETAILS: {
            return initialState;
        }
        default:
            return state;
    }
}
