import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { AUTH, PARAMS, CART_API } from '../common/Api';

export default class PromotionApi {
    static getPromotionsList(payload) {
        //const { baseInfo = {}, data } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${config.baseInfo.siteId}/users/${config.baseInfo.userId}/promotion/${config.baseInfo[PARAMS.CART_ID]
            }`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static getPromotionDeal(payload) {
        const { promotionId, dealId } = payload.data; //pageSize, sortCode, showMode
        const config = RestConfig.v2ComApiConfig(payload);

        //config.url = `/${baseInfo.siteId}/checkout/promotion/deal`;
        config.url = `/${config.baseInfo.siteId}/users/${config.baseInfo.userId}/deal`;

        config.params = {
            promotionId,
            dealId
        };

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static addToCartPromo(payload) {
        const { baseInfo = {}, data, params = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);

        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${CART_API.PROMO_TO_CART}/${baseInfo[PARAMS.CART_ID]
            }`;

        if (baseInfo[PARAMS.IS_RETURN_EMPTIES]) {
            params[PARAMS.IS_RETURN_EMPTIES] = baseInfo[PARAMS.IS_RETURN_EMPTIES];
        }

        if (baseInfo[PARAMS.IS_PICKUP_ORDER]) {
            params[PARAMS.IS_PICKUP_ORDER] = baseInfo[PARAMS.IS_PICKUP_ORDER];
        }

        config.params = params;
        config.data = data;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static removePromoFromCart(payload) {
        const { baseInfo = {}, data, params = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);

        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${CART_API.PROMO_TO_CART}/${baseInfo[PARAMS.CART_ID]
            }`;

        config.params = params;
        config.data = data;
        config.data.cartId = baseInfo.cartId;

        return RestClient.delete(config).then(json => {
            return json;
        });
    }

    static getPromotionsCalenderList(payload) {
        const { params = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${config.baseInfo.siteId}/my-account/promo-calendar`;

        if (config.baseInfo[PARAMS.CART_ID]) {
            params[PARAMS.CART_ID] = config.baseInfo[PARAMS.CART_ID];
        }
        config.params = params;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static getPreCheckoutUpdate(payload) {
        const { data } = payload;

        const config = RestConfig.v2ComApiConfig(payload);

        config.url = `/${config.baseInfo.siteId}${AUTH.USERS}/${config.baseInfo[PARAMS.USER_ID]}/checkout/promoB/${config.baseInfo[PARAMS.CART_ID]}`;

        config.data = data === null ? {} : data

        return RestClient.post(config).then(json => {
            return json;
        });
    }
}
