/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useSelector } from 'react-redux';
import {
    MY_ACCOUNT_L10N,
    URL_PATHS,
    MY_ACCOUNT_SETTINGS_TABS,
    SHOPPING_LIST_SECTION_TABS
} from '../../../common/Constants';
import classNames from 'classnames/bind';
import Icon from '../../../components/core/Icon/Icon';
import Link from 'react-router/lib/Link';

import * as styles from './MyAccount.css';
import { buttonClickEventConfigs, buttonClickEvent } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

const AccountComponents = {
    'Order-Information-MyAccount-React': {
        svg: 'order-history'
    },
    'Shopping-List-MyAccount-React': {
        svg: 'add-cart'
    },
    'My-Rewards-MyAccount-React': {
        svg: 'trofy'
    },
    'My-Equipment-MyAccount-React': {
        svg: 'cooler'
    },
    'My-Services-MyAccount-React': {
        svg: 'tickets'
    },
    'Account-Settings-MyAccount-React': {
        svg: 'settings'
    },
    'My-Reports-MyAccount-React': {
        svg: 'reports'
    }
};
export const AccountCard = ({ name, childComponents, uid }) => {
    const localization = useSelector(state => state.pageContent.localization);
    const loyaltyEnabled = useSelector(state => state.loginPage.loyaltyEnabled);

    return (
        <div
            className={cx(
                'gridChild',
                name === localization[MY_ACCOUNT_L10N.MY_REWARDS] && !loyaltyEnabled && 'hidden-card'
            )}
        >
            <Icon width='32px' height='32px' viewBox='0 0 32 32' name={AccountComponents[uid]?.svg || ''} />
            <h3 className={cx('name')}>{name}</h3>
            {childComponents && (
                <ul>
                    {childComponents.map(cComponent => {
                        return (
                            <li className={cx('action')} key={cComponent.name}>
                                <span className={cx('arrow')}></span>
                                <Link
                                    to={cComponent?.url}
                                    onClick={() => {
                                        try {
                                            const gaLinks = {
                                                [URL_PATHS.ORDER_LIST]: {
                                                    action: buttonClickEventConfigs.myAccount.actions.orderInformation,
                                                    label:
                                                        buttonClickEventConfigs.myAccount.labels
                                                            .orderInformationOrderHistory,
                                                    category: buttonClickEventConfigs.myAccount.categories.myAccount
                                                },
                                                [URL_PATHS.MY_ACCOUNT_INVOICES]: {
                                                    action: buttonClickEventConfigs.myAccount.actions.orderInformation,
                                                    label:
                                                        buttonClickEventConfigs.myAccount.labels
                                                            .orderInformationInvoices,
                                                    category: buttonClickEventConfigs.myAccount.categories.myAccount
                                                },
                                                [URL_PATHS.ACCOUNT_SHOPPING_LIST +
                                                '/' +
                                                SHOPPING_LIST_SECTION_TABS.quickOrder]: {
                                                    action: buttonClickEventConfigs.myAccount.actions.shoppingList,
                                                    label:
                                                        buttonClickEventConfigs.myAccount.labels.shoppingListQuickOrder,
                                                    category: buttonClickEventConfigs.myAccount.categories.myAccount
                                                },
                                                [URL_PATHS.ACCOUNT_SHOPPING_LIST +
                                                '/' +
                                                SHOPPING_LIST_SECTION_TABS.savedCarts]: {
                                                    action: buttonClickEventConfigs.myAccount.actions.shoppingList,
                                                    label:
                                                        buttonClickEventConfigs.myAccount.labels
                                                            .shoppingListOrderTemplate,
                                                    category: buttonClickEventConfigs.myAccount.categories.myAccount
                                                },
                                                [URL_PATHS.ACCOUNT_SETTINGS +
                                                '/' +
                                                MY_ACCOUNT_SETTINGS_TABS.personalData.name]: {
                                                    action: buttonClickEventConfigs.myAccount.actions.accountSettings,
                                                    label:
                                                        buttonClickEventConfigs.myAccount.labels
                                                            .accountSettingsPersonalData,
                                                    category: buttonClickEventConfigs.myAccount.categories.myAccount
                                                },
                                                [URL_PATHS.ACCOUNT_SETTINGS +
                                                '/' +
                                                MY_ACCOUNT_SETTINGS_TABS.customerFactSheet.name]: {
                                                    action: buttonClickEventConfigs.myAccount.actions.accountSettings,
                                                    label:
                                                        buttonClickEventConfigs.myAccount.labels
                                                            .accountSettingsCustomerFactSheet,
                                                    category: buttonClickEventConfigs.myAccount.categories.myAccount
                                                },
                                                [URL_PATHS.ACCOUNT_SETTINGS +
                                                '/' +
                                                MY_ACCOUNT_SETTINGS_TABS.companyData.name]: {
                                                    action: buttonClickEventConfigs.myAccount.actions.accountSettings,
                                                    label:
                                                        buttonClickEventConfigs.myAccount.labels
                                                            .accountSettingsCompanyData,
                                                    category: buttonClickEventConfigs.myAccount.categories.myAccount
                                                },
                                                [URL_PATHS.ACCOUNT_SETTINGS +
                                                '/' +
                                                MY_ACCOUNT_SETTINGS_TABS.userManagement.name]: {
                                                    action: buttonClickEventConfigs.myAccount.actions.accountSettings,
                                                    label:
                                                        buttonClickEventConfigs.myAccount.labels
                                                            .accountSettingsUserManagement,
                                                    category: buttonClickEventConfigs.myAccount.categories.myAccount
                                                },
                                                [URL_PATHS.ACCOUNT_MY_REWARDS]: {
                                                    action: buttonClickEventConfigs.myAccount.actions.myRewards,
                                                    label: buttonClickEventConfigs.myAccount.labels.myRewards,
                                                    category: buttonClickEventConfigs.myAccount.categories.myAccount
                                                },
                                                [URL_PATHS.COOLER_DASHBOARD]: {
                                                    action: buttonClickEventConfigs.myAccount.actions.myEquipment,
                                                    label:
                                                        buttonClickEventConfigs.myAccount.labels
                                                            .myEquipmentCoolerDashboard,
                                                    category: buttonClickEventConfigs.myAccount.categories.myAccount
                                                },
                                                [URL_PATHS.ACCOUNT_MY_SERVICES + '/requestSupport']: {
                                                    action: buttonClickEventConfigs.myAccount.actions.myServices,
                                                    label:
                                                        buttonClickEventConfigs.myAccount.labels
                                                            .myServicesRequestSupport,
                                                    category: buttonClickEventConfigs.myAccount.categories.myAccount
                                                }
                                            };
                                            const foundLink =
                                                gaLinks[cComponent?.url] || gaLinks['/' + cComponent?.url];
                                            if (foundLink) {
                                                buttonClickEvent(foundLink.action, foundLink.label, foundLink.category);
                                            }
                                        } catch (ex) {
                                            console.error(ex);
                                        }
                                    }}
                                >
                                    {cComponent?.name}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};
