import _get from 'lodash/get';

const invoicesFillteredData = invoicesData => {
    const status = [];
    const timePeriod = [];
    const invoicesList = [];
    const breadcrumbsObjs = [];

    const timePeriods = _get(invoicesData, 'timePeriods', []);
    const invoiceTimePeriods = _get(timePeriods, 'entry', []);
    for (let j = 0; j < invoiceTimePeriods.length; j += 1) {
        const timePeriodsObj = {
            id: j,
            value: invoiceTimePeriods[j].key,
            label: invoiceTimePeriods[j].value
        };
        timePeriod.push(timePeriodsObj);
    }

    const invoiceResults = _get(invoicesData, 'invoiceResults', []);
    for (let k = 0; k < invoiceResults.length; k += 1) {
        const invoicesListObj = {
            id: k,
            documentType: invoiceResults[k].documentDescription,
            status: invoiceResults[k].status,
            dueDate: invoiceResults[k].dueDateFormat,
            billNo: invoiceResults[k].invoiceNumber,
            invoiceDate: invoiceResults[k].documentDateFormat,
            customer: invoiceResults[k].customer,
            amount: invoiceResults[k].openAmount,
            amountString: invoiceResults[k].openAmountString,
            currency: invoiceResults[k].currency,
            pdfActive: invoiceResults[k].pdfActive,
            pdfUrl: invoiceResults[k].pdfUrl
        };
        invoicesList.push(invoicesListObj);
    }

    const breadcrumbs = _get(invoicesData, 'breadcrumbs', []);
    for (let m = 0; m < breadcrumbs.length; m += 1) {
        const breadcrumbObj = {
            id: m,
            url: breadcrumbs[m].url,
            name: breadcrumbs[m].name
        };
        breadcrumbsObjs.push(breadcrumbObj);
    }

    const {
        totalAmount,
        totalCurrency,
        createClaimVisibility,
        message,
        accountStatementEnabled,
        payerCustomers
    } = invoicesData;
    return {
        breadcrumbsObjs,
        status,
        timePeriod,
        totalAmount,
        totalCurrency,
        invoicesList,
        createClaimVisibility,
        message,
        accountStatementEnabled,
        payerCustomers
    };
};

export default invoicesFillteredData;
