import {
    GET_ORDER_TEMPLATE_REQUEST,
    CREATE_ORDER_TEMPLATE_REQUEST,
    DELETE_ORDER_TEMPLATE_REQUEST,
    ADD_ITEM_TO_TEMPLATE,
    RESTORE_ORDER_TEMPLATE_REQUEST,
    GET_ORDER_TEMPLATE_EXCEL,
    POST_ORDER_TEMPLATE_EXCEL,
    RESET_TEMPLATE_EXCEL_ERROR,
    CLEAR_REDUCER
} from '../actionTypes/OrderTemplateActionTypes';

export const clearOrderTemplateReducer = () => ({
    type: CLEAR_REDUCER
})

export const getOrderTemplateDetails = payload => ({
    type: GET_ORDER_TEMPLATE_REQUEST,
    payload
});

export const createOrderTemplate = payload => ({
    type: CREATE_ORDER_TEMPLATE_REQUEST,
    payload
});

export const deleteOrderTemplate = payload => ({
    type: DELETE_ORDER_TEMPLATE_REQUEST,
    payload
});

export const addItemsToTemplate = payload => ({
    type: ADD_ITEM_TO_TEMPLATE,
    payload
});

export const restoreOrderTemplate = payload => ({
    type: RESTORE_ORDER_TEMPLATE_REQUEST,
    payload
});

export const getExcel = payload => ({
    type: GET_ORDER_TEMPLATE_EXCEL,
    payload
});

export const postExcel = payload => ({
    type: POST_ORDER_TEMPLATE_EXCEL,
    payload
});

export const resetExcelErrors = _ => ({
    type: RESET_TEMPLATE_EXCEL_ERROR,
})
