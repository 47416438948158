import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_LOYALTY_NOTIFICATIONS,
    GET_LOYALTY_NOTIFICATIONS_ERROR,
    GET_LOYALTY_NOTIFICATIONS_SUCCESS,
    READ_LOYALTY_NOTIFICATION,
    READ_LOYALTY_NOTIFICATION_SUCCESS,
    READ_LOYALTY_NOTIFICATION_ERROR,
    MUTE_LOYALTY_NOTIFICATIONS,
    MUTE_LOYALTY_NOTIFICATIONS_SUCCESS,
    MUTE_LOYALTY_NOTIFICATIONS_ERROR,
    UNMUTE_LOYALTY_NOTIFICATIONS,
    UNMUTE_LOYALTY_NOTIFICATIONS_SUCCESS,
    UNMUTE_LOYALTY_NOTIFICATIONS_ERROR,
    CLEAR_LOYALTY_NOTIFICATIONS,
    CLEAR_LOYALTY_NOTIFICATIONS_SUCCESS,
    CLEAR_LOYALTY_NOTIFICATIONS_ERROR,
    GET_LOYALTY_DASHBOARD_DATA_SUCCESS,
    GET_LOYALTY_DASHBOARD_DATA,
    GET_LOYALTY_DASHBOARD_DATA_ERROR
} from '../actionTypes/LoyaltyActionTypes';
import LoyaltyApi from '../interfaces/Loyalty/LoyaltyApi';
import FactorySaga from './FactorySaga';
import { removeLoader, showLoader } from '../actions/LoaderAction';

function* getLoyaltyNotifications(action) {
    try {
        // make the API call to get the notifications for a user
        const result = yield call(FactorySaga, LoyaltyApi.getAllLoyaltyNotifications, action);
        if (result.isSuccess) {
            yield put({ type: GET_LOYALTY_NOTIFICATIONS_SUCCESS, data: { ...result.response.data } });
        } else {
            yield put({ type: GET_LOYALTY_NOTIFICATIONS_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_LOYALTY_NOTIFICATIONS_ERROR });
    }
}

function* readLoyaltyNotification(action) {
    try {
        // make the API call to get the unread notifications for a user
        const result = yield call(FactorySaga, LoyaltyApi.sendReadLoyaltyNotification, action);
        if (result.isSuccess) {
            yield put({ type: READ_LOYALTY_NOTIFICATION_SUCCESS, payload: action.payload.messageId });
        } else {
            yield put({ type: READ_LOYALTY_NOTIFICATION_ERROR });
        }
    } catch (err) {
        yield put({ type: READ_LOYALTY_NOTIFICATION_ERROR });
    }
}

function* muteLoyaltyNotifications(action) {
    try {
        // make the API call to get the unread notifications for a user
        const result = yield call(FactorySaga, LoyaltyApi.muteLoyaltyNotificationsFunc, action);
        if (result.isSuccess) {
            yield put({ type: MUTE_LOYALTY_NOTIFICATIONS_SUCCESS });
            yield put({ type: CLEAR_LOYALTY_NOTIFICATIONS_SUCCESS });
        } else {
            yield put({ type: MUTE_LOYALTY_NOTIFICATIONS_ERROR });
        }
    } catch (err) {
        yield put({ type: MUTE_LOYALTY_NOTIFICATIONS_ERROR });
    }
}

function* unmuteLoyaltyNotifications(action) {
    try {
        // make the API call to get the unread notifications for a user
        const result = yield call(FactorySaga, LoyaltyApi.unmuteLoyaltyNotificationsFunc, action);
        if (result.isSuccess) {
            yield put({ type: UNMUTE_LOYALTY_NOTIFICATIONS_SUCCESS });
        } else {
            yield put({ type: UNMUTE_LOYALTY_NOTIFICATIONS_ERROR });
        }
    } catch (err) {
        yield put({ type: UNMUTE_LOYALTY_NOTIFICATIONS_ERROR });
    }
}

function* clearLoyaltyNotifications(action) {
    try {
        // Call the API to clear notifications
        const result = yield call(FactorySaga, LoyaltyApi.clearAllLoyaltyNotifications, action);
        // const response = yield call(api.clearNotifications);
        // In this example, we're just simulating a successful response
        if (result.isSuccess) {
            yield put({ type: CLEAR_LOYALTY_NOTIFICATIONS_SUCCESS });
        } else {
            yield put({ type: CLEAR_LOYALTY_NOTIFICATIONS_ERROR });
        }
    } catch (error) {
        yield put({ type: CLEAR_LOYALTY_NOTIFICATIONS_ERROR });
    }
}

function* getLoyaltyDashboardData(action) {
    try {
        const result = yield call(FactorySaga, LoyaltyApi.getLoyaltyDashboardData, action);
        if (result.isSuccess) {
            yield put({ type: GET_LOYALTY_DASHBOARD_DATA_SUCCESS, data: { ...result.response.data } });
        } else {
            yield put({ type: GET_LOYALTY_DASHBOARD_DATA_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_LOYALTY_DASHBOARD_DATA_ERROR });
    }
}

// watch for GET_LOYALTY_NOTIFICATIONS action to trigger the loyalty saga
export default function* watchGetLoyaltyNotifications() {
    yield takeLatest(GET_LOYALTY_NOTIFICATIONS, getLoyaltyNotifications);
    yield takeLatest(READ_LOYALTY_NOTIFICATION, readLoyaltyNotification);
    yield takeLatest(MUTE_LOYALTY_NOTIFICATIONS, muteLoyaltyNotifications);
    yield takeLatest(UNMUTE_LOYALTY_NOTIFICATIONS, unmuteLoyaltyNotifications);
    yield takeLatest(CLEAR_LOYALTY_NOTIFICATIONS, clearLoyaltyNotifications);
    yield takeLatest(GET_LOYALTY_DASHBOARD_DATA, getLoyaltyDashboardData);
}
