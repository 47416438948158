import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { CONTENT } from '../common/Api';
import { PAGE_ID } from '../../common/Constants';
import Storage from '../../common/Storage/Storage';
export default class ContentApi {
    static getPage(payload) {
        const cmsTicketId = Storage.getCmsTicketId();
        const pageId = payload.pageId === PAGE_ID.MINI_CART ? PAGE_ID.CART : payload.pageId;
        const config = RestConfig.v2CmsApiConfig(payload);
        
        config.url = `${CONTENT.PAGES}${pageId}${CONTENT.QUERY}${cmsTicketId}`;
        config.params = payload.params;

        return RestClient.get(config).then(json => {
            return json;
        });
    }
}
