import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import Button from '../../../components/core/Button/Button';
import Icon from '../../../components/core/Icon/Icon';
import Image from '../../../components/core/Image/Image';
import Accordion from '../../../components/core/Accordion/Accordion';
import Input from '../../../components/core/Input/Input';
import * as styles from './Checkout.css';
import Layout from '../../../components/site/Layout/Layout';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import Tabs from '../../../components/core/Tabs/Tabs';
import NavLink from '../../../components/core/NavLink/NavLink';
import TabPanel from '../../../components/core/Tabs/TabPanel';
import { getPageContent, clearPageContent } from '../../../actions/PageContentAction';
import { setMarketingCommunicationChannels } from '../../../actions/MarketingAction';
import {
    PAGE_ID,
    CHECKOUT_L10N,
    URL_PATHS,
    COMMON_COMPONENT,
    UNIQUE_ID,
    GENERIC_L10N,
    CART_PRICING_TYPES,
    GA_CHECKOUT_STEPS,
    DATA_LAYER_LISTS,
    NAV_COMPONENTS,
    MARKETING_TYPES
} from '../../../common/Constants';
import Checkbox from '../../../components/core/Checkbox/Checkbox';
import { postPlaceOrderData } from '../../../actions/CheckoutAction';
import {
    isIndirectUser,
    getActiveCartId,
    getUserInfo,
    getRandomUUID,
    setDeliveryDatePreference,
    getLocalization,
    getSiteId
} from '../../../common/UserManager/UserManager';
import {
    productClickEventGA4,
    buttonClickEvent,
    buttonClickEventConfigs
} from '../../../common/GoogleTagManager/GoogleTagManager';
import {
    redirectTo,
    getImageByFormat,
    trimString,
    formatHungaryTousends,
    convertBGNToEUR
} from '../../../common/Utils/Utils';
import { productClickOrderConfirmDataLayerFilterGA4 } from '../../../service/DataLayerFilter';
import Modal from '../../../components/core/Modal/Modal';
import parse from 'html-react-parser';

const cx = classNames.bind(styles);
export class Checkout extends Component {
    static propTypes = {
        localization: PropTypes.objectOf(PropTypes.string),
        carts: PropTypes.arrayOf(PropTypes.any),
        deviceType: PropTypes.objectOf(PropTypes.bool),
        footerData: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        localization: {},
        carts: [],
        deviceType: {},
        footerData: []
    };

    faviLabelIcon = (<Icon width='24px' height='24px' viewBox='0 0 24 24' name='promo-badge' />);

    constructor(props) {
        super(props);

        const userInfo = getUserInfo();
        this.siteId = getSiteId();

        let options = Object.values(MARKETING_TYPES).map(type => ({ name: type, isConsented: false }));

        if (userInfo.marketingProfile && userInfo.marketingProfile.options) {
            options = options.map(option => {
                const item = userInfo.marketingProfile.options.find(o => o.name === option.name);
                return item || option;
            });
        }

        this.state = {
            addClass: false,
            cartaddClass: false,
            tabIndex: 0,
            isCheckedTerms: false,
            isCheckedSpecialClauses: false,
            userInfo,
            marketingProfile: {
                ...(userInfo.marketingProfile || {}),
                options,
                displayMarkConstAtCheckout: userInfo.displayMarkConstAtCheckout
            },
            showPromoModal: true
        };
    }

    componentDidMount() {
        if (getActiveCartId()) {
            this.props.actions.getPageContent({ pageId: PAGE_ID.CHECKOUT });
        } else {
            redirectTo(URL_PATHS.CART);
        }
    }

    componentDidUpdate(prevProps) {
        const { placeOrder, orderConfirmationList } = this.props;
        if (placeOrder && prevProps.placeOrder !== placeOrder && orderConfirmationList) {
            const orderNumber = (orderConfirmationList || []).map(item => {
                const { code } = item.orderData || {};
                return code;
            });
            redirectTo(`${URL_PATHS.ORDER_CONFIRMATION}/${orderNumber.join('+')}`);
        }
    }

    reviewToggle = () => {
        const { addClass } = this.state;
        this.setState({ addClass: !addClass });
    };

    summaryToggle = () => {
        const { cartaddClass } = this.state;
        this.setState({ cartaddClass: !cartaddClass });
    };

    deliveryAddressHandler = (e, selectedAddress) => {
        const {
            target: { value }
        } = e;
        this.setState({
            [selectedAddress]: value
        });
    };

    onChangeHandler = (event, input) => {
        const {
            target: { value }
        } = event;
        this.setState({
            [input]: value
        });
    };

    checkboxHandler = event => {
        const {
            target: { checked, name }
        } = event;

        if (name === 'TermsAndCondition') {
            this.setState({
                isCheckedTerms: checked
            });
        } else {
            this.setState({
                isCheckedSpecialClauses: checked
            });
        }
    };

    isPlaceOrderEnabled = () => {
        const { specialClausesComponent } = this.props;
        const { isCheckedTerms, isCheckedSpecialClauses } = this.state;

        return !_isEmpty(specialClausesComponent) ? isCheckedTerms && isCheckedSpecialClauses : isCheckedTerms;
    };

    onTabChangeHandler = i => {
        this.setState({
            tabIndex: i
        });
    };

    formatCartname = (name, count = 0) => {
        const cartName = `${name} (${count})`;
        return name ? cartName : '';
    };

    formatAddress = item => {
        const {
            firstName = '',
            lastName = '',
            companyName = '',
            line1 = '',
            line2 = '',
            town = '',
            postalCode = '',
            country = {}
        } = item || {};
        const { name = '' } = country;
        let firstLine = `${firstName} ${lastName} ${companyName}`;
        firstLine = firstLine.trim() ? `${firstLine} /` : '';
        const l1 = line1.trim();
        const l2 = line2.trim();
        const townValue = town.trim();
        const secondLine = l1 && (l2 || townValue) ? `${l1} / ${l2} ${townValue}` : `${l1} ${l2} ${townValue}`.trim();
        return (
            <>
                <p>{firstLine}</p>
                <p>{secondLine}</p>
                <p> {`${postalCode} ${name}`}</p>
            </>
        );
    };

    formatbillingAddress = item => {
        const {
            firstName = '',
            lastName = '',
            line1 = '',
            town = '',
            postalCode = '',
            country = {},
            phone
        } = item || {};
        const { name = '' } = country;
        let firstLine = `${firstName} ${lastName}`;
        firstLine = firstLine.trim() ? `${firstLine} /` : '';
        const l1 = line1.trim();
        const townValue = town.trim();
        const secondLine = l1 && townValue ? `${l1} / ${townValue}` : `${l1} ${townValue}`.trim();
        return (
            <>
                <p>{firstLine}</p>
                <p>{secondLine}</p>
                <p> {`${postalCode} ${name}`}</p>
                <p> {`${phone}`}</p>
            </>
        );
    };

    placeOrderHandler = () => {
        const { isCheckedTerms, marketingProfile } = this.state;
        const {
            carts,
            checkoutData: { prefilledOrderReferenceNotes }
        } = this.props;
        // const sendMarketing  =  marketingProfile.options.some(({isConsented}) => isConsented);

        // if (sendMarketing) {
        //     this.props.actions.setMarketingCommunicationChannels(
        //         marketingProfile.options.reduce((options, option) => ({
        //             ...options,
        //             [`${option.name.toLowerCase()}Option`]: option.isConsented
        //         }), {})
        //     )
        // }

        if (carts && carts.length && isCheckedTerms) {
            const data = [];
            carts.forEach((item, i) => {
                if (item.cartItemCount > 0) {
                    const key = `cart${i}`;
                    const { cartName, cartDetails: { deliveryAddressList } = {} } = item;
                    const { addresses = [] } = deliveryAddressList;
                    const dAddress = addresses[this.state[`${key}selectedAddress`] || 0];
                    const { id: dId } = dAddress || {};

                    const purchaseOrderNumber =
                        this.state[`${key}refernceInput`] ?? prefilledOrderReferenceNotes?.purchaseOrderNumber ?? '';
                    const orderDescription =
                        this.state[`${key}descriptionInput`] ?? prefilledOrderReferenceNotes?.orderDescription ?? '';
                    const textMessageToDriver =
                        this.state[`${key}driverMessage`] ?? prefilledOrderReferenceNotes?.textMessageToDriver ?? '';
                    const textMessageToDispatcher =
                        this.state[`${key}dispatcherMessage`] ??
                        prefilledOrderReferenceNotes?.textMessageToDispatcher ??
                        '';

                    data.push({
                        subCartId: cartName,
                        purchaseOrderNumber,
                        orderDescription,
                        textMessageToDriver,
                        textMessageToDispatcher,
                        shippingAddressId: dId || null,
                        saveOrderReferenceNotesConfig: {
                            savePurchaseOrderNumber: !!this.state[`${key}referenceInputCheckbox`],
                            saveOrderDescription: !!this.state[`${key}descriptionInputCheckbox`],
                            saveTextMessageToDriver: !!this.state[`${key}driverMessageCheckbox`],
                            saveTextMessageToDispatcher: !!this.state[`${key}dispatcherMessageCheckbox`]
                        }
                    });
                }
            });
            const reqBody = {
                deviceId: getRandomUUID(),
                paymentTypeForms: data,
                addressId: '',
                splitOrder: 'true',
                termsCheck: 'true'
            };
            this.props.actions.postPlaceOrderData({ data: reqBody });
            this.props.actions.clearPageContent([
                PAGE_ID.MY_REWARDS,
                PAGE_ID.SELF_RED,
                PAGE_ID.LOYALTY_ORDERS,
                PAGE_ID.ONBOARDING
            ]);
            setDeliveryDatePreference('');
        }
    };

    deliveryDateData = deliveryDate => {
        const { localization } = this.props;
        return (
            <div automation-id='at-list-of-delivery-date' className={cx('deliveryContent')}>
                <div className={cx('col4', 'sm12', 'leftContent')}>{localization[CHECKOUT_L10N.DELIVERY_DATE]}</div>
                <div className={cx('delivery-checkbox', 'col5', 'sm12', 'rightContent')}>{deliveryDate}</div>
            </div>
        );
    };

    deliveryAddress = (key, addresses) => {
        const { localization, deviceType } = this.props;
        return (
            <div automation-id='at-list-of-shipping-address' className={cx('accordition-inner')}>
                <Accordion
                    title={localization[CHECKOUT_L10N.DELIVERY_ADDRESS]}
                    customTitle={cx('AccTitle')}
                    setActive={!deviceType.isMobile}
                    onChange={() => {
                        buttonClickEvent(
                            buttonClickEventConfigs.checkout.actions.checkout,
                            buttonClickEventConfigs.checkout.labels.checkoutArrowSelection,
                            buttonClickEventConfigs.checkout.categories.checkout
                        );
                    }}
                >
                    <div className={cx('col4', 'sm12')} />
                    <div className={cx('delivery-checkbox', 'col5', 'sm12')}>
                        {addresses.length > 0 &&
                            addresses.map((item, i) => {
                                const checked = i === Number(this.state[`${key}selectedAddress`] || 0);
                                return (
                                    <label key={`delivery-address-${i.toString()}`} className={cx('custom-radio')}>
                                        <input
                                            automation-id='at-shipping-address-list-details'
                                            type='radio'
                                            name='address'
                                            value={i}
                                            checked={checked}
                                            onChange={e => this.deliveryAddressHandler(e, `${key}selectedAddress`)}
                                        />
                                        <span className={cx('checkmark')} />
                                        <p>{this.formatAddress(item)}</p>
                                    </label>
                                );
                            })}
                    </div>
                </Accordion>
            </div>
        );
    };

    desktopBillingAddress = address => {
        const { localization, deviceType } = this.props;

        return (
            <div automation-id='at-billing-address-information' className={cx('accordition-inner')}>
                <Accordion
                    title={localization[CHECKOUT_L10N.BILLING_ADDRESS]}
                    customTitle={cx('AccTitle')}
                    setActive={!deviceType.isMobile}
                >
                    <div className={cx('col4', 'sm12')} />
                    <div className={cx('delivery-checkbox', 'col5', 'sm12')}>
                        <p>
                            {this.formatAddress(address)}
                            {''}
                        </p>
                    </div>
                </Accordion>
            </div>
        );
    };

    trimEndDot = termText => (termText.lastIndexOf('.') === termText.length - 1 ? termText.slice(0, -1) : termText);

    generateTermText = (termText, URL, asterisk = false) => {
        const splitedArr = (termText || '').split(/\{(.*?)}.?/g);

        if (splitedArr.length === 0) return null;

        return (
            <>
                {this.trimEndDot(splitedArr[0])}
                {!_isEmpty(URL) && (
                    <NavLink
                        linkUrl={URL}
                        automation-id='at-terms-and-conditions-link'
                        onClick={() => {
                            buttonClickEvent(
                                buttonClickEventConfigs.checkout.actions.orderSummary,
                                buttonClickEventConfigs.checkout.labels.orderSummaryTermsConditions,
                                buttonClickEventConfigs.checkout.categories.checkout
                            );
                        }}
                    >
                        {splitedArr[1]}
                    </NavLink>
                )}
                {splitedArr.length === 3 && ` ${this.trimEndDot(splitedArr[2])}`}
                {`.`}
                {asterisk && <span className={cx('terms-asterisk')}>*</span>}
            </>
        );
    };

    renderMarketingText = () => {
        const { localization } = this.props;
        const { menuList = [] } = this.props.footerData;

        let accountDataLink;
        menuList.forEach(({ children }) => {
            if (children) {
                const child = children.find(({ uid }) => uid === NAV_COMPONENTS.PERSONAL_DATA);
                if (child) {
                    accountDataLink = child;
                }
            }
        });

        const url = _get(accountDataLink, 'linkUrl', '');

        return this.generateTermText(
            localization[CHECKOUT_L10N.MARKETING_COM_DESC] +
                ` {${localization[CHECKOUT_L10N.MARKETING_COM_LINK].replace('.', '')}}`,
            url
        );
    };

    renderTermsAndConditionLink = () => {
        const { localization } = this.props;
        const { bottomMenuList = [] } = this.props.footerData;
        const termsLink = _find(bottomMenuList, [UNIQUE_ID, COMMON_COMPONENT.FOOTER_TERMS_AND_CONDITIONS]);
        const url = _get(termsLink, 'url', '');

        return this.generateTermText(localization[CHECKOUT_L10N.TERMS_CONDITION], url, true);
    };

    renderSpecialClauses = () => {
        const { localization, specialClausesComponent } = this.props;
        const url = _get(specialClausesComponent, 'url', '');

        return this.generateTermText(localization[CHECKOUT_L10N.SPECIAL_CLAUSES] || '', url);
    };

    renderSubmitOrderButton = () => {
        const {
            localization,
            specialClausesComponent,
            deviceType: { isMobile }
        } = this.props;
        const { isCheckedTerms, isCheckedSpecialClauses } = this.state;

        return (
            <div className={cx('chckplacebtn', 'sm12', 'pull-right')}>
                <div className={isMobile ? '' : cx('col12', 'sm12')}>
                    <div className={cx('checkoutTerms')}>
                        <Checkbox
                            id='placeOrder'
                            name='TermsAndCondition'
                            checked={isCheckedTerms}
                            onChange={this.checkboxHandler}
                            theme={cx('checkoutTermsCheckbox')}
                        />{' '}
                        <label htmlFor='placeOrder' className={cx('placeTxt')}>
                            {this.renderTermsAndConditionLink()}
                        </label>
                    </div>
                </div>
                {!_isEmpty(specialClausesComponent) && (
                    <div className={cx('checkoutTerms')}>
                        <div className={cx('specialClauses')}>
                            <Checkbox
                                id='placeOrderSC'
                                name='SpecialClauses'
                                checked={isCheckedSpecialClauses}
                                onChange={this.checkboxHandler}
                            />{' '}
                            <label htmlFor='placeOrderSC' className={cx('placeTxt')}>
                                {this.renderSpecialClauses()}
                            </label>
                        </div>
                    </div>
                )}
                <div className={cx('checkOutBtn')}>
                    <Button
                        automationId='at-place-order-button'
                        type='button'
                        buttonType='Primary'
                        isDisabled={!this.isPlaceOrderEnabled()}
                        onClick={this.placeOrderHandler}
                    >
                        {localization[CHECKOUT_L10N.PLACE_ORDER_BUTTON]}
                    </Button>
                </div>
            </div>
        );
    };

    renderBulgariaEuroConversionText = () => {  
        const { localization } = this.props;
        if (localization) {
            const ordersCanBePlacedText = localization[CHECKOUT_L10N.ORDERS_CAN_BE_PLACED_TEXT];
            // Replace <a> tag with <a> tag with href
            const modifiedString = parse(ordersCanBePlacedText, {
                    replace: (node) => {
                        if (node.name === 'a' && node.children?.length > 0) {
                            return (
                                <a
                                    className={cx('conversionFontLight')}
                                    href={URL_PATHS.FAQ}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {node.children[0].data}
                                </a>
                            )
                        }
                }
            })
            return modifiedString;
        }
    }

    cartSummary = (cartDetails, cchPricingDataList, cartName, cartItemCount) => {
        const { userInfo } = this.state;
        const {
            localization,
            commonLocalization,
            checkoutData: { showPricingMessage, hidePaymentTermsForIndirectCustomers },
            deviceType: { isMobile }
        } = this.props;
        const {
            subTotal,
            pricingBreakDown = [],
            totalPrice,
            palletRequired,
            paymentTerms,
            weight,
            totalPriceWithTax
        } = cartDetails || {};
        const { name, email } = userInfo || {};
        const totalTax = (_find(pricingBreakDown, pricingData => !_isEmpty(pricingData.totalPriceBeforeTax)) || {})
            .totalPriceBeforeTax;

        let pricingDetails = {};
        if (!_isEmpty(cchPricingDataList)) {
            pricingDetails = cchPricingDataList[0] || {};
        }
        const { pricingInfoStyle, pricingConditions, yourSavings, orderEmpties } = pricingDetails;

        return (
            <div automatio-id='at-shopping-cart-summary-section' className={cx('cartsummarypanel')}>
                <h4>
                    <span className={cx('hiddenSm')}>
                        <Icon width='24px' height='24px' viewBox='0 0 24 24' name='financial-info' />
                        {cartName} {localization[CHECKOUT_L10N.SUMMARY]}
                    </span>
                    <Button
                        onClick={this.summaryToggle}
                        onKeyDown={this.summaryToggle}
                        type='button'
                        className={cx('hiddenLg', 'hiddenMd', 'hiddenXl', 'summerydownupbtn')}
                    >
                        <Icon width='24px' height='24px' viewBox='0 0 24 24' name='financial-info' /> {cartName}{' '}
                        {localization[CHECKOUT_L10N.SUMMARY]}
                        <span className={cx('summerydownup')}>
                            <Icon
                                iconType='svg'
                                width='24px'
                                className={cx('sumup')}
                                height='24px'
                                viewBox='0 0 24 24'
                                name='up'
                            />
                            <Icon
                                iconType='svg'
                                width='24px'
                                className={cx('sumdown')}
                                height='24px'
                                viewBox='0 0 24 24'
                                name='caln-down'
                            />
                        </span>
                    </Button>
                </h4>
                <div className={cx('summeryninner')}>
                    <ul className={cx('summeryList')}>
                        <li automation-id='at-item-text' className={cx('clearfix')}>
                            <span className={cx('totVal')}>{localization[CHECKOUT_L10N.TOTAL_ITEMS]} </span>
                            <span automation-id='at-item-value' className={cx('totPrice')}>
                                {cartItemCount}
                            </span>
                        </li>

                        {subTotal && (
                            <li automation-id='at-sub-total-text' className={cx('clearfix')}>
                                <span className={cx('totVal')}>{localization[CHECKOUT_L10N.SUB_TOTAL]} </span>
                                <span automation-id='at-sub-total-value' className={cx('totPrice')}>
                                    {formatHungaryTousends(subTotal.formattedValue)}
                                </span>
                            </li>
                        )}

                        {pricingInfoStyle === CART_PRICING_TYPES.SAVINGS && (
                            <>
                                {!_isEmpty(yourSavings) && (
                                    <li key='yourSavings'>
                                        <span className={cx('totVal')}>
                                            {commonLocalization[GENERIC_L10N.YOUR_SAVINGS]}{' '}
                                        </span>
                                        <div className={cx('bulgarianCurrencyCol')} style={{ alignItems: 'flex-end' }}>
                                            <span automation-id='at-ur-saving-value' className={cx('totPrice')}>
                                                {formatHungaryTousends(yourSavings.formattedValue)}
                                            </span>
                                            {this.siteId === 'bg' && (
                                                <p className={cx('conversionFont')}>
                                                    ({convertBGNToEUR(yourSavings.value)})
                                                </p>
                                            )}
                                        </div>
                                    </li>
                                )}
                                {pricingConditions &&
                                    pricingConditions.length > 0 &&
                                    pricingConditions
                                        .filter(pc => pc?.key.includes('LF_'))
                                        .map(pc => {
                                            return (
                                                <li key='yourSavings'>
                                                    <span className={cx('totVal')}>{trimString(pc?.key)} </span>
                                                    <span automation-id='at-ur-saving-value' className={cx('totPrice')}>
                                                        {formatHungaryTousends((pc?.value || {}).formattedValue)}
                                                    </span>
                                                </li>
                                            );
                                        })}
                            </>
                        )}

                        {!_isEmpty(cartDetails.selfREDDiscount) && (
                            <li key='selfRedDiscount' className={cx('selfRedDiscount')}>
                                <span className={cx('totVal')}>
                                    {commonLocalization?.[GENERIC_L10N.SELF_RED.SELF_RED_DISCOUNT_LABEL]}
                                </span>
                                <span className={cx('totPrice')}>
                                    {formatHungaryTousends(cartDetails.selfREDDiscount.formattedValue)}
                                </span>
                            </li>
                        )}

                        {!_isEmpty(cartDetails.loyaltyDiscount) && (
                            <li key='loyaltyDiscount' className={cx('loyaltyDiscount')}>
                                <span className={cx('totVal')}>
                                    {commonLocalization[GENERIC_L10N.LOYALTY_DISCOUNT]}{' '}
                                </span>
                                <span className={cx('totPrice')}>
                                    {formatHungaryTousends(cartDetails.loyaltyDiscount.formattedValue)}
                                </span>
                            </li>
                        )}

                        {totalTax && (
                            <li automation-id='at-tax-text' className={cx('clearfix')}>
                                <span className={cx('totVal')}>{localization[CHECKOUT_L10N.TAX]} </span>
                                <span automation-id='at-tax-value' className={cx('totPrice')}>
                                    {formatHungaryTousends(_get(totalTax, 'formattedValue', ''))}
                                </span>
                            </li>
                        )}

                        {!_isEmpty(cartDetails.depositFee) && (
                            <li key='depositFee' className={cx('totalSplit')}>
                                <span className={cx('totVal')}>{commonLocalization[GENERIC_L10N.DEPOSIT_FEE]} </span>
                                <span className={cx('totPrice')}>
                                    {formatHungaryTousends(cartDetails.depositFee.formattedValue)}
                                </span>
                            </li>
                        )}

                        {cartDetails.ecoTax && !_isEmpty(totalPrice) && (
                            <li automation-id='at-eco-tax-text' className={cx('clearfix')}>
                                <span className={cx('totVal')}>{commonLocalization[GENERIC_L10N.ECO_TAX]} </span>
                                <span automation-id='at-tax-value' className={cx('totPrice')}>
                                    {_get(totalPrice, 'currencyIso', '')} {cartDetails.ecoTax}
                                </span>
                            </li>
                        )}

                        {!_isEmpty(orderEmpties) && orderEmpties.formattedValue && (
                            <li automation-id='at-order-empties-text' className={cx('clearfix')}>
                                <span className={cx('totVal')}>{localization[CHECKOUT_L10N.ORDER_EMPTIES]} </span>
                                <span automation-id='at-order-empties-value' className={cx('totPrice')}>
                                    {formatHungaryTousends(orderEmpties.formattedValue)}
                                </span>
                            </li>
                        )}

                        {totalPriceWithTax && (
                            <li automation-id='at-total-text' className={cx('clearfix', 'totalsummery')}>
                                <span className={cx('totVal')}>{localization[CHECKOUT_L10N.ORDER_TOTAL]}</span>
                                <div className={cx('bulgarianCurrencyCol')} style={{ alignItems: 'flex-end' }}>
                                    <span automation-id='at-total-value' className={cx('totPrice')}>
                                        {formatHungaryTousends(totalPriceWithTax.formattedValue)}
                                    </span>
                                    {this.siteId === 'bg' && (
                                        <p className={cx('conversionFontBold')}>
                                            ({convertBGNToEUR(totalPriceWithTax.value)})
                                        </p>
                                    )}
                                </div>
                                {showPricingMessage && (
                                    <div className={cx('totPriceMsg')}>
                                        *<span>{showPricingMessage}</span>
                                    </div>
                                )}
                            </li>
                        )}

                        {pricingInfoStyle === CART_PRICING_TYPES.PRICING &&
                            (pricingConditions || []).map(({ key, value }) => {
                                return (
                                    <li key={key}>
                                        <span className={cx('totVal')}>{trimString(key)}</span>
                                        <span className={cx('totPrice')}>
                                            {formatHungaryTousends(value.formattedValue)}
                                        </span>
                                    </li>
                                );
                            })}

                        {!hidePaymentTermsForIndirectCustomers && (
                            <li automation-id='at-payment-items-text' className={cx('clearfix')}>
                                <span className={cx('totVal')}>{localization[CHECKOUT_L10N.PAYMENT_TERMS]} </span>
                                <span automation-id='at-payment-items-value' className={cx('totPrice')}>
                                    {paymentTerms}
                                </span>
                            </li>
                        )}
                        <li automation-id='at-pallets-required-text' className={cx('clearfix')}>
                            <span className={cx('totVal')}>{`${localization[CHECKOUT_L10N.PALLET]}`}</span>
                            <span automation-id='at-pallets-required-value' className={cx('totPrice')}>
                                {palletRequired}
                            </span>
                        </li>
                        {weight && (
                            <li automation-id='at-total-weight-text' className={cx('clearfix')}>
                                <span className={cx('totVal')}>{localization[CHECKOUT_L10N.WEIGHT]}</span>
                                <span automation-id='at-total-weight-value' className={cx('totPrice')}>
                                    {weight} {localization[CHECKOUT_L10N.WEIGHT_UNIT]}
                                </span>
                            </li>
                        )}
                        {name && (
                            <li className={cx('clearfix')}>
                                <div automation-id='at-personal-data-text' className={cx('col4', 'summeryTxt')}>
                                    {localization[CHECKOUT_L10N.PERSONAL_DATA]}
                                </div>
                                <div automation-id='at-personal-data-value' className={cx('col8', 'summeryVal')}>
                                    <span>{name}</span>
                                </div>
                                <div
                                    automation-id='at-personal-data-value'
                                    className={cx('col8', 'summeryVal', 'email')}
                                >
                                    <span>{email}</span>
                                </div>
                            </li>
                        )}
                    </ul>
                    {this.siteId === 'bg' && (
                        <p className={cx('conversionFontLight')}>
                            {this.renderBulgariaEuroConversionText()}
                        </p>   
                    )}
                    {!isMobile && this.renderSubmitOrderButton()}
                </div>
            </div>
        );
    };

    renderReferenceData = (key, i, cartsLength) => {
        const {
            localization,
            deviceType,
            checkoutData: { prefilledOrderReferenceNotes, messageDispatcher, messageDriver }
        } = this.props;
        const { siteId } = getLocalization();
        const hiddenDescBoxCountries = ['czech', 'slovakia'];
        const isCheckboxDisabled = (cartIndex, checkboxName) => {
            // Check if the checkbox has been clicked in a previous cart
            for (let i = 0; i < cartIndex; i++) {
                if (this.state[`cart${i}${checkboxName}`]) {
                    return true;
                }
            }

            // Check if the checkbox has been clicked in a subsequent cart
            for (let i = cartIndex + 1; i <= cartsLength; i++) {
                if (this.state[`cart${i}${checkboxName}`]) {
                    return true;
                }
            }
            // Checkbox has not been clicked before
            return false;
        };
        return (
            <div className={cx('accordition-inner')}>
                <Accordion
                    title={localization[CHECKOUT_L10N.REFERENCE_DATA]}
                    customTitle={cx('AccTitle')}
                    setActive={!deviceType.isMobile}
                    themeAccordionContent={cx('ReferData')}
                >
                    <div className={cx('col4', 'sm12')} />
                    <div className={cx('delivery-checkbox', 'col7', 'sm11', 'md7', 'ref-checkboxes')}>
                        <div className={cx('input')}>
                            <label className={cx('input-label')} htmlFor='yourReference'>
                                {localization[CHECKOUT_L10N.YOUR_REFERENCE]}
                            </label>
                            <Input
                                name='yourReference'
                                id='yourReference'
                                automation-id='at-your-reference-inputfield'
                                value={
                                    this.state[`${key}refernceInput`] ??
                                    prefilledOrderReferenceNotes?.purchaseOrderNumber
                                }
                                type='text'
                                placeholder={localization[CHECKOUT_L10N.YOUR_REFERENCE]}
                                onChange={e => this.onChangeHandler(e, `${key}refernceInput`)}
                                maxlength='35'
                            />
                        </div>
                        <div className={cx('notesCheckbox')}>
                            <Checkbox
                                name='referenceInputCheckbox'
                                id='referenceInputCheckbox'
                                onChange={e =>
                                    this.setState({ [`${key}referenceInputCheckbox`]: e.target.checked }, () => {
                                        buttonClickEvent(
                                            buttonClickEventConfigs.checkout.actions.referenceData,
                                            buttonClickEventConfigs.checkout.labels.referenceDataSaveNote,
                                            buttonClickEventConfigs.checkout.categories.checkout
                                        );
                                    })
                                }
                                checked={Boolean(this.state[`${key}referenceInputCheckbox`])}
                                disabled={isCheckboxDisabled(i, 'referenceInputCheckbox')}
                                disabledClass={isCheckboxDisabled(i, 'referenceInputCheckbox') ? 'opacity-class' : ''}
                                labelText={localization[CHECKOUT_L10N.SAVE_NOTE]}
                            />
                        </div>
                        {!hiddenDescBoxCountries.includes(siteId) && (
                            <>
                                <div className={cx('input')}>
                                    <label className={cx('input-label')} htmlFor='yourDesc'>
                                        {localization[CHECKOUT_L10N.YOUR_DESCRIPTION]}
                                    </label>
                                    <Input
                                        id='yourDesc'
                                        automation-id='at-your-description-inputfield'
                                        value={
                                            this.state[`${key}descriptionInput`] ??
                                            prefilledOrderReferenceNotes?.orderDescription
                                        }
                                        type='text'
                                        placeholder={localization[CHECKOUT_L10N.YOUR_DESCRIPTION]}
                                        onChange={e => this.onChangeHandler(e, `${key}descriptionInput`)}
                                        maxlength='35'
                                    />
                                </div>
                                <div className={cx('notesCheckbox')}>
                                    <Checkbox
                                        name='descriptionInputCheckbox'
                                        id='descriptionInputCheckbox'
                                        onChange={e =>
                                            this.setState({ [`${key}descriptionInputCheckbox`]: e.target.checked })
                                        }
                                        checked={Boolean(this.state[`${key}descriptionInputCheckbox`])}
                                        disabled={isCheckboxDisabled(i, 'descriptionInputCheckbox')}
                                        disabledClass={
                                            isCheckboxDisabled(i, 'descriptionInputCheckbox') ? 'opacity-class' : ''
                                        }
                                        labelText={localization[CHECKOUT_L10N.SAVE_NOTE]}
                                    />
                                </div>
                            </>
                        )}
                        {messageDriver && (
                            <>
                                <div className={cx('input')}>
                                    <label className={cx('input-label')} htmlFor='driverMessage'>
                                        {localization[CHECKOUT_L10N.DRIVER_MESSAGE]}
                                    </label>
                                    <Input
                                        id='driverMessage'
                                        automation-id='at-message-to-driver-inputfield'
                                        value={
                                            this.state[`${key}driverMessage`] ??
                                            prefilledOrderReferenceNotes?.textMessageToDriver
                                        }
                                        type='text'
                                        placeholder={localization[CHECKOUT_L10N.DRIVER_MESSAGE]}
                                        onChange={e => this.onChangeHandler(e, `${key}driverMessage`)}
                                        maxlength='35'
                                    />
                                </div>

                                <div className={cx('notesCheckbox')}>
                                    <Checkbox
                                        name='driverMessageCheckbox'
                                        id='driverMessageCheckbox'
                                        onChange={e =>
                                            this.setState({ [`${key}driverMessageCheckbox`]: e.target.checked })
                                        }
                                        checked={Boolean(this.state[`${key}driverMessageCheckbox`])}
                                        disabled={isCheckboxDisabled(i, 'driverMessageCheckbox')}
                                        disabledClass={
                                            isCheckboxDisabled(i, 'driverMessageCheckbox') ? 'opacity-class' : ''
                                        }
                                        labelText={localization[CHECKOUT_L10N.SAVE_NOTE]}
                                    />
                                </div>
                            </>
                        )}
                        {messageDispatcher && (
                            <>
                                <div className={cx('input', 'lastinput')}>
                                    <label className={cx('input-label')} htmlFor='dispatcherMessage'>
                                        {localization[CHECKOUT_L10N.DISPATCHER_MESSAGE]}
                                    </label>
                                    <Input
                                        id='dispatcherMessage'
                                        automation-id='at-message-to-dispatcher-inputfield'
                                        value={
                                            this.state[`${key}dispatcherMessage`] ??
                                            prefilledOrderReferenceNotes?.textMessageToDispatcher
                                        }
                                        type='text'
                                        placeholder={localization[CHECKOUT_L10N.DISPATCHER_MESSAGE]}
                                        onChange={e => this.onChangeHandler(e, `${key}dispatcherMessage`)}
                                        maxlength='35'
                                    />
                                </div>
                                <div className={cx('notesCheckbox')}>
                                    <Checkbox
                                        name='dispatcherMessageCheckbox'
                                        id='dispatcherMessageCheckbox'
                                        onChange={e =>
                                            this.setState({ [`${key}dispatcherMessageCheckbox`]: e.target.checked })
                                        }
                                        checked={Boolean(this.state[`${key}dispatcherMessageCheckbox`])}
                                        disabled={isCheckboxDisabled(i, 'dispatcherMessageCheckbox')}
                                        disabledClass={
                                            isCheckboxDisabled(i, 'dispatcherMessageCheckbox') ? 'opacity-class' : ''
                                        }
                                        labelText={localization[CHECKOUT_L10N.SAVE_NOTE]}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </Accordion>
            </div>
        );
    };

    onProductClick = item => {
        try {
            const data = _cloneDeep(item);
            productClickEventGA4(
                productClickOrderConfirmDataLayerFilterGA4(data),
                DATA_LAYER_LISTS.CHECKOUT_CONFIRM_LIST
            );
        } catch (ex) {
            console.error(ex);
        }
    };

    renderItemDetails = (item, index) => {
        const {
            product: { images, name, code } = {},
            basePrice,
            totalPrice,
            quantity,
            freeItem,
            hideProduct,
            promotion
        } = item;
        const image = getImageByFormat(images);
        const { localization } = this.props;
        const productUrl = `${URL_PATHS.PDP}/${code}`;
        const { commonLocalization = {} } = this.props;

        return (
            !hideProduct && (
                <li key={`reviewProducts-${index.toString()}`} className={cx('historylistItem', 'clearfix')}>
                    <div className={cx('td-data', 'col2', 'sm12', 'reviewImg')}>
                        <div onClick={() => this.onProductClick(item)} className={cx('reviewImginner')}>
                            <Image
                                src={image.url}
                                linkUrl={productUrl}
                                alt={image.altText}
                                automationId='at-checkout-item-image'
                                isInternal={image.defaultImage}
                            />
                        </div>
                    </div>
                    <div onClick={() => this.onProductClick(item)} className={cx('td-data', 'col4', 'sm12')}>
                        <h4 automation-id='at-product-value'>
                            <NavLink linkUrl={productUrl}>
                                {freeItem && (
                                    <span className={cx('freeTxt', 'textBrand')}>
                                        {commonLocalization[GENERIC_L10N.FREE]}
                                    </span>
                                )}{' '}
                                <span translate='no'>{name}</span>
                            </NavLink>
                            {promotion !== undefined &&
                                (promotion.promotionMechanic === undefined || promotion.mechanicType === 'B') && (
                                    <>
                                        <span className={cx('promoInfo-tooltip')}>
                                            {this.faviLabelIcon}
                                            <span className={cx('promoInfo-tooltip-text')}>
                                                {!_isEmpty(promotion) && (
                                                    <>
                                                        {!_isEmpty(promotion.description) && (
                                                            <h5>
                                                                <p>{promotion.description}</p>
                                                            </h5>
                                                        )}
                                                    </>
                                                )}
                                            </span>
                                        </span>
                                    </>
                                )}
                        </h4>
                        <h5 automation-id='at-product' className={cx('hiddenLg', 'hiddenMd', 'hiddenXl', 'hiddenSm')}>
                            {localization[CHECKOUT_L10N.PRODUCT_DETAILS]}
                        </h5>
                    </div>
                    <div className={cx('td-data', 'col2', 'sm12')}>
                        <div
                            automation-id='at-product-quantity'
                            className={cx('listItemhead', 'hiddenLg', 'hiddenMd', 'hiddenXl', 'sm6')}
                        >
                            {localization[CHECKOUT_L10N.QUANTITY]}
                        </div>
                        <div automation-id='at-product-quantity-value' className={cx('Item-data', 'sm6')}>
                            {quantity}
                        </div>
                    </div>
                    {!_isEmpty(basePrice) ? (
                        <div className={cx('td-data', 'col2', 'sm12')}>
                            <div
                                automation-id='at-price-exclusive-tax'
                                className={cx('listItemhead', 'hiddenLg', 'hiddenMd', 'hiddenXl', 'sm6')}
                            >
                                {localization[CHECKOUT_L10N.PRICE_EXL_TAX]}
                            </div>
                            <div
                                automation-id='at-price-exclusive-tax-value'
                                className={cx('Item-data', 'sm6', this.siteId === 'bg' ? 'bulgarianCurrencyCol' : '')}
                            >
                                {formatHungaryTousends(basePrice.formattedValue)}
                                {this.siteId === 'bg' && (
                                    <p className={cx('conversionFontNormal')}>({convertBGNToEUR(basePrice?.value)})</p>
                                )}
                            </div>
                        </div>
                    ) : null}
                    {!_isEmpty(totalPrice) ? (
                        <div className={cx('td-data', 'col2', 'sm12', 'priceinclTax')}>
                            <div
                                automation-id='at-price-inclusive-tax'
                                className={cx('listItemhead', 'hiddenLg', 'hiddenMd', 'hiddenXl', 'sm6')}
                            >
                                {localization[CHECKOUT_L10N.PRICE_INCL_TAX]}
                            </div>
                            <div
                                automation-id='at-price-inclusive-tax-value'
                                className={cx('Item-data', 'sm6', this.siteId === 'bg' ? 'bulgarianCurrencyCol' : '')}
                            >
                                {formatHungaryTousends(totalPrice.formattedValue)}
                                {this.siteId === 'bg' && (
                                    <p className={cx('conversionFontNormal')}>({convertBGNToEUR(totalPrice?.value)})</p>
                                )}
                            </div>
                        </div>
                    ) : null}
                </li>
            )
        );
    };

    renderReviewProducts = (orderEntries, isPricingAvailable) => {
        const { localization } = this.props;

        return (
            <div automation-id='at-available-product-list-section' className={cx('checkoutproductreview', 'clearfix')}>
                <h3>
                    <span className={cx('hiddenSm')}>
                        {localization[CHECKOUT_L10N.REVIEW_PRODUCTS]} <span>({(orderEntries || []).length})</span>
                    </span>
                    <button
                        onClick={this.reviewToggle}
                        onKeyDown={this.reviewToggle}
                        type='button'
                        className={cx('hiddenLg', 'hiddenMd', 'hiddenXl', 'mreviewtogglebtn')}
                    >
                        {localization[CHECKOUT_L10N.REVIEW_PRODUCTS]}
                        <span className={cx('reviewtogglebtn')}>
                            <span className={cx('reviewup')}>
                                <Icon iconType='svg' width='16' height='16' viewBox='0 0 16 16' name='Acc-up' />
                            </span>
                            <span className={cx('reviewdown')}>
                                <Icon iconType='svg' width='16' height='16' viewBox='0 0 16 16' name='acc-down' />
                            </span>
                        </span>
                    </button>
                </h3>
                <div className={cx('reviewproductList', 'clearfix')}>
                    <ul>
                        <li className={cx('historylistheader', 'hiddenSm')}>
                            <div className={cx('th-data', 'col2')}>&nbsp;</div>
                            <div className={cx('th-data', 'col4')}>{localization[CHECKOUT_L10N.PRODUCT]}</div>
                            <div className={cx('th-data', 'col2')}>{localization[CHECKOUT_L10N.QUANTITY]}</div>
                            {isPricingAvailable && (
                                <>
                                    <div className={cx('th-data', 'col2')}>
                                        {localization[CHECKOUT_L10N.PRICE_EXL_TAX]}
                                    </div>
                                    <div className={cx('th-data', 'col2')}>
                                        {localization[CHECKOUT_L10N.PRICE_INCL_TAX]}
                                    </div>
                                </>
                            )}
                        </li>

                        {(orderEntries || []).map((entry, index) => {
                            return (
                                <>
                                    {this.renderItemDetails(entry, index, entry)}
                                    {(entry.entries || []).map(empties => {
                                        return this.renderItemDetails(empties, index);
                                    })}
                                </>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    };

    handleMarketingCheckboxes = (name, value) => {
        const { marketingProfile } = this.state;
        this.setState({
            marketingProfile: {
                ...marketingProfile,
                options: marketingProfile.options.map(option => ({
                    ...option,
                    isConsented: name === option.name ? value : option.isConsented
                }))
            }
        });
    };

    handleCloseModal = () => {
        this.setState({ showPromoModal: false });
    };
    
    render() {
        const { tabIndex, showPromoModal } = this.state;
        const {
            checkoutData = {},
            carts,
            localization,
            commonLocalization,
            deviceType: { isMobile }
        } = this.props;
        const isIndirectCustomer = isIndirectUser();
        const hasPopUp = !_isEmpty(checkoutData.promoAllocationMessage);

        const cartDetails = _get(carts, `[${tabIndex}].cartDetails`, {});
        return (
            <Layout showDashboardHeader showOutlet hideNotification={hasPopUp}>
                {!_isEmpty(checkoutData.breadcrumbs) && <BreadCrumbs breadcrumbValues={checkoutData.breadcrumbs} />}
                {hasPopUp && (
                    <Modal defaultCloseBtn={false} showModal={showPromoModal}>
                        <div className={cx('promoAllocationModal')}>
                            <p>{parse(checkoutData.promoAllocationMessage)}</p>
                            <div className={cx('promoAllocationModalBtn')}>
                                <Button type='button' size={cx('Sm')} onClick={() => this.handleCloseModal()}>
                                    {commonLocalization[CHECKOUT_L10N.BTN_CANCEL]?.toUpperCase()}
                                </Button>
                            </div>
                        </div>
                    </Modal>
                )}
                <div automation-id='at-checkout-screen' className={cx('checkoutpage', 'clearfix')}>
                    {!_isEmpty(localization) && !_isEmpty(carts) && (
                        <div className={cx(`${isIndirectCustomer ? 'IndirectLogin' : ''}`)}>
                            <h2 className={cx('clearfix')}>
                                <span className={cx('checkoutbadge', 'hiddenLg', 'hiddenMd', 'hiddenXl')}>
                                    <Icon width='30px' height='30px' viewBox='0 0 30 30' name='checkout-badge' />
                                </span>
                                <span className={cx('checkoutTitle')}>{localization[CHECKOUT_L10N.TITLE]}</span>
                                {isMobile || !cartDetails?.subTotal ? this.renderSubmitOrderButton() : ''}
                            </h2>
                            <Tabs
                                activeTabPanelIndex={tabIndex}
                                onTabChange={this.onTabChangeHandler}
                                themeTabContainerClass={cx('themeTabContainerClass')}
                                automationId='cart-tabs'
                                customTabPanelContentClass={cx('contentWrapper')}
                            >
                                {carts.map((item, i) => {
                                    const {
                                        cartTranslatedName,
                                        cartItemCount = 0,
                                        cchPricingDataList = [],
                                        cartDetails: {
                                            deliveryAddressList,
                                            billingAddress,
                                            entries,
                                            deliveryDate,
                                            updatedDeliveryDate
                                        } = {}
                                    } = item;
                                    const { cartDetails } = item;
                                    const { addresses = [] } = deliveryAddressList || {};
                                    const { address = {} } = billingAddress || {};
                                    const { orderEntries = [] } = entries || {};
                                    const key = `cart${i}`;
                                    const orderDeliveryDate = updatedDeliveryDate || deliveryDate;
                                    const cartsLength = carts.length;
                                    return (
                                        cartItemCount > 0 && (
                                            <TabPanel
                                                key={key}
                                                title={this.formatCartname(cartTranslatedName, cartItemCount)}
                                                index={i}
                                                customTabButtonClass={cx(_isEmpty(cartTranslatedName) && 'titleNone')}
                                            >
                                                <div className={cx('tabsInner')}>
                                                    {/*Tab one Start*/}
                                                    <div className={cx('checkoutleft', 'col8', 'md12', 'sm12')}>
                                                        <div className={cx('checkout-Accordition')}>
                                                            {this.deliveryDateData(orderDeliveryDate)}
                                                            {this.deliveryAddress(key, addresses || [])}
                                                            {!isIndirectCustomer
                                                                ? this.desktopBillingAddress(address)
                                                                : null}
                                                            {/*this.mobileWholeSalerAddress(wholeSalerAddress)*/}
                                                            {this.renderReferenceData(key, i, cartsLength)}
                                                        </div>
                                                        <div
                                                            className={cx(
                                                                `${this.state.addClass ? 'openReviewProducts' : ''}`
                                                            )}
                                                        >
                                                            {this.renderReviewProducts(
                                                                orderEntries,
                                                                !_isEmpty(cartDetails.subTotal)
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className={cx('checkoutright', 'col4', 'md12', 'sm12')}>
                                                        <div
                                                            className={cx(
                                                                `${this.state.cartaddClass ? 'openCartSummery' : ''}`
                                                            )}
                                                        >
                                                            {!_isEmpty(cartDetails.subTotal) &&
                                                                this.cartSummary(
                                                                    cartDetails,
                                                                    cchPricingDataList,
                                                                    cartTranslatedName,
                                                                    cartItemCount
                                                                )}
                                                        </div>
                                                    </div>
                                                    {/*Tab one End*/}
                                                </div>
                                            </TabPanel>
                                        )
                                    );
                                })}
                            </Tabs>
                        </div>
                    )}
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = state => ({
    localization: _get(state, 'pageContent.localization'),
    carts: _get(state, 'checkoutData.cartList') || [],
    checkoutData: _get(state, 'checkoutData') || {},
    placeOrder: _get(state, 'checkoutData.placeOrder'),
    orderConfirmationList: _get(state, 'checkoutData.orderConfirmationList'),
    deviceType: _get(state, 'context.deviceType'),
    footerData: _get(state, 'pageContent.footerData', {}),
    commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`),
    specialClausesComponent: _find(_get(state, 'pageContent.components'), [
        UNIQUE_ID,
        CHECKOUT_L10N.SPECIAL_CLAUSES_LINK_COMPONENT
    ])
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getPageContent,
            postPlaceOrderData,
            clearPageContent,
            setMarketingCommunicationChannels
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
