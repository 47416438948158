import { put, takeLatest, call, select } from 'redux-saga/effects';
import _get from 'lodash/get';
import FavouriteApi from '../interfaces/Favourite/FavouriteApi';
import FactorySaga from './FactorySaga';
import { getFavoriteProductData, getFavoriteProductSaga } from './HomeSaga';
import ProductListingApi from '../interfaces/PLP/PLPApi';

import {
    ADD_TO_FAVOURITE_REQUEST,
    REMOVE_FAVOURITE_REQUEST,
    FAVOURITE_ERROR,
    GET_LIST_FAVORITES,
    UPDATE_FAVOURITES,
    GET_FAVORITES_REQUEST_SUCCESS,
    GET_FAVORITES_REQUEST_ERROR
} from '../actionTypes/FavouriteActionTypes';
import { UPDATE_FAVOURITE_IN_PRODUCT_DETAIL } from '../actionTypes/ProductDetailTypes';
import {
    UPDATE_FAVOURITE_IN_PRODUCT_LIST,
    RESET_PRODUCT_LIST,
    UPDATE_FAVOURITE_IN_CART
} from '../actionTypes/ProductListActionTypes';
import { UPDATE_FAVOURITE_IN_HOME, CLEAR_FAVOURITE_IN_HOME } from '../actionTypes/HomeActionTypes';
import { addToFavoritesEventGA4, removeFromFavoritesEventGA4 } from '../common/GoogleTagManager/GoogleTagManager';

function* addToFavouriteSaga(action) {
    try {
        const result = yield call(FactorySaga, FavouriteApi.saveFavourite, action);
        if (result.isSuccess) {
            try {
                addToFavoritesEventGA4(action.payload.code);
            } catch (ex) {
                console.error(ex);
            }

            if (action?.payload?.product) {
                yield put({
                    type: UPDATE_FAVOURITES,
                    isFavourite: true,
                    code: _get(action.payload, 'code', ''),
                    product: action?.payload?.product
                });
            }

            if (_get(action.payload, 'from', '') === 'PDP') {
                yield put({
                    type: UPDATE_FAVOURITE_IN_PRODUCT_DETAIL,
                    isFavourite: true
                });
                yield put({ type: RESET_PRODUCT_LIST });
                yield put({ type: CLEAR_FAVOURITE_IN_HOME });
            } else if (_get(action.payload, 'from', '') === 'PLP') {
                yield put({
                    type: UPDATE_FAVOURITE_IN_PRODUCT_LIST,
                    isFavourite: true,
                    code: _get(action.payload, 'code', '')
                });
                yield put({ type: CLEAR_FAVOURITE_IN_HOME });
            } else if (_get(action.payload, 'from', '') === 'CART') {
                yield put({
                    type: UPDATE_FAVOURITE_IN_CART,
                    isFavourite: true,
                    code: _get(action.payload, 'code', '')
                });
                yield put({ type: CLEAR_FAVOURITE_IN_HOME });
            } else if (_get(action.payload, 'from', '') === 'HOME') {
                yield put({
                    type: UPDATE_FAVOURITE_IN_HOME,
                    isFavourite: true,
                    code: _get(action.payload, 'code', '')
                });
                yield put({ type: RESET_PRODUCT_LIST });
                yield call(getFavoriteProductSaga, action);
            }
        } else {
            yield put({ type: FAVOURITE_ERROR });
        }
    } catch (err) {
        yield put({ type: FAVOURITE_ERROR });
    }
}

function* removeFavouriteSaga(action) {
    try {
        const result = yield call(FactorySaga, FavouriteApi.removeFavourite, action);

        if (result.isSuccess) {
            try {
                removeFromFavoritesEventGA4(action.payload.code);
            } catch (ex) {
                console.error(ex);
            }

            yield put({
                type: UPDATE_FAVOURITES,
                isFavourite: false,
                code: _get(action.payload, 'code', '')
            });
            if (_get(action.payload, 'from', '') === 'PDP') {
                yield put({
                    type: UPDATE_FAVOURITE_IN_PRODUCT_DETAIL,
                    isFavourite: false
                });
                yield put({ type: RESET_PRODUCT_LIST });
                yield put({ type: CLEAR_FAVOURITE_IN_HOME });
            } else if (_get(action.payload, 'from', '') === 'PLP') {
                yield put({
                    type: UPDATE_FAVOURITE_IN_PRODUCT_LIST,
                    isFavourite: false,
                    code: _get(action.payload, 'code', '')
                });
                yield put({ type: CLEAR_FAVOURITE_IN_HOME });
            } else if (_get(action.payload, 'from', '') === 'HOME') {
                yield put({
                    type: UPDATE_FAVOURITE_IN_HOME,
                    isFavourite: false,
                    code: _get(action.payload, 'code', '')
                });
                yield put({ type: RESET_PRODUCT_LIST });

                const favoriteProductData = yield select(getFavoriteProductData);

                // Checking to fetch more products if no of favorite products are more than 25.
                if ((favoriteProductData.favProducts || []).length === 5 && favoriteProductData.isMoreFavorite) {
                    yield call(getFavoriteProductSaga, action);
                }
            } else if (_get(action.payload, 'from', '') === 'CART') {
                yield put({
                    type: UPDATE_FAVOURITE_IN_CART,
                    isFavourite: false,
                    code: _get(action.payload, 'code', '')
                });
                yield put({ type: CLEAR_FAVOURITE_IN_HOME });
            }
        } else {
            yield put({ type: FAVOURITE_ERROR });
        }
    } catch (err) {
        yield put({ type: FAVOURITE_ERROR });
    }
}

export function* getListFavoriteSaga(action = {}) {
    try {
        action.payload = {
            ...action.payload,
            queryString: `&isFavorite=true&currentPage=${action?.payload?.currentPage || 0}`
        };
        const result = yield call(FactorySaga, ProductListingApi.getProductListing, action);

        if (result.isSuccess) {
            yield put({ type: GET_FAVORITES_REQUEST_SUCCESS, data: { ...result.response.data } });
        } else {
            yield put({ type: GET_FAVORITES_REQUEST_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_FAVORITES_REQUEST_ERROR });
    }
}

export default function* watchFavouriteRequest() {
    yield takeLatest(ADD_TO_FAVOURITE_REQUEST, addToFavouriteSaga);
    yield takeLatest(REMOVE_FAVOURITE_REQUEST, removeFavouriteSaga);
    yield takeLatest(GET_LIST_FAVORITES, getListFavoriteSaga);
}
