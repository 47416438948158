import React, { lazy, Suspense, useEffect } from 'react';
import { clearChatForm } from '../../../common/widgetChat/i18n/chat';
import { setCmsTicketId } from '../../../common/Utils/Utils';

export default function LocalizationLazy(props) {
    useEffect(() => {
        // If the app is loaded in SmartEdit
        if (window !== window.parent) {
            const {
                location: { search }
            } = props;
            if (search != "") {
                const cmsTicketId = search.split("=")[1];
                setCmsTicketId(cmsTicketId);
            }
        }

        if (document.getElementsByClassName("cx-widget")[0]) {
            try {
                clearChatForm();
                window._genesys.widgets.bus.command("WebChat.endChat")
                window._genesys.widgets.bus.command("WebChat.close")
            } catch (error) {
                clearChatForm();
                window._genesys.widgets.bus.command("WebChat.close")
            }
            document.getElementsByClassName("cx-widget")[0].style.display = "none"
        }
    }, [])

    const Localization = lazy(() =>
        import(/* webpackChunkName: "Localization", webpackPrefetch: true */ './Localization')
    );

    return (
        <Suspense fallback={<div />}>
            {/* eslint-disable-next-line */}
            <Localization {...props} />
        </Suspense>
    );
}
