import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import { Link } from 'react-router';
import Image from '../../core/Image/Image';
import Button from '../../core/Button/Button';
import QtyInput from '../../core/QtyInput/QtyInput';
import Icon from '../../core/Icon/Icon';
import { getImageByFormat, formatHungaryTousends, convertBGNToEUR } from '../../../common/Utils/Utils';
import { getSiteId, isReturnEmpties } from '../../../common/UserManager/UserManager';
import { deleteFromCart, updateCart } from '../../../actions/CartAction';
import {
    MAX_PRODUCT_QUANTITY,
    MIN_PRODUCT_QUANTITY,
    CART_PRODUCT_LENGTH,
    CART_L10N,
    URL_PATHS,
    GENERIC_L10N,
    PAGE_ID,
    DATA_LAYER_LISTS
} from '../../../common/Constants';
import * as styles from './CartItemDetails.css';
import { addToCartEventGA4, removeFromCartEventGA4, productClickEventGA4, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';
import { getGTMAddToCartProductGA4, getGTMRemoveFromCartProductGA4, getProductPrice, productClickCartItemDetailDataLayerFilterGA4 } from '../../../service/DataLayerFilter';

const cx = classNames.bind(styles);

export const CartItemDetails = props => {
    const {
        customAddCartDetailsWrapper,
        pageLevelClass,
        customAddcartContainer,
        localization,
        commonLocalization,
        stockAllocation,
        customCartLevels,
        showGrossPrice
    } = props;
    const { entries = {} } = props.cartInfo || {};
    const isReturnEmpty = isReturnEmpties();
    const siteId = getSiteId();

    const handleQtyChange = (product, entryNumber, quantity, qtyType, price, oldQuantity, gaAction) => {
        props.actions.updateCart({ data: { code: product.code, entryNumber, quantity }, pageId: PAGE_ID.CART });
        try {
            const itemPrice = price?.value ? getProductPrice((price.value / oldQuantity).toFixed(2)) : 0;
            let originElement = '';

            if (props.isFrom === DATA_LAYER_LISTS.CART_LIST) {
                originElement = gaAction === 'remove' ? buttonClickEventConfigs.cart.labels.productCardMinus : gaAction === 'add' ? buttonClickEventConfigs.cart.labels.productCardPlus : buttonClickEventConfigs.cart.labels.productCardQuantityInput;
            } else if (props.isFrom === DATA_LAYER_LISTS.MINI_CART_LIST) {
                originElement = gaAction === 'remove' ? buttonClickEventConfigs.minicart.labels.productCardMinus : gaAction === 'add' ? buttonClickEventConfigs.minicart.labels.productCardPlus : buttonClickEventConfigs.minicart.labels.productCardQuantityInput;
            }

            if (quantity > oldQuantity) {
                const gaQuantity = quantity - oldQuantity;
                addToCartEventGA4([getGTMAddToCartProductGA4(0, product.name, product.codeShort, qtyType, gaQuantity, itemPrice, props.isFrom, null, product.brand, product.category)], originElement);
            } else {
                const gaQuantity = oldQuantity - quantity;
                removeFromCartEventGA4([getGTMRemoveFromCartProductGA4(0, product.name, product.codeShort, qtyType, gaQuantity, itemPrice, props.isFrom, null, product.brand, product.category)], originElement);
            }
        } catch (ex) {
            console.error(ex);
        }
    };

    const deleteCartItem = (code, entryNumber, product, quantity, qtyType, price) => {
        props.actions.deleteFromCart({ code, entryNumber });
        try {
            let originElement = '';

            if (props.isFrom === DATA_LAYER_LISTS.CART_LIST) {
                originElement = buttonClickEventConfigs.cart.labels.productCardDelete;
            } else if (props.isFrom === DATA_LAYER_LISTS.MINI_CART_LIST) {
                originElement = buttonClickEventConfigs.minicart.labels.productCardDelete;
            }

            removeFromCartEventGA4([getGTMRemoveFromCartProductGA4(0, product.name, product.codeShort, qtyType, quantity, price?.value ? getProductPrice((price.value / quantity).toFixed(2)) : 0, props.isFrom, null, product.brand, product.category)], originElement);
        } catch (ex) {
            console.error(ex);
        }
    };

    const onProductClick = (...data) => {
        try {
            const [product, quantity, qtyType, price, list] = [..._cloneDeep(data), props.isFrom];
            productClickEventGA4(productClickCartItemDetailDataLayerFilterGA4(product, quantity, qtyType, price?.value ? getProductPrice((price.value / quantity).toFixed(2)) : 0), list);
        } catch (ex) {
            console.error(ex);
        }
    }

    const renderCartItem = (
        {
            entryNumber,
            product = {},
            quantity,
            qtyTypeName,
            qtyType,
            basePrice = {},
            totalPrice = {},
            stockLevel,
            stockColour,
            scheduleLines = [],
            freeItem,
            hideProduct
        },
        isEmpties
    ) => {
        const image = getImageByFormat(product.images);
        const productUrl = `${URL_PATHS.PDP}/${product.code}`;
        const stockMessage = !props.hideATP ? stockLevel : '';
        const stockLength = showGrossPrice
            ? _get(totalPrice, 'formattedValue', '')
            : _get(basePrice, 'formattedValue', '');
        const priceformattedValue = showGrossPrice ? totalPrice.formattedValue : basePrice.formattedValue;
        const price = showGrossPrice ? totalPrice : basePrice;
        const maxQty = stockAllocation?.products.find(({ product: { codeShort } }) => codeShort === product.codeShort)
            ?.quantity;

        return (
            !hideProduct && (
                <div
                    className={cx(
                        'cartItemDetailsWrapper',
                        customAddCartDetailsWrapper,
                        pageLevelClass && 'pageLevelClass',
                        !_isEmpty(scheduleLines) && 'pageScheduleLine'
                    )}
                    automation-id='addcard-details'
                    key={`prod-${product.codeShort}`}
                >
                    <div className={cx('bdrBtmShort', quantity === maxQty ? 'bdrBtmShort__warn' : '')}>
                        <div className={cx('productDetails', 'col9', pageLevelClass && 'sm12')}>
                            <div className={cx('topListView')}>
                                <div onClick={() => onProductClick(product, quantity, qtyType, price)} className={cx('thumbImg')}>
                                    <Image
                                        src={image.url}
                                        linkUrl={productUrl}
                                        alt={image.altText}
                                        automationId='at-cart-item-image'
                                        theme={cx('imgClass')}
                                        isInternal={image.defaultImage}
                                    />
                                </div>
                            </div>
                            <span onClick={() => onProductClick(product, quantity, qtyType, price)} className={cx('rightContent')}>
                                <h4 className={cx('primaryTile')}>
                                    <Link to={productUrl}>
                                        {freeItem && (
                                            <span className={cx('freeTxt', 'textBrand')}>
                                                {commonLocalization[GENERIC_L10N.FREE]}
                                            </span>
                                        )}{' '}
                                        <span translate='no'>{product.name.substring(0, CART_PRODUCT_LENGTH)}</span>
                                        {product.name.length > CART_PRODUCT_LENGTH && <span>...</span>}
                                    </Link>
                                </h4>
                                <span className={cx('secondaryTitle')}>
                                    {commonLocalization[GENERIC_L10N.SKU]} {product.codeShort}
                                </span>
                                {stockMessage && !isEmpties && (
                                    <span className={cx('stockContainer')}>
                                        <span
                                            className={cx('inStock', 'inlineLevel', `${stockColour || 'default'}bgATP`)}
                                        >
                                            {stockMessage}
                                        </span>
                                        {!_isEmpty(scheduleLines) && (
                                            <span
                                                className={cx(
                                                    'scheduleLines',
                                                    'inlineLevel',
                                                    stockMessage.length >= 15 && 'lineAlignment'
                                                )}
                                            >
                                                {formatHungaryTousends(scheduleLines[0].formattedValue)}
                                            </span>
                                        )}
                                    </span>
                                )}
                            </span>
                        </div>

                        <div
                            className={cx(
                                'col3',
                                'inStockWrapper',
                                (stockLength.length >= 9 || (stockMessage && stockMessage.length >= 15)) &&
                                    'inStockWrapperLevel'
                            )}
                        >
                            <div className={cx('bulgarianCurrencyCol')}>
                            {!_isEmpty(priceformattedValue) && (
                                <span className={cx('priceValue', pageLevelClass && 'display-none')}>
                                    {formatHungaryTousends(priceformattedValue)}
                                </span>
                            )}
                            {siteId === 'bg' && (
                                <p>
                                    ({convertBGNToEUR(price?.value)})
                                </p>
                            )}
                            </div>
                            {stockMessage && !isEmpties && (
                                <span
                                    className={cx(
                                        'inStock',
                                        `${stockColour || 'default'}bgATP`,
                                        stockLength.length > 0 && 'instockPrice',
                                        stockLength.length >= 14 && 'inStockLevel',
                                        stockMessage.length >= 15 && 'availStockLevel',
                                        _isEmpty(priceformattedValue) && 'hidePriceLevel'
                                    )}
                                >
                                    {stockMessage}
                                </span>
                            )}
                        </div>

                        <div
                            className={cx(
                                'col4',
                                'md4',
                                'sm3',
                                'pad0',
                                pageLevelClass ? 'pageQtyMain' : 'qtyMain',
                                isEmpties && 'emptiesNone'
                            )}
                        >
                            {!isEmpties && (
                                <QtyInput
                                    id={`qty-${product.codeShort}`}
                                    theme={cx('inputTheme')}
                                    themeIncreBtn={cx('themeIncreBtn')}
                                    themeDescrBtn={cx('themeDescrBtn')}
                                    minValue={MIN_PRODUCT_QUANTITY}
                                    maxValue={maxQty || MAX_PRODUCT_QUANTITY}
                                    value={quantity}
                                    onChange={(qty, gaAction) => handleQtyChange(product, entryNumber, qty, qtyType, price, quantity, gaAction)}
                                />
                            )}
                            {pageLevelClass && !isEmpties && (
                                <Button
                                    type='linkbutton'
                                    className={cx('deleteLabel', 'textBrand')}
                                    onClick={() =>
                                        deleteCartItem(product.code, entryNumber, product, quantity, qtyType, price)
                                    }
                                >
                                    {' '}
                                    {localization[CART_L10N.DELETE_BTN]}
                                </Button>
                            )}
                        </div>
                        <div className={cx('col4', 'md4', 'sm3', 'bdrRght', isEmpties && 'emptiesNone')}>
                            {!isEmpties && !isReturnEmpty && (
                                <span className={cx('csText', 'csLabel')}>{qtyTypeName}</span>
                            )}
                        </div>
                        <div className={cx('col4', 'md4', 'sm3', 'pageLevelNone', isEmpties && 'emptiesNone')}>
                            {!isEmpties && (
                                <div className={cx('delItem')}>
                                    <div className={cx('float-left')}>
                                        <Button
                                            type='linkbutton'
                                            className={cx('deleteLabel', 'quickView', 'textBrand')}
                                            onClick={() =>
                                                deleteCartItem(
                                                    product.code,
                                                    entryNumber,
                                                    product,
                                                    quantity,
                                                    qtyType,
                                                    price
                                                )
                                            }
                                        >
                                            {' '}
                                            {localization[CART_L10N.DELETE_BTN]}
                                        </Button>
                                    </div>
                                    <Button
                                        type='linkbutton'
                                        className={cx('float-right', pageLevelClass ? 'mobileIcon' : 'delIcon')}
                                        onClick={() =>
                                            deleteCartItem(product.code, entryNumber, product, quantity, qtyType, price)
                                        }
                                    >
                                        <Icon
                                            iconType='svg'
                                            width='20px'
                                            height='20px'
                                            viewBox='0 0 23 23'
                                            name='delete-product'
                                        />
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className={cx('pageGridLevel')}>
                            {!_isEmpty(priceformattedValue) && (
                                <a className={cx('mobileDisplay')}>
                                    <div className={cx('bulgarianCurrencyColCart')}>
                                        <div className={cx('price')} style={{marginBottom: siteId === 'bg' ? 0 : '6px'}}>
                                            {formatHungaryTousends(priceformattedValue)}
                                        </div>
                                        {siteId === 'bg' && <p>({convertBGNToEUR(price?.value)})</p>}
                                    </div>
                                    <div className={cx('priceText')}>{localization[CART_L10N.PRICE_EX_TAX]}</div>
                                </a>
                            )}
                        </div>
                        <div className={cx('pageGridLevel')}>
                            {!_isEmpty(totalPrice.formattedValue) && (
                                <a className={cx('mobileDisplay')}>
                                    <div className={cx('bulgarianCurrencyColCart')}>
                                        <div className={cx('price')} style={{marginBottom: siteId === 'bg' ? 0 : '6px'}}>
                                            {formatHungaryTousends(totalPrice.formattedValue)}
                                        </div>
                                        {siteId === 'bg' && <p>({convertBGNToEUR(totalPrice?.value)})</p>}
                                    </div>
                                    <div className={cx('priceText')}>{localization[CART_L10N.PRICE_IN_TAX]}</div>
                                </a>
                            )}
                        </div>
                        {quantity === maxQty && (
                            <div className={cx('maxQtyWarn')}>
                                <Icon iconType='svg' width='20px' height='20px' viewBox='0 0 23 23' name='info-red' />{' '}
                                {commonLocalization[CART_L10N.STOCK_ALLOCATION_LIMIT_MESSAGE]}
                            </div>
                        )}
                    </div>
                </div>
            )
        );
    };

    return (
        <div className={cx('addCartContainer', customAddcartContainer, pageLevelClass && 'shoppingCartPageContainer')}>
            {(entries.orderEntries || []).map(entry => {
                return (
                    // <div className={cx('cartLevels', customCartLevels)} key={`${'shoppingCart'}-${entry.product.codeShort}-${entry.product.qtyTypeName}`}>
                    <div key={entry.product.codeShort} className={cx('cartLevels', customCartLevels)}>
                        {renderCartItem(entry)}
                        {pageLevelClass &&
                            (entry.entries || []).map(empties => {
                                return renderCartItem(empties, true);
                            })}
                    </div>
                );
            })}
        </div>
    );
};

CartItemDetails.propTypes = {
    customAddCartDetailsWrapper: PropTypes.string,
    pageLevelClass: PropTypes.bool,
    customAddcartContainer: PropTypes.string,
    customCartLevels: PropTypes.string,
    commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hideATP: PropTypes.bool,
    showGrossPrice: PropTypes.bool
};

CartItemDetails.defaultProps = {
    customAddCartDetailsWrapper: '',
    pageLevelClass: false,
    customAddcartContainer: '',
    customCartLevels: '',
    commonLocalization: {},
    hideATP: false,
    showGrossPrice: false
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            deleteFromCart,
            updateCart
        },
        dispatch
    )
});

export default connect(null, mapDispatchToProps)(CartItemDetails);
