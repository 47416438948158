export const ro = {
  "ro": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
    "callus": {
      "CallUsTitle": "Apeleaza-ne",
      "ContactsHeader": "Poti sa ne apelezi la oricare din urmatoarele numere...",
      "CancelButtonText": "Anulare",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Already on a call? Let us <a role='link' tabindex='0' class='cx-cobrowse-link'>browse with you</a></span>",
      "CoBrowse": "Deschide Co-browse",
      "CoBrowseWarning": "Co-browse permite consultantului sa vada si sa controleze  fereastra calculatorului pentru ati oferi asistenta. Cand esti intr-un apel online cu un consultant, solicita un cod pentru a deschide Co-browse si introdu-l mai jos. Nu esti inca intr-un apel? Anuleaza aceasta fereastra pentru a te intoarce in pagina Call Us",
      "SubTitle": "Poti sa ne apelezi la oricare din urmatoarele numere...",
      "AriaWindowLabel": "Fereastra de apel",
      "AriaCallUsClose": "Inchide fereastra de apel",
      "AriaBusinessHours": "Program de lucru",
      "AriaCallUsPhoneApp": "Deschide aplicatia pentru apel",
      "AriaCobrowseLink": "Deschide sesiunea Co-browse",
      "AriaCancelButtonText": "Anuleaza fereastra de apel"
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector": {
      "Title": "Assistenta in timp real",
      "SubTitle": "Cum ati dori sa luam legatura?",
      "WaitTimeTitle": "Timp de asteptare",
      "AvailableTitle": "Disponibil",
      "AriaAvailableTitle": "Disponibil",
      "UnavailableTitle": "Indisponibil",
      "CobrowseButtonText": "Titlu Cobrowse",
      "CallbackTitle": "Receptioneaza un apel",
      "CobrowseSubTitle": "Conectarea agentului este necesara pentru aceasta.",
      "AriaClose": "Inchidere Asistenta Online",
      "AriaWarning": "Atentie",
      "AriaAlert": "Alert",
      "minute": "minute",
      "minutes": "minute",
      "AriaWindowLabel": "Fereastra de asistenta"
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse": {
      "agentJoined": "Consultantul s-a conectat la sesiune.",
      "youLeft": "Ati parasit sesiunea. Co-browse s-a terminat.",
      "sessionTimedOut": "Sesiunea a expirat. Co-browse s-a incheiat.",
      "sessionInactiveTimedOut": "Sesiunea a expirat. Co-browse s-a incheiat.",
      "agentLeft": "Consultantul a parasit sesiunea. Co-browse s-a incheiat.",
      "sessionError": "A aparut o eroarea neasteptata. Co-browse s-a incheiat.",
      "sessionStarted": "S-a deschis sesiunea Co-browse. Asteptam consultantul sa se conecteze la sesiune…",
      "navRefresh": "Consultantul a actualizat pagina. Reincarcare.",
      "navBack": "Consultantul a apasat butonul \"Back\". Pagina se reincarca.",
      "navForward": "Consultantul a apasat butonul \"Forward\". Pagina se reincarca.",
      "navUrl": "Consultantul a solicitat navigarea. Pagina se reincarca.",
      "navFailed": "Solicitarea de navigare a consultantului are eroare.",
      "toolbarContent": "ID sesiune: {sessionId}",
      "contentMasked": "Continutul nu este disponibil consultantului.",
      "contentMaskedPartially": "O parte a continutului nu este disponibil consultantului.",
      "exitBtnTitle": "Iesire sesiune Co-browse",
      "areYouOnPhone": "Sunteti la telefon cu consultantul nostru?",
      "areYouOnPhoneOrChat": "Sunteti la telefon sau chat cu consultantul nostru?",
      "connectBeforeCobrowse": "Este nevoie sa fiti conectat cu consultantul nostru pentru a continua sesiunea de co-browsing. Va rog sa ne apelati sau sa deschideti un chat online si apoi incepeti sesiunea Co-browse din nou.",
      "sessionStartedAutoConnect": "Sesiunea Co-browse deschisa. Asteptam consultantul sa se conecteze la sesiune…",
      "browserUnsupported": "Din pacate, browserul tau nu este compatibil.<br><br> Browserele compatibile sunt: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 and above</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 and above</a></li></ul>",
      "chatIsAlreadyRunning": "Chatul este activ in alta pagina.",
      "modalYes": "Da",
      "modalNo": "Nu"
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar": {
      "SidebarTitle": "Ai nevoie de ajutor?",
      "ChannelSelectorName": "Asistenta online",
      "ChannelSelectorTitle": "Primeste asistenta de la unul din consultanti acum",
      "SearchName": "Cautare",
      "SearchTitle": "Cautare",
      "CallUsName": "Apeleaza-ne",
      "CallUsTitle": "Detalii de apelare",
      "CallbackName": "Callback",
      "CallbackTitle": "Primeste un apel",
      "ClickToCallName": "Click pentru apel",
      "ClickToCallTitle": "Solicita un nr de telefon pentru relatii clienti",
      "SendMessageName": "Trimite mesaj",
      "SendMessageTitle": "Trimite mesaj",
      "WebChatName": "Chat online",
      "WebChatTitle": "Chat online",
      "AriaClose": "Inchide meniul de ajutor"
    },
    "webchat": {
      "ChatButton": "Chat",
      "ChatStarted": "Chat deschis",
      "ChatEnded": "Chat inchis",
      "AgentNameDefault": "Consultant",
      "AgentConnected": "<%Agent%> Conectat",
      "AgentDisconnected": "<%Agent%> Deconectat",
      "BotNameDefault": "Bot",
      "BotConnected": "<%Bot%> Conectat",
      "BotDisconnected": "<%Bot%> Deconectat",
      "SupervisorNameDefault": "Supervizor",
      "SupervisorConnected": "<%Agent%> Conectat",
      "SupervisorDisconnected": "<%Agent%> Deconectat",
      "AgentTyping": "...",
      "AriaAgentTyping": "Consultantul scrie",
      "AgentUnavailable": "Ne pare rau. Nu sunt consultanti disponibili. Te rog incearca mai tarziu.",
      "ChatTitle": "Chat Online",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Min",
      "ChatFormFirstName": "Prenume",
      "ChatFormLastName": "Nume",
      "ChatFormNickname": "Pseudonim",
      "ChatFormName": "Nume",
      "ChatFormPlaceholderName": "Optional",
      "ChatFormCustomerId": "Cod Client",
      "ChatFormPlaceholderCustomerId": "Optional",
      "ChatFormSubjectPassword": "Parola",
      "ChatFormSubjectService": "Serviciu",
      "ChatFormSubjectOrder": "Comanda",
      "ChatFormSubjectFinance": "Financiar",
      "ChatFormEmail": "Email",
      "ChatFormSubject": "Subiect",
      "ChatFormPlaceholderFirstName": "Obligatoriu",
      "ChatFormPlaceholderLastName": "Obligatoriu",
      "ChatFormPlaceholderNickname": "Optional",
      "ChatFormPlaceholderEmail": "Obligatoriu",
      "ChatFormPlaceholderSubject": "Optional",
      "ChatFormSubmit": "Start Chat",
      "AriaChatFormSubmit": "Start Chat",
      "ChatFormCancel": "Anulare",
      "AriaChatFormCancel": "Anulare Chat",
      "ChatFormClose": "Inchide",
      "ChatInputPlaceholder": "Scrie mesajul tau aici",
      "ChatInputSend": "Trimite",
      "AriaChatInputSend": "Trimite",
      "ChatEndQuestion": "Esti sigur ca doresti sa finalizezi aceasta sesiune de chat?",
      "ChatEndCancel": "Anulare",
      "ChatEndConfirm": "Finalizare chat",
      "AriaChatEndCancel": "Anulare",
      "AriaChatEndConfirm": "Finalizare",
      "ConfirmCloseWindow": "Esti sigur ca doresti sa inchizi chat-ul",
      "ConfirmCloseCancel": "Anulare",
      "ConfirmCloseConfirm": "Inchide",
      "AriaConfirmCloseCancel": "Anulare",
      "AriaConfirmCloseConfirm": "Inchide",
      "ActionsDownload": "Descarca transcrierea",
      "ActionsEmail": "Trimite pe email transcrierea",
      "ActionsPrint": "Tipareste transcrierea",
      "ActionsCobrowseStart": "Start Co-browse",
      "AriaActionsCobrowseStartTitle": "Deschide sesiunea Co-browse",
      "ActionsSendFile": "Ataseaza fisiere",
      "AriaActionsSendFileTitle": "Deschide o fereastra pentru incarcare fisiere",
      "ActionsEmoji": "Trimite Emoji",
      "ActionsCobrowseStop": "Iesire Co-browse",
      "ActionsVideo": "Invitatie pentru Video Chat",
      "ActionsTransfer": "Transfer",
      "ActionsInvite": "Invita",
      "InstructionsTransfer": "Deschide acest link pe un alt dispozitiv pentru a transfera sesiunea de chat</br></br><%link%>",
      "InstructionsInvite": "Trimite acest link la alta persoana pentru a o adauga in aceasta sesiune de chat</br></br><%link%>",
      "InviteTitle": "Ai nevoie de ajutor?",
      "InviteBody": "Spune-ne daca te putem ajuta",
      "InviteReject": "Nu, multumesc",
      "InviteAccept": "Deschide chatul",
      "AriaInviteAccept": "Accepta invitatia si deschide sesiunea de chat",
      "AriaInviteReject": "Anuleaza invitatia",
      "ChatError": "A existat o problema la deschiderea sesiunii de chat. Te rog incearca din nou.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Da",
      "ChatErrorDefaultButton": "Nu",
      "AriaChatErrorPrimaryButton": "Da",
      "AriaChatErrorDefaultButton": "Nu",
      "DownloadButton": "Descarca",
      "AriaDownloadButton": "Descarca fisierul",
      "FileSent": "a fost trimis:",
      "FileTransferRetry": "Reincearca",
      "AriaFileTransferRetry": "Reincearca transferul fisierului",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Anulare",
      "AriaFileTransferCancel": "Anulare transfer fisier",
      "RestoreTimeoutTitle": "Chat finalizat",
      "RestoreTimeoutBody": "Sesiunea anterioare de chat a expirat. Doriti sa incepeti o noua sesiune?",
      "RestoreTimeoutReject": "Nu, multumesc",
      "RestoreTimeoutAccept": "Start chat",
      "AriaRestoreTimeoutAccept": "Accepta invitatia si incepe sesiunea de chat",
      "AriaRestoreTimeoutReject": "Anuleaza invitatia",
      "EndConfirmBody": "Doriti sa finalizati sesiunea de chat?",
      "EndConfirmAccept": "Finalizare chat",
      "EndConfirmReject": "Anulare",
      "AriaEndConfirmAccept": "Finalizare chat",
      "AriaEndConfirmReject": "Anulare",
      "SurveyOfferQuestion": "Doriti sa participati la un chestionar?",
      "ShowSurveyAccept": "Da",
      "ShowSurveyReject": "Nu",
      "AriaShowSurveyAccept": "Da",
      "AriaShowSurveyReject": "Nu",
      "UnreadMessagesTitle": "necitit",
      "AriaYouSaid": "Ai spus",
      "AriaSaid": "spus",
      "AriaSystemSaid": "Sistemul a spus",
      "AriaWindowLabel": "Fereastra Chat Online",
      "AriaMinimize": "Micsoreaza Chat Online",
      "AriaMaximize": "Mareste Chat Online",
      "AriaClose": "Inchide Chat Online",
      "AriaEmojiStatusOpen": "Fereastra pentru selectie Emoji este deschisa",
      "AriaEmojiStatusClose": "Fereastra pentru selectie Emoji  este inchisa",
      "AriaEmoji": "emoji",
      "AriaEmojiPicker": "selectie Emoji",
      "AriaCharRemaining": "Caractere remase",
      "AriaMessageInput": "Fereastra de mesaje",
      "AsyncChatEnd": "Finalizare Chat",
      "AsyncChatClose": "Inchide fereastra",
      "AriaAsyncChatEnd": "Inchide Chat",
      "AriaAsyncChatClose": "Inchide fereastra",
      "DayLabels": [
        "Duminica",
        "Luni",
        "Marti",
        "Miercuri",
        "Joi",
        "Vineri",
        "Simbata"
      ],
      "MonthLabels": [
        "Ianuarie",
        "Februarie",
        "Martie",
        "Aprilie",
        "Mai",
        "Iunie",
        "Iulie",
        "August",
        "Septembrie",
        "Octombrie",
        "Noiembrie",
        "Decembrie"
      ],
      "todayLabel": "Azi",
      "Errors": {
        "102": "Prenumele este obligatoriu.",
        "103": "Numele este obligatoriu.",
        "161": "Te rog sa introduci numele.",
        "201": "Fisierul nu poate fi trimis.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Numarul maxim de fisiere atasate ar putea fi depasit (<%MaxFilesAllowed%>).</p>",
        "202": "Fisierul nu poate fi trimis.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Limita de upload si/sau numarul maxim de fisiere atasate ar putea fi depasit (<%MaxAttachmentsSize%>).</p>",
        "203": "Fisierul nu poate fi trimis.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Tipul de fisier nu este permis.</p>",
        "204": "Ne pare rau, dar mesajul tau este prea lung. Te rog sa scrii un mesaj mai scurt.",
        "240": "Ne pare rau, dar nu putem incepe o noua sesiune de chat acum. Te rog sa incerci din nou mai tarziu.",
        "364": "Adresa de mail incorecta.",
        "401": "Ne pare rau, dar nu putem autoriza sesiunea chat. Doresti sa deschizi o noua sesiune de chat?",
        "404": "Ne pare rau, dar nu putem identifica sesiunea de chat anterioara. Doresti sa deschizi o noua sesiune de chat?",
        "500": "Ne pare rau, a aparut o eroare neprevazuta. Doresti sa inchizi sesiunea chat si apoi sa deschizi una noua?",
        "503": "Ne pare rau, serviciul este momentan indisponibil sau ocupat. Doresti sa inchizi sesiunea chat si apoi sa deschizi una noua?",
        "ChatUnavailable": "Ne pare rau, dar nu putem incepe o noua sesiune chat acum. Te rog sa incerci mai tarziu.",
        "CriticalFault": "Sesiunea chat s-a inchis neasteptat, dintr-o eroare necunoscuta. Ne cerem scuze pentru inconvenient.",
        "StartFailed": "A fost o eroare la deschiderea sesiunii chat. Te rog verifica conexiunea si ca ai introdus corect toate informatiile solicitate, apoi incearca din nou.",
        "MessageFailed": "Mesajul tau nu a fost receptionat cu success. Te rog incearca din nou.",
        "RestoreFailed": "Ne pare rau, dar nu putem sa restauram sesiunea chat din cauza unei erori necunoscute.",
        "TransferFailed": "Nu putem sa transferam chatul acum. Te rog sa incerci mai tarziu.",
        "FileTransferSizeError": "Fisierul nu poate fi trimis.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Marimea fisierului depaseste limita maxima (<%MaxSizePerFile%>).</p>",
        "InviteFailed": "Nu se poate genera o invitatie acum. Te rog sa incerci mai tarziu.",
        "ChatServerWentOffline": "Transmiterea mesajelor dureaza mai mult decat de obicei. Ne pare rau pentru intarziere.",
        "RestoredOffline": "Transmiterea mesajelor dureaza mai mult decat de obicei. Ne pare rau pentru intarziere.",
        "Disconnected": "<div style='text-align:center'>Conexiune pierduta</div>",
        "Reconnected": "<div style='text-align:center'>Conexiune restaurata</div>",
        "FileSendFailed": "Fisierul nu poate fi trimis.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>A fost o deconectare neasteptata. Incerci din nou?</p>",
        "Generic": "<div style='text-align:center'>A aparut o eroare neprevazuta.</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Tip de fisier incorect. Numai imagini sunt permise.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "Marimea fisierului depaseste limita permisa.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "Numarul de fisiere atasate a depasit limita maxima.",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Configuratia de transport 'x-api-key' este invalida.",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Configuratia de transport 'endpoint' este invalida.",
        "pureengage-v3-rest-INVALID_NICKNAME": "Prenumele este obligatoriu.",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "Ne pare rau, dar nu putem autoriza sesiunea de chat.",
        "purecloud-v2-sockets-400": "Ne pare rau, ceva nu functioneaza. Te rog verifica conexiunea si ca ai trimis toate informatiile solicitate corect, apoi incearca din nou.",
        "purecloud-v2-sockets-500": "Ne pare rau, a aparut o eroare neprevazuta.",
        "purecloud-v2-sockets-503": "Ne pare rau, serviciul este momentan indisponibil."
      }
    }
  }
}
