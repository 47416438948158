import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import styles from './StatsComponent.css';
import { FSA } from '../../../../common/Constants';

const cx = classNames.bind(styles);

const StatsComponent = () => {
    const localization = useSelector(state => state.pageContent.localization);
    return (
        <div className={cx('statsWrapper')}>
            <div className={cx('statsContainer')}>
                <div className={cx('statsInnerLeft')}>
                    <p>{localization[FSA.SECTION_ONE_TEXT_2]}</p>
                </div>
                <div className={cx('statsInnerRight')}>
                    {[
                        { stat: localization[FSA.SECTION_ONE_TEXT_3], desc: localization[FSA.SECTION_ONE_TEXT_4] },
                        { stat: localization[FSA.SECTION_ONE_TEXT_5], desc: localization[FSA.SECTION_ONE_TEXT_6] },
                        { stat: localization[FSA.SECTION_ONE_TEXT_7], desc: localization[FSA.SECTION_ONE_TEXT_8] }
                    ].map(item => (
                        <div className={cx('statsInnerRightItem')} key={Math.random()}>
                            <div className={cx('statsInnerRightItemStat')}>{item.stat}</div>
                            <div className={cx('statsInnerRightItemDesc')}>{item.desc}</div>
                        </div>
                    ))}
                </div>
            </div>
            <p className={cx('statsLink')}>{localization[FSA.SECTION_ONE_TEXT_9]}</p>
        </div>
    );
};

export default StatsComponent;
