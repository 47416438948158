import { put, takeLatest, call } from 'redux-saga/effects';
import _get from 'lodash/get';
import FactorySaga from './FactorySaga';
import ProductApi from '../interfaces/PDP/PDPApi';

import {
    GET_PRODUCT_DETAIL_REQUEST,
    GET_PRODUCT_DETAIL_SUCCESS,
    GET_PRODUCT_DETAIL_ERROR
} from '../actionTypes/ProductDetailTypes';

export function* getProductDetailSaga(action) {
    try {
        const result = yield call(FactorySaga, ProductApi.getProductPage, action);

        if (result.isSuccess) {
            yield put({ type: GET_PRODUCT_DETAIL_SUCCESS, data: _get(result.response, 'data') });
        }
    } catch (err) {
        yield put({ type: GET_PRODUCT_DETAIL_ERROR });
    }
}

export default function* watchProductDetail() {
    yield takeLatest(GET_PRODUCT_DETAIL_REQUEST, getProductDetailSaga);
}
