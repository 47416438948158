import {
    GET_ORDER_LIST_REQUEST,
    CANCEL_ORDER_REQUEST,
    REORDER_ORDER_REQUEST,
    UPDATE_ORDER_ID_REQUEST,
    UPDATE_CREATION_PERIOD_REQUEST,
    UPDATE_PROCESS_TYPE_REQUEST,
    CLEAR_ORDER_SEARCH_FORM,
    RESET_ORDER_LIST,
    CLEAR_REDUCER
} from '../actionTypes/OrderListActionTypes';

export const clearOrderListReducer = () => ({
    type: CLEAR_REDUCER
})

export const getOrderList = payload => ({
    type: GET_ORDER_LIST_REQUEST,
    payload
});

export const cancelOrderRequest = id => ({
    type: CANCEL_ORDER_REQUEST,
    id
});

export const reOrderItems = payload => ({
    type: REORDER_ORDER_REQUEST,
    payload
});

export const updateOrderId = payload => ({
    type: UPDATE_ORDER_ID_REQUEST,
    payload
});

export const updateCreationPeriod = payload => ({
    type: UPDATE_CREATION_PERIOD_REQUEST,
    payload
});

export const updateProcessType = payload => ({
    type: UPDATE_PROCESS_TYPE_REQUEST,
    payload
});

export const clearOrderForm = payload => ({
    type: CLEAR_ORDER_SEARCH_FORM,
    payload
});

export const resetOrderList = () => ({
    type: RESET_ORDER_LIST
});
