import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { redirectTo } from '../../../../common/Utils/Utils';
import Button from '../../../core/Button/Button';
import Modal from '../../../core/Modal/Modal';
import * as styles from './OnboardingJourney.css';
import { setMinicartMessageShown } from '../../../../actions/HomePageAction';

const cx = classNames.bind(styles);

export const OnboardingJourney = ({
    actions,
    automationId,
    modalLocalization,
}) => {
    const [showModal, setShowModal] = useState(true);
    if (!showModal) return null;

    const closeModal = () => {
        actions.setMinicartMessageShown({ data: true });
        setShowModal(false);
    };

    const handleConfigure = () => {
        closeModal();
        redirectTo('account/personaldata');
    };

    return (
        <Modal
            showModal={showModal}
            className={cx('minicartJourneyOverlay')}
            modalTheme={cx('minicartJourneyTheme')}
            modalTitleTheme={cx('minicartJourneyTitle')}
            modalTitleText={cx('minicartJourneyTitleText')}
            themeContent={cx('minicartJourneyContent')}
            themeClose={cx('themeClose')}
            themeModalBlock={cx('minicartJourneyWrapper')}
            title={<div className={cx('titleWrapper')}>
                <div className={cx('newLabel')}>{modalLocalization.newLabel}</div>
                <div className={cx('titleWrapper__text')}>{modalLocalization.pic1title}</div>
            </div>}
            titleAutomationId={automationId}
            onClose={closeModal}
        >
            <>
                <img src='/assets/svg/minicart-journey/pic1.svg' alt='add_card'
                    className={cx('pic1')}
                />
                <div>{modalLocalization.pic1line1}</div>
                <div>{modalLocalization.pic1line3}.</div>
                <div className={cx('minicartJourneyTitleText')}>{modalLocalization.pic2title}</div>
                <img src='/assets/svg/minicart-journey/pic2.svg' alt='add_card'
                    className={cx('pic1')}
                />
                <div className={cx('digitLine')}>
                    <img src='/assets/svg/minicart-journey/1.svg' alt='add_card'
                        className={cx('digitIcon')}
                    />
                    <>{modalLocalization.pic2line1}</>
                </div>
                <div className={cx('digitLine')}>
                    <img src='/assets/svg/minicart-journey/2.svg' alt='add_card'
                        className={cx('digitIcon')}
                    />
                    <>{modalLocalization.pic2line2}</>
                    <img src='/assets/svg/minicart-journey/settings-line.svg' alt='settings icon'
                        className={cx('settingsIcon')}
                    />
                </div>
                {/* temporary hidden */}
                {/* <div className={cx('digitLine')}>
                    <img src='/assets/svg/minicart-journey/3.svg' alt='add_card'
                        className={cx('digitIcon')}
                    />
                    <>{modalLocalization.pic2line3}</>
                    <img src='/assets/svg/minicart-journey/settings-line.svg' alt='settings icon'
                        className={cx('settingsIcon')}
                    />
                </div> */}
                <div className={cx('minicartJourneyButtons')}>
                    <Button
                        automation-id="at-minicart-journey-modal-checkout-button"
                        className={cx('minicartJourneyButton', 'minicartJourneyCheckoutButton')}
                        type='button'
                        size='Sm'
                        buttonType='Primary'
                        onClick={handleConfigure}
                    >{modalLocalization.configureBtn}</Button>
                    <Button
                        automation-id="at-minicart-journey-modal-cancel-button"
                        className={cx('minicartJourneyButton', 'minicartJourneyCancelButton')}
                        type='button'
                        size='Sm'
                        buttonType='Secondary'
                        onClick={closeModal}
                    >{modalLocalization.laterBtn}</Button>
                </div>
            </>
        </Modal>
    );
};


OnboardingJourney.propTypes = {
    automationId: PropTypes.string,
    modalLocalization: PropTypes.shape({
        configureBtn: PropTypes.string,
        pic1line1: PropTypes.string,
        pic1line2: PropTypes.string,
        pic1line3: PropTypes.string,
        pic1title: PropTypes.string,
        newLabel: PropTypes.string,
        laterBtn: PropTypes.string,
        pic2line1: PropTypes.string,
        pic2line2: PropTypes.string,
        pic2line3: PropTypes.string,
        pic2title: PropTypes.string,
    })
};

OnboardingJourney.defaultProps = {
    automationId: 'at-onboarding-journey-modal',
    modalLocalization: {
      configureBtn : "CONFIGURE NOW",
      pic1line1: "If you feel you are waiting too long for your mini cart to be updated, then this new feature is just for you. Change your mini cart settings to:",
      pic1line2: "“quick mini cart” or “no mini cart”",
      pic1line3: "and your order placement speed will greatly increase.",
      pic1title: "PLACE YOUR ORDER FASTER THAN EVER BY CONFIGURING YOUR MINI CART",
      newLabel: "NEW",
      laterBtn: "MAYBE LATER",
      pic2line1: "Configuration Settings in Account >Personal details",
      pic2line2: "Mini cart settings icon",
      pic2line3: "Shopping cart settings icon",
      pic2title: "MANAGE YOUR MINI CART CONFIGURATION SETTING FROM 3 DIFFERENT PLACES",
    },
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            setMinicartMessageShown,
        },
        dispatch
    )
});

export default connect(null, mapDispatchToProps)(OnboardingJourney);
