import PropTypes from 'prop-types';
import React, { Component, cloneElement, Children } from 'react';
import classNames from 'classnames/bind';
import * as styles from './Tabs.css';

const cx = classNames.bind(styles);

export default class Tabs extends Component {
    static propTypes = {
        activeTabPanelIndex: PropTypes.number,
        automationId: PropTypes.string,
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
        onTabChange: PropTypes.func,
        themeTab: PropTypes.string,
        themeTabContainerClass: PropTypes.string,
        customTabPanelContentClass: PropTypes.string
    };

    static defaultProps = {
        activeTabPanelIndex: null,
        onTabChange: () => {},
        themeTab: '',
        themeTabContainerClass: '',
        customTabPanelContentClass: '',
        children: null
    };

    constructor(props) {
        super(props);
        /* istanbul ignore next */
        this.state = {
            activeTabPanelIndex: props.activeTabPanelIndex
        };
    }

    onTabSelect = index => {
        this.setState({
            activeTabPanelIndex: index
        });
        this.props.onTabChange(index);
    };

    getTabContentConfig(tabContent, mapIndex) {
        if (tabContent) {
            const tabProps = tabContent.props || {};
            const tabPanelIndex = tabProps.index;
            const isTabActive = tabPanelIndex === this.state.activeTabPanelIndex;
            if (isTabActive) {
                this.activePanelContent = tabProps.children && tabProps.children.props.children;
                this.activeTitle = tabProps.title;
            }

            return cloneElement(tabContent, {
                key: tabPanelIndex,
                id: `tab${mapIndex}`,
                isTabActive: tabPanelIndex === this.state.activeTabPanelIndex,
                onSelect: this.onTabSelect,
                automationId: this.props.automationId
            });
        }
    }

    render() {
        const childTabContents = Children.map(this.props.children, this.getTabContentConfig, this);
        const { themeTab, themeTabContainerClass, customTabPanelContentClass } = this.props;
        return (
            <div role='tablist' className={cx('tab', themeTab)}>
                <ul className={cx('tabContainerClass', themeTabContainerClass)}>{childTabContents}</ul>
                {this.state.activeTabPanelIndex != null ? (
                    <div
                        aria-label={this.activeTitle}
                        role='tabpanel'
                        className={cx('tabPanelContentClass', customTabPanelContentClass)}
                    >
                        {this.activePanelContent}
                    </div>
                ) : null}
            </div>
        );
    }
}
