/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { PRE_LOGIN_UID, URL_PATHS } from '../../../common/Constants';
import { findComponent } from '../../../common/Utils/Utils';
import Icon from '../../../components/core/Icon/Icon';
import * as styles from './Banner.css';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';
import { getLocalization } from '../../../common/UserManager/UserManager';

const cx = classNames.bind(styles);

const Banner = ({ cmsComponents, handleCustomSignUpPopup, signupLink, handlePopup }) => {
    if (!cmsComponents) {
        return null;
    }

    const localization = getLocalization();
    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);

    const backGroundImgComp = findComponent(
        cmsComponents,
        PRE_LOGIN_UID.BACKGROUND_BANNER_COMPONENTS.BACKGROUND_IMG_UID
    );
    const titleComponent = findComponent(cmsComponents, PRE_LOGIN_UID.BACKGROUND_BANNER_COMPONENTS.TITLE_UID);
    const paragraphComponent = findComponent(cmsComponents, PRE_LOGIN_UID.BACKGROUND_BANNER_COMPONENTS.PARAGRAPH_UID);
    const loginComponent = findComponent(cmsComponents, PRE_LOGIN_UID.BACKGROUND_BANNER_COMPONENTS.LOGIN_LINK_UID);
    const signUpComponent = findComponent(cmsComponents, PRE_LOGIN_UID.BACKGROUND_BANNER_COMPONENTS.SIGNUP_BTN_UID);
    const greekCountries = ['gr', 'cy'];
    const irishCountries = ['ni', 'roi'];

    return (
        <section
            className={cx('banner')}
            style={
                backGroundImgComp && {
                    backgroundImage: `url(${assetBaseUrl}${backGroundImgComp.url})`
                }
            }
        >
            <div className={cx('banner-icon-wrapper')}>
                <Icon
                    className={cx('icon')}
                    iconType='svg'
                    width='auto'
                    height='25px'
                    viewBox='0 0 20 32'
                    name='mouse'
                />
            </div>
            <div className={cx('banner-content')}>
                <h1 className={cx('banner-title')}>{titleComponent?.content?.split('<br>').filter(Boolean)[0]}</h1>
                <h3 className={cx('banner-subtitle')}>{titleComponent?.content?.split('<br>').filter(Boolean)[1]}</h3>
                <p className={cx('banner-paragraphcontent')}>{paragraphComponent?.content}</p>
                <div className={cx('banner-login-links')}>
                    {greekCountries.includes(localization?.siteId) ? (
                        <Link to={URL_PATHS.REGISTER} className={cx('signup-btn')}>
                            {signUpComponent?.content}
                        </Link>
                    ) : (
                        <div
                            role='presentation'
                            onKeyDown={e => {
                                handleCustomSignUpPopup(e);
                                buttonClickEvent(
                                    buttonClickEventConfigs.preLogin.actions.preLoginFirstBanner,
                                    buttonClickEventConfigs.preLogin.labels.preLoginFirstBannerSignUp,
                                    buttonClickEventConfigs.preLogin.categories.preLogin
                                );
                            }}
                            className={cx('signup-btn')}
                            onClick={e => {
                                handleCustomSignUpPopup(e);
                                buttonClickEvent(
                                    buttonClickEventConfigs.preLogin.actions.preLoginFirstBanner,
                                    buttonClickEventConfigs.preLogin.labels.preLoginFirstBannerSignUp,
                                    buttonClickEventConfigs.preLogin.categories.preLogin
                                );
                            }}
                        >
                            {signupLink?.content}
                        </div>
                    )}
                    <div className={cx('link-row')}>
                        {loginComponent?.content?.split('<a>')[0]}
                        {irishCountries.includes(localization?.siteId) ? (
                            <div
                            className={cx('login-link')}
                            role='presentation'
                            onKeyDown={e => {
                                handlePopup(e);
                                buttonClickEvent(
                                    buttonClickEventConfigs.preLogin.actions.preLoginFirstBanner,
                                    buttonClickEventConfigs.preLogin.labels.preLoginFirstBannerLogin,
                                    buttonClickEventConfigs.preLogin.categories.preLogin
                                )
                            }}
                            onClick={e => {
                                handlePopup(e);
                                buttonClickEvent(
                                    buttonClickEventConfigs.preLogin.actions.preLoginFirstBanner,
                                    buttonClickEventConfigs.preLogin.labels.preLoginFirstBannerLogin,
                                    buttonClickEventConfigs.preLogin.categories.preLogin
                                )
                            }}
                        >
                            {loginComponent?.content?.split('<a>')[1].split('</a>')[0]}
                        </div>
                        ) : (
                        <Link
                            className={cx('login-link')}
                            to={URL_PATHS.LOGIN}
                            onClick={() =>
                                buttonClickEvent(
                                    buttonClickEventConfigs.preLogin.actions.preLoginFirstBanner,
                                    buttonClickEventConfigs.preLogin.labels.preLoginFirstBannerLogin,
                                    buttonClickEventConfigs.preLogin.categories.preLogin
                                )
                            }
                        >
                            {loginComponent?.content?.split('<a>')[1].split('</a>')[0]}
                        </Link>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
