import React, { Component, Suspense } from 'react';
import Storage from '../../../common/Storage/Storage';
import { isLocalizationSelected, isUserLoggedIn, setLocalization } from '../../../common/UserManager/UserManager';
import { LOGIN_INFO, URL_PATHS } from '../../../common/Constants';
import { redirectTo } from '../../../common/Utils/Utils';
import { setCmsTicketId } from '../../../common/Utils/Utils';

/**
 * This is HOC used bu routex.jsx for wrapping the React page component to control the application
 * authentication to view the page.
 *
 * Usage: <Route path={PAGE_URL_PATH} component={AppGuard(PageComponent)} />
 * PageComponent - The page component which take cares of rendering the page.
 *
 * @param {React.Component} PageComponent
 */
function AppGuard(PageComponent) {
    return class WithAppGuard extends Component {
        constructor(props) {
            super(props);
            const {
                location: { pathname }
            } = props;
            // Allow, if the PageComponent is login page otherwise check for token presence.
            this.isValidAuth = pathname.indexOf(URL_PATHS.LOGIN) !== -1 || isUserLoggedIn();
        }

        componentDidMount() {
            this.validateSession();

            // If the app is loaded in SmartEdit
            if (window !== window.parent) {
                const {
                    location: { search }
                } = this.props;
                if (search != "") {
                    const cmsTicketId = search.split("=")[1];
                    setCmsTicketId(cmsTicketId);
                }
            }
        }

        // TODO: Need to check whether we need to take the user to login / l10n page during user interaction with the page.
        componentDidUpdate() {
            this.validateSession();
        }

        /**
         * To check for access token and localization info and based on the unavailable info, this will take the user
         * to the respective page.
         */
        validateSession = () => {
            let redirectUrl;

            //If the localization data is defined via GET params, set them to the local storage
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            // if (urlParams.get('I10n')) {
            //     setLocalization(JSON.parse(urlParams.get('I10n')))
            // }
            //https://localhost:3093/login?siteId=swiss&country=swiss&lang=en&language=English&username=asq00072&password=XXXXXX
            if (urlParams.get('siteId') && urlParams.get('country')) {
                const countryData = {
                    [LOGIN_INFO.SITE_ID]: urlParams.get('siteId'),
                    [LOGIN_INFO.COUNTRY]: urlParams.get('country'),
                    [LOGIN_INFO.LANG]: urlParams.get('lang'),
                    [LOGIN_INFO.LANGUAGE]: urlParams.get('language')
                }
                setLocalization(countryData)
            }

            /* istanbul ignore else */
             if (!isLocalizationSelected()) {
                redirectUrl = URL_PATHS.PRE_LOGIN;
            } else if (!this.isValidAuth) {
                redirectUrl = URL_PATHS.LOGIN;
            }
            /* istanbul ignore else */
            if (redirectUrl) {
                const {
                    location: { pathname, search }
                } = this.props;
                Storage.setData(LOGIN_INFO.REDIRECT_FROM, `${pathname}${search}`);
                redirectTo(redirectUrl);
            }

            return true;
        };

        render() {
            return this.isValidAuth ? (
                <Suspense fallback={<div />}>
                    {/* eslint-disable-next-line */}
                    <PageComponent {...this.props} />
                </Suspense>
            ) : null;
        }
    };
}

export default AppGuard;
