/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import Icon from '../Icon/Icon';
import * as styles from './SlidePanel.css';
import Button from '../../core/Button/Button';
import { redirectTo } from '../../../common/Utils/Utils';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

export class SlidePanel extends PureComponent {
    static propTypes = {
        isOpenPanel: PropTypes.bool,
        onClosePanel: PropTypes.func,
        panelTitle: PropTypes.string,
        children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
        panelIcon: PropTypes.string,

        panelTitleAlignment: PropTypes.oneOf(['default', 'right']),
        slidePanelHeaderTheme: PropTypes.string,
        themeDetailsPanel: PropTypes.string,
        themePanelContent: PropTypes.string,
        themePanelTitle: PropTypes.string,
        themePanelIcon: PropTypes.string,
        themeRgtIcon: PropTypes.string
    };

    static defaultProps = {
        isOpenPanel: false,
        panelTitle: '',
        children: '',
        panelIcon: 'arrow-left',
        panelTitleAlignment: 'default',
        slidePanelHeaderTheme: '',
        themeDetailsPanel: '',
        themePanelContent: '',
        themePanelTitle: '',
        themePanelIcon: '',
        themeRgtIcon: ''
    };

    /* istanbul ignore next */
    constructor(props) {
        super(props);
        this.closePanel = this.closePanel.bind(this);
        this.state = {
            animateOnLoad: props.isOpenPanel,
            // This state represents if the panel was completely closed (after transition)
            closed: !props.isOpenPanel
        };
    }

    componentWillUnmount() {
        // eslint-disable-next-line no-unused-expressions
        document && document.body.classList.remove(styles.scrollLock);
    }

    onOpenTransitionEnd = () => {
        this.setState({
            closed: false
        });
    };

    onCloseTransitionEnd = () => {
        this.setState({
            closed: true
        });
    };

    onTransitionEnd = () => {
        // eslint-disable-next-line no-unused-expressions
        this.props.isOpenPanel ? this.onOpenTransitionEnd() : this.onCloseTransitionEnd();
    };

    getStyleToogleWrapper() {
        const toogleWrapper = this.props.isOpenPanel
            ? `${styles.toggleWrapper} ${styles.activeToggleWrapper}`
            : styles.toggleWrapper;
        return toogleWrapper;
    }

    getStyleTooglePanel() {
        const { isOpenPanel } = this.props;
        const tooglePanel = this.props.isOpenPanel
            ? `${styles.detailsPanel} ${styles.activeDetailsPanel}`
            : styles.detailsPanel;
        // eslint-disable-next-line no-unused-expressions
        isOpenPanel && document.body.classList.add(styles.scrollLock);
        return tooglePanel;
    }

    closePanel = e => {
        /* istanbul ignore next */
        if (document.body.classList.contains(styles.scrollLock)) {
            document.body.classList.remove(styles.scrollLock);
            e.preventDefault();
            this.props.onClosePanel();
        }
    };

    handleOutsideClick = e => {
        if (e.target.classList.contains('SlidePanel-sliderWrapper')) {
            this.closePanel(e);
        }
    };

    redirectToSetting = () => {
        try {
            buttonClickEvent(buttonClickEventConfigs.minicart.actions.settings, buttonClickEventConfigs.minicart.labels.settingsAccountSettings, buttonClickEventConfigs.minicart.categories.minicart);
        } catch (ex) {
            console.error(ex);
        }
        redirectTo('/account/settings/personalData');
    };


    render() {
        const {
            panelIcon,
            panelTitleAlignment,
            slidePanelHeaderTheme,
            themeDetailsPanel,
            themePanelContent,
            themePanelTitle,
            themePanelIcon,
            themeRgtIcon
        } = this.props;
        const getIcon = (
            <Icon
                iconType='svg'
                width='32px'
                height='32px'
                viewBox='0 0 24 25'
                className={cx('panelIcon', themePanelIcon)}
                name={panelIcon}
                automationId={panelIcon}
            />
        );
        return (
            <div className={cx(styles.cover, 'sliderWrapper')} onClick={this.handleOutsideClick}>
                <div
                    data-automation-id='at-active-panel'
                    className={cx(
                        this.getStyleTooglePanel(),
                        {
                            'animate-on-load': this.state.animateOnLoad
                        },
                        themeDetailsPanel
                    )}
                    onTransitionEnd={this.onTransitionEnd}
                    onAnimationEnd={this.onTransitionEnd}
                >
                    <div
                        className={cx(styles.panelHeader, slidePanelHeaderTheme, `panel-title-${panelTitleAlignment}`)}
                    >
                        <Button
                            type='linkbutton'
                            onClick={this.redirectToSetting}
                            >
                            <Icon
                            className={cx('settingsIcon')}
                            iconType='svg'
                            width='20'
                            height='20'
                            viewBox='0 0 20 20'
                            name='settings-line'
                            />
                        </Button>
           
                        <button
                            id='btnSideclosePanel'
                            className={cx('backIcon', themeRgtIcon)}
                            onClick={this.closePanel}
                            data-automation-id='at-slide-back-button'
                        >
                            {getIcon}
                            <span
                                data-automation-id='at-panel-title'
                                className={cx('panelTitle', themePanelTitle)}
                                tabIndex={-42}
                            >
                                {this.props.panelTitle}
                            </span>
                        </button>
                    </div>
                    <div className={cx('sliderContent')}>
                        {!this.state.closed ? (
                            <div
                                data-automation-id='at-panel-content'
                                className={cx('panelContent', themePanelContent)}
                            >
                                {this.props.children}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default SlidePanel;
