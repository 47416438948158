import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _get from 'lodash/get';
import Icon from '../../core/Icon/Icon';
import Tooltip from '../../core/Tooltip/Tooltip';
import { getWholeSaler, submitSelectedWholeSaler } from '../../../actions/DeliveryAction';
import * as styles from './WholesalerReports.css';
import { getMiniCartWorkingWay } from '../../../common/UserManager/UserManager';
import { MINI_CART_WORKING_WAYS } from '../../../common/Constants';

const cx = classNames.bind(styles);

export const WholesalerReports = props => {
    const { actions, wholeSalerId } = props;
    const wholeSalerList = props.wholeSalerList || [];
    const defaultWholesaler = _find(wholeSalerList, 'defaultWholesaler', {});
    const isOptimizedMiniCartFlow = getMiniCartWorkingWay() === MINI_CART_WORKING_WAYS.MINICARTNOCRM;
    const isSkipMiniCartFlow = getMiniCartWorkingWay() === MINI_CART_WORKING_WAYS.SKIPMINICART;
    let wholeSalerSelected = wholeSalerId && _find(wholeSalerList, ['id', wholeSalerId], defaultWholesaler);

    if (_isEmpty(wholeSalerSelected)) {
        wholeSalerSelected = !_isEmpty(defaultWholesaler) ? defaultWholesaler : wholeSalerList[0];
    }

    const selectWholeOutlet = (e, saleInfo) => {
        e && e.preventDefault();
        actions.submitSelectedWholeSaler({ data: { wholeSalerId: saleInfo.id, isOptimizedMiniCartFlow: isOptimizedMiniCartFlow } });
    };

    useEffect(() => {
        if (_isEmpty(props.wholeSalerList)) {
            props.actions.getWholeSaler();
        }
    }, []);

    const renderDialog = () => {
        return (
            <div className={cx('miniOutletWrapper')} automation-id='at-list-of-Whole-outlet'>
                {wholeSalerList &&
                    wholeSalerList.length > 0 &&
                    wholeSalerList.map(saleInfo => {
                        const { name1 } = saleInfo;
                        const activeClass = wholeSalerSelected.name1 === name1 && 'activeClass';
                        return (
                            <a
                                className={cx('outletList', activeClass)}
                                href='./'
                                role='button'
                                key={`${saleInfo.partnerGUID}-${Math.random()}`}
                                onClick={e => {
                                    selectWholeOutlet(e, saleInfo);
                                }}
                            >
                                <div className={cx('customItemList', 'itemlistSecond')}>
                                    <ul className={cx('address')}>
                                        <div className={cx('companyName')}>{saleInfo.name1}</div>
                                        <div className={cx('street')}>
                                            {saleInfo.street} {saleInfo.houseNo}
                                        </div>
                                        <div className={cx('city')}>
                                            <div className={cx('city')}>
                                                {saleInfo.postalCode} {saleInfo.city}
                                            </div>
                                            <div className={cx('city')}>ID: {saleInfo.id}</div>
                                        </div>
                                    </ul>
                                </div>
                            </a>
                        );
                    })}
            </div>
        );
    };

    return (
        <div className={cx('wholesalerReportsContainer')}>
            <Tooltip
                renderTooltipContents={renderDialog()}
                CustomClassName={cx('customClassName')}
                customTooltipContent={cx('customTooltipContent')}
            >
                <div className={cx('deliveryContent')} automation-id='at-delivery-information'>
                    <span className={cx('textEllipsis', `${isSkipMiniCartFlow ? 'wholeSalerTextWith' : ''}`)}>{(wholeSalerSelected || {}).name1}</span>
                    <span automation-id='at-delivery-name-dropdown'>
                        <Icon
                            className={cx('downArrowSvg')}
                            iconType='svg'
                            width='16px'
                            height='16px'
                            viewBox='0 0 16 16'
                            name='down'
                        />
                    </span>
                </div>
            </Tooltip>
        </div>
    );
};

WholesalerReports.propTypes = {
    wholeSalerList: PropTypes.arrayOf(PropTypes.object),
    wholeSalerId: PropTypes.string
};

WholesalerReports.defaultProps = {
    wholeSalerList: [],
    wholeSalerId: ''
};

const mapStateToProps = state => {
    return {
        wholeSalerList: _get(state, 'cartDetails.wholesaler.wholeSalerList'),
        wholeSalerId: _get(state, 'cartDetails.wholeSalerId')
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators({ getWholeSaler, submitSelectedWholeSaler }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WholesalerReports);
