import { CLEAR_REDUCER, GET_CHECKOUT_DATA_REQUEST, POST_PLACE_ORDER_DATA_REQUEST } from '../actionTypes/CheckoutActionTypes';

export const clearCheckoutReducer = () => ({
    type: CLEAR_REDUCER
})

export const getCheckoutData = payload => ({
    type: GET_CHECKOUT_DATA_REQUEST,
    payload
});

export const postPlaceOrderData = payload => ({
    type: POST_PLACE_ORDER_DATA_REQUEST,
    payload
});
