import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Textarea from '../../core/Textarea/Textarea';
import Input from '../../core/Input/Input';
import * as styles from './TemplateDetailsForm.css';

const cx = classNames.bind(styles);

const TemplateDetailsForm = props => {
    const { orderTemplateData, onChangeName, onChangeDesc, isEditMode, localization } = props;

    return (
        <div className={`${cx('templateDetailsForm')}`}>
            <div className={`${cx('templateDetailsFormList')}`}>
                <div className={`${cx('temprow')}`}>
                    <div className={cx('tempdata', 'col4', 'sm12')}>
                        {!isEditMode && (
                            <label className={cx('sm6')} htmlFor='name'>
                                {localization.name}
                            </label>
                        )}
                        {isEditMode && (
                            <div className={cx('input', 'sm12')}>
                                <Input
                                    automationId='at-template-name-inputfield'
                                    name='name'
                                    value={orderTemplateData.newName}
                                    onChange={e => onChangeName(e.target.value)}
                                />
                            </div>
                        )}
                        {!isEditMode && (
                            <div automation-id='at-template-name' className={cx('frmTxt', 'sm6')}>
                                {orderTemplateData.name}
                            </div>
                        )}
                    </div>
                    <div className={cx('tempdata', 'col4', 'sm12')}>
                        <label className={cx('sm6')} htmlFor='DateSaved'>
                            {localization.dateSaved}
                        </label>
                        <div automation-id='at-template-date-saved' className={cx('frmTxt', 'sm6')}>
                            {orderTemplateData.dateSaved}
                        </div>
                    </div>
                    <div className={cx('tempdata', 'col3', 'sm12')}>
                        <label htmlFor='Qty' className={cx('sm6')}>
                            {localization.sku}
                        </label>
                        <div automation-id='at-item-qty' className={cx('frmTxt', 'sm6')}>
                            {orderTemplateData.qty}
                        </div>
                    </div>
                </div>
            </div>
            <div className={cx('tempFrmDes', 'col12', 'sm12')}>
                {!isEditMode && <label>{localization.description}</label>}
                {isEditMode && (
                    <div className={cx('input')}>
                        <Textarea
                            automationId='at-template-description'
                            name='description'
                            value={orderTemplateData.newDescription}
                            onChange={e => onChangeDesc(e.target.value)}
                            maxlength={255}
                        />
                    </div>
                )}
                {!isEditMode && <div className={cx('frmTxt')}>{orderTemplateData.description}</div>}
            </div>
        </div>
    );
};

TemplateDetailsForm.propTypes = {
    orderTemplateData: PropTypes.objectOf(PropTypes.any),
    onChangeName: PropTypes.func,
    onChangeDesc: PropTypes.func,
    isEditMode: PropTypes.bool,
    localization: PropTypes.objectOf(PropTypes.any)
};

TemplateDetailsForm.defaultProps = {
    orderTemplateData: null,
    onChangeName: null,
    onChangeDesc: null,
    isEditMode: false,
    localization: null
};

export default TemplateDetailsForm;
