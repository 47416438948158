import _get from 'lodash/get';
import sortBy from 'lodash/sortBy';

const templateDetailsFillteredData = templateData => {
    let transformedData = {};

    if (templateData) {
        const tempData = templateData;
        transformedData = {
            id: tempData.code,
            name: tempData.name,
            description: tempData.description,
            dateSaved: tempData.formattedSaveTime,
            qty: tempData.totalItems
        };

        let items = [];
        const entries = _get(tempData, 'entries', []);
        for (let i = 0; i < entries.length; i += 1) {
            const productData = entries[i].product;
            const prodObj = {
                index: entries[i].entryNumber,
                imageUrl: _get(productData, 'images[0].url', ''),
                description: productData.description,
                link: productData.url,
                qty: entries[i].quantity,
                displayQty: entries[i].quantity,
                unitCode: entries[i].qtyTypeName,
                unitCodeForBackend: entries[i].qtyType,
                id: productData.code,
                name: productData.name,
                price: entries[i]?.totalPrice?.value, // GA4 recommended metric
                currency: entries[i]?.totalPrice?.currencyIso // GA4 recommended metric
            };
            items.push(prodObj);
        }
        items = sortBy(items, ['index']);
        transformedData.items = items;
        transformedData.nonDeletedItemCount = items.length;
    }

    if (templateData.breadcrumbs) {
        transformedData.breadcrumbs = templateData.breadcrumbs;
    }

    return {
        ...transformedData
    };
};

export default templateDetailsFillteredData;
