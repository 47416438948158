import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { CONTENT } from '../common/Api';

export default class SearchApi {
    static getSearchPage(payload) {
        const { baseInfo, searchValue, isReturnEmpties, isMarketingMaterials } = payload;
        let urlParam = isReturnEmpties ? `&term=${searchValue}&useEmpties=true` : `&term=${searchValue}`;
        urlParam = isMarketingMaterials ? `&term=${searchValue}&useMarketingMaterials=true` : `&term=${searchValue}`;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}/products/suggestions/searchBox${CONTENT.QUERY}${urlParam}`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }
}
