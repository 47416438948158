import _get from 'lodash/get';
import _merge from 'lodash/merge';
import RestClient from '../../src/interfaces/RestClient/RestClient';
import settings from '../../src/settings.json'

export const DEVICE_TYPES = {
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
    TABLET: 'tablet'
};

export const getDevice = () => {
    const userAgent = _get(navigator, 'userAgent', '');
    let device = DEVICE_TYPES.DESKTOP;

    if (userAgent.match(/iPad/i)) {
        device = DEVICE_TYPES.TABLET;
    } else if (userAgent.match(/mobi|iPhone|iPod|Android/i)) {
        device = DEVICE_TYPES.MOBILE;
    }

    return device;
};

const mergeCommonToDevice = () => {
    const deviceType = getDevice();
    const context = {};

    Object.keys(settings).forEach((type) => {
        context[type] = _merge({}, settings[type].common, settings[type][deviceType]);
    });
    return context;
}

const setEnvContext = (context) => {
    const deviceType = getDevice();
    const env = _get(context, `environments.env`, {});
    const envObj = _get(context, `environments.${env}`, {});
    const envContext = {
        host: location.host,
        isSecure: location.protocol === 'https:',
        protocol: location.protocol,
        ...envObj
    };

    RestClient.setContext(envContext);
    context.environment = envContext;
    context.deviceType = {
        isDesktop: deviceType === DEVICE_TYPES.DESKTOP,
        isMobile: deviceType === DEVICE_TYPES.MOBILE,
        isTablet: deviceType === DEVICE_TYPES.TABLET,
        isDevice: window.innerWidth <= 1024 || deviceType === DEVICE_TYPES.MOBILE || deviceType === DEVICE_TYPES.TABLET
    }
    delete context.environments;
};

export const getApplicationContext = () => {
    const context = mergeCommonToDevice();
    setEnvContext(context);

    return context;
};
