export const GET_ORDER_TEMPLATE_REQUEST = 'GET_ORDER_TEMPLATE_REQUEST';
export const GET_ORDER_TEMPLATE_SUCCESS = 'GET_ORDER_TEMPLATE_SUCCESS';
export const GET_ORDER_TEMPLATE_ERROR = 'GET_ORDER_TEMPLATE_ERROR';
export const CREATE_ORDER_TEMPLATE_REQUEST = 'CREATE_ORDER_TEMPLATE_REQUEST';
export const DELETE_ORDER_TEMPLATE_REQUEST = 'DELETE_ORDER_TEMPLATE_REQUEST';
export const RESTORE_ORDER_TEMPLATE_REQUEST = 'RESTORE_ORDER_TEMPLATE_REQUEST';
export const ADD_ITEM_TO_TEMPLATE = 'ADD_ITEM_TO_TEMPLATE';
export const RESET_ORDER_TEMPLATE = 'RESET_ORDER_TEMPLATE';

export const GET_ORDER_TEMPLATE_EXCEL = 'GET_ORDER_TEMPLATE_EXCEL';
export const POST_ORDER_TEMPLATE_EXCEL = 'POST_ORDER_TEMPLATE_EXCEL';
export const POST_ORDER_TEMPLATE_EXCEL_ERROR = 'POST_ORDER_TEMPLATE_EXCEL_ERROR';
export const RESET_TEMPLATE_EXCEL_ERROR = 'RESET_TEMPLATE_EXCEL_ERROR';

export const CLEAR_REDUCER = 'CLEAR_REDUCER';
