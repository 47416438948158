import React from 'react';
import classNames from 'classnames/bind';
import styles from './DosAndDontsComponent.css';
import { useSelector } from 'react-redux';
import { FSA, FSA_IMAGES } from '../../../../common/Constants';
import dos from '../../../../assets/svg/dos.svg';
import donts from '../../../../assets/svg/donts.svg';
import greenCheck from '../../../../assets/svg/greenCheck.svg';
import redCross from '../../../../assets/svg/redCross.svg';

const cx = classNames.bind(styles);

const DoCard = ({ title, line1, line2, line3, imageSrc, topIconSrc, tooltip }) => {
    return (
        <div className={cx('doCard')}>
            <div className={cx('cardIcon')}>
                <img src={topIconSrc} alt='Do checkmark' />
            </div>
            <div className={cx('tooltip')}>
                <p>{tooltip}</p>
            </div>
            <div className={cx('doCardTitle')}>
                <p>{title}</p>
            </div>
            <div className={cx('cardInfo')}>
                <div className={cx('cardDescription')}>
                    <img src={greenCheck} alt="green checkmark" /> <p>{line1}</p>
                </div>
                <div className={cx('cardDescription')}>
                    <img src={greenCheck} alt="green checkmark" /> <p>{line2}</p>
                </div>
                <div className={cx('cardDescription')}>
                    <img src={greenCheck} alt="green checkmark" /> <p>{line3}</p>
                </div>
            </div>
            <div className={cx('imgContainer')}>
                <img src={imageSrc} alt="Do's" />
            </div>
        </div>
    );
};

const DontCard = ({ title, line1, line2, line3, imageSrc, topIconSrc }) => {
    return (
        <div className={cx('dontCard')}>
            <div className={cx('cardIcon')}>
                <img src={topIconSrc} alt="Don't cross" />
            </div>
            <div className={cx('dontCardTitle')}>
                <p>{title}</p>
            </div>
            <div className={cx('cardInfo')}>
                <div className={cx('cardDescription')}>
                   <img src={redCross} alt="red cross" /> <p>{line1}</p>
                </div>
                <div className={cx('cardDescription')}>
                <img src={redCross} alt="red cross" /> <p>{line2}</p>
                </div>
                <div className={cx('cardDescription')}>
                <img src={redCross} alt="red cross" /> <p>{line3}</p>
                </div>
            </div>
            <div className={cx('imgContainer')}>
                <img src={imageSrc} alt="Dont's" />
            </div>
        </div>
    );
};

const DosAndDontsComponent = () => {
    const localization = useSelector(state => state.pageContent.localization);
    const components = useSelector(state => state.pageContent.components);
    const dosImageSrc = components.find(component => component.uid === FSA_IMAGES.DOS_IMAGE)?.url?.replace('"', '');
    const dontsImageSrc = components.find(component => component.uid === FSA_IMAGES.DONT_IMAGE)?.url?.replace('"', '');
    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);

    return (
        <div className={cx('dosAndDontsContainer')}>
            <div className={cx('dosAndDontsInner')}>
                <div className={cx('dosAndDontsTitle')}>
                    <p>{localization[FSA.SECTION_FOUR_TEXT_1]}</p>
                </div>
                <div className={cx('cardsContainer')}>
                    <DoCard
                        title={localization[FSA.SECTION_FOUR_TEXT_3]}
                        tooltip={localization[FSA.SECTION_FOUR_TEXT_2]}
                        line1={localization[FSA.SECTION_FOUR_TEXT_4]}
                        line2={localization[FSA.SECTION_FOUR_TEXT_5]}
                        line3={localization[FSA.SECTION_FOUR_TEXT_6]}
                        imageSrc={`${assetBaseUrl}${dosImageSrc}`}
                        topIconSrc={dos}
                    />
                    <DontCard
                        title={localization[FSA.SECTION_FOUR_TEXT_7]}
                        line1={localization[FSA.SECTION_FOUR_TEXT_8]}
                        line2={localization[FSA.SECTION_FOUR_TEXT_9]}
                        line3={localization[FSA.SECTION_FOUR_TEXT_10]}
                        imageSrc={`${assetBaseUrl}${dontsImageSrc}`}
                        topIconSrc={donts}
                    />
                </div>
            </div>
        </div>
    );
};

export default DosAndDontsComponent;
