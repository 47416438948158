import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { AUTH, PARAMS } from '../common/Api';

export function submitMarketing(payload) {
    const config = RestConfig.v2ComApiConfig(payload);
    const { newsletter, offers, consentDate } = payload;

    config.url = `/${config.baseInfo.siteId}/consent/agreement`;
    config.data = {
        newsletter,
        offers,
        consentDate
    };

    return RestClient.post(config).then(json => {
        return json;
    });
}

export function getMarketing(payload) {
    const config = RestConfig.v2ComApiConfig(payload);
    config.url = `/${config.baseInfo.siteId}/consent/agreement`;
    
    return RestClient.get(config).then(json => {
        return json;
    });
}

export function submitMarketingCommunication(payload) {
    const config = RestConfig.v2ComApiConfig(payload);
    const { emailOption, smsOption } = payload;
    const { baseInfo } = config; 
    config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}/marketing-communication`;
    
    config.params = {
        emailOption,
        smsOption
    };

    return RestClient.post(config).then(json => {
        return json;
    });
}
