import {useEffect, useState} from "react";

const getDeviceType = () => {
    const width = window.innerWidth;

    if (width <= 480) {
        return 'mobile';
    } else if (width <= 768) {
        return 'tablet';
    } else if (width <= 1024) {
        return 'tablet-xl';
    } else {
        return 'desktop';
    }
};

export const useDeviceType = () => {
    const [deviceType, setDeviceType] = useState(getDeviceType());

    useEffect(() => {
        const handleResize = () => {
            setDeviceType(getDeviceType());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return deviceType;
};