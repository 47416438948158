import {
    ACTIVATE_DELIVERY_TYPE_REQUEST,
    GET_PLANT_TYPE,
    GET_SELECTED_PLANT_TYPE_REQUEST,
    GET_WHOLE_SALER,
    GET_SELECTED_WHOLE_SALER_REQUEST
} from '../actionTypes/DeliveryActionTypes';

export const activateDeliveryType = payload => ({
    type: ACTIVATE_DELIVERY_TYPE_REQUEST,
    payload
});

export const getPlantType = payload => ({
    type: GET_PLANT_TYPE,
    payload
});

export const submitSelectedPlants = payload => ({
    type: GET_SELECTED_PLANT_TYPE_REQUEST,
    payload
});

export const getWholeSaler = payload => ({
    type: GET_WHOLE_SALER,
    payload
});

export const submitSelectedWholeSaler = payload => ({
    type: GET_SELECTED_WHOLE_SALER_REQUEST,
    payload
});
