import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { PAGE_ID } from '../../common/Constants';

export default class AccountDataApi {
    static getAccountData(params) {
        const { baseInfo, pageId } = params;
        const config = RestConfig.v2ComApiConfig(params);
        if (pageId === PAGE_ID.COMPANY) {
            config.url = `/${baseInfo.siteId}/my-account/company-data`;
        } else if (pageId === PAGE_ID.PERSONAL) {
            config.url = `/${baseInfo.siteId}/my-account/personal-data`;
        } else {
            config.url = `/${baseInfo.siteId}/my-account/fact-sheet`;
        }

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static updatePreferences(params) {
        const { baseInfo, data } = params;
        const config = RestConfig.v2ComApiConfig(params);
        config.url = `/${baseInfo.siteId}/my-account/personal-data-change`;
        config.data = data;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static changePricePreferences(params) {
        const { baseInfo, data } = params;
        const config = RestConfig.v2ComApiConfig(params);
        config.url = `/${baseInfo.siteId}/my-account/company-data-change`;
        config.data = data;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static updateOrderNotifications(params) {
        const config = RestConfig.v2ComApiConfig(params);
        const { baseInfo } = config;
        config.url = `/${baseInfo.siteId}/my-account/update-order-notifications`;
        config.data = { orderNotificationCodes: params.orderNotificationCodes };

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static changeMiniCartPreferences(params) {
        const { baseInfo, miniCartPreference } = params;
        const config = RestConfig.v2ComApiConfig(params);
        config.url = `/${baseInfo.siteId}/my-account/minicart-preference-change/`;
        config.params = { ...config.params, miniCartPreference };
        return RestClient.post(config).then(json => {
            return json;
        })
    }
}
