import _get from 'lodash/get';
import { MARKETING_TYPES, PERSONAL_DATA_L10N, MINI_CART_WORKING_WAYS, CONSENT_STATUS } from '../common/Constants';
import { getUserInfo } from '../common/UserManager/UserManager';

const populateMarketingCommunication = () => {
    const userInfo = getUserInfo();
    let smsEnabled = false;
    let emailEnabled = false;
    const consentStatus = userInfo.marketingProfile?.status;

    if (!userInfo.marketingProfile) {
        return null;
    }

    if (consentStatus === CONSENT_STATUS.ACTIVE && Object.keys(userInfo.marketingProfile).length > 0 && userInfo.marketingProfile.options) {
        userInfo.marketingProfile.options.forEach((element) => {
            if (element.name.toLowerCase() === MARKETING_TYPES.SMS.toLowerCase()) {
                smsEnabled = true;
            } else if (element.name.toLowerCase() === MARKETING_TYPES.EMAIL.toLowerCase()) {
                emailEnabled = true;
            }
        });
    }
    const obj = {
        title: 'MARKETING_COMM',
        description: 'MARKETING_COMM_DESC',
        fields: [
            { label: MARKETING_TYPES.SMS, name: MARKETING_TYPES.SMS.toLowerCase(), value: smsEnabled },
            { label: MARKETING_TYPES.EMAIL, name: MARKETING_TYPES.EMAIL.toLowerCase(), value: emailEnabled }
        ],
        group: 'marketingCommunicationChannels',
        emailNotificationDataChangeable: true
    };
    
    return obj;
}

const populateOrderNotificationCodes = (personalData) => {

    const userSubscribedCodes = personalData.cchUserInformationData.orderNotificationCodes;
    userSubscribedCodes.push('n0'); //not allowed to unsubscribe
    const subscriptionChecker = (notificationCode) => userSubscribedCodes.includes(notificationCode);

    const fields = [
        { label: 'n0', name: PERSONAL_DATA_L10N.orderNotifications.n0, value: true }, //not allowed to unsubscribe
        { label: 'n1', name: PERSONAL_DATA_L10N.orderNotifications.n1, value: subscriptionChecker('n1') },
        { label: 'n2', name: PERSONAL_DATA_L10N.orderNotifications.n2, value: subscriptionChecker('n2') },
        { label: 'n3', name: PERSONAL_DATA_L10N.orderNotifications.n3, value: subscriptionChecker('n3') },
        { label: 'n4', name: PERSONAL_DATA_L10N.orderNotifications.n4, value: subscriptionChecker('n4') },
        { label: 'n5', name: PERSONAL_DATA_L10N.orderNotifications.n5, value: subscriptionChecker('n5') }
    ];
    const subscriptionsEnabledFields = fields.filter((x)=> personalData.orderNotificationCodes.includes(x.label) || x.label === 'n0');

    return {
        title: 'ORDER_NOTIFICATION_TITLE',
        description: 'ORDER_NOTIFICATION_DESCRIPTION',
        fields: subscriptionsEnabledFields,
        group: 'orderNotifications',
        emailNotificationDataChangeable: true
    };
}

const populateMiniCartPreferences = (personalData) => {

    const showMiniCartPreferences = personalData.showMiniCartPreferences;


    const fields = [
        { label: MINI_CART_WORKING_WAYS.CRMLOAD, name: PERSONAL_DATA_L10N.miniCartPreferences.CRMLOAD, description: PERSONAL_DATA_L10N.miniCartPreferences.CRMLOAD_DESCRIPTION}, 
        { label: MINI_CART_WORKING_WAYS.MINICARTNOCRM, name: PERSONAL_DATA_L10N.miniCartPreferences.MINICARTNOCRM, description: PERSONAL_DATA_L10N.miniCartPreferences.MINICARTNOCRM_DESCRIPTION}, 
        { label: MINI_CART_WORKING_WAYS.SKIPMINICART, name: PERSONAL_DATA_L10N.miniCartPreferences.SKIPMINICART, description: PERSONAL_DATA_L10N.miniCartPreferences.SKIPMINICART_DESCRIPTION },
    ];

    return {
        title: 'MINICART_TITLE_PREFERENCE',
        description: 'MINICART_DESCRIPTION_PREFERENCE',
        fields: fields,
        group: 'miniCartPreferences',
        showMiniCartPreferences: showMiniCartPreferences
    };
}


const personalFillteredData = personalData => {
    const defaultItem = { title: '', fields: [], };
    const iniItemData = { label: '', value: '' };

    const baseRespData = {
        title: '',
        data: {
            userData: [],
            contact: []
        },
        footerData: [],
    };
    const { cchUserInformationData, enableOrderNotification, showMiniCartPreferences } = personalData;

    baseRespData.title = _get(personalData, 'title', '');
    
    Object.entries(PERSONAL_DATA_L10N).forEach(([key, value]) => {
        const { showMarketingConsent } = personalData.marketingConsentAgreementData;
        if (typeof value === 'string') {
            const itemData = { ...iniItemData };
            itemData.label = key;

            if (key === 'COMPANY_NAME') {
                itemData.value = _get(personalData, 'companyName', '');
            } else {
                itemData.value = _get(cchUserInformationData, value, '');
            }
    
            switch (key) {
                case 'USER_NAME':
                case 'FIRST_NAME':
                case 'LAST_NAME':
                    baseRespData.data.userData.push(itemData);
                    break;
                case 'COMPANY_NAME':
                    baseRespData.data.userData.push(itemData);
                    break;
                case 'PHONE':
                case 'MOBILE':
                case 'EMAIL':
                    baseRespData.data.contact.push(itemData);
                    break;
                default:
                    break;
            }
        } else if(key !== 'miniCartPreferences') {
            // eslint-disable-next-line no-lonely-if
            if ((key !== 'marketingConsentAgreementData' || showMarketingConsent) 
                    && key !== 'marketingCommunicationChannels'
                    && key !== 'orderNotifications') { 

                let item = { ...defaultItem };
                item.group = key;
    
                if (key === 'emailNotification') {
                    if (!enableOrderNotification) {
                        item.title = 'COMM_PREF';
                        item.emailNotificationDataChangeable = _get(
                            personalData,
                            'emailNotificationDataChangeable',
                            false
                        );

                        Object.entries(value).forEach(([subKey, subValue]) => {
                            item = {
                                ...item,
                                fields: [
                                    ...item.fields,
                                    {
                                        label: subKey,
                                        value: _get(personalData[key], subValue, false),
                                        name: subValue,
                                    }
                                ]
                            }
                        });
            
                        baseRespData.footerData.push(item);
                    }
                } else {
                    item.title = 'ADV_PREF';
                    item.emailNotificationDataChangeable = true;

                    Object.entries(value).forEach(([subKey, subValue]) => {
                        item = {
                            ...item,
                            fields: [
                                ...item.fields,
                                {
                                    label: subKey,
                                    value: _get(personalData[key], subValue, false),
                                    name: subValue,
                                }
                            ]
                        }
                    });
        
                    baseRespData.footerData.push(item);
                }

            }
        }
    });

    const marketingCommunication = populateMarketingCommunication(); 
    if (marketingCommunication) {
        baseRespData.footerData.push(marketingCommunication);
    }

    const orderNotificationCodes = populateOrderNotificationCodes(personalData);
    if (enableOrderNotification && orderNotificationCodes) {
        baseRespData.footerData.push(orderNotificationCodes);
    }

    const miniCartPreferences = populateMiniCartPreferences(personalData);
    if (showMiniCartPreferences && miniCartPreferences) {
        baseRespData.footerData.push(miniCartPreferences);
    }

    return baseRespData;
};

export default personalFillteredData;
