import axios from 'axios';
import { getRequestConfig } from './RestConfig';
import { ACCESS_TOKEN, HEADERS, PARAMS } from '../common/Api';

/**
 * This method is responsible of preparing API end point URL.
 * Note: All query parameters and request body data is taken care by getRequestConfig() method.
 *
 * @param {object} context
 * @param {objject} config
 */
const getApiUrl = (context, config) => {
    const { apiHost, url = '', version = '' } = config;
    const { apiHosts = {}, protocol } = context;
    return url.indexOf(protocol) === -1 ? `${protocol}//${apiHosts[apiHost]}${config.baseURL}${version}${url}` : url;
};

export const getApiConfig = config => {
    if (config) {
        let { headers = {} } = config;
        const { baseInfo = {}, params = {} } = config;
        const { CURRENCY, LANG, SITE_ID } = PARAMS;

        if (config.isAuthRequired && baseInfo[ACCESS_TOKEN]) {
            headers[HEADERS.AUTH] = `Bearer ${baseInfo[ACCESS_TOKEN]}`;
        }

        if (config.isL10NRequired) {
            const { siteId = baseInfo[SITE_ID], lang = baseInfo[LANG] } = params;

            if (siteId) {
                params[SITE_ID] = siteId;
            }
            if (lang) {
                params[LANG] = lang;
            }
            if (baseInfo[CURRENCY]) {
                params[CURRENCY] = baseInfo[CURRENCY];
            }
        }

        if (config.customHeaders) {
            headers = { ...headers, ...config.customHeaders };
        }

        config.headers = headers;
        config.params = params;
    }
    
    return config;
};

/**
 * This class is to invoke the actual Service API calls for all the request methods.
 */
export default class RestHelper {
    static invoke = (context, config, method) => {
        const apiUrl = getApiUrl(context, config);
        let response = {};
        const requestConfig = getRequestConfig(getApiConfig(config), method, apiUrl);
        return axios(requestConfig)
            .then(response => {
                response = { ...response, isSuccess: true };
                return response;
            })
            .catch(error => {
                response = { ...error.response, isSuccess: false };
                return response;
            });
    };
}
