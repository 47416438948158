/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import parse from 'html-react-parser';
import { browserHistory } from 'react-router';
import _get from 'lodash/get';
import classNames from 'classnames/bind';
import { bindActionCreators } from 'redux';
import {
    PAGE_ID,
    ORDER_INFORMATION_L10N,
    MY_ACCOUNT_ORDER_INFORMATION_TABS,
    ACCOUNT_TABS_RESTRICTIVE,
    MESSAGE_LEVELS,
    MESSAGE_TYPES,
    ACCOUNT_STATEMENTS_L10N,
    INVOICES_L10N,
    URL_PATHS
} from '../../../common/Constants';
import { getPageContent } from '../../../actions/PageContentAction';
import { clearAccountStatementError } from '../../../actions/AccountStatementAction';
import { clearInvoicesPDFData } from '../../../actions/InvoicesAction';
import Layout from '../../../components/site/Layout/Layout';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import * as styles from './OrderInformation.css';
import OrderList from '../OrderList/OrderList';
import Invoices from '../Invoices/Invoices';
import MessageBox from '../../../components/core/MessageBox/MessageBox';
import { buttonClickEventConfigs, buttonClickEvent } from '../../../common/GoogleTagManager/GoogleTagManager';
const cx = classNames.bind(styles);

const sections = Object.keys(MY_ACCOUNT_ORDER_INFORMATION_TABS).map((key, i) => {
    return {
        type: key,
        title: MY_ACCOUNT_ORDER_INFORMATION_TABS[key]
    };
});

const HtmlRenderer = ({ htmlString }) => {
    return <div>{parse(htmlString)}</div>;
};

HtmlRenderer.propTypes = {
    htmlString: PropTypes.string
};

export const OrderInformation = props => {
    const [currentSectionTab, setCurrentSectionTab] = useState('');
    const components = props.components;

    const {
        localization = {},
        breadcrumbs,
        message,
        accountStatementError,
        actions,
        invoiceDownloaded,
        xNumberPdfs
    } = props;

    useEffect(() => {
        actions.getPageContent({ pageId: PAGE_ID.ORDERS_INFORMATION, isSkipLoader: true });
    }, []);

    React.useEffect(() => {
        if (props?.params?.section) {
            setCurrentSectionTab(MY_ACCOUNT_ORDER_INFORMATION_TABS[props?.params?.section]);
        }
    }, [props?.params]);

    const findLocalizationForTabs = key => {
        if (!localization) return '';
        switch (key) {
            case 'orderHistory':
                return localization[ORDER_INFORMATION_L10N?.ORDER_HISTORY_TAB];
            case 'invoices':
                return localization[ORDER_INFORMATION_L10N?.INVOICES_TAB];
            default:
                return '';
        }
    };

    const filteredSections = React.useMemo(() => {
        const uidsComponents = components.map(c => c?.uid || '');
        let fsections = sections;

        if (!uidsComponents.includes(ACCOUNT_TABS_RESTRICTIVE.ORDER_HISTORY_TAB)) {
            fsections = fsections.filter(section => section.title !== MY_ACCOUNT_ORDER_INFORMATION_TABS.orderHistory);
        }
        if (!uidsComponents.includes(ACCOUNT_TABS_RESTRICTIVE.INVOICES_TAB)) {
            fsections = fsections.filter(section => section.title !== MY_ACCOUNT_ORDER_INFORMATION_TABS.invoices);
        }

        return fsections;
    }, [sections, components]);

    return (
        <Layout showDashboardHeader showOutlet pageId={PAGE_ID.ORDER_INFORMATION}>
            {message?.error ? (
                <p className={cx('errorMsg')} automation-id='at-error-invalid-data'>
                    {message?.error.message} {message?.error.errorCode}
                </p>
            ) : accountStatementError ? (
                <MessageBox
                    id='account-statement-error'
                    level={MESSAGE_LEVELS.SECTION}
                    type={MESSAGE_TYPES.ERROR}
                    closeBtn
                    onClose={actions.clearAccountStatementError}
                    isAutoClose
                    autoCloseTime={4000}
                    fullWidth
                >
                    <HtmlRenderer htmlString={localization[ACCOUNT_STATEMENTS_L10N.ACCOUNT_STATEMENT_ERROR_MESSAGE]} />
                </MessageBox>
            ) : invoiceDownloaded ? (
                <MessageBox
                    id='invoices-download-success'
                    level={MESSAGE_LEVELS.SECTION}
                    type={MESSAGE_TYPES.SUCCESS}
                    onClose={actions.clearInvoicesPDFData}
                    closeBtn
                    isAutoClose
                    autoCloseTime={9000}
                    fullWidth
                >
                    {xNumberPdfs > 1 ? (
                        <HtmlRenderer
                            htmlString={`${xNumberPdfs} ${localization[INVOICES_L10N.SUCCESSFUL_DOWNLOAD_TEXT]}`}
                        />
                    ) : (
                        <HtmlRenderer
                            htmlString={`${xNumberPdfs} ${
                                localization[INVOICES_L10N.ONE_INVOICE_SUCCESSFUL_DOWNLOAD_TEXT]
                            }`}
                        />
                    )}
                </MessageBox>
            ) : null}
            <div automation-id='at-order-information-page' className={cx('orderInformation')}>
                <BreadCrumbs breadcrumbValues={breadcrumbs} />
                <div className={cx('title-holder')}>
                    <h2 automation-id='at-order-information-title-text' className={cx('title')}>
                        <span>{localization[ORDER_INFORMATION_L10N.TITLE]}</span>
                    </h2>
                    {/* <p automation-id='at-order-information-subtitle-text' className={cx('subtitle')}>
                        {localization[ORDER_INFORMATION_L10N.SUB_TITLE]}
                    </p> */}
                </div>
                <div className={cx('tabs-holder')}>
                    <Tabs
                        className={cx('react-tabs')}
                        selectedTabClassName='OrderInformation-react-tabs__tab--selected'
                        selectedTabPanelClassName='OrderInformation-react-tabs__tab-panel--selected'
                        selectedIndex={Object.values(MY_ACCOUNT_ORDER_INFORMATION_TABS).findIndex(
                            tab => tab === currentSectionTab
                        )}
                        onSelect={index => {
                            const tabUrl = `/account/order-information/${
                                Object.keys(MY_ACCOUNT_ORDER_INFORMATION_TABS)[index]
                            }`;
                            browserHistory.push(tabUrl);

                            const gaLinks = {
                                ['/' +
                                URL_PATHS.ORDER_INFORMATION +
                                '/' +
                                MY_ACCOUNT_ORDER_INFORMATION_TABS.orderHistory]: {
                                    action: buttonClickEventConfigs.orderInformation.actions.orderHistory,
                                    label: buttonClickEventConfigs.orderInformation.labels.orderHistory,
                                    category: buttonClickEventConfigs.orderInformation.categories.orderInformation
                                },
                                ['/' +
                                URL_PATHS.ORDER_INFORMATION +
                                '/' +
                                MY_ACCOUNT_ORDER_INFORMATION_TABS.invoices]: {
                                    action: buttonClickEventConfigs.orderInformation.actions.invoices,
                                    label: buttonClickEventConfigs.orderInformation.labels.invoices,
                                    category: buttonClickEventConfigs.orderInformation.categories.orderInformation
                                }
                            };

                            const foundLink = gaLinks[tabUrl];
                            if (foundLink) {
                                buttonClickEvent(foundLink.action, foundLink.label, foundLink.category);
                            }
                        }}
                    >
                        <TabList className={cx('react-tabs__tab-list')}>
                            {Object.values(MY_ACCOUNT_ORDER_INFORMATION_TABS).map(tabName => {
                                const findTab = filteredSections.find(fs => fs.type === tabName);
                                return (
                                    <Tab key={tabName} className={cx('react-tabs__tab', findTab ? '' : 'hideTab')}>
                                        {findLocalizationForTabs(tabName)}
                                    </Tab>
                                );
                            })}
                        </TabList>

                        <TabPanel className={cx('react-tabs__tab-panel')}>
                            <OrderList embeded />
                        </TabPanel>
                        <TabPanel className={cx('react-tabs__tab-panel')}>
                            <Invoices embeded />
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </Layout>
    );
};

OrderInformation.propTypes = {
    commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

OrderInformation.defaultProps = {
    commonLocalization: {}
};

const mapStateToProps = state => {
    return {
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`),
        localization: _get(state, 'pageContent.localization'),
        components: _get(state, 'pageContent.components'),
        breadcrumbs: _get(state, 'pageContent.breadcrumbs'),
        message: _get(state, 'invoicesDetails.message') || {},
        accountStatementError: _get(state, 'accountStatement.error'),
        invoiceDownloaded: _get(state, 'invoicesDetails.invoiceDownloaded'),
        xNumberPdfs: _get(state, 'invoicesDetails.xNumberPdfs')
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                getPageContent,
                clearAccountStatementError,
                clearInvoicesPDFData
            },
            dispatch
        )
    };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(OrderInformation));
