/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import Link from 'react-router/lib/Link';
import * as styles from './MobileTabbar.css';
import Icon from '../../core/Icon/Icon';
import { Home, Promo, Shop, Account } from './icons';
import { logoutUser } from '../../../actions/LoginAction';
import { openMiniCart } from '../../../actions/CartAction';
import Button from '../../core/Button/Button';
import Alerts from './icons/NotificationBell';
import { PAGE_ID, URL_PATHS, GENERIC_L10N, COMMON_COMPONENT, UNIQUE_ID } from '../../../common/Constants';
import { getElement, redirectTo } from '../../../common/Utils/Utils';
import CreditLimit from '../CreditLimit/CreditLimit';
import { getBdaaSuggestion } from '../../../actions/ProductListAction';
import { isBdaaActive } from '../../../common/UserManager/UserManager';

const MENU_ICONS = [Home, Promo, Shop, Account, Alerts];
const cx = classNames.bind(styles);

class MobileTabbar extends React.Component {
    constructor(props) {
        super(props);
        this.isStickyEnabled = true;
        this.notificationBannerRef = React.createRef();
        this.state = {
            subMenuOpen: false,
            selectedIdx: 0,
            showNotificationBanner: false,
            hasShownNotificationBanner: false,
            isMounted: false
        };
        this.isBdaaActive = isBdaaActive();
    }

    componentDidUpdate(prevProps) {
        if (this.props.unreadCount !== prevProps.unreadCount && !this.state.hasShownNotificationBanner) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ showNotificationBanner: true, hasShownNotificationBanner: true });
            setTimeout(() => {
                this.setState({ showNotificationBanner: false });
            }, 7000);
        }
    }

    static getDerivedStateFromProps(props, state) {
        let { selectedIdx } = state;
        const { subMenuOpen } = state;

        if (window.location.pathname.indexOf('home') >= 0 && !subMenuOpen) {
            selectedIdx = 0;
        } else if (window.location.pathname.indexOf('promotions') >= 0 && !subMenuOpen) {
            selectedIdx = 1;
        } else if (window.location.pathname.indexOf('plp') >= 0 && !subMenuOpen) {
            selectedIdx = 2;
        } else if (subMenuOpen) {
            selectedIdx = 3;
        }

        return {
            ...state,
            selectedIdx
        };
    }

    logoutEvent = () => {
        if (document.getElementsByClassName('cx-widget')[0]) {
            window._genesys.widgets.bus.command('WebChat.endChat').then(() => {
                window._genesys.widgets.bus.command('WebChat.close');
            });
        }
        this.props.actions.logoutUser();
    };

    handleScroll = () => {
        // The property pageYOffset is compatable with all browsers.
        const currentScrollTop = window.pageYOffset;
        const headerEle = getElement('#stickyHeader');
        const notificationEle = getElement('#notificationContainer');
        const stickyHeight = headerEle.height + notificationEle.height;
        // Use #stickyWrapper to get top value instead of stickyButton.
        // As wrapper won't get moved from the current position and gives the exact original top value.
        const stickyWrapper = getElement('#stickyWrapper');
        const stickyWrapperTop = Number(currentScrollTop + stickyWrapper.top);
        let isSticky = false;

        /**
         * Make button sticky after considering both scroll top & total height of the top sticky header (Current Scroll + Header + Notification)
         * to compare with sticky button top value.
         */
        if (currentScrollTop + stickyHeight >= stickyWrapperTop) {
            isSticky = true;
        }

        // Setting sticky state for freezing the sticky buttons just below the top sticky header.
        this.setState({
            isSticky,
            stickyHeight
        });
    };

    handleRequestSupportPopup = (menu, e) => {
        this.props.toggleSubmenu(false);
        this.props.showRequestModal(true);
        this.setState({ subMenuOpen: false }, this.handleScroll);
    };

    redirectToCart = () => {
        redirectTo('/cart');
    };

    renderSubMenu = submenuChildren => {
        const { selectedSubMenuIndex, stickyHeight, logoutLabel, selectedIdx } = this.state;
        const { commonLocalization } = this.props;
        return (
            <div
                style={{
                    maxHeight: `calc(100vh - 60px - ${stickyHeight}px)`
                }}
                className={cx('MobileSubMenuContainer')}
            >
                <div className={cx('SubMenuTitle')}>{commonLocalization[GENERIC_L10N.MY_ACCOUNT]}</div>
                <div
                    className={cx('CloseIcon')}
                    onClick={() => {
                        this.setState({ subMenuOpen: false }, this.handleScroll);
                    }}
                >
                    <Icon iconType='svg' width='24px' height='24px' viewBox='0 0 24 24' name='close-gray' />
                </div>

                <div className={cx('LogoutButton')}>
                    <Button buttonType='Primary' type='button' onClick={this.logoutEvent} size='Lg'>
                        {logoutLabel}
                    </Button>
                </div>

                <ul className={cx('CreditBalanceContainer')}>
                    <CreditLimit theme='phoneCreditBalance' />
                </ul>

                <ul className={cx('SubMenuList')}>
                    {submenuChildren.map((menu, i) => {
                        if (menu.url !== URL_PATHS.LOGOUT) {
                            return (
                                <li className={cx('SubMenuListItem')} key={'li' + i}>
                                    {(!menu.children && (
                                        <Link
                                            onClick={() => {
                                                this.props.setShowAlerts(false);
                                                this.setState({ subMenuOpen: false }, this.handleScroll);
                                            }}
                                            className={cx('SubMenuLabel')}
                                            to={menu.url}
                                        >
                                            {menu.name}
                                        </Link>
                                    )) || (
                                            <div
                                                className={cx('SubMenuAccordion')}
                                                onClick={() => {
                                                    this.setState({
                                                        selectedSubMenuIndex: selectedSubMenuIndex !== i ? i : -1
                                                    });
                                                }}
                                            >
                                                <div className={cx('SubMenuLabel')}>
                                                    <span>{menu.name}</span>
                                                    <Icon
                                                        className={cx('downArrow')}
                                                        iconType='svg'
                                                        width='16px'
                                                        height='16px'
                                                        viewBox='0 0 16 16'
                                                        name='down'
                                                    />
                                                </div>
                                                <div className='dropdown'>
                                                    {selectedSubMenuIndex === i &&
                                                        menu.children.map((item, idx) => {
                                                            return (
                                                                <Link
                                                                    key={idx}
                                                                    className={cx('SubMenuLabel', 'Inner')}
                                                                    automation-id={`at-my-orders-and-return-dropdown--${idx}`}
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        const reqSupport = (item || {})[UNIQUE_ID];

                                                                        if (
                                                                            reqSupport ===
                                                                            COMMON_COMPONENT.HEADER_REQUEST_SUPPORT_LINK
                                                                        ) {
                                                                            this.handleRequestSupportPopup(item, e);
                                                                        } else {
                                                                            redirectTo(item.linkUrl);
                                                                        }
                                                                    }}
                                                                >
                                                                    {item.linkName}
                                                                </Link>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        )}
                                </li>
                            );
                        }
                        if (!logoutLabel) this.setState({ logoutLabel: menu.name });

                        return null;
                    })}
                </ul>
            </div>
        );
    };

    render() {
        const { mobileMenuData, commonLocalization } = this.props;
        const { selectedIdx, subMenuOpen, subMenuChildren, showNotificationBanner } = this.state;
        const isBdaaActive = (this.props.isBdaaActive || this.isBdaaActive) && !!this.props.bdaaSuggestion?.length;

        const filteredSubMenuChildren = (subMenuChildren = []) => {
            if (!isBdaaActive) {
                return subMenuChildren.filter(x => x?.uid !== 'MySmartOrdersLink');
            }
            return subMenuChildren;
        };

        if (!mobileMenuData.menuList) return <div />;

        return (
            <div className={cx('MobileMenuContainer')} style={{ height: subMenuOpen && `calc(100vh - 55px)` }}>
                {subMenuOpen && this.renderSubMenu(filteredSubMenuChildren(subMenuChildren))}
                <div className={cx('TabBarItemList')}>
                    {mobileMenuData.menuList
                        .filter((listItem) => listItem && listItem.uid && listItem.uid !== 'CartLink')
                        .map(({ linkName, linkUrl, uid, children }, i) => {
                            const isShopComponent = uid === 'ShopLink';
                            const isPromotionsComponent = uid === 'PromotionsLinkMobile';
                            const isAccountComponent = uid === 'MobileAccountNavNode';
                            const isLoyaltyComponent = uid === 'AlertsLink';
                            return (
                                <Link
                                    key={uid + i}
                                    style={{
                                        border: isShopComponent && 'none',
                                        maxWidth: isShopComponent && '56px',
                                        borderTopRightRadius: isPromotionsComponent && '8px',
                                        borderTopLeftRadius: isAccountComponent && '8px',
                                        paddingRight: isPromotionsComponent && '5px',
                                        paddingLeft: isAccountComponent && '5px'
                                    }}
                                    {...((!children && !isLoyaltyComponent && { to: linkUrl }) || {})}
                                    className={cx('MenuButton')}
                                    automation-id={`at-my-orders-and-return-dropdown-${i}`}
                                    onClick={() => {
                                        if (children) {
                                            this.props.setShowAlerts(false);
                                            this.props.toggleMiniCart(false);
                                            this.setState(
                                                {
                                                    selectedIdx: i,
                                                    selectedSubMenuIndex: -1,
                                                    subMenuOpen: true,
                                                    subMenuChildren: children
                                                },
                                                this.handleScroll
                                            );
                                            return;
                                        }
                                        if (isLoyaltyComponent) {
                                            this.setState({ selectedIdx: 4, subMenuOpen: false }, this.handleScroll);
                                            this.props.toggleMiniCart(false);
                                            this.props.setShowAlerts(true);
                                            return;
                                        }
                                        this.props.setShowAlerts(false);
                                        this.props.toggleMiniCart(false);
                                        this.setState(
                                            {
                                                selectedIdx: i,
                                                subMenuOpen: false
                                            },
                                            this.handleScroll
                                        );
                                    }}
                                >
                                    <div className={cx('IconContainer')}>
                                        {MENU_ICONS[i] &&
                                            (() => {
                                                const Icon = MENU_ICONS[i];
                                                if (Icon === Shop) {
                                                    return (
                                                        <div className={cx('ShopIconContainer')}>
                                                            <Icon
                                                                color={
                                                                    selectedIdx === 2 && !this.props.showAlerts
                                                                        ? '#e90000'
                                                                        : '#616161'
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                }
                                                if (Icon === Alerts) {
                                                    return (
                                                        <>
                                                            <Icon
                                                                color={this.props.showAlerts ? '#e90000' : '#616161'}
                                                                width='24px'
                                                                height='24px'
                                                                active={this.props.showAlerts}
                                                            />
                                                            {this.props.unreadCount > 0 && (
                                                                <div
                                                                    style={{
                                                                        backgroundColor: this.props.showAlerts
                                                                            ? '#000000'
                                                                            : '#e90000'
                                                                    }}
                                                                    className={cx('AlertIconNumber')}
                                                                >
                                                                    {this.props.unreadCount}
                                                                </div>
                                                            )}
                                                            {showNotificationBanner && (
                                                                <div className={cx('NotificationTooltip')}>
                                                                    {commonLocalization &&
                                                                        commonLocalization[
                                                                        GENERIC_L10N.LOYALTY.GREETINGS
                                                                        ]}
                                                                </div>
                                                            )}
                                                        </>
                                                    );
                                                }
                                                return (
                                                    <Icon
                                                        active={subMenuOpen}
                                                        color={
                                                            selectedIdx === i && !this.props.showAlerts
                                                                ? '#e90000'
                                                                : '#616161'
                                                        }
                                                    />
                                                );
                                            })()}
                                    </div>
                                    <div
                                        className={cx(
                                            'MenuLabel',
                                            selectedIdx === i && !this.props.showAlerts && 'MenuLabelSelected'
                                        )}
                                        style={{ transform: uid === 'ShopLink' && 'translateY(-39.5px)' }}
                                    >
                                        {linkName || ''}
                                    </div>
                                </Link>
                            );
                        })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        deviceType: _get(state, 'context.deviceType'),
        mobileMenuData: _get(state, 'pageContent.mobileMenuData'),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`),
        bdaaSuggestion: _get(state, 'products.bdaaSuggestion'),
        isBdaaActive: _get(state, 'products.isBdaaActive'),
        unreadCount: _get(state, 'loyalty.unreadCount'),
        showAlerts: ownProps.showAlerts
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            openMiniCart,
            logoutUser,
            getBdaaSuggestion
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileTabbar);
