/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Icon from '../Icon/Icon';
import * as styles from './Tabs.css';

const cx = classNames.bind(styles);
class TabPanel extends Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        isTabActive: PropTypes.bool,
        onSelect: PropTypes.func,
        id: PropTypes.string,
        title: PropTypes.node,
        customTabButtonClass: PropTypes.string,
        customContainerClass: PropTypes.string,
        iconName: PropTypes.string,
        customIcons: PropTypes.string,
        showIcon: PropTypes.bool,
        automationId: PropTypes.string
    };

    static defaultProps = {
        isTabActive: false,
        onSelect: function onSelect(index) {
            return index;
        },
        id: 'tab0',
        title: null,
        customTabButtonClass: '',
        customContainerClass: '',
        iconName: '',
        customIcons: '',
        showIcon: false,
        automationId: ''
    };

    handleClick = () => {
        this.props.onSelect(this.props.index);
    };

    render() {
        const {
            isTabActive,
            id,
            title,
            customTabButtonClass,
            customContainerClass,
            iconName,
            customIcons,
            showIcon,
            automationId
        } = this.props;
        const containerClass = cx({
            [`${'tabListItemClass'}`]: true,
            [`${'tabActiveClass'}`]: isTabActive,
            [`${customContainerClass}`]: true
        });

        return (
            <li
                automation-id={automationId}
                className={containerClass}
                key={id}
                id={id}
                role='tab'
                aria-selected={isTabActive}
            >
                <button
                    className={cx('tabButtonClass', customTabButtonClass)}
                    role='tab'
                    id='tabPanel'
                    aria-selected={isTabActive}
                    onClick={this.handleClick}
                >
                    <label className={cx('tabPanelTitleClass')} htmlFor={id}>
                        {title}
                    </label>
                    {showIcon && (
                        <Icon
                            className={cx('leftIcon', customIcons)}
                            width='21px'
                            height='21px'
                            viewBox='0 0 22 22'
                            name={iconName}
                        />
                    )}
                </button>
            </li>
        );
    }
}

export default TabPanel;
