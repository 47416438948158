import {
    CLOSE_SUBSCRIPTION_POPUP,
    OPEN_SUBSCRIPTION_POPUP,
    SUBSCRIBE_TO_PRODUCT
} from "../actionTypes/NotifyMeActionTypes";

export const subscribeToProduct = payload => ({
    type: SUBSCRIBE_TO_PRODUCT,
    payload
});

export const openSubscriptionPopup = productCode => ({
    type: OPEN_SUBSCRIPTION_POPUP,
    productCode
});
export const closeSubscriptionPopup = payload => ({
    type: CLOSE_SUBSCRIPTION_POPUP,
    payload
});