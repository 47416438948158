import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class SmartEdit extends Component {
    static defaultProps = {
        data: {}
    };

    static propTypes = {
        data: PropTypes.object
    };

    constructor() {
        super();
    }

    render() {
        const {
            data,
            pageContent,
            children,
            customClassName
        } = this.props;

        if (window === window.parent) {
            return children ? children : null;
        } else {
            return (
               data ?  <div 
                    className={`${customClassName ? `${customClassName} ` : ""}smartEditComponent`}
                    data-smartedit-component-type={data.type} 
                    data-smartedit-component-id={data.uid} 
                    data-smartedit-component-uuid={data.uuid} 
                    style={{heigth: '0px'}}
                    data-smartedit-catalog-version-uuid={`${pageContent && pageContent.catalogID}/${pageContent && pageContent.catalogVersion}`}>
                    {children}
                </div> : {children}
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        pageContent: state.pageContent,
    };
};


export default connect(mapStateToProps)(SmartEdit);
