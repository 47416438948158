import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import classNames from 'classnames/bind';
import Icon from '../Icon/Icon';
import * as styles from './FAQAccordion.css';

const cx = classNames.bind(styles);

const FAQAccordion = ({
    headline = '',
    body = '',
    className = '',
    isActive,
    onToggle,
    openIcon = 'plus',
    closeIcon = 'minus'
}) => {
    return (
        <div className={cx('accordionContainer', `${className}`)}>
            <button type='button' className={cx('accordionHeader')} onClick={onToggle}>
                <span className={cx('iconTheme', 'iconLeft')}>
                    <Icon
                        key={isActive ? closeIcon : openIcon}
                        iconType='svg'
                        width='24px'
                        height='24px'
                        viewBox='0 0 24 24'
                        name={isActive ? closeIcon : openIcon}
                    />
                </span>
                <p className={cx('accordionTitle')}>{headline}</p>
            </button>
            <div className={`${cx('accordionContent')} ${isActive ? cx('scrollConent') : ''}`}>
                <div className={cx('accordionText')}>{body}</div>
            </div>
        </div>
    );
};

FAQAccordion.propTypes = {
    headline: PropTypes.string,
    body: PropTypes.any,
    className: PropTypes.string,
    isActive: PropTypes.bool,
    onToggle: PropTypes.func,
    openIcon: PropTypes.string,
    closeIcon: PropTypes.string
};

export default FAQAccordion;
