import produce from 'immer';
import {
    GET_PROMOTIONS_LIST_SUCCESS,
    GET_PROMOTION_DEAL_SUCCESS,
    GET_PROMO_CALENDER_LIST_SUCCESS,
    ADD_PROMO_TO_CART_SUCCESS,
    RESET_ADD_PROMO_TO_CART_SUCCESS,
    REMOVE_PRESELECTED_DEAL,
    RESET_ACTIVE_DEAL_INDEX_SUCCESS,
    RESET_PROMOTIONS_LIST,
    RESET_PROMO_CALENDER,
    REMOVE_PROMO_FROM_CART_SUCCESS,
    RESET_REMOVE_PROMO_FROM_CART_SUCCESS,
    GET_PRE_CHECKOUT_UPDATE_SUCCESS,
    CLEAR_REDUCER
} from '../actionTypes/PromotionsActionTypes';
import {
    promotionListFilteredData,
    promotionDealFilteredData,
    promoCalendarListFilteredData
} from '../service/PromotionsFilter';

export default function PromotionsReducer(state = {}, action = {}) {
    switch (action.type) {
        case GET_PROMOTIONS_LIST_SUCCESS: {
            const transformedData = promotionListFilteredData(action.data);
            return {
                ...transformedData,
                copiedPromotions: JSON.parse(JSON.stringify(transformedData.promotions))
            };
        }
        case RESET_PROMOTIONS_LIST: {
            const newState = { ...state };
            newState.promotions = JSON.parse(JSON.stringify(newState.copiedPromotions));
            newState.isRemovePreselectedDeal = 0;
            return newState;
        }
        case ADD_PROMO_TO_CART_SUCCESS: {
            const stateAfterAdd = promotionListFilteredData(action.data);
            stateAfterAdd.isPromoAddedToCart = [true];

            return {
                ...stateAfterAdd,
                copiedPromotions: JSON.parse(JSON.stringify(stateAfterAdd.promotions))
            };
        }
        case REMOVE_PROMO_FROM_CART_SUCCESS: {
            const transformedDataAfterRemove = promotionListFilteredData(action.data, true);

            const nextState = produce(state, draftState => {
                draftState.breadcrumbs = transformedDataAfterRemove.breadcrumbs;
                draftState.promotions = transformedDataAfterRemove.promotions;
                draftState.isPromoRemoved = [true];
                draftState.copiedPromotions = transformedDataAfterRemove.promotions;
            });

            return nextState;
        }
        case RESET_REMOVE_PROMO_FROM_CART_SUCCESS: {
            const newState = { ...state };
            newState.isPromoRemoved = [false];

            return newState;
        }
        case RESET_ADD_PROMO_TO_CART_SUCCESS: {
            const newState = { ...state };
            newState.isPromoAddedToCart = [false];
            return newState;
        }
        case GET_PROMOTION_DEAL_SUCCESS: {
            const newState = { ...state };
            const {
                selectedIndex: { promotionIndex, dealIndex },
                preselectedDeal
            } = action.data;
            const transformedData = promotionDealFilteredData(preselectedDeal);
            newState.promotions[promotionIndex].deals[dealIndex] = { ...transformedData };
            return newState;
        }
        case GET_PROMO_CALENDER_LIST_SUCCESS: {
            const transformedData = promoCalendarListFilteredData(action.data);
            return {
                promoCalender: [...transformedData]
            };
        }
        case REMOVE_PRESELECTED_DEAL: {
            const newState = { ...state };
            const { promotionIndex, dealIndex, reqActiveDealIndex } = action.payload;
            newState.promotions[promotionIndex].deals.splice(dealIndex, 1);
            newState.promotions[promotionIndex].showInitial = false;
            newState.isRemovePreselectedDeal = reqActiveDealIndex;
            return newState;
        }
        case RESET_ACTIVE_DEAL_INDEX_SUCCESS: {
            const newState = { ...state };
            newState.isRemovePreselectedDeal = null;
            return newState;
        }
        case RESET_PROMO_CALENDER: {
            const newState = { ...state };
            newState.promoCalender = {
                isReset: true // To identify for making Promo Calendar API call in the Component for RDD.
            };
            return newState;
        }
        case GET_PRE_CHECKOUT_UPDATE_SUCCESS: {
            const newState = { ...state };
            newState.isPrecheckoutUpdateDone = true;
            return newState;
        }
        case CLEAR_REDUCER: {
            return {}
        }
        default:
            return state;
    }
}
