import React from 'react';
import { COMPANY_DATA_L10N, PERSONAL_DATA_L10N, PAGE_ID, GENERIC_L10N } from '../../../common/Constants';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';

import * as styles from './AccountData.css';
import Icon from '../../../components/core/Icon/Icon';
import RadioButton from '../../../components/core/RadioButton/RadioButton';
import Checkbox from '../../../components/core/Checkbox/Checkbox';
import Button from '../../../components/core/Button/Button';
import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { buttonClickEventConfigs, buttonClickEvent } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

const FooterDataRow = ({
    data,
    index,
    editHandler,
    showButtons,
    state,
    radioButtonHandlerMiniCartPref,
    radioButtonHandler,
    checkboxHandler,
    saveChanges,
    cancelChanges,
    isLast
}) => {
    const { LANG_CONST } = state;
    const localization = useSelector(state => state.pageContent.localization);
    const commonLocalization = useSelector(state => state.pageContent.commonLocalization[PAGE_ID.GENERIC]);

    const { emailNotificationDataChangeable, showPricesPrefRadioButtons, showMiniCartPreferences } = data;

    const onGAEdit = () => {
        const gaLinks = {
            miniCartPreferences: {
                action: buttonClickEventConfigs.accountSettings.actions.personalData,
                label: buttonClickEventConfigs.accountSettings.labels.personalDataMiniCartConfigurationEdit,
                category: buttonClickEventConfigs.accountSettings.categories.accountSettings
            },
            orderNotifications: {
                action: buttonClickEventConfigs.accountSettings.actions.personalData,
                label: buttonClickEventConfigs.accountSettings.labels.personalDataTransactionalNotificationsEdit,
                category: buttonClickEventConfigs.accountSettings.categories.accountSettings
            }
        };

        const foundLink = gaLinks[data.group];
        if (foundLink) {
            buttonClickEvent(foundLink.action, foundLink.label, foundLink.category);
        }
    };

    const onGACancel = () => {
        const gaLinks = {
            miniCartPreferences: {
                action: buttonClickEventConfigs.accountSettings.actions.personalData,
                label: buttonClickEventConfigs.accountSettings.labels.personalDataMiniCartConfigurationCancel,
                category: buttonClickEventConfigs.accountSettings.categories.accountSettings
            },
            orderNotifications: {
                action: buttonClickEventConfigs.accountSettings.actions.personalData,
                label: buttonClickEventConfigs.accountSettings.labels.personalDataTransactionalNotificationsCancel,
                category: buttonClickEventConfigs.accountSettings.categories.accountSettings
            }
        };

        const foundLink = gaLinks[data.group];
        if (foundLink) {
            buttonClickEvent(foundLink.action, foundLink.label, foundLink.category);
        }
    };

    const onGASave = () => {
        const gaLinks = {
            miniCartPreferences: {
                action: buttonClickEventConfigs.accountSettings.actions.personalData,
                label: buttonClickEventConfigs.accountSettings.labels.personalDataMiniCartConfigurationSave,
                category: buttonClickEventConfigs.accountSettings.categories.accountSettings
            },
            orderNotifications: {
                action: buttonClickEventConfigs.accountSettings.actions.personalData,
                label: buttonClickEventConfigs.accountSettings.labels.personalDataTransactionalNotificationsSave,
                category: buttonClickEventConfigs.accountSettings.categories.accountSettings
            }
        };

        const foundLink = gaLinks[data.group];
        if (foundLink) {
            buttonClickEvent(foundLink.action, foundLink.label, foundLink.category);
        }
    };

    return (
        <>
            <div className={cx('communication-perf', 'clearfix')} key={Math.random()}>
                <div className={cx('edit-icon-wrapper', showButtons ? 'opacity-class' : '')}>
                    <span
                        className={cx('edit-icon')}
                        onClick={() => {
                            editHandler(index);
                            onGAEdit();
                        }}
                        role='button'
                        tabIndex={0}
                    >
                        <Icon width='24px' height='24px' viewBox='0 0 24 24' name='edit-block' />
                    </span>
                </div>
                <div className={cx('col3', 'sm12', 'widgetInfoleft')}>
                    <h5 className={cx('tableTitle')}>{localization[LANG_CONST[_get(data, 'title', '')]]}</h5>
                    <span
                        className={cx(
                            'tableDescription',
                            `${data.group === 'miniCartPreferences' ? 'warningMiniCart' : ''}`
                        )}
                    >
                        {localization[LANG_CONST[_get(data, 'description', '')]]}
                    </span>
                </div>
                <div className={cx('col9', 'sm12', 'widgetInforight')}>
                    {(data.fields || []).map(item => {
                        return (
                            <div
                                automation-id={`at-${item.label.toLowerCase()}`}
                                key={item.name}
                                className={cx('col4', 'md4', 'sm12', 'input', !showButtons ? 'opacity-class' : '')}
                            >
                                {showPricesPrefRadioButtons || showMiniCartPreferences ? (
                                    <>
                                        {showMiniCartPreferences ? (
                                            <RadioButton
                                                theme={cx('customRadio', 'miniCartPreferencesRadioButton')}
                                                name={item.name}
                                                id={item.name}
                                                customCheckMark={cx('checkmark')}
                                                checked={item.label === state.miniCartPreference}
                                                onChange={() => radioButtonHandlerMiniCartPref(item.label, index)}
                                            />
                                        ) : (
                                            <RadioButton
                                                theme={cx('customRadio', 'pricesPrefRadioButton')}
                                                name={item.name}
                                                id={item.name}
                                                customCheckMark={cx('checkmark')}
                                                checked={
                                                    item.name === COMPANY_DATA_L10N.GROSS_PRICE_PREF
                                                        ? state.isShowGrossPriceChosen
                                                        : !state.isShowGrossPriceChosen
                                                }
                                                onChange={() => radioButtonHandler(index)}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <Checkbox
                                        name={item.name}
                                        id={item.name}
                                        checked={state[item.name]}
                                        onChange={() => checkboxHandler(item.name, index)}
                                        disabled={!emailNotificationDataChangeable || item.label === 'n0'}
                                        disabledClass={item.label === 'n0' ? 'opacity-class' : ''}
                                    />
                                )}
                                <label
                                    htmlFor={item.name}
                                    className={cx('LBLTxt', item.label === 'n0' ? 'opacity-class' : '')}
                                >
                                    {(data.group &&
                                        localization &&
                                        localization[LANG_CONST[data.group]?.[item.label]]) ||
                                        localization[LANG_CONST[item.label]]}
                                </label>
                                <span className={cx('tableDescriptionMiniCart')}>{localization[item.description]}</span>
                            </div>
                        );
                    })}
                </div>
                {showButtons &&
                    (emailNotificationDataChangeable ||
                        showPricesPrefRadioButtons ||
                        showMiniCartPreferences ||
                        data.group === 'marketingCommunicationChannels') && (
                        <div className={cx('submit', 'clearfix')}>
                            <Button
                                isDisabled={
                                    Array.isArray(state.isDisabled) ? state.isDisabled[index] : state.isDisabled
                                }
                                className={cx('save-btn')}
                                buttonType='Primary'
                                type='submit'
                                onClick={() => {
                                    saveChanges(data.group, index);
                                    onGASave();
                                }}
                                automationId='at-save-button'
                            >
                                {commonLocalization[GENERIC_L10N.SAVE_BTN]}
                            </Button>
                            <Button
                                isDisabled={
                                    Array.isArray(state.isDisabled) ? state.isDisabled[index] : state.isDisabled
                                }
                                className={cx('cancel-btn')}
                                type='cancel'
                                onClick={() => cancelChanges(data.group, index) & onGACancel()}
                                automationId='at-cancel-button'
                            >
                                {commonLocalization[GENERIC_L10N.CANCEL_BTN]}
                            </Button>
                        </div>
                    )}
            </div>
            {!isLast && <hr />}
        </>
    );
};

export default FooterDataRow;
