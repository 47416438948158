export const GET_PAGE_CONTENT_REQUEST = 'GET_PAGE_CONTENT_REQUEST';
export const GET_PAGE_CONTENT_SUCCESS = 'GET_PAGE_CONTENT_SUCCESS';
export const GET_PAGE_CONTENT_ERROR = 'GET_PAGE_CONTENT_ERROR';
export const UPDATE_PAGE_CONTENT = 'UPDATE_PAGE_CONTENT';
export const CLEAR_PAGE_CONTENT = 'CLEAR_PAGE_CONTENT';
export const CLEAR_ALL_PAGE_CONTENT = 'CLEAR_ALL_PAGE_CONTENT';
export const LOGIN_CONTENT_SUCCESS = 'LOGIN_CONTENT_SUCCESS';
export const GET_SELECTED_OUTLET_REQUEST = 'GET_SELECTED_OUTLET_REQUEST';
export const GET_SELECTED_OUTLET_SUCCESS = 'GET_SELECTED_OUTLET_SUCCESS';
export const GET_OUTLET_LIST_REQUEST = 'GET_OUTLET_LIST_REQUEST';
export const GET_OUTLET_LIST_SUCCESS = 'GET_OUTLET_LIST_SUCCESS';
export const SUBMIT_SELECTED_OUTLET = 'SUBMIT_SELECTED_OUTLET';
export const OUTLET_ERROR = 'OUTLET_ERROR';
export const GET_STOCK_ALLOCATION = 'GET_STOCK_ALLOCATION';
export const GET_STOCK_ALLOCATION_SUCCESS = 'GET_STOCK_ALLOCATION_SUCCESS';
export const UPDATE_COMMON_LOCALIZATION = 'UPDATE_COMMON_LOCALIZATION';
export const PLP_BANNER_CONTENT_SUCCESS = 'PLP_BANNER_CONTENT_SUCCESS';
export const SET_PAGE_TITLE_PREFIX = 'SET_PAGE_TITLE_PREFIX';
export const SET_CHAT_PLUGIN_LOADED = 'SET_CHAT_PLUGIN_LOADED';
export const GET_DELIVERY_INFO = 'GET_DELIVERY_INFO';
