import React from 'react';

export default ({ color }) => {
    if (color === '#616161') {
        return (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
                width='52'
                height='52'
                viewBox='0 0 52 52'
            >
                <defs>
                    <clipPath id='clip-path'>
                        <rect
                            id='Rectangle_24510'
                            data-name='Rectangle 24510'
                            width='21.53'
                            height='21.542'
                            transform='translate(0 0)'
                            fill='none'
                        />
                    </clipPath>
                </defs>
                <g id='Group_48585' data-name='Group 48585' transform='translate(-134 -557)'>
                    <path
                        id='Path_2849'
                        data-name='Path 2849'
                        d='M159,0h0a25,25,0,1,1-25,25A25,25,0,0,1,159,0'
                        transform='translate(1 558)'
                        fill='#f6dbdb'
                        stroke='#f6dbdb'
                        strokeWidth='2'
                    />
                    <g id='Group_48570' data-name='Group 48570' transform='translate(150.094 572.229)'>
                        <g id='Group_48569' data-name='Group 48569' clipPath='url(#clip-path)'>
                            <path
                                id='Path_4732'
                                data-name='Path 4732'
                                d='M17.236,12.98l-.157-.157c-.252-.252-.409-.472-.472-.535-.188-.189-1.76-2.39-2.169-2.863-.378-.5-.6-.471-.6-.471A11.834,11.834,0,0,0,10.224,5.84c.031-.345-1.258-.816-1.258-.816C7.927,4.616,6.574,3.956,6.574,3.956c-.283-.157-.691-.44-1.1-.723A17.919,17.919,0,0,1,4.089,1.975c-.157-.157.063-.315-.062-.441a5.715,5.715,0,0,0-.755-.377l-.126-.126,0-.128A.466.466,0,0,0,2.489.871.364.364,0,0,0,2.455.9L.881,2.479a.526.526,0,0,0,0,.693h.126a.135.135,0,0,1,.126.126,4.054,4.054,0,0,0,.377.754c.125.126.314-.063.44.063A17.347,17.347,0,0,1,3.207,5.5q.471.66.754,1.132s.63,1.384,1.07,2.391c0,0,.439,1.258.817,1.259A11.71,11.71,0,0,0,8.961,13.9s-.031.22.472.6c.472.409,2.674,1.981,2.862,2.17.063.063.284.22.536.471l.126.126c1.163,1.164,1.224,2.17,1.98,2.926.975.975,2.139.88,4.123-1.1s2.109-3.116,1.1-4.122c-.755-.754-1.794-.849-2.926-1.981'
                                fill='none'
                                stroke='#e90000'
                                strokeWidth='1.5'
                            />
                            <path
                                id='Path_4733'
                                data-name='Path 4733'
                                d='M5.62,10.15l4.4-4.6,4.1,3.534L9.182,14.279Z'
                                fill='#e90000'
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            width='52'
            height='52'
            viewBox='0 0 52 52'
        >
            <defs>
                <clipPath id='clip-path'>
                    <rect
                        id='Rectangle_24510'
                        data-name='Rectangle 24510'
                        width='21.53'
                        height='21.542'
                        transform='translate(0 0)'
                        fill='none'
                    />
                </clipPath>
            </defs>
            <g id='Group_48586' data-name='Group 48586' transform='translate(-134 -557)'>
                <path
                    id='Path_2849'
                    data-name='Path 2849'
                    d='M159,0h0a25,25,0,1,1-25,25A25,25,0,0,1,159,0'
                    transform='translate(1 558)'
                    fill='#e90000'
                    stroke='#e90000'
                    strokeWidth='2'
                />
                <g id='Group_48570' data-name='Group 48570' transform='translate(150.094 572.229)'>
                    <g id='Group_48569' data-name='Group 48569' clipPath='url(#clip-path)'>
                        <path
                            id='Path_4732'
                            data-name='Path 4732'
                            d='M17.236,12.98l-.157-.157c-.252-.252-.409-.472-.472-.535-.188-.189-1.76-2.39-2.169-2.863-.378-.5-.6-.471-.6-.471A11.834,11.834,0,0,0,10.224,5.84c.031-.345-1.258-.816-1.258-.816C7.927,4.616,6.574,3.956,6.574,3.956c-.283-.157-.691-.44-1.1-.723A17.919,17.919,0,0,1,4.089,1.975c-.157-.157.063-.315-.062-.441a5.715,5.715,0,0,0-.755-.377l-.126-.126,0-.128A.466.466,0,0,0,2.489.871.364.364,0,0,0,2.455.9L.881,2.479a.526.526,0,0,0,0,.693h.126a.135.135,0,0,1,.126.126,4.054,4.054,0,0,0,.377.754c.125.126.314-.063.44.063A17.347,17.347,0,0,1,3.207,5.5q.471.66.754,1.132s.63,1.384,1.07,2.391c0,0,.439,1.258.817,1.259A11.71,11.71,0,0,0,8.961,13.9s-.031.22.472.6c.472.409,2.674,1.981,2.862,2.17.063.063.284.22.536.471l.126.126c1.163,1.164,1.224,2.17,1.98,2.926.975.975,2.139.88,4.123-1.1s2.109-3.116,1.1-4.122c-.755-.754-1.794-.849-2.926-1.981'
                            fill='none'
                            stroke='#fff'
                            strokeWidth='1.5'
                        />
                        <path
                            id='Path_4733'
                            data-name='Path 4733'
                            d='M5.62,10.15l4.4-4.6,4.1,3.534L9.182,14.279Z'
                            fill='#fff'
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
