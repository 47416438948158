import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import * as styles from './Textarea.css';

const cx = classNames.bind(styles);

const Textarea = props => {
    const { value, name, placeholder, id, rows, cols, theme, maxlength, onChange, automationId, themeTextarea } = props;

    return (
        <div className={`${cx('textareaContainer')} ${theme}`}>
            <textarea
                value={value}
                name={name}
                id={id}
                placeholder={placeholder}
                rows={rows}
                cols={cols}
                maxLength={maxlength}
                onChange={onChange}
                automation-id={automationId}
                className={themeTextarea}
            />
        </div>
    );
};

Textarea.defaultProps = {
    theme: '',
    value: '',
    name: '',
    id: '',
    placeholder: '',
    rows: 4,
    cols: 50,
    maxlength: 1000,
    onChange: () => {},
    automationId: '',
    themeTextarea: ''
};

Textarea.propTypes = {
    theme: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    cols: PropTypes.number,
    maxlength: PropTypes.number,
    onChange: PropTypes.func,
    automationId: PropTypes.string,
    themeTextarea: PropTypes.string
};

export default Textarea;
