import React from 'react';
import Link from 'react-router/lib/Link';
import * as styles from './MyRewardsOnboardingCard.css';
import classNames from 'classnames/bind';
import Stepper from '../../core/Stepper/Stepper';
import Icon from '../../core/Icon/Icon';
import parse from 'html-react-parser';
import { MY_REWARDS } from '../../../common/Constants';
import PropTypes from 'prop-types';
import { getCurrency } from '../../../common/UserManager/UserManager';

const cx = classNames.bind(styles);

const MyRewardsOnboardingCard = ({
    title,
    savingsText,
    savingsValue,
    ordersText,
    ordersValue,
    footerText,
    maxSteps,
    currentStep,
    status,
    statusText,
    link,
    discountAmount,
}) => {
    const isCompleted = status === MY_REWARDS.ONBOARDING_COMPLETED_STATUS;
    const { symbol } = getCurrency();
    return (
        <div className={cx('onboarding-container', isCompleted && 'completed')}>
            <div className={cx('onboarding-heading')}>
                <h1 className={cx('onboarding-title')}>{title}</h1>
                <Icon iconType='svg' width='40px' height='40px' viewBox='0 0 40 40' name='loyalty-onboarding' />
            </div>
            <div className={cx('onboarding-inner')}>
                <p className={cx('onboarding-text')}>{savingsText}</p>
                <p className={cx('onboarding-value')}>{savingsValue}{symbol}</p>
            </div>
            <div className={cx('onboarding-inner')}>
                <p className={cx('onboarding-text', 'orders-text')}>{ordersText}</p>
                <p className={cx('onboarding-value', 'orders-value')}>{isCompleted ? "6" : ordersValue}</p>
            </div>
            <Stepper totalSteps={maxSteps} currentStep={currentStep} isCompleted={isCompleted} icon={null} />
            <div className={cx('onboarding-footer')}>
                <p className={cx('onboarding-footer-text')}>{footerText && parse(footerText.replace('{discountValue}', discountAmount))}</p>
                {link && (
                    <Link to={link} className={cx(isCompleted && "disabled-click")}>
                        <Icon
                            className={cx('arrow-right')}
                            iconType='svg'
                            width='35px'
                            height='35px'
                            viewBox='0 0 35 35'
                            name='loyalty-arrow-right'
                        />
                    </Link>
                )}
            </div>
            {isCompleted && (
                <div className={cx('status')}>
                    <p>{statusText}</p>
                </div>
            )}
        </div>
    );
};

MyRewardsOnboardingCard.propTypes = {
    title: PropTypes.string,
    savingsText: PropTypes.string,
    savingsValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    ordersText: PropTypes.string,
    ordersValue: PropTypes.string,
    footerText: PropTypes.string,
    maxSteps: PropTypes.number.isRequired,
    currentStep: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    status: PropTypes.string,
    statusText: PropTypes.string,
    link: PropTypes.string,
};

export default MyRewardsOnboardingCard;
