import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import Icon from '../../core/Icon/Icon';
import Button from '../../core/Button/Button';
import Input from '../../core/Input/Input';
import Textarea from '../../core/Textarea/Textarea';
import SelectBox from '../../core/SelectBox/SelectBox';
import { submitClaim, resetClaims } from '../../../actions/InvoicesAction';
import * as styles from './CreateClaim.css';
import { INVOICES_L10N, GENERIC_L10N, PAGE_ID } from '../../../common/Constants';

const cx = classNames.bind(styles);
export class CreateClaim extends Component {
    static propTypes = {
        deviceType: PropTypes.objectOf(PropTypes.bool),
        onCancel: PropTypes.func,
        localization: PropTypes.objectOf(PropTypes.string),
        selectedInvoices: PropTypes.arrayOf(PropTypes.any),
        commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        setSelectedInvoices: PropTypes.func,
    };

    static defaultProps = {
        deviceType: {},
        onCancel: () => { },
        localization: {},
        selectedInvoices: [],
        commonLocalization: {},
        setSelectedInvoices: () => { }
    };

    constructor(props) {
        super(props);
        this.state = {
            subject: '01',
            shortDescription: '',
            shortDescError: false,
            emailError: false,
            detailedDescription: '',
            detailedDescError: false,
            email: _get(props.createClaimData, 'email', '')
        };
    }

    componentWillUnmount() {
        const { actions } = this.props;
        actions.resetClaims();
    }

    isFieldEmpty = () => {
        const { shortDescription, detailedDescription, email } = this.state;
        this.setState({
            shortDescError: isEmpty(shortDescription),
            detailedDescError: isEmpty(detailedDescription),
            emailError: isEmpty(email)
        });

        return isEmpty(shortDescription) || isEmpty(detailedDescription) || isEmpty(email);
    };

    handleSubmit = () => {
        const { onCancel, selectedInvoices, createClaimData } = this.props;
        const { subject, shortDescription, detailedDescription, email } = this.state;
        const invoiceNumbers = selectedInvoices.map(item => item.billNo);
        const amounts = selectedInvoices.map(item => item.amount);

        if (this.isFieldEmpty()) return 0;

        const payload = {
            data: {
                invoiceNumbers,
                amounts,
                reasoncode: subject,
                longDescription: detailedDescription,
                customerEmail: email,
                shortDescription,
                customerNumber: selectedInvoices[0].customer,
                totalAmount: _get(createClaimData, 'totalCheckedAmount', '')
            }
        };

        this.props.actions.submitClaim(payload);
        this.props.setSelectedInvoices();
        onCancel();
    };

    onChangeInput = e => {
        this.setState({ [e.target.name]: e.target.value }, this.disbaleError);
    };

    disbaleError = () => {
        const { shortDescription, detailedDescription, email } = this.state;
        if (shortDescription.length > 0) {
            this.setState({ shortDescError: false });
        }
        if (detailedDescription.length > 0) {
            this.setState({ detailedDescError: false });
        }
        if (email > 0) {
            this.setState({ emailError: false });
        }
    };

    onChangeSubject = val => {
        this.setState({ subject: val });
    };

    render() {
        const { localization, createClaimData, commonLocalization, deviceType } = this.props;
        const {
            subject,
            shortDescription,
            detailedDescription,
            shortDescError,
            detailedDescError,
            emailError,
            email
        } = this.state;
        return (
            <div className={cx('createClaimContainer')}>
                <div className={cx('createnewModalTitle')}>
                    <h4 automation-id='at-create-a-claim-title' className={cx('text-left')}>
                        <Icon width='24' height='32' viewBox='0 0 32 33' name='custom-reports' />
                        {localization[INVOICES_L10N.CREATE_CLAIM_TITLE]}
                    </h4>
                    <div className={cx('innerContent')}>
                        <p
                            automation-id='at-total-sum-amount-text'
                            className={cx('totalAmt', 'text-left', deviceType.isMobile && 'totalMobile')}
                        >
                            {localization[INVOICES_L10N.TOTAL_SUM_AMOUNT]}:{' '}
                            {`${_get(createClaimData, 'currency', '')} ${_get(
                                createClaimData,
                                'totalCheckedAmount',
                                ''
                            )}`}
                        </p>
                        <div className={cx('inputPad')}>
                            <SelectBox
                                placeholder={`${localization[INVOICES_L10N.SUBJECT]}*`}
                                automationId='at-subject-dropdown'
                                theme={cx('customBox')}
                                themeSelectBox={cx('pageThemeselectBox')}
                                options={_get(createClaimData, 'subjectsList', [])}
                                onChange={this.onChangeSubject}
                                defaultValue={subject}
                            />

                            <Input
                                automationId='at-short-description-text-box'
                                type='text'
                                id='shortDescription'
                                name='shortDescription'
                                placeholder={`${localization[INVOICES_L10N.SHORT_DESC]}*`}
                                value={shortDescription}
                                onChange={this.onChangeInput}
                                className={cx(shortDescError && 'error')}
                            />
                            {shortDescError && (
                                <span
                                    automation-id='at-empty-short-description-field-error-message'
                                    className={cx('errorMessage')}
                                >
                                    {localization[INVOICES_L10N.ERROR_MESSAGE]}
                                </span>
                            )}
                            <Textarea
                                automationId='at-detailed-description-text-box'
                                type='text'
                                id='detailedDescription'
                                name='detailedDescription'
                                rows={4}
                                placeholder={`${localization[INVOICES_L10N.DETAILED_DESC]}*`}
                                value={detailedDescription}
                                onChange={this.onChangeInput}
                                themeTextarea={cx(detailedDescError && 'error')}
                            />
                            {detailedDescError && (
                                <span
                                    automation-id='at-empty-detailed-description-field-error-message'
                                    className={cx('errorMessage')}
                                >
                                    {localization[INVOICES_L10N.ERROR_MESSAGE]}
                                </span>
                            )}
                            <Input
                                automationId='at-email-address-text-box'
                                type='text'
                                id='email'
                                name='email'
                                placeholder={`${localization[INVOICES_L10N.EMAIL_ADDRESS]}*`}
                                value={email}
                                onChange={this.onChangeInput}
                                className={cx(emailError && 'error')}
                            />
                            {emailError && (
                                <span automation-id='at-empty-email-field-error-message' className={cx('errorMessage')}>
                                    {localization[INVOICES_L10N.ERROR_MESSAGE]}
                                </span>
                            )}
                        </div>
                    </div>

                    <div className={cx('buttonSection')}>
                        <span className={cx('float-left')}>*{localization[INVOICES_L10N.MANDATORY_INFO]}</span>
                        <Button
                            automationId='at-submit-button'
                            className={cx('createButton', 'float-right')}
                            type='button'
                            size='Sm'
                            buttonType='Primary'
                            onClick={this.handleSubmit}
                        >
                            {commonLocalization[GENERIC_L10N.SUBMIT_BTN]}
                        </Button>
                        <Button
                            automationId='at-cancel-button'
                            className={cx('cancelButton', 'float-right')}
                            type='button'
                            size='Sm'
                            onClick={this.props.onCancel}
                        >
                            {commonLocalization[GENERIC_L10N.CANCEL_BTN]}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    deviceType: _get(state, 'context.deviceType'),
    createClaimData: _get(state, 'invoicesDetails.createClaimData'),
    localization: _get(state, 'pageContent.localization'),
    commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            resetClaims,
            submitClaim
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateClaim);
