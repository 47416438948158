import { GET_FINANCIAL_INFO_REQUEST, GET_FINANCIAL_INFO_SUCCESS, CLEAR_REDUCER } from '../actionTypes/FinancialInfoActionTypes';

const initialState = {};
export default function FinancialInformationReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_FINANCIAL_INFO_REQUEST:
            return {
                ...initialState
            };
        case GET_FINANCIAL_INFO_SUCCESS:
            return {
                ...action.data
            };
        case CLEAR_REDUCER: {
            return initialState
        }
        default:
            return state;
    }
}
