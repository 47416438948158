import RestClient from '../RestClient/RestClient';
import RestConfig, { RESPONSE_TYPES } from '../RestClient/RestConfig';
import { AUTH, TEMPLATE_API, PARAMS, HEADERS } from '../common/Api';

export default class OrderTemplateApi {
    static getOrderTemplateExcel(payload) {
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${config.baseInfo.siteId}/products/catalogue/excel`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static postOrderTemplateExcel(payload) {
        const { data } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${config.baseInfo.siteId}/products/catalogue/excel/upload/`;
        config.data = data;
        config.responseType = RESPONSE_TYPES.JSON;
        config.customHeaders = {
            [HEADERS.CONTENT_TYPE]: 'multipart/form-data'
        }

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static getOrderTemplates(payload) {
        const { pageNumber, pageSize, isAllTemplates = false } = payload; //pageSize, sortCode, showMode
        const page = pageNumber !== undefined ? pageNumber : '0';
        const itemSize = pageSize !== undefined ? pageSize : '25';
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${config.baseInfo.siteId}${AUTH.USERS}/${config.baseInfo.userId}${TEMPLATE_API.SAVED_CART}`;

        config.params = isAllTemplates
            ? {
                  show: 'All',
                  sort: 'bySaveTime'
              }
            : {
                  page,
                  pageItemSize: itemSize,
                  show: 'Page',
                  sort: 'bySaveTime'
              };

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static createOrderTemplate(payload) {
        const { data } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${config.baseInfo.siteId}${AUTH.USERS}/${config.baseInfo.userId}${TEMPLATE_API.SAVED_CART}`;
        config.data = data;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static deleteOrderTemplate(payload) {
        const { code } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${config.baseInfo.siteId}${AUTH.USERS}/${config.baseInfo.userId}${TEMPLATE_API.SAVED_CART}/${code}/delete`;

        return RestClient.delete(config).then(json => {
            return json;
        });
    }

    static addToTemplate(payload) {
        const { data } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.data = data;
        config.url = `/${config.baseInfo.siteId}${AUTH.USERS}/${config.baseInfo.userId}${TEMPLATE_API.SAVED_CART}/add`;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static getOrderTemplateDetails(payload) {
        const { code } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${config.baseInfo.siteId}${AUTH.USERS}/${config.baseInfo.userId}${TEMPLATE_API.SAVED_CART}/${code}`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static editOrderTemplateDetails(payload) {
        const { data } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.data = data;
        config.url = `/${config.baseInfo.siteId}${AUTH.USERS}/${config.baseInfo.userId}${TEMPLATE_API.SAVED_CART}/edit`;

        return RestClient.put(config).then(json => {
            return json;
        });
    }

    static updateOrderTemplate(payload) {
        const { data } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.data = data;
        config.url = `/${config.baseInfo.siteId}${AUTH.USERS}/${config.baseInfo.userId}${TEMPLATE_API.SAVED_CART}/update`;

        return RestClient.put(config).then(json => {
            return json;
        });
    }

    static restoreOrderTemplate(payload) {
        const config = RestConfig.v2ComApiConfig(payload);
        config.data = payload.postData;
        config.url = `/${config.baseInfo.siteId}${AUTH.USERS}/${config.baseInfo.userId}${TEMPLATE_API.MY_SAVED_CARDS}/restore`;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static restoreTemplateToCart(payload) {
        const { baseInfo = {}, postData } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.data = postData;
        config.url = `/${config.baseInfo.siteId}${AUTH.USERS}/${baseInfo.userId}${TEMPLATE_API.MY_SAVED_CARDS}/cart/${
            baseInfo[PARAMS.CART_ID]
        }/restore`;

        return RestClient.put(config).then(json => {
            return json;
        });
    }

    static updateOrderTemplateDetails(payload) {
        const { data, baseInfo = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.data = data;
        config.url = `/${config.baseInfo.siteId}${AUTH.USERS}/${baseInfo.userId}${TEMPLATE_API.MY_SAVED_CARDS}/updateordertemplate`;

        return RestClient.put(config).then(json => {
            return json;
        });
    }

    static updateSortingOrder(payload) {
        const { data, baseInfo = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.data = data;
        config.url = `/${config.baseInfo.siteId}${AUTH.USERS}/${baseInfo.userId}${TEMPLATE_API.MY_SAVED_CARDS}/updatesortingorder`;

        return RestClient.put(config).then(json => {
            return json;
        });
    }
}
