/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames/bind';
import NavLink from '../../../components/core/NavLink/NavLink';
import Checkbox from '../../../components/core/Checkbox/Checkbox';
import Layout from '../../../components/site/Layout/Layout';
import Button from '../../../components/core/Button/Button';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import RadioButton from '../../../components/core/RadioButton/RadioButton';
import * as styles from './AccountData.css';
import {
    receiveEmailAlert,
    resetAccountData,
    changePricesPreferences,
    setOrderNotifications,
    setMiniCartPreferences,
    getAccountData
} from '../../../actions/AccountDataAction';
import { setMarketingConsent, setMarketingCommunicationChannels } from '../../../actions/MarketingAction';
import { getPageContent } from '../../../actions/PageContentAction';
import Icon from '../../../components/core/Icon/Icon';
import {
    ACCOUNT_L10N,
    COMPANY_DATA_L10N,
    PERSONAL_DATA_L10N,
    FACT_SHEET_L10N,
    PAGE_ID,
    GENERIC_L10N,
    PERSONAL_DATA_CONSENTS,
    URL_PATHS,
    MY_ACCOUNT_SETTINGS_TABS
} from '../../../common/Constants';
import { getMiniCartWorkingWay } from '../../../common/UserManager/UserManager';
import FooterDataRow from './FooterDataRow';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

export class AccountData extends Component {
    static propTypes = {
        commonData: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
        localization: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        breadCrumbs: PropTypes.oneOfType([PropTypes.array]),
        commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    };

    static defaultProps = {
        commonData: {},
        localization: {},
        breadCrumbs: [],
        commonLocalization: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            orderSaveEmailActive: true,
            orderReleaseEmailActive: true,
            newsletterSubscription: true,
            tradingOffersSubscription: true,
            email: true,
            sms: true,
            orderConfirmation: true, //not allowed to unsubscribe
            orderDispatching: false,
            deliveryPlanning: false,
            loadingActivities: false,
            shipmentStart: false,
            orderFinalized: false,
            isDisabled: true,
            LANG_CONST: {},
            isMobile: false,
            activeCreditDataMobileIndex: 0,
            embeded: this.props?.embeded
        };
        this.pageId = '';
        this.firstRender = true;
    }

    componentDidMount() {
        this.getPageContent();
        window.addEventListener('resize', this.resize.bind(this));
        this.resize();
    }

    componentDidUpdate(prevProps) {
        let pathname = this.props.params?.tmplType;
        if (this.state.embeded) {
            pathname = this.state.embeded;
        }

        let prePathname = prevProps.params?.tmplType;
        if (this.state.embeded) {
            prePathname = this.state.embeded;
        }

        if (pathname !== prePathname) this.getPageContent(true);
    }

    componentWillUnmount() {
        this.props.actions.resetAccountData();
        window.removeEventListener('resize', this.resize.bind(this));
    }

    resize = () => {
        this.setState({ isMobile: window.innerWidth <= 760 });
    };

    getPageContent = (isUpdate = false) => {
        let pathname = this.props.params?.tmplType;
        if (this.state.embeded) {
            pathname = this.state.embeded;
        }

        if (!isUpdate && !this.state.embeded) {
            this.props.actions.getPageContent({ pageId: pathname });
        }
        if (this.state.embeded) {
            this.props.actions.getAccountData({
                pageId: pathname
            });
        }

        this.pageId = pathname;

        if (pathname === PAGE_ID.COMPANY) {
            this.setState({ LANG_CONST: COMPANY_DATA_L10N });
        } else if (pathname === PAGE_ID.PERSONAL) {
            this.setState({ LANG_CONST: PERSONAL_DATA_L10N });
        } else {
            this.setState({ LANG_CONST: FACT_SHEET_L10N });
        }
    };

    getSnapshotBeforeUpdate = prevProps => {
        let pathname = this.props.params?.tmplType;
        if (this.state.embeded) {
            pathname = this.state.embeded;
        }

        let prevPath = prevProps.params?.tmplType;
        if (this.state.embeded) {
            prevPath = this.state.embeded;
        }

        if (
            this.firstRender &&
            !isEmpty(this.props.commonData) &&
            !isEmpty(this.props.commonData.footerData) &&
            _get(prevProps, 'commonData.footerData', {}) !== _get(this.props, 'commonData.footerData', {})
        ) {
            const miniCartPreference = getMiniCartWorkingWay();

            this.setState({
                miniCartPreference
            });

            if (pathname === PAGE_ID.PERSONAL) {
                const { footerData } = this.props.commonData;
                /*
                 * Extract: orderSaveEmailActive, orderReleaseEmailActive, newsletterSubscription, tradingOffersSubscription
                 */
                this.setState({
                    ...footerData.reduce(
                        (all, group) => ({
                            ...all,
                            ...group.fields.reduce(
                                (a, groupItem) => ({
                                    ...a,
                                    [groupItem.name]: groupItem.value
                                }),
                                {}
                            )
                        }),
                        {}
                    )

                    // isDisabled: new Array(footerData.length).fill(true)
                });
            } else {
                const { isShowGrossPriceChosen } = this.props.commonData.footerData;
                this.setState({
                    isShowGrossPriceChosen,
                    isDisabled: true
                });
            }
            this.firstRender = false;
        }

        if (prevPath !== pathname) {
            if (pathname === PAGE_ID.PERSONAL || pathname === PAGE_ID.COMPANY) {
                this.firstRender = true;
            }
            this.props.actions.getPageContent({ pageId: pathname });

            this.pageId = pathname;
        }
        return '';
    };

    renderData = data => {
        const newData = Object.keys(data);
        const { LANG_CONST } = this.state;
        const { localization } = this.props;

        return (newData || []).map(item => (
            <div
                className={cx('confirmwidgetInforows', 'clearfix', item === 'userData' && 'perosnal')}
                key={Math.random()}
            >
                {(data[item] || []).map(subItem => (
                    <div
                        automation-id={`at-${subItem.label.toLowerCase()}`}
                        className={cx('col3', 'sm12', 'commondataList', 'mbrder')}
                        key={subItem.label}
                    >
                        {subItem.url && (
                            <>
                                <h5 className={cx('tableTitle', 'sm5')}>{localization[LANG_CONST[subItem.label]]}</h5>
                                <span className={cx('cartval', 'sm7')}>
                                    <NavLink
                                        linkUrl={subItem.url}
                                        automationId='at-imprint-link'
                                        theme={cx('link-btn')}
                                    >
                                        {localization[LANG_CONST[subItem.value]] || ''}
                                    </NavLink>
                                </span>
                            </>
                        )}

                        {subItem.url === undefined && (
                            <>
                                <h5 className={cx('tableTitle', 'sm5')}>{localization[LANG_CONST[subItem.label]]}</h5>
                                <span className={cx('cartval', 'sm7')}>{subItem.value || '-'}</span>
                            </>
                        )}
                    </div>
                ))}
            </div>
        ));
    };

    renderCreditDataMobile = creditData => {
        const { localization } = this.props;
        return (creditData || []).map((item, index) => (
            <div
                onClick={() => this.setState({ activeCreditDataMobileIndex: index })}
                className={cx('creditDataRightWrapperMobile')}
                key={index}
            >
                <h5
                    className={cx(
                        'creditDataRightDataMobileHeader',
                        'creditDataRightDataMobileWholeSaler',
                        'commondataList',
                        'tableTitle'
                    )}
                >
                    {item.wholesaler || '-'}
                </h5>
                {index === this.state.activeCreditDataMobileIndex ? (
                    <div className={cx('creditDataRightDataMobileOpenWrapper')}>
                        <h5
                            className={cx(
                                'creditDataRightDataMobileHeader',
                                'creditDataRightDataMobileQuickView',
                                'commondataList',
                                'tableTitle'
                            )}
                        >
                            <div style={{ display: 'none' }}>{index}</div>
                            {localization[FACT_SHEET_L10N.QUICK_VIEW]}
                            <Icon
                                className={cx('creditDataRightDataDownArrow')}
                                width='24px'
                                height='24px'
                                viewBox='0 0 24 24'
                                name='up'
                            />
                        </h5>
                        <div className={cx('creditDataRightDataMobileDataWrapper')}>
                            <div className={cx('creditDataRightDataMobileData')}>
                                <h5 className={cx('commondataList', 'tableTitle')}>
                                    {localization[FACT_SHEET_L10N.CREDIT]}
                                </h5>
                                <h5
                                    className={cx(
                                        'creditDataRightDataMobileDataContent',
                                        'commondataList',
                                        'tableTitle'
                                    )}
                                >
                                    {item.credit || '-'}
                                </h5>
                            </div>
                        </div>
                        <div className={cx('creditDataRightDataMobileDataWrapper')}>
                            <div className={cx('creditDataRightDataMobileData')}>
                                <h5 className={cx('commondataList', 'tableTitle')}>
                                    {localization[FACT_SHEET_L10N.CREDIT_DAY]}
                                </h5>
                                <h5
                                    className={cx(
                                        'creditDataRightDataMobileDataContent',
                                        'commondataList',
                                        'tableTitle'
                                    )}
                                >
                                    {item.creditDay || '-'}
                                </h5>
                            </div>
                        </div>
                        <div className={cx('creditDataRightDataMobileDataWrapper')}>
                            <div className={cx('creditDataRightDataMobileData')}>
                                <h5 className={cx('commondataList', 'tableTitle')}>
                                    {localization[FACT_SHEET_L10N.CREDIT_LIMIT]}
                                </h5>
                                <h5
                                    className={cx(
                                        'creditDataRightDataMobileDataContent',
                                        'commondataList',
                                        'tableTitle'
                                    )}
                                >
                                    {item.creditLimit || '-'}
                                </h5>
                            </div>
                        </div>
                        <div className={cx('creditDataRightDataMobileDataWrapper')}>
                            <div className={cx('creditDataRightDataMobileData')} style={{ borderBottom: 'unset' }}>
                                <h5 className={cx('commondataList', 'tableTitle')}>
                                    {localization[FACT_SHEET_L10N.CREDIT_OVERDUE]}
                                </h5>
                                <h5
                                    className={cx(
                                        'creditDataRightDataMobileDataContent',
                                        'commondataList',
                                        'tableTitle'
                                    )}
                                >
                                    {item.creditOverdue || '-'}
                                </h5>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={cx('')}>
                        <h5
                            className={cx(
                                'creditDataRightDataMobileHeader',
                                'creditDataRightDataMobileQuickView',
                                'creditDataRightData',
                                'commondataList',
                                'tableTitle'
                            )}
                        >
                            {localization[FACT_SHEET_L10N.QUICK_VIEW]}
                            <Icon
                                className={cx('creditDataRightDataDownArrow')}
                                width='24px'
                                height='24px'
                                viewBox='0 0 24 24'
                                name='downarrow'
                            />
                        </h5>
                    </div>
                )}
            </div>
        ));
    };

    renderCreditData = creditData => {
        const { localization } = this.props;
        const wholesaler = localization[FACT_SHEET_L10N.WHOLESALER] || '';
        const credit = localization[FACT_SHEET_L10N.CREDIT] || '';
        const creditDay = localization[FACT_SHEET_L10N.CREDIT_DAY] || '';
        const creditLimit = localization[FACT_SHEET_L10N.CREDIT_LIMIT] || '';
        const creditOverdue = localization[FACT_SHEET_L10N.CREDIT_OVERDUE] || '';

        const headers = [wholesaler, credit, creditDay, creditLimit, creditOverdue];

        const renderHeaders = () => {
            return (
                <div className={cx('creditDataRightHeadersWrapper')}>
                    {headers.map((header, index) => (
                        <h5 className={cx('creditDataRightHeader', 'tableTitle', 'sm5')} key={index}>
                            {header}
                        </h5>
                    ))}
                </div>
            );
        };

        const renderCreditData = () => {
            return (creditData || []).map((item, index) => (
                <div className={cx('creditDataRightDataWrapper')} key={index}>
                    <h5 className={cx('creditDataRightData', 'commondataList', 'mbrder', 'tableTitle')}>
                        {item.wholesaler || '-'}
                    </h5>
                    <h5 className={cx('creditDataRightData', 'commondataList', 'mbrder', 'tableTitle')}>
                        {item.credit || '-'}
                    </h5>
                    <h5 className={cx('creditDataRightData', 'commondataList', 'mbrder', 'tableTitle')}>
                        {item.creditDay || '-'}
                    </h5>
                    <h5 className={cx('creditDataRightData', 'commondataList', 'mbrder', 'tableTitle')}>
                        {item.creditLimit || '-'}
                    </h5>
                    <h5 className={cx('creditDataRightData', 'commondataList', 'mbrder', 'tableTitle')}>
                        {item.creditOverdue || '-'}
                    </h5>
                </div>
            ));
        };

        return (
            <div className={cx('creditDataWrapper')}>
                <div className={cx('creditDataLeftWrapper')}>
                    <h5 className={cx('creditDataLeftHeader', 'tableTitle', 'sm5')}>
                        {localization[FACT_SHEET_L10N.CREDIT_DATA] || ''}
                    </h5>
                </div>
                {this.state.isMobile ? (
                    this.renderCreditDataMobile(creditData)
                ) : (
                    <div className={cx('creditDataRightWrapper')}>
                        {renderHeaders()}
                        {renderCreditData()}
                    </div>
                )}
            </div>
        );
    };

    checkboxHandler = (name, index) => {
        this.setState(prevState => ({
            [name]: !prevState[name],
            isDisabled: (
                (Array.isArray(prevState.isDisabled) && prevState.isDisabled) ||
                new Array(this.props.commonData.footerData.length).fill(true)
            ).reduce((a, e, i) => [...a, i === index ? false : e], [])
        }));
    };

    saveChanges = (group, index) => {
        const {
            orderSaveEmailActive,
            orderReleaseEmailActive,
            isShowGrossPriceChosen,
            newsletterSubscription,
            tradingOffersSubscription,
            email,
            sms,
            orderConfirmation,
            orderDispatching,
            deliveryPlanning,
            loadingActivities,
            shipmentStart,
            orderFinalized,
            miniCartPreference
        } = this.state;

        let pathname = this.props.params?.tmplType;
        if (this.state.embeded) {
            pathname = this.state.embeded;
        }

        if (pathname === PAGE_ID.PERSONAL) {
            switch (group) {
                case 'emailNotification':
                    this.props.actions.receiveEmailAlert({
                        data: {
                            orderSaveEmailActive,
                            orderReleaseEmailActive
                        }
                    });
                    break;
                case 'marketingConsentAgreementData':
                    this.props.actions.setMarketingConsent({
                        newsletter: newsletterSubscription,
                        offers: tradingOffersSubscription,
                        consentDate: Math.floor(new Date().getTime() / 1000)
                    });
                    break;
                case 'marketingCommunicationChannels':
                    this.props.actions.setMarketingCommunicationChannels({
                        emailOption: email,
                        smsOption: sms
                    });
                    break;
                case 'miniCartPreferences':
                    this.props.actions.setMiniCartPreferences({
                        miniCartPreference
                    });
                    break;
                case 'orderNotifications':
                    this.props.actions.setOrderNotifications({
                        n0: orderConfirmation,
                        n1: orderDispatching,
                        n2: deliveryPlanning,
                        n3: loadingActivities,
                        n4: shipmentStart,
                        n5: orderFinalized
                    });
                    break;
                default:
                    break;
            }
        } else {
            this.props.actions.changePricesPreferences({
                data: {
                    isGrossPriceSelected: isShowGrossPriceChosen
                }
            });
        }

        const { isDisabled } = this.state;
        this.setState({
            isDisabled:
                (Array.isArray(isDisabled) && isDisabled.reduce((a, e, i) => [...a, (i === index && true) || e], [])) ||
                false
        });

        const isCompanyDataPage = window.location.href.includes(
            URL_PATHS.ACCOUNT_SETTINGS + '/' + MY_ACCOUNT_SETTINGS_TABS.companyData.name
        );
        if (isCompanyDataPage) {
            buttonClickEvent(
                buttonClickEventConfigs.accountSettings.actions.companyData,
                buttonClickEventConfigs.accountSettings.labels.companyDataPricingPreferenceSave,
                buttonClickEventConfigs.accountSettings.categories.accountSettings
            );
        }
    };

    cancelChanges = (group, index) => {
        const { commonData } = this.props;
        let pathname = this.props.params?.tmplType;
        if (this.state.embeded) {
            pathname = this.state.embeded;
        }

        if (pathname === PAGE_ID.PERSONAL) {
            this.setState({
                ...commonData.footerData.reduce(
                    (all, currGroup, i) => ({
                        ...all,
                        ...((i === index &&
                            currGroup.fields.reduce(
                                (a, groupItem) => ({
                                    ...a,
                                    [groupItem.name]: groupItem.value
                                }),
                                {}
                            )) ||
                            [])
                    }),
                    {}
                ),
                miniCartPreference: getMiniCartWorkingWay()
            });
        } else {
            const { isShowGrossPriceChosen } = commonData.footerData;
            if (commonData.footerData.showPricesPrefRadioButtons) {
                this.setState({
                    isShowGrossPriceChosen
                });
            }
        }

        const { isDisabled } = this.state;
        this.setState({
            isDisabled:
                (Array.isArray(isDisabled) && isDisabled.reduce((a, e, i) => [...a, (i === index && true) || e], [])) ||
                false
        });

        const isCompanyDataPage = window.location.href.includes(
            URL_PATHS.ACCOUNT_SETTINGS + '/' + MY_ACCOUNT_SETTINGS_TABS.companyData.name
        );
        if (isCompanyDataPage) {
            buttonClickEvent(
                buttonClickEventConfigs.accountSettings.actions.companyData,
                buttonClickEventConfigs.accountSettings.labels.companyDataPricingPreferenceCancel,
                buttonClickEventConfigs.accountSettings.categories.accountSettings
            );
        }
    };

    radioButtonHandler = index => {
        const { isShowGrossPriceChosen } = this.state;
        this.setState(prevState => ({
            isShowGrossPriceChosen: !isShowGrossPriceChosen,
            isDisabled: (
                (Array.isArray(prevState.isDisabled) && prevState.isDisabled) ||
                new Array(this.props.commonData.footerData.length).fill(true)
            ).reduce((a, e, i) => [...a, i === index ? false : e], [])
        }));
    };

    radioButtonHandlerMiniCartPref = (miniCartPreference, index) => {
        this.setState(prevState => ({
            miniCartPreference,
            isDisabled: (
                (Array.isArray(prevState.isDisabled) && prevState.isDisabled) ||
                new Array(this.props.commonData.footerData.length).fill(true)
            ).reduce((a, e, i) => [...a, i === index ? false : e], [])
        }));
    };

    editHandler = index => {
        this.setState(
            prevState => ({
                isDisabled: (
                    (Array.isArray(prevState.isDisabled) && prevState.isDisabled) ||
                    new Array(this.props.commonData.footerData.length).fill(true)
                ).reduce((a, e, i) => [...a, i === index ? false : e], [])
            }),
            () => {
                const isCompanyDataPage = window.location.href.includes(
                    URL_PATHS.ACCOUNT_SETTINGS + '/' + MY_ACCOUNT_SETTINGS_TABS.companyData.name
                );
                const isDisabled = this.state.isDisabled[index];
                if (!isDisabled && isCompanyDataPage) {
                    buttonClickEvent(
                        buttonClickEventConfigs.accountSettings.actions.companyData,
                        buttonClickEventConfigs.accountSettings.labels.companyDataPricingPreferenceEdit,
                        buttonClickEventConfigs.accountSettings.categories.accountSettings
                    );
                }
            }
        );
    };

    renderFooterData = () => {
        const { commonData } = this.props;

        let { footerData } = commonData;
        //Quick fix for company data page (check AcountDataReducer -> CompanyDataFilter). Footer data should be an array of fields.
        if (!Array.isArray(footerData)) footerData = [footerData];

        return (
            <>
                {footerData.map((data, index) => {
                    // eslint-disable-next-line no-return-assign
                    const showButtons = !(Array.isArray(this.state.isDisabled)
                        ? this.state.isDisabled[index]
                        : this.state.isDisabled);

                    return (
                        <FooterDataRow
                            data={data}
                            showButtons={showButtons}
                            state={this.state}
                            radioButtonHandlerMiniCartPref={this.radioButtonHandlerMiniCartPref}
                            radioButtonHandler={this.radioButtonHandler}
                            checkboxHandler={this.checkboxHandler}
                            saveChanges={this.saveChanges}
                            cancelChanges={this.cancelChanges}
                            editHandler={this.editHandler}
                            index={index}
                        />
                    );
                })}
            </>
        );
    };

    renderFooterDataPersonalData = () => {
        const { commonData, localization } = this.props;

        let { footerData } = commonData;
        //Quick fix for company data page (check AcountDataReducer -> CompanyDataFilter). Footer data should be an array of fields.

        if (!Array.isArray(footerData)) footerData = [footerData];

        const preferences = [
            PERSONAL_DATA_CONSENTS.MARKETING_COMMUNICATION,
            PERSONAL_DATA_CONSENTS.ORDER_NOTIFICATION,
            PERSONAL_DATA_CONSENTS.EMAIL_NOTIFICATION,
            PERSONAL_DATA_CONSENTS.MARKETING_AGREEMENT
        ];
        const configurations = [PERSONAL_DATA_CONSENTS.MINI_CART];
        const filterPreferences = footerData.filter(fd => preferences.includes(fd.group));
        const filterConfigurations = footerData.filter(fd => configurations.includes(fd.group));

        return (
            <>
                {filterPreferences?.length > 0 && (
                    <div className={cx('rowContainer', 'rowBackground')}>
                        <div className={cx('communication-perf', 'clearfix')}>
                            <div className={cx('sm12', 'widgetInfoleft')}>
                                <h5 className={cx('tableTitle')}>
                                    {localization[PERSONAL_DATA_L10N.PREFERENCES_CENTER]}
                                </h5>
                                <span className={cx('tableDescription')}>
                                    {localization[PERSONAL_DATA_L10N.MANAGE_COMMUNICATION_CONSENTS]}
                                </span>
                            </div>
                        </div>
                        <hr />
                        {filterPreferences.map((data, i, list) => {
                            const index = footerData.findIndex(fd => fd.group === data.group);
                            // eslint-disable-next-line no-return-assign
                            const showButtons = !(Array.isArray(this.state.isDisabled)
                                ? this.state.isDisabled[index]
                                : this.state.isDisabled);
                            return (
                                <FooterDataRow
                                    data={data}
                                    showButtons={showButtons}
                                    state={this.state}
                                    radioButtonHandlerMiniCartPref={this.radioButtonHandlerMiniCartPref}
                                    radioButtonHandler={this.radioButtonHandler}
                                    checkboxHandler={this.checkboxHandler}
                                    saveChanges={this.saveChanges}
                                    cancelChanges={this.cancelChanges}
                                    editHandler={this.editHandler}
                                    index={index}
                                    isLast={list.length - 1 === i}
                                />
                            );
                        })}
                    </div>
                )}

                {filterConfigurations?.length > 0 && (
                    <div className={cx('rowContainer')}>
                        <div className={cx('communication-perf', 'clearfix')}>
                            <div className={cx('sm12', 'widgetInfoleft')}>
                                <h5 className={cx('tableTitle')}>
                                    {localization[PERSONAL_DATA_L10N.CONFIGURATION_SETTINGS]}
                                </h5>
                                <span className={cx('tableDescription')}>
                                    {localization[PERSONAL_DATA_L10N.MANAGE_CONFIGURATION_SETTINGS]}
                                </span>
                            </div>
                        </div>
                        <hr />
                        {filterConfigurations.map((data, i, list) => {
                            const index = footerData.findIndex(fd => fd.group === data.group);
                            // eslint-disable-next-line no-return-assign
                            const showButtons = !(Array.isArray(this.state.isDisabled)
                                ? this.state.isDisabled[index]
                                : this.state.isDisabled);

                            return (
                                <FooterDataRow
                                    data={data}
                                    showButtons={showButtons}
                                    state={this.state}
                                    radioButtonHandlerMiniCartPref={this.radioButtonHandlerMiniCartPref}
                                    radioButtonHandler={this.radioButtonHandler}
                                    checkboxHandler={this.checkboxHandler}
                                    saveChanges={this.saveChanges}
                                    cancelChanges={this.cancelChanges}
                                    editHandler={this.editHandler}
                                    index={index}
                                    isLast={list.length - 1 === i}
                                />
                            );
                        })}
                    </div>
                )}
            </>
        );
    };

    getContent() {
        const { commonData, localization } = this.props;
        const { data } = commonData;

        let pathname = this.props.params?.tmplType;
        if (this.state.embeded) {
            pathname = this.state.embeded;
        }

        return (
            <>
                <div className={cx('commonWidget', 'clearfix', 'personalpage')}>
                    <div className={cx('confirmwidgetInfo', 'clearfix', `${this.pageId}Pages`)}>
                        {/* render account  info */}
                        {!isEmpty(_get(commonData, 'data', {})) && !isEmpty(localization) && this.renderData(data)}
                        {/* render footer info */}
                        {_get(commonData, 'footerData', false) &&
                            !isEmpty(localization) &&
                            this.props.commonData.footerData.showPricesPrefRadioButtons &&
                            pathname !== PAGE_ID.PERSONAL &&
                            this.renderFooterData()}
                        {_get(commonData, 'footerData', false) &&
                            !isEmpty(localization) &&
                            pathname === PAGE_ID.PERSONAL &&
                            this.renderFooterDataPersonalData()}
                    </div>
                </div>
                <div className={cx('commonWidget', 'clearfix', 'personalpage', 'factSheetCreditData')}>
                    <div className={cx('confirmwidgetInfo', 'clearfix', `${this.pageId}Pages`)}>
                        {!isEmpty(_get(commonData, 'creditData', {})) &&
                            !isEmpty(localization) &&
                            this.renderCreditData(_get(commonData, 'creditData', {}))}
                    </div>
                </div>
            </>
        );
    }

    fillPageMode() {
        const { breadCrumbs } = this.props;
        const { localization } = this.props;

        return (
            <Layout showDashboardHeader showOutlet isAccountDataContainer={cx('isAccountDataContainer')}>
                <div automation-id={`at-${this.pageId}-page`} className='AccountDataContainer'>
                    <BreadCrumbs breadcrumbValues={breadCrumbs} />
                    <div className={cx('commonWidget', 'clearfix', 'personalpage')}>
                        <h2>
                            <span className={cx('pageTitle')}>{localization[ACCOUNT_L10N.CONTENT_TITLE]}</span>
                        </h2>
                    </div>
                    {this.getContent()}
                </div>
            </Layout>
        );
    }
    embedableMode() {
        return <>{this.getContent()}</>;
    }

    render() {
        const { embeded } = this.props;

        return <>{embeded ? this.embedableMode() : this.fillPageMode()}</>;
    }
}

const mapStateToProps = state => {
    return {
        commonData: _get(state, 'commonData'),
        stateData: _get(state, 'state'),
        breadCrumbs: _get(state, 'pageContent.breadcrumbs', []),
        localization: _get(state, 'pageContent.localization'),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getPageContent,
            receiveEmailAlert,
            resetAccountData,
            changePricesPreferences,
            setMarketingConsent,
            setMarketingCommunicationChannels,
            setOrderNotifications,
            setMiniCartPreferences,
            getAccountData
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountData);
