import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Icon from '../../core/Icon/Icon';
import Button from '../../core/Button/Button';
import Modal from '../../core/Modal/Modal';
import { reOrderItems } from '../../../actions/OrderListAction';
import * as styles from './Reorder.css';
import { buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';
import { URL_PATHS } from '../../../common/Constants';

const cx = classNames.bind(styles);

export const Reorder = ({
    actions,
    automationId,
    buttonValue,
    customReorderStyle,
    from,
    orderId,
    themeCartIcon,
    themeAddCartIcon,
    modalLocalization,
}) => {
    const [showModal, setShowModal] = useState(false);
    const reorderNoteModalOnceShowed = sessionStorage.getItem('reorderNoteModalOnceShowed');

    const closeModal = useCallback(() => {
        if (showModal) setShowModal(false);
    },[showModal]);

    const handleCheckout = useCallback(() => {
        closeModal();
        const path = window.location.pathname;
        let origin_element = '';
        if (path.includes(URL_PATHS.HOME)) {
            origin_element = buttonClickEventConfigs.home.labels.myRecentOrdersReorder;
        } else if (path.includes(URL_PATHS.ORDER_LIST)) {
            origin_element = buttonClickEventConfigs.orderInformation.labels.orderInformationOrderHistoryReorder;
        } else if (path.includes(URL_PATHS.ORDER_DETAILS)) {
            origin_element = buttonClickEventConfigs.order.labels.detailsReorder;
        }
        actions.reOrderItems({ data: { orderCode: orderId, origin_element } });
    }, [actions, orderId, showModal]);

    const handleReorder = () => {
        if (!reorderNoteModalOnceShowed) {
            setShowModal(true);
            sessionStorage.setItem("reorderNoteModalOnceShowed", 1);
        } else {
            handleCheckout();
        };
    };

    const addIcon = (
        <Icon
            className={cx('addIcon', themeCartIcon)}
            iconType='svg'
            width='17px'
            height='20px'
            viewBox='0 -3 17 16'
            name='addCart-Icon'
        />
    );

    return (
        <div className={cx('reorder-container')}>
            <Button
                automation-id={automationId}
                className={cx('reorder', customReorderStyle)}
                type='button'
                size='Sm'
                buttonType='Primary'
                onClick={handleReorder}
            >
                {buttonValue}
                {from === 'HOME' && (
                    <span className={cx(themeAddCartIcon)}>{addIcon}</span>
                )}
            </Button>
            {showModal && (
                <Modal
                    className={cx('reorderModalOverlay')}
                    showModal={showModal}
                    modalTheme={cx('reorderModalTheme')}
                    modalTitleTheme={cx('reorderModalTitle')}
                    themeContent={cx('reorderModalContent')}
                    themeClose={cx('themeClose')}
                    themeModalBlock={cx('reorderModalWrapper')}
                    title={<div className={cx('reorderModalTitleText')}>
                        {modalLocalization.header}&nbsp;<strong>{orderId}</strong>
                        <Icon className={cx('reorderModalTitleIcon')} iconType='svg' width='42' height='48' viewBox='0 0 42 48' name='sent' />
                    </div>}
                    titleAutomationId='at-add-to-order-template-title-text'
                    onClose={() => setShowModal(false)}
                >
                    <>
                        <div className={cx('reorderModalNoteBar')}>
                            <strong>{modalLocalization.warning}</strong> {modalLocalization.subtext}
                        </div>
                        <div className={cx('reorderModalButtons')}>
                            <Button
                                automation-id="at-reorder-modal-checkout-button"
                                className={cx('reorderModalButton', 'reorderModalCheckoutButton')}
                                type='button'
                                size='Sm'
                                buttonType='Primary'
                                onClick={handleCheckout}
                            >{modalLocalization.checkoutBtn}</Button>
                            <Button
                                automation-id="at-reorder-modal-cancel-button"
                                className={cx('reorderModalButton', 'reorderModalCancelButton')}
                                type='button'
                                size='Sm'
                                buttonType='Secondary'
                                onClick={closeModal}
                            >{modalLocalization.cancelBtn}</Button>
                        </div>
                    </>
                </Modal>
            )}
        </div>
    );
};


Reorder.propTypes = {
    orderId: PropTypes.string,
    automationId: PropTypes.string,
    customReorderStyle: PropTypes.string,
    buttonValue: PropTypes.string,
    from: PropTypes.string,
    modalLocalization: PropTypes.shape({
        header: PropTypes.string,
        subtext: PropTypes.string,
        warning: PropTypes.string,
        cancelBtn: PropTypes.string,
        checkoutBtn: PropTypes.string,
    }),
    themeCartIcon: PropTypes.string
};

Reorder.defaultProps = {
    orderId: '',
    automationId: '',
    customReorderStyle: '',
    buttonValue: '',
    from: '',
    themeCartIcon: '',
    modalLocalization: {
        header: "You are about to reorder order",
        subtext: "This action will replace the current items in the cart!",
        warning: "NOTE: ",
        cancelBtn: "Cancel",
        checkoutBtn: "Go to checkout",
    },
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            reOrderItems
        },
        dispatch
    )
});

export default connect(null, mapDispatchToProps)(Reorder);
