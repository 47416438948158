import React from 'react';

import styles from './CreditIssueNotificationStatus.pcss';
import { CreditIssueIcon } from '../Icon/CreditIssueIcon';
import { CreditIssueNotification } from '../Notification/CreditIssueNotification';
import { useDispatch, useSelector } from 'react-redux';
import { showNotification } from '../../../../actions/CreditInfoAction';
import { GENERIC_L10N, PAGE_ID } from '../../../../common/Constants';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../../common/GoogleTagManager/GoogleTagManager';

export const CreditIssueNotificationStatus = () => {
    const dispatch = useDispatch();
    const commonLocalization = useSelector(state => state.pageContent.commonLocalization[PAGE_ID.GENERIC]);

    if (!commonLocalization) return null;

    return (
        <>
            <div
                className={styles.status}
                onClick={() => {
                    dispatch(showNotification());
                    buttonClickEvent(
                        buttonClickEventConfigs.header.actions.header,
                        buttonClickEventConfigs.header.labels.headerCreditIssue,
                        buttonClickEventConfigs.header.categories.header
                    );
                }}
            >
                <span className={styles.icon}>
                    <CreditIssueIcon width={16} height={16} />
                </span>
                <span className={styles.text}>{commonLocalization[GENERIC_L10N.CREDIT_ISSUE]}</span>
            </div>
            <CreditIssueNotification />
        </>
    );
};
