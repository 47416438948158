import _isEmpty from 'lodash/isEmpty';
import {
    GET_MINI_CART_SUCCESS,
    GET_MULTI_CART_SUCCESS,
    GET_CART_SUCCESS,
    CLEAR_ALL_CART_SUCCESS,
    CLEAR_SAVED_CART_SUCCESS,
    UPDATE_MINI_CART_LIST,
    UPDATE_TEMPORARY_PLP_CART,
    UPDATE_MINI_CART_COUNT,
    CLEAR_MINI_CART_ENTRY,
    CLEAR_MINI_CART,
    UPDATE_RETURN_EMPTIES,
    UPDATE_MARKETING_MATERIALS,
    CLEAR_REDUCER,
    CREATE_TEMP_MINI_CART,
    CHANGE_ORDER_TYPE,
    UPDATE_SAVED_MINI_CART_TOTAL_PRICE
} from '../actionTypes/CartActionTypes';
import {
    GET_WHOLE_SALER_SUCCESS,
    GET_PLANT_TYPE_SUCCESS,
    SET_DELIVERY_DATE,
    UPDATE_DELIVERY_TYPE,
    UPDATE_WHOLESALER_OUTLET,
} from '../actionTypes/DeliveryActionTypes';
import { DELIVERY_TYPES, PLP_TYPES } from '../common/Constants';
import { getDeliveryType, getActiveWholeSaler, isReturnEmpties } from '../common/UserManager/UserManager';

const initPlpMiniCartItems = {
    PAL : {},
    CS: {},
    LAY: {},
    ST: {}
}

const initialState = {
    orderType: PLP_TYPES.ORDER,
    miniCartItems: [],
    plpMiniCartItems: initPlpMiniCartItems,
    miniCart: {},
    cart: {},
    isReturnEmpties: isReturnEmpties(),
    wholesaler: {},
    plantDetails: {},
    deliveryType: isReturnEmpties() ? DELIVERY_TYPES.PICKUP : getDeliveryType() || DELIVERY_TYPES.ORDER_DRINKS,
    wholeSalerId: getActiveWholeSaler() || '',
	
};

export default function CartReducer(state = initialState, action = {}) {
    switch (action.type) {
        case CHANGE_ORDER_TYPE:
            const { orderType } = state;

            if (orderType === action.payload.type) {
                return state;
            }

            return {
                ...state,
                orderType: action.payload.type,
                miniCartItems: [],
                miniCart: { totalCartCount: 0 }
            }
        case GET_MINI_CART_SUCCESS: {
            const totalCartCount = state.miniCart.totalCartCount || action.data.totalCartCount || 0;
            return {
                ...state,
                miniCart: { ...action.data, totalCartCount}
            };
        }
        case GET_MULTI_CART_SUCCESS: {
            const { totalCartCount = 0, showPricingMessage = '', productValidationMessage, cartList = [] } = action.data || {};
            try {
                action.data.showRddCalender = !_isEmpty(action.data.availableDeliveryDates); //HYB-462 fix
            } catch (ex) {
                // eslint-disable-next-line no-console
                console.error('CartReducer: Error in setting deliveryDate from availableDates: ', ex);
            }

            let miniCartItems = [];
            cartList.forEach(cart => {
                miniCartItems = [
                    ...miniCartItems,
                    ...cart.cartDetails.entries.orderEntries.reduce((all, entry) => [
                        ...all,
                        {
                            code: entry.product.code,
                            shortCode: entry.product.codeShort,
                            name: entry.product.name,
                            stockFlag: entry.product.stockFlag,
                            price: entry.product.price,
                            freeItem: entry.freeItem,
                            promotionId: entry.promotionId,
                            quantity: entry.quantity,
                            quantityType: entry.qtyType,
                            quantityTypeName: entry.qtyTypeName,
                            entryNumber: entry.entryNumber
                        }
                    ], [])
                ]
            });
            

            const plpMiniCartItems = miniCartItems.reduce((a, e) => ({
                ...a,
                [e.quantityType]: {
                    ...(a[e.quantityType] || {}),
                    ...((a[e.quantityType] || {})[e.code]
                        ? 
                        {[e.code]: {
                            quantityType: e.quantityType,
                            quantity: e.quantity + a[e.quantityType][e.code].quantity
                        }}
                        : 
                        {[e.code]: {
                            quantityType: e.quantityType,
                            quantity: e.quantity
                        }}
                    )
                }
            }), initPlpMiniCartItems);

            // Product validation message only potentially applies during reorder, therefore we need to validate it isn't undefined

            return {
                ...state,
                miniCart: { totalCartCount },
                cart: action.data,
                showPricingMessage,
                miniCartItems,
                plpMiniCartItems,
                productValidationMessage: productValidationMessage || ''
            };
        }
        case GET_CART_SUCCESS: {
            return {
                ...state,
                cart: action.data
            };
        }
        case GET_WHOLE_SALER_SUCCESS: {
            return {
                ...state,
                wholesaler: action.data
            };
        }
        case GET_PLANT_TYPE_SUCCESS: {
            return {
                ...state,
                plantDetails: action.data
            };
        }
        case CLEAR_ALL_CART_SUCCESS: {
            const isClearCart = !!(action.data || {}).isClearCart;

            return {
                ...state,
                miniCart: {},
                cart: { isClearCart },
                deliveryType: getDeliveryType() || DELIVERY_TYPES.ORDER_DRINKS,
                isReturnEmpties: false
            };
        }

        case CLEAR_SAVED_CART_SUCCESS: {
            const isClearCart = !!(action.data || {}).isClearCart;

            return {
                ...state,
                miniCart: {},
                cart: { isClearCart },
                deliveryType: getDeliveryType() || DELIVERY_TYPES.ORDER_DRINKS,
                isReturnEmpties: false
            };
        }

        case SET_DELIVERY_DATE: {
            return {
                ...state,
                deliveryDate: action.data
            };
        }
        case UPDATE_DELIVERY_TYPE: {
            return {
                ...state,
                deliveryType: action.data
            };
        }
        case CREATE_TEMP_MINI_CART: {
            return {
                ...state,
                plpMiniCartItems: action.data,
            }
        }
        case UPDATE_TEMPORARY_PLP_CART: {
            let { plpMiniCartItems = {} } = state;
            const { code, quantityType, isAdd, substitute, quantity } = action.payload;

            if (plpMiniCartItems[quantityType] && plpMiniCartItems[quantityType][code]) {
                if (substitute) {
                    plpMiniCartItems = {
                        ...plpMiniCartItems,
                        [quantityType]: {
                            ...(plpMiniCartItems[quantityType] || {}),
                            [code]: {
                                quantityType,
                                quantity
                            }
                        }
                    }
                } else {
                    plpMiniCartItems = {
                        ...plpMiniCartItems,
                        [quantityType]: {
                            ...(plpMiniCartItems[quantityType] || {}),
                            [code]: {
                                quantityType,
                                quantity: 
                                    plpMiniCartItems[quantityType][code].quantity
                                    + (quantity * (isAdd && 1 || -1))
                            }
                        }
                    }
                }

            } else {
                plpMiniCartItems = {
                    ...plpMiniCartItems,
                    [quantityType]: {
                        ...(plpMiniCartItems[quantityType] || {}),
                        [code]: {
                            quantityType,
                            quantity
                        }
                    }
                }
            }


            return {
                ...state,
                plpMiniCartItems
            }
        }
        case UPDATE_MINI_CART_LIST: {
            const updateItem = (entry) => {
                const productsList = state.miniCartItems;
                const { code, quantityType, isAdd, substitute, freeItem } = entry;
                let { quantity } = entry;
                //substitude or add quantity
                const idx = productsList.findIndex(p => {
                    // let res = false;
                    if (p.code === code) {
                        if (p.hasOwnProperty('freeItem')) {
                            if (p.freeItem === freeItem) {
                                return true;
                            }

                            return false;
                        }
                        return true;
                    }
                    return false
                });

                if (idx !== -1) {
                    if (!substitute) {
                        quantity = isAdd ? quantity : quantity * -1;
                        return {
                            ...state,
                            miniCartItems: productsList.reduce((a, item, i) => [
                                ...a,
                                ...(i === idx
                                     ? (item.quantity + quantity) > 0  //Update product quantity
                                        ? [{
                                            ...item,
                                            entryNumber: item.entryNumber || null,
                                            freeItem,
                                            quantity: Math.max(item.quantity + quantity, 0)
                                        }]
                                         : [] // Quantity is less than zero
                                    : [item])
                            ], [])
                        };
                    }

                    // Add specific quantity to an existing item (Manually edit the quantity field)


                    return {
                        ...state,
                        miniCartItems: productsList.reduce((a, item, i) => [
                            ...a,
                            ...( i === idx
                                ? [{
                                    ...item,
                                    entryNumber: item.entryNumber || null,
                                    freeItem,
                                    quantity
                                }]
                                : [item])
                        ], [])
                    };
                    
                }

                //Add new item
                return {
                    ...state,
                    miniCartItems: [
                        ...state.miniCartItems,
                        {
                            code,
                            codeShort: "",
                            name: "",
                            stockFlag: "",
                            price: {},
                            freeItem,
                            quantity,
                            quantityType,
                            entryNumber: null,
                        }
                    ]
                };
            }

            const { entry } = action.data;
            if (Array.isArray(entry)) {
                //Build the cart items in advanced
                return {
                    ...state,
                    miniCartItems: entry
                }
            }
            //Add cart item manuel trough ADD_TO_CART action
            return updateItem(entry);
        }
        case CLEAR_MINI_CART_ENTRY: {
            const productsList = state.miniCartItems;
            const {code} = action;
            const idx = productsList.findIndex(p => p.code === code);

            if (idx !== -1) {
                return {
                    ...state,
                    miniCartItems: state.miniCartItems.filter((item, index) => index !== idx),
                    plpMiniCartItems: {
                        ...state.plpMiniCartItems,
                        [code]: undefined
                    }
                }
            }
            break;
        }
        case CLEAR_MINI_CART:{
            return{
                ...state,
                miniCartItems: [],
                plpMiniCartItems: initPlpMiniCartItems,
                miniCart: { totalCartCount: 0 }
            }

        }
        case UPDATE_MINI_CART_COUNT: {
            const { payload = {} } = action
            const {override, qtyAdded} = payload
            // const productsList = [...state.miniCartItems];

            let totalCartCount = qtyAdded;
            
            // let totalCartCount = productsList.reduce((a, {quantity}) => 
            //             a + quantity, 0);

            // totalCartCount = totalCartCount < 0 ? 0 : totalCartCount

            if (state.miniCart.totalCartCount) {
                totalCartCount += state.miniCart.totalCartCount;
            }

            return {
                ...state,
                miniCart: {
                    ...state.miniCart,
                    totalCartCount: override ? qtyAdded : totalCartCount
                }
            };
        }

        case UPDATE_SAVED_MINI_CART_TOTAL_PRICE: {
            const { payload = {} } = action
            const { savedCartTotalPrice, showGrossPrice} = payload

            return {
                ...state,
                miniCart: {
                    ...state.miniCart,
                    savedCartTotalPrice: savedCartTotalPrice,
                    showGrossPrice: showGrossPrice
                }
            };
        }

        case UPDATE_RETURN_EMPTIES: {
            const deliveryType = action.data.isReturnEmpties
                ? DELIVERY_TYPES.PICKUP
                : getDeliveryType() || DELIVERY_TYPES.ORDER_DRINKS;

            return {
                ...state,
                deliveryType,
                isReturnEmpties: action.data.isReturnEmpties
            };
        }

        case UPDATE_MARKETING_MATERIALS: {
            const deliveryType = action.data.isUseMarketingMaterials
                ? DELIVERY_TYPES.PICKUP
                : getDeliveryType() || DELIVERY_TYPES.ORDER_DRINKS;

            return {
                ...state,
                deliveryType,
                isUseMarketingMaterials: action.data.isUseMarketingMaterials
            };
        }
        case UPDATE_WHOLESALER_OUTLET: {
            return {
                ...state,
                wholeSalerId: action.data
            };
        }

		// case UPDATE_FAVOURITE_IN_CART: {

        //     const newData = { ...state };

        //     const { bdaaSuggestion } = newData;
        //     const index = bdaaSuggestion.findIndex(item => item.code === action.code);

        //     bdaaSuggestion[index] = {
        //         ...bdaaSuggestion[index],
        //         isFav: action.isFavourite
        //     };
		// 	console.log('bdaaSuggestion => ', bdaaSuggestion);
        //     return {
        //         ...state,
        //         bdaaSuggestion: newData.bdaaSuggestion
        //     };
        // }
        case CLEAR_REDUCER:
            return {
                ...initialState
            };
        default:
            return state;
    }

}
