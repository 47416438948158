import { takeLatest, call, put } from "redux-saga/effects";
import { setDashboardOverview } from "../actions/CoolerDashboardAction";
import { GET_COOLER_DASHBOARD_OVERVIEW } from "../actionTypes/CoolerDashboardActionTypes";
import CoolerAPI from "../interfaces/Coolers/CoolerAPI";
import FactorySaga from "./FactorySaga";

export function* getCoolerDashboardOverview(action) {
    const {response, isSuccess} = yield call(FactorySaga, CoolerAPI.getCoolerDashboard, action)

    if (isSuccess) {
        const { data } = response;
        yield put(setDashboardOverview(data));
    } else {
        console.log("Show error")
    }
}

export default function* watchCoolerRequest() {
    yield takeLatest(GET_COOLER_DASHBOARD_OVERVIEW, getCoolerDashboardOverview)
}