import React from 'react';

import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { HOWITWORKS_QUICK_ORDER } from '../../../../common/Constants';

import classNames from 'classnames/bind';
import * as styles from './AccountShoppingQuickOrder.css';
import cart from '../../../../assets/svg/account-cart.svg';
import catalogue from '../../../../assets/svg/account-catalogue.svg';
import download from '../../../../assets/svg/account-download.svg';
import upload from '../../../../assets/svg/account-upload.svg';

const cx = classNames.bind(styles);
export const HowItWorks = () => {
    const localization = useSelector(state => state.pageContent.localization);


    return (
        <div>
            <div>
                <p className={cx('title')}>{localization && localization[HOWITWORKS_QUICK_ORDER.SHOPPING_LIST_HOW_IT_WORKS]}</p>
                <p className={cx('description')}>{localization && localization[HOWITWORKS_QUICK_ORDER.SHOPPING_LIST_ORDER_QUICKLY]}</p>
            </div>

            <div className={cx('tutorialBox')}>
                <div className={cx('flexCenter')}>
                    <div className={cx('imgBg')}>
                        <img className={cx('iconImg')} src={download} />
                    </div>
                    <p className={cx('description', 'description-black')}>
                       {localization && localization[HOWITWORKS_QUICK_ORDER.SHOPPING_LIST_STEP_1]}
                    </p>
                </div>
                <div className={cx('flexCenter')}>
                    <div className={cx('imgBg')}>
                        <img src={catalogue} className={cx('iconImg')} />
                    </div>
                    <p className={cx('description', 'description-black')}>
                    {localization && localization[HOWITWORKS_QUICK_ORDER.SHOPPING_LIST_STEP_2]}
                    </p>
                </div>
                <div className={cx('flexCenter')}>
                    <div className={cx('imgBg')}>
                        <img src={upload} className={cx('iconImg')} />
                    </div>
                    <p className={cx('description', 'description-black')}>
                    {localization && localization[HOWITWORKS_QUICK_ORDER.SHOPPING_LIST_STEP_3]}
                    </p>
                </div>
                <div className={cx('flexCenter')}>
                    <div className={cx('imgBg')}>
                        <img src={cart} className={cx('iconImg')} />
                    </div>

                    <p className={cx('description', 'description-black')}>
                    {localization && localization[HOWITWORKS_QUICK_ORDER.SHOPPING_LIST_STEP_4]}
                    </p>
                </div>
            </div>
        </div>
    );
};
