import { put, takeLatest, call } from 'redux-saga/effects';
import FactorySaga from './FactorySaga';
import AccountDataApi from '../interfaces/AccountData/AccountDataApi';
import { notifySuccess, notifyError } from '../actions/NotificationAction';
import {
    GET_ACCOUNT_INFO_REQUEST,
    GET_ACCOUNT_DATA_ERROR,
    GET_ACCOUNT_DATA_SUCCESS,
    RECEIVE_EMAIL_REQUEST,
    RECEIVE_EMAIL_SUCCESS,
    RECEIVE_EMAIL_ERROR,
    CHANGE_PRICES_PREF_REQUEST,
    CHANGE_PRICES_PREF_SUCCESS,
    CHANGE_PRICES_PREF_ERROR,
    SET_ORDER_NOTIFICATIONS,
    SET_MINI_CART_PREFERENCES,
    SET_MINI_CART_PREF_ERROR
} from '../actionTypes/AccountDataActionTypes';

import {
    logoutUser
} from '../interfaces/Login/LoginApi';
import { redirectTo } from '../common/Utils/Utils';
import { URL_PATHS } from '../common/Constants';

export function* getAccountDataSaga(action) {
    try {
        const result = yield call(FactorySaga, AccountDataApi.getAccountData, action);
        if (result.isSuccess) {
            // will update the response
            yield put({
                type: GET_ACCOUNT_DATA_SUCCESS,
                data: { data: result.response.data, pageId: action.payload.pageId }
            });

            if (result.response.data.marketingConsentAgreementData.errorMessage) {
                yield put(notifyError({ 
                    message: result.response.data.marketingConsentAgreementData.errorMessage,
                    isAutoClose: false
                }));
            }
        } else {
            // TODO: will remove
            yield put({ type: GET_ACCOUNT_DATA_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_ACCOUNT_DATA_ERROR });
    }
}

export function* updatePreferencesSaga(action) {
    try {
        const result = yield call(FactorySaga, AccountDataApi.updatePreferences, action);

        if (result.isSuccess) {
            yield put({ type: RECEIVE_EMAIL_SUCCESS, data: result.response.data });
            yield call(getAccountDataSaga, { payload: { pageId: 'personaldata' } });
            // TODO: will remove message
            yield put(notifySuccess(result.response.data));
        } else {
            yield put({ type: RECEIVE_EMAIL_ERROR, data: result.response.data });
        }
    } catch (err) {
        yield put({ type: RECEIVE_EMAIL_ERROR });
    }
}

export function* changePricesPreferencesSaga(action) {
    try {
        const result = yield call(FactorySaga, AccountDataApi.changePricePreferences, action);

        if (result.isSuccess) {
            yield put({ type: CHANGE_PRICES_PREF_SUCCESS, data: result.response.data });
            yield call(getAccountDataSaga, { payload: { pageId: 'companydata' } });
            // TODO: will remove message
            yield put(notifySuccess(result.response.data));
        } else {
            yield put({ type: CHANGE_PRICES_PREF_ERROR, data: result.response.data });
        }
    } catch (err) {
        yield put({ type: CHANGE_PRICES_PREF_ERROR });
    }
}

export function* setOrderNotificationsSaga(action) {
    try {
        const { payload } = action;
        const orderNotificationCodes = [];

        Object.entries(payload).forEach(([key, value]) => {if (value === true) {orderNotificationCodes.push(key)}});
        action.orderNotificationCodes = orderNotificationCodes.filter((x) => x !== 'n0'); //n0 should not be send to backend
        const result = yield call(FactorySaga, AccountDataApi.updateOrderNotifications, action);

        if (result.isSuccess) {
            yield put(notifySuccess(result.response.data));
        } else {
            yield put({ type: RECEIVE_EMAIL_ERROR, data: result.response.data });
        }
    } catch (err) {
        yield put({ type: RECEIVE_EMAIL_ERROR })
    }
}

export function* setMiniCartPreferencesSaga(action) {
    try {
        const result = yield call(FactorySaga, AccountDataApi.changeMiniCartPreferences, action);
        if (result.isSuccess) {
            yield call(FactorySaga, logoutUser, action);
            redirectTo(URL_PATHS.LOGOUT);
        } else {
            yield put({ type: SET_MINI_CART_PREF_ERROR, data: result.response.data });
        }
    } catch (err) {
        console.error(err);
        yield put({ type: SET_MINI_CART_PREF_ERROR });
    }
}

export default function* watchAccountDataRequest() {
    yield takeLatest(GET_ACCOUNT_INFO_REQUEST, getAccountDataSaga);
    yield takeLatest(RECEIVE_EMAIL_REQUEST, updatePreferencesSaga);
    yield takeLatest(CHANGE_PRICES_PREF_REQUEST, changePricesPreferencesSaga);
    yield takeLatest(SET_ORDER_NOTIFICATIONS, setOrderNotificationsSaga)
    yield takeLatest(SET_MINI_CART_PREFERENCES, setMiniCartPreferencesSaga)
    

}
