import { put, takeLatest, call } from 'redux-saga/effects';
import FactorySaga from './FactorySaga';
import ClaimHistoryApi from '../interfaces/ClaimHistory/ClaimHistoryApi';
import {
    GET_CLAIM_TICKET_HISTORY_REQUEST,
    GET_CLAIM_TICKET_HISTORY_SUCCESS,
    GET_CLAIM_TICKET_HISTORY_ERROR
} from '../actionTypes/ClaimTicketHistoryActionTypes';

export function* getClaimHistorySaga(action) {
    try {
        const result = yield call(FactorySaga, ClaimHistoryApi.getClaimTicketHistory, action);
        if (result.isSuccess) {
            yield put({ type: GET_CLAIM_TICKET_HISTORY_SUCCESS, claimHistoryData: result.response.data });
        } else {
            yield put({ type: GET_CLAIM_TICKET_HISTORY_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_CLAIM_TICKET_HISTORY_ERROR });
    }
}

export default function* watchClaimHistoryRequest() {
    yield takeLatest(GET_CLAIM_TICKET_HISTORY_REQUEST, getClaimHistorySaga);
}
