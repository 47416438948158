import {
    GET_INVOICES_SUCCESS,
    GET_FILTER_INVOICES_SUCCESS,
    GET_INVOICES_ERROR,
    CREATE_CLAIM_SUCCESS,
    GET_INVOICE_PDF_SUCCESS,
    RESET_CLAIMS,
    RESET_INVOICES,
    GET_INVOICE_ZIP_SUCCESS,
    CLEAR_INVOICES_PDF_DATA
} from '../actionTypes/InvoicesActionTypes';
import invoicesFillteredData from '../service/InvoicesFillter';
import { createClaimFillteredData } from '../service/CreateClaimFIllter';

const initialState = {};

export default function InvoicesReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_INVOICES_SUCCESS: {
            const transformedData = invoicesFillteredData(action.data);
            return {
                ...state,
                ...transformedData,
                invoicePageMounted: true
            };
        }
        case GET_INVOICES_ERROR: {
            const { error = {} } = action.data || {};

            return {
                ...state,
                message: {
                    isSuccess: false,
                    error
                }
            };
        }
        case GET_FILTER_INVOICES_SUCCESS: {
            const transformedData = invoicesFillteredData(action.data);
            return {
                ...state,
                ...transformedData
            };
        }
        case CREATE_CLAIM_SUCCESS: {
            const createClaimTransformedData = createClaimFillteredData(action.data);
            return {
                ...state,
                createClaimData: {
                    isSuccess: action.isSuccess,
                    ...createClaimTransformedData
                }
            };
        }
        case GET_INVOICE_PDF_SUCCESS: {
            return {
                ...state,
                invoicePDFData: action.data.blob,
                xNumberPdfs: action.data.xNumberPdfs,
                invoiceDownloaded: true
            };
        }
        case GET_INVOICE_ZIP_SUCCESS: {
            return {
                ...state,
                invoiceZIPData: action.data.blob,
                xNumberPdfs: action.data.xNumberPdfs,
                invoiceDownloaded: true
            };
        }
        case RESET_CLAIMS: {
            return {
                ...state,
                createClaimData: initialState
            };
        }
        case CLEAR_INVOICES_PDF_DATA: {
            return {
                ...state,
                invoiceDownloaded: false,
                pdfsDownloaded: null
            };
        }
        case RESET_INVOICES: {
            return initialState;
        }
        default:
            return state;
    }
}
