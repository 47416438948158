import _get from 'lodash/get';

const filterClaimHistoryData = list => {
    const processTypeList = [];
    const periodList = [];
    const statusList = [];
    let defaultPeriod = '';
    let defaultProcessType = '';
    let defaultStatus = '';
    if (!list.currentPeriod) {
        const periods = _get(list.ticketSearchForm, 'periods', []);
        for (let i = 0; i < periods.length; i += 1) {
            const periodObj = {
                id: periods[i].key,
                value: periods[i].key,
                label: periods[i].value
            };
            if (Number(periods[i].key) === _get(list.ticketSearchForm, 'period', 30)) {
                defaultPeriod = periods[i].key;
            }
            periodList.push(periodObj);
        }
    }
    if (!list.currentProcessType) {
        const processTypes = _get(list.ticketSearchForm, 'processTypesFilter', []);
        for (let i = 0; i < processTypes.length; i += 1) {
            const processTypeObj = {
                id: processTypes[i].key,
                value: processTypes[i].key,
                label: processTypes[i].value
            };
            if (processTypes[i].key === _get(list.ticketSearchForm, 'processType', 'All')) {
                defaultProcessType = processTypes[i].key;
            }
            processTypeList.push(processTypeObj);
        }
    }

    if (!list.currentStatusType) {
        const statusTypes = _get(list.statusesFilter, 'entry', []);
        for (let i = 0; i < statusTypes.length; i += 1) {
            const statusTypeObj = {
                id: statusTypes[i].key,
                value: statusTypes[i].key,
                label: statusTypes[i].value
            };
            if (statusTypes[i].key === _get(list.ticketSearchForm, 'statuses[0]', 'All')) {
                defaultStatus = statusTypes[i].key;
            }
            statusList.push(statusTypeObj);
        }
    }

    return {
        periods: periodList,
        processTypes: processTypeList,
        statusTypesList: statusList,
        currentProcessType: defaultProcessType,
        currentPeriod: defaultPeriod,
        currentStatusType: defaultStatus,
        claims: list.results
    };
};
export default filterClaimHistoryData;
