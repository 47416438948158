import PropTypes from 'prop-types';
import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import classNames from 'classnames/bind';
import Layout from '../../../components/site/Layout/Layout';
import Image from '../../../components/core/Image/Image';
import Login from './templates/Login';
import { getPageContent } from '../../../actions/PageContentAction';
import { clearLoginMessage } from '../../../actions/LoginAction';
import { PAGE_ID, LOGIN_UID, URL_PATHS, ROUND_LOGO_COUNTRIES, UNIQUE_ID } from '../../../common/Constants';
import { isUserLoggedIn, getSiteId, getLocalization } from '../../../common/UserManager/UserManager';
import { redirectTo, isInSmartEdit } from '../../../common/Utils/Utils';
import * as styles from './LoginPage.css';
import SmartEdit from '../../../components/site/SmartEdit/SmartEdit';
import Icon from '../../../components/core/Icon/Icon';

const cx = classNames.bind(styles);
export class LoginPage extends Component {
    static propTypes = {
        actions: PropTypes.objectOf(PropTypes.func),
        assetBaseUrl: PropTypes.string,
        loginInfo: PropTypes.objectOf(PropTypes.any)
    };

    static defaultProps = {
        actions: {},
        assetBaseUrl: '',
        loginInfo: {}
    };

    constructor(props) {
        super(props);
        // UI-4262: Navigate to home page if user still has a valid session.
        isUserLoggedIn() && redirectTo(URL_PATHS.HOME);
        /* istanbul ignore next */
        this.template = {
            login: Login,
            forgot: lazy(() => import(/* webpackChunkName: "ForgotPassword" */ './templates/ForgotPassword')),
            reset: lazy(() => import(/* webpackChunkName: "ResetPassword" */ './templates/ResetPassword')),
            registerUser: lazy(() => import(/* webpackChunkName: "RegisterUser" */ './templates/RegisterUser')),
            imprint: lazy(() => import(/* webpackChunkName: "aboutus" */ '../../../components/site/AboutUs/AboutUs'))
        };
        this.state = {};
    }

    componentDidMount() {
        // If Russian user is trying to login, redirect to the other website.
        const localization = getLocalization();
        if (localization) {
            const siteId = localization?.siteId
            if (siteId === 'russia') {
                window.location.href = 'https://xn--80abigshmntdy5h.xn--p1ai/cabinet/sign-in';
            }
        }
        this.updatePageTitle();
        this.initialize();
    }

    componentDidUpdate(prevProps) {
        // If Russian user is trying to login, redirect to the other website.
        if (!_isEqual(prevProps.location, this.props.location) || Object.keys(this.props.location).length > 0) {
            if (this.props.location.query.siteId === 'russia') {
                window.location.href = 'https://xn--80abigshmntdy5h.xn--p1ai/cabinet/sign-in';
            }
        }
        this.updatePageTitle();
        if (prevProps.params.tmplType !== this.props.params.tmplType) {
            this.initialize();
        }
    }

    initialize = () => {
        let pageId;
        const params = {};
        /* istanbul ignore next */
        switch (this.props.params.tmplType) {
            case 'forgot': {
                this.loadTemplate = this.template.forgot;
                pageId = PAGE_ID.LOGIN_FORGOT;
                break;
            }
            case 'reset': {
                this.loadTemplate = this.template.reset;
                pageId = PAGE_ID.LOGIN_RESET;
                const {
                    query: { siteId, lang, token },
                    search = ''
                } = this.props.location || {};

                if (token) {
                    // To avoid specical char issue fetching the token from location.search instead of query string.
                    [params.token] = search.replace('?token=', '').split('&');
                }
                params.siteId = siteId;
                params.lang = lang;
                break;
            }
            case 'imprint': {
                this.loadTemplate = this.template.imprint;
                pageId = PAGE_ID.LOGIN_IMPRINT;
                break;
            }
            case 'register': {
                this.loadTemplate = this.template.registerUser;
                pageId = PAGE_ID.LOGIN_REGISTER_USER;
                break;
            }
            default: {
                const { search = '' } = this.props.location || {};
                this.loadTemplate = this.template.login;
                params.search = encodeURIComponent(search);
                pageId = PAGE_ID.LOGIN;
            }
        }

        this.setState({ pageId });

        if (_isEmpty(this.props.loginInfo[pageId]) || isInSmartEdit()) {
            this.props.actions.getPageContent({ pageId, params });
        }
    };

    updatePageTitle = () => {
        const { pageTitle = '' } = this.props.loginInfo[this.state.pageId] || {};
        // React Helemt can be used if need to update more than just a title.
        document.title = pageTitle;
    };

    render() {
        const Template = this.loadTemplate;
        const templateData = this.props.loginInfo[this.state.pageId] || {};
        const privacyComp = _get(this.props.loginInfo, `${LOGIN_UID.FOOTER}.${LOGIN_UID.PRIVACY}`, {});
        const logoComp = templateData[LOGIN_UID.SITE_LOGO] || {};
        const bgBannerComp = templateData[LOGIN_UID.BG_BANNER];
        const imprintComp = templateData[LOGIN_UID.IMPRINT_PARA];
        const isRoundLoginContainer = ROUND_LOGO_COUNTRIES.includes(getSiteId());
        return (
            <Layout hideHeader isLoginFooter isNoSticky theme='loginLayout'>
                <div className={cx('loginContainer', isRoundLoginContainer && 'roundLoginContainer')}>
                    <div className={cx('col12', 'pad0')}>
                        <section className={cx('loginBlock')}>
                            <SmartEdit data={bgBannerComp}>
                                <div
                                    className={cx('col6', 'sm12', 'hiddenSm', 'rgtBanner')}
                                    style={
                                        bgBannerComp && {
                                            backgroundImage: `url(${this.props.assetBaseUrl}${bgBannerComp.url})`
                                        }
                                    }
                                />
                                {window.location.pathname === '/login' && <div className={cx('loginBackButtonContainer')}>
                                    <div className={cx('loginBackButton')}>
                                        <a href={URL_PATHS.PRE_LOGIN}>
                                            <Icon
                                                className={cx('loginBackIcon')}
                                                iconType='svg'
                                                width='16px'
                                                height='16px'
                                                viewBox='0 0 16 16'
                                                name='down'
                                            />
                                        </a>
                                    </div>
                                </div>}
                                <div className={cx('col6', 'md7', 'sm12', 'loginSection', 'loginInner')}>
                                    <SmartEdit data={logoComp}>
                                        {logoComp.url && (
                                            <Image
                                                automationId='at-cchbc-logo'
                                                theme={cx('logoImg')}
                                                src={logoComp.url}
                                                alt={logoComp.altText}
                                                linkUrl={URL_PATHS.PRE_LOGIN}
                                            />
                                        )}
                                    </SmartEdit>
                                    <Suspense fallback={<div />}>
                                        {Template && (
                                            <Template
                                                data={{ ...templateData, privacyComp, imprintComp }}
                                                message={this.props.loginInfo.message}
                                                location={this.props.location}
                                            />
                                        )}
                                    </Suspense>
                                </div>
                            </SmartEdit>
                        </section>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        loginInfo: state.loginPage,
        assetBaseUrl: _get(state, 'context.environment.assetBaseUrl')
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            clearLoginMessage,
            getPageContent
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
