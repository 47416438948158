import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { PRE_LOGIN_UID } from '../../../common/Constants';
import { findComponent } from '../../../common/Utils/Utils';

import * as styles from './Workflow.css';

const cx = classNames.bind(styles);

const Workflow = ({ className, cmsComponents }) => {
    if (!cmsComponents) {
        return null;
    }

    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);
    const bannerComponent = findComponent(cmsComponents, PRE_LOGIN_UID.HOWITWORKS_COMPONENTS.BANNER_UID);
    const titleComponent = findComponent(cmsComponents, PRE_LOGIN_UID.HOWITWORKS_COMPONENTS.TITLE_UID);
    const paragraphComponent = findComponent(cmsComponents, PRE_LOGIN_UID.HOWITWORKS_COMPONENTS.PARAGRAPH_UID);
    const registerComponent = findComponent(cmsComponents, PRE_LOGIN_UID.HOWITWORKS_COMPONENTS.REGISTER_UID);
    const loginComponent = findComponent(cmsComponents, PRE_LOGIN_UID.HOWITWORKS_COMPONENTS.LOGIN_UID);
    const orderComponent = findComponent(cmsComponents, PRE_LOGIN_UID.HOWITWORKS_COMPONENTS.ORDER_UID);

   

    return (
        <section className={cx('workflow', className)}>
            <div className={cx('workflow-info')}>
                <h2>{titleComponent?.content}</h2>
                <p>{paragraphComponent?.content}</p>
              
                 <ul className={cx('workflow-list-grid')}>
                    <div className={cx('line')} />
                    <li className={cx('red-circle-background')}>01</li>
                    <li className={cx('red-circle-background')}>02</li>
                    <li className={cx('red-circle-background')}>03</li>
                   
                </ul>
                

                <ul className={cx('workflow-list-grid', 'list-content')}>
                    <li className={cx('position-relative')}>
                        <p>
                        {registerComponent?.content}
                        </p>
                        </li>
                    <li className={cx('position-relative')}>
                        <p>
                        {loginComponent?.content}
                        </p>
                        </li>
                    <li className={cx('position-relative')}>
                        <p>
                        {orderComponent?.content}
                        </p>
                        
                        </li>
                </ul>
            </div>
            <div className={cx('workflow-img-container')}>
                {(bannerComponent && (
                    <img src={`${assetBaseUrl}/${bannerComponent.url}`} alt={bannerComponent.altText} />
                )) || <img src='../../../assets/images/video.png' alt={'bannerComponent.altText'} />}
            </div>
        </section>
    );
};

export default Workflow;
