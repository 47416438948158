export const GET_ACCOUNT_INFO_REQUEST = 'GET_ACCOUNT_INFO_REQUEST';
export const GET_ACCOUNT_DATA_SUCCESS = 'GET_ACCOUNT_DATA_SUCCESS';
export const GET_ACCOUNT_DATA_ERROR = 'GET_ACCOUNT_DATA_ERROR';
export const RECEIVE_EMAIL_REQUEST = 'RECEIVE_EMAIL_REQUEST';
export const RECEIVE_EMAIL_SUCCESS = 'RECEIVE_EMAIL_SUCCESS';
export const RECEIVE_EMAIL_ERROR = 'RECEIVE_EMAIL_ERROR';
export const CHANGE_PRICES_PREF_REQUEST = 'CHANGE_PRICES_PREF_REQUEST';
export const CHANGE_PRICES_PREF_SUCCESS = 'CHANGE_PRICES_PREF_SUCCESS';
export const CHANGE_PRICES_PREF_ERROR = 'CHANGE_PRICES_PREF_ERROR';
export const RESET_ACCOUNT_DATA = 'RESET_ACCOUNT_DATA';
export const SET_ORDER_NOTIFICATIONS = 'SET_ORDER_NOTIFICATIONS';
export const SET_MINI_CART_PREFERENCES = 'SET_MINI_CART_PREFERENCES';
export const SET_MINI_CART_PREF_ERROR = 'SET_MINI_CART_PREF_ERROR';

