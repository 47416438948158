import React from 'react';
import _get from 'lodash/get';

import ClaimsHistory from '../../ClaimsHistory/ClaimsHistory';

export const AccountMyServicesClaims = props => {


    return <ClaimsHistory onlyRenderTicketSection />;
};
