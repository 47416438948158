import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { AUTH, PARAMS, REFRESH_TOKEN } from '../common/Api';
import { CDC_INFO } from '../../common/Constants';
import qs from "qs";

export function getAccessToken(payload) {
    const config = RestConfig.v2ComApiConfig(payload);
    config.url = `/${config.baseInfo[PARAMS.SITE_ID]}/token/extend`;
    config.data = {
        refreshToken: config.baseInfo[REFRESH_TOKEN]
    };

    return RestClient.post(config).then(json => {
        return json;
    });
}

export function loginUser(payload) {
    const config = RestConfig.authorizationApiConfig(payload);
    config.url = `/token`;
    const params = new URLSearchParams();
    params.append('grant_type', 'custom');
    params.append('client_id', CDC_INFO.client_id); 
    params.append('client_secret', CDC_INFO.client_secret);
    params.append('baseSite', config.baseInfo[PARAMS.SITE_ID]);
    config.data = qs.stringify({
        'UID': encodeURIComponent(payload.UID),
        'UIDSignature': encodeURIComponent(payload.UIDSignature),
        'signatureTimestamp': payload.signatureTimestamp
    });
    config.params = params
    return RestClient.post(config).then(json => {
        return json;
    });
}


export function getUser(payload) {
    const config = RestConfig.v2ComApiConfig(payload);
    config.url = `/${config.baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}${AUTH.CURRENT}`;

    return RestClient.get(config).then(json => {
        return json;
    });
}

export function getDefaultDeliverDate(payload) {
    const config = RestConfig.v2ComApiConfig(payload);
    config.url = `/${config.baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${config.baseInfo[PARAMS.USER_ID]}${
        AUTH.DELIVERY_DATES
    }`;

    return RestClient.get(config).then(json => {
        return json;
    });
}

export function getUserType(payload) {
    const config = RestConfig.v2ComApiConfig(payload);
    config.url = `/${config.baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${config.baseInfo[PARAMS.USER_ID]}${
        AUTH.CUSTOMER_TYPE
    }`;

    return RestClient.get(config).then(json => {
        return json;
    });
}

export function verifyUser(payload) {
    const config = RestConfig.v2NoAuthComApiConfig(payload);
    config.url = `/${config.baseInfo[PARAMS.SITE_ID]}${AUTH.LOGIN}${AUTH.USER}${AUTH.IS_VALID}`;
    config.data = payload.data;

    return RestClient.post(config).then(json => {
        return json;
    });
}

export function registerUser(payload) {
    const config = RestConfig.v2NoAuthComApiConfig(payload);
    config.url = `/${config.baseInfo[PARAMS.SITE_ID]}${AUTH.LOGIN}${AUTH.USER}${AUTH.REGISTER_USER}`;
    config.data = payload.data;

    return RestClient.post(config).then(json => {
        return json;
    });
}

export function logoutUser(payload) {
    const { baseInfo = {} } = payload;
    const config = RestConfig.v2ComApiConfig(payload);
    config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${AUTH.LOGOUT}`;

    return RestClient.post(config).then(json => {
        return json;
    });
}

export function updateUserLocale(payload) {
    const config = RestConfig.v2NoAuthComApiConfigNoL10N(payload);
    config.url = `/${config.baseInfo[PARAMS.SITE_ID]}${AUTH.LOCALE}`;
    const {formData} = payload;
    let email;
    /*
    todo refactor to have on the payload only required data (instead of passing events from CDC SDK)
    CDC SDK has some internal issues for which we have to use different handlers on different pages
    to make this Commerce API call (i.e. `onSubmit`, `onBeforeSubmit` have different payload structures)
    * */
    if (formData) {
        email = formData.loginID;
    } else {
        email = payload.formModel.identifier;
    }
    const locale = config?.baseInfo[PARAMS.LANG]?.replace('_', '-');
    const data = {
        email,
        locale,
        country: config.baseInfo[PARAMS.SITE_ID]
    }
    config.data = data;
    return RestClient.post(config).then(json => {
        return json;
    });
}
