import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Link from 'react-router/lib/Link';
import _get from 'lodash/get';
import _find from 'lodash/find';
import Layout from '../../../components/site/Layout/Layout';
import PageHeader from '../../../components/site/PageHeader/PageHeader';
import UserMaintenanceForm from '../../../components/site/UserMaintenanceForm/UserMaintenanceForm';
import Button from '../../../components/core/Button/Button';
import Modal from '../../../components/core/Modal/Modal';
import { PAGE_ID, USER_MAIN_L10N, GENERIC_L10N } from '../../../common/Constants';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';
import Icon from '../../../components/core/Icon/Icon';
import { getPageContent } from '../../../actions/PageContentAction';
import { phoneNumber } from '../../../common/Utils/Utils';
import { getAccountData } from '../../../actions/AccountDataAction';
import { clearAllNotification } from '../../../actions/NotificationAction';
import {
    getUserMaintenanceDetails,
    onDeleteFromUser,
    onResetEditSuccess
} from '../../../actions/UserMaintenanceAction';
import * as styles from './UserMaintenance.css';
import _isEmpty from 'lodash/isEmpty';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

export class UserMaintenance extends Component {
    static propTypes = {
        userMaintenanceDetails: PropTypes.objectOf(PropTypes.any),
        localization: PropTypes.objectOf(PropTypes.string),
        commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        message: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    };

    static defaultProps = {
        userMaintenanceDetails: {},
        localization: {},
        commonLocalization: {},
        message: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedEditUserData: {},
            addUserData: {},
            deleteUserModal: false,
            editUser: false,
            addUser: false,
            selectUserId: '',
            registered: true,
            saveEnable: true,
            statusValue: {}
        };
    }

    componentDidMount() {
        const { embeded } = this.props;
        if (embeded) {
            this.props.actions.getUserMaintenanceDetails();
            return;
        }
        this.props.actions.getPageContent({ pageId: PAGE_ID.USER_MAINTENANCE });
    }

    onDetailsHandler = (e, id) => {
        e.preventDefault();
        const listItem = this[id].current;
        /* istanbul ignore next */
        if (listItem) {
            listItem.classList.toggle('UserMaintenance-expandedListItem');
        }
    };

    onDeleteUserModal = (e, rowId) => {
        this.props.actions.clearAllNotification();
        this.setState({
            deleteUserModal: true,
            selectUserId: rowId
        });
    };

    onCloseDeleteUserModal = () => {
        this.setState({
            deleteUserModal: false
        });
    };

    onDeleteUserEvent = (e, rowId) => {
        const deleteUserInfo = { userId: rowId }; // TO DO Need to Change
        this.props.actions.onDeleteFromUser({ params: deleteUserInfo });
        this.setState({
            deleteUserModal: false
        });
        buttonClickEvent(
            buttonClickEventConfigs.accountSettings.actions.userManagement,
            buttonClickEventConfigs.accountSettings.labels.userManagementDelete,
            buttonClickEventConfigs.accountSettings.categories.accountSettings
        );
    };

    handleAddNewUser = () => {
        this.props.actions.getAccountData({ pageId: PAGE_ID.COMPANY });
        this.props.actions.onResetEditSuccess();
        this.props.actions.clearAllNotification();
        this.setState({
            addUser: true,
            registered: true,
            saveEnable: true
        });
        buttonClickEvent(
            buttonClickEventConfigs.accountSettings.actions.userManagement,
            buttonClickEventConfigs.accountSettings.labels.userManagementAddNewUser,
            buttonClickEventConfigs.accountSettings.categories.accountSettings
        );
    };

    handleEditUser = (e, row) => {
        this.props.actions.getAccountData({ pageId: PAGE_ID.COMPANY });
        this.props.actions.onResetEditSuccess();
        this.props.actions.clearAllNotification();
        this.setState({
            selectedEditUserData: {
                ...row
            },
            editUser: true,
            registered: true
        });
        buttonClickEvent(
            buttonClickEventConfigs.accountSettings.actions.userManagement,
            buttonClickEventConfigs.accountSettings.labels.userManagementEdit,
            buttonClickEventConfigs.accountSettings.categories.accountSettings
        );
    };

    handleCancel = () => {
        this.props.actions.onResetEditSuccess();
        this.props.actions.clearAllNotification();
        this.setState({
            editUser: false,
            addUser: false,
            addUserData: {},
            selectedEditUserData: {},
            registered: true,
            saveEnable: true
        });
        window.scrollTo(0, 0);
    };

    renderTableHeaderRow = (value = '', styleName, automationId, symbol = '') => {
        return (
            <div
                className={cx('tableCell', styleName, 'column-heading')}
                automation-id={automationId}
            >{`${value} ${symbol}`}</div>
        );
    };

    renderTableContentRow = (value, styleName, contentStyle, automationId) => {
        return (
            <div className={cx('tableCell', styleName)} automation-id={automationId}>
                <div className={cx('tableCellContent', contentStyle)}>{value}</div>
            </div>
        );
    };

    renderTableRow = (value, l10nValue, styleName, contentStyle, automationId, symbol = '') => {
        return (
            <div className={cx('tableCell', styleName)} automation-id={automationId}>
                <div className={cx('tableCellHeading')}>{`${l10nValue} ${symbol}`}</div>
                <div className={cx('tableCellContent', contentStyle)}>{value}</div>
            </div>
        );
    };

    renderHeader = () => {
        const { localization } = this.props;
        return (
            <div className={cx('tablerow', 'tableHeader')}>
                {this.renderTableHeaderRow(localization[USER_MAIN_L10N.NAME], 'nameCell', 'at-name-column-heading')}
                {this.renderTableHeaderRow(
                    localization[USER_MAIN_L10N.USER_NAME],
                    'userCell',
                    'at-username-column-heading'
                )}
                {this.renderTableHeaderRow(localization[USER_MAIN_L10N.ROLE], 'roleCell', 'at-role-column-heading')}
                <div className={cx('tableCell', 'empty-cell', 'column-heading', 'btnCell', 'editCell')}>&nbsp;</div>
                <div className={cx('tableCell', 'empty-cell', 'column-heading', 'btnCell')}>&nbsp;</div>
            </div>
        );
    };

    renderUserMobileView = () => {
        const { localization } = this.props;
        return (
            <div className={cx('tablerow', 'tableHeader', 'tabletViewStyles', 'tabletHeader')}>
                {this.renderTableHeaderRow(localization[USER_MAIN_L10N.NAME], 'nameCell', 'at-name-column-heading')}
                {this.renderTableHeaderRow(localization[USER_MAIN_L10N.USER_NAME], 'userCell', 'at-username-text')}
                {this.renderTableHeaderRow(localization[USER_MAIN_L10N.ROLE], 'roleCell', 'at-role-text')}
            </div>
        );
    };

    renderDeleteModal = rowId => {
        const { localization, commonLocalization } = this.props;
        const { deleteUserModal } = this.state;
        return (
            <div className={cx('deleteModal')} automation-id='at-delete-user-confirmation-popup'>
                <Modal
                    showModal={deleteUserModal}
                    modalTheme={cx('userThemeModal')}
                    modalTitleTheme={cx('modalTitleTheme')}
                    themeContent={cx('themeContent')}
                    themeClose={cx('themeClose')}
                    themeModalBlock={cx('themeModalBlock')}
                    title={localization[USER_MAIN_L10N.DELETE_USER]}
                    defaultCloseBtn={false}
                    titleAutomationId='at-delete-user-popup-title-text'
                >
                    <div className={cx('clearContent')}>
                        <p automation-id='at-popup-sub-title-text'>{localization[USER_MAIN_L10N.DELETE_CONTENT]}</p>
                        <div className={cx('bottomBtns')}>
                            <Button
                                automationId='at-cancel-button'
                                type='button'
                                size={cx('Sm')}
                                className={cx('userBtns', 'cancelBtn')}
                                onClick={this.onCloseDeleteUserModal}
                            >
                                {commonLocalization[GENERIC_L10N.CANCEL_BTN]}
                            </Button>
                            <Button
                                automationId='at-delete-button'
                                type='button'
                                buttonType={cx('Primary')}
                                size={cx('Md')}
                                className={cx('userBtns', 'deleteBtn')}
                                onClick={e => this.onDeleteUserEvent(e, rowId)}
                            >
                                {localization[USER_MAIN_L10N.DELETE]}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    };

    renderTableLayout = (row = {}) => {
        const { localization } = this.props;
        const { firstName, lastName } = row;
        const fullName = `${lastName}, ${firstName}`;
        const { deleteUserModal, selectUserId } = this.state;
        return (
            <div className={cx('tablerow', 'layoutContent')} key={row.userId}>
                {this.renderTableRow(
                    fullName,
                    localization[USER_MAIN_L10N.NAME],
                    'nameCell',
                    'docContent',
                    'at-name-column-value'
                )}
                {this.renderTableRow(
                    row.userAlias,
                    localization[USER_MAIN_L10N.USER_NAME],
                    'userCell',
                    'statusContent',
                    'at-username-column-value'
                )}
                {this.renderTableRow(
                    row.role,
                    localization[USER_MAIN_L10N.ROLE],
                    'roleCell',
                    'dueContent',
                    'at-role-columnt-value'
                )}
                <div className={cx('tableCell', 'empty-cell', 'btnCell', 'editCell')}>
                    <div className={cx('tableCellContent', 'empty-cell')}>
                        <Button
                            automationId='at-edit-button'
                            className={cx('editDel', 'edit')}
                            type='button'
                            buttonType='Secondary'
                            size='Sm'
                            id='editData'
                            onClick={e => this.handleEditUser(e, row)}
                        >
                            {localization[USER_MAIN_L10N.EDIT]}
                        </Button>
                    </div>
                </div>
                <div className={cx('tableCell', 'empty-cell', 'btnCell')}>
                    <div className={cx('tableCellContent', 'checkboxContent')}>
                        <Button
                            automationId='at-delete-button'
                            className={cx('editDel', 'usermodal')}
                            type='button'
                            buttonType='Ordinary'
                            size='Sm'
                            id='usermodal'
                            onClick={e => this.onDeleteUserModal(e, row.userId)}
                        >
                            {localization[USER_MAIN_L10N.DELETE]}
                        </Button>
                    </div>
                </div>
                {deleteUserModal && selectUserId === row.userId && this.renderDeleteModal(row.userId)}
            </div>
        );
    };

    renderbottomViewContent = (row = {}) => {
        const { localization } = this.props;
        const { deleteUserModal, selectUserId } = this.state;
        return (
            <div className={cx('col12', 'sm12', 'pad16', 'visibleSm', 'mobileBtm')} key={row.userId}>
                <div className={cx('mobBdr')} />
                <Button
                    automationId='at-edit-option'
                    className={cx('editDel', 'edit')}
                    type='button'
                    buttonType='Secondary'
                    size='Sm'
                    id='editData'
                    onClick={e => this.handleEditUser(e, row)}
                >
                    {localization[USER_MAIN_L10N.EDIT]}
                </Button>
                <Button
                    automationId='at-delete-option'
                    className={cx('editDel', 'usermodal')}
                    type='button'
                    buttonType='Ordinary'
                    size='Sm'
                    id='usermodal'
                    onClick={e => this.onDeleteUserModal(e, row.userId)}
                >
                    {localization[USER_MAIN_L10N.DELETE]}
                </Button>
                {deleteUserModal && selectUserId === row.userId && this.renderDeleteModal(row.userId)}
            </div>
        );
    };

    renderDesktopLayout = () => {
        const { userMaintenanceDetails, localization } = this.props;
        const userMaintenanceList = _get(userMaintenanceDetails, 'cchUserMaintenanceData', []);
        return (
            <div className={cx('tableContent', 'desktopLayout')} key='desktopTableLayout'>
                <div className={cx('invoiceTable', 'tableWrapper')} automation-id='at-list-of-user-table'>
                    {this.renderHeader()}
                    {userMaintenanceList.length > 0 ? (
                        userMaintenanceList.map(row => {
                            return this.renderTableLayout(row.value);
                        })
                    ) : (
                        <li automation-id='at-no-orders-found-text' className={cx('clearfix', 'noResults')}>
                            {localization[USER_MAIN_L10N.NO_RESULTS_FOUND] && (
                                <Icon iconType='svg' width='24' height='24' viewBox='0 0 24 24' name='no-results' />
                            )}
                            {localization[USER_MAIN_L10N.NO_RESULTS_FOUND]}
                        </li>
                    )}
                </div>
            </div>
        );
    };

    renderMobileLayout = () => {
        const { userMaintenanceDetails, localization } = this.props;
        const userMaintenanceList = _get(userMaintenanceDetails, 'cchUserMaintenanceData', []);
        return (
            <div className={cx('mobileTabletLayout')} key='mobilelayout'>
                <div className={cx('tableContent')} automation-id='at-list-of-user-tile'>
                    {/* Tablet & Mobile Repeat - 1 */}
                    {/* {deviceType.isMobile && TO DO DeviceType */}
                    {userMaintenanceList.length > 0 ? (
                        userMaintenanceList.map(row => {
                            const { value = {} } = row;
                            const { firstName, lastName } = value;
                            const fullName = `${lastName}, ${firstName}`;
                            const iconViewList = (
                                <Icon
                                    className={cx('accordionArrow')}
                                    iconType='svg'
                                    width='24px'
                                    height='24px'
                                    viewBox='0 0 16 16'
                                    name='acc-down'
                                />
                            );
                            const ref = `UserMaintenance-${row.value.userId}`;
                            this[ref] = React.createRef();
                            return (
                                <div ref={this[ref]} className={cx('mobileWrapper')} key={row.value.userId}>
                                    <div className={cx('sm12', 'visibleSm', 'mobileContentSpace')}>
                                        <h3 className={cx('col6', 'pad0', 'mobileTitle')} automation-id='at-name-text'>
                                            {localization[USER_MAIN_L10N.NAME]}
                                        </h3>
                                        <p className={cx('col6', 'pad0')} automation-id='at-name-value'>
                                            {fullName}
                                        </p>
                                        <Link
                                            className={cx('mobileDetailsLink')}
                                            onClick={e =>
                                                this.onDetailsHandler(e, `UserMaintenance-${row.value.userId}`)
                                            }
                                        >
                                            {localization[USER_MAIN_L10N.DETAILS]}
                                            {iconViewList}
                                        </Link>
                                    </div>
                                    {/* }  TO DO DeviceType */}
                                    <div
                                        ref={this[ref]}
                                        className={cx('tableWrapper', 'mobileActiveTable', 'visibleNone')}
                                    >
                                        {this.renderHeader(row.value)}
                                        {this.renderTableLayout(row.value)}
                                        {this.renderUserMobileView(row.value)}
                                    </div>
                                    {this.renderbottomViewContent(row.value)}
                                </div>
                            );
                        })
                    ) : (
                        <li
                            automation-id='at-no-orders-found-text'
                            className={cx('orderlistItem', 'clearfix', 'noResults')}
                        >
                            <Icon iconType='svg' width='24' height='24' viewBox='0 0 24 24' name='no-results' />
                            {localization[USER_MAIN_L10N.NO_RESULTS_FOUND]}
                        </li>
                    )}
                </div>
            </div>
        );
    };

    renderAddUserForm = companyDetails => {
        const { localization } = this.props;
        const companyName = _get(companyDetails, 'value', '');
        const { addUserData, registered, addUser, statusValue } = this.state;
        const radioValue = _get(statusValue, 'radioValue', '');
        return (
            <UserMaintenanceForm
                pageTitle={localization[USER_MAIN_L10N.ADD_NEW_USER]}
                localization={localization}
                companyName={companyName}
                buttonRegister
                handleCancel={this.handleCancel}
                onChangeHandler={this.onChangeHandler}
                handleRadioButton={this.handleRadioButton}
                selectedEditUserData={addUserData}
                type='addUser'
                registered={registered}
                addUser={addUser}
                automationId='at-add-new-user-screen'
                pageTitleId='at-add-new-user-title-screen'
                statusValue={radioValue}
                initalRadioButtonChange={this.initalRadioButtonChange}
            />
        );
    };

    renderEditUserForm = companyDetails => {
        const { selectedEditUserData, editUser, saveEnable, statusValue } = this.state;
        const companyName = _get(companyDetails, 'value', '');
        const { localization } = this.props;
        const radioValue = _get(statusValue, 'radioValue', '');
        return (
            <UserMaintenanceForm
                pageTitle={localization[USER_MAIN_L10N.EDIT_USER]}
                selectedEditUserData={selectedEditUserData}
                localization={localization}
                handleRadioButton={this.handleRadioButton}
                buttonSave
                disabledField
                handleCancel={this.handleCancel}
                onChangeHandler={this.onChangeHandler}
                type='editUser'
                editUser={editUser}
                saveEnable={saveEnable}
                companyName={companyName}
                automationId='at-edit-user-screen'
                pageTitleId='at-edit-user-title-screen'
                editSuccess={this.props.editSuccess}
                statusValue={radioValue}
                initalRadioButtonChange={this.initalRadioButtonChange}
            />
        );
    };

    initalRadioButtonChange = () => {
        const { selectedEditUserData } = this.state;
        this.setState(preveState => ({
            statusValue: {
                ...preveState.statusValue,
                radioValue: selectedEditUserData.role
            }
        }));
    };

    handleRadioButton = event => {
        const { value } = event.target;
        this.setState(
            preveState => ({
                statusValue: {
                    ...preveState.statusValue,
                    radioValue: value
                }
            }),
            this.handlerResister
        );
    };

    checkBtnValidate = (getUser, statusValue) => {
        const registered =
            _get(getUser, 'firstName', '').length > 0 &&
            _get(getUser, 'lastName', '').length > 0 &&
            _get(getUser, 'mobile', '').length > 0 &&
            _get(getUser, 'email', '').length > 0 &&
            _get(getUser, 'email', '').length > 0 &&
            _get(statusValue, 'radioValue', '').length > 0;
        return registered;
    };

    handlerResister = () => {
        const { addUserData, statusValue } = this.state;
        const registered = this.checkBtnValidate(addUserData, statusValue);
        this.setState({ registered: !registered });
    };

    onKeyHandler = event => {
        const { addUserData, selectedEditUserData } = this.state;
        const { name, value } = event.target;
        if (name === 'phone' || name === 'mobile') {
            if (phoneNumber.test(value)) {
                return value;
            }
        }
        return name === 'phone'
            ? addUserData.phone || selectedEditUserData.phone
            : addUserData.mobile || selectedEditUserData.mobile;
    };

    onChangeHandler = (event, type) => {
        const phoneNo = this.onKeyHandler(event);
        if (type === 'addUser') {
            const { name, value } = event.target;
            const validateNumber = name === 'phone' || name === 'mobile';
            this.setState(
                preveState => ({
                    addUserData: {
                        ...preveState.addUserData,
                        [name]: validateNumber ? phoneNo : value
                    }
                }),
                this.handlerResister
            );
        } else {
            const { name, value } = event.target;
            const validateNumber = name === 'phone' || name === 'mobile';
            this.setState(preveState => ({
                selectedEditUserData: {
                    ...preveState.selectedEditUserData,
                    [name]: validateNumber ? phoneNo : value
                }
            }));
        }
    };

    getContent() {
        const { embeded } = this.props;

        const { localization, companyDetails, message } = this.props;
        const getCompanyList = _find(companyDetails, item => item.label === 'COMPANY_NAME');
        const { editUser, addUser } = this.state;
        const { error = {} } = message;
        const isError = !_isEmpty(error);

        return (
            <div className={cx('userMainContainer')}>
                {!isError && !editUser && !addUser && (
                    <div>
                        <div className={cx('userHeader', embeded && 'align-right')}>
                            {!embeded && (
                                <PageHeader
                                    title={localization[USER_MAIN_L10N.TITLE]}
                                    themeTitle={cx('themeTitle')}
                                    automationId='at-user-maintenance-title-screen'
                                />
                            )}

                            <div className={cx('col5', 'md5', 'sm12', 'pad0', 'pad16', 'text-right', 'mobdr')}>
                                <Button
                                    automationId='at-add-new-user-button'
                                    className={cx('adduserbtn')}
                                    type='button'
                                    size='Sm'
                                    buttonType='Primary'
                                    onClick={this.handleAddNewUser}
                                >
                                    <span className={cx('addUserText')}>
                                        {localization[USER_MAIN_L10N.ADD_NEW_USER]}
                                    </span>
                                    <Icon
                                        className={cx('leftCalIcon')}
                                        iconType='svg'
                                        width='24px'
                                        height='24px'
                                        viewBox='0 0 24 24'
                                        name='add-user'
                                    />
                                </Button>
                            </div>
                        </div>
                        {this.renderDesktopLayout()} {/* Desktop and Tablet View Layout Changes */}
                        {this.renderMobileLayout()} {/* Mobile View Layout Changes */}
                    </div>
                )}
                <div className={cx('formContainer')}>
                    {addUser && this.renderAddUserForm(getCompanyList)}
                    {editUser && this.renderEditUserForm(getCompanyList)}
                </div>

                {isError && (
                    <p className={cx('errorMsg')} automation-id='at-error-invalid-data'>
                        {error.message} {error.errorCode}
                    </p>
                )}
            </div>
        );
    }

    fillPageMode() {
        const { breadcrumbs } = this.props;

        return (
            <Layout showDashboardHeader showOutlet>
                <div automation-id='at-user-maintenance-screen' className={cx('userMainContainer')}>
                    <BreadCrumbs breadcrumbValues={breadcrumbs} />
                    {this.getContent()}
                </div>
            </Layout>
        );
    }
    embedableMode() {
        return <>{this.getContent()}</>;
    }

    render() {
        const { embeded } = this.props;

        return <>{embeded ? this.embedableMode() : this.fillPageMode()}</>;
    }
}

const mapStateToProps = state => {
    return {
        localization: _get(state, 'pageContent.localization'),
        breadcrumbs: _get(state, 'pageContent.breadcrumbs'),
        deviceType: _get(state, 'context.deviceType'),
        userMaintenanceDetails: _get(state, 'userMaintenanceDetails.data') || {},
        message: _get(state, 'userMaintenanceDetails.message') || {},
        companyDetails: _get(state, 'commonData.data.companyData'),
        editSuccess: _get(state, 'userMaintenanceDetails.editSuccess') || false,
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getPageContent,
            getUserMaintenanceDetails,
            onDeleteFromUser,
            getAccountData,
            onResetEditSuccess,
            clearAllNotification
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMaintenance);
