import React from 'react';

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import _get from "lodash/get";
import StockNotificationModal from "../../core/StockNotificationModal/StockNotificationModal";
import {PAGE_ID} from "../../../common/Constants";
import {closeSubscriptionPopup, subscribeToProduct} from "../../../actions/NotifyMeAction";

export const NotificationSubscription = (props) => {

    const close = () => {
        props.actions.closeSubscriptionPopup()
    }

    const subscribe = (email) => {
        props.actions.subscribeToProduct({
            params: {
                email,
                code: props.productCode
            }
        })
    }

    if (props.showNotifyPopup) {
        return (
            <StockNotificationModal
                localization={props.commonLocalization}
                showNotifyPopup={props.showNotifyPopup}
                subscribeCustomerToTheProduct={subscribe}
                onCloseModal={close}/>
        );
    }
    return <></>
}

const mapStateToProps = state => {
    return {
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`),
        showNotifyPopup: _get(state, `notifyMeData.showPopup`, false),
        productCode: _get(state, `notifyMeData.productCode`, false),
    }
}
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            subscribeToProduct,
            closeSubscriptionPopup
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSubscription);
