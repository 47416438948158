export const de = {
  "de": {
    "calendar": {
      "CalendarDayLabels": [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mrz",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Okt",
        "Nov",
        "Dez"
      ],
      "CalendarLabelToday": "Heute",
      "CalendarLabelTomorrow": "Morgen",
      "CalendarTitle": "Rückruf planen",
      "CalendarOkButtonText": "OK",
      "CalendarError": "Verfügbarkeitsdetails können nicht abgerufen werden.",
      "CalendarClose": "Abbrechen",
      "AriaWindowTitle": "Fenster 'Kalender'",
      "AriaCalendarClose": "Kalender abbrechen und zur Rückrufregistrierung zurückkehren",
      "AriaYouHaveChosen": "Ihre Auswahl lautet",
      "AriaNoTimeSlotsFound": "Keine Zeitfenster für das ausgewählte Datum gefunden"
    },
    "callus": {
      "CallUsTitle": "Rufen Sie uns an",
      "ContactsHeader": "Sie erreichen uns unter den folgenden Telefonnummern...",
      "CancelButtonText": "Abbrechen",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Bereits in einem Telefongespräch? Lassen Sie uns <a role='link' tabindex='0' class='cx-cobrowse-link'>mit Ihnen browsen</a></span>",
      "CoBrowse": "Co-Browsing starten",
      "CoBrowseWarning": "Mithilfe von Co-Browsing kann Ihr Agent Ihren Desktop sehen und steuern, um Sie anzuleiten. Wenn Sie gerade mit einem Agenten telefonieren, fordern Sie einen Code zum Starten von Co-Browsing an, und geben Sie ihn unten ein. Noch in keinem Telefongespräch? Verlassen Sie einfach diesen Bildschirm, um zur Seite 'Rufen Sie uns an' zurückzukehren.",
      "SubTitle": "Sie erreichen uns unter den folgenden Telefonnummern...",
      "AriaWindowLabel": "Fenster 'Rufen Sie uns an'",
      "AriaCallUsClose": "Rufen Sie uns an – schließen",
      "AriaBusinessHours": "Geschäftszeiten",
      "AriaCallUsPhoneApp": "Öffnet die Telefonanwendung",
      "AriaCobrowseLink": "Öffnet die Co-Browsing-Sitzung",
      "AriaCancelButtonText": "Rufen Sie uns an – abbrechen"
    },
    "callback": {
      "CallbackTitle": "Anruf empfangen",
      "CancelButtonText": "Abbrechen",
      "AriaCancelButtonText": "Abbrechen",
      "ConfirmButtonText": "Bestätigen",
      "AriaConfirmButtonText": "Bestätigen",
      "CallbackFirstName": "Vorname",
      "CallbackPlaceholderRequired": "Erforderlich",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Nachname",
      "CallbackPhoneNumber": "Telefon",
      "CallbackQuestion": "Wann sollen wir Sie anrufen?",
      "CallbackDayLabels": [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mrz",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dez"
      ],
      "CallbackConfirmDescription": "Ihr Anruf ist reserviert!",
      "CallbackNumberDescription": "Wir rufen Sie unter der angegebenen Nummer an:",
      "CallbackNotes": "Notizen",
      "CallbackDone": "Schließen",
      "AriaCallbackDone": "Schließen",
      "CallbackOk": "OK",
      "AriaCallbackOk": "OK",
      "CallbackCloseConfirm": "Möchten Sie die Vereinbarung dieses Rückrufs stornieren?",
      "CallbackNoButtonText": "Nein",
      "AriaCallbackNoButtonText": "Nein",
      "CallbackYesButtonText": "Ja",
      "AriaCallbackYesButtonText": "Ja",
      "CallbackWaitTime": "Wartezeit",
      "CallbackWaitTimeText": "Min. Wartezeit",
      "CallbackOptionASAP": "So bald wie möglich",
      "CallbackOptionPickDateTime": "Datum/Uhrzeit auswählen",
      "AriaCallbackOptionPickDateTime": "Öffnet eine Datumsauswahl",
      "CallbackPlaceholderCalendar": "Datum/Uhrzeit auswählen",
      "AriaMinimize": "Rückruf – minimieren",
      "AriaWindowLabel": "Fenster 'Rückruf'",
      "AriaMaximize": "Rückruf – maximieren",
      "AriaClose": "Rückruf – schließen",
      "AriaCalendarClosedStatus": "Der Kalender ist geschlossen.",
      "Errors": {
        "501": "Ungültige Parameter werden nicht akzeptiert. Informationen zu gültigen Parametern finden Sie in der entsprechenden API-Server-Dokumentation.",
        "503": "API-Schlüssel fehlt. Stellen Sie sicher, dass er richtig konfiguriert ist.",
        "1103": "API-Schlüssel fehlt. Stellen Sie sicher, dass er richtig konfiguriert ist.",
        "7030": "Geben Sie eine gültige Telefonnummer ein.",
        "7036": "Mit dieser Nummer ist kein Rückruf möglich. Versuchen Sie es mit einer anderen Telefonnummer.",
        "7037": "Ein Rückruf zu dieser Nummer ist nicht zulässig. Versuchen Sie es mit einer anderen Telefonnummer.",
        "7040": "Konfigurieren Sie einen gültigen Dienstnamen.",
        "7041": "Zu viele Anforderungen zu diesem Zeitpunkt.",
        "7042": "Büro geschlossen. Vereinbaren Sie einen Termin während der Geschäftszeiten.",
        "unknownError": "Es ist ein Problem aufgetreten. Wir bitten die Unannehmlichkeiten zu entschuldigen. Überprüfen Sie die Verbindungseinstellungen, und versuchen Sie es erneut.",
        "phoneNumberRequired": "Die Telefonnummer ist erforderlich."
      }
    },
    "channelselector": {
      "Title": "Live-Unterstützung",
      "SubTitle": "Wie möchten Sie Verbindung aufnehmen?",
      "WaitTimeTitle": "Wartezeit",
      "AvailableTitle": "Verfügbar",
      "AriaAvailableTitle": "Verfügbar",
      "UnavailableTitle": "Nicht verfügbar",
      "CobrowseButtonText": "CobrowseSubTitle",
      "CallbackTitle": "Anruf empfangen",
      "CobrowseSubTitle": "Dies erfordert eine Agentenverbindung.",
      "AriaClose": "Live-Unterstützung – schließen",
      "AriaWarning": "Warnung",
      "AriaAlert": "Benachrichtigung",
      "minute": "Min",
      "minutes": "Mins",
      "AriaWindowLabel": "Fenster 'Live-Unterstützung\""
    },
    "clicktocall": {
      "Title": "Click-to-Call",
      "FirstName": "Vorname",
      "PlaceholderRequired": "Erforderlich",
      "PlaceholderOptional": "Optional",
      "LastName": "Nachname",
      "PhoneNumber": "Telefon",
      "WaitTime": "Wartezeit",
      "FormCancel": "Abbrechen",
      "AriaFormCancel": "Abbrechen",
      "FormSubmit": "Nummer anfordern",
      "AriaFormSubmit": "Nummer anfordern",
      "PhoneLabel": "Jetzt einwählen",
      "AriaPhoneTitle": "Öffnet die Telefonanwendung",
      "AccessLabel": "Zugangscode",
      "ExpireLabel": "Nummer läuft ab in",
      "AriaExpireLabel": "Die Nummer läuft ab in",
      "DisplayClose": "Schließen",
      "AriaDisplayClose": "Schließen",
      "NetworkFail": "Es ist ein Problem aufgetreten. Wir bitten die Unannehmlichkeiten zu entschuldigen. Überprüfen Sie die Verbindungseinstellungen, und versuchen Sie es erneut.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Die Telefonnummer ist abgelaufen!",
      "PhoneReRequest": "Neue Nummer anfordern",
      "AriaPhoneReRequest": "Neue Nummer anfordern",
      "LocalFormValidationEmptyPhoneNumber": "Geben Sie eine Telefonnummer ein.",
      "ConfirmCloseWindow": "Es sind nicht gesendete Formulardaten vorhanden. Möchten Sie wirklich beenden?",
      "AriaConfirmCloseCancel": "Nein",
      "ConfirmCloseCancel": "Nein",
      "AriaConfirmCloseConfirm": "Ja",
      "ConfirmCloseConfirm": "Ja",
      "AriaWindowLabel": "Fenster 'Click-to-Call'",
      "AriaMaximize": "Click-to-Call – maximieren",
      "AriaMinimize": "Click-to-Call – minimieren",
      "AriaClose": "Click-to-Call – schließen"
    },
    "cobrowse": {
      "agentJoined": "Der Agent ist der Sitzung beigetreten.",
      "youLeft": "Sie haben die Sitzung verlassen. Co-Browsing wurde beendet.",
      "sessionTimedOut": "Zeitüberschreitung der Sitzung. Co-Browsing wurde beendet.",
      "sessionInactiveTimedOut": "Zeitüberschreitung der Sitzung. Co-Browsing wurde beendet.",
      "agentLeft": "Der Agent hat die Sitzung verlassen. Co-Browsing wurde beendet.",
      "sessionError": "Ein unerwarteter Fehler ist aufgetreten. Co-Browsing wurde beendet.",
      "sessionStarted": "Co-Browsing wurde gestartet. Warten auf Verbinden des Agenten mit der Sitzung...",
      "navRefresh": "Der Agent hat die Seite aktualisiert. Sie wird neu geladen.",
      "navBack": "Der Agent hat auf die Schaltfläche \"Zurück\" geklickt. Die Seite wird neu geladen.",
      "navForward": "Der Agent hat auf die Schaltfläche \"Weiter\" geklickt. Die Seite wird neu geladen.",
      "navUrl": "Der Agent hat Navigation angefordert. Die Seite wird neu geladen.",
      "navFailed": "Die Navigationsanforderung des Agenten ist fehlgeschlagen.",
      "toolbarContent": "Sitzungs-ID: {sessionId}",
      "contentMasked": "Der Inhalt ist für den Agenten nicht sichtbar.",
      "contentMaskedPartially": "Einige Inhalte sind für den Agenten nicht sichtbar.",
      "exitBtnTitle": "Co-Browsing-Sitzung beenden",
      "areYouOnPhone": "Telefonieren Sie gerade mit unserem Agenten?",
      "areYouOnPhoneOrChat": "Telefonieren oder chatten Sie gerade mit unserem Agenten?",
      "connectBeforeCobrowse": "Um mit Co-Browsing fortzufahren, müssen Sie mit unserem Agenten verbunden sein. Rufen Sie uns an, oder beginnen Sie einen Live-Chat mit uns, und starten Sie dann Co-Browsing erneut.",
      "sessionStartedAutoConnect": "Co-Browsing wurde gestartet. Warten auf Verbinden des Agenten mit der Sitzung...",
      "browserUnsupported": "Ihr Browser wird derzeit nicht unterstützt.<br><br> Unterstützte Browser: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 und höher</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 und höher</a></li></ul>",
      "chatIsAlreadyRunning": "Der Chat wird bereits auf einer anderen Seite ausgeführt.",
      "modalYes": "Ja",
      "modalNo": "Nein"
    },
    "knowledgecenter": {
      "SidebarButton": "Suchen",
      "SearchButton": "Suchen",
      "Title": "Stellen Sie eine Frage",
      "Ask": "Fragen",
      "AriaAsk": "Fragen",
      "Close": "Schließen",
      "AriaClose": "Suchen – schließen",
      "Categories": "Kategorien",
      "NoResults": "Keine Ergebnisse",
      "NoResultsTextUnder": "Leider konnte keine geeignete Antwort für Sie gefunden werden.",
      "NoResultsTextRephrase": "Können Sie bitte die Frage neu formulieren?",
      "WasThisHelpful": "War dies hilfreich?",
      "Yes": "Ja",
      "No": "Nein",
      "AriaYes": "Ja",
      "AriaNo": "Nein",
      "ArticleHelpfulnessYes": "Nützlichkeit des Artikels – 'Ja'",
      "ArticleHelpfulnessYesDesc": "Schön! Wir freuen uns zu erfahren, dass der Artikel für Ihre Suche hilfreich war. Wir wünschen Ihnen einen schönen Tag!",
      "ArticleHelpfulnessNo": "Nützlichkeit des Artikels – 'Nein'",
      "ArticleHelpfulnessNoDesc": "Es tut uns Leid, dass der Artikel für Ihre Suche nicht hilfreich war. Wir danken Ihnen für Ihr Feedback!",
      "TypeYourQuestion": "Geben Sie Ihre Frage ein.",
      "Back": "Zurück",
      "AriaBack": "Zurück zu den Suchergebnissen",
      "More": "Mehr",
      "Error": "Fehler!",
      "GKCIsUnavailable": "Der Wissenscenter-Server ist derzeit nicht verfügbar.",
      "AriaClear": "Suchtext löschen",
      "AriaSearch": "Suchen",
      "AriaWindowLabel": "Fenster 'Suchen'",
      "AriaSearchDropdown": "Vorgeschlagene Ergebnisse",
      "AriaSearchMore": "Weitere Informationen",
      "AriaResultsCount": "Gesamtzahl der Ergebnisse",
      "KnowledgeAgentName": "Wissenscenter",
      "WelcomeMessage": "Hallo und Willkommen! In Kürze steht Ihnen ein Live-Agent zur Verfügung. Kann ich Ihnen in der Zwischenzeit Fragen beantworten? Geben Sie unten im Eingabefeld eine Frage ein.",
      "SearchResult": "Es wurde noch kein Agent verbunden. Dies sind die relevantesten Antworten auf Ihre Frage:",
      "NoDocumentsFound": "Leider sind keine Artikel vorhanden, die Ihre Frage beantworten. Möchten Sie eine weitere Frage stellen?",
      "SuggestedMessage": "Das folgende Element aus dem Wissenscenter wurde vorgeschlagen:",
      "OpenDocumentHint": "Klicken Sie darauf, um seinen Inhalt anzuzeigen.",
      "SuggestedDocumentMessage": "Das Dokument '<%DocTitle%>' wurde vorgeschlagen.",
      "FeedbackQuestion": "War dies hilfreich?",
      "FeedbackAccept": "Ja",
      "FeedbackDecline": "Nein",
      "TranscriptMarker": "Wissenscenter: ",
      "SearchMessage": "Mit Frage '<%SearchQuery%>' suchen↲",
      "VisitMessage": "Für Dokument '<%VisitQuery%>' besuchen",
      "OpenMessage": "'<%OpenQuery%>' angezeigt",
      "AnsweredMessage": "Die Ergebnisse für die Frage '<%AnsweredQuery%>' wurden als relevant markiert.",
      "UnansweredMessage": "Die Ergebnisse für die Frage '<%UnansweredQuery%>' wurden als unbeantwortet markiert.",
      "PositiveVoteMessage": "Positive Abstimmung für Dokument '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative Abstimmung für Dokument '<%VoteQuery%>'.",
      "SuggestedMarker": "Das Dokument[^\\0]*?wurde vorgeschlagen."
    },
    "sendmessage": {
      "SendMessageButton": "Nachricht senden",
      "OK": "OK",
      "Title": "Nachricht senden",
      "PlaceholderFirstName": "Erforderlich",
      "PlaceholderLastName": "Erforderlich",
      "PlaceholderEmail": "Pflichtfeld",
      "PlaceholderSubject": "Erforderlich",
      "PlaceholderTypetexthere": "Geben Sie hier Ihre Nachricht ein...",
      "FirstName": "Vorname",
      "LastName": "Nachname",
      "Email": "E-Mail",
      "Subject": "Betreff",
      "Attachfiles": "Dateien anfügen",
      "AriaAttachfiles": "Link 'Dateien anfügen'; öffnet ein Dialogfeld für den Datei-Upload.",
      "Send": "Senden",
      "AriaSend": "Nachricht senden",
      "Sent": "Ihre Nachricht wurde gesendet...",
      "Close": "Schließen",
      "ConfirmCloseWindow": "Möchten Sie das Widget 'Nachricht senden' wirklich schließen?",
      "Cancel": "Abbrechen",
      "AriaMinimize": "Nachricht senden – minimieren",
      "AriaMaximize": "Nachricht senden – maximieren",
      "AriaWindowLabel": "Fenster 'Nachricht senden'",
      "AriaClose": "Nachricht senden – schließen",
      "AriaCloseAlert": "Benachrichtigungsfeld ist geschlossen",
      "AriaEndConfirm": "Ja",
      "AriaEndCancel": "Abbrechen",
      "AriaOK": "OK",
      "AriaRemoveFile": "Datei entfernen",
      "AriaFileIcon": "Datei",
      "AriaFileSize": "Dateigröße",
      "Errors": {
        "102": "Vorname ist erforderlich.",
        "103": "Nachname ist erforderlich.",
        "104": "Betreff ist erforderlich.",
        "181": "E-Mail-Adresse ist erforderlich.",
        "182": "Nachrichtentextinhalt ist erforderlich.",
        "connectionError": "Der Server ist nicht erreichbar. Versuchen Sie es erneut.",
        "unknowError": "Es ist ein Problem aufgetreten. Wir bitten die Unannehmlichkeiten zu entschuldigen. Überprüfen Sie die Verbindungseinstellungen, und versuchen Sie es erneut.",
        "attachmentsLimit": "Die maximal zulässige Anzahl von Anlagen wird überschritten: ",
        "attachmentsSize": "Die maximal zulässige Gesamtgröße von Anlagen wird überschritten: ",
        "invalidFileType": "Nicht unterstützter Dateityp. Laden Sie Bilder, PDF-Dateien, Textdateien und ZIP-Dateien hoch.",
        "invalidFromEmail": "Ungültige E-Mail-Absenderadresse.",
        "invalidMailbox": "Ungültige E-Mail-Empfängeradresse."
      }
    },
    "sidebar": {
      "SidebarTitle": "Brauchen Sie Hilfe?",
      "ChannelSelectorName": "Live-Unterstützung",
      "ChannelSelectorTitle": "Erhalten Sie direkte Unterstützung von einem unserer Agenten",
      "SearchName": "Suchen",
      "SearchTitle": "Suchen",
      "OffersName": "Angebote",
      "OffersTitle": "Angebote",
      "CallUsName": "Rufen Sie uns an",
      "CallUsTitle": "Rufen Sie uns an – Details",
      "CallbackName": "Rückruf",
      "CallbackTitle": "Anruf empfangen",
      "ClickToCallName": "Click-to-Call",
      "ClickToCallTitle": "Fordern Sie eine Kundenbetreuungs-Telefonnummer an",
      "SendMessageName": "Nachricht senden",
      "SendMessageTitle": "Nachricht senden",
      "WebChatName": "Live-Chat",
      "WebChatTitle": "Live-Chat",
      "AriaClose": "Menü 'Brauchen Sie Hilfe?' – schließen"
    },
    "webchat": {
      "ChatButton": "Chat",
      "ChatStarted": "Chat wurde gestartet",
      "ChatEnded": "Chat wurde beendet",
      "AgentNameDefault": "Agent",
      "AgentConnected": "<%Agent%> verbunden",
      "AgentDisconnected": "<%Agent%> getrennt",
      "BotNameDefault": "Bot",
      "BotConnected": "<%Bot%> verbunden",
      "BotDisconnected": "<%Bot%> getrennt",
      "SupervisorNameDefault": "Supervisor",
      "SupervisorConnected": "<%Agent%> verbunden",
      "SupervisorDisconnected": "<%Agent%> getrennt",
      "AgentTyping": "...",
      "AriaAgentTyping": "Agent schreibt",
      "AgentUnavailable": "Leider sind keine Agenten verfügbar. Bitte versuchen Sie es später.",
      "ChatTitle": "Live-Chat",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Min",
      "ChatFormName": "Name",
	    "ChatFormPlaceholderName": "Optional",
      "ChatFormCustomerId": "Kundennummer",
      "ChatFormPlaceholderCustomerId": "Optional",
	    "ChatFormSubjectPassword": "Passwort",
      "ChatFormSubjectService": "Dienst",
      "ChatFormSubjectOrder": "Bestellung",
      "ChatFormSubjectFinance": "Finanzen",
      "ChatFormFirstName": "Vorname",
      "ChatFormLastName": "Nachname",
      "ChatFormNickname": "Spitzname",
      "ChatFormEmail": "E-Mail",
      "ChatFormSubject": "Betreff",
      "ChatFormPlaceholderFirstName": "Erforderlich",
      "ChatFormPlaceholderLastName": "Erforderlich",
      "ChatFormPlaceholderNickname": "Optional",
      "ChatFormPlaceholderEmail": "Erforderlich",
      "ChatFormPlaceholderSubject": "Optional",
      "ChatFormSubmit": "Chat starten",
      "AriaChatFormSubmit": "Chat starten",
      "ChatFormCancel": "Abbrechen",
      "AriaChatFormCancel": "Chat abbrechen",
      "ChatFormClose": "Schließen",
      "ChatInputPlaceholder": "Geben Sie hier Ihre Nachricht ein.",
      "ChatInputSend": "Senden",
      "AriaChatInputSend": "Senden",
      "ChatEndQuestion": "Möchten Sie diese Chat-Sitzung wirklich beenden?",
      "ChatEndCancel": "Abbrechen",
      "ChatEndConfirm": "Chat beenden",
      "AriaChatEndCancel": "Abbrechen",
      "AriaChatEndConfirm": "Beenden",
      "ConfirmCloseWindow": "Möchten Sie diesen Chat wirklich schließen?",
      "ConfirmCloseCancel": "Abbrechen",
      "ConfirmCloseConfirm": "Schließen",
      "AriaConfirmCloseCancel": "Abbrechen",
      "AriaConfirmCloseConfirm": "Schließen",
      "ActionsDownload": "Aufzeichnung herunterladen",
      "ActionsEmail": "E-Mail-Aufzeichnung",
      "ActionsPrint": "Aufzeichnung drucken",
      "ActionsCobrowseStart": "Co-Browsing starten",
      "AriaActionsCobrowseStartTitle": "Öffnet die Co-Browsing-Sitzung",
      "ActionsSendFile": "Dateien anfügen",
      "AriaActionsSendFileTitle": "Link 'Dateien anfügen'; öffnet ein Dialogfeld für den Datei-Upload",
      "ActionsEmoji": "Emoji senden",
      "ActionsCobrowseStop": "Co-Browsing beenden",
      "ActionsVideo": "Zu Video-Chat einladen",
      "ActionsTransfer": "Übertragen",
      "ActionsInvite": "Einladen",
      "InstructionsTransfer": "Öffnen Sie diesen Link auf einem anderen Gerät, um Ihre Chat-Sitzung zu übertragen.</br></br><%link%>",
      "InstructionsInvite": "Teilen Sie diesen Link mit einer anderen Person, um sie dieser Chat-Sitzung hinzuzufügen.</br></br><%link%>",
      "InviteTitle": "Brauchen Sie Hilfe?",
      "InviteBody": "Teilen Sie uns mit, ob wir helfen können.",
      "InviteReject": "Nein danke",
      "InviteAccept": "Chat starten",
      "AriaInviteAccept": "Einladung annehmen und Chat starten",
      "AriaInviteReject": "Einladung ablehnen",
      "ChatError": "Beim Starten der Chat-Sitzung ist ein Problem aufgetreten. Versuchen Sie es erneut.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Ja",
      "ChatErrorDefaultButton": "Nein",
      "AriaChatErrorPrimaryButton": "Ja",
      "AriaChatErrorDefaultButton": "Nein",
      "DownloadButton": "Herunterladen",
      "AriaDownloadButton": "Datei herunterladen",
      "FileSent": "hat gesendet:",
      "FileTransferRetry": "Erneut versuchen",
      "AriaFileTransferRetry": "Dateiübertragung erneut versuchen",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Abbrechen",
      "AriaFileTransferCancel": "Dateiübertragung abbrechen",
      "RestoreTimeoutTitle": "Chat wurde beendet",
      "RestoreTimeoutBody": "Die Zeit für Ihre vorherige Chat-Sitzung wurde überschritten. Möchten Sie eine neue Chat-Sitzung starten?",
      "RestoreTimeoutReject": "Nein danke",
      "RestoreTimeoutAccept": "Chat starten",
      "AriaRestoreTimeoutAccept": "Einladung annehmen und Chat starten",
      "AriaRestoreTimeoutReject": "Einladung ablehnen",
      "EndConfirmBody": "Möchten Sie die Chat-Sitzung wirklich beenden?",
      "EndConfirmAccept": "Chat beenden",
      "EndConfirmReject": "Abbrechen",
      "AriaEndConfirmAccept": "Chat beenden",
      "AriaEndConfirmReject": "Abbrechen",
      "SurveyOfferQuestion": "Möchten Sie an einer Umfrage teilnehmen?",
      "ShowSurveyAccept": "Ja",
      "ShowSurveyReject": "Nein",
      "AriaShowSurveyAccept": "Ja",
      "AriaShowSurveyReject": "Nein",
      "UnreadMessagesTitle": "ungelesen",
      "AriaYouSaid": "Sie sagten",
      "AriaSaid": "sagte",
      "AriaSystemSaid": "Das System sagte",
      "AriaWindowLabel": "Fenster 'Live-Chat'",
      "AriaMinimize": "Live-Chat – minimieren",
      "AriaMaximize": "Live-Chat – maximieren",
      "AriaClose": "Live-Chat – schließen",
      "AriaEmojiStatusOpen": "Das Dialogfeld 'Emoji-Auswahl' ist geöffnet.",
      "AriaEmojiStatusClose": "Das Dialogfeld 'Emoji-Auswahl' ist geschlossen.",
      "AriaEmoji": "Emoji",
      "AriaEmojiPicker": "Emoji-Auswahl",
      "AriaCharRemaining": "Verbleibende Zeichen",
      "AriaMessageInput": "Nachrichtenfeld",
      "AsyncChatEnd": "Chat beenden",
      "AsyncChatClose": "Fenster schließen",
      "AriaAsyncChatEnd": "Chat beenden",
      "AriaAsyncChatClose": "Fenster schließen",
      "DayLabels": [
        "So",
        "Mo",
        "Di",
        "Mi",
        "Do",
        "Fr",
        "Sa"
      ],
      "MonthLabels": [
        "Jan",
        "Feb",
        "Mrz",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Okt",
        "Nov",
        "Dez"
      ],
      "todayLabel": "Heute",
      "Errors": {
        "102": "Vorname ist erforderlich.",
        "103": "Nachname ist erforderlich.",
        "161": "Geben Sie Ihren Namen ein.",
        "201": "Die Datei konnte nicht gesendet werden.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Die maximale Anzahl angefügter Dateien wird überschritten (<%MaxFilesAllowed%>).</p>",
        "202": "Die Datei konnte nicht gesendet werden.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Das Upload-Limit und/oder die maximale Anzahl von Anlagen wird überschritten (<%MaxAttachmentsSize%>).</p>",
        "203": "Die Datei konnte nicht gesendet werden.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Der Dateityp ist nicht zulässig.</p>",
        "204": "Ihre Nachricht ist zu lang. Schreiben Sie eine kürzere Nachricht.",
        "240": "Leider kann derzeit kein neuer Chat gestartet werden. Versuchen Sie es später erneut.",
        "364": "Ungültige E-Mail-Adresse",
        "401": "Die Chat-Sitzung kann leider nicht autorisiert werden. Möchten Sie einen neuen Chat starten?",
        "404": "Ihre vorherige Chat-Sitzung konnte nicht gefunden werden. Möchten Sie einen neuen Chat starten?",
        "500": "Bei dem Dienst ist ein unerwarteter Fehler aufgetreten. Möchten Sie ihn schließen und einen neuen Chat starten?",
        "503": "Der Dienst ist leider derzeit ausgelastet oder nicht erreichbar. Möchten Sie ihn schließen und einen neuen Chat starten?",
        "ChatUnavailable": "Leider kann derzeit kein neuer Chat gestartet werden. Versuchen Sie es später erneut.",
        "CriticalFault": "Ihre Chat-Sitzung wurde aufgrund eines unbekannten Problems unerwartet beendet. Wir entschuldigen uns für die Unannehmlichkeit.",
        "StartFailed": "Beim Starten Ihrer Chat-Sitzung ist ein Problem aufgetreten. Überprüfen Sie die Verbindung, überprüfen Sie, ob Sie alle erforderlichen Informationen ordnungsgemäß übermittelt haben, und versuchen Sie es dann erneut.",
        "MessageFailed": "Ihr Nachricht wurde nicht empfangen. Versuchen Sie es erneut.",
        "RestoreFailed": "Ihre Chat-Sitzung konnte aufgrund eines unbekannten Fehlers nicht wiederhergestellt werden.",
        "TransferFailed": "Chat kann derzeit nicht übertragen werden. Versuchen Sie es später erneut.",
        "FileTransferSizeError": "Die Datei konnte nicht gesendet werden.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Die Datei überschreitet die zulässige Größe (<%MaxSizePerFile%>).</p>",
        "InviteFailed": "Derzeit kann keine Einladung generiert werden. Versuchen Sie es später erneut.",
        "ChatServerWentOffline": "Das Übertragen von Nachrichten dauert derzeit länger als üblich. Wir entschuldigen uns für die Verzögerung.",
        "RestoredOffline": "Das Übertragen von Nachrichten dauert derzeit länger als üblich. Wir entschuldigen uns für die Verzögerung.",
        "Disconnected": "<div style='text-align:center'>Verbindung unterbrochen</div>",
        "Reconnected": "<div style='text-align:center'>Verbindung wiederhergestellt</div>",
        "FileSendFailed": "Die Datei konnte nicht gesendet werden.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Die Verbindung wurde unerwartet unterbrochen. Erneut versuchen?</p>",
        "Generic": "<div style='text-align:center'>Ein unerwarteter Fehler ist aufgetreten.</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Ungültiger Dateityp. Es sind nur Bilder zulässig.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "Die Datei überschreitet die zulässige Größe.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "Die maximal zulässige Anzahl von Anlagen wird überschritten.",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Ungültige x-api-key-Transportkonfiguration",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Ungültige Endpunkt-Transportkonfiguration",
        "pureengage-v3-rest-INVALID_NICKNAME": "Vorname ist erforderlich.",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "Die Chat-Sitzung kann leider nicht autorisiert werden.",
        "purecloud-v2-sockets-400": "Ein Problem ist aufgetreten. Überprüfen Sie die Verbindung, überprüfen Sie, ob Sie alle erforderlichen Informationen ordnungsgemäß übermittelt haben, und versuchen Sie es dann erneut.",
        "purecloud-v2-sockets-500": "Bei dem Dienst ist ein unerwarteter Fehler aufgetreten.",
        "purecloud-v2-sockets-503": "Der Dienst ist leider derzeit nicht erreichbar."
      }
    }
  }
}

export const de_AT = {
  "de_AT": {
    "calendar": {
      "CalendarDayLabels": [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mrz",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Okt",
        "Nov",
        "Dez"
      ],
      "CalendarLabelToday": "Heute",
      "CalendarLabelTomorrow": "Morgen",
      "CalendarTitle": "Rückruf planen",
      "CalendarOkButtonText": "OK",
      "CalendarError": "Verfügbarkeitsdetails können nicht abgerufen werden.",
      "CalendarClose": "Abbrechen",
      "AriaWindowTitle": "Fenster 'Kalender'",
      "AriaCalendarClose": "Kalender abbrechen und zur Rückrufregistrierung zurückkehren",
      "AriaYouHaveChosen": "Ihre Auswahl lautet",
      "AriaNoTimeSlotsFound": "Keine Zeitfenster für das ausgewählte Datum gefunden"
    },
    "callus": {
      "CallUsTitle": "Rufen Sie uns an",
      "ContactsHeader": "Sie erreichen uns unter den folgenden Telefonnummern...",
      "CancelButtonText": "Abbrechen",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Bereits in einem Telefongespräch? Lassen Sie uns <a role='link' tabindex='0' class='cx-cobrowse-link'>mit Ihnen browsen</a></span>",
      "CoBrowse": "Co-Browsing starten",
      "CoBrowseWarning": "Mithilfe von Co-Browsing kann Ihr Agent Ihren Desktop sehen und steuern, um Sie anzuleiten. Wenn Sie gerade mit einem Agenten telefonieren, fordern Sie einen Code zum Starten von Co-Browsing an, und geben Sie ihn unten ein. Noch in keinem Telefongespräch? Verlassen Sie einfach diesen Bildschirm, um zur Seite 'Rufen Sie uns an' zurückzukehren.",
      "SubTitle": "Sie erreichen uns unter den folgenden Telefonnummern...",
      "AriaWindowLabel": "Fenster 'Rufen Sie uns an'",
      "AriaCallUsClose": "Rufen Sie uns an – schließen",
      "AriaBusinessHours": "Geschäftszeiten",
      "AriaCallUsPhoneApp": "Öffnet die Telefonanwendung",
      "AriaCobrowseLink": "Öffnet die Co-Browsing-Sitzung",
      "AriaCancelButtonText": "Rufen Sie uns an – abbrechen"
    },
    "callback": {
      "CallbackTitle": "Anruf empfangen",
      "CancelButtonText": "Abbrechen",
      "AriaCancelButtonText": "Abbrechen",
      "ConfirmButtonText": "Bestätigen",
      "AriaConfirmButtonText": "Bestätigen",
      "CallbackFirstName": "Vorname",
      "CallbackPlaceholderRequired": "Erforderlich",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Nachname",
      "CallbackPhoneNumber": "Telefon",
      "CallbackQuestion": "Wann sollen wir Sie anrufen?",
      "CallbackDayLabels": [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mrz",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dez"
      ],
      "CallbackConfirmDescription": "Ihr Anruf ist reserviert!",
      "CallbackNumberDescription": "Wir rufen Sie unter der angegebenen Nummer an:",
      "CallbackNotes": "Notizen",
      "CallbackDone": "Schließen",
      "AriaCallbackDone": "Schließen",
      "CallbackOk": "OK",
      "AriaCallbackOk": "OK",
      "CallbackCloseConfirm": "Möchten Sie die Vereinbarung dieses Rückrufs stornieren?",
      "CallbackNoButtonText": "Nein",
      "AriaCallbackNoButtonText": "Nein",
      "CallbackYesButtonText": "Ja",
      "AriaCallbackYesButtonText": "Ja",
      "CallbackWaitTime": "Wartezeit",
      "CallbackWaitTimeText": "Min. Wartezeit",
      "CallbackOptionASAP": "So bald wie möglich",
      "CallbackOptionPickDateTime": "Datum/Uhrzeit auswählen",
      "AriaCallbackOptionPickDateTime": "Öffnet eine Datumsauswahl",
      "CallbackPlaceholderCalendar": "Datum/Uhrzeit auswählen",
      "AriaMinimize": "Rückruf – minimieren",
      "AriaWindowLabel": "Fenster 'Rückruf'",
      "AriaMaximize": "Rückruf – maximieren",
      "AriaClose": "Rückruf – schließen",
      "AriaCalendarClosedStatus": "Der Kalender ist geschlossen.",
      "Errors": {
        "501": "Ungültige Parameter werden nicht akzeptiert. Informationen zu gültigen Parametern finden Sie in der entsprechenden API-Server-Dokumentation.",
        "503": "API-Schlüssel fehlt. Stellen Sie sicher, dass er richtig konfiguriert ist.",
        "1103": "API-Schlüssel fehlt. Stellen Sie sicher, dass er richtig konfiguriert ist.",
        "7030": "Geben Sie eine gültige Telefonnummer ein.",
        "7036": "Mit dieser Nummer ist kein Rückruf möglich. Versuchen Sie es mit einer anderen Telefonnummer.",
        "7037": "Ein Rückruf zu dieser Nummer ist nicht zulässig. Versuchen Sie es mit einer anderen Telefonnummer.",
        "7040": "Konfigurieren Sie einen gültigen Dienstnamen.",
        "7041": "Zu viele Anforderungen zu diesem Zeitpunkt.",
        "7042": "Büro geschlossen. Vereinbaren Sie einen Termin während der Geschäftszeiten.",
        "unknownError": "Es ist ein Problem aufgetreten. Wir bitten die Unannehmlichkeiten zu entschuldigen. Überprüfen Sie die Verbindungseinstellungen, und versuchen Sie es erneut.",
        "phoneNumberRequired": "Die Telefonnummer ist erforderlich."
      }
    },
    "channelselector": {
      "Title": "Live-Unterstützung",
      "SubTitle": "Wie möchten Sie Verbindung aufnehmen?",
      "WaitTimeTitle": "Wartezeit",
      "AvailableTitle": "Verfügbar",
      "AriaAvailableTitle": "Verfügbar",
      "UnavailableTitle": "Nicht verfügbar",
      "CobrowseButtonText": "CobrowseSubTitle",
      "CallbackTitle": "Anruf empfangen",
      "CobrowseSubTitle": "Dies erfordert eine Agentenverbindung.",
      "AriaClose": "Live-Unterstützung – schließen",
      "AriaWarning": "Warnung",
      "AriaAlert": "Benachrichtigung",
      "minute": "Min",
      "minutes": "Mins",
      "AriaWindowLabel": "Fenster 'Live-Unterstützung\""
    },
    "clicktocall": {
      "Title": "Click-to-Call",
      "FirstName": "Vorname",
      "PlaceholderRequired": "Erforderlich",
      "PlaceholderOptional": "Optional",
      "LastName": "Nachname",
      "PhoneNumber": "Telefon",
      "WaitTime": "Wartezeit",
      "FormCancel": "Abbrechen",
      "AriaFormCancel": "Abbrechen",
      "FormSubmit": "Nummer anfordern",
      "AriaFormSubmit": "Nummer anfordern",
      "PhoneLabel": "Jetzt einwählen",
      "AriaPhoneTitle": "Öffnet die Telefonanwendung",
      "AccessLabel": "Zugangscode",
      "ExpireLabel": "Nummer läuft ab in",
      "AriaExpireLabel": "Die Nummer läuft ab in",
      "DisplayClose": "Schließen",
      "AriaDisplayClose": "Schließen",
      "NetworkFail": "Es ist ein Problem aufgetreten. Wir bitten die Unannehmlichkeiten zu entschuldigen. Überprüfen Sie die Verbindungseinstellungen, und versuchen Sie es erneut.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Die Telefonnummer ist abgelaufen!",
      "PhoneReRequest": "Neue Nummer anfordern",
      "AriaPhoneReRequest": "Neue Nummer anfordern",
      "LocalFormValidationEmptyPhoneNumber": "Geben Sie eine Telefonnummer ein.",
      "ConfirmCloseWindow": "Es sind nicht gesendete Formulardaten vorhanden. Möchten Sie wirklich beenden?",
      "AriaConfirmCloseCancel": "Nein",
      "ConfirmCloseCancel": "Nein",
      "AriaConfirmCloseConfirm": "Ja",
      "ConfirmCloseConfirm": "Ja",
      "AriaWindowLabel": "Fenster 'Click-to-Call'",
      "AriaMaximize": "Click-to-Call – maximieren",
      "AriaMinimize": "Click-to-Call – minimieren",
      "AriaClose": "Click-to-Call – schließen"
    },
    "cobrowse": {
      "agentJoined": "Der Agent ist der Sitzung beigetreten.",
      "youLeft": "Sie haben die Sitzung verlassen. Co-Browsing wurde beendet.",
      "sessionTimedOut": "Zeitüberschreitung der Sitzung. Co-Browsing wurde beendet.",
      "sessionInactiveTimedOut": "Zeitüberschreitung der Sitzung. Co-Browsing wurde beendet.",
      "agentLeft": "Der Agent hat die Sitzung verlassen. Co-Browsing wurde beendet.",
      "sessionError": "Ein unerwarteter Fehler ist aufgetreten. Co-Browsing wurde beendet.",
      "sessionStarted": "Co-Browsing wurde gestartet. Warten auf Verbinden des Agenten mit der Sitzung...",
      "navRefresh": "Der Agent hat die Seite aktualisiert. Sie wird neu geladen.",
      "navBack": "Der Agent hat auf die Schaltfläche \"Zurück\" geklickt. Die Seite wird neu geladen.",
      "navForward": "Der Agent hat auf die Schaltfläche \"Weiter\" geklickt. Die Seite wird neu geladen.",
      "navUrl": "Der Agent hat Navigation angefordert. Die Seite wird neu geladen.",
      "navFailed": "Die Navigationsanforderung des Agenten ist fehlgeschlagen.",
      "toolbarContent": "Sitzungs-ID: {sessionId}",
      "contentMasked": "Der Inhalt ist für den Agenten nicht sichtbar.",
      "contentMaskedPartially": "Einige Inhalte sind für den Agenten nicht sichtbar.",
      "exitBtnTitle": "Co-Browsing-Sitzung beenden",
      "areYouOnPhone": "Telefonieren Sie gerade mit unserem Agenten?",
      "areYouOnPhoneOrChat": "Telefonieren oder chatten Sie gerade mit unserem Agenten?",
      "connectBeforeCobrowse": "Um mit Co-Browsing fortzufahren, müssen Sie mit unserem Agenten verbunden sein. Rufen Sie uns an, oder beginnen Sie einen Live-Chat mit uns, und starten Sie dann Co-Browsing erneut.",
      "sessionStartedAutoConnect": "Co-Browsing wurde gestartet. Warten auf Verbinden des Agenten mit der Sitzung...",
      "browserUnsupported": "Ihr Browser wird derzeit nicht unterstützt.<br><br> Unterstützte Browser: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 und höher</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 und höher</a></li></ul>",
      "chatIsAlreadyRunning": "Der Chat wird bereits auf einer anderen Seite ausgeführt.",
      "modalYes": "Ja",
      "modalNo": "Nein"
    },
    "knowledgecenter": {
      "SidebarButton": "Suchen",
      "SearchButton": "Suchen",
      "Title": "Stellen Sie eine Frage",
      "Ask": "Fragen",
      "AriaAsk": "Fragen",
      "Close": "Schließen",
      "AriaClose": "Suchen – schließen",
      "Categories": "Kategorien",
      "NoResults": "Keine Ergebnisse",
      "NoResultsTextUnder": "Leider konnte keine geeignete Antwort für Sie gefunden werden.",
      "NoResultsTextRephrase": "Können Sie bitte die Frage neu formulieren?",
      "WasThisHelpful": "War dies hilfreich?",
      "Yes": "Ja",
      "No": "Nein",
      "AriaYes": "Ja",
      "AriaNo": "Nein",
      "ArticleHelpfulnessYes": "Nützlichkeit des Artikels – 'Ja'",
      "ArticleHelpfulnessYesDesc": "Schön! Wir freuen uns zu erfahren, dass der Artikel für Ihre Suche hilfreich war. Wir wünschen Ihnen einen schönen Tag!",
      "ArticleHelpfulnessNo": "Nützlichkeit des Artikels – 'Nein'",
      "ArticleHelpfulnessNoDesc": "Es tut uns Leid, dass der Artikel für Ihre Suche nicht hilfreich war. Wir danken Ihnen für Ihr Feedback!",
      "TypeYourQuestion": "Geben Sie Ihre Frage ein.",
      "Back": "Zurück",
      "AriaBack": "Zurück zu den Suchergebnissen",
      "More": "Mehr",
      "Error": "Fehler!",
      "GKCIsUnavailable": "Der Wissenscenter-Server ist derzeit nicht verfügbar.",
      "AriaClear": "Suchtext löschen",
      "AriaSearch": "Suchen",
      "AriaWindowLabel": "Fenster 'Suchen'",
      "AriaSearchDropdown": "Vorgeschlagene Ergebnisse",
      "AriaSearchMore": "Weitere Informationen",
      "AriaResultsCount": "Gesamtzahl der Ergebnisse",
      "KnowledgeAgentName": "Wissenscenter",
      "WelcomeMessage": "Hallo und Willkommen! In Kürze steht Ihnen ein Live-Agent zur Verfügung. Kann ich Ihnen in der Zwischenzeit Fragen beantworten? Geben Sie unten im Eingabefeld eine Frage ein.",
      "SearchResult": "Es wurde noch kein Agent verbunden. Dies sind die relevantesten Antworten auf Ihre Frage:",
      "NoDocumentsFound": "Leider sind keine Artikel vorhanden, die Ihre Frage beantworten. Möchten Sie eine weitere Frage stellen?",
      "SuggestedMessage": "Das folgende Element aus dem Wissenscenter wurde vorgeschlagen:",
      "OpenDocumentHint": "Klicken Sie darauf, um seinen Inhalt anzuzeigen.",
      "SuggestedDocumentMessage": "Das Dokument '<%DocTitle%>' wurde vorgeschlagen.",
      "FeedbackQuestion": "War dies hilfreich?",
      "FeedbackAccept": "Ja",
      "FeedbackDecline": "Nein",
      "TranscriptMarker": "Wissenscenter: ",
      "SearchMessage": "Mit Frage '<%SearchQuery%>' suchen↲",
      "VisitMessage": "Für Dokument '<%VisitQuery%>' besuchen",
      "OpenMessage": "'<%OpenQuery%>' angezeigt",
      "AnsweredMessage": "Die Ergebnisse für die Frage '<%AnsweredQuery%>' wurden als relevant markiert.",
      "UnansweredMessage": "Die Ergebnisse für die Frage '<%UnansweredQuery%>' wurden als unbeantwortet markiert.",
      "PositiveVoteMessage": "Positive Abstimmung für Dokument '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative Abstimmung für Dokument '<%VoteQuery%>'.",
      "SuggestedMarker": "Das Dokument[^\\0]*?wurde vorgeschlagen."
    },
    "sendmessage": {
      "SendMessageButton": "Nachricht senden",
      "OK": "OK",
      "Title": "Nachricht senden",
      "PlaceholderFirstName": "Erforderlich",
      "PlaceholderLastName": "Erforderlich",
      "PlaceholderEmail": "Erforderlich",
      "PlaceholderSubject": "Erforderlich",
      "PlaceholderTypetexthere": "Geben Sie hier Ihre Nachricht ein...",
      "FirstName": "Vorname",
      "LastName": "Nachname",
      "Email": "E-Mail",
      "Subject": "Betreff",
      "Attachfiles": "Dateien anfügen",
      "AriaAttachfiles": "Link 'Dateien anfügen'; öffnet ein Dialogfeld für den Datei-Upload.",
      "Send": "Senden",
      "AriaSend": "Nachricht senden",
      "Sent": "Ihre Nachricht wurde gesendet...",
      "Close": "Schließen",
      "ConfirmCloseWindow": "Möchten Sie das Widget 'Nachricht senden' wirklich schließen?",
      "Cancel": "Abbrechen",
      "AriaMinimize": "Nachricht senden – minimieren",
      "AriaMaximize": "Nachricht senden – maximieren",
      "AriaWindowLabel": "Fenster 'Nachricht senden'",
      "AriaClose": "Nachricht senden – schließen",
      "AriaCloseAlert": "Benachrichtigungsfeld ist geschlossen",
      "AriaEndConfirm": "Ja",
      "AriaEndCancel": "Abbrechen",
      "AriaOK": "OK",
      "AriaRemoveFile": "Datei entfernen",
      "AriaFileIcon": "Datei",
      "AriaFileSize": "Dateigröße",
      "Errors": {
        "102": "Vorname ist erforderlich.",
        "103": "Nachname ist erforderlich.",
        "104": "Betreff ist erforderlich.",
        "181": "E-Mail-Adresse ist erforderlich.",
        "182": "Nachrichtentextinhalt ist erforderlich.",
        "connectionError": "Der Server ist nicht erreichbar. Versuchen Sie es erneut.",
        "unknowError": "Es ist ein Problem aufgetreten. Wir bitten die Unannehmlichkeiten zu entschuldigen. Überprüfen Sie die Verbindungseinstellungen, und versuchen Sie es erneut.",
        "attachmentsLimit": "Die maximal zulässige Anzahl von Anlagen wird überschritten: ",
        "attachmentsSize": "Die maximal zulässige Gesamtgröße von Anlagen wird überschritten: ",
        "invalidFileType": "Nicht unterstützter Dateityp. Laden Sie Bilder, PDF-Dateien, Textdateien und ZIP-Dateien hoch.",
        "invalidFromEmail": "Ungültige E-Mail-Absenderadresse.",
        "invalidMailbox": "Ungültige E-Mail-Empfängeradresse."
      }
    },
    "sidebar": {
      "SidebarTitle": "Brauchen Sie Hilfe?",
      "ChannelSelectorName": "Live-Unterstützung",
      "ChannelSelectorTitle": "Erhalten Sie direkte Unterstützung von einem unserer Agenten",
      "SearchName": "Suchen",
      "SearchTitle": "Suchen",
      "OffersName": "Angebote",
      "OffersTitle": "Angebote",
      "CallUsName": "Rufen Sie uns an",
      "CallUsTitle": "Rufen Sie uns an – Details",
      "CallbackName": "Rückruf",
      "CallbackTitle": "Anruf empfangen",
      "ClickToCallName": "Click-to-Call",
      "ClickToCallTitle": "Fordern Sie eine Kundenbetreuungs-Telefonnummer an",
      "SendMessageName": "Nachricht senden",
      "SendMessageTitle": "Nachricht senden",
      "WebChatName": "Live-Chat",
      "WebChatTitle": "Live-Chat",
      "AriaClose": "Menü 'Brauchen Sie Hilfe?' – schließen"
    },
    "webchat": {
      "ChatButton": "Chat",
      "ChatStarted": "Chat wurde gestartet",
      "ChatEnded": "Chat wurde beendet",
      "AgentNameDefault": "Agent",
      "AgentConnected": "<%Agent%> verbunden",
      "AgentDisconnected": "<%Agent%> getrennt",
      "BotNameDefault": "Bot",
      "BotConnected": "<%Bot%> verbunden",
      "BotDisconnected": "<%Bot%> getrennt",
      "SupervisorNameDefault": "Supervisor",
      "SupervisorConnected": "<%Agent%> verbunden",
      "SupervisorDisconnected": "<%Agent%> getrennt",
      "AgentTyping": "...",
      "AriaAgentTyping": "Agent schreibt",
      "AgentUnavailable": "Leider sind keine Agenten verfügbar. Bitte versuchen Sie es später.",
      "ChatTitle": "Live-Chat",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Min",
      "ChatFormName": "Name",
      "ChatFormPlaceholderName": "Optional",
      "ChatFormCustomerId": "Kundennummer",
      "ChatFormPlaceholderCustomerId": "Optional",
      "ChatFormSubjectPassword": "Passwort",
      "ChatFormSubjectService": "Dienst",
      "ChatFormSubjectOrder": "Bestellung",
      "ChatFormSubjectFinance": "Finanzen",
      "ChatFormFirstName": "Vorname",
      "ChatFormLastName": "Nachname",
      "ChatFormNickname": "Spitzname",
      "ChatFormEmail": "E-Mail",
      "ChatFormSubject": "Betreff",
      "ChatFormPlaceholderFirstName": "Erforderlich",
      "ChatFormPlaceholderLastName": "Erforderlich",
      "ChatFormPlaceholderNickname": "Optional",
      "ChatFormPlaceholderEmail": "Pflichtfeld",
      "ChatFormPlaceholderSubject": "Optional",
      "ChatFormSubmit": "Chat starten",
      "AriaChatFormSubmit": "Chat starten",
      "ChatFormCancel": "Abbrechen",
      "AriaChatFormCancel": "Chat abbrechen",
      "ChatFormClose": "Schließen",
      "ChatInputPlaceholder": "Geben Sie hier Ihre Nachricht ein.",
      "ChatInputSend": "Senden",
      "AriaChatInputSend": "Senden",
      "ChatEndQuestion": "Möchten Sie diese Chat-Sitzung wirklich beenden?",
      "ChatEndCancel": "Abbrechen",
      "ChatEndConfirm": "Chat beenden",
      "AriaChatEndCancel": "Abbrechen",
      "AriaChatEndConfirm": "Beenden",
      "ConfirmCloseWindow": "Möchten Sie diesen Chat wirklich schließen?",
      "ConfirmCloseCancel": "Abbrechen",
      "ConfirmCloseConfirm": "Schließen",
      "AriaConfirmCloseCancel": "Abbrechen",
      "AriaConfirmCloseConfirm": "Schließen",
      "ActionsDownload": "Aufzeichnung herunterladen",
      "ActionsEmail": "E-Mail-Aufzeichnung",
      "ActionsPrint": "Aufzeichnung drucken",
      "ActionsCobrowseStart": "Co-Browsing starten",
      "AriaActionsCobrowseStartTitle": "Öffnet die Co-Browsing-Sitzung",
      "ActionsSendFile": "Dateien anfügen",
      "AriaActionsSendFileTitle": "Link 'Dateien anfügen'; öffnet ein Dialogfeld für den Datei-Upload",
      "ActionsEmoji": "Emoji senden",
      "ActionsCobrowseStop": "Co-Browsing beenden",
      "ActionsVideo": "Zu Video-Chat einladen",
      "ActionsTransfer": "Übertragen",
      "ActionsInvite": "Einladen",
      "InstructionsTransfer": "Öffnen Sie diesen Link auf einem anderen Gerät, um Ihre Chat-Sitzung zu übertragen.</br></br><%link%>",
      "InstructionsInvite": "Teilen Sie diesen Link mit einer anderen Person, um sie dieser Chat-Sitzung hinzuzufügen.</br></br><%link%>",
      "InviteTitle": "Brauchen Sie Hilfe?",
      "InviteBody": "Teilen Sie uns mit, ob wir helfen können.",
      "InviteReject": "Nein danke",
      "InviteAccept": "Chat starten",
      "AriaInviteAccept": "Einladung annehmen und Chat starten",
      "AriaInviteReject": "Einladung ablehnen",
      "ChatError": "Beim Starten der Chat-Sitzung ist ein Problem aufgetreten. Versuchen Sie es erneut.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Ja",
      "ChatErrorDefaultButton": "Nein",
      "AriaChatErrorPrimaryButton": "Ja",
      "AriaChatErrorDefaultButton": "Nein",
      "DownloadButton": "Herunterladen",
      "AriaDownloadButton": "Datei herunterladen",
      "FileSent": "hat gesendet:",
      "FileTransferRetry": "Erneut versuchen",
      "AriaFileTransferRetry": "Dateiübertragung erneut versuchen",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Abbrechen",
      "AriaFileTransferCancel": "Dateiübertragung abbrechen",
      "RestoreTimeoutTitle": "Chat wurde beendet",
      "RestoreTimeoutBody": "Die Zeit für Ihre vorherige Chat-Sitzung wurde überschritten. Möchten Sie eine neue Chat-Sitzung starten?",
      "RestoreTimeoutReject": "Nein danke",
      "RestoreTimeoutAccept": "Chat starten",
      "AriaRestoreTimeoutAccept": "Einladung annehmen und Chat starten",
      "AriaRestoreTimeoutReject": "Einladung ablehnen",
      "EndConfirmBody": "Möchten Sie die Chat-Sitzung wirklich beenden?",
      "EndConfirmAccept": "Chat beenden",
      "EndConfirmReject": "Abbrechen",
      "AriaEndConfirmAccept": "Chat beenden",
      "AriaEndConfirmReject": "Abbrechen",
      "SurveyOfferQuestion": "Möchten Sie an einer Umfrage teilnehmen?",
      "ShowSurveyAccept": "Ja",
      "ShowSurveyReject": "Nein",
      "AriaShowSurveyAccept": "Ja",
      "AriaShowSurveyReject": "Nein",
      "UnreadMessagesTitle": "ungelesen",
      "AriaYouSaid": "Sie sagten",
      "AriaSaid": "sagte",
      "AriaSystemSaid": "Das System sagte",
      "AriaWindowLabel": "Fenster 'Live-Chat'",
      "AriaMinimize": "Live-Chat – minimieren",
      "AriaMaximize": "Live-Chat – maximieren",
      "AriaClose": "Live-Chat – schließen",
      "AriaEmojiStatusOpen": "Das Dialogfeld 'Emoji-Auswahl' ist geöffnet.",
      "AriaEmojiStatusClose": "Das Dialogfeld 'Emoji-Auswahl' ist geschlossen.",
      "AriaEmoji": "Emoji",
      "AriaEmojiPicker": "Emoji-Auswahl",
      "AriaCharRemaining": "Verbleibende Zeichen",
      "AriaMessageInput": "Nachrichtenfeld",
      "AsyncChatEnd": "Chat beenden",
      "AsyncChatClose": "Fenster schließen",
      "AriaAsyncChatEnd": "Chat beenden",
      "AriaAsyncChatClose": "Fenster schließen",
      "DayLabels": [
        "So",
        "Mo",
        "Di",
        "Mi",
        "Do",
        "Fr",
        "Sa"
      ],
      "MonthLabels": [
        "Jan",
        "Feb",
        "Mrz",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Okt",
        "Nov",
        "Dez"
      ],
      "todayLabel": "Heute",
      "Errors": {
        "102": "Vorname ist erforderlich.",
        "103": "Nachname ist erforderlich.",
        "161": "Geben Sie Ihren Namen ein.",
        "201": "Die Datei konnte nicht gesendet werden.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Die maximale Anzahl angefügter Dateien wird überschritten (<%MaxFilesAllowed%>).</p>",
        "202": "Die Datei konnte nicht gesendet werden.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Das Upload-Limit und/oder die maximale Anzahl von Anlagen wird überschritten (<%MaxAttachmentsSize%>).</p>",
        "203": "Die Datei konnte nicht gesendet werden.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Der Dateityp ist nicht zulässig.</p>",
        "204": "Ihre Nachricht ist zu lang. Schreiben Sie eine kürzere Nachricht.",
        "240": "Leider kann derzeit kein neuer Chat gestartet werden. Versuchen Sie es später erneut.",
        "364": "Ungültige E-Mail-Adresse",
        "401": "Die Chat-Sitzung kann leider nicht autorisiert werden. Möchten Sie einen neuen Chat starten?",
        "404": "Ihre vorherige Chat-Sitzung konnte nicht gefunden werden. Möchten Sie einen neuen Chat starten?",
        "500": "Bei dem Dienst ist ein unerwarteter Fehler aufgetreten. Möchten Sie ihn schließen und einen neuen Chat starten?",
        "503": "Der Dienst ist leider derzeit ausgelastet oder nicht erreichbar. Möchten Sie ihn schließen und einen neuen Chat starten?",
        "ChatUnavailable": "Leider kann derzeit kein neuer Chat gestartet werden. Versuchen Sie es später erneut.",
        "CriticalFault": "Ihre Chat-Sitzung wurde aufgrund eines unbekannten Problems unerwartet beendet. Wir entschuldigen uns für die Unannehmlichkeit.",
        "StartFailed": "Beim Starten Ihrer Chat-Sitzung ist ein Problem aufgetreten. Überprüfen Sie die Verbindung, überprüfen Sie, ob Sie alle erforderlichen Informationen ordnungsgemäß übermittelt haben, und versuchen Sie es dann erneut.",
        "MessageFailed": "Ihr Nachricht wurde nicht empfangen. Versuchen Sie es erneut.",
        "RestoreFailed": "Ihre Chat-Sitzung konnte aufgrund eines unbekannten Fehlers nicht wiederhergestellt werden.",
        "TransferFailed": "Chat kann derzeit nicht übertragen werden. Versuchen Sie es später erneut.",
        "FileTransferSizeError": "Die Datei konnte nicht gesendet werden.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Die Datei überschreitet die zulässige Größe (<%MaxSizePerFile%>).</p>",
        "InviteFailed": "Derzeit kann keine Einladung generiert werden. Versuchen Sie es später erneut.",
        "ChatServerWentOffline": "Das Übertragen von Nachrichten dauert derzeit länger als üblich. Wir entschuldigen uns für die Verzögerung.",
        "RestoredOffline": "Das Übertragen von Nachrichten dauert derzeit länger als üblich. Wir entschuldigen uns für die Verzögerung.",
        "Disconnected": "<div style='text-align:center'>Verbindung unterbrochen</div>",
        "Reconnected": "<div style='text-align:center'>Verbindung wiederhergestellt</div>",
        "FileSendFailed": "Die Datei konnte nicht gesendet werden.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Die Verbindung wurde unerwartet unterbrochen. Erneut versuchen?</p>",
        "Generic": "<div style='text-align:center'>Ein unerwarteter Fehler ist aufgetreten.</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Ungültiger Dateityp. Es sind nur Bilder zulässig.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "Die Datei überschreitet die zulässige Größe.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "Die maximal zulässige Anzahl von Anlagen wird überschritten.",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Ungültige x-api-key-Transportkonfiguration",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Ungültige Endpunkt-Transportkonfiguration",
        "pureengage-v3-rest-INVALID_NICKNAME": "Vorname ist erforderlich.",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "Die Chat-Sitzung kann leider nicht autorisiert werden.",
        "purecloud-v2-sockets-400": "Ein Problem ist aufgetreten. Überprüfen Sie die Verbindung, überprüfen Sie, ob Sie alle erforderlichen Informationen ordnungsgemäß übermittelt haben, und versuchen Sie es dann erneut.",
        "purecloud-v2-sockets-500": "Bei dem Dienst ist ein unerwarteter Fehler aufgetreten.",
        "purecloud-v2-sockets-503": "Der Dienst ist leider derzeit nicht erreichbar."
      }
    }
  }
}