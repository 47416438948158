import { call, takeLatest, put } from 'redux-saga/effects';
import FactorySaga from './FactorySaga';
import { GET_ACCOUNT_STATEMENT, GET_ACCOUNT_STATEMENT_ERROR } from '../actionTypes/AccountStatementActionTypes';
import AccountStatementApi from '../interfaces/AccountStatement/AccountStatementApi';

function* getAccountStatementFunc(action) {
    try {
        const result = yield call(FactorySaga, AccountStatementApi.getAccountStatement, action);
        if (result.isSuccess) {
            const blob = new Blob([result.response.data], { type: 'application/pdf' });
            const filename = 'Account_Statement.pdf';
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.click();
        } else {
            const error = JSON.parse(new TextDecoder().decode(result.response.data));
            const errorMessage = error.errors[0];
            yield put({ type: GET_ACCOUNT_STATEMENT_ERROR, data: { error: errorMessage } });
        }
    } catch (err) {
        yield put({ type: GET_ACCOUNT_STATEMENT_ERROR, data: { error: err } });
    }
}

export default function* watchGetAccountStatement() {
    yield takeLatest(GET_ACCOUNT_STATEMENT, getAccountStatementFunc);
}
