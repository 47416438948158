export const en = {
  "en": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
    "callus": {
      "CallUsTitle": "Call Us",
      "ContactsHeader": "You can reach us at any of the following numbers...",
      "CancelButtonText": "Cancel",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Already on a call? Let us <a role='link' tabindex='0' class='cx-cobrowse-link'>browse with you</a></span>",
      "CoBrowse": "Start Co-browse",
      "CoBrowseWarning": "Co-browse allows your agent to see and control your desktop, to help guide you. When on a live call with an Agent, request a code to start Co-browse and enter it below. Not yet on a call? just cancel out of this screen to return to Call Us page.",
      "SubTitle": "You can reach us at any of the following numbers...",
      "AriaWindowLabel": "Call Us Window",
      "AriaCallUsClose": "Call Us Close",
      "AriaBusinessHours": "Business Hours",
      "AriaCallUsPhoneApp": "Opens the phone application",
      "AriaCobrowseLink": "Opens the Co-browse Session",
      "AriaCancelButtonText": "Call Us Cancel"
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector": {
      "Title": "Live Assistance",
      "SubTitle": "How would you like to get in touch?",
      "WaitTimeTitle": "Wait Time",
      "AvailableTitle": "Available",
      "AriaAvailableTitle": "Available",
      "UnavailableTitle": "Unavailable",
      "CobrowseButtonText": "CobrowseSubTitle",
      "CallbackTitle": "Receive a Call",
      "CobrowseSubTitle": "Agent connection is required for this.",
      "AriaClose": "Live Assistance Close",
      "AriaWarning": "Warning",
      "AriaAlert": "Alert",
      "minute": "min",
      "minutes": "mins",
      "AriaWindowLabel": "Live Assistance Window"
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse": {
      "agentJoined": "Representative has joined the session.",
      "youLeft": "You have left the session. Co-browse is now terminated.",
      "sessionTimedOut": "Session timed out. Co-browse is now terminated.",
      "sessionInactiveTimedOut": "Session timed out. Co-browse is now terminated.",
      "agentLeft": "Representative has left the session. Co-browse is now terminated.",
      "sessionError": "Unexpected error occured. Co-browse is now terminated.",
      "sessionStarted": "Co-browse session started. Waiting for representative to connect to the session…",
      "navRefresh": "Representative has refreshed the page. Reloading.",
      "navBack": "Representative has pressed the \"Back\" button. Reloading page.",
      "navForward": "Representative has pressed the \"Forward\" button. Reloading page.",
      "navUrl": "Representative has requested navigation. Reloading page.",
      "navFailed": "Navigation request by representative has failed.",
      "toolbarContent": "Session ID",
      "contentMasked": "Content is hidden from representative.",
      "contentMaskedPartially": "Some content is hidden from representative.",
      "exitBtnTitle": "Exit Co-browse session",
      "areYouOnPhone": "Are you on the phone with our representative?",
      "areYouOnPhoneOrChat": "Are you on the phone or chat with our representative?",
      "connectBeforeCobrowse": "You need to be connected with our representative to continue with co-browsing. Please call us or start a live chat with us, and then start Co-browse again.",
      "sessionStartedAutoConnect": "Co-browse session started. Waiting for representative to connect to the session…",
      "browserUnsupported": "Unfortunately, your browser is not currently supported.<br><br> Supported browsers are: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 and above</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 and above</a></li></ul>",
      "chatIsAlreadyRunning": "Chat is already running on another page.",
      "modalYes": "Yes",
      "modalNo": "No"
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar": {
      "SidebarTitle": "Need help?",
      "ChannelSelectorName": "Live Assistance",
      "ChannelSelectorTitle": "Get assistance from one of our agents right away",
      "SearchName": "Search",
      "SearchTitle": "Search",
      "CallUsName": "Call Us",
      "CallUsTitle": "Call Us details",
      "CallbackName": "Callback",
      "CallbackTitle": "Receive a Call",
      "ClickToCallName": "ClickToCall",
      "ClickToCallTitle": "Request a customer service phone number",
      "SendMessageName": "Send Message",
      "SendMessageTitle": "Send Message",
      "WebChatName": "Live Chat",
      "WebChatTitle": "Live Chat",
      "AriaClose": "Close the menu Need help"
    },
    "webchat": {
      "ChatButton": "Chat",
      "ChatStarted": "Chat Started",
      "ChatEnded": "Chat Ended",
      "AgentNameDefault": "Agent",
      "AgentConnected": "<%Agent%> Connected",
      "AgentDisconnected": "<%Agent%> Disconnected",
      "BotNameDefault": "Bot",
      "BotConnected": "<%Bot%> Connected",
      "BotDisconnected": "<%Bot%> Disconnected",
      "SupervisorNameDefault": "Supervisor",
      "SupervisorConnected": "<%Agent%> Connected",
      "SupervisorDisconnected": "<%Agent%> Disconnected",
      "AgentTyping": "...",
      "AriaAgentTyping": "Agent is typing",
      "AgentUnavailable": "Sorry. There are no agents available. Please try later.",
      "ChatTitle": "Live Chat",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Min",
      "ChatFormFirstName": "First Name",
      "ChatFormLastName": "Last Name",
      "ChatFormNickname": "Nickname",
      "ChatFormEmail": "Email",
      "ChatFormSubject": "Subject",
	    "ChatFormName": "Name",
	    "ChatFormPlaceholderName": "Optional",
	    "ChatFormCustomerId": "Customer ID",
	    "ChatFormPlaceholderCustomerId": "Optional",
	    "ChatFormSubjectPassword": "Password",
	    "ChatFormSubjectService": "Service",
	    "ChatFormSubjectOrder": "Order",
	    "ChatFormSubjectFinance": "Finance",
      "ChatFormPlaceholderFirstName": "Required",
      "ChatFormPlaceholderLastName": "Required",
      "ChatFormPlaceholderNickname": "Optional",
      "ChatFormPlaceholderEmail": "Required",
      "ChatFormPlaceholderSubject": "Optional",
      "ChatFormSubmit": "Start Chat",
      "AriaChatFormSubmit": "Start Chat",
      "ChatFormCancel": "Cancel",
      "AriaChatFormCancel": "Cancel Chat",
      "ChatFormClose": "Close",
      "ChatInputPlaceholder": "Type your message here",
      "ChatInputSend": "Send",
      "AriaChatInputSend": "Send",
      "ChatEndQuestion": "Are you sure you want to end this chat session?",
      "ChatEndCancel": "Cancel",
      "ChatEndConfirm": "End chat",
      "AriaChatEndCancel": "Cancel",
      "AriaChatEndConfirm": "End",
      "ConfirmCloseWindow": "Are you sure you want to close chat?",
      "ConfirmCloseCancel": "Cancel",
      "ConfirmCloseConfirm": "Close",
      "AriaConfirmCloseCancel": "Cancel",
      "AriaConfirmCloseConfirm": "Close",
      "ActionsDownload": "Download transcript",
      "ActionsEmail": "Email transcript",
      "ActionsPrint": "Print transcript",
      "ActionsCobrowseStart": "Start Co-browse",
      "AriaActionsCobrowseStartTitle": "Opens the Co-browse session",
      "ActionsSendFile": "Attach Files",
      "AriaActionsSendFileTitle": "Opens a file upload dialog",
      "ActionsEmoji": "Send Emoji",
      "ActionsCobrowseStop": "Exit Co-browse",
      "ActionsVideo": "Invite to Video Chat",
      "ActionsTransfer": "Transfer",
      "ActionsInvite": "Invite",
      "InstructionsTransfer": "Open this link on another device to transfer your chat session</br></br><%link%>",
      "InstructionsInvite": "Share this link with another person to add them to this chat session</br></br><%link%>",
      "InviteTitle": "Need help?",
      "InviteBody": "Let us know if we can help out.",
      "InviteReject": "No thanks",
      "InviteAccept": "Start chat",
      "AriaInviteAccept": "Accept invite and start chat",
      "AriaInviteReject": "Reject invite",
      "ChatError": "There was a problem starting the chat session. Please retry.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Yes",
      "ChatErrorDefaultButton": "No",
      "AriaChatErrorPrimaryButton": "Yes",
      "AriaChatErrorDefaultButton": "No",
      "DownloadButton": "Download",
      "AriaDownloadButton": "Download the file",
      "FileSent": "has sent:",
      "FileTransferRetry": "Retry",
      "AriaFileTransferRetry": "Retry the file transfer",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Cancel",
      "AriaFileTransferCancel": "Cancel file transfer",
      "RestoreTimeoutTitle": "Chat ended",
      "RestoreTimeoutBody": "Your previous chat session has timed out. Would you like to start a new one?",
      "RestoreTimeoutReject": "No thanks",
      "RestoreTimeoutAccept": "Start chat",
      "AriaRestoreTimeoutAccept": "Accept invite and start chat",
      "AriaRestoreTimeoutReject": "Reject invite",
      "EndConfirmBody": "Would you really like to end your chat session?",
      "EndConfirmAccept": "End chat",
      "EndConfirmReject": "Cancel",
      "AriaEndConfirmAccept": "End chat",
      "AriaEndConfirmReject": "Cancel",
      "SurveyOfferQuestion": "Would you like to participate in a survey?",
      "ShowSurveyAccept": "Yes",
      "ShowSurveyReject": "No",
      "AriaShowSurveyAccept": "Yes",
      "AriaShowSurveyReject": "No",
      "UnreadMessagesTitle": "unread",
      "AriaYouSaid": "You said",
      "AriaSaid": "said",
      "AriaSystemSaid": "System said",
      "AriaWindowLabel": "Live Chat Window",
      "AriaMinimize": "Live Chat Minimize",
      "AriaMaximize": "Live Chat Maximize",
      "AriaClose": "Live Chat Close",
      "AriaEmojiStatusOpen": "Emoji picker dialog is opened",
      "AriaEmojiStatusClose": "Emoji picker dialog is closed",
      "AriaEmoji": "emoji",
      "AriaEmojiPicker": "Emoji Picker",
      "AriaCharRemaining": "Characters remaining",
      "AriaMessageInput": "Message box",
      "AsyncChatEnd": "End Chat",
      "AsyncChatClose": "Close Window",
      "AriaAsyncChatEnd": "End Chat",
      "AriaAsyncChatClose": "Close Window",
      "DayLabels": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thur",
        "Fri",
        "Sat"
      ],
      "MonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "todayLabel": "Today",
      "Errors": {
        "102": "First name is required.",
        "103": "Last name is required.",
        "161": "Please enter your name.",
        "201": "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>The maximum number of attached files would be exceeded (<%MaxFilesAllowed%>).</p>",
        "202": "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Upload limit and/or maximum number of attachments would be exceeded (<%MaxAttachmentsSize%>).</p>",
        "203": "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>File type is not allowed.</p>",
        "204": "We're sorry but your message is too long. Please write a shorter message.",
        "240": "We're sorry but we cannot start a new chat at this time. Please try again later.",
        "364": "Invalid email address.",
        "401": "We're sorry but we are not able to authorize the chat session. Would you like to start a new chat?",
        "404": "We're sorry but we cannot find your previous chat session. Would you like to start a new chat?",
        "500": "We're sorry, an unexpected error occurred with the service. Would you like to close and start a new Chat?",
        "503": "We're sorry, the service is currently unavailable or busy. Would you like to close and start a new Chat again?",
        "ChatUnavailable": "We're sorry but we cannot start a new chat at this time. Please try again later.",
        "CriticalFault": "Your chat session has ended unexpectedly due to an unknown issue. We apologize for the inconvenience.",
        "StartFailed": "There was an issue starting your chat session. Please verify your connection and that you submitted all required information properly, then try again.",
        "MessageFailed": "Your message was not received successfully. Please try again.",
        "RestoreFailed": "We're sorry but we were unable to restore your chat session due to an unknown error.",
        "TransferFailed": "Unable to transfer chat at this time. Please try again later.",
        "FileTransferSizeError": "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>File size is larger than the allowed size (<%MaxSizePerFile%>).</p>",
        "InviteFailed": "Unable to generate invite at this time. Please try again later.",
        "ChatServerWentOffline": "Messages are currently taking longer than normal to get through. We're sorry for the delay.",
        "RestoredOffline": "Messages are currently taking longer than normal to get through. We're sorry for the delay.",
        "Disconnected": "<div style='text-align:center'>Connection lost</div>",
        "Reconnected": "<div style='text-align:center'>Connection restored</div>",
        "FileSendFailed": "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>There was an unexpected disconnection. Try again?</p>",
        "Generic": "<div style='text-align:center'>An unexpected error occurred.</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Invalid file type. Only Images are allowed.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "File size is larger than the allowed size.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "The maximum number of attached files exceeded the limit.",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Invalid x-api-key transport configuration.",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Invalid endpoint transport configuration.",
        "pureengage-v3-rest-INVALID_NICKNAME": "First Name is required.",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "We're sorry but we are not able to authorize the chat session.",
        "purecloud-v2-sockets-400": "Sorry, something went wrong. Please verify your connection and that you submitted all required information properly, then try again.",
        "purecloud-v2-sockets-500": "We're are sorry, an unexpected error occurred with the service.",
        "purecloud-v2-sockets-503": "We're sorry, the service is currently unavailable."
      }
    }
  }
}

export const en_IE = {
  "en_IE": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
    "callus": {
      "CallUsTitle": "Call Us",
      "ContactsHeader": "You can reach us at any of the following numbers...",
      "CancelButtonText": "Cancel",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Already on a call? Let us <a role='link' tabindex='0' class='cx-cobrowse-link'>browse with you</a></span>",
      "CoBrowse": "Start Co-browse",
      "CoBrowseWarning": "Co-browse allows your agent to see and control your desktop, to help guide you. When on a live call with an Agent, request a code to start Co-browse and enter it below. Not yet on a call? just cancel out of this screen to return to Call Us page.",
      "SubTitle": "You can reach us at any of the following numbers...",
      "AriaWindowLabel": "Call Us Window",
      "AriaCallUsClose": "Call Us Close",
      "AriaBusinessHours": "Business Hours",
      "AriaCallUsPhoneApp": "Opens the phone application",
      "AriaCobrowseLink": "Opens the Co-browse Session",
      "AriaCancelButtonText": "Call Us Cancel"
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector": {
      "Title": "Live Assistance",
      "SubTitle": "How would you like to get in touch?",
      "WaitTimeTitle": "Wait Time",
      "AvailableTitle": "Available",
      "AriaAvailableTitle": "Available",
      "UnavailableTitle": "Unavailable",
      "CobrowseButtonText": "CobrowseSubTitle",
      "CallbackTitle": "Receive a Call",
      "CobrowseSubTitle": "Agent connection is required for this.",
      "AriaClose": "Live Assistance Close",
      "AriaWarning": "Warning",
      "AriaAlert": "Alert",
      "minute": "min",
      "minutes": "mins",
      "AriaWindowLabel": "Live Assistance Window"
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse": {
      "agentJoined": "Representative has joined the session.",
      "youLeft": "You have left the session. Co-browse is now terminated.",
      "sessionTimedOut": "Session timed out. Co-browse is now terminated.",
      "sessionInactiveTimedOut": "Session timed out. Co-browse is now terminated.",
      "agentLeft": "Representative has left the session. Co-browse is now terminated.",
      "sessionError": "Unexpected error occured. Co-browse is now terminated.",
      "sessionStarted": "Co-browse session started. Waiting for representative to connect to the session…",
      "navRefresh": "Representative has refreshed the page. Reloading.",
      "navBack": "Representative has pressed the \"Back\" button. Reloading page.",
      "navForward": "Representative has pressed the \"Forward\" button. Reloading page.",
      "navUrl": "Representative has requested navigation. Reloading page.",
      "navFailed": "Navigation request by representative has failed.",
      "toolbarContent": "Session ID",
      "contentMasked": "Content is hidden from representative.",
      "contentMaskedPartially": "Some content is hidden from representative.",
      "exitBtnTitle": "Exit Co-browse session",
      "areYouOnPhone": "Are you on the phone with our representative?",
      "areYouOnPhoneOrChat": "Are you on the phone or chat with our representative?",
      "connectBeforeCobrowse": "You need to be connected with our representative to continue with co-browsing. Please call us or start a live chat with us, and then start Co-browse again.",
      "sessionStartedAutoConnect": "Co-browse session started. Waiting for representative to connect to the session…",
      "browserUnsupported": "Unfortunately, your browser is not currently supported.<br><br> Supported browsers are: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 and above</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 and above</a></li></ul>",
      "chatIsAlreadyRunning": "Chat is already running on another page.",
      "modalYes": "Yes",
      "modalNo": "No"
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar": {
      "SidebarTitle": "Need help?",
      "ChannelSelectorName": "Live Assistance",
      "ChannelSelectorTitle": "Get assistance from one of our agents right away",
      "SearchName": "Search",
      "SearchTitle": "Search",
      "CallUsName": "Call Us",
      "CallUsTitle": "Call Us details",
      "CallbackName": "Callback",
      "CallbackTitle": "Receive a Call",
      "ClickToCallName": "ClickToCall",
      "ClickToCallTitle": "Request a customer service phone number",
      "SendMessageName": "Send Message",
      "SendMessageTitle": "Send Message",
      "WebChatName": "Live Chat",
      "WebChatTitle": "Live Chat",
      "AriaClose": "Close the menu Need help"
    },
    "webchat": {
      "ChatButton": "Chat",
      "ChatStarted": "Chat Started",
      "ChatEnded": "Chat Ended",
      "AgentNameDefault": "Agent",
      "AgentConnected": "<%Agent%> Connected",
      "AgentDisconnected": "<%Agent%> Disconnected",
      "BotNameDefault": "Bot",
      "BotConnected": "<%Bot%> Connected",
      "BotDisconnected": "<%Bot%> Disconnected",
      "SupervisorNameDefault": "Supervisor",
      "SupervisorConnected": "<%Agent%> Connected",
      "SupervisorDisconnected": "<%Agent%> Disconnected",
      "AgentTyping": "...",
      "AriaAgentTyping": "Agent is typing",
      "AgentUnavailable": "Sorry. There are no agents available. Please try later.",
      "ChatTitle": "Live Chat",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Min",
      "ChatFormFirstName": "First Name",
      "ChatFormLastName": "Last Name",
      "ChatFormNickname": "Nickname",
      "ChatFormEmail": "Email",
      "ChatFormSubject": "Subject",
	    "ChatFormName": "Name",
	    "ChatFormPlaceholderName": "Optional",
	    "ChatFormCustomerId": "Customer ID",
	    "ChatFormPlaceholderCustomerId": "Optional",
	    "ChatFormSubjectPassword": "Password",
	    "ChatFormSubjectService": "Service",
	    "ChatFormSubjectOrder": "Order",
	    "ChatFormSubjectFinance": "Finance",
      "ChatFormPlaceholderFirstName": "Required",
      "ChatFormPlaceholderLastName": "Required",
      "ChatFormPlaceholderNickname": "Optional",
      "ChatFormPlaceholderEmail": "Required",
      "ChatFormPlaceholderSubject": "Optional",
      "ChatFormSubmit": "Start Chat",
      "AriaChatFormSubmit": "Start Chat",
      "ChatFormCancel": "Cancel",
      "AriaChatFormCancel": "Cancel Chat",
      "ChatFormClose": "Close",
      "ChatInputPlaceholder": "Type your message here",
      "ChatInputSend": "Send",
      "AriaChatInputSend": "Send",
      "ChatEndQuestion": "Are you sure you want to end this chat session?",
      "ChatEndCancel": "Cancel",
      "ChatEndConfirm": "End chat",
      "AriaChatEndCancel": "Cancel",
      "AriaChatEndConfirm": "End",
      "ConfirmCloseWindow": "Are you sure you want to close chat?",
      "ConfirmCloseCancel": "Cancel",
      "ConfirmCloseConfirm": "Close",
      "AriaConfirmCloseCancel": "Cancel",
      "AriaConfirmCloseConfirm": "Close",
      "ActionsDownload": "Download transcript",
      "ActionsEmail": "Email transcript",
      "ActionsPrint": "Print transcript",
      "ActionsCobrowseStart": "Start Co-browse",
      "AriaActionsCobrowseStartTitle": "Opens the Co-browse session",
      "ActionsSendFile": "Attach Files",
      "AriaActionsSendFileTitle": "Opens a file upload dialog",
      "ActionsEmoji": "Send Emoji",
      "ActionsCobrowseStop": "Exit Co-browse",
      "ActionsVideo": "Invite to Video Chat",
      "ActionsTransfer": "Transfer",
      "ActionsInvite": "Invite",
      "InstructionsTransfer": "Open this link on another device to transfer your chat session</br></br><%link%>",
      "InstructionsInvite": "Share this link with another person to add them to this chat session</br></br><%link%>",
      "InviteTitle": "Need help?",
      "InviteBody": "Let us know if we can help out.",
      "InviteReject": "No thanks",
      "InviteAccept": "Start chat",
      "AriaInviteAccept": "Accept invite and start chat",
      "AriaInviteReject": "Reject invite",
      "ChatError": "There was a problem starting the chat session. Please retry.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Yes",
      "ChatErrorDefaultButton": "No",
      "AriaChatErrorPrimaryButton": "Yes",
      "AriaChatErrorDefaultButton": "No",
      "DownloadButton": "Download",
      "AriaDownloadButton": "Download the file",
      "FileSent": "has sent:",
      "FileTransferRetry": "Retry",
      "AriaFileTransferRetry": "Retry the file transfer",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Cancel",
      "AriaFileTransferCancel": "Cancel file transfer",
      "RestoreTimeoutTitle": "Chat ended",
      "RestoreTimeoutBody": "Your previous chat session has timed out. Would you like to start a new one?",
      "RestoreTimeoutReject": "No thanks",
      "RestoreTimeoutAccept": "Start chat",
      "AriaRestoreTimeoutAccept": "Accept invite and start chat",
      "AriaRestoreTimeoutReject": "Reject invite",
      "EndConfirmBody": "Would you really like to end your chat session?",
      "EndConfirmAccept": "End chat",
      "EndConfirmReject": "Cancel",
      "AriaEndConfirmAccept": "End chat",
      "AriaEndConfirmReject": "Cancel",
      "SurveyOfferQuestion": "Would you like to participate in a survey?",
      "ShowSurveyAccept": "Yes",
      "ShowSurveyReject": "No",
      "AriaShowSurveyAccept": "Yes",
      "AriaShowSurveyReject": "No",
      "UnreadMessagesTitle": "unread",
      "AriaYouSaid": "You said",
      "AriaSaid": "said",
      "AriaSystemSaid": "System said",
      "AriaWindowLabel": "Live Chat Window",
      "AriaMinimize": "Live Chat Minimize",
      "AriaMaximize": "Live Chat Maximize",
      "AriaClose": "Live Chat Close",
      "AriaEmojiStatusOpen": "Emoji picker dialog is opened",
      "AriaEmojiStatusClose": "Emoji picker dialog is closed",
      "AriaEmoji": "emoji",
      "AriaEmojiPicker": "Emoji Picker",
      "AriaCharRemaining": "Characters remaining",
      "AriaMessageInput": "Message box",
      "AsyncChatEnd": "End Chat",
      "AsyncChatClose": "Close Window",
      "AriaAsyncChatEnd": "End Chat",
      "AriaAsyncChatClose": "Close Window",
      "DayLabels": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thur",
        "Fri",
        "Sat"
      ],
      "MonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "todayLabel": "Today",
      "Errors": {
        "102": "First name is required.",
        "103": "Last name is required.",
        "161": "Please enter your name.",
        "201": "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>The maximum number of attached files would be exceeded (<%MaxFilesAllowed%>).</p>",
        "202": "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Upload limit and/or maximum number of attachments would be exceeded (<%MaxAttachmentsSize%>).</p>",
        "203": "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>File type is not allowed.</p>",
        "204": "We're sorry but your message is too long. Please write a shorter message.",
        "240": "We're sorry but we cannot start a new chat at this time. Please try again later.",
        "364": "Invalid email address.",
        "401": "We're sorry but we are not able to authorize the chat session. Would you like to start a new chat?",
        "404": "We're sorry but we cannot find your previous chat session. Would you like to start a new chat?",
        "500": "We're sorry, an unexpected error occurred with the service. Would you like to close and start a new Chat?",
        "503": "We're sorry, the service is currently unavailable or busy. Would you like to close and start a new Chat again?",
        "ChatUnavailable": "We're sorry but we cannot start a new chat at this time. Please try again later.",
        "CriticalFault": "Your chat session has ended unexpectedly due to an unknown issue. We apologize for the inconvenience.",
        "StartFailed": "There was an issue starting your chat session. Please verify your connection and that you submitted all required information properly, then try again.",
        "MessageFailed": "Your message was not received successfully. Please try again.",
        "RestoreFailed": "We're sorry but we were unable to restore your chat session due to an unknown error.",
        "TransferFailed": "Unable to transfer chat at this time. Please try again later.",
        "FileTransferSizeError": "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>File size is larger than the allowed size (<%MaxSizePerFile%>).</p>",
        "InviteFailed": "Unable to generate invite at this time. Please try again later.",
        "ChatServerWentOffline": "Messages are currently taking longer than normal to get through. We're sorry for the delay.",
        "RestoredOffline": "Messages are currently taking longer than normal to get through. We're sorry for the delay.",
        "Disconnected": "<div style='text-align:center'>Connection lost</div>",
        "Reconnected": "<div style='text-align:center'>Connection restored</div>",
        "FileSendFailed": "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>There was an unexpected disconnection. Try again?</p>",
        "Generic": "<div style='text-align:center'>An unexpected error occurred.</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Invalid file type. Only Images are allowed.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "File size is larger than the allowed size.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "The maximum number of attached files exceeded the limit.",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Invalid x-api-key transport configuration.",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Invalid endpoint transport configuration.",
        "pureengage-v3-rest-INVALID_NICKNAME": "First Name is required.",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "We're sorry but we are not able to authorize the chat session.",
        "purecloud-v2-sockets-400": "Sorry, something went wrong. Please verify your connection and that you submitted all required information properly, then try again.",
        "purecloud-v2-sockets-500": "We're are sorry, an unexpected error occurred with the service.",
        "purecloud-v2-sockets-503": "We're sorry, the service is currently unavailable."
      }
    }
  }
}