import {
    ADD_TO_FAVOURITE_REQUEST,
    REMOVE_FAVOURITE_REQUEST,
    UPDATE_BDAA_PRODUCT_FAVOURITE_PROP,
    GET_LIST_FAVORITES
} from '../actionTypes/FavouriteActionTypes';

export const getListFavorites = payload => ({
    type: GET_LIST_FAVORITES,
    payload
});

export const addToFavouriteEvent = payload => ({
    type: ADD_TO_FAVOURITE_REQUEST,
    payload
});

export const removeFavouriteEvent = payload => ({
    type: REMOVE_FAVOURITE_REQUEST,
    payload
});

export const updateBdaaProductFavouriteProp = payload => {
    return {
        type: UPDATE_BDAA_PRODUCT_FAVOURITE_PROP,
        payload
    };
};
