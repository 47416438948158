import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { AUTH } from '../common/Api';

export default class ContactMeApi {
    static getContactInfo(payload) {
        const { baseInfo } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}${AUTH.USERS}/${baseInfo.userId}/servicerequest`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static submitContactInfo(payload) {
        const { baseInfo, data } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo.siteId}${AUTH.USERS}/${baseInfo.userId}/servicerequest`;
        config.data = data;

        return RestClient.post(config).then(json => {
            return json;
        });
    }
}
