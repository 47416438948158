import { all, call, put, takeLatest } from 'redux-saga/effects';
import FactorySaga from './FactorySaga';
import {
    SUBMIT_MARKETING_CONSENT,
    GET_MARKETING_CONSENT,
    SET_MARKETING_CONSENT,
    SET_MARKETING_VISABILITY,
    MARKETING_ERROR,
    SET_MARKETING_COMMUNICATION_CHANNELS
} from '../actionTypes/MarketingActionTypes';
import { hideMarketingConsent } from '../actions/MarketingAction';
import { notifyError } from '../actions/NotificationAction';
import {
    setUserInfo,
    getUserInfo
} from '../common/UserManager/UserManager';
import {
    submitMarketing,
    getMarketing,
    submitMarketingCommunication
} from '../interfaces/Marketing/MarketingApi';

export function* getConsentSaga(action) {
    try {
        const result = yield call(FactorySaga, getMarketing, action);

        if (result) {
            //TODO: set subscriptions
            const { data, isSuccess } = result.response;
            if (!isSuccess) {
                yield all([
                    put({
                        type: MARKETING_ERROR,
                        data: data?.errors || [],
                    }),
                    put(hideMarketingConsent())
                ]);
            } else {
                yield all([
                    ...(data?.errorMessage
                        && [
                            put(notifyError({ message: data.errorMessage, isAutoClose: false })),
                            put(hideMarketingConsent())
                        ]
                        || [
                            put({
                                type: SET_MARKETING_VISABILITY,
                                data: data?.showMarketingConsent
                            }),
                            put({
                                type: SET_MARKETING_CONSENT,
                                data,
                            }),
                        ]
                    ),
                ])
            }
        }
    } catch (err) {
        yield all([
            put({ type: MARKETING_ERROR }),
            put(hideMarketingConsent())
        ]);
    }
}

export function* submitConsentSaga(action) {
    try {
        const result = yield call(FactorySaga, submitMarketing, action);
        const { data } = result.response;
        if (data.errors) {
            yield all([
                put({
                    type: MARKETING_ERROR,
                    data: data?.errors || [],
                }),
                put(hideMarketingConsent())
            ]);
        } else {
            const message = typeof data === 'string' ? 
                data.replace(
                    /\{(.*?)\}/g,
                    `<a href="">${data.match(/\{(.*?)\}/g)}</a>`
                )
                : "";

            yield all([
                put(notifyError({ message: data.message })),
                put({
                    type: SET_MARKETING_CONSENT,
                    data,
                }),
                put(hideMarketingConsent())
            ]);
        }
    } catch (err) {
        yield put({ type: MARKETING_ERROR });
    }
}

export function* setMarketingCommunicationChannels(action) {
    try {
        const result = yield call(FactorySaga, submitMarketingCommunication, action);
        const { data } = result.response;
    if (data.errors) {
        yield all([
            put({
                type: MARKETING_ERROR,
                data: data?.errors || [],
            }),
            put(hideMarketingConsent())
        ]);
    }
    } catch (err) {
        yield put({ type: MARKETING_ERROR });
    }

    updateUserInfo(action);
} 

function updateUserInfo(action) {
    const userInfo = getUserInfo();
    userInfo.marketingProfile.options = [];
    if (action.payload.smsOption === true) {
        userInfo.marketingProfile.options.push({ name: "SMS" });
    }
    if (action.payload.emailOption === true) {
        userInfo.marketingProfile.options.push({ name: "EMAIL" });
    }
    setUserInfo(userInfo);
}

export default function* marketingSagaRequest() {
    yield takeLatest(GET_MARKETING_CONSENT, getConsentSaga);
    yield takeLatest(SUBMIT_MARKETING_CONSENT, submitConsentSaga);
    yield takeLatest(SET_MARKETING_COMMUNICATION_CHANNELS, setMarketingCommunicationChannels);
}