import { GET_ORDER_CONFIRMATION_SUCCESS, CLEAR_REDUCER } from '../actionTypes/OrderConfirmActionType';

export default function OrderConfirmReducer(state = {}, action = {}) {
    switch (action.type) {
        case GET_ORDER_CONFIRMATION_SUCCESS:
            return {
                ...action.data
            };
        case CLEAR_REDUCER:
            return {};
        default:
            return state;
    }
}
