/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Link } from 'react-router';
import classNames from 'classnames/bind';
import { PRE_LOGIN_UID, URL_PATHS } from '../../../common/Constants';
import * as styles from './PreLoginHeader.css';
import Icon from '../../../components/core/Icon/Icon';
import Image from '../../../components/core/Image/Image';
import { getLocalization } from '../../../common/UserManager/UserManager';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';
import { findComponent } from '../../../common/Utils/Utils';
import { useSelector } from 'react-redux';

const cx = classNames.bind(styles);

const PreLoginHeader = ({
    logoComponents,
    mobileMenuLogoComponent,
    scrollToSection,
    headerLinks,
    loginLink,
    signupLink,
    openlanguageModal,
    handleCustomSignUpPopup
}) => {
    if (!headerLinks) {
        return null;
    }

    const siteLogoComponent = logoComponents[0];
    const mobileMenuOpenLogoComponent = mobileMenuLogoComponent[0];
    const [mobileHeader, setMobileHeader] = useState(false);
    const localization = getLocalization();
    const cmsComponents = useSelector(state => state.pageContent.components) || [];

    const showMenu = type => {
        if (type === 'hide') {
            setMobileHeader(false);
            return;
        }
        setMobileHeader(!mobileHeader);
    };

    const greekCountries = ['gr', 'cy'];
    const irishCountries = ['ni', 'roi'];
    const signUpComponent = findComponent(cmsComponents, PRE_LOGIN_UID.BACKGROUND_BANNER_COMPONENTS.SIGNUP_BTN_UID);
    return (
        <header className={cx('preLoginHeader', mobileHeader ? 'mobileHeader' : '')}>
            <div className={cx('logo-wrapper')}>
                <div onClick={() => showMenu()}>
                    <Icon
                        className={cx('icon')}
                        iconType='svg'
                        width='20px'
                        height='32px'
                        viewBox='0 0 18 18'
                        name='Icons-Menu'
                    />
                </div>
                <div className={cx('logo')}>
                    {!mobileHeader && siteLogoComponent?.url ? (
                        <Image
                            automationId='at-cchbc-logo'
                            src={siteLogoComponent.url}
                            alt={siteLogoComponent.altText}
                        />
                    ) : mobileHeader && mobileMenuOpenLogoComponent?.url ? (
                        <Image
                            automationId='at-cchbc-logo'
                            src={mobileMenuOpenLogoComponent.url}
                            alt={mobileMenuOpenLogoComponent.altText}
                        />
                    ) : null}
                </div>
            </div>
            <ul className={cx('topnav')} id='myTopnav' onClick={() => showMenu('hide')}>
                {headerLinks.benefitsLink && (
                    <li
                        key={headerLinks.benefitsLink.content}
                        onClick={() => {
                            scrollToSection(headerLinks.benefitsLink.content);
                            buttonClickEvent(
                                buttonClickEventConfigs.preLogin.actions.preLoginHeader,
                                buttonClickEventConfigs.preLogin.labels.preLoginHeaderBenefits,
                                buttonClickEventConfigs.preLogin.categories.preLogin
                            );
                        }}
                    >
                        {headerLinks.benefitsLink.content}
                    </li>
                )}
                {headerLinks.workflowLink && (
                    <li
                        key={headerLinks.workflowLink.content}
                        onClick={() => {
                            scrollToSection(headerLinks.workflowLink.content);
                            buttonClickEvent(
                                buttonClickEventConfigs.preLogin.actions.preLoginHeader,
                                buttonClickEventConfigs.preLogin.labels.preLoginHeaderHowItWorks,
                                buttonClickEventConfigs.preLogin.categories.preLogin
                            );
                        }}
                    >
                        {headerLinks.workflowLink.content}
                    </li>
                )}
                {headerLinks.aboutUsLink && (
                    <div className={cx('dropdown')}>
                        <button className={cx('dropbtn')} type='button'>
                            {headerLinks.aboutUsLink.content}
                        </button>
                        <ul className={cx('dropdown-content')}>
                            {headerLinks.whyCCHBCLink && (
                                <li
                                    key={headerLinks.whyCCHBCLink.content}
                                    onClick={() => {
                                        scrollToSection(headerLinks.whyCCHBCLink.content);
                                        buttonClickEvent(
                                            buttonClickEventConfigs.preLogin.actions.preLoginHeader,
                                            buttonClickEventConfigs.preLogin.labels.preLoginHeaderAboutUsWhyCCHBC,
                                            buttonClickEventConfigs.preLogin.categories.preLogin
                                        );
                                    }}
                                >
                                    {headerLinks.whyCCHBCLink.content}
                                </li>
                            )}
                            {headerLinks.ourCommitmentLink && (
                                <li
                                    key={headerLinks.ourCommitmentLink.content}
                                    onClick={() => {
                                        scrollToSection(headerLinks.ourCommitmentLink.content);
                                        buttonClickEvent(
                                            buttonClickEventConfigs.preLogin.actions.preLoginHeader,
                                            buttonClickEventConfigs.preLogin.labels.preLoginHeaderAboutUsWhyCommitment,
                                            buttonClickEventConfigs.preLogin.categories.preLogin
                                        );
                                    }}
                                >
                                    {headerLinks.ourCommitmentLink.content}
                                </li>
                            )}
                        </ul>
                    </div>
                )}
                {headerLinks.aboutUsLink && headerLinks.whyCCHBCLink && (
                    <li
                        className={cx('invisible')}
                        key={headerLinks.whyCCHBCLink.content}
                        onClick={() => {
                            scrollToSection(headerLinks.whyCCHBCLink.content);
                            buttonClickEvent(
                                buttonClickEventConfigs.preLogin.actions.preLoginHeader,
                                buttonClickEventConfigs.preLogin.labels.preLoginHeaderAboutUsWhyCCHBC,
                                buttonClickEventConfigs.preLogin.categories.preLogin
                            );
                        }}
                    >
                        {headerLinks.whyCCHBCLink.content}
                    </li>
                )}
                {headerLinks.aboutUsLink && headerLinks.ourCommitmentLink && (
                    <li
                        className={cx('invisible')}
                        key={headerLinks.ourCommitmentLink.content}
                        onClick={() => {
                            scrollToSection(headerLinks.ourCommitmentLink.content);
                            buttonClickEvent(
                                buttonClickEventConfigs.preLogin.actions.preLoginHeader,
                                buttonClickEventConfigs.preLogin.labels.preLoginHeaderAboutUsWhyCommitment,
                                buttonClickEventConfigs.preLogin.categories.preLogin
                            );
                        }}
                    >
                        {headerLinks.ourCommitmentLink.content}
                    </li>
                )}
                <li
                    onClick={() => {
                        openlanguageModal();
                        buttonClickEvent(
                            buttonClickEventConfigs.preLogin.actions.preLoginHeader,
                            buttonClickEventConfigs.preLogin.labels.preLoginHeaderCountryLanguageChoice,
                            buttonClickEventConfigs.preLogin.categories.preLogin
                        );
                    }}
                >
                    <div className={cx('globe-wrapper')}>
                        <Icon
                            className='flex-icon'
                            iconType='svg'
                            width='16px'
                            height='16px'
                            viewBox='0 0 18.5 18.5'
                            name='language-icon'
                        />
                        <span>{localization?.language}</span>
                    </div>
                </li>
                <li className={cx('login-btn-wrapper')}>
                    <Link
                        to={URL_PATHS.LOGIN}
                        className={cx('login-btn')}
                        onClick={() =>
                            buttonClickEvent(
                                buttonClickEventConfigs.preLogin.actions.preLoginHeader,
                                buttonClickEventConfigs.preLogin.labels.preLoginHeaderLogin,
                                buttonClickEventConfigs.preLogin.categories.preLogin
                            )
                        }
                    >
                        {loginLink?.content}
                    </Link>
                    {greekCountries.includes(localization?.siteId) ? (
                        <Link to={URL_PATHS.REGISTER} className={cx('signup-btn')}>
                            {signupLink?.content}
                        </Link>
                    ) : irishCountries.includes(localization?.siteId) ? (
                        <a
                            role='presentation'
                            onKeyDown={e => {
                                handleCustomSignUpPopup(e);
                                buttonClickEvent(
                                    buttonClickEventConfigs.preLogin.actions.preLoginHeader,
                                    buttonClickEventConfigs.preLogin.labels.preLoginHeaderSignUp,
                                    buttonClickEventConfigs.preLogin.categories.preLogin
                                );
                            }}
                            className={cx('signup-btn')}
                            onClick={e => {
                                handleCustomSignUpPopup(e);
                                buttonClickEvent(
                                    buttonClickEventConfigs.preLogin.actions.preLoginHeader,
                                    buttonClickEventConfigs.preLogin.labels.preLoginHeaderSignUp,
                                    buttonClickEventConfigs.preLogin.categories.preLogin
                                );
                            }}
                        >
                            {signUpComponent?.content}
                        </a>
                    ) : (
                        <a
                            role='presentation'
                            onKeyDown={e => {
                                handleCustomSignUpPopup(e);
                                buttonClickEvent(
                                    buttonClickEventConfigs.preLogin.actions.preLoginHeader,
                                    buttonClickEventConfigs.preLogin.labels.preLoginHeaderSignUp,
                                    buttonClickEventConfigs.preLogin.categories.preLogin
                                );
                            }}
                            className={cx('signup-btn')}
                            onClick={e => {
                                handleCustomSignUpPopup(e);
                                buttonClickEvent(
                                    buttonClickEventConfigs.preLogin.actions.preLoginHeader,
                                    buttonClickEventConfigs.preLogin.labels.preLoginHeaderSignUp,
                                    buttonClickEventConfigs.preLogin.categories.preLogin
                                );
                            }}
                        >
                            {signupLink?.content}
                        </a>
                    )}
                </li>
            </ul>
        </header>
    );
};

export default PreLoginHeader;
