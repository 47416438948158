import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _find from 'lodash/find';
import _get from 'lodash/get';
import classNames from 'classnames/bind';
import Layout from '../../../components/site/Layout/Layout';
import Image from '../../../components/core/Image/Image';
import { getPageContent } from '../../../actions/PageContentAction';
import { PAGE_ID, UNIQUE_ID, CAMPAIGN_BANNER, CAMPAIGN_L10N, CAMPAIGN_TEXT} from '../../../common/Constants';
import * as styles from './Campaigns.css';

const cx = classNames.bind(styles);

const CAMPAIGN_ID = {
    'valentine-day': 'campaignValentine',
    'easter': 'campaignEaster',
    'summer': 'campaignSummer',
    'back-to-school': 'campaignBackToSchool',
    'black-Friday': 'campaignBlackFriday',
    'christmas': 'campaignChristmas',
    'women-day': 'campaignWomenDay',
    'halloween': 'campaignHalloween',
    'saint-patrick-day': 'campaignStPatrick',
    'ramadan': 'campaignRamadan',
    'constitution-day': 'campaignConstitutionDay',
    'independance-day': 'campaignIndependanceDay',
    'labour-day': 'campaignLaborDay',
    'russia-day': 'campaignRussiaDay'
}

export function Campaigns(props) {
    let isInitialLoad = false;

    useEffect(() => {
        props.actions.getPageContent({ pageId: CAMPAIGN_ID[props.location.pathname.split('/').pop()] });
        isInitialLoad = true;
    }, []);

    const prepareAdMiddleBanners = (bannerImages) => {
        return _get(bannerImages, 'childComponents', [])
    }

    const renderBannerSection = () => {
        const { topBanner } = props;

        return (
            <div 
                automation-id='at-campaign-banner' 
                className={cx('campaignBanner')}
            >
                <Image
                    automation-id='at-static-banner'
                    theme={cx('bannerImg')}
                    src={topBanner.url}
                    linkUrl={topBanner.urlLink}
                    alt={topBanner.altText}
                />
            </div>
        );
    };

    
    const renderTextSection = () => {
        const { campaignText } = props;
        return (
            <h5>{campaignText.content}</h5>
        );
    };

    const renderTitleTextSection = () => {
        const { campaignTitleText } = props;
        return (
            <h1>{campaignTitleText.content}</h1>
        );
    };

    const renderAdBanner = () => {
        const { bannerImages } = props;
        const banners = prepareAdMiddleBanners(bannerImages);
        return (
            <div className={cx('productMidBanners', 'campaignBanner')}>

                {renderTitleTextSection()}
                {renderTextSection()}

                {banners.map(item => (
                    <div
                        key={item.url} className={cx('imageContainer', 'col4', 'sm6', 'md4', 'text-center')}>
                        <Image
                            automation-id='at-Promotion'
                            theme={cx('bannerImg')}
                            linkUrl={item.urlLink}
                            src={item.url}
                            alt={item.altText}
                        />
                        <div>
                            <span>
                             {item.content}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Layout showDashboardHeader showOutlet theme={cx('campaignsContainer')}>
           {renderBannerSection()}
           {renderAdBanner()}

        </Layout>
    );
}

Campaigns.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func),
    topBanner: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    bannerImages: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    campaignText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    campaignTitleText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    breadcrumbs: PropTypes.arrayOf(PropTypes.any),
    localization: PropTypes.objectOf(PropTypes.string),
    commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

Campaigns.defaultProps = {
    actions: {},
    topBanner: {},
    bannerImages: {},
    campaignText: {},
    campaignTitleText: {},
    breadcrumbs: [],
    localization: {},
    commonLocalization: {}
};

const mapStateToProps = state => {
    const components = _get(state, 'pageContent.components', {});

    return {
        topBanner: _find(components, [UNIQUE_ID, CAMPAIGN_L10N.TOP_BANNER]),
        bannerImages: _find(components, [UNIQUE_ID, CAMPAIGN_BANNER.AD_IMAGES_UID]),
        campaignText: _find(components, [UNIQUE_ID, CAMPAIGN_TEXT.CAMPAIGN_TEXT_CONTENT]),
        campaignTitleText: _find(components, [UNIQUE_ID, CAMPAIGN_TEXT.CAMPAIGN_TITLE_TEXT_CONTENT]),
        breadcrumbs: _get(state, 'pageContent.breadcrumbs'),
        localization: _get(state, 'pageContent.localization'),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`),
        components: _get(state, 'pageContent', {})
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getPageContent
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
