import React from 'react';

import _get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { PLP_L10N} from '../../../../common/Constants';

import List from '../../ProductList/List';
import { getListFavorites } from '../../../../actions/FavouriteAction';

export const AccountShoppingFavorites = () => {
    const dispatch = useDispatch();

    const listOfFavorites = useSelector(state => state.favorites);
    const bdaaSuggestion = useSelector(state => state.products.bdaaSuggestion);
    const deviceType = useSelector(state => state.context.deviceType);
    const blockAddToCartEnabled = useSelector(state => state.homePageData.blockAddToCartEnabled);

    const localization = useSelector(state => state.pageContent.localization);

    const nextPage = React.useCallback(currentPage => {
        dispatch(getListFavorites({ currentPage }));
    }, [getListFavorites]);

    const getPageContentFunc = React.useCallback(() => {
        nextPage()
    }, [dispatch, nextPage]);

    React.useEffect(() => {
        getPageContentFunc();
    }, [getPageContentFunc]);

    return (
        <List
            productList={listOfFavorites}
            blockAddToCartEnabled={blockAddToCartEnabled}
            selectedView={PLP_L10N.GRID_VIEW}
            currentPage={0}
            bdaaSuggestion={bdaaSuggestion}
            localization={localization}
            isShowFilter={false}
            isShowPrice={false}
            isGrid={true}
            isReturnEmpties={false}
            appliedMobileView=''
            isSearch={false}
            deviceType={deviceType}
            isMarketingMaterials={false}
            plpType={'order'}
            nextPage={nextPage}
            isPagination
            isFrom='shopping-list'
        />
    );
};
