export const uk = {
  "uk": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
    "callus": {
      "CallUsTitle":"Зателефонуйте нам",
      "ContactsHeader":"Ви можете зв’язатися з нами за будь-яким із наведених нижче номерів",
      "CancelButtonText":"Відхилити",
      "CoBrowseText":"<span class='cx-cobrowse-offer'>Ми можемо вам зателефонувати <a role='link' tabindex='0' class='cx-cobrowse-link'>browse with you</a></span>",
      "CoBrowse":"Почати спільний перегляд",
      "CoBrowseWarning":"Спільний перегляд дозволяє спеціалісту бачити та отримати управління на Вашому робочому столі, щоб допомогти Вам. Для того, щоб розпочати спільний перегляд, запитайте код у спеціаліста під час розмови. Якщо Ви ще не контактували зі спеціалістом, відхиліть та поверніться до меню \"Зателефонуйте нам\"",
      "SubTitle":"Ви можете зв’язатися з нами за будь-яким із наведених нижче номерів",
      "AriaWindowLabel":"Меню \"Зателефонуйте нам\"",
      "AriaCallUsClose":"Закрити меню \"Зателефонуйте нам\"",
      "AriaBusinessHours":"Робочі години",
      "AriaCallUsPhoneApp":"Відкриває застосунок у смартфоні",
      "AriaCobrowseLink":"Відкриває сесію спільного перегляду",
      "AriaCancelButtonText":"Відхилити меню \"Зателефонуйте нам\"",
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector":{
      "Title":"Допомога спеціаліста",
      "SubTitle":"Як з Вами зв'язатися?",
      "WaitTimeTitle":"Час очікування",
      "AvailableTitle":"Доступний",
      "AriaAvailableTitle":"Доступний",
      "UnavailableTitle":"Недоступний",
      "CobrowseButtonText":"Підзаголовок спільного перегляду",
      "CallbackTitle":"Отримати дзвінок",
      "CobrowseSubTitle":"Для цього потрібно зв'язатися зі спеціалістом",
      "AriaClose":"Закрити вікно \"Допомога спеціаліста\"",
      "AriaWarning":"Попередження",
      "AriaAlert":"Сповіщення",
      "minute":"хв.",
      "minutes":"хв.",
      "AriaWindowLabel":"Вікно \"Допомога спеціаліста\""
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse":{
      "agentJoined":"Представник приєднався до сесії",
      "youLeft":"Ви покинули сесію. Зараз спільний перегляд припинено",
      "sessionTimedOut":"Час очікування сеансу минув. Спільний перегляд припинено.",
      "sessionInactiveTimedOut":"Час очікування сеансу минув. Спільний перегляд припинено.",
      "agentLeft":"Представник покинув сесію. Спільний перегляд припинено.",
      "sessionError":"Сталася несподівана помилка. Спільний перегляд припинено.",
      "sessionStarted":"Cеанс спільного перегляду розпочато. Очікуйте, поки представник під'єднається до сесії ...",
      "navRefresh":"Представник оновив сторінку. Перезавантаження.",
      "navBack":"Представник натиснув кнопку \"Назад \". Перезавантаження сторінки.",
      "navForward":"Представник натиснув кнопку \"Вперед\". Перезавантаження сторінки.",
      "navUrl":"Представник запросив навігацію. Перезавантаження сторінки.",
      "navFailed":"Помилка навігаційного запиту представника.",
      "toolbarContent":"Ідентифікатор сеансу:",
      "contentMasked":"Зміст приховано від представника.",
      "contentMaskedPartially":"Частина змісту прихована від представника.",
      "exitBtnTitle":"Вийти із сеансу спільного перегляду",
      "areYouOnPhone":"Ви розмовляєте с нашим представником?",
      "areYouOnPhoneOrChat":"Ви розмовляєте з нашим представником телефоном або у чаті?",
      "connectBeforeCobrowse":"Вам потрібно встановити зв’язок з нашим представником, щоб продовжити спільний перегляд. Будь ласка, зателефонуйте нам або напишіть у чат, а потім знову розпочніть сеанс спільного перегляду.",
      "sessionStartedAutoConnect":"Сеанс спільного перегляду розпочато. Очікуйте підключення представника до сеансу ...",
      "browserUnsupported":"На жаль, ваш браузер наразі не підтримується. <br> <br> Підтримувані браузери: <ul> <li> <a target = '_ blank' href = 'http: //www.google.com/ chrome '> Google Chrome </a> </li> <li> <a target='_blank' href='http://www.firefox.com/'> Mozilla Firefox </a> </li> <li > <a target='_blank' href='http://microsoft.com/ie'> Internet Explorer 9 і вище </a> </li> <li> <a target = '_ blank' href = 'https: //www.apple.com/safari'>Safari 6 і вище </a> </li> </ul>",
      "chatIsAlreadyRunning":"Чат уже запущений на іншій сторінці.",
      "modalYes":"Так",
      "modalNo":"Ні"
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar":{
      "SidebarTitle":"Потрібна допомога?",
      "ChannelSelectorName":"Допомога в режимі онлайн",
      "ChannelSelectorTitle":"Отримайте допомогу від одного з наших спеціалістів",
      "SearchName":"Пошук",
      "SearchTitle":"Пошук",
      "CallUsName":"Зателефонуйте нам",
      "CallUsTitle":"Зателефонувати нам. Деталі",
      "CallbackName":"Зворотний дзвінок",
      "CallbackTitle":"Отримати дзвінок",
      "ClickToCallName":"Натиснути, щоб зателефонувати",
      "ClickToCallTitle":"Запит на номер телефону служби обслуговування клієнтів",
      "SendMessageName":"Надіслати повідомлення",
      "SendMessageTitle":"Надіслати повідомлення",
      "WebChatName":"Онлайн-чат",
      "WebChatTitle":"Онлайн-чат",
      "AriaClose":"Закрити меню \"Потрібна допомога\""
    },
    "webchat":{
      "ChatButton":"Чат",
      "ChatStarted":"Чат розпочато",
      "ChatEnded":"Чат завершено",
      "AgentNameDefault":"Спеціаліст",
      "AgentConnected":"Підключений",
      "AgentDisconnected":"Відключений",
      "BotNameDefault":"Бот",
      "BotConnected":"Підключений",
      "BotDisconnected":"Відключений",
      "SupervisorNameDefault":"Супервайзер",
      "SupervisorConnected":"Підключений",
      "SupervisorDisconnected":"Відключений",
      "AgentTyping":"",
      "AriaAgentTyping":"Спеціаліст пише",
      "AgentUnavailable":"На жаль, немає вільних спеціалістів. Спробуйте пізніше",
      "ChatTitle":"Онлайн-чат",
      "ChatEnd":"X",
      "ChatClose":"X",
      "ChatMinimize":"Хв.",
      "ChatFormFirstName":"Ім'я",
      "ChatFormLastName":"Прізвище",
      "ChatFormNickname":"Логін",
      "ChatFormName": "Ім'я",
      "ChatFormPlaceholderName": "Необов'язково",
      "ChatFormCustomerId": "Код замовника",
      "ChatFormPlaceholderCustomerId": "Необов'язково",
      "ChatFormSubjectPassword": "Пароль",
      "ChatFormSubjectService": "Послуга",
      "ChatFormSubjectOrder": "Замовлення",
      "ChatFormSubjectFinance": "Фінанси",

      "ChatFormEmail":"Електронна адреса",
      "ChatFormSubject":"Тема",
      "ChatFormPlaceholderFirstName":"Обов'язково",
      "ChatFormPlaceholderLastName":"Обов'язково",
      "ChatFormPlaceholderNickname":"Необов'язково",
      "ChatFormPlaceholderEmail":"Обов'язково",
      "ChatFormPlaceholderSubject":"Необов'язково",
      "ChatFormSubmit":"Почати чат",
      "AriaChatFormSubmit":"Почати чат",
      "ChatFormCancel":"Скасувати",
      "AriaChatFormCancel":"Скасувати чат",
      "ChatFormClose":"Закрити",
      "ChatInputPlaceholder":"Напишіть своє повідомлення тут",
      "ChatInputSend":"Відправити",
      "AriaChatInputSend":"Відправити",
      "ChatEndQuestion":"Ви впевнені, що хочете закінчити цей чат?",
      "ChatEndCancel":"Скасувати",
      "ChatEndConfirm":"Закінчити чат",
      "AriaChatEndCancel":"Скасувати",
      "AriaChatEndConfirm":"Закінчити",
      "ConfirmCloseWindow":"Ви впевнені, що хочете закрити чат?",
      "ConfirmCloseCancel":"Скасувати",
      "ConfirmCloseConfirm":"Закрити",
      "AriaConfirmCloseCancel":"Скасувати",
      "AriaConfirmCloseConfirm":"Закрити",
      "ActionsDownload":"Завантажити запис",
      "ActionsEmail":"Відправити запис електронною поштою",
      "ActionsPrint":"Друкувати запис",
      "ActionsCobrowseStart":"Почати спільний перегляд",
      "AriaActionsCobrowseStartTitle":"Починається сесія спільного перегляду",
      "ActionsSendFile":"Прикріпити файли",
      "AriaActionsSendFileTitle":"Відкриває вікно завантаження файлу",
      "ActionsEmoji":"Відправити емодзі",
      "ActionsCobrowseStop":"Вийти із сесії спільного перегляду",
      "ActionsVideo":"Запросити до відеочату",
      "ActionsTransfer":"Відправити",
      "ActionsInvite":"Запросити",
      "InstructionsTransfer":"Відкрийте це посилання на іншому пристрої, щоб перенести чат</br></br><%link%>",
      "InstructionsInvite":"Поділіться цим посиланням з іншою особою, щоб додати його до цього чату</br></br><%link%>",
      "InviteTitle":"Потрібна допомога?",
      "InviteBody":"Повідомте нас, якщо ми можемо допомогти.",
      "InviteReject":"Ні, дякую",
      "InviteAccept":"Розпочати чат",
      "AriaInviteAccept":"Прийняти запрошення і розпочати чат",
      "AriaInviteReject":"Відхилити запрошення",
      "ChatError":"Не вдалося запустити чат. Повторіть спробу.",
      "ChatErrorButton":"OK",
      "AriaChatErrorButton":"OK",
      "ChatErrorPrimaryButton":"Так",
      "ChatErrorDefaultButton":"Ні",
      "AriaChatErrorPrimaryButton":"Так",
      "AriaChatErrorDefaultButton":"Ні",
      "DownloadButton":"Завантажити",
      "AriaDownloadButton":"Завантажити файл",
      "FileSent":"Відправлено",
      "FileTransferRetry":"Повторити",
      "AriaFileTransferRetry":"Повторити відправлення файлу",
      "FileTransferError":"ОК",
      "AriaFileTransferError":"ОК",
      "FileTransferCancel":"Скасувати",
      "AriaFileTransferCancel":"Скасувати відправлення файлу",
      "RestoreTimeoutTitle":"Чат завершено",
      "RestoreTimeoutBody":"Вийшов максимальний час сеансу чату. Чи хочете ви розпочати новий?",
      "RestoreTimeoutReject":"Ні, дякую",
      "RestoreTimeoutAccept":"Розпочати чат",
      "AriaRestoreTimeoutAccept":"Прийняти запрошення і розпочати чат",
      "AriaRestoreTimeoutReject":"Відхилити запрошення",
      "EndConfirmBody":"Ви дійсно хотіли б завершити чат?",
      "EndConfirmAccept":"Завершити чат",
      "EndConfirmReject":"Скасувати",
      "AriaEndConfirmAccept":"Завершити чат",
      "AriaEndConfirmReject":"Скасувати",
      "SurveyOfferQuestion":"Ви хочете взяти участь в опитуванні?",
      "ShowSurveyAccept":"Так",
      "ShowSurveyReject":"Ні",
      "AriaShowSurveyAccept":"Так",
      "AriaShowSurveyReject":"Ні",
      "UnreadMessagesTitle":"Непрочитане",
      "AriaYouSaid":"Ви писали",
      "AriaSaid":"писали",
      "AriaSystemSaid":"Повідомлення системи",
      "AriaWindowLabel":"Вікно онлайн чату",
      "AriaMinimize":"Зменшити вікно онлайн чату",
      "AriaMaximize":"Збільшити вікно онлайн чату",
      "AriaClose":"Закрити вікно онлайн чату",
      "AriaEmojiStatusOpen":"Відкрито вікно вибору емодзі",
      "AriaEmojiStatusClose":"Закрито вікно вибору емодзі",
      "AriaEmoji":"Емодзі",
      "AriaEmojiPicker":"Вікно вибору емодзі",
      "AriaCharRemaining":"Залишилося символів",
      "AriaMessageInput":"Вікно повідомлення",
      "AsyncChatEnd":"Завершити чат",
      "AsyncChatClose":"Закрити вікно",
      "AriaAsyncChatEnd":"Завершити чат",
      "AriaAsyncChatClose":"Закрити вікно",
      "DayLabels": [
      "Неділя",
      "Понеділок",
      "Вівторок",
      "Середа",
      "Четвер",
      "П'ятниця",
      "Субота",
      ],
      "MonthLabels": [
      "Січень",
      "Лютий",
      "Березень",
      "Квітень",
      "Травень",
      "Червень",
      "Липень",
      "Серпень",
      "Вересень",
      "Жовтень",
      "Листопад",
      "Грудень",
      ],
      "todayLabel":"Сьогодні",
      "Errors": {
        "102":"Потрібне ім'я",
        "103":"Потрібне прізвище",
        "161":"Будь ласка, вкажіть Ваше ім'я",
        "201": "Не вдалося надіслати файл.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Буде перевищено максимально дозволену кількість вкладених файлів (<%MaxFilesAllowed%>).</p>",
        "202": "Не вдалося надіслати файл.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Буде перевищено ліміт завантаження та / або максимальну кількість вкладень (<%MaxAttachmentsSize%>).</p>",
        "203": "Не вдалося надіслати файл.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Неправильний тип файлу.</p>",
        "204":"На жаль, Ваше повідомлення надто довге. Напишіть коротше повідомлення.",
        "240":"На жаль, наразі ми не можемо розпочати новий чат. Повторіть спробу пізніше.",
        "364":"Неправильна електронна адреса.",
        "401":"На жаль, ми не можемо авторизувати чат. Хочете розпочати новий чат?",
        "404":"На жаль, ми не можемо знайти ваш попередній чат. Хочете розпочати новий чат?",
        "500":"На жаль, сталася несподівана помилка. Хочете закрити та розпочати новий чат?",
        "503":"На жаль, сервіс наразі недоступний або зайнятий. Хочете закрити та розпочати новий чат знову?",
        "ChatUnavailable":"На жаль, наразі ми не можемо розпочати новий чат. Будь ласка, спробуйте пізніше.",
        "CriticalFault":"Ваш чат закінчився через невідому проблему. Просимо вибачення за незручності.",
        "StartFailed":"Під час запуску чату сталася помилка. Перевірте своє з’єднання та правильність поданої інформації, а потім спробуйте ще раз.",
        "MessageFailed":"Ваше повідомлення не отримане. Повторіть спробу.",
        "RestoreFailed":"На жаль, нам не вдалося відновити чат через невідому помилку.",
        "TransferFailed":"Зараз неможливо перенести чат. Повторіть спробу пізніше.",
        "FileTransferSizeError": "Не вдалося надіслати файл.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Перевищено дозволений розмір файлу. (<%MaxSizePerFile%>).</p>",
        "InviteFailed":"Зараз не вдається створити запрошення. Повторіть спробу пізніше.",
        "ChatServerWentOffline":"Відправлення повідомлення зараз займає більше часу ніж зазвичай. Вибачте за затримку.",
        "RestoredOffline":"Відправлення повідомлення зараз займає більше часу ніж зазвичай. Вибачте за затримку.",
        "Disconnected": "<div style='text-align:center'>З'єднання перервано</div>",
        "Reconnected": "<div style='text-align:center'>З'єднання відновлено</div>",
        "FileSendFailed": "Не вдалося надіслати файл.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>Сталося несподіване відключення. Повторити спробу?</p>",
        "Generic": "<div style='text-align:center'>Сталася несподівана помилка.</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE":"Неправильний тип файлу. Дозволені лише зображення.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE":"Перевищено дозволений розмір файлу.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT":"Перевищено максимальну кількість вкладених файлів.",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Invalid x-api-key transport configuration.",
        "pureengage-v3-rest-INVALID_ENDPOINT":"Недійсна конфігурація транспорту кінцевої точки.",
        "pureengage-v3-rest-INVALID_NICKNAME":"Потрібне ім'я.",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED":"На жаль, ми не можемо авторизувати чат.",
        "purecloud-v2-sockets-400":"Вибачте, щось пішло не так. Перевірте своє з’єднання та правильність поданої інформації, а потім спробуйте ще раз.",
        "purecloud-v2-sockets-500":"На жаль, сталася несподівана помилка.",
        "purecloud-v2-sockets-503":"На жаль, сервіс наразі недоступний."
      }
    }
  }
}
