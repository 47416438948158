import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import * as styles from './removeOOSModal.css';
import { CART_L10N, CART_PRODUCT_LENGTH, GENERIC_L10N, PAGE_ID } from '../../../common/Constants';
import Modal from '../../../components/core/Modal/Modal';
import Button from '../../../components/core/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { removeManyFromCart } from '../../../actions/CartAction';
import Image from '../../../components/core/Image/Image';
import { getImageByFormat } from '../../../common/Utils/Utils';
import { createPortal } from 'react-dom';
import Icon from '../../../components/core/Icon/Icon';
import { removingOosProductsEnabled } from '../../../common/UserManager/UserManager';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';
const cx = classNames.bind(styles);

export const RemoveOOSModal = props => {
    const removingOosProductsisEnabled = removingOosProductsEnabled();
    const commonLocalization = useSelector(state => state.pageContent.commonLocalization[PAGE_ID.GENERIC]);
    const cartList = useSelector(state => state.cartDetails?.cart?.cartList);
    const localization = useSelector(state => state.pageContent.localization);
    const dispatch = useDispatch();
    const isFirstMount = useRef(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const oosProducts = React.useMemo(() => {
        const reduced = cartList?.reduce((acc, curr) => {
            const oosItems = (curr?.cartDetails?.entries?.orderEntries || []).filter(oe => !!oe?.isNotFullyInStock);
            return [...acc, ...oosItems];
        }, []);
        return reduced;
    }, [cartList]);
    const removeProducts = () => {
        dispatch(
            removeManyFromCart({
                data: {
                    productCodesAndQuantities: oosProducts
                        .filter(oos => !!oos.product.code)
                        .map(oos => ({
                            key: oos.product.code,
                            value: oos.scheduleLines && Math.round(oos.scheduleLines[0]?.confirmedQuantity || 0)
                        }))
                },
                pageId: PAGE_ID.CART
            })
        );
        document.documentElement.style.overflowY = 'unset';
        setIsOpenModal(false);
    };

    useEffect(() => {
        if (oosProducts?.length > 0 && removingOosProductsisEnabled && !isFirstMount.current) {
            setIsOpenModal(true);
            isFirstMount.current = true;
            document.documentElement.style.overflowY = 'hidden';
        }
    }, [oosProducts, removingOosProductsEnabled]);

    const onCancel = () => {
        document.documentElement.style.overflowY = 'unset';
        setIsOpenModal(false);
    };
    return (
        <>
            <button
                type='button'
                className={cx('removeOSSBtn', oosProducts?.length === 0 ? 'disable-click' : '')}
                onClick={() => {
                    if (oosProducts?.length > 0) {
                        document.documentElement.style.overflowY = 'hidden';
                        setIsOpenModal(true);
                        buttonClickEvent(
                            buttonClickEventConfigs.cart.actions.shoppingCart,
                            buttonClickEventConfigs.cart.labels.shoppingCartRemoveOOSItems,
                            buttonClickEventConfigs.cart.categories.cart
                        );
                    }
                }}
            >
                <Icon
                    className={cx('remove-oss-icon')}
                    iconType='svg'
                    width='9px'
                    height='auto'
                    viewBox='0 0 9 12'
                    name='remove-oos'
                />
                {localization && localization[CART_L10N.REMOVE_OOS_ITEMS_BUTTON]}
            </button>
            {isOpenModal &&
                createPortal(
                    <Modal
                        showModal={isOpenModal}
                        modalTheme={cx('themeModal')}
                        modalTitleTheme={cx('modalTitleTheme')}
                        themeContent={cx('themeContent')}
                        themeClose={cx('themeClose')}
                        themeModalBlock={cx('themeModalBlock')}
                        defaultCloseBtn={false}
                        className={cx('modalContainer')}
                    >
                        <div className={cx('inner-modal-content')}>
                            <div className={cx('removeHeader')}>
                                <div>
                                    <p className={cx('headerText')}>
                                        {localization && localization[CART_L10N.REMOVE_OOS_ITEMS_TEXT]}
                                    </p>
                                </div>
                                <div className={cx('closeWrapper')}>
                                    <button
                                        className={cx('modalCloseButton', 'themeClose')}
                                        onClick={() => {
                                            onCancel();
                                            buttonClickEvent(
                                                buttonClickEventConfigs.cart.actions.outOfStockPopUp,
                                                buttonClickEventConfigs.cart.labels.outOfStockPopUpExitPopUp,
                                                buttonClickEventConfigs.cart.categories.cart
                                            );
                                        }}
                                        type='button'
                                    >
                                        &times;
                                    </button>
                                </div>
                            </div>
                            <div className={cx('border-line')} />
                            <div className={cx('products-content')}>
                                {oosProducts.map(oe => {
                                    const { product } = oe;
                                    const image = getImageByFormat(product?.images);
                                    return (
                                        <div key={product.code}>
                                            <div className={cx('product-item-container')}>
                                                <div className={cx('product-img-container')}>
                                                    <Image
                                                        src={image.url}
                                                        // linkUrl={productUrl}
                                                        alt={image.altText}
                                                        automationId='at-cart-item-image'
                                                        theme={cx('imgClass')}
                                                        isInternal={image.defaultImage}
                                                    />
                                                </div>
                                                <div>
                                                    <p translate='no' className={cx('product-name')}>
                                                        {product.name.substring(0, CART_PRODUCT_LENGTH)}
                                                        {product.name.length > CART_PRODUCT_LENGTH && <span>...</span>}
                                                    </p>
                                                    <div className={cx('product-details-container')}>
                                                        <p>
                                                            <span className={cx('secondaryTitle')}>
                                                                {commonLocalization[GENERIC_L10N.SKU]}{' '}
                                                                {product.codeShort}
                                                            </span>
                                                        </p>
                                                        <span className={cx('stockContainer')}>
                                                            <span
                                                                className={cx(
                                                                    'inStock',
                                                                    'inlineLevel',
                                                                    `${oe.stockColour || 'default'}bgATP`
                                                                )}
                                                            >
                                                                {oe.stockLevel}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={cx('border-line')} />
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={cx('bottomBtns', 'clearContent')}>
                                <Button
                                    type='cancel'
                                    size={cx('Sm')}
                                    className={cx('cartsBtns', 'cancelBtn')}
                                    onClick={() => {
                                        onCancel();
                                        buttonClickEvent(
                                            buttonClickEventConfigs.cart.actions.outOfStockPopUp,
                                            buttonClickEventConfigs.cart.labels.outOfStockPopUpKeepItems,
                                            buttonClickEventConfigs.cart.categories.cart
                                        );
                                    }}
                                >
                                    {localization && localization[CART_L10N.KEEP_ITEMS]}
                                </Button>
                                <Button
                                    type='submit'
                                    buttonType={cx('Primary')}
                                    size={cx('Sm')}
                                    className={cx('cartsBtns', 'clearAll')}
                                    onClick={() => {
                                        removeProducts();
                                        buttonClickEvent(
                                            buttonClickEventConfigs.cart.actions.outOfStockPopUp,
                                            buttonClickEventConfigs.cart.labels.outOfStockPopUpReduceItems,
                                            buttonClickEventConfigs.cart.categories.cart
                                        );
                                    }}
                                >
                                    {localization && localization[CART_L10N.REMOVE_ITEMS]}
                                </Button>
                            </div>
                        </div>
                    </Modal>,
                    document.body
                )}
        </>
    );
};

export default RemoveOOSModal;
