/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import {
    readLoyaltyNotification,
    clearLoyaltyNotifications,
    muteLoyaltyNotifications,
    unmuteLoyaltyNotifications,
    displayNotificationBanner
} from '../../../actions/LoyaltyAction';
import { PAGE_ID, GENERIC_L10N } from '../../../common/Constants';
import Icon from '../../core/Icon/Icon';
import Switcher from '../../core/Switcher/Switcher';
import * as styles from './Notification.css';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

const Notification = ({ mobileAlerts, setShowAlerts }) => {
    const dispatch = useDispatch();

    const [openLoyaltyNotifications, setOpenLoyaltyNotifications] = useState(false);
    const [readStatus, setReadStatus] = useState('all');
    const [notificationSettings, setNotificationSettings] = useState(false);
    const [showNotificationBanner, setShowNotificationBanner] = useState(false);

    const loyaltyNotifications = useSelector(state => state.loyalty.loyaltyNotifications);
    const unreadLoyaltyNotifications = useSelector(state => state.loyalty.unreadLoyaltyNotifications);
    const unreadCount = useSelector(state => state.loyalty.unreadCount);
    const mutedNotifications = useSelector(state => state.loyalty.muted);
    const commonLocalization = useSelector(state => state.pageContent.commonLocalization[PAGE_ID.GENERIC]);
    const isBannerShown = useSelector(state => state.loyalty.notificationBannerShown);

    const loyaltyCenterRef = useRef(null);
    const closeIconRef = useRef(null);

    useEffect(() => {
        if (!isBannerShown) {
            if (unreadCount && unreadCount > 0) {
                setShowNotificationBanner(true);
                setTimeout(() => {
                    setShowNotificationBanner(false);
                }, 7000);
                dispatch(displayNotificationBanner(true));
            }
        }
    }, [unreadCount, isBannerShown]);

    const onGAOpenNotifications = () => {
        buttonClickEvent(
            buttonClickEventConfigs.header.actions.header,
            buttonClickEventConfigs.header.labels.headerNotificationsOpen,
            buttonClickEventConfigs.header.categories.header
        );
    };

    const onGACloseNotifications = () => {
        buttonClickEvent(
            buttonClickEventConfigs.header.actions.header,
            buttonClickEventConfigs.header.labels.headerNotificationsClose,
            buttonClickEventConfigs.header.categories.header
        );
    };

    const onGAAllNotifications = () => {
        buttonClickEvent(
            buttonClickEventConfigs.header.actions.header,
            buttonClickEventConfigs.header.labels.headerNotificationsAll,
            buttonClickEventConfigs.header.categories.header
        );
    };

    const onGAUnreadNotifications = () => {
        buttonClickEvent(
            buttonClickEventConfigs.header.actions.header,
            buttonClickEventConfigs.header.labels.headerNotificationsUnread,
            buttonClickEventConfigs.header.categories.header
        );
    };

    const handleBlur = event => {
        const isChildOfNotificationCenter = loyaltyCenterRef.current.contains(event.relatedTarget);
        if (isChildOfNotificationCenter) {
            return;
        }
        setOpenLoyaltyNotifications(false);
        onGACloseNotifications();
    };

    const handleBellClick = () => {
        const open = !openLoyaltyNotifications;
        setOpenLoyaltyNotifications(open);
        if (open) {
            onGAOpenNotifications();
        } else {
            onGACloseNotifications();
        }
    };

    const handleReadLoyaltyNotification = (messageId, notification) => {
        if (notification.isRead) return;
        dispatch(readLoyaltyNotification(messageId));
    };

    const handleMuteNotifications = () => {
        if (!mutedNotifications) {
            dispatch(muteLoyaltyNotifications());
        } else {
            dispatch(unmuteLoyaltyNotifications());
        }
    };

    const handleClearNotifications = () => {
        dispatch(clearLoyaltyNotifications());
    };

    const handleGetAllUnreadNotifications = () => {
        setReadStatus('unread');
        onGAUnreadNotifications();
    };

    const handleBackToNotifications = () => {
        if (mutedNotifications) return;
        setNotificationSettings(false);
        loyaltyCenterRef.current.focus();
    };

    return (
        <li tabIndex={-1} ref={loyaltyCenterRef} onBlur={handleBlur}>
            <div className={cx('notificationBellIcon')}>
                <div role='button' tabIndex={-1} onKeyDown={handleBellClick} onClick={handleBellClick}>
                    <Icon iconType='svg' width='24px' height='24px' viewBox='0 0 24 24' name='notification-bell' />
                    {unreadCount > 0 && <div className={cx('notificationNumber')}>{unreadCount}</div>}
                </div>
                {showNotificationBanner && (
                    <div className={cx('notificationTooltip')}>
                        {commonLocalization && commonLocalization[GENERIC_L10N.LOYALTY.GREETINGS]}
                    </div>
                )}
                <div
                    style={{ display: openLoyaltyNotifications || mobileAlerts ? 'block' : 'none' }}
                    tabIndex={-1}
                    className={cx('notificationList')}
                >
                    <div className={cx('notificationListHeader')}>
                        <div className={cx('notificationListHeaderIconRow')}>
                            <div
                                tabIndex={-1}
                                role='button'
                                onKeyDown={() => {
                                    setNotificationSettings(true);
                                }}
                                onClick={() => {
                                    setNotificationSettings(true);
                                }}
                            >
                                <Icon
                                    iconType='svg'
                                    width='24px'
                                    height='24px'
                                    viewBox='0 0 24 24'
                                    name={mobileAlerts ? 'notification-cog-black' : 'notification-cog'}
                                    className={cx('flex-icon', 'notificationListHeaderIcons')}
                                />
                            </div>
                            {mobileAlerts && (
                                <div
                                    tabIndex={-1}
                                    role='button'
                                    onKeyDown={() => {
                                        setShowAlerts();
                                    }}
                                    onClick={() => {
                                        setShowAlerts();
                                    }}
                                >
                                    <Icon
                                        iconType='svg'
                                        width='24px'
                                        height='24px'
                                        viewBox='0 0 24 24'
                                        name='close-gray'
                                        className={cx(
                                            'flex-icon',
                                            'notificationListHeaderIcons',
                                            'closeMobileAlertsIcon'
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                        <p style={{ userSelect: 'none' }}>
                            {commonLocalization && commonLocalization[GENERIC_L10N.LOYALTY.TITLE]}
                        </p>

                        {!mobileAlerts && (
                            <div
                                role='button'
                                tabIndex={-1}
                                ref={closeIconRef}
                                onKeyDown={() => setOpenLoyaltyNotifications(false) & onGACloseNotifications()}
                                onClick={() => setOpenLoyaltyNotifications(false) & onGACloseNotifications()}
                            >
                                <Icon
                                    iconType='svg'
                                    width='24px'
                                    height='24px'
                                    viewBox='0 0 24 24'
                                    name='close-global'
                                    className={cx('flex-icon', 'notificationListHeaderIcons')}
                                />
                            </div>
                        )}
                    </div>
                    {notificationSettings || mutedNotifications ? (
                        <div className={cx('settingsContainer')}>
                            <div className={cx('backButtonRow')}>
                                <div
                                    className={cx('backButton', mutedNotifications && 'backButtonDisabled')}
                                    role='presentation'
                                    tabIndex={-1}
                                    onClick={handleBackToNotifications}
                                >
                                    <Icon
                                        iconType='svg'
                                        width='24px'
                                        height='24px'
                                        viewBox='0 0 24 24'
                                        name='chevron-left-black'
                                        className='flex-icon'
                                    />
                                    <p>{commonLocalization && commonLocalization[GENERIC_L10N.LOYALTY.BACK]}</p>
                                </div>
                            </div>
                            <div className={cx('settingsCard')}>
                                <div className={cx('settingsCardHeader')}>
                                    <p>{commonLocalization && commonLocalization[GENERIC_L10N.LOYALTY.MUTE_TITLE]}</p>
                                    <Switcher
                                        controlledValue={!mutedNotifications}
                                        onChange={handleMuteNotifications}
                                    />
                                </div>
                                <div className={cx('settingsCardSubHeader')}>
                                    <p>
                                        {commonLocalization && commonLocalization[GENERIC_L10N.LOYALTY.MUTE_SUBTITLE]}
                                    </p>
                                </div>
                                <div className={cx('settingsCardDivider')} />
                                <div className={cx('settingsCardFooter')}>
                                    <p>
                                        {commonLocalization && commonLocalization[GENERIC_L10N.LOYALTY.DELETE_MESSAGES]}{' '}
                                        ({loyaltyNotifications?.length})
                                    </p>
                                    {loyaltyNotifications?.length > 0 ? (
                                        <div
                                            role='presentation'
                                            tabIndex={-1}
                                            onKeyDown={handleClearNotifications}
                                            onClick={handleClearNotifications}
                                        >
                                            <Icon
                                                iconType='svg'
                                                width='24px'
                                                height='24px'
                                                viewBox='0 0 24 24'
                                                name='notification-bin'
                                                className={cx('settingsCardBin')}
                                            />
                                        </div>
                                    ) : (
                                        <Icon
                                            iconType='svg'
                                            width='24px'
                                            height='24px'
                                            viewBox='0 0 24 24'
                                            name='checkmark'
                                            className={cx('flex-icon')}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className={cx('notificationListSubHeader')}>
                                <div className={cx('loyaltyTab')}>
                                    <p>
                                        {commonLocalization && commonLocalization[GENERIC_L10N.LOYALTY.LOYALTY_TITLE]}
                                    </p>
                                    <div className={cx('loyaltyUnderline')} />
                                </div>
                                <div className={cx('loyaltyToggle')}>
                                    <p
                                        className={cx(
                                            readStatus === 'all' ? 'loyaltyToggleActive' : 'loyaltyToggleInactive'
                                        )}
                                        onClick={() => {
                                            setReadStatus('all') & onGAAllNotifications();
                                        }}
                                        role='presentation'
                                    >
                                        {commonLocalization && commonLocalization[GENERIC_L10N.LOYALTY.ALL_MESSAGES]}
                                    </p>
                                    <p
                                        className={cx(
                                            readStatus === 'unread' ? 'loyaltyToggleActive' : 'loyaltyToggleInactive'
                                        )}
                                        onClick={handleGetAllUnreadNotifications}
                                        role='presentation'
                                    >
                                        {commonLocalization && commonLocalization[GENERIC_L10N.LOYALTY.UNREAD_MESSAGES]}
                                    </p>
                                </div>
                            </div>
                            <div className={cx('notificationListCards')}>
                                {readStatus === 'all'
                                    ? loyaltyNotifications?.map(notification => {
                                          return (
                                              <div
                                                  key={notification.messageId}
                                                  role='button'
                                                  tabIndex={-1}
                                                  onClick={() =>
                                                      handleReadLoyaltyNotification(
                                                          notification.messageId,
                                                          notification
                                                      )
                                                  }
                                                  onKeyDown={() =>
                                                      handleReadLoyaltyNotification(
                                                          notification.messageId,
                                                          notification
                                                      )
                                                  }
                                                  className={cx('notificationListCard')}
                                              >
                                                  {!notification?.isRead ? (
                                                      <div className={cx('notificationDot')} />
                                                  ) : (
                                                      <div />
                                                  )}
                                                  {notification?.icon === 'HANDS' ? (
                                                      <div className={cx('icon-bg')}>
                                                          <Icon
                                                              iconType='svg'
                                                              width='34px'
                                                              height='34px'
                                                              viewBox='-6 -10 34 34'
                                                              name='notification-hands'
                                                          />
                                                      </div>
                                                  ) : notification?.icon === 'DEFAULT' ? (
                                                      <Icon
                                                          iconType='svg'
                                                          width='34px'
                                                          height='34px'
                                                          viewBox='0 -4 34 34'
                                                          name='notification-tag'
                                                      />
                                                  ) : notification?.icon === 'GIFT' ? (
                                                      <div className={cx('icon-bg')}>
                                                          <Icon
                                                              iconType='svg'
                                                              width='34px'
                                                              height='34px'
                                                              viewBox='-9 -9 34 34'
                                                              name='notification-gift'
                                                          />
                                                      </div>
                                                  ) : (
                                                      <Icon
                                                          iconType='svg'
                                                          width='34px'
                                                          height='34px'
                                                          viewBox='0 -4 34 34'
                                                          name='notification-tag'
                                                      />
                                                  )}
                                                  <div className={cx('notificationListCardText')}>
                                                      {notification?.message}
                                                  </div>
                                              </div>
                                          );
                                      })
                                    : unreadLoyaltyNotifications?.map(unreadNotification => {
                                          return (
                                              <div
                                                  key={unreadNotification.messageId}
                                                  className={cx('notificationListCard')}
                                                  role='button'
                                                  tabIndex={-1}
                                                  onClick={() =>
                                                      handleReadLoyaltyNotification(
                                                          unreadNotification.messageId,
                                                          unreadNotification
                                                      )
                                                  }
                                                  onKeyDown={() =>
                                                      handleReadLoyaltyNotification(
                                                          unreadNotification.messageId,
                                                          unreadNotification
                                                      )
                                                  }
                                              >
                                                  <div className={cx('notificationDot')} />
                                                  {unreadNotification?.icon === 'HANDS' ? (
                                                      <div className={cx('icon-bg')}>
                                                          <Icon
                                                              iconType='svg'
                                                              width='34px'
                                                              height='34px'
                                                              viewBox='-6 -10 34 34'
                                                              name='notification-hands'
                                                          />
                                                      </div>
                                                  ) : unreadNotification?.icon === 'DEFAULT' ? (
                                                      <Icon
                                                          iconType='svg'
                                                          width='34px'
                                                          height='34px'
                                                          viewBox='0 -4 34 34'
                                                          name='notification-tag'
                                                      />
                                                  ) : unreadNotification?.icon === 'GIFT' ? (
                                                      <div className={cx('icon-bg')}>
                                                          <Icon
                                                              iconType='svg'
                                                              width='34px'
                                                              height='34px'
                                                              viewBox='-9 -9 34 34'
                                                              name='notification-gift'
                                                          />
                                                      </div>
                                                  ) : (
                                                      <Icon
                                                          iconType='svg'
                                                          width='34px'
                                                          height='34px'
                                                          viewBox='0 -4 34 34'
                                                          name='notification-tag'
                                                      />
                                                  )}
                                                  <div className={cx('notificationListCardText')}>
                                                      {unreadNotification?.message}
                                                  </div>
                                              </div>
                                          );
                                      })}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </li>
    );
};

export default Notification;
