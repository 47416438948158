import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Link from 'react-router/lib/Link';
import * as styles from './BreadCrumbs.css';

const cx = classNames.bind(styles);

const BreadCrumbs = props => {
    const { breadcrumbValues = [], custombreadCrumbWrapper } = props;
    return (
        <div className={cx('breadCrumbWrapper', custombreadCrumbWrapper)} automation-id='at-breadcrumb-navigation'>
            <ul className={cx('crumbWrapper')}>
                {breadcrumbValues &&
                    breadcrumbValues.length > 0 &&
                    breadcrumbValues.map(item => {
                        return (
                            <li className={cx('crumbList')} key={`${item.name}-${item.id}`}>
                                <span className={styles.arrow}>
                                    {item.url !== '#' ? <Link to={item.url}>{item.name}</Link> : item.name}
                                </span>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};

BreadCrumbs.propTypes = {
    breadcrumbValues: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    custombreadCrumbWrapper: PropTypes.string
};

BreadCrumbs.defaultProps = {
    breadcrumbValues: [],
    custombreadCrumbWrapper: ''
};

export default BreadCrumbs;
