import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import { HOME_L10N } from '../../../common/Constants';
import { findComponent } from '../../../common/Utils/Utils';
import { getCountry, getUserInfo } from '../../../common/UserManager/UserManager';
import { setMarketingCommunicationChannels } from '../../../actions/MarketingAction';
import { notifyBlueMessage } from '../../../actions/NotificationAction';

import * as styles from './ConsentBanner.css';

const cx = classNames.bind(styles);

const ConsentBanner = ({ bannerImages, localization, hideSubscriptionBanner }) => {

    const dispatch = useDispatch();
    const { displayExplicitBanner } = getUserInfo();
    const [showBanner, setShowBanner] = useState(displayExplicitBanner);
    const [emailChecked, setEmailChecked] = useState(true);
    const [smsChecked, setSmsChecked] = useState(true);

    const titleComponent = findComponent(bannerImages, HOME_L10N.MARKETING_CONSENT_BANNER_TITLE);
    const contentComponent = findComponent(bannerImages, HOME_L10N.MARKETING_CONSENT_BANNER_CONTENT);
    const hideOptionsClass = getCountry() === 'Poland' ?'' :'hide-options';

    const subscribe = (emailOption, smsOption) => {
        hideSubscriptionBanner();
        setShowBanner(false);
        dispatch(setMarketingCommunicationChannels({
            emailOption: emailOption,
            smsOption: smsOption})
        );
        dispatch(notifyBlueMessage({message: localization.subscribeNotification}));
    }

    const rejectSubscription = () => { 
        setEmailChecked(false);
        setSmsChecked(false);
        subscribe(false,false);
    }

    if (showBanner) {
        return (
            <div className={cx('background')}>
                <div className={cx('consent-wrapper')}>
                    <div className={cx('texts-wrapper')}>
                        <h3>{titleComponent?.content}</h3>
                        <p dangerouslySetInnerHTML={{__html: contentComponent?.content}} />
                        <div className={cx(hideOptionsClass)}>
                            <input type="checkbox" id="email" name="email" defaultChecked={emailChecked} onChange={() => setEmailChecked(!emailChecked)}/>
                            <label htmlFor="email">{localization?.emailLabel}</label>
                            <input type="checkbox" id="sms" name="sms" defaultChecked={smsChecked} onChange={() => setSmsChecked(!smsChecked)}/>
                            <label htmlFor="sms">{localization?.smsLabel}</label>
                        </div>
                    </div>
                    <div className={cx('buttons-wrapper')}>
                        <button className={cx('subscribe-btn')} type="button" onClick={() => subscribe(emailChecked, smsChecked)} >{localization?.btnSubscribe}</button>
                        <button className={cx('cancel-btn')} type="button" onClick={() => rejectSubscription()} >{localization?.btnReject}</button>
                    </div>
                </div>
            </div>
        )
    }
    return null;
}

export default ConsentBanner;