import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import * as styles from './SoftConsentModal.css';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import Icon from '../Icon/Icon'; 
import { HOME_L10N } from '../../../common/Constants';
import { updateSoftConsentFlag } from '../../../actions/UserMaintenanceAction';
import { setUserInfo, getUserInfo } from '../../../common/UserManager/UserManager';
import { redirectTo, findComponent } from '../../../common/Utils/Utils';

const cx = classNames.bind(styles);

const SoftConsentModal = ({ bannerImages, localization, hideSoftConsentPopup }) => {

    const dispatch = useDispatch();
    const { displaySoftConsentPopup } = getUserInfo();
    const [showPopup, setShowPopup] = useState(displaySoftConsentPopup);
    
    const title = findComponent(bannerImages, HOME_L10N.SOFT_CONSENT_POPUP_TITLE)?.content;
    const subtitle = findComponent(bannerImages, HOME_L10N.SOFT_CONSENT_POPUP_SUBTITLE)?.content;
    const policyTitle = findComponent(bannerImages, HOME_L10N.SOFT_CONSENT_POPUP_POLICY_TITLE)?.content;
    const policyContent = findComponent(bannerImages, HOME_L10N.SOFT_CONSENT_POPUP_POLICY_CONTENT)?.content;
    
    const agreeOnConsent = () => {
        const userInfo = {...getUserInfo(), displaySoftConsentPopup: true};
        hideSoftConsentPopup();
        setUserInfo(userInfo);
        setShowPopup(false);
        dispatch(updateSoftConsentFlag());
    };

    const redirectToManagePreferences = () => {
        agreeOnConsent();
        redirectTo('/account/personaldata');
    };

    if(showPopup) {
        return (
            <div className={cx('contactUsModal')}>
                <Modal
                    showModal={showPopup}
                    modalTheme={cx('userThemeModal')}
                    modalTitleTheme={cx('modalTitleTheme')}
                    themeContent={cx('themeContent')}
                    themeClose={cx('themeClose')}
                    themeModalBlock={cx('themeModalBlock')} 
                    defaultCloseBtn={false}
                >
                    <div className={cx('clearContent')}>
                        <div className={cx('contentText')}>
                            <h1>{title}</h1>
                            <p>{subtitle}</p>
                        </div>
                        <div className={cx('contentIcons')}>
                            <Icon
                                className={cx('emailIcon')}
                                iconType='svg'
                                width='54' height='48'
                                viewBox='0 0 54 48' name='email' />
                            <div className={cx('iconLabel', 'emailLabel')}>
                                {localization[HOME_L10N.EMAIL_LABEL]}
                            </div> 
                            <Icon iconType='svg' width='54' height='48' viewBox='0 0 54 48' name='sms' />
                            <div className={cx('iconLabel')}>
                                {localization[HOME_L10N.SMS_LABEL]}
                            </div>
                        </div>
                        <div className={cx('contentText')}>
                            {policyContent}
                            <a
                                className={cx('phoneLink')}
                                automation-id='at-phone-number-link'
                            >
                                &nbsp; {policyTitle}
                            </a>
                        </div>
                        <div className={cx('bottomBtns')}>
                            <Button
                                type='button'
                                size={cx('Sm')}
                                className={cx('userBtns', 'managePreferencesButton')}
                                onClick={redirectToManagePreferences}>
                                {localization[HOME_L10N.MANAGE_PREFERENCES_BUTTON]}
                            </Button>
                            <Button
                                type='button'
                                size={cx('Sm')}
                                className={cx('userBtns', 'agreeButton')}
                                onClick={agreeOnConsent}
                            > 
                                {localization[HOME_L10N.AGREE_BUTTON]}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
    return null;
};

export default SoftConsentModal;