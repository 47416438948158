import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { AUTH, PARAMS } from '../common/Api';

export default class InvoicesApi {
    static getAccountStatement(payload) {
        const { baseInfo, from, to, params = {} } = payload;
        const config = RestConfig.v2ComApiConfigPDF(payload);
        config.url = `/${baseInfo.siteId}${AUTH.USERS}/${baseInfo.userId}/accountstatement/pdf`;
        params[PARAMS.FROM] = from;
        params[PARAMS.TO] = to;
        config.params = params;
        return RestClient.get(config).then(response => {
            return response;
        });
    }
}
