import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { HOME_L10N, URL_PATHS, PAGE_TYPES, CART_L10N, PAGE_ID } from '../../../common/Constants';

import { redirectTo } from '../../../common/Utils/Utils';
import Button from '../../core/Button/Button';
import Modal from '../../core/Modal/Modal';
import * as styles from './StockAllocation.css';
import { SessionStorage } from '../../../common/Storage/Storage';
import ProductCard from '../ProductCards/ProductCards';
import Icon from '../../core/Icon/Icon';
const cx = classNames.bind(styles);

export const StockAllocation = ({
    automationId,
    title, 
    subTitle,
    isFrom
}) => {
    const [showModal, setShowModal] = useState(true);
    const stockAllocationProps = useSelector(state => state.pageContent.stockAllocation);
    const localization = useSelector(state => state.pageContent.localization);
    if (!showModal || !stockAllocationProps?.products?.length) return null;

    const { products } = stockAllocationProps;

    const closeModal = () => {
        setShowModal(false);
    };

    const redirectToCart = () => {
        SessionStorage.setData(CART_L10N.FROM_STOCK_ALLOCATION_POPUP, true, true)
        redirectTo(URL_PATHS.CART);
    };

    return (
        <Modal
            showModal={showModal}
            className={cx('stockAllocationOverlay')}
            modalTheme={cx('stockAllocationTheme')}
            modalTitleTheme={cx('stockAllocationTitle')}
            modalTitleText={cx('stockAllocationTitleText')}
            themeContent={cx('stockAllocationContent')}
            themeClose={cx('themeClose')}
            themeModalBlock={cx('stockAllocationWrapper')}
            title={<div className={cx('titleWrapper')}>
                <div className={cx('newLabel')}><Icon
                                                className={cx('warning-icon')}
                                                iconType='svg'
                                                width='24px'
                                                height='26px'
                                                viewBox='0 0 24.238 26'
                                                name='warning-stock-allocation'
                                            />{title}</div>
                <div className={cx('titleWrapper__text')}>{subTitle}</div>
            </div>}
            titleAutomationId={automationId}
            onClose={closeModal}
        >
            <>
            <ul className={cx('stockAllocationProductsList')}>{(products.map(i => ({ ...i.product, maxQty: i.quantity })) || []).map((item, key) => (
                item.maxQty ? <ProductCard
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...item}
                    imageUrl={item.images?.[0].url}
                    isReadOnlyMode
                    isListGrid
                    unitOptions={item.availableUnits.map(({id,description}) => ({id,label: description, value: description}))}
                    url={item.url}
                    price={0}
                    key={`stock-allocation-item-${key.toString()}`}
                    code={item.code}
                    skuId={item.codeShort}
                    isFav={item.favourite}
                    isReturnEmpties={false}
                    isFrom={PAGE_TYPES.HOME}
                    recommendedQuantity={item?.quantity || null}
                    recommendedReason={item?.reason || ''}
                /> : null
            ))}</ul>
            <div className={cx('stockAllocationButtons')}>
                {isFrom === PAGE_ID.HOME && (
                    <>
                <Button
                automation-id="at-minicart-journey-modal-checkout-button"
                className={cx('stockAllocationButton', 'stockAllocationCheckoutButton')}
                type='button'
                size='Sm'
                buttonType='Primary'
                onClick={redirectToCart}
            >{localization?.[HOME_L10N.STOCK_ALLOCATION_CONTINUE_TO_CART]}</Button>
            <Button
                automation-id="at-stock-allocation-modal-cancel-button"
                className={cx('stockAllocationButton', 'stockAllocationCancelButton')}
                type='button'
                size='Sm'
                buttonType='Secondary'
                onClick={() => redirectTo(URL_PATHS.PLP_ORDER)}
            >{localization?.[HOME_L10N.STOCK_ALLOCATION_CONTINUE_SHOPPING]}</Button>
            </>
                )}
                {isFrom === PAGE_ID.CART && (
                      <Button
                      automation-id="at-stock-allocation-modal-cancel-button"
                      className={cx('stockAllocationButton', 'stockAllocationCancelButton')}
                      type='button'
                      size='Sm'
                      buttonType='Secondary'
                      onClick={() => closeModal()}
                  >{localization?.[CART_L10N.STOCK_ALLOCATION_BUTTON_OK]}</Button>
                )}
            </div>
            </>
        </Modal>
    );
};


StockAllocation.propTypes = {
    automationId: PropTypes.string,
};

StockAllocation.defaultProps = {
    automationId: 'at-stock-allocation-modal',
};

export default StockAllocation;
