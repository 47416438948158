import {
  GET_ORDER_TEMPLATE_SUCCESS,
  RESET_ORDER_TEMPLATE,
  POST_ORDER_TEMPLATE_EXCEL_ERROR,
  RESET_TEMPLATE_EXCEL_ERROR,
  CLEAR_REDUCER
} from '../actionTypes/OrderTemplateActionTypes';

/**
 * {
  items: [
    {
      id: string,
      name: string,
      description: string,
      qty: number,
      date: string,
    }
  ],
  totalItems: number,
  totalPage: number,
  currentPage: number,
  itemPerPage: number
}
**/
export default function OrderTemplateReducer(state = {}, action = {}) {
    const { type, data } = action;
    switch (type) {
        case CLEAR_REDUCER:
            return {}
        case GET_ORDER_TEMPLATE_SUCCESS:
            return {
                ...state,
                ...data
            };
        case RESET_ORDER_TEMPLATE:
            return {};
        case POST_ORDER_TEMPLATE_EXCEL_ERROR:
            return {
                ...state,
                templateUploadError: data
            }
        case RESET_TEMPLATE_EXCEL_ERROR:
            return {
                ...state,
                templateUploadError: undefined
            }
        default:
            return state;
    }
}
