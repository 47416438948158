import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import * as styles from './RadioButton.css';

const cx = classNames.bind(styles);

const RadioButton = props => {
    const { name, checked, disabled, labelText, theme, onChange, id, customCheckMark, value, automationId } = props;

    return (
        <label className={`${cx('customRadio', theme)}`} htmlFor={id}>
            <input
                type='radio'
                name={name}
                disabled={disabled}
                checked={checked}
                value={value}
                onChange={e => onChange(e)}
                id={id}
                automation-id={automationId}
            />
            <span className={cx('checkmark', customCheckMark)} />
            {labelText && <p>{labelText}</p>}
        </label>
    );
};

RadioButton.propTypes = {
    name: PropTypes.string,
    labelText: PropTypes.string,
    disabled: PropTypes.bool,
    theme: PropTypes.string,
    onChange: PropTypes.func,
    id: PropTypes.string,
    customCheckMark: PropTypes.string,
    value: PropTypes.string,
    automationId: PropTypes.string
};

RadioButton.defaultProps = {
    name: '',
    labelText: '',
    disabled: false,
    theme: '',
    onChange: () => {},
    id: '',
    customCheckMark: '',
    value: '',
    automationId: ''
};

export default RadioButton;
