import {useDispatch} from "react-redux";
import React, {useEffect} from "react";
import {notificationRead} from "../../../../actions/CreditInfoAction";
import styles from "./CreditIssueSnippet.pcss";
import {CreditIssueMessage} from "../CreditIssueMessage/CreditIssueMessage";
import PropTypes from "prop-types";

const FixedContainer = (props) => {
    return <div className={`${styles.container} ${styles.fixed}`}>{props.children}</div>
}

const RelativeContainer = (props) => {
    return <div style={{position: "relative"}}>
        <div style={{position: "absolute", width: "100%"}}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative"
            }}>
                <div className={styles.container}>{props.children}</div>
            </div>
        </div>
    </div>
}

const SnippetContainer = (props) => {
    return props.fixed ? <FixedContainer>{props.children}</FixedContainer>
        : <RelativeContainer>{props.children}</RelativeContainer>
}

export const CreditIssueSnippet = (props) => {
    const {isOpen} = props;
    const dispatch = useDispatch();
    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                close();
            }, 5000);
        }
    }, [isOpen]);

    const close = () => {
        dispatch(notificationRead());
    }
    if (!isOpen) return <></>;

    return <SnippetContainer fixed={props.fixed}>
        <div className={styles.content}>
            <CreditIssueMessage checkInvoicesButton={false} text={props.text}/>
        </div>
    </SnippetContainer>
};
CreditIssueSnippet.propTypes = {
    text: PropTypes.string,
    isOpen: PropTypes.bool,
    fixed: PropTypes.bool
}