import {
    UPDATE_FAVOURITES,
    GET_FAVORITES_REQUEST_SUCCESS,
    CLEAR_FAVOURITES
} from '../actionTypes/FavouriteActionTypes';
import { filterProductListingData } from '../service/ProductListFilter';
import { getNewProducts } from './ProductListReducer';
import {SUBSCRIBE_TO_PRODUCT_SUCCESS} from "../actionTypes/NotifyMeActionTypes";

export default function FavoritesReducer(state = {}, action = {}) {
    switch (action.type) {
        case GET_FAVORITES_REQUEST_SUCCESS: {
            const transformedData = filterProductListingData(action.data);
            const products = getNewProducts(state, transformedData);
            return {
                ...state,
                ...transformedData,
                products,
                isReset: false
            };
        }

        case SUBSCRIBE_TO_PRODUCT_SUCCESS: {
            const newData = state;
            const { products } = newData;

            if (!products) {
                return state;
            }
            const index = products.findIndex(item => item.code === action.params.code);
            if(index === -1 ) return state
            products[index] = {
                ...products[index],
                stockNotified: true
            };

            return {
                ...state,
                products
            };
        }

        case UPDATE_FAVOURITES: {
            const newData = { ...state };
            const { products } = newData;

            if (!action.isFavourite) {
                if (!products) return state;
                newData[products] = products.filter(item => item.code !== action.code);
            }

            if (action.isFavourite && action?.product) {
                newData[products] = [...products, action.product];
            }

            return {
                ...state,
                products: newData[products]
            };
        }

        case CLEAR_FAVOURITES: {
            return {
                ...state,
                products: undefined
            };
        }

        default:
            return state;
    }
}
