export const pl = {
  "pl": {
    "calendar": {
      "CalendarDayLabels": [
        "niedziela",
        "poniedziałek",
        "wtorek",
        "środa",
        "czwartek",
        "piątek",
        "sobota"
      ],
      "CalendarMonthLabels": [
        "sty",
        "lut",
        "mar",
        "kwi",
        "maj",
        "cze",
        "lip",
        "sie",
        "wrz",
        "paź",
        "Lis",
        "gru"
      ],
      "CalendarLabelToday": "Dziś",
      "CalendarLabelTomorrow": "Jutro",
      "CalendarTitle": "Zaplanuj połączenie",
      "CalendarOkButtonText": "OK",
      "CalendarError": "Nie można pobrać szczegółów dostępności.",
      "CalendarClose": "Anuluj",
      "AriaWindowTitle": "Okno kalendarza",
      "AriaCalendarClose": "Anuluj kalendarz i wróć do rejestracji wywołania zwrotnego",
      "AriaYouHaveChosen": "Wybrano",
      "AriaNoTimeSlotsFound": "Brak zakresów czasu dla wybranej daty"
    },
    "callus": {
      "CallUsTitle": "Zadzwoń do nas",
      "ContactsHeader": "Możesz skontaktować się z nami pod następującymi numerami...",
      "CancelButtonText": "Anuluj",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Połączenie już trwa? Pozwól nam <a role='link' tabindex='0' class='cx-cobrowse-link'>przeglądać z Tobą</a></span>",
      "CoBrowse": "Rozpocznij współprzeglądanie",
      "CoBrowseWarning": "Współprzeglądanie umożliwia agentowi wyświetlanie i kontrolowanie Twojego pulpitu w celu udzielania Ci wskazówek. Podczas połączenia z agentem poproś o kod do rozpoczęcia współprzeglądania i wprowadź go poniżej. Jeżeli połączenie nie zostało jeszcze nawiązane, wyjdź z tego ekranu i powróć do strony Zadzwoń do nas.",
      "SubTitle": "Możesz skontaktować się z nami pod następującymi numerami...",
      "AriaWindowLabel": "Okno Zadzwoń do nas",
      "AriaCallUsClose": "Zamknij Zadzwoń do nas",
      "AriaBusinessHours": "W godzinach pracy",
      "AriaCallUsPhoneApp": "Otwiera aplikację telefoniczną",
      "AriaCobrowseLink": "Otwiera sesję wspólnego przeglądania",
      "AriaCancelButtonText": "Anuluj Zadzwoń do nas"
    },
    "callback": {
      "CallbackTitle": "Odbierz połączenie",
      "CancelButtonText": "Anuluj",
      "AriaCancelButtonText": "Anuluj",
      "ConfirmButtonText": "Potwierdź",
      "AriaConfirmButtonText": "Potwierdź",
      "CallbackFirstName": "Imię",
      "CallbackPlaceholderRequired": "Wymagane",
      "CallbackPlaceholderOptional": "Opcjonalnie",
      "CallbackLastName": "Nazwisko",
      "CallbackPhoneNumber": "Telefon",
      "CallbackQuestion": "Kiedy mamy do Ciebie zadzwonić?",
      "CallbackDayLabels": [
        "niedziela",
        "poniedziałek",
        "wtorek",
        "środa",
        "czwartek",
        "piątek",
        "sobota"
      ],
      "CallbackMonthLabels": [
        "sty",
        "lut",
        "mar",
        "kwi",
        "maj",
        "cze",
        "lip",
        "sie",
        "wrz",
        "paź",
        "Lis",
        "gru"
      ],
      "CallbackConfirmDescription": "Rezerwacja gotowa!",
      "CallbackNumberDescription": "Zadzwonimy do Ciebie pod podanym numerem:",
      "CallbackNotes": "Uwagi",
      "CallbackDone": "Zamknij",
      "AriaCallbackDone": "Zamknij",
      "CallbackOk": "OK",
      "AriaCallbackOk": "OK",
      "CallbackCloseConfirm": "Czy na pewno chcesz anulować planowanie tego oddzwonienia?",
      "CallbackNoButtonText": "Nie",
      "AriaCallbackNoButtonText": "Nie",
      "CallbackYesButtonText": "Tak",
      "AriaCallbackYesButtonText": "Tak",
      "CallbackWaitTime": "Czas oczekiwania",
      "CallbackWaitTimeText": "min oczekiwania",
      "CallbackOptionASAP": "Jak najszybciej",
      "CallbackOptionPickDateTime": "Wybierz datę i godzinę",
      "AriaCallbackOptionPickDateTime": "Otwiera wybór daty",
      "CallbackPlaceholderCalendar": "Wybierz datę i godzinę",
      "AriaMinimize": "Minimalizuj Oddzwanianie",
      "AriaWindowLabel": "Okno Oddzwanianie",
      "AriaMaximize": "Maksymalizuj Oddzwanianie",
      "AriaClose": "Zamknij Oddzwanianie",
      "AriaCalendarClosedStatus": "Kalendarz jest zamknięty",
      "Errors": {
        "501": "Nie można zaakceptować nieprawidłowych parametrów. Sprawdź prawidłowe parametry w dokumentacji API serwera obsługującego.",
        "503": "Brak klucza API. Skonfiguruj go prawidłowo.",
        "1103": "Brak klucza API. Skonfiguruj go prawidłowo.",
        "7030": "Wprowadź prawidłowy numer telefonu.",
        "7036": "Oddzwonienie na ten numer jest niemożliwe. Spróbuj ponownie, używając innego numeru telefonu.",
        "7037": "Oddzwonienie na ten numer jest niedozwolone. Spróbuj ponownie, używając innego numeru telefonu.",
        "7040": "Ustaw prawidłową nazwę usługi.",
        "7041": "Zbyt wiele żądań w tym momencie.",
        "7042": "Biuro nieczynne. Spróbuj zaplanować w godzinach pracy.",
        "unknownError": "Wystąpił problem. Przepraszamy za niedogodność. Sprawdź ustawienia połączenia i spróbuj ponownie.",
        "phoneNumberRequired": "Numer telefonu jest wymagany."
      }
    },
    "channelselector": {
      "Title": "Asysta na żywo",
      "SubTitle": "Jak chcesz się skontaktować?",
      "WaitTimeTitle": "Czas oczekiwania",
      "AvailableTitle": "Dostępne",
      "AriaAvailableTitle": "Dostępne",
      "UnavailableTitle": "Niedostępne",
      "CobrowseButtonText": "CobrowseSubTitle",
      "CallbackTitle": "Odbierz połączenie",
      "CobrowseSubTitle": "To wymaga połączenia z agentem.",
      "AriaClose": "Zamknij Pomoc na żywo",
      "AriaWarning": "Ostrzeżenie",
      "AriaAlert": "Alarm",
      "minute": "min",
      "minutes": "min",
      "AriaWindowLabel": "Okno Pomoc na żywo"
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "Imię",
      "PlaceholderRequired": "Wymagane",
      "PlaceholderOptional": "Opcjonalnie",
      "LastName": "Nazwisko",
      "PhoneNumber": "Telefon",
      "WaitTime": "Czas oczekiwania",
      "FormCancel": "Anuluj",
      "AriaFormCancel": "Anuluj",
      "FormSubmit": "Poproś o numer",
      "AriaFormSubmit": "Poproś o numer",
      "PhoneLabel": "Połącz się teraz",
      "AriaPhoneTitle": "Otwiera aplikację telefoniczną",
      "AccessLabel": "Kod dostępu",
      "ExpireLabel": "Numer wygasa za",
      "AriaExpireLabel": "Licznik Numer wygasa za",
      "DisplayClose": "Zamknij",
      "AriaDisplayClose": "Zamknij",
      "NetworkFail": "Wystąpił problem. Przepraszamy za niedogodność. Sprawdź ustawienia połączenia i spróbuj ponownie.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Numer telefonu wygasł.",
      "PhoneReRequest": "Poproś o nowy numer",
      "AriaPhoneReRequest": "Poproś o nowy numer",
      "LocalFormValidationEmptyPhoneNumber": "Wprowadź numer telefonu",
      "ConfirmCloseWindow": "Formularz zawiera nieprzesłane dane. Czy na pewno chcesz zamknąć?",
      "AriaConfirmCloseCancel": "Nie",
      "ConfirmCloseCancel": "Nie",
      "AriaConfirmCloseConfirm": "Tak",
      "ConfirmCloseConfirm": "Tak",
      "AriaWindowLabel": "Okno Kliknij, aby zadzwonić",
      "AriaMaximize": "Maksymalizuj Kliknij, aby zadzwonić",
      "AriaMinimize": "Minimalizuj Kliknij, aby zadzwonić",
      "AriaClose": "Zamknij Kliknij, aby zadzwonić"
    },
    "cobrowse": {
      "agentJoined": "Agent dołączył do sesji.",
      "youLeft": "Opuszczasz sesję. Współprzeglądanie zostanie teraz zakończone.",
      "sessionTimedOut": "Sesja przekroczyła limit czasu. Współprzeglądanie zostanie teraz zakończone.",
      "sessionInactiveTimedOut": "Sesja przekroczyła limit czasu. Współprzeglądanie zostanie teraz zakończone.",
      "agentLeft": "Agent opuścił sesję. Współprzeglądanie zostanie teraz zakończone.",
      "sessionError": "Wystąpił nieoczekiwany błąd. Współprzeglądanie zostanie teraz zakończone.",
      "sessionStarted": "Rozpoczęto sesję współprzeglądania. Oczekiwanie na dołączenie agenta do sesji…",
      "navRefresh": "Agent odświeżył stronę. Ponowne ładowanie.",
      "navBack": "Agent nacisnął przycisk Wstecz. Ponowne ładowanie strony.",
      "navForward": "Agent nacisnął przycisk Dalej. Ponowne ładowanie strony.",
      "navUrl": "Agent zażądał nawigacji. Ponowne ładowanie strony.",
      "navFailed": "Żądanie nawigacji zgłoszone przez agenta nie powiodło się.",
      "toolbarContent": "ID sesji: {sessionId}",
      "contentMasked": "Treść jest ukryta przed agentem.",
      "contentMaskedPartially": "Część treści jest ukryta przed agentem.",
      "exitBtnTitle": "Wyjdź z sesji współprzeglądania",
      "areYouOnPhone": "Czy rozmawiasz z naszym agentem przez telefon?",
      "areYouOnPhoneOrChat": "Czy rozmawiasz z naszym agentem przez telefon lub czat?",
      "connectBeforeCobrowse": "Aby współprzeglądać, konieczne jest połączenie z naszym agentem. Zadzwoń do nas lub rozpocznij czat na żywo, a następnie ponownie zacznij współprzeglądanie.",
      "sessionStartedAutoConnect": "Rozpoczęto sesję współprzeglądania. Oczekiwanie na dołączenie agenta do sesji…",
      "browserUnsupported": "Twoja przeglądarka nie jest obecnie obsługiwana.<br><br> Obsługiwane przeglądarki: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 i nowsza</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 i nowsza</a></li></ul>",
      "chatIsAlreadyRunning": "Czat już trwa na innej stronie.",
      "modalYes": "Tak",
      "modalNo": "Nie"
    },
    "knowledgecenter": {
      "SidebarButton": "Szukaj",
      "SearchButton": "Szukaj",
      "Title": "Zadaj pytanie",
      "Ask": "Zapytaj",
      "AriaAsk": "Zapytaj",
      "Close": "Zamknij",
      "AriaClose": "Zamknij Wyszukaj",
      "Categories": "Kategorie",
      "NoResults": "Brak wyników",
      "NoResultsTextUnder": "Niestety nie możemy znaleźć odpowiedniej odpowiedzi.",
      "NoResultsTextRephrase": "Czy możesz inaczej sformułować pytanie?",
      "WasThisHelpful": "Czy to było przydatne?",
      "Yes": "Tak",
      "No": "Nie",
      "AriaYes": "Tak",
      "AriaNo": "Nie",
      "ArticleHelpfulnessYes": "Przydatność artykułu — Tak",
      "ArticleHelpfulnessYesDesc": "Wspaniale! Cieszy nas, że artykuł pomógł w znalezieniu informacji. Miłego dnia!.",
      "ArticleHelpfulnessNo": "Przydatność artykułu — Nie",
      "ArticleHelpfulnessNoDesc": "Przykro nam, że artykuł nie okazał się przydatny. Dziękujemy za opinię!",
      "TypeYourQuestion": "Wpisz pytanie",
      "Back": "Wstecz",
      "AriaBack": "Powrót do Wyników wyszukiwania",
      "More": "Więcej",
      "Error": "Błąd!",
      "GKCIsUnavailable": "Serwer centrum wiedzy jest obecnie niedostępny.",
      "AriaClear": "Wyczyść wyszukiwany tekst",
      "AriaSearch": "Szukaj",
      "AriaWindowLabel": "Okno Wyszukaj",
      "AriaSearchDropdown": "Sugerowane wyniki",
      "AriaSearchMore": "Dodatkowe informacje",
      "AriaResultsCount": "Wyniki łącznie",
      "KnowledgeAgentName": "Centrum wiedzy",
      "WelcomeMessage": "Dzień dobry! Wkrótce dołączy do Ciebie agent. Czy w międzyczasie mogę pomóc w znalezieniu odpowiedzi na Twoje pytania? Wpisz pytanie w poniższym polu.",
      "SearchResult": "Podczas oczekiwania na połączenie z agentem zapoznaj się z najlepszymi odpowiedziami na Twoje pytanie:",
      "NoDocumentsFound": "Niestety żaden artykuł nie pasuje do Twojego pytania. Czy chcesz zadać inne pytanie?",
      "SuggestedMessage": "Proponowana pozycja centrum wiedzy:",
      "OpenDocumentHint": "kliknij, aby wyświetlić treść",
      "SuggestedDocumentMessage": "Zaproponowano dokument '<%DocTitle%>'",
      "FeedbackQuestion": "Czy to było przydatne?",
      "FeedbackAccept": "Tak",
      "FeedbackDecline": "Nie",
      "TranscriptMarker": "Centrum wiedzy: ",
      "SearchMessage": "Wyszukaj przy użyciu zapytania '<%SearchQuery%>'↲",
      "VisitMessage": "Wyświetlenie dokumentu '<%VisitQuery%>'",
      "OpenMessage": "Wyświetlono '<%OpenQuery%>'",
      "AnsweredMessage": "Wyniki zapytania '<%AnsweredQuery%>' oznaczono jako istotne.",
      "UnansweredMessage": "Wyniki zapytania '<%UnansweredQuery%>' oznaczono jako bez odpowiedzi.",
      "PositiveVoteMessage": "Pozytywne głosowanie na dokument '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negatywne głosowanie na dokument '<%VoteQuery%>'.",
      "SuggestedMarker": "Zaproponowano dokument[^\\0]*?"
    },
    "sendmessage": {
      "SendMessageButton": "Wyślij wiadomość",
      "OK": "OK",
      "Title": "Wyślij wiadomość",
      "PlaceholderFirstName": "Wymagane",
      "PlaceholderLastName": "Wymagane",
      "PlaceholderEmail": "Wymagane",
      "PlaceholderSubject": "Wymagane",
      "PlaceholderTypetexthere": "Tutaj wpisz wiadomość...",
      "FirstName": "Imię",
      "LastName": "Nazwisko",
      "Email": "E-mail",
      "Subject": "Temat",
      "Attachfiles": "Załącz pliki",
      "AriaAttachfiles": "Dołącz łącze do plików, Otwiera okno dialogowe przesyłania plików",
      "Send": "Wyślij",
      "AriaSend": "Wyślij wiadomość",
      "Sent": "Twoja wiadomość została wysłana...",
      "Close": "Zamknij",
      "ConfirmCloseWindow": "Czy na pewno chcesz zamknąć widżet wysyłania wiadomości?",
      "Cancel": "Anuluj",
      "AriaMinimize": "Minimalizuj Wyślij wiadomość",
      "AriaMaximize": "Maksymalizuj Wyślij wiadomość",
      "AriaWindowLabel": "Okno Wyślij wiadomość",
      "AriaClose": "Zamknij Wyślij wiadomość",
      "AriaCloseAlert": "Pole alertów jest zamknięte",
      "AriaEndConfirm": "Tak",
      "AriaEndCancel": "Anuluj",
      "AriaOK": "OK",
      "AriaRemoveFile": "Usuń plik",
      "AriaFileIcon": "Plik",
      "AriaFileSize": "Rozmiar pliku",
      "Errors": {
        "102": "Wymagane imię",
        "103": "Wymagane nazwisko",
        "104": "Temat jest wymagany",
        "181": "Wymagany adres e-mail",
        "182": "Wymagana treść wiadomości tekstowej",
        "connectionError": "Nie można połączyć się z serwerem. Spróbuj ponownie później.",
        "unknowError": "Wystąpił problem. Przepraszamy za niedogodność. Sprawdź ustawienia połączenia i spróbuj ponownie.",
        "attachmentsLimit": "Łączna liczba załączników przekracza limit: ",
        "attachmentsSize": "Łączny rozmiar załączników przekracza limit: ",
        "invalidFileType": "Nieobsługiwany typ pliku. Prześlij obrazy, pliki PDF, tekstowe i archiwa ZIP.",
        "invalidFromEmail": "Nieprawidłowy adres e-mail nadawcy — Od.",
        "invalidMailbox": "Nieprawidłowy adres e-mail adresata — Do."
      }
    },
    "sidebar": {
      "SidebarTitle": "Potrzebujesz pomocy?",
      "ChannelSelectorName": "Asysta na żywo",
      "ChannelSelectorTitle": "Uzyskaj natychmiastową pomoc od jednego z naszych agentów",
      "SearchName": "Szukaj",
      "SearchTitle": "Szukaj",
      "OffersName": "Oferty",
      "OffersTitle": "Oferty",
      "CallUsName": "Zadzwoń do nas",
      "CallUsTitle": "Zadzwoń do nas — szczegóły",
      "CallbackName": "Oddzwanianie",
      "CallbackTitle": "Odbierz połączenie",
      "ClickToCallName": "ClickToCall",
      "ClickToCallTitle": "Poproś o numer telefonu działu obsługi klienta",
      "SendMessageName": "Wyślij wiadomość",
      "SendMessageTitle": "Wyślij wiadomość",
      "WebChatName": "Czat na żywo",
      "WebChatTitle": "Czat na żywo",
      "AriaClose": "Zamknij menu Potrzebujesz pomocy"
    },
    "webchat": {
      "ChatButton": "Czat",
      "ChatStarted": "Rozpoczęto czat",
      "ChatEnded": "Zakończono czat",
      "AgentNameDefault": "Agent",
      "AgentConnected": "<%Agent%> Podłączony",
      "AgentDisconnected": "<%Agent%> Rozłączony",
      "BotNameDefault": "Obie wartości",
      "BotConnected": "<%Bot%> połączony",
      "BotDisconnected": "<%Bot%> rozłączony",
      "SupervisorNameDefault": "Kierownik",
      "SupervisorConnected": "<%Agent%> połączony",
      "SupervisorDisconnected": "<%Agent%> rozłączony",
      "AgentTyping": "...",
      "AriaAgentTyping": "Agent pisze",
      "AgentUnavailable": "Brak dostępnych agentów. Spróbuj ponownie później.",
      "ChatTitle": "Czat na żywo",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Minimalizuj",
      "ChatFormFirstName": "Imię",
      "ChatFormLastName": "Nazwisko",
      "ChatFormNickname": "Pseudonim",
      "ChatFormEmail": "E-mail",
      "ChatFormSubject": "Temat",
	    "ChatFormName": "Imię",
	    "ChatFormPlaceholderName": "Opcjonalnie",
	    "ChatFormCustomerId": "Numer klienta",
	    "ChatFormPlaceholderCustomerId": "Opcjonalnie",
	    "ChatFormSubjectPassword": "Hasło",
	    "ChatFormSubjectService": "Usługa",
	    "ChatFormSubjectOrder": "Zamówienie",
	    "ChatFormSubjectFinance": "Finanse",
      "ChatFormPlaceholderFirstName": "Wymagane",
      "ChatFormPlaceholderLastName": "Wymagane",
      "ChatFormPlaceholderNickname": "Opcjonalnie",
      "ChatFormPlaceholderEmail": "Wymagane",
      "ChatFormPlaceholderSubject": "Opcjonalnie",
      "ChatFormSubmit": "Rozpocznij czat",
      "AriaChatFormSubmit": "Rozpocznij czat",
      "ChatFormCancel": "Anuluj",
      "AriaChatFormCancel": "Anuluj czat",
      "ChatFormClose": "Zamknij",
      "ChatInputPlaceholder": "Tutaj wpisz swoją wiadomość",
      "ChatInputSend": "Wyślij",
      "AriaChatInputSend": "Wyślij",
      "ChatEndQuestion": "Czy na pewno chcesz zakończyć tę sesję czatu?",
      "ChatEndCancel": "Anuluj",
      "ChatEndConfirm": "Zakończ czat",
      "AriaChatEndCancel": "Anuluj",
      "AriaChatEndConfirm": "Zakończ",
      "ConfirmCloseWindow": "Czy na pewno chcesz zamknąć czat?",
      "ConfirmCloseCancel": "Anuluj",
      "ConfirmCloseConfirm": "Zamknij",
      "AriaConfirmCloseCancel": "Anuluj",
      "AriaConfirmCloseConfirm": "Zamknij",
      "ActionsDownload": "Pobierz transkrypcję",
      "ActionsEmail": "Wyślij transkrypcję pocztą e-mail",
      "ActionsPrint": "Drukuj transkrypcję",
      "ActionsCobrowseStart": "Rozpocznij współprzeglądanie",
      "AriaActionsCobrowseStartTitle": "Otwiera sesję wspólnego przeglądania",
      "ActionsSendFile": "Załącz pliki",
      "AriaActionsSendFileTitle": "Dołącz łącze do plików, Otwiera okno dialogowe przesyłania plików",
      "ActionsEmoji": "Wyślij Emoji",
      "ActionsCobrowseStop": "Wyjdź z współprzeglądania",
      "ActionsVideo": "Zaproś do czatu wideo",
      "ActionsTransfer": "Przełącz",
      "ActionsInvite": "Zaproś",
      "InstructionsTransfer": "Otwórz to łącze na innym urządzeniu, aby przełączyć sesję czatu</br></br><%link%>",
      "InstructionsInvite": "Udostępnij to łącze innej osobie, aby dodać ją do tej sesji czatu</br></br><%link%>",
      "InviteTitle": "Potrzebujesz pomocy?",
      "InviteBody": "Daj nam znać, jeśli możemy pomóc.",
      "InviteReject": "Nie, dziękuję",
      "InviteAccept": "Rozpocznij czat",
      "AriaInviteAccept": "Zaakceptuj zaproszenie i rozpocznij czat",
      "AriaInviteReject": "Odrzuć zaproszenie",
      "ChatError": "Wystąpił problem podczas rozpoczynania sesji czatu. Spróbuj ponownie.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Tak",
      "ChatErrorDefaultButton": "Nie",
      "AriaChatErrorPrimaryButton": "Tak",
      "AriaChatErrorDefaultButton": "Nie",
      "DownloadButton": "Pobierz",
      "AriaDownloadButton": "Pobierz plik",
      "FileSent": "wysłał:",
      "FileTransferRetry": "Ponów",
      "AriaFileTransferRetry": "Ponownie spróbuj przesłać plik",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Anuluj",
      "AriaFileTransferCancel": "Anuluj przesyłanie pliku",
      "RestoreTimeoutTitle": "Zakończono czat",
      "RestoreTimeoutBody": "Twoja poprzednia sesja czatu przekroczyła limit czasu. Czy chcesz rozpocząć nowy?",
      "RestoreTimeoutReject": "Nie, dziękuję",
      "RestoreTimeoutAccept": "Rozpocznij czat",
      "AriaRestoreTimeoutAccept": "Zaakceptuj zaproszenie i rozpocznij czat",
      "AriaRestoreTimeoutReject": "Odrzuć zaproszenie",
      "EndConfirmBody": "Czy na pewno chcesz zakończyć sesję czatu?",
      "EndConfirmAccept": "Zakończ czat",
      "EndConfirmReject": "Anuluj",
      "AriaEndConfirmAccept": "Zakończ czat",
      "AriaEndConfirmReject": "Anuluj",
      "SurveyOfferQuestion": "Czy chciałbyś wziąć udział w ankiecie?",
      "ShowSurveyAccept": "Tak",
      "ShowSurveyReject": "Nie",
      "AriaShowSurveyAccept": "Tak",
      "AriaShowSurveyReject": "Nie",
      "UnreadMessagesTitle": "anuluj czytanie",
      "AriaYouSaid": "Powiedziałeś",
      "AriaSaid": "powiedział",
      "AriaSystemSaid": "System powiedział",
      "AriaWindowLabel": "Okno Czat na żywo",
      "AriaMinimize": "Czat na żywo - minimalizuj",
      "AriaMaximize": "Czat na żywo - maksymalizuj",
      "AriaClose": "Czat na żywo - zamknij",
      "AriaEmojiStatusOpen": "Okno dialogowe wyboru emoji jest otwarte",
      "AriaEmojiStatusClose": "Okno dialogowe wyboru emoji jest zamknięte",
      "AriaEmoji": "emoji",
      "AriaEmojiPicker": "Wybór emoji",
      "AriaCharRemaining": "Pozostałe znaki",
      "AriaMessageInput": "Pole komunikatów",
      "AsyncChatEnd": "Zakończ czat",
      "AsyncChatClose": "Zamknij okno",
      "AriaAsyncChatEnd": "Zakończ czat",
      "AriaAsyncChatClose": "Zamknij okno",
      "DayLabels": [
        "nie",
        "pon",
        "wto",
        "śro",
        "czw",
        "pt",
        "sob"
      ],
      "MonthLabels": [
        "sty",
        "lut",
        "mar",
        "kwi",
        "maj",
        "cze",
        "lip",
        "sie",
        "wrz",
        "paź",
        "Lis",
        "gru"
      ],
      "todayLabel": "Dziś",
      "Errors": {
        "102": "Imię jest wymagane",
        "103": "Nazwisko jest wymagane",
        "161": "Wpisz imię i nazwisko",
        "201": "Nie można wysłać pliku.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Maksymalna liczba załączonych plików zostałaby przekroczona (<%MaxFilesAllowed%>)</p>",
        "202": "Nie można wysłać pliku.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Limit przesyłu i/lub maksymalna liczba załączników zostałaby przekroczone (<%MaxAttachmentsSize%>)</p>",
        "203": "Nie można wysłać pliku.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Typ pliku jest niedozwolony.</p>",
        "204": "Twoja wiadomość jest za długa. Napisz krótszą wiadomość",
        "240": "Obecnie nie możemy rozpocząć nowego czatu. Spróbuj ponownie później",
        "364": "Nieprawidłowy adres e-mail",
        "401": "Nie można uwierzytelnić tej sesji czatu. Czy rozpocząć nowy czat?",
        "404": "Nie można znaleźć poprzedniej sesji czatu. Czy rozpocząć nowy czat?",
        "500": "Wystąpił nieoczekiwany błąd usługi. Czy zamknąć i uruchomić nową sesję czatu?",
        "503": "Usługa jest aktualnie niedostępna lub zajęta. Czy zamknąć i ponownie uruchomić nową sesję czatu?",
        "ChatUnavailable": "Obecnie nie możemy rozpocząć nowego czatu. Spróbuj ponownie później",
        "CriticalFault": "Twoja sesja czatu zakończyła się niespodziewanie z powodu nieznanego problemu. Przepraszamy za niedogodności",
        "StartFailed": "Wystąpił problem z rozpoczęciem sesji czatu. Sprawdź połączenie i czy prawidłowo przesłano wszystkie wymagane informacje, a następnie spróbuj ponownie",
        "MessageFailed": "Twoja wiadomość nie została odebrana. Spróbuj ponownie",
        "RestoreFailed": "Niestety nie możemy przywrócić Twojej sesji czatu z powodu nieznanego błędu",
        "TransferFailed": "Obecnie nie można przełączyć czatu. Spróbuj ponownie później",
        "FileTransferSizeError": "Nie można wysłać pliku.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Rozmiar pliku jest większy niż dozwolony rozmiar (<%MaxSizePerFile%>)</p>",
        "InviteFailed": "Obecnie nie można wygenerować zaproszenia. Spróbuj ponownie później",
        "ChatServerWentOffline": "Przesłanie wiadomości obecnie zajmuje więcej czasu niż zwykle. Przepraszamy za opóźnienie",
        "RestoredOffline": "Przesłanie wiadomości obecnie zajmuje więcej czasu niż zwykle. Przepraszamy za opóźnienie",
        "Disconnected": "<div style='text-align:center'>Utracono połączenie</div>",
        "Reconnected": "<div style='text-align:center'>Przywrócono połączenie</div>",
        "FileSendFailed": "Nie można wysłać pliku.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>Nastąpiło nieoczekiwane rozłączenie. Spróbować ponownie?</p>",
        "Generic": "<div style='text-align:center'>Wystąpił nieoczekiwany błąd</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Nieprawidłowy typ pliku. Dozwolone są tylko obrazy",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "Rozmiar pliku jest większy niż dozwolony",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "Maksymalna liczba załączonych plików przekroczyła limit",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Nieprawidłowa konfiguracja transportu klucza x-api-key",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Nieprawidłowa konfiguracja transportu punktu końcowego",
        "pureengage-v3-rest-INVALID_NICKNAME": "Imię jest wymagane",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "Nie można uwierzytelnić tej sesji czatu.",
        "purecloud-v2-sockets-400": "Wystąpił błąd. Sprawdź połączenie i czy prawidłowo przesłano wszystkie wymagane informacje, a następnie spróbuj ponownie.",
        "purecloud-v2-sockets-500": "Niestety w usłudze wystąpił nieoczekiwany błąd.",
        "purecloud-v2-sockets-503": "Przepraszamy, usługa jest obecnie niedostępna."
      }
    }
  }
}