import { put, takeLatest, call } from 'redux-saga/effects';
import _get from 'lodash/get';
import getLocalization from '../interfaces/Localization/LocalizationApi';

import {
    GET_LANGUAGE_REQUEST,
    GET_LANGUAGE_SUCCESS,
    GET_LANGUAGE_ERROR
} from '../actionTypes/SelectLanguageActionTypes';

export function* getLanguageSaga() {
    try {
        const result = yield call(getLocalization);

        if (result.isSuccess) {
            const data = _get(result, 'data.stores', []);
            yield put({ type: GET_LANGUAGE_SUCCESS, data });
        } else {
            yield put({ type: GET_LANGUAGE_ERROR });
        }
    } catch (err) {
        yield put({ type: GET_LANGUAGE_ERROR });
    }
}

export default function* watchSelectLanuageRequest() {
    yield takeLatest(GET_LANGUAGE_REQUEST, getLanguageSaga);
}
