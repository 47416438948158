import _get from 'lodash/get';

export const createClaimFillteredData = invoicesData => {
    const subjectsList = [];
    const { reasonCodes } = invoicesData;
    const reasonCodesArray = _get(reasonCodes, 'entry', []);
    for (let i = 0; i < reasonCodesArray.length; i += 1) {
        const subjectsObj = {
            id: i,
            value: reasonCodesArray[i].key,
            label: reasonCodesArray[i].value
        };
        subjectsList.push(subjectsObj);
    }

    const { totalCheckedAmount, currency, email } = invoicesData;
    const createClaimData = {
        subjectsList,
        totalCheckedAmount,
        currency,
        email
    };
    return createClaimData;
};
