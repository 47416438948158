import {
    GET_USER_MAINTENANCE_SUCCESS,
    EDIT_USER_ACCOUNT_SUCCESS,
    EDIT_USER_ACCOUNT_ERROR,
    RESET_EDIT_SUCCESS,
    CLEAR_REDUCER,
    GET_USER_MAINTENANCE_ERROR
} from '../actionTypes/UserMaintenanceActionTypes';

const initialState = {
    isSuccess: false
};

export default function UserMaintenanceReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_USER_MAINTENANCE_SUCCESS:
            return {
                ...state,
                data: action.data,
                isSuccess: action.isSuccess
            };
        case GET_USER_MAINTENANCE_ERROR:
            const { error = {} } = action.data || {};

            return {
                ...state,
                message: {
                    isSuccess: false,
                    error
                }
            };
        case EDIT_USER_ACCOUNT_SUCCESS:
            return {
                ...state,
                editSuccess: action.isSuccess
            };
        case EDIT_USER_ACCOUNT_ERROR:
            return {
                ...state,
                editSuccess: action.isSuccess
            };
        case RESET_EDIT_SUCCESS:
            return {
                ...state,
                editSuccess: false
            };
        case CLEAR_REDUCER:
            return initialState
        default:
            return state;
    }
}
