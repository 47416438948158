import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import { HOME_L10N } from '../../../common/Constants';
import { getUserInfo, setUserInfo, getCountry } from '../../../common/UserManager/UserManager';
import { redirectTo, findComponent } from '../../../common/Utils/Utils';
import { setMarketingCommunicationChannels } from '../../../actions/MarketingAction';
import { notifyBlueMessage } from '../../../actions/NotificationAction';

import * as styles from './MarketingConsentPopup.css';

const cx = classNames.bind(styles);

const MarketingConsentPopup = ({ localization, bannerImages, hideConsentPopUp}) => {

    const dispatch = useDispatch();
    const { displayExplicitPopup } = getUserInfo();
    const [showPopup, setShowPopup] = useState(displayExplicitPopup);
    const [emailChecked, setEmailChecked] = useState(true);
    const [smsChecked, setSmsChecked] = useState(true);

    const title = findComponent(bannerImages, HOME_L10N.MARKETING_CONSENT_POPUP_TITLE);
    const subtitle = findComponent(bannerImages, HOME_L10N.MARKETING_CONSENT_POPUP_SUBTITLE);
    const customerSupportText = findComponent(bannerImages, HOME_L10N.MARKETING_CONSENT_POPUP_SUPPORTTEXT);
    const customerSupportPhone = findComponent(bannerImages, HOME_L10N.MARKETING_CONSENT_POPUP_SUPPORTNUMBER);
    const subscribeComponent = findComponent(bannerImages, HOME_L10N.MARKETING_CONSENT_BANNER_SUBSCRIBE_BTN);
    const hideOptionsClass = getCountry() === 'Poland' ?'' :'hide-options';

    const agreeOnConsent = () => {
        const userInfo = {...getUserInfo(), displayExplicitPopup: true};
        setUserInfo(userInfo);
        setShowPopup(false);
        hideConsentPopUp();
        dispatch(setMarketingCommunicationChannels({
            emailOption: emailChecked,
            smsOption: smsChecked})
        );
        dispatch(notifyBlueMessage({message: localization.subscribeNotification}));
    };

    const redirectToManagePreferences = () => {
        hideConsentPopUp();
        redirectTo('/account/personaldata');
    };

    const closePopup = () => {
        setShowPopup(false);
        hideConsentPopUp();
    };

    if (showPopup) {
        return (
            <div className={cx('contactUsModal')}>
                <Modal
                    showModal={showPopup}
                    onClose={() => closePopup()}
                    modalTheme={cx('userThemeModal')}
                    modalTitleTheme={cx('modalTitleTheme')}
                    themeContent={cx('themeContent')}
                    themeClose={cx('themeClose')}
                    themeModalBlock={cx('themeModalBlock')} 
                    defaultCloseBtn
                >
                    <div className={cx('clearContent')}>
                        <div className={cx('contentText')}>
                            <h1>{title?.content}</h1>
                            <p>
                                {subtitle?.content}
                            </p>
                        </div>
                        <div className={cx('input-fields')}>
                            <div className={cx('left-fields')}>
                                <label htmlFor="email">{localization?.emailLabel}</label>
                                <input type="email" name="email" id="email" disabled placeholder={getUserInfo().email}/>
                                <div className={cx(hideOptionsClass)}>
                                    <input type="checkbox" name="emailsAgree" id="emailsAgree" defaultChecked={emailChecked} onChange={() => setEmailChecked(!emailChecked)} />
                                    <label htmlFor="emailsAgree">{localization?.emailAgreeButton}</label>
                                </div>
                            </div>
                            <div className={cx('right-fields')}>
                                <label htmlFor="tel">{localization?.mobile}</label>
                                <input type="tel" name="tel" id="tel" disabled placeholder=''/>
                                <div className={cx(hideOptionsClass)}>
                                    <input type="checkbox" name="smsAgree" id="smsAgree" defaultChecked={smsChecked} onChange={() => setSmsChecked(!smsChecked)} />
                                    <label htmlFor="smsAgree">{localization?.smsAgreeButton}</label>
                                </div>
                            </div>
                        </div>
                        <div className={cx('bottomBtns')}>
                            <Button
                                type='button'
                                className={cx('userBtns', 'managePreferencesButton')}
                                onClick={redirectToManagePreferences}
                            >
                                {localization?.managePreferencesButton}
                            </Button>
                            <Button
                                type='button'
                                size={cx('Sm')}
                                className={cx('userBtns', 'agreeButton')}
                                onClick={agreeOnConsent}
                            >
                                {localization?.btnSubscribe}
                            </Button>
                        </div>
                        <div className={cx('contentText')}>
                            {customerSupportText?.content}
                            <a
                                className={cx('phoneLink')}
                                automation-id='at-phone-number-link'
                            >
                                &nbsp; {customerSupportPhone?.content}
                            </a>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
    return null;
};

export default MarketingConsentPopup;