import React from 'react';
import classNames from 'classnames/bind';
import * as styles from './clearAllCartsModal.css';
import { CART_L10N } from '../../../../common/Constants';
import Button from '../../../core/Button/Button';
import Modal from '../../../core/Modal/Modal';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

export const ClearAllCartsModal = props => {
    const { showModal, onOk, onCancel, localization, isFromMiniCart } = props;

    const onCancelClear = e => {
        onCancel(e);
        if (isFromMiniCart) {
            buttonClickEvent(
                buttonClickEventConfigs.minicart.actions.clearAllPopUp,
                buttonClickEventConfigs.minicart.labels.clearAllPopUpCancel,
                buttonClickEventConfigs.minicart.categories.minicart
            );
        }
    };

    return (
        <Modal
            showModal={showModal}
            modalTheme={cx('themeModal')}
            modalTitleTheme={cx('modalTitleTheme')}
            themeContent={cx('themeContent')}
            themeClose={cx('themeClose')}
            themeModalBlock={cx('themeModalBlock')}
            title={localization[CART_L10N.CLEAR_ALL_CART]}
            defaultCloseBtn={false}
        >
            <div className={cx('clearContent')}>
                <p>{localization[CART_L10N.CLEAR_ALL_CONFIRM]}</p>
                <div className={cx('bottomBtns')}>
                    <Button
                        type='cancel'
                        size={cx('Sm')}
                        className={cx('cartsBtns', 'hiddenSm', 'cancelBtn')}
                        onClick={onCancelClear}
                    >
                        {localization[CART_L10N.CANCEL]}
                    </Button>
                    <Button
                        type='submit'
                        buttonType={cx('Primary')}
                        size={cx('Md')}
                        className={cx('cartsBtns', 'clearAll')}
                        onClick={onOk}
                    >
                        {localization[CART_L10N.CLEAR_ALL]}
                    </Button>
                    <Button
                        type='cancel'
                        size={cx('Sm')}
                        className={cx('cartsBtns', 'hiddenMd', 'hiddenXl', 'hiddenLg', 'cancelBtn')}
                        onClick={onCancelClear}
                    >
                        {localization[CART_L10N.CANCEL]}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ClearAllCartsModal;
