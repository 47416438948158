import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { AUTH, PARAMS } from '../common/Api';

export default class NotificationApi {
    static getCutOffNotifications(payload) {
        const { baseInfo = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}/notification`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }
}
