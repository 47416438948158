import {
    GET_INVOICES_REQUEST,
    CREATE_CLAIM_REQUEST,
    GET_FILTER_INVOICES_REQUEST,
    SUMBIT_CLAIM_REQUEST,
    GET_INVOICE_PDF_REQUEST,
    RESET_CLAIMS,
    CLEAR_INVOICES_PDF_DATA,
    RESET_INVOICES
} from '../actionTypes/InvoicesActionTypes';

export const getInvoicesDetails = payload => ({
    type: GET_INVOICES_REQUEST,
    payload
});

export const createClaim = payload => ({
    type: CREATE_CLAIM_REQUEST,
    payload
});

export const getFilterInvoicesDetails = payload => ({
    type: GET_FILTER_INVOICES_REQUEST,
    payload
});

export const submitClaim = payload => ({
    type: SUMBIT_CLAIM_REQUEST,
    payload
});

export const getInvoicePDF = payload => ({
    type: GET_INVOICE_PDF_REQUEST,
    payload
});

export const resetClaims = payload => ({
    type: RESET_CLAIMS,
    payload
});

export const clearInvoicesPDFData = payload => {
    return {
        type: CLEAR_INVOICES_PDF_DATA,
        payload
    };
};

export const resetInvoiceDetails = payload => ({
    type: RESET_INVOICES,
    payload
});
