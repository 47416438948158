export const fr = {
  "fr": {
    "calendar": {
      "CalendarDayLabels": [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi"
      ],
      "CalendarMonthLabels": [
        "janv.",
        "févr.",
        "mars",
        "avr.",
        "mai",
        "juin",
        "juil.",
        "août",
        "sept",
        "oct.",
        "nov.",
        "déc."
      ],
      "CalendarLabelToday": "Aujourd'hui",
      "CalendarLabelTomorrow": "Demain",
      "CalendarTitle": "Planifier un appel",
      "CalendarOkButtonText": "OK",
      "CalendarError": "Impossible de récupérer les détails de disponibilité.",
      "CalendarClose": "Annuler",
      "AriaWindowTitle": "Fenêtre Calendrier",
      "AriaCalendarClose": "Annuler le calendrier et retourner à l'inscription du rappel",
      "AriaYouHaveChosen": "Vous avez choisi",
      "AriaNoTimeSlotsFound": "Aucun créneau horaire trouvé pour la date sélectionnée"
    },
    "callus": {
      "CallUsTitle": "Nous appeler",
      "ContactsHeader": "Vous pouvez nous contacter à l'un des numéros suivants...",
      "CancelButtonText": "Annuler",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Déjà en ligne ? Laissez-nous commencer <a role='link' tabindex='0' class='cx-cobrowse-link'>une session de co-navigation</a></span>",
      "CoBrowse": "Initier la co-navigation",
      "CoBrowseWarning": "La fonction de co-navigation (Co-browse) permet à l'agent de voir et de contrôler votre bureau pour mieux vous guider. Lors d'un appel en direct avec un agent, demandez un code pour initier une session et entrez-le ci-dessous. Pas d'appel en cours ? Quittez cet écran pour revenir à la page Nous appeler.",
      "SubTitle": "Vous pouvez nous contacter à l'un des numéros suivants...",
      "AriaWindowLabel": "Fenêtre Nous appeler",
      "AriaCallUsClose": "Fermer Nous appeler",
      "AriaBusinessHours": "Horaires d'ouverture",
      "AriaCallUsPhoneApp": "Ouvre l'application de téléphone",
      "AriaCobrowseLink": "Ouvre la session de co-navigation",
      "AriaCancelButtonText": "Annuler Nous appeler"
    },
    "callback": {
      "CallbackTitle": "Recevoir un appel",
      "CancelButtonText": "Annuler",
      "AriaCancelButtonText": "Annuler",
      "ConfirmButtonText": "Confirmer",
      "AriaConfirmButtonText": "Confirmer",
      "CallbackFirstName": "Prénom",
      "CallbackPlaceholderRequired": "Requis",
      "CallbackPlaceholderOptional": "Facultatif",
      "CallbackLastName": "Nom",
      "CallbackPhoneNumber": "Téléphone",
      "CallbackQuestion": "Quand pouvons-nous vous appeler ?",
      "CallbackDayLabels": [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi"
      ],
      "CallbackMonthLabels": [
        "janv.",
        "févr.",
        "mars",
        "avr.",
        "mai",
        "juin",
        "juil.",
        "août",
        "sept.",
        "oct.",
        "nov.",
        "déc."
      ],
      "CallbackConfirmDescription": "Appel programmé !",
      "CallbackNumberDescription": "Nous vous appellerons au numéro fourni :",
      "CallbackNotes": "Notes",
      "CallbackDone": "Fermer",
      "AriaCallbackDone": "Fermer",
      "CallbackOk": "OK",
      "AriaCallbackOk": "OK",
      "CallbackCloseConfirm": "Voulez-vous vraiment annuler l'organisation de ce rappel ?",
      "CallbackNoButtonText": "Non",
      "AriaCallbackNoButtonText": "Non",
      "CallbackYesButtonText": "Oui",
      "AriaCallbackYesButtonText": "Oui",
      "CallbackWaitTime": "Temps d'attente",
      "CallbackWaitTimeText": "attente min",
      "CallbackOptionASAP": "Dès que possible",
      "CallbackOptionPickDateTime": "Sélectionner une date et une heure",
      "AriaCallbackOptionPickDateTime": "Ouvre un sélecteur de date",
      "CallbackPlaceholderCalendar": "Sélectionner une date et une heure",
      "AriaMinimize": "Réduire Rappel",
      "AriaWindowLabel": "Fenêtre Rappel",
      "AriaMaximize": "Agrandir Rappel",
      "AriaClose": "Fermer Rappel",
      "AriaCalendarClosedStatus": "Le calendrier est fermé.",
      "Errors": {
        "501": "Impossible d'accepter des paramètres non valides. Consultez la documentation de l'API de serveur pour vérifier les paramètres valides.",
        "503": "Clé d'API manquante. Assurez-vous qu'elle est bien configurée.",
        "1103": "Clé d'API manquante. Assurez-vous qu'elle est bien configurée.",
        "7030": "Entrez un numéro de téléphone valide.",
        "7036": "Impossible de rappeler ce numéro. Réessayez avec un autre numéro de téléphone.",
        "7037": "Rappeler ce numéro n'est pas autorisé. Réessayez avec un autre numéro de téléphone.",
        "7040": "Configurez un nom de service valide.",
        "7041": "Trop de demandes pour le moment.",
        "7042": "Bureau fermé. Essayez de le planifier lors des heures de bureau.",
        "unknownError": "Une erreur s'est produite. Nous sommes désolés pour le désagrément occasionné. Vérifiez vos paramètres de connexion, puis réessayez.",
        "phoneNumberRequired": "Numéro de téléphone requis."
      }
    },
    "channelselector": {
      "Title": "Assistance en direct",
      "SubTitle": "Comment souhaitez-vous nous contacter ?",
      "WaitTimeTitle": "Temps d'attente",
      "AvailableTitle": "Disponible",
      "AriaAvailableTitle": "Disponible",
      "UnavailableTitle": "Indisponible",
      "CobrowseButtonText": "CobrowseSubTitle",
      "CallbackTitle": "Recevoir un appel",
      "CobrowseSubTitle": "La connexion d'un agent est requise pour ceci.",
      "AriaClose": "Fermer Assistance en direct",
      "AriaWarning": "Avertissement",
      "AriaAlert": "Alerte",
      "minute": "min",
      "minutes": "min",
      "AriaWindowLabel": "Fenêtre Assistance en direct"
    },
    "clicktocall": {
      "Title": "Cliquer pour appeler",
      "FirstName": "Prénom",
      "PlaceholderRequired": "Requis",
      "PlaceholderOptional": "Facultatif",
      "LastName": "Nom",
      "PhoneNumber": "Téléphone",
      "WaitTime": "Temps d'attente",
      "FormCancel": "Annuler",
      "AriaFormCancel": "Annuler",
      "FormSubmit": "Demander un numéro",
      "AriaFormSubmit": "Demander un numéro",
      "PhoneLabel": "Numéroter maintenant",
      "AriaPhoneTitle": "Ouvre l'application de téléphone",
      "AccessLabel": "Code d'accès",
      "ExpireLabel": "Le numéro expire dans",
      "AriaExpireLabel": "Le numéro expire dans le minuteur",
      "DisplayClose": "Fermer",
      "AriaDisplayClose": "Fermer",
      "NetworkFail": "Une erreur s'est produite. Nous sommes désolés pour le désagrément occasionné. Vérifiez vos paramètres de connexion, puis réessayez.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Numéro de téléphone expiré.",
      "PhoneReRequest": "Demander un nouveau numéro",
      "AriaPhoneReRequest": "Demander un nouveau numéro",
      "LocalFormValidationEmptyPhoneNumber": "Entrez un numéro de téléphone",
      "ConfirmCloseWindow": "Des données de formulaire n'ont pas été envoyées. Voulez-vous vraiment quitter ?",
      "AriaConfirmCloseCancel": "Non",
      "ConfirmCloseCancel": "Non",
      "AriaConfirmCloseConfirm": "Oui",
      "ConfirmCloseConfirm": "Oui",
      "AriaWindowLabel": "Fenêtre Cliquer pour appeler",
      "AriaMaximize": "Agrandir Cliquer pour appeler",
      "AriaMinimize": "Réduire Cliquer pour appeler",
      "AriaClose": "Fermer Cliquer pour appeler"
    },
    "cobrowse": {
      "agentJoined": "L'agent a rejoint la session.",
      "youLeft": "Vous avez quitté la session. La co-navigation est terminée.",
      "sessionTimedOut": "Session expirée. La session de co-navigation est maintenant terminée.",
      "sessionInactiveTimedOut": "Session expirée. La session de co-navigation est maintenant terminée.",
      "agentLeft": "L'agent a quitté la session. La session de co-navigation est maintenant terminée.",
      "sessionError": "Une erreur inattendue s'est produite. La session de co-navigation est maintenant terminée.",
      "sessionStarted": "Session de co-navigation initiée. En attente de la connexion de l'agent à la session...",
      "navRefresh": "L'agent a actualisé la page. Chargement en cours.",
      "navBack": "L'agent a cliqué sur le bouton Précédent. Chargement de la page en cours.",
      "navForward": "L'agent a cliqué sur le bouton Suivant. Chargement de la page en cours.",
      "navUrl": "L'agent a demandé la navigation. Chargement de la page en cours.",
      "navFailed": "Échec de la demande de navigation de l'agent.",
      "toolbarContent": "ID session : {sessionId}",
      "contentMasked": "L'agent ne peut pas voir le contenu.",
      "contentMaskedPartially": "L'agent ne peut pas voir certaines parties du contenu.",
      "exitBtnTitle": "Quitter la session de co-navigation",
      "areYouOnPhone": "Êtes-vous au téléphone avec notre agent ?",
      "areYouOnPhoneOrChat": "Communiquez-vous avec notre agent par téléphone or par chat ?",
      "connectBeforeCobrowse": "Vous devez être connecté à notre agent pour continuer la co-navigation. Appelez-nous ou lancez un chat en direct, puis initiez une nouvelle session de co-navigation.",
      "sessionStartedAutoConnect": "Session de co-navigation initiée. En attente de la connexion de l'agent à la session...",
      "browserUnsupported": "Malheureusement, votre navigateur n'est pas pris en charge.<br><br> Navigateurs pris en charge : <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 et ultérieur</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 et ultérieur</a></li></ul>",
      "chatIsAlreadyRunning": "Une session de chat est déjà en cours sur une autre page.",
      "modalYes": "Oui",
      "modalNo": "Non"
    },
    "knowledgecenter": {
      "SidebarButton": "Rechercher",
      "SearchButton": "Rechercher",
      "Title": "Poser une question",
      "Ask": "Demander",
      "AriaAsk": "Demander",
      "Close": "Fermer",
      "AriaClose": "Fermer la recherche",
      "Categories": "Catégories",
      "NoResults": "Aucun résultat",
      "NoResultsTextUnder": "Nous sommes désolés, mais nous n'avons pas pu trouver de réponse adéquate pour vous.",
      "NoResultsTextRephrase": "Pouvez-vous reformuler votre question ?",
      "WasThisHelpful": "Cela vous a-t-il aidé ?",
      "Yes": "Oui",
      "No": "Non",
      "AriaYes": "Oui",
      "AriaNo": "Non",
      "ArticleHelpfulnessYes": "Utilité de l'article - Oui",
      "ArticleHelpfulnessYesDesc": "Super ! Nous sommes ravis que l'article ait pu vous aider. Bonne continuation !",
      "ArticleHelpfulnessNo": "Utilité de l'article - Non",
      "ArticleHelpfulnessNoDesc": "Nous sommes désolés que l'article n'ait pas répondu à votre recherche. Nous vous remercions de vos commentaires.",
      "TypeYourQuestion": "Taper votre question",
      "Back": "Précédent",
      "AriaBack": "Retourner aux résultats de recherche",
      "More": "Plus",
      "Error": "Erreur !",
      "GKCIsUnavailable": "Le serveur du centre de connaissances n'est pas disponible actuellement.",
      "AriaClear": "Effacer le texte de la recherche",
      "AriaSearch": "Rechercher",
      "AriaWindowLabel": "Fenêtre Recherche",
      "AriaSearchDropdown": "Résultats suggérés",
      "AriaSearchMore": "Lire plus",
      "AriaResultsCount": "Nombre total de résultats",
      "KnowledgeAgentName": "Centre de connaissances",
      "WelcomeMessage": "Bonjour et bienvenue ! Un agent vous répondra bientôt. En attendant, avez-vous des questions ? Saisissez une question dans le champ ci-dessous.",
      "SearchResult": "En attendant qu'un agent se connecte, voici les réponses les plus pertinentes à votre question :",
      "NoDocumentsFound": "Aucun article n'est pertinent à votre question. Voulez-vous poser une autre question ?",
      "SuggestedMessage": "L'élément de connaissance suivant a été suggéré :",
      "OpenDocumentHint": "cliquer dessus pour afficher son contenu",
      "SuggestedDocumentMessage": "Ce document a été suggéré : '<%DocTitle%>'",
      "FeedbackQuestion": "Cela vous a-t-il aidé ?",
      "FeedbackAccept": "Oui",
      "FeedbackDecline": "Non",
      "TranscriptMarker": "Centre de connaissances : ",
      "SearchMessage": "Rechercher avec la requête '<%SearchQuery%>'↲",
      "VisitMessage": "Visite du document '<%VisitQuery%>'",
      "OpenMessage": "'<%OpenQuery%>' consulté",
      "AnsweredMessage": "Les résultats de la requête '<%AnsweredQuery%>' ont été marqués comme pertinents.",
      "UnansweredMessage": "Les résultats de la requête '<%UnansweredQuery%>' ont été marqués comme sans réponse.",
      "PositiveVoteMessage": "Vote positif pour le document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Vote négatif pour le document '<%VoteQuery%>'.",
      "SuggestedMarker": "Ce document a été suggéré : [^\\0]*?"
    },
    "sendmessage": {
      "SendMessageButton": "Envoyer un message",
      "OK": "OK",
      "Title": "Envoyer un message",
      "PlaceholderFirstName": "Requis",
      "PlaceholderLastName": "Requis",
      "PlaceholderEmail": "Requis",
      "PlaceholderSubject": "Requis",
      "PlaceholderTypetexthere": "Taper votre message ici...",
      "FirstName": "Prénom",
      "LastName": "Nom",
      "Email": "E-mail",
      "Subject": "Objet",
      "Attachfiles": "Joindre des fichiers",
      "AriaAttachfiles": "Le lien Joindre des fichiers ouvre une boîte de dialogue de chargement.",
      "Send": "Envoyer",
      "AriaSend": "Envoyer un message",
      "Sent": "Votre message a été envoyé...",
      "Close": "Fermer",
      "ConfirmCloseWindow": "Voulez-vous vraiment fermer le widget Envoyer le message ?",
      "Cancel": "Annuler",
      "AriaMinimize": "Réduire  Envoyer le message",
      "AriaMaximize": "Agrandir Envoyer le message",
      "AriaWindowLabel": "Fenêtre Envoyer le message",
      "AriaClose": "Fermer Envoyer le message",
      "AriaCloseAlert": "La boîte d'alerte est fermée.",
      "AriaEndConfirm": "Oui",
      "AriaEndCancel": "Annuler",
      "AriaOK": "OK",
      "AriaRemoveFile": "Retirer le fichier",
      "AriaFileIcon": "Fichier",
      "AriaFileSize": "Taille du fichier",
      "Errors": {
        "102": "Prénom requis",
        "103": "Nom de famille requis",
        "104": "Sujet requis",
        "181": "Adresse e-mail requise",
        "182": "Texte dans le message requis",
        "connectionError": "Impossible d'atteindre le serveur. Réessayez plus tard.",
        "unknowError": "Une erreur s'est produite. Nous sommes désolés pour le désagrément occasionné. Vérifiez vos paramètres de connexion, puis réessayez.",
        "attachmentsLimit": "Le nombre total de pièces jointes dépasse la limite : ",
        "attachmentsSize": "La taille totale des pièces jointes dépasse la limite : ",
        "invalidFileType": "Type de fichier non pris en charge. Chargez des images, PDF, fichiers texte et ZIP.",
        "invalidFromEmail": "Adresse e-mail non valide - De",
        "invalidMailbox": "Adresse e-mail non valide - À"
      }
    },
    "sidebar": {
      "SidebarTitle": "Besoin d'aide ?",
      "ChannelSelectorName": "Assistance en direct",
      "ChannelSelectorTitle": "Obtenez l'aide d'un de nos agents tout de suite",
      "SearchName": "Rechercher",
      "SearchTitle": "Rechercher",
      "OffersName": "Offres",
      "OffersTitle": "Offres",
      "CallUsName": "Nous appeler",
      "CallUsTitle": "Détails Nous appeler",
      "CallbackName": "Rappel",
      "CallbackTitle": "Recevoir un appel",
      "ClickToCallName": "Cliquer pour appeler",
      "ClickToCallTitle": "Demander un numéro de téléphone pour le service clients",
      "SendMessageName": "Envoyer un message",
      "SendMessageTitle": "Envoyer un message",
      "WebChatName": "Chat en direct",
      "WebChatTitle": "Chat en direct",
      "AriaClose": "Fermer le menu Besoin d'aide ?"
    },
    "webchat": {
      "ChatButton": "Chat",
      "ChatStarted": "Chat lancé",
      "ChatEnded": "Chat terminé",
      "AgentNameDefault": "Agent",
      "AgentConnected": "<%Agent%> connecté",
      "AgentDisconnected": "<%Agent%> déconnecté",
      "BotNameDefault": "Bot",
      "BotConnected": "<%Bot%> connecté",
      "BotDisconnected": "<%Bot%> déconnecté",
      "SupervisorNameDefault": "Superviseur",
      "SupervisorConnected": "<%Agent%> connecté",
      "SupervisorDisconnected": "<%Agent%> déconnecté",
      "AgentTyping": "...",
      "AriaAgentTyping": "L'agent écrit...",
      "AgentUnavailable": "Aucun agent disponible. Réessayez plus tard.",
      "ChatTitle": "Chat en direct",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "min",
      "ChatFormFirstName": "Prénom",
      "ChatFormLastName": "Nom",
      "ChatFormNickname": "Surnom",
      "ChatFormEmail": "E-mail",
      "ChatFormSubject": "Objet",
	    "ChatFormName": "Nom",
	    "ChatFormPlaceholderName": "Facultatif",
	    "ChatFormCustomerId": "ID client",
	    "ChatFormPlaceholderCustomerId": "Facultatif",
	    "ChatFormSubjectPassword": "Mot de passe",
	    "ChatFormSubjectService": "Service",
	    "ChatFormSubjectOrder": "Commander",
	    "ChatFormSubjectFinance": "Finance",
      "ChatFormPlaceholderFirstName": "Requis",
      "ChatFormPlaceholderLastName": "Requis",
      "ChatFormPlaceholderNickname": "Facultatif",
      "ChatFormPlaceholderEmail": "Requis",
      "ChatFormPlaceholderSubject": "Facultatif",
      "ChatFormSubmit": "Lancer un chat",
      "AriaChatFormSubmit": "Lancer un chat",
      "ChatFormCancel": "Annuler",
      "AriaChatFormCancel": "Annuler le chat",
      "ChatFormClose": "Fermer",
      "ChatInputPlaceholder": "Taper votre message ici",
      "ChatInputSend": "Envoyer",
      "AriaChatInputSend": "Envoyer",
      "ChatEndQuestion": "Voulez-vous vraiment terminer la session de chat ?",
      "ChatEndCancel": "Annuler",
      "ChatEndConfirm": "Terminer le chat",
      "AriaChatEndCancel": "Annuler",
      "AriaChatEndConfirm": "Terminer",
      "ConfirmCloseWindow": "Voulez-vous vraiment fermer le chat ?",
      "ConfirmCloseCancel": "Annuler",
      "ConfirmCloseConfirm": "Fermer",
      "AriaConfirmCloseCancel": "Annuler",
      "AriaConfirmCloseConfirm": "Fermer",
      "ActionsDownload": "Télécharger la transcription",
      "ActionsEmail": "Envoyer la transcription par email",
      "ActionsPrint": "Imprimer la transcription",
      "ActionsCobrowseStart": "Initier la co-navigation",
      "AriaActionsCobrowseStartTitle": "Ouvre une session de co-navigation",
      "ActionsSendFile": "Joindre des fichiers",
      "AriaActionsSendFileTitle": "Joint un lien aux fichiers et ouvre une fenêtre de chargement",
      "ActionsEmoji": "Envoyer un emoji",
      "ActionsCobrowseStop": "Quitter la session de co-navigation",
      "ActionsVideo": "Inviter à un chat vidéo",
      "ActionsTransfer": "Transférer",
      "ActionsInvite": "Inviter",
      "InstructionsTransfer": "Ouvrir ce lien sur un autre appareil pour transférer votre session de chat</br></br><%link%>",
      "InstructionsInvite": "Partager ce lien avec une autre personne pour l'ajouter à cette session de chat</br></br><%link%>",
      "InviteTitle": "Besoin d'aide ?",
      "InviteBody": "Dites-nous si nous pouvons vous aider.",
      "InviteReject": "Non merci",
      "InviteAccept": "Lancer un chat",
      "AriaInviteAccept": "Accepter l'invitation et démarrer le chat",
      "AriaInviteReject": "Rejeter l'invitation",
      "ChatError": "Un problème s'est produit lors du lancement de la session de chat. Réessayez.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Oui",
      "ChatErrorDefaultButton": "Non",
      "AriaChatErrorPrimaryButton": "Oui",
      "AriaChatErrorDefaultButton": "Non",
      "DownloadButton": "Télécharger",
      "AriaDownloadButton": "Télécharger le fichier",
      "FileSent": "a envoyé :",
      "FileTransferRetry": "Réessayer",
      "AriaFileTransferRetry": "Réessayer le transfert de fichiers",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Annuler",
      "AriaFileTransferCancel": "Annuler le transfert de fichier",
      "RestoreTimeoutTitle": "Chat terminé",
      "RestoreTimeoutBody": "Votre précédente session de chat a expiré. Voulez-vous en commencer une nouvelle ?",
      "RestoreTimeoutReject": "Non merci",
      "RestoreTimeoutAccept": "Lancer un chat",
      "AriaRestoreTimeoutAccept": "Accepter l'invitation et démarrer le chat",
      "AriaRestoreTimeoutReject": "Rejeter l'invitation",
      "EndConfirmBody": "Voulez-vous vraiment terminer votre session de chat ?",
      "EndConfirmAccept": "Terminer le chat",
      "EndConfirmReject": "Annuler",
      "AriaEndConfirmAccept": "Terminer le chat",
      "AriaEndConfirmReject": "Annuler",
      "SurveyOfferQuestion": "Voulez-vous participer à une enquête ?",
      "ShowSurveyAccept": "Oui",
      "ShowSurveyReject": "Non",
      "AriaShowSurveyAccept": "Oui",
      "AriaShowSurveyReject": "Non",
      "UnreadMessagesTitle": "non lu",
      "AriaYouSaid": "Vous avez dit",
      "AriaSaid": "a dit",
      "AriaSystemSaid": "Le système a dit",
      "AriaWindowLabel": "Fenêtre Chat en direct",
      "AriaMinimize": "Réduire Chat en direct",
      "AriaMaximize": "Agrandir Chat en direct",
      "AriaClose": "Fermer Chat en direct",
      "AriaEmojiStatusOpen": "La fenêtre du sélecteur d'emoji est ouverte.",
      "AriaEmojiStatusClose": "La fenêtre du sélecteur d'emoji est fermée.",
      "AriaEmoji": "emoji",
      "AriaEmojiPicker": "Sélecteur d'emoji",
      "AriaCharRemaining": "Caractères restants",
      "AriaMessageInput": "Boîte de message",
      "AsyncChatEnd": "Terminer le chat",
      "AsyncChatClose": "Fermer la fenêtre",
      "AriaAsyncChatEnd": "Terminer le chat",
      "AriaAsyncChatClose": "Fermer la fenêtre",
      "DayLabels": [
        "dim",
        "lun",
        "mar",
        "mer",
        "jeu",
        "ven",
        "sam"
      ],
      "MonthLabels": [
        "janv.",
        "févr.",
        "mars",
        "avr.",
        "mai",
        "juin",
        "juil.",
        "août",
        "sept",
        "oct.",
        "nov.",
        "déc."
      ],
      "todayLabel": "Aujourd'hui",
      "Errors": {
        "102": "Prénom requis",
        "103": "Nom requis",
        "161": "Entrez votre nom.",
        "201": "Impossible d'envoyer le fichier.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Il y a trop de fichiers joints. (<%MaxFilesAllowed%>)</p>",
        "202": "Impossible d'envoyer le fichier.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>La limite de chargement ou le nombre maximum de fichiers joints serait atteinte (<%MaxAttachmentsSize%>)</p>",
        "203": "Impossible d'envoyer le fichier.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Ce type de fichier n'est pas autorisé.</p>",
        "204": "Votre message est trop long. Rédigez un message plus court.",
        "240": "Impossible d'initier un nouveau chat pour le moment. Réessayez plus tard.",
        "364": "Adresse e-mail non valide",
        "401": "Nous sommes désolés, mais nous ne pouvons pas autoriser cette session de chat. Voulez-vous initier un nouveau chat ?",
        "404": "Nous sommes désolés, mais nous ne trouvons pas votre cette session de chat précédente. Voulez-vous initier un nouveau chat ?",
        "500": "Nous sommes désolés, une erreur inattendues est survenue avec le service. Voulez-vous fermer et initier un nouveau chat ?",
        "503": "Nous sommes désolés, le service n'est actuellement pas disponible. Voulez-vous fermer et initier un nouveau chat ?",
        "ChatUnavailable": "Impossible d'initier un nouveau chat pour le moment. Réessayez plus tard.",
        "CriticalFault": "Votre session de chat s'est terminée de manière inattendue pour une raison inconnue. Nous sommes désolés de ce désagrément.",
        "StartFailed": "Un problème est survenu au lancement de votre session de chat. Vérifiez votre connexion et que vous avez bien envoyé toutes les informations requises.",
        "MessageFailed": "Votre message n'a pas été reçu. Réessayez.",
        "RestoreFailed": "Nous n'avons pas pu restaurer votre session de chat pour une raison inconnue.",
        "TransferFailed": "Impossible de transférer le chat. Réessayez plus tard.",
        "FileTransferSizeError": "Impossible d'envoyer le fichier.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>La taille du fichier est plus large que la taille autorisée (<%MaxSizePerFile%>)</p>",
        "InviteFailed": "Impossible de génerer l'invite. Réessayez plus tard.",
        "ChatServerWentOffline": "La remise des messages prend plus longtemps que normal. Nous sommes désolés pour ce délai.",
        "RestoredOffline": "La remise des messages prend plus longtemps que normal. Nous sommes désolés pour ce délai.",
        "Disconnected": "<div style='text-align:center'>Connexion perdue</div>",
        "Reconnected": "<div style='text-align:center'>Connexion restaurée</div>",
        "FileSendFailed": "Impossible d'envoyer le fichier.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Une déconnexion inattendue s'est produite. Réessayer ?</p>",
        "Generic": "<div style='text-align:center'>Une erreur inattendue s'est produite.</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Type de fichier non valide. Seules les images sont autorisées.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "La taille du fichier est plus large que la taille autorisée",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "Le nombre maximal de fichiers joints dépassait la limite",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Configuration du transport de la clé d'API X non valide",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Configuration du transport du point de terminaison",
        "pureengage-v3-rest-INVALID_NICKNAME": "Prénom requis",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "Nous sommes désolés, mais nous ne pouvons pas autoriser cette session de chat.",
        "purecloud-v2-sockets-400": "Un problème s'est produit. Vérifiez votre connexion et que vous avez bien envoyé toutes les informations requises, puis réessayez.",
        "purecloud-v2-sockets-500": "Une erreur inattendue s'est produite avec le service.",
        "purecloud-v2-sockets-503": "Le service n'est pas disponible actuellement."
      }
    }
  }
}