import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../components/site/Layout/Layout';
import { initializeOneTrustScript } from '../../../common/OneTrust/OneTrustScript';
import { store } from '../../../../__runtime/redux/Store';
import { getPageContent } from '../../../actions/PageContentAction';
import { getLocalization } from '../../../common/UserManager/UserManager';
import { PAGE_ID, LOGIN_INFO } from '../../../common/Constants';

const CookieListPage = props => {
    const dispatch = useDispatch();

    const { cookienotice = {} } = useSelector(state => state.loginPage);

    const { pageTitle } = cookienotice;

    useEffect(() => {
        const localization = getLocalization();
        document.documentElement.lang =
            localization[LOGIN_INFO.LANG] === LOGIN_INFO.SERBIAN_LANG
                ? LOGIN_INFO.ONETRUST_SERBIAN_LANG
                : localization[LOGIN_INFO.ONE_TRUST_ISO_CODE || LOGIN_INFO.LANG];
        dispatch(getPageContent({ pageId: PAGE_ID.COOKIE_NOTICE }));
        initializeOneTrustScript(store.getState());
    }, []);

    return (
        <Layout isLoginFooter isLoginHeader hideHeader pageTitle={pageTitle}>
            <div id='ot-sdk-cookie-policy'></div>
        </Layout>
    );
};

export default CookieListPage;
