import React from 'react';
import Link from 'react-router/lib/Link';
import * as styles from './MyRewardsNavigationCard.css';
import classNames from 'classnames/bind';
import Icon from '../../core/Icon/Icon';
import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

const MyRewardsNavigationCard = ({
    title,
    icon,
    link,
    buttonText,
    statusText,
    isLoyaltyCard,
    isOnboardingCard,
    isSelfRedCard,
    isCompleted
}) => {
    const loyaltyCompleted = isLoyaltyCard && isCompleted;
    const onboardingCompleted = isOnboardingCard && isCompleted;
    return (
        <div
            className={cx(
                'loyalty-navigation-card-container',
                (!isSelfRedCard && ((!isOnboardingCard && !isLoyaltyCard) || loyaltyCompleted || onboardingCompleted)) &&
                'completed'
            )}
        >
            <div className={cx('background-icon')}>
                <Icon iconType='svg' width='50px' height='40px' viewBox='0 0 50 40' name={icon} />
            </div>
            <div className={cx('navigation-icon')}>
                <Icon
                    iconType='svg'
                    width='50px'
                    height='40px'
                    viewBox='0 0 50 40'
                    name={icon}
                    className={cx('icon-theme')}
                />
            </div>
            <h1>{title}</h1>
            <div className={cx('navigation-link')}>
                <Link
                    to={link}
                    className={cx(
                        ((!isOnboardingCard && !isLoyaltyCard && !isSelfRedCard) ||
                            loyaltyCompleted ||
                            onboardingCompleted) &&
                        'disabled-click'
                    )}
                >
                    {buttonText}
                    <Icon iconType='svg' width='16px' height='16px' viewBox='0 0 16 16' name='down' />
                </Link>
            </div>
            {(loyaltyCompleted || onboardingCompleted) && (
                <div className={cx('status', loyaltyCompleted && 'coming-soon')}>
                    <p>{statusText}</p>
                </div>
            )}
        </div>
    );
};

MyRewardsNavigationCard.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    link: PropTypes.string,
    buttonText: PropTypes.string,
    isCompleted: PropTypes.bool,
    statusText: PropTypes.string,
    isLoyaltyCard: PropTypes.bool,
    isOnboardingCard: PropTypes.bool,
    isSelfRedCard: PropTypes.bool
};

export default MyRewardsNavigationCard;
