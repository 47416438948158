import { UPDATE_COUNTRY_REQUEST, UPDATE_LANGUAGE_REQUEST } from '../actionTypes/SelectLanguageActionTypes';

export const updateCountryEvent = payload => ({
    type: UPDATE_COUNTRY_REQUEST,
    payload
});

export const updateLanguageEvent = payload => ({
    type: UPDATE_LANGUAGE_REQUEST,
    payload
});
