import _get from 'lodash/get';

let oneTrustConfig = {};
export const initializeOneTrustScript = state => {
    oneTrustConfig = _get(state, 'context.environment.oneTrustScript', {});
    
    if (oneTrustConfig.firstScript !== undefined && document.getElementById('onetrust-script') === null) {

        const firstScript = document.createElement('script');
        const secondScript = document.createElement('script');
        const thirdScript = document.createElement('script');

        firstScript.id = 'onetrust-script';
        firstScript.src = oneTrustConfig.firstScript.src;
        firstScript.type = oneTrustConfig.firstScript.type;
        firstScript.setAttribute('data-domain-script', oneTrustConfig.firstScript.dataDomain);
        firstScript.charset = oneTrustConfig.firstScript.charset;
        firstScript.setAttribute('data-document-language', 'true');


        document.body.appendChild(firstScript);

        const inlineScript = document.createTextNode(oneTrustConfig.secondScript.value);
        secondScript.type = oneTrustConfig.secondScript.type;

        secondScript.appendChild(inlineScript);
        document.body.appendChild(secondScript);

        thirdScript.type = oneTrustConfig.firstScript.type;
        thirdScript.src = oneTrustConfig.thirdScript.src;

        document.body.appendChild(thirdScript);
    }
};