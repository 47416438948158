import _get from 'lodash/get';
import { COMPANY_DATA_L10N } from '../common/Constants';

const companyFillteredData = companyData => {
    const baseRespData = {
        title: '',
        data: {
            companyData: [],
            contact: [],
            location: [],
            commonData: []
        },
        footerData: {
            title: '',
            fields: [],
            isShowGrossPriceChosen: false,
            showPricesPrefRadioButtons: true

        }
    };
    const { mainPartnerAddress } = companyData;
    const iniItemData = {
        label: '',
        value: ''
    };

    baseRespData.title = _get(companyData, 'title', '');

    baseRespData.footerData.title = 'PRICE_PREF';
    baseRespData.footerData.isShowGrossPriceChosen = _get(
        companyData,
        'isShowGrossPriceChosen',
        false
    );

    baseRespData.footerData.showPricesPrefRadioButtons = _get(
        companyData,
        'showPricesPrefRadioButtons',
        false
    );


    const itemData = { ...iniItemData };
    itemData.label = 'COMPANY_ID';
    itemData.value = _get(companyData, 'mainPartnerId', '');
    baseRespData.data.companyData.push(itemData);

    Object.keys(COMPANY_DATA_L10N).forEach(key => {
        const itemData = { ...iniItemData };
        itemData.label = key;
        if (key === 'GROSS_PRICE_PREF' || key === 'NET_PRICE_PREF') {
            itemData.name = COMPANY_DATA_L10N[key];
        } else if (key === 'SDO_PORTAL') {
            itemData.value = key;
            itemData.url = _get(companyData, COMPANY_DATA_L10N[key], '');
        } else if (key === 'POSTAL_CODE_OR_CITY') {
            itemData.value = `${_get(mainPartnerAddress, COMPANY_DATA_L10N[key], '')} ${_get(
                mainPartnerAddress,
                COMPANY_DATA_L10N.CITY,
                ''
            )}`;
        } else {
            itemData.value = _get(mainPartnerAddress, COMPANY_DATA_L10N[key], '');
        }

        switch (key) {
            case 'COMPANY_NAME':
                baseRespData.data.companyData.push(itemData);
                break;
            case 'PHONE':
            case 'FAX':
            case 'EMAIL':
                baseRespData.data.contact.push(itemData);
                break;
            case 'COUNTRY':
            case 'STATE_OR_REGION':
            case 'STREET_OR_HOUSE':
            case 'POSTAL_CODE_OR_CITY':
                baseRespData.data.location.push(itemData);
                break;
            case 'SDO_PORTAL':
                baseRespData.data.commonData.push(itemData);
                break;
            case 'GROSS_PRICE_PREF':
            case 'NET_PRICE_PREF':
                baseRespData.footerData.fields.push(itemData);
                break;    
            default:
                break;
        }
    });

    return baseRespData;
};

export default companyFillteredData;
