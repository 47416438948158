import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import { connect } from 'react-redux';
import _get from 'lodash/get';

export const NavLink = props => {
    const { assetBaseUrl, automationId, children = '', theme, onClick } = props;
    let { linkUrl } = props;
    const linkWithoutContext = linkUrl.split("?")[0];
    const isDownloadLink = new RegExp("bin|pdf|jpg|jpeg|png").test(linkWithoutContext);
    const isAbsolutePath = linkUrl.indexOf('http') !== -1;
    const isExternalNavLink = isAbsolutePath && linkUrl.indexOf(window.location.hostname) === -1;

    if (isDownloadLink && !isAbsolutePath) {
        linkUrl = `${assetBaseUrl}${linkUrl}`;
    }

    return isExternalNavLink || isDownloadLink ? (
        <a
            className={theme}
            href={linkUrl}
            onClick={onClick}
            automation-id={automationId}
            target='_blank'
            rel='noopener noreferrer'
        >
            {children}
        </a>
    ) : (
        <Link {...props} className={theme} to={linkUrl} onClick={onClick} automation-id={automationId}>
            {children}
        </Link>
    );
};

NavLink.propTypes = {
    assetBaseUrl: PropTypes.string,
    linkUrl: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]),
    onClick: PropTypes.func,
    automationId: PropTypes.string,
    theme: PropTypes.string
};

NavLink.defaultProps = {
    assetBaseUrl: '',
    linkUrl: '',
    children: '',
    onClick: undefined,
    automationId: '',
    theme: ''
};

const mapStateToProps = state => ({
    assetBaseUrl: _get(state, 'context.environment.assetBaseUrl')
});

export default connect(mapStateToProps)(NavLink);
