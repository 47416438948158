import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Icon from '../Icon/Icon';
import * as styles from './SelectBox.css';

const cx = classNames.bind(styles);

function SelectBox(props) {
    function onChange(e) {
        props.onChange(e.target.value);
    }
    const { themeSelectBox, theme, arrowTheme, deaultIcon, defaultValue, onClick, onFocus } = props;
    const iconArrow = (
        <Icon
            className={cx('svgClass', arrowTheme)}
            iconType='svg'
            width='14px'
            height='14px'
            viewBox='0 0 14 14'
            name='down'
        />
    );
    return (
        <div className={cx(theme, 'selectContainer')}>
            <select
                onClick={onClick}
                className={cx('selectBox', themeSelectBox)}
                automation-id={props.automationId}
                id={props.id}
                onChange={onChange}
                value={defaultValue}
                disabled={props.disabled}
                onFocus={onFocus || (() => 1)}
            >
                {props.options.map(item => {
                    return (
                        <option key={`${item.label}-${Math.random()}`} value={item.value}>
                            {item.label}
                        </option>
                    );
                })}
            </select>
            {!props.disabled && deaultIcon && iconArrow}
        </div>
    );
}

SelectBox.propTypes = {
    id: PropTypes.string,
    theme: PropTypes.string,
    arrowTheme: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.object),
    defaultValue: PropTypes.string,
    automationId: PropTypes.string,
    themeSelectBox: PropTypes.string,
    deaultIcon: PropTypes.bool,
    disabled: PropTypes.bool
};

SelectBox.defaultProps = {
    id: '',
    theme: '',
    defaultValue: '',
    options: [],
    automationId: '',
    arrowTheme: '',
    themeSelectBox: '',
    deaultIcon: true,
    disabled: false,
    onClick: () => { },
};

export default SelectBox;
