import {
    GET_SEARCH_DATA_REQUEST,
    GET_SEARCH_DATA_SUCCESS,
    RESET_SEARCH_DATA,
    INPUT_UPDATE,
    CLEAR_REDUCER
} from '../actionTypes/SearchActionTypes';

export const clearSearchReducer = () => ({
    type: CLEAR_REDUCER
})

export const getSearchDetails = payload => ({
    type: GET_SEARCH_DATA_REQUEST,
    payload
});
export const getSearchData = payload => ({
    type: GET_SEARCH_DATA_SUCCESS,
    payload
});

export const resetSearchData = () => ({
    type: RESET_SEARCH_DATA
});
export const updateData = searchValue => ({
    type: INPUT_UPDATE,
    searchValue
});
