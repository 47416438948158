import classNames from 'classnames/bind';
import React from 'react';
import Link from 'react-router/lib/Link';
import { PROMO_L10N, GENERIC_L10N } from '../../../../common/Constants';
import Icon from '../../../../components/core/Icon/Icon';
import FreeProducts from '../../../../components/site/PromotionCard/FreeProducts';
import * as styles from '../Promotions.css';

const cx = classNames.bind(styles);

const StepThreePromotion = props => {
    const { localization, commonLocalization } = props;
    const iconFreeList = (
        <Icon
            className={cx('accordionArrow', 'visibleSm')}
            iconType='svg'
            width='24px'
            height='24px'
            viewBox='0 0 16 16'
            name='acc-up'
        />
    );
    const isStep3Active = props.isStepThreeActive();
    const isCheckOutActive = props.isCheckOutActive();
    if (props.updatedFreeItemIndex !== null) props.isFreeConditionalSatisfied(props.updatedFreeItemIndex);
    const itemNeedToSelect = props.selectedDeals.freeGroup ? props.freeItemNeedToSelect(props.selectedDeals) : 0;

    return (
        <div
            automation-id='at-green-tick-step-3'
            className={cx('container', 'thirdLevel', `${isCheckOutActive && isStep3Active ? 'active' : ''}`)}
        >
            <div automation-id='at-step-3-option' className={cx('content', 'bdr')}>
                {' '}
                {/* 'border-line need to add for inital'  Todo Need to remove */}
                <h2 className={cx('stepsTitle')}>
                    <Link className={cx('showlinks')} onClick={props.toggleFreeView} role='button'>
                        <span className={cx('col8', 'sm8', 'pad0')}>
                            <strong>{localization[PROMO_L10N.STEP3]}.</strong> {localization[PROMO_L10N.ADD_YOUR]}{' '}
                            <span className={cx('freeTxt', 'textBrand')}>
                                {' '}
                                {itemNeedToSelect} {localization[PROMO_L10N.FREE]}
                            </span>{' '}
                            {localization[PROMO_L10N.PROD_TO_YOUR_CART]}{' '}
                        </span>
                        <span
                            className={cx(
                                'counts',
                                'col4',
                                'sm4',
                                'pad0',
                                `${!isStep3Active ? 'display-none' : ''}`,
                                props.selectedDeals.freeItemAmount - itemNeedToSelect >=
                                    props.selectedDeals.freeItemAmount
                                    ? 'validCounts'
                                    : 'invalidCounts'
                            )}
                        >
                            {props.selectedDeals.freeItemAmount - itemNeedToSelect}/{props.selectedDeals.freeItemAmount}
                            <span className={cx(props.mobileFreeSummary && 'rotateIcon')}>{iconFreeList}</span>
                        </span>
                    </Link>
                </h2>
                {isStep3Active && props.selectedDeals.freeItemAmount > 0 && (
                    <div
                        automation-id='at-free-products-list'
                        className={cx(props.mobileFreeSummary ? 'visibleSm' : 'hiddenSm')}
                    >
                        <FreeProducts
                            showCard={props.mobileFreeSummary}
                            items={props.selectedDeals.freeGroup.items}
                            maxValue={props.selectedDeals.freeItemAmount - props.selectedDeals.selectedFreeItem || 0}
                            disabledIncreBtn={isCheckOutActive}
                            changeQuantity={(e, itemIndex) => props.changeFreeQuantity(e, itemIndex)}
                            inCartText={localization[PROMO_L10N.IN_CART]}
                            skuText={commonLocalization[GENERIC_L10N.SKU]}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default StepThreePromotion;
