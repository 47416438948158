import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { AUTH, PARAMS, CART_API } from '../common/Api';
import { PAGE_ID } from '../../common/Constants';
import { store } from '../../../__runtime/redux/Store';
import { removeFromCartEventGA4 } from '../../common/GoogleTagManager/GoogleTagManager';
import { getGTMRemoveFromCartProductGA4, getProductPrice } from '../../service/DataLayerFilter';
import _cloneDeep from 'lodash/cloneDeep';

export default class CartApi {
    static getCart(payload) {
        const { baseInfo = {}, cartId } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${CART_API.CART}/${cartId}`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static getMultiCart(payload) {
        const { baseInfo = {}, params = { [PARAMS.FIELDS]: PARAMS.FULL }, isOpenMiniCartFlow, isRemoveOosFlow, pageId } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${CART_API.MULTI_CART}`;

        if (baseInfo[PARAMS.CART_ID]) {
            config.url = `${config.url}/${baseInfo[PARAMS.CART_ID]}`;
        }

        if (baseInfo[PARAMS.IS_RETURN_EMPTIES]) {
            params[PARAMS.IS_RETURN_EMPTIES] = baseInfo[PARAMS.IS_RETURN_EMPTIES];
        }

        if (baseInfo[PARAMS.IS_MARKETING_MATRIALS]) {
            params[PARAMS.IS_MARKETING_MATRIALS] = baseInfo[PARAMS.IS_MARKETING_MATRIALS];
        }

        if (baseInfo[PARAMS.IS_PICKUP_ORDER]) {
            params[PARAMS.IS_PICKUP_ORDER] = baseInfo[PARAMS.IS_PICKUP_ORDER];
        }

        if (isOpenMiniCartFlow || pageId === PAGE_ID.MINI_CART) {
            params[PARAMS.IS_OPEN_MINI_CART] = true;
        }

        if (isRemoveOosFlow) {
            params[PARAMS.IS_REMOVE_OOS_FLOW] = true;
        }

        params[PARAMS.FIELDS] = params[PARAMS.FIELDS] || PARAMS.FULL;

        config.params = params;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static addToCart(payload) {
        const { baseInfo = {}, data = {} } = payload;

        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${CART_API.ADD_TO_CART}`;

        // if (baseInfo[PARAMS.CART_ID]) {
        //     config.url = `${config.url}/${baseInfo[PARAMS.CART_ID]}`;
        // }

        if (baseInfo[PARAMS.IS_PICKUP_ORDER]) {
            data[PARAMS.IS_PICKUP_ORDER] = baseInfo[PARAMS.IS_PICKUP_ORDER];
        }

        config.data = data;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static addToMultiCart(payload) {
        const { baseInfo = {}, data = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${CART_API.MULTI_CART}`;

        if (baseInfo[PARAMS.CART_ID]) {
            config.url = `${config.url}/${baseInfo[PARAMS.CART_ID]}`;
        }

        if (baseInfo[PARAMS.IS_PICKUP_ORDER]) {
            data[PARAMS.IS_PICKUP_ORDER] = baseInfo[PARAMS.IS_PICKUP_ORDER];
        }

        config.data = data;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static updateManyInCart(payload) {
        const { baseInfo = {}, data, params = { [PARAMS.FIELDS]: PARAMS.FULL } } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}/updateManyInCart`;

        if (baseInfo[PARAMS.IS_RETURN_EMPTIES]) {
            params[PARAMS.IS_RETURN_EMPTIES] = baseInfo[PARAMS.IS_RETURN_EMPTIES];
        }

        if (baseInfo[PARAMS.IS_MARKETING_MATRIALS]) {
            params[PARAMS.IS_MARKETING_MATRIALS] = baseInfo[PARAMS.IS_MARKETING_MATRIALS];
        }

        if (baseInfo[PARAMS.IS_PICKUP_ORDER]) {
            params[PARAMS.IS_PICKUP_ORDER] = baseInfo[PARAMS.IS_PICKUP_ORDER];
        }

        config.params = params;
        config.data = data;

        return RestClient.put(config).then(json => {
            return json;
        });
    }

    static updateToCart(payload) {
        const { baseInfo = {}, data, params = { [PARAMS.FIELDS]: PARAMS.FULL } } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}/${
            baseInfo[PARAMS.CART_ID]
        }${CART_API.CART}`;

        if (baseInfo[PARAMS.IS_RETURN_EMPTIES]) {
            params[PARAMS.IS_RETURN_EMPTIES] = baseInfo[PARAMS.IS_RETURN_EMPTIES];
        }

        if (baseInfo[PARAMS.IS_MARKETING_MATRIALS]) {
            params[PARAMS.IS_MARKETING_MATRIALS] = baseInfo[PARAMS.IS_MARKETING_MATRIALS];
        }

        if (baseInfo[PARAMS.IS_PICKUP_ORDER]) {
            params[PARAMS.IS_PICKUP_ORDER] = baseInfo[PARAMS.IS_PICKUP_ORDER];
        }

        config.params = params;
        config.data = data;

        return RestClient.put(config).then(json => {
            return json;
        });
    }

    // static getSavedCart(params) {
    //     // config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${CART_API.GET_SAVED_CART}`;

    //     const { baseInfo } = params;
    //     const config = RestConfig.v2ComApiConfig(params);
    //     config.url = `/${baseInfo.siteId}${AUTH.USERS}/${baseInfo.userId}${CART_API.GET_SAVED_CART}`;
    //     config.params = {
    //         b2bUnitId: "2700006858"
    //     }

    //     return RestClient.get(config).then(json => {
    //         return json;
    //     });
    // }

    static getConvFactor(payload) {
        const { baseInfo = {}, data = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${CART_API.CONV_FACTOR}`;

        config.data = data;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static deleteFromCart(payload) {
        const { baseInfo = {}, code, entryNumber, params = { [PARAMS.FIELDS]: PARAMS.FULL } } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${CART_API.CART}/${
            baseInfo[PARAMS.CART_ID]
        }/item/${entryNumber}`;

            params.code = code;

        if (baseInfo[PARAMS.IS_RETURN_EMPTIES]) {
            params[PARAMS.IS_RETURN_EMPTIES] = baseInfo[PARAMS.IS_RETURN_EMPTIES];
        }

        if (baseInfo[PARAMS.IS_MARKETING_MATRIALS]) {
            params[PARAMS.IS_MARKETING_MATRIALS] = baseInfo[PARAMS.IS_MARKETING_MATRIALS];
        }

        if (baseInfo[PARAMS.IS_PICKUP_ORDER]) {
            params[PARAMS.IS_PICKUP_ORDER] = baseInfo[PARAMS.IS_PICKUP_ORDER];
        }

        config.params = params;
        

        return RestClient.delete(config).then(json => {
            return json;
        });
    }
    static removeManyFromCartSaga(payload) {
        const { baseInfo = {}, data, params = { [PARAMS.FIELDS]: PARAMS.FULL } } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${CART_API.CART}/${
            baseInfo[PARAMS.CART_ID]
        }/removeProducts`;

     

        if (baseInfo[PARAMS.IS_RETURN_EMPTIES]) {
            params[PARAMS.IS_RETURN_EMPTIES] = baseInfo[PARAMS.IS_RETURN_EMPTIES];
        }

        if (baseInfo[PARAMS.IS_MARKETING_MATRIALS]) {
            params[PARAMS.IS_MARKETING_MATRIALS] = baseInfo[PARAMS.IS_MARKETING_MATRIALS];
        }

        if (baseInfo[PARAMS.IS_PICKUP_ORDER]) {
            params[PARAMS.IS_PICKUP_ORDER] = baseInfo[PARAMS.IS_PICKUP_ORDER];
        }

        config.params = params;
        config.data = data;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    

    static clearAllCart(payload) {
        const { baseInfo = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${CART_API.CART}${
            CART_API.CLEAR_ALL
        }/${baseInfo[PARAMS.CART_ID]}`;

        try {
            const gtmCarts = store.getState()?.cartDetails?.cart?.cartList;
            if (gtmCarts?.length) {
                removeFromCartEventGA4(_cloneDeep(gtmCarts).reduce((gtmRemoveProducts, { cartDetails: { entries: { orderEntries } } }) => [
                    ...gtmRemoveProducts,
                    ...orderEntries.map(({ product: { name, codeShort, category, brand }, quantity, qtyType, totalPrice: { value, currencyIso } = {} }, index) => getGTMRemoveFromCartProductGA4(index + gtmRemoveProducts.length, name, codeShort, qtyType, quantity, value && quantity ? getProductPrice((value / quantity).toFixed(2)) : 0, payload?.data?.list, currencyIso, brand, category))
                ], []), payload?.data?.origin_element);
            }
        } catch (ex) {
            console.error(ex);
        }

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static clearSavedCart(payload) {
        const { baseInfo, params } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${CART_API.CLEAR_SAVED_CART}`;

        config.params = params;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static deleteSavedCartEntry(payload) {
        const { baseInfo, params } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${CART_API.DELETE_SAVED_CART_ENTRY}`;

        config.params = params;

        return RestClient.delete(config).then(json => {
            return json;
        });
    }

    static updateDeliveryDate(payload) {
        const { baseInfo = {}, data = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${
            CART_API.UPDATED_DELIVERY_DATE
        }/${baseInfo[PARAMS.CART_ID]}`;

        if (baseInfo[PARAMS.IS_RETURN_EMPTIES]) {
            data[PARAMS.IS_RETURN_EMPTIES] = baseInfo[PARAMS.IS_RETURN_EMPTIES];
        }

        if (baseInfo[PARAMS.IS_MARKETING_MATRIALS]) {
            data[PARAMS.IS_MARKETING_MATRIALS] = baseInfo[PARAMS.IS_MARKETING_MATRIALS];
        }

        if (baseInfo[PARAMS.IS_PICKUP_ORDER]) {
            data[PARAMS.IS_PICKUP_ORDER] = baseInfo[PARAMS.IS_PICKUP_ORDER];
        }
        config.data = payload.data;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static resetDeliveryDate(payload) {
        const { baseInfo = {} } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}${AUTH.USERS}/${baseInfo[PARAMS.USER_ID]}${
            CART_API.RESET_DELIVERY_DATE
        }`;

        return RestClient.put(config).then(json => {
            return json;
        });
    }
}
