import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import _isEmpty from 'lodash/isEmpty';

import Loader from '../../../components/site/Loader/Loader';
import Notification from '../../../components/core/Notification/Notification';
import SmartEdit from '../../../components/site/SmartEdit/SmartEdit';
import Image from '../../../components/core/Image/Image';
import SelectBox from '../../../components/core/SelectBox/SelectBox';
import { PAGE_ID, LOGIN_INFO, URL_PATHS } from '../../../common/Constants';
import { setLocalization, logout } from '../../../common/UserManager/UserManager';
import { initializeGA } from '../../../common/GoogleAnalytics/GoogleAnalytics';
import * as selectLanguageActions from '../../../actions/SelectLanguageAction';
import * as loginActions from '../../../actions/LoginAction';
import * as pageContentActions from '../../../actions/PageContentAction';

import * as styles from './SelectLanguageModal.css';
import { buttonClickEvent, buttonClickEventConfigs } from '../../../common/GoogleTagManager/GoogleTagManager';

const cx = classNames.bind(styles);

const SelectLanguageModal = () => {
    const dispatch = useDispatch();

    const context = useSelector(state => state.context);
    const countryDetails = useSelector(state => state.countryDetails);
    const supportedCountry = useSelector(state => state.context.preferences.supportedCountry);
    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);
    const storeFrontUrl = useSelector(state => state.context.environment.storeFrontUrl);

    const [filteredCountryList, setFilteredCountryList] = useState([]);

    const {
        pageContent = {},
        countryList,
        selectedCountry = {},
        selectedLanguage = {},
        lanaguageList = {}
    } = countryDetails;

    const { logoImage = {} } = pageContent;
    const langList = lanaguageList[selectedCountry.value];

    useEffect(() => {
        if (!_isEmpty(countryList) && !_isEmpty(supportedCountry)) {
            const newCountryList = countryList.filter(country => supportedCountry.some(sc => sc === country.id));
            setFilteredCountryList(newCountryList);
        }
    }, [countryDetails, supportedCountry]);

    useEffect(() => {
        logout();
        dispatch(loginActions.clearLoginPage());

        if (_isEmpty(countryDetails.pageContent)) {
            dispatch(pageContentActions.getPageContent({ pageId: PAGE_ID.SELECTLANGUAGE }));
        }
    }, []);

    const onCountryChange = value => {
        dispatch(selectLanguageActions.updateCountryEvent({ value }));
    };

    const onLangChange = value => {
        dispatch(selectLanguageActions.updateLanguageEvent({ value }));
    };

    const submitLocalization = () => {
        if (_isEmpty(supportedCountry) || supportedCountry.indexOf(selectedCountry.value) !== -1) {
            const countryData = {
                [LOGIN_INFO.SITE_ID]: selectedCountry.value,
                [LOGIN_INFO.COUNTRY]: selectedCountry.label,
                [LOGIN_INFO.LANG]: selectedLanguage.value,
                [LOGIN_INFO.LANGUAGE]: selectedLanguage.label,
                [LOGIN_INFO.ONE_TRUST_ISO_CODE]: selectedLanguage.oneTrustIsoCode
            };

            setLocalization(countryData);
            // Initialize Google Analytics whenever the country is changed.
            initializeGA({ context });
            window.location.href = URL_PATHS.PRE_LOGIN;
        } else {
            window.location.href = `${assetBaseUrl}${storeFrontUrl}/${selectedCountry.value}/${selectedLanguage.value}`;
        }
    };

    return (
        <div className={cx('languageContainer')}>
            <Loader />
            <Notification />
            <div className={cx('backgroundOpacity')} />
            <section className={cx('languageBlock')}>
                <SmartEdit data={logoImage}>
                    <Image
                        automationId='at-cchbc-logo'
                        theme={cx('langlogo')}
                        src={logoImage.url}
                        alt={logoImage.altText}
                    />
                </SmartEdit>
                <h2 automation-id='at-country-language-title'>Select Country &amp; Language</h2>
                <form className={cx('languageForm')}>
                    <div>
                        <SelectBox
                            options={filteredCountryList || []}
                            defaultValue={selectedCountry.value}
                            theme={cx('langSelect')}
                            arrowTheme={cx('arrow')}
                            onChange={onCountryChange}
                            automationId='at-country-dropdown'
                            onFocus={() =>
                                buttonClickEvent(
                                    buttonClickEventConfigs.preLogin.actions.preLoginCountryLanguagePopUp,
                                    buttonClickEventConfigs.preLogin.labels.preLoginCountryLanguagePopUpSelectCountry,
                                    buttonClickEventConfigs.preLogin.categories.preLogin
                                )
                            }
                        />
                    </div>
                    {langList && langList.length > 1 && (
                        <div>
                            <SelectBox
                                options={lanaguageList[selectedCountry.value]}
                                defaultValue={selectedLanguage.value}
                                theme={cx('langSelect')}
                                arrowTheme={cx('arrow')}
                                onChange={onLangChange}
                                automationId='at-language-dropdown'
                                onFocus={() =>
                                    buttonClickEvent(
                                        buttonClickEventConfigs.preLogin.actions.preLoginCountryLanguagePopUp,
                                        buttonClickEventConfigs.preLogin.labels
                                            .preLoginCountryLanguagePopUpSelectLanguage,
                                        buttonClickEventConfigs.preLogin.categories.preLogin
                                    )
                                }
                            />
                        </div>
                    )}
                    {langList && langList.length === 1 && (
                        <div className={cx('langInput')}>
                            <input type='text' value={langList[0].label} readOnly='readonly' />
                        </div>
                    )}
                    <div className={cx('langbtn')}>
                        <button
                            automation-id='at-continue-to-login-button'
                            type='button'
                            className={cx('btnLogin')}
                            onClick={() => {
                                submitLocalization();
                                buttonClickEvent(
                                    buttonClickEventConfigs.preLogin.actions.preLoginCountryLanguagePopUp,
                                    buttonClickEventConfigs.preLogin.labels.preLoginCountryLanguagePopUpContinue,
                                    buttonClickEventConfigs.preLogin.categories.preLogin
                                );
                            }}
                        >
                            Continue
                        </button>
                    </div>
                </form>
            </section>
        </div>
    );
};

export default SelectLanguageModal;
