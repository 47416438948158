import {
    GET_ORDER_TEMPLATE_DETAILS_REQUEST,
    UPDATE_ORDER_TEMPLATE_DETAILS_QUANTITY_REQUEST,
    UPDATE_ORDER_TEMPLATE_DETAILS_REQUEST,
    DELETE_ORDER_TEMPLATE_ITEM_REQUEST,
    UPDATE_ORDER_TEMPLATE_DETAILS_ITEM_QUANTITY,
    UPDATE_ORDER_DETAILS_TEMPLATE_DESCRIPTION,
    UPDATE_ORDER_DETAILS_TEMPLATE_NAME,
    CALCEL_ORDER_TEMPLATE_DETAILS,
    CLEAR_ORDER_TEMPLATE_SUCCESS,
    RESET_ORDER_TEMPLATE_DETAILS,
    SET_ZERO_QTY_ERR,
    UPDATE_ITEM_SEQUENCE,
    UPDATE_SORTING_ORDER_TEMPLATE_ITEM_REQUEST
} from '../actionTypes/OrderTemplateDetailsActionTypes';

export const getTemplateDetails = payload => ({
    type: GET_ORDER_TEMPLATE_DETAILS_REQUEST,
    payload
});

export const updateOrderTemplateQty = payload => ({
    type: UPDATE_ORDER_TEMPLATE_DETAILS_QUANTITY_REQUEST,
    payload
});

export const updateOrderTemplateItemQty = payload => ({
    type: UPDATE_ORDER_TEMPLATE_DETAILS_ITEM_QUANTITY,
    payload
});

export const updateOrderTemplate = payload => ({
    type: UPDATE_ORDER_TEMPLATE_DETAILS_REQUEST,
    payload
});

export const deleteOrderTemplateItem = payload => ({
    type: DELETE_ORDER_TEMPLATE_ITEM_REQUEST,
    payload
});

export const updateTemplateDescription = payload => ({
    type: UPDATE_ORDER_DETAILS_TEMPLATE_DESCRIPTION,
    payload
});

export const updateTemplateName = payload => ({
    type: UPDATE_ORDER_DETAILS_TEMPLATE_NAME,
    payload
});

export const cancelOrderTemplate = payload => ({
    type: CALCEL_ORDER_TEMPLATE_DETAILS,
    payload
});

export const clearTemplateMessage = payload => ({
    type: CLEAR_ORDER_TEMPLATE_SUCCESS,
    payload
});

export const resetTemplateDetails = () => ({
    type: RESET_ORDER_TEMPLATE_DETAILS
});

export const setZeroQtyError = payload => ({
    type: SET_ZERO_QTY_ERR,
    payload
});

export const updateItemSequence = payload => ({
    type: UPDATE_ITEM_SEQUENCE,
    payload
});

export const updateSortingOrderItem = payload => ({
    type: UPDATE_SORTING_ORDER_TEMPLATE_ITEM_REQUEST,
    payload
});
