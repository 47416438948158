import {
    GET_SEARCH_DATA_SUCCESS,
    GET_SEARCH_DATA_REQUEST,
    RESET_SEARCH_DATA,
    INPUT_UPDATE,
    CLEAR_REDUCER
} from '../actionTypes/SearchActionTypes';

const initialState = {
    searchValue: '',
    isSuccess: false
};
export default function SearchReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_SEARCH_DATA_REQUEST:
            return {
                searchValue: action.searchValue,
                isSuccess: false
            };
        case GET_SEARCH_DATA_SUCCESS: {
            return {
                ...action.searchData,
                isSuccess: true
            };
        }
        case RESET_SEARCH_DATA: {
            return initialState;
        }
        case INPUT_UPDATE: {
            return {
                searchValue: action.searchValue,
                isSuccess: false
            };
        }
        case CLEAR_REDUCER: {
            return initialState
        }
        default:
            return state;
    }
}
