import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames/bind';
import Layout from '../../../components/site/Layout/Layout';
import BreadCrumbs from '../../../components/core/BreadCrumbs/BreadCrumbs';

import { getPageContent } from '../../../actions/PageContentAction';
import { getPromotionCalendarList, resetPromotionCalendar } from '../../../actions/PromotionsAction';
import {  PAGE_ID, PROMO_CALENDAR_L10N, UNIQUE_ID } from '../../../common/Constants';
import * as styles from './PromotionsCalendar.css';

import PromotionsCard from './PromotionsCard';

const cx = classNames.bind(styles);

export function PromotionsCalendar(props) {
    const { promotionsData, promoBanner, localization, } = props;

    useEffect(() => {
        props.actions.getPageContent({ pageId: PAGE_ID.PROMOTIONS_CALENDAR });
        props.actions.getPromotionCalendarList();
    }, []);

    return (
        <Layout showDashboardHeader showOutlet theme={cx('promotionContainer')}>
            {promotionsData && !promotionsData.isReset && (
                <div automation-id='at-promotions-calendar-screen' className={cx('promotionContent')}>
                    {!_isEmpty(props.breadcrumbs) && (
                        <BreadCrumbs 
                            breadcrumbValues={props.breadcrumbs} 
                            custombreadCrumbWrapper={cx('custombreadCrumbWrapper')}  
                        />
                    )}
                    <div className={cx('pageTitleContainer')}>
                        <h1 className={cx('pageTitle')}>{localization[PROMO_CALENDAR_L10N.SUBTITLE]}</h1>
                        <p className={cx('pageDescription')}>
                        {localization[PROMO_CALENDAR_L10N.DESCRIPTION]}
                    
                        </p>
                    </div>
                   
                    <div className={cx('promotionsContainer')}>
                        {!_isEmpty(promotionsData) &&
                            promotionsData.map((promo, index) => {
                               
                                return (
                                    <PromotionsCard key={promo.key} promo={promo} promoBanner={promoBanner} />
                                  
                                );
                            })}
                        {_isEmpty(promotionsData) && (
                            <div className={cx('noPromotions', 'clearfix')}>
                                {localization[PROMO_CALENDAR_L10N.NOPROMOTIONS]}
                            </div>
                        )}
                    </div>
            
                </div>
            )}
        </Layout>
    );
}

PromotionsCalendar.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func),
    topBanner: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    promoBanner: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    breadcrumbs: PropTypes.arrayOf(PropTypes.any),
    promotionsData: PropTypes.arrayOf(PropTypes.any),
    localization: PropTypes.objectOf(PropTypes.string),
    commonLocalization: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

PromotionsCalendar.defaultProps = {
    actions: {},
    topBanner: {},
    promoBanner: {},
    breadcrumbs: [],
    promotionsData: undefined,
    localization: {},
    commonLocalization: {}
};

const mapStateToProps = state => {
    const components = _get(state, 'pageContent.components', {});

    return {
        topBanner:
            _find(components, [UNIQUE_ID, PROMO_CALENDAR_L10N.TOP_BANNER_RESTRICTED]) ||
            _find(components, [UNIQUE_ID, PROMO_CALENDAR_L10N.TOP_BANNER]),
        promoBanner: _find(components, [UNIQUE_ID, PROMO_CALENDAR_L10N.PROMO_BANNER]),
        breadcrumbs: _get(state, 'pageContent.breadcrumbs'),
        promotionsData: _get(state, 'promotionsData.promoCalender'),
        localization: _get(state, 'pageContent.localization'),
        commonLocalization: _get(state, `pageContent.commonLocalization[${PAGE_ID.GENERIC}]`)
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getPageContent,
            getPromotionCalendarList,
            resetPromotionCalendar
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(PromotionsCalendar);
