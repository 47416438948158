import { useSelector } from 'react-redux';
import React from 'react';
import classNames from 'classnames/bind';
import styles from './BannerComponent.css';
import { FSA, FSA_IMAGES } from '../../../../common/Constants';
import { useDeviceType } from '../../../../common/Device/Device';

const cx = classNames.bind(styles);

const BannerComponent = () => {
    const localization = useSelector(state => state.pageContent.localization);
    const components = useSelector(state => state.pageContent.components);
    const assetBaseUrl = useSelector(state => state.context.environment.assetBaseUrl);
    
    const deviceType = useDeviceType();
    const getImageForDevice = () => {
        switch (deviceType) {
            case 'mobile':
                return components
                    .find(component => component.uid === FSA_IMAGES.BANNER_IMAGE_MOBILE)
                    ?.url?.replace('"', '');
            case 'desktop':
                return components.find(component => component.uid === FSA_IMAGES.BANNER_IMAGE)?.url?.replace('"', '');
            default:
                return components
                    .find(component => component.uid === FSA_IMAGES.BANNER_IMAGE_TABLET)
                    ?.url?.replace('"', '');
        }
    };

    return (
        <section
            className={cx('banner')}
            style={{
                backgroundImage: `url(${assetBaseUrl}${getImageForDevice()})`
            }}
        >
            <p>{localization[FSA.SECTION_ONE_TEXT_1]}</p>
        </section>
    );
};

export default BannerComponent;
