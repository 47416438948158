import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';
import {
    GET_ORDER_TEMPLATE_DETAILS_SUCCESS,
    UPDATE_ORDER_TEMPLATE_DETAILS_ITEM_QUANTITY,
    UPDATE_ORDER_DETAILS_TEMPLATE_DESCRIPTION,
    UPDATE_ORDER_DETAILS_TEMPLATE_NAME,
    CALCEL_ORDER_TEMPLATE_DETAILS,
    UPDATE_ORDER_TEMPLATE_SUCCESS,
    CLEAR_ORDER_TEMPLATE_SUCCESS,
    RESET_ORDER_TEMPLATE_DETAILS,
    DELETE_ORDER_TEMPLATE_ITEM_REQUEST,
    SET_ZERO_QTY_ERR,
    UPDATE_ITEM_SEQUENCE,
    GET_ORDER_ITEM_TEMPLATE_DETAILS_SUCCESS
} from '../actionTypes/OrderTemplateDetailsActionTypes';
import templateDetailsFillteredData from '../service/TemplateDetailsFilter';

/**
 * {
    name: string,
    id: string,
    dateSaved: string,
    qty: number,
    description: string,
    items: [
        {
            imageUrl: string,
            description: string,
            link: string,
            qty: number,
            id: string
        }
    ],
    status: {
      quantityUpdated: Bool,
      successMsg: string
    }
}
**/

const initialState = {};

export default function OrderTemplateDetailsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_ORDER_TEMPLATE_DETAILS_SUCCESS: {
            const transformedData = templateDetailsFillteredData(action.data);

            return {
                ...transformedData
            };
        }

        case GET_ORDER_ITEM_TEMPLATE_DETAILS_SUCCESS: {
            const newState = { ...state };
            const transformedData = templateDetailsFillteredData(action.data);
            newState.items = transformedData.items;
            return newState;
        }

        case UPDATE_ORDER_TEMPLATE_SUCCESS: {
            const newState = { ...state };
            newState.status = {
                quantityUpdated: !!action.message,
                successMsg: action.message
            };
            return newState;
        }

        case UPDATE_ORDER_TEMPLATE_DETAILS_ITEM_QUANTITY: {
            const newState = { ...state };
            const { id, qty } = action.payload;
            const itemIndex = findIndex(newState.items, item => {
                return item.id === id;
            });
            newState.items[itemIndex].qty = qty;
            newState.items[itemIndex].isZeroQty = false;
            return newState;
        }

        case UPDATE_ORDER_DETAILS_TEMPLATE_DESCRIPTION: {
            const newState = { ...state };
            newState.newDescription = action.payload.value;
            return newState;
        }

        case UPDATE_ORDER_DETAILS_TEMPLATE_NAME: {
            const newState = { ...state };
            newState.newName = action.payload.value;
            return newState;
        }

        case CALCEL_ORDER_TEMPLATE_DETAILS: {
            const newState = { ...state };
            newState.newName = '';
            newState.newDescription = '';
            newState.items = sortBy(newState.items, ['index']);
            newState.items = newState.items.map(item => {
                return { ...item, deleted: false, isZeroQty: false, qty: item.displayQty };
            });
            newState.nonDeletedItemCount = newState.items.length;
            return newState;
        }

        case CLEAR_ORDER_TEMPLATE_SUCCESS: {
            const newState = { ...state };
            newState.status = {
                quantityUpdated: false,
                successMsg: ''
            };
            return newState;
        }

        case DELETE_ORDER_TEMPLATE_ITEM_REQUEST: {
            const { id, isRemoveAll } = action.payload;
            const newState = { ...state };
            if (isRemoveAll) {
                newState.items = newState.items.map(item => {
                    return { ...item, deleted: true };
                });
                newState.nonDeletedItemCount = 0;
            } else {
                const itemIndex = findIndex(newState.items, item => {
                    return item.id === id;
                });
                newState.items[itemIndex].deleted = true;
                newState.nonDeletedItemCount = newState.items.filter(item => item.deleted !== true).length;
            }

            return newState;
        }

        case SET_ZERO_QTY_ERR: {
            const { id } = action.payload;
            const newState = { ...state };
            const itemIndex = findIndex(newState.items, item => {
                return item.id === id;
            });
            newState.items[itemIndex].isZeroQty = true;
            return newState;
        }

        case UPDATE_ITEM_SEQUENCE: {
            const { curIndex, nextIndex } = action.payload;
            const newState = { ...state };
            const nextObj = newState.items[nextIndex];
            newState.items[nextIndex] = newState.items[curIndex];
            newState.items[curIndex] = nextObj;
            return newState;
        }

        case RESET_ORDER_TEMPLATE_DETAILS:
            return initialState;

        default:
            return state;
    }
}
