import RestClient from '../RestClient/RestClient';
import RestConfig from '../RestClient/RestConfig';
import { PARAMS } from '../common/Api';

export default class UserMaintenanceApi {
    static getUserMaintenanceData(payload) {
        const { baseInfo } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}/my-account/user-management`;

        return RestClient.get(config).then(json => {
            return json;
        });
    }

    static submitAddNewUser(payload) {
        const { baseInfo = {}, params } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}/my-account/user-management-create`;
        config.data = params;

        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static updateEditUser(payload) {
        const { baseInfo = {}, params } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}/my-account/user-management-change`;
        config.data = params;

        return RestClient.put(config).then(json => {
            return json;
        });
    }

    static deleteFromUser(payload) {
        const { baseInfo = {}, params } = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}/my-account/user-management-delete`;
        config.params = params;

        return RestClient.delete(config).then(json => {
            return json;
        });
    }

    static submitSoftConsentFlag(payload) {
        const { baseInfo = {}} = payload; 
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}/users/${baseInfo[PARAMS.USER_ID]}/soft-consent`;
        
        return RestClient.post(config).then(json => {
            return json;
        });
    }

    static setMinicartMessageShown(payload) {
        const { baseInfo = {}} = payload;
        const config = RestConfig.v2ComApiConfig(payload);
        config.url = `/${baseInfo[PARAMS.SITE_ID]}/users/current/setminicartshown`;

        return RestClient.put(config).then(json => {
            return json;
        });
    }
}
