import _get from 'lodash/get';
import { FACT_SHEET_L10N } from '../common/Constants';

const factSheetFillteredData = factSheetDatas => {
    const factSheetLines = _get(factSheetDatas, 'factSheetLines', []);
    const creditDataLines = _get(factSheetDatas, 'creditDataLines', []);

    const creditData = [];
    for (let index = 0; index < creditDataLines.length; index++) {
        const { credit, creditDay, creditLimit, creditOverdue, outletNumber, timestamp, wholesaler } = creditDataLines[index];

        creditData.push({
            credit: credit,
            creditDay: creditDay,
            creditLimit: creditLimit,
            creditOverdue: creditOverdue,
            outletNumber: outletNumber,
            timestamp: timestamp,
            wholesaler: wholesaler
        })
    }

    const factSheetData = {};
    for (let i = 0; i < factSheetLines.length; i += 1) {
        if (factSheetLines[i].name === 'CORRESPONDANCE_ADDRESS') {
            const correspondanceAddress = _get(factSheetData, 'CORRESPONDANCE_ADDRESS', '');
            factSheetData[factSheetLines[i].name] = correspondanceAddress.concat(` ${factSheetLines[i].value}`);
        } else if (factSheetLines[i].name === 'BILLING_ADDRESS') {
            const billingAddress = _get(factSheetData, 'BILLING_ADDRESS', '');
            factSheetData[factSheetLines[i].name] = billingAddress.concat(` ${factSheetLines[i].value}`);
        } else {
            factSheetData[factSheetLines[i].name] = factSheetLines[i].value;
        }
    }

    const baseRespData = {
        title: '',
        data: {
            companyData: [],
            contact: [],
            location: [],
            customerData: [],
            commonData: []
        }
    };

    const iniItemData = {
        label: '',
        value: ''
    };

    baseRespData.title = _get(factSheetData, 'title', '');

    Object.keys(FACT_SHEET_L10N).forEach(key => {
        const itemData = { ...iniItemData };
        itemData.label = key;
        itemData.value = _get(factSheetData, key, '');

        switch (key) {
            case 'OUTLETID':
            case 'NAME1':
            case 'NAME2':
            case 'WEB_SITE':
                baseRespData.data.companyData.push(itemData);
                break;
            case 'PHONE':
            case 'FAX':
            case 'EMAIL':
                baseRespData.data.contact.push(itemData);
                break;
            case 'CORRESPONDANCE_ADDRESS':
            case 'BILLING_ADDRESS':
                baseRespData.data.location.push(itemData);
                break;
            case 'LEAD_TIME':
            case 'PAYMENTS_TERM':
                baseRespData.data.customerData.push(itemData);
                break;
            case 'BD_NAME':
            case 'BD_TEL_NUMBER':
                baseRespData.data.commonData.push(itemData);
                break;
            default:
                break;
        }
    });

    baseRespData.creditData = creditData;
    return baseRespData;
};

export default factSheetFillteredData;
