export const hu = {
  "hu": {
    "calendar": {
      "CalendarDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CalendarMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CalendarLabelToday": "Today",
      "CalendarLabelTomorrow": "Tomorrow",
      "CalendarTitle": "Schedule a Call",
      "CalendarOkButtonText": "Okay",
      "CalendarError": "Unable to fetch availability details.",
      "CalendarClose": "Cancel",
      "AriaWindowTitle": "Calendar Window",
      "AriaCalendarClose": "Cancel the Calendar and go back to the Callback Registration",
      "AriaYouHaveChosen": "You have chosen",
      "AriaNoTimeSlotsFound": "No time slots found for selected date"
    },
    "callus": {
      "CallUsTitle":"Hívjon minket",
      "ContactsHeader":"A kötekező telefonszámokon érhet el minket...",
      "CancelButtonText":"Visszavon",
      "CoBrowseText":"",
      "CoBrowse":"Közös munkamenet indítása",
      "CoBrowseWarning":"Az közös munkamenet indítása lehetővé teszi munkatársunk számára, hogy lássa és kontrollálja munkaterületét annak érdekében, hogy segíteni tudjon. Amikor munkatársunkkal élő hívásban van, kérjen egy kódot az közös munkamenet indításához és illessze azt be. Nincs még élő hívásban? Csak zárja be a jelenlegi ablakot, hogy visszatérhessen a 'Hívjon minket' oldalra.",
      "SubTitle":"A kötekező telefonszámokon érhet el minket...",
      "AriaWindowLabel":"Hívjon minket ablak",
      "AriaCallUsClose":"Hívás bezárása",
      "AriaBusinessHours":"Nyitvatartás",
      "AriaCallUsPhoneApp":"A telefon applikáció megnyitása",
      "AriaCobrowseLink":"Közös munkamenet indítása",
      "AriaCancelButtonText":"Hívás törlése",
    },
    "callback": {
      "CallbackTitle": "Receive a Call",
      "CancelButtonText": "Cancel",
      "AriaCancelButtonText": "Cancel",
      "ConfirmButtonText": "Confirm",
      "AriaConfirmButtonText": "Confirm",
      "CallbackFirstName": "First Name",
      "CallbackPlaceholderRequired": "Required",
      "CallbackPlaceholderOptional": "Optional",
      "CallbackLastName": "Last Name",
      "CallbackPhoneNumber": "Phone",
      "CallbackQuestion": "When should we call you?",
      "CallbackDayLabels": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "CallbackMonthLabels": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "CallbackConfirmDescription": "You're booked in!",
      "CallbackNumberDescription": "We will call you at the number provided:",
      "CallbackNotes": "Notes",
      "CallbackDone": "Close",
      "AriaCallbackDone": "Close",
      "CallbackOk": "Okay",
      "AriaCallbackOk": "Okay",
      "CallbackCloseConfirm": "Are you sure you want to cancel arranging this callback?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Yes",
      "AriaCallbackYesButtonText": "Yes",
      "CallbackWaitTime": "Wait Time",
      "CallbackWaitTimeText": "min wait",
      "CallbackOptionASAP": "As soon as possible",
      "CallbackOptionPickDateTime": "Pick date & time",
      "AriaCallbackOptionPickDateTime": "Opens a date picker",
      "CallbackPlaceholderCalendar": "Select Date & Time",
      "AriaMinimize": "Callback Minimize",
      "AriaWindowLabel": "Callback Window",
      "AriaMaximize": "Callback Maximize",
      "AriaClose": "Callback Close",
      "AriaCalendarClosedStatus": "Calendar is closed",
      "Errors": {
        "501": "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        "503": "Missing apikey, please ensure it is configured properly.",
        "1103": "Missing apikey, please ensure it is configured properly.",
        "7030": "Please enter a valid phone number.",
        "7036": "Callback to this number is not possible. Please retry with another phone number.",
        "7037": "Callback to this number is not allowed. Please retry with another phone number.",
        "7040": "Please configure a valid service name.",
        "7041": "Too many requests at this time.",
        "7042": "Office closed. Please try scheduling within the office hours.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "phoneNumberRequired": "Phone number is required."
      }
    },
    "channelselector": {
      "Title":"Élő segítség",
      "SubTitle":"Hogy szeretne kapcsolatba lépni velünk?",
      "WaitTimeTitle":"Várakozási idő",
      "AvailableTitle":"Elérhető",
      "AriaAvailableTitle":"Elérhető",
      "UnavailableTitle":"Nem eléhető",
      "CobrowseButtonText":"Közös munkamenet elnevezése",
      "CallbackTitle":"Visszahívás kérése",
      "CobrowseSubTitle":"Munkatársunk csatlakozása szükséges ehhez.",
      "AriaClose":"Élő segítség bezárása",
      "AriaWarning":"Figyelmeztetés",
      "AriaAlert":"Riasztás",
      "minute":"Perc",
      "minutes":"Percek",
      "AriaWindowLabel":"Élő segítség ablak",
    },
    "clicktocall": {
      "Title": "ClickToCall",
      "FirstName": "First Name",
      "PlaceholderRequired": "Required",
      "PlaceholderOptional": "Optional",
      "LastName": "Last Name",
      "PhoneNumber": "Phone",
      "WaitTime": "Wait Time",
      "FormCancel": "Cancel",
      "AriaFormCancel": "Cancel",
      "FormSubmit": "Request a number",
      "AriaFormSubmit": "Request a number",
      "PhoneLabel": "Dial in now",
      "AriaPhoneTitle": "Opens the phone application",
      "AccessLabel": "Access Code",
      "ExpireLabel": "Number Expires in",
      "AriaExpireLabel": "Number Expires in Timer",
      "DisplayClose": "Close",
      "AriaDisplayClose": "Close",
      "NetworkFail": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Phone number has expired!",
      "PhoneReRequest": "Request a new number",
      "AriaPhoneReRequest": "Request a new number",
      "LocalFormValidationEmptyPhoneNumber": "Please enter a phone number",
      "ConfirmCloseWindow": "You have unsubmitted form data. Are you sure you want to quit?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Yes",
      "ConfirmCloseConfirm": "Yes",
      "AriaWindowLabel": "Click To Call Window",
      "AriaMaximize": "Click To Call Maximize",
      "AriaMinimize": "Click To Call Minimize",
      "AriaClose": "Click To Call Close"
    },
    "cobrowse": {
      "agentJoined":"Munkatársunk csatlakozott a munkamenethez.",
      "youLeft":"Ön elhagyta a munkamenetet. A közös munkamenet bezárul.",
      "sessionTimedOut":"A közös munkamenet időtúllépés miatt bezárul.",
      "sessionInactiveTimedOut":"A közös munkamenet időtúllépés miatt bezárul.",
      "agentLeft":"Munkatársunk kilépett a közös munkamenetből, amely most bezárul.",
      "sessionError":"Váratlan hiba történt. A közös munkamenet bezárul.",
      "sessionStarted":"A közös munakament megkezdődött. Kérem várjon munkatársunk csatlakozásáig...",
      "navRefresh":"Munkatársunk frissítette az oldalt. Újratöltődik.",
      "navBack":"Munkatársunk megnyomta a 'vissza' gombot. Az oldal újratöltődik.",
      "navForward":"Munkatársunk megnyomta a 'előre' gombot. Az oldal újratöltődik",
      "navUrl":"Munkatársunk igényelte az irányítás átvételét. Az oldal újratöltődik.",
      "navFailed":"Munaktársunk irányítás iránti igénye nem jött lére.",
      "toolbarContent":"Munakement azonosító:",
      "contentMasked":"A tartalom rejtve marad a munkatársunk számára.",
      "contentMaskedPartially":"Néhány tartalom rejtett a munkatársunk számára.",
      "exitBtnTitle":"Kilépés a közös munkamenetből",
      "areYouOnPhone":"Telefonon beszél munkatársunkkal?",
      "areYouOnPhoneOrChat":"Telefonon beszél, vagy chat beszélgetést folytat munkatársunkkal?",
      "connectBeforeCobrowse":"A közös munkamenet érdekében kapcsolatba kell lépnie munkatársunkkal. Kérem hívjon minket vagy kezdeményezzen élő chat beszélgetést és akkor indíthatja el a közös munkamenetet újra.",
      "sessionStartedAutoConnect":"A közös munakament megkezdődött. Kérem várjon munkatársunk csatlakozásáig...",
      "browserUnsupported": "Sajnos nem támogatott böngészőt használ.<br><br> Támogatott böngészők: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 and above</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 and above</a></li></ul>",
      "chatIsAlreadyRunning":"A chat beszélgetés már folyamatban van egy másik böngésző ablakban.",
      "modalYes":"Igen",
      "modalNo":"Nem",
    },
    "knowledgecenter": {
      "SidebarButton": "Search",
      "SearchButton": "Search",
      "Title": "Ask a Question",
      "Ask": "Ask",
      "AriaAsk": "Ask",
      "Close": "Close",
      "AriaClose": "Search Close",
      "Categories": "Categories",
      "NoResults": "No Results",
      "NoResultsTextUnder": "We're sorry but we could not find a suitable answer for you.",
      "NoResultsTextRephrase": "Could you please try rephrasing the question?",
      "WasThisHelpful": "Was this helpful?",
      "Yes": "Yes",
      "No": "No",
      "AriaYes": "Yes",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Article Helpfulness - 'Yes'",
      "ArticleHelpfulnessYesDesc": "Great! We're very pleased to hear that the article assisted you in your search. Have a great day!",
      "ArticleHelpfulnessNo": "Article Helpfulness - 'No'",
      "ArticleHelpfulnessNoDesc": "We're sorry that the article wasn't a good match for your search. We thank you for your feedback!",
      "TypeYourQuestion": "Type your question",
      "Back": "Back",
      "AriaBack": "Back to the Search Results",
      "More": "More",
      "Error": "Error!",
      "GKCIsUnavailable": "Knowledge Center Server is currently not available.",
      "AriaClear": "Clear the Search Text",
      "AriaSearch": "Search",
      "AriaWindowLabel": "Search Window",
      "AriaSearchDropdown": "Suggested results",
      "AriaSearchMore": "Read more about",
      "AriaResultsCount": "Total number of results",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Hello and welcome! A Live agent will be with you shortly. In the meantime, can I assist you with any questions you may have? Please type a question into the input field below.",
      "SearchResult": "While waiting for an Agent to connect, here are the most relevant answers to your query:",
      "NoDocumentsFound": "I'm sorry. No articles matched your question. Would you like to ask another question?",
      "SuggestedMessage": "The following knowledge item has been suggested:",
      "OpenDocumentHint": "Click on it to view its content.",
      "SuggestedDocumentMessage": "The document '<%DocTitle%>' has been suggested.",
      "FeedbackQuestion": "Was this helpful?",
      "FeedbackAccept": "Yes",
      "FeedbackDecline": "No",
      "TranscriptMarker": "KnowledgeCenter: ",
      "SearchMessage": "Search with query '<%SearchQuery%>'↲",
      "VisitMessage": "Visit for document '<%VisitQuery%>'",
      "OpenMessage": "Viewed '<%OpenQuery%>'",
      "AnsweredMessage": "Results for query '<%AnsweredQuery%>' have been marked as relevant.",
      "UnansweredMessage": "Results for query '<%UnansweredQuery%>' have been marked as unanswered.",
      "PositiveVoteMessage": "Positive voting for document '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Negative voting for document '<%VoteQuery%>'.",
      "SuggestedMarker": "The document[^\\0]*?has been suggested."
    },
    "sendmessage": {
      "SendMessageButton": "Send Message",
      "OK": "OK",
      "Title": "Send Message",
      "PlaceholderFirstName": "Required",
      "PlaceholderLastName": "Required",
      "PlaceholderEmail": "Required",
      "PlaceholderSubject": "Required",
      "PlaceholderTypetexthere": "Type your message here...",
      "FirstName": "First Name",
      "LastName": "Last Name",
      "Email": "Email",
      "Subject": "Subject",
      "Attachfiles": "Attach files",
      "AriaAttachfiles": "Attach files link. Open a file upload dialog.",
      "Send": "Send",
      "AriaSend": "Send Message",
      "Sent": "Your message has been sent...",
      "Close": "Close",
      "ConfirmCloseWindow": "Are you sure you want to close the Send Message widget?",
      "Cancel": "Cancel",
      "AriaMinimize": "Send Message Minimize",
      "AriaMaximize": "Send Message Maximize",
      "AriaWindowLabel": "Send Message Window",
      "AriaClose": "Send Message Close",
      "AriaCloseAlert": "Alert box is closed",
      "AriaEndConfirm": "Yes",
      "AriaEndCancel": "Cancel",
      "AriaOK": "OK",
      "AriaRemoveFile": "Remove file",
      "AriaFileIcon": "File",
      "AriaFileSize": "File Size",
      "Errors": {
        "102": "First Name required.",
        "103": "Last Name required.",
        "104": "Subject required.",
        "181": "Email address required.",
        "182": "Message text content required.",
        "connectionError": "Unable to reach server. Please try again.",
        "unknownError": "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        "attachmentsLimit": "Total number of attachments exceeds limit: ",
        "attachmentsSize": "Total size of attachments exceeds limit: ",
        "invalidFileType": "Unsupported file type. Please upload images, PDFs, text files and ZIPs.",
        "invalidFromEmail": "Invalid email - From address.",
        "invalidMailbox": "Invalid email - To address."
      }
    },
    "sidebar": {
      "SidebarTitle":"Segíthetünk?",
      "ChannelSelectorName":"Élő segítség",
      "ChannelSelectorTitle":"Azonnali segítség igénylése munkatársunktól",
      "SearchName":"Keresés",
      "SearchTitle":"Keresés",
      "CallUsName":"Hívjon minket",
      "CallUsTitle":"Hívás részletei",
      "CallbackName":"Visszahívás",
      "CallbackTitle":"Hívás fogadása",
      "ClickToCallName":"Kattintson a hívás indításához",
      "ClickToCallTitle":"Vevőszolgálatunk elérhetőségéinek mutatása",
      "SendMessageName":"Üzenet küldése",
      "SendMessageTitle":"Üzenet küldése",
      "WebChatName":"Élő beszélgetés",
      "WebChatTitle":"Élő beszélgetés",
      "AriaClose":"A 'Segíthetünk' menü bezárása",
    },
    "webchat": {
      "ChatButton":"Chat beszélgetés",
      "ChatStarted":"A beszélgetés elkezdődött",
      "ChatEnded":"A beszélgetés befejeződött",
      "AgentNameDefault":"Munkatársunk",
      "AgentConnected":"Kapcsolódva",
      "AgentDisconnected":"Szétkapcsolva",
      "BotNameDefault":"Chat Bot",
      "BotConnected":"Kapcsolódva",
      "BotDisconnected":"Szétkapcsolva",
      "SupervisorNameDefault":"Vezető",
      "SupervisorConnected":"Kapcsolódva",
      "SupervisorDisconnected":"Szétkapcsolva",
      "AgentTyping":"...",
      "AriaAgentTyping":"Munkatársunk éppen gépel",
      "AgentUnavailable":"Sajnáljuk, jelenleg minden munkatársunk foglalt. Kérem próbálja később!",
      "ChatTitle":"Élő chat beszélgetés",
      "ChatEnd":"",
      "ChatClose":"",
      "ChatMinimize":"Minimalizálás",
      "ChatFormFirstName":"Keresztnév",
      "ChatFormLastName":"Vezetéknév",
      "ChatFormNickname":"Becenév",
      "ChatFormName":"Név",
      "ChatFormEmail":"Email",
      "ChatFormSubject":"Tárgy",
      "ChatFormPlaceholderName": "\"Nem kötelező\"",
      "ChatFormCustomerId": "Boltkód",
      "ChatFormPlaceholderCustomerId": "\"Nem kötelezo\"",
      "ChatFormSubjectPassword": "Jelszó",
      "ChatFormSubjectService": "Szervíz",
      "ChatFormSubjectOrder": "Rendelés info",
      "ChatFormSubjectFinance": "Számlák",
      "ChatFormPlaceholderFirstName":"Szükséges",
      "ChatFormPlaceholderLastName":"Szükséges",
      "ChatFormPlaceholderNickname":"Opcionális",
      "ChatFormPlaceholderEmail":"Szükséges",
      "ChatFormPlaceholderSubject":"Opcionális",
      "ChatFormSubmit":"Chat indítása",
      "AriaChatFormSubmit":"Chat indítása",
      "ChatFormCancel":"Visszavonás",
      "AriaChatFormCancel":"Beszélgetés visszavonása",
      "ChatFormClose":"Bezárás",
      "ChatInputPlaceholder":"Írja be üzenetét ide",
      "ChatInputSend":"Küldés",
      "AriaChatInputSend":"Küldés",
      "ChatEndQuestion":"Biztos benne, hogy be szeretné zárni a beszélgetést?",
      "ChatEndCancel":"Visszavonás",
      "ChatEndConfirm":"A beszélgetés befejezése",
      "AriaChatEndCancel":"Visszavonás",
      "AriaChatEndConfirm":"Befejezés",
      "ConfirmCloseWindow":"Biztos benne, hogy be szeretné zárni a beszélgetést?",
      "ConfirmCloseCancel":"Visszavonás",
      "ConfirmCloseConfirm":"Befejezés",
      "AriaConfirmCloseCancel":"Visszavonás",
      "AriaConfirmCloseConfirm":"Befejezés",
      "ActionsDownload":"A beszélgetés letöltése",
      "ActionsEmail":"A beszélgetés elküldése email-ben",
      "ActionsPrint":"A beszélgetés kinyomtatása",
      "ActionsCobrowseStart":"Új böngészőablak nyitása",
      "AriaActionsCobrowseStartTitle":"Új böngészőablak megnyitása",
      "ActionsSendFile":"Fájl csatolása",
      "AriaActionsSendFileTitle":"Fájl felöltés ablak megnyitása",
      "ActionsEmoji":"Hangulatjel küldése",
      "ActionsCobrowseStop":"A böngészőablak bezárása",
      "ActionsVideo":"Meghívó videó beszélgetésre",
      "ActionsTransfer":"Átküldés",
      "ActionsInvite":"Meghívó",
      "InstructionsTransfer":"Nyissa meg ezt a linket egy másik eszközön, amely elvezeti Önt a jelenlegi chat munkamenthez",
      "InstructionsInvite":"Ossza meg ezt a linket egy másik személlyel, amely elvezeti Őt a jelenlegi chat munkamenethez",
      "InviteTitle":"Segíthetünk?",
      "InviteBody":"Kérem jelezze, ha segítségre lenne szüksége.",
      "InviteReject":"Köszönöm nem",
      "InviteAccept":"A beszégetés megkezdése",
      "AriaInviteAccept":"A meghívó elfogadása és a chat beszélgetés megkezdése",
      "AriaInviteReject":"A meghívó elutasítása",
      "ChatError":"A beszélgetés indításánál hiba lépett fel. Kérem próbálja újra.",
      "ChatErrorButton":"OK",
      "AriaChatErrorButton":"OK",
      "ChatErrorPrimaryButton":"Igen",
      "ChatErrorDefaultButton":"Nem",
      "AriaChatErrorPrimaryButton":"Igen",
      "AriaChatErrorDefaultButton":"Nem",
      "DownloadButton":"Letöltés",
      "AriaDownloadButton":"Fájl letöltése",
      "FileSent":"Elküldve",
      "FileTransferRetry":"Újra",
      "AriaFileTransferRetry":"A fájl küldés megismétlése",
      "FileTransferError":"OK",
      "AriaFileTransferError":"OK",
      "FileTransferCancel":"Visszavonás",
      "AriaFileTransferCancel":"A fájl küldés visszavonása",
      "RestoreTimeoutTitle":"A beszélgetés befejeződött",
      "RestoreTimeoutBody":"Az előző beszélgetése időtúllépés miatt befejeződött. Szeretne új beszélgetést indítani?",
      "RestoreTimeoutReject":"Köszönöm nem",
      "RestoreTimeoutAccept":"Chat indítása",
      "AriaRestoreTimeoutAccept":"A meghívó elfogadása és a beszélgetés megkezdése",
      "AriaRestoreTimeoutReject":"A meghívás visszautasítása",
      "EndConfirmBody":"Tényleg szeretné megszakítani a beszélgetést?",
      "EndConfirmAccept":"A beszélgetés befejezése",
      "EndConfirmReject":"Visszavonás",
      "AriaEndConfirmAccept":"A beszélgetés befejezése",
      "AriaEndConfirmReject":"Visszavonás",
      "SurveyOfferQuestion":"Szeretne visszajelzést biztosítani a számunkra?",
      "ShowSurveyAccept":"Igen",
      "ShowSurveyReject":"Nem",
      "AriaShowSurveyAccept":"Igen",
      "AriaShowSurveyReject":"Nem",
      "UnreadMessagesTitle":"Olvasatlan",
      "AriaYouSaid":"Ön írta",
      "AriaSaid":"írta",
      "AriaSystemSaid":"Rendszerüzenet",
      "AriaWindowLabel":"Élő chat beszélgetés ablak",
      "AriaMinimize":"Élő beszélgetés minimalizálása",
      "AriaMaximize":"Élő beszélgetés maximalizálása",
      "AriaClose":"Élő beszélgetés bezárása",
      "AriaEmojiStatusOpen":"Hangulatjel kiválasztó ablak megnyílt",
      "AriaEmojiStatusClose":"Hangulatjel kiválasztó ablak bezáródott",
      "AriaEmoji":"Hangulatjel",
      "AriaEmojiPicker":"Hangulatjel választó",
      "AriaCharRemaining":"Hátra lévő karakterek száma",
      "AriaMessageInput":"Üzenetküldő doboz",
      "AsyncChatEnd":"A beszélgetés befejezése",
      "AsyncChatClose":"Az ablak bezárása",
      "AriaAsyncChatEnd":"A beszélgetés befejezése",
      "AriaAsyncChatClose":"Az ablak bezárása",
      "DayLabels":[
        "vasárnap",
        "hétfő",
        "kedd",
        "szerda",
        "csütörtök",
        "péntek",
        "szombat",
      ],
      "MonthLabels": [
        "Jan.",
        "Febr.",
        "Márc.",
        "Ápr.",
        "Máj.",
        "Jún.",
        "Júl.",
        "Aug.",
        "Szept.",
        "Okt.",
        "Nov.",
        "Dec.",
      ],
      "todayLabel":"Mai nap",
      "Errors": {
        "102":"A keresztnév szükséges",
        "103":"A vezetéknév szükséges",
        "161":"Kérem írja be a nevét",
        "201": "A fájlküldés sikertelen.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Túllépte a maximálisan csatolható fájlok számát (<%MaxFilesAllowed%>).</p>",
        "202": "A fájlküldés sikertelen.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Túllépte a maximálisan csatolható fájlok számát és / vagy azok méretét (<%MaxAttachmentsSize%>).</p>",
        "203": "A fájlküldés sikertelen.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>A fájl kiterjesztése nem megengedett.</p>",
        "204":"Sajnáljuk, de az üzenet mérete túl hosszú. Kérem rövidítse le.",
        "240":"Sajnáljuk, de nem tudunk új beszélgetés indítani. Kérem próbálja meg később.",
        "364":"Helytelen email cím",
        "401":"Sajnáljuk, de nem tudtunk elindítani a beszélgetést. Megpróbálja újra?",
        "404":"Sajnáljuk, de nem sikerült megtalálni az előző chat beszélgetés munkamenetét. Szeretne egy újat kezdeményezni?",
        "500":"Sajnáljuk, de váratlan hiba történt. Szeretné bezárni a jelenlegi chat beszélgetését és újat indítani?",
        "503":"Sajnáljuk, de szolgáltatásunk jelenleg nem elérhető. Kérjük próbálja meg később.",
        "ChatUnavailable":"Sajnáljuk, de jelenleg nem tudunk új chat beszélgetést indítani. Kérjük próbálja meg később.",
        "CriticalFault":"A chat beszélgetés munkamenete váratlan hiba miatt megszakadt. Elnézést kérünk a kellemetlenségért!",
        "StartFailed":"Sajnáljuk, váratlan hiba történt. Kérem ellenőrízze a kapcsolatot, valamint azt, hogy benyújtotta az összes szükséges információt és ezután kérem próbálja újra.",
        "MessageFailed":"Üzenetküldése sikertelen. Kérem küldje újra.",
        "RestoreFailed":"Sajnáljuk, de váratlan hiba miatt a chat beszélgetésének munkamenetét nem tudjuk visszaállítani.",
        "TransferFailed":"Nem sikerült a chat beszélgetésbe való átirányítás. Kérem práblja meg később.",
        "FileTransferSizeError": "A fájlküldés sikertelen.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>A fájl mérete nagyobb a megengedett értéknél. (<%MaxSizePerFile%>).</p>",
        "InviteFailed":"Nem sikerült a meghívót legenerálni. Kérjük próbálja meg később.",
        "ChatServerWentOffline":"A üzenetek átvitele a szokásosnál lassabb. Elnézést kérünk a hiáért.",
        "RestoredOffline":"A üzenetek átvitele a szokásosnál lassabb. Elnézést kérünk a hiáért.",
        "Disconnected": "<div style='text-align:center'>A kapcsolat megszakadt</div>",
        "Reconnected": "<div style='text-align:center'>A kapcsolat helyreállt</div>",
        "FileSendFailed": "A fájl küldés sikertelen.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>A folyamat váratlanul megszakadt. Megpróbálja újra?</p>",
        "Generic": "<div style='text-align:center'>Váratlan hiba történt.</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE":"Helytelen fájl kiterjesztés. Csak képek feltöltése lehetséges.",
        "pureengage-v3-rest-LIMIT_FILE_SIZE":"A fájl mérete túllépte a megendett határt.",
        "pureengage-v3-rest-LIMIT_FILE_COUNT":"A fájlok számának értéke túllépte a megengedett értéket.",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER":"Érvénytelen x-api-key transzfer konfiguráció.",
        "pureengage-v3-rest-INVALID_ENDPOINT":"Érvénytelen végpont transzfer konfiguráció.",
        "pureengage-v3-rest-INVALID_NICKNAME":"A keresztnév szükséges",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED":"Sajnáljuk, de nem tudjuk elindítani a chat beszélgetés munkamenetet.",
        "purecloud-v2-sockets-400":"Sajnáljuk, váratlan hiba történt. Kérem ellenőrízze a kapcsolatot, valamint azt, hogy benyújtotta az összes szükséges információt és ezután kérem próbálja újra.",
        "purecloud-v2-sockets-500":"Sajnáljuk, hogy szolgáltatásunk váratlan hiba lépett fel.",
        "purecloud-v2-sockets-503":"Sajnáljuk, de szolgáltatásunk jelenleg nem elérhető."
      }
    }
  }
}