export const it = {
  "it": {
    "calendar": {
      "CalendarDayLabels": [
        "Domenica",
        "Lunedì",
        "Martedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato"
      ],
      "CalendarMonthLabels": [
        "gen",
        "feb",
        "mar",
        "apr",
        "mag",
        "giu",
        "lug",
        "ago",
        "Sett",
        "ott",
        "nov",
        "dic"
      ],
      "CalendarLabelToday": "Oggi",
      "CalendarLabelTomorrow": "Domani",
      "CalendarTitle": "Programma una chiamata",
      "CalendarOkButtonText": "OK",
      "CalendarError": "Impossibile recuperare i dati sulla disponibilità.",
      "CalendarClose": "Annulla",
      "AriaWindowTitle": "Finestra Calendario",
      "AriaCalendarClose": "Annullare il Calendario e tornare alla registrazione Richiamata",
      "AriaYouHaveChosen": "Si è scelto",
      "AriaNoTimeSlotsFound": "Non è stato rilevato alcuno slot temporale per la data selezionata"
    },
    "callus": {
      "CallUsTitle": "Chiamaci",
      "ContactsHeader": "Puoi contattarci a uno dei seguenti numeri...",
      "CancelButtonText": "Annulla",
      "CoBrowseText": "<span class='cx-cobrowse-offer'>Stai già realizzando una chiamata? Permettici <a role='link' tabindex='0' class='cx-cobrowse-link'>di navigare con te</a></span>",
      "CoBrowse": "Avvia navigazione condivisa",
      "CoBrowseWarning": "La navigazione condivisa consente all'agente di visualizzare e controllare il tuo desktop, per guidarti. Se sei al telefono con un agente, richiedi un codice per avviare la navigazione condivisa e inseriscilo qui in basso. Non hai ancora chiamato? Esci da questa schermata per tornare alla pagina Chiamaci.",
      "SubTitle": "Puoi contattarci a uno dei seguenti numeri...",
      "AriaWindowLabel": "Finestra Chiamaci",
      "AriaCallUsClose": "Chiudi Chiamaci",
      "AriaBusinessHours": "Orari di lavoro",
      "AriaCallUsPhoneApp": "Apre l'applicazione telefono",
      "AriaCobrowseLink": "Apre la sessione Navigazione condivisa",
      "AriaCancelButtonText": "Annulla Chiamaci"
    },
    "callback": {
      "CallbackTitle": "Ricevi una chiamata",
      "CancelButtonText": "Annulla",
      "AriaCancelButtonText": "Annulla",
      "ConfirmButtonText": "Conferma",
      "AriaConfirmButtonText": "Conferma",
      "CallbackFirstName": "Nome",
      "CallbackPlaceholderRequired": "Obbligatorio",
      "CallbackPlaceholderOptional": "Facoltativo",
      "CallbackLastName": "Cognome",
      "CallbackPhoneNumber": "Telefono",
      "CallbackQuestion": "Quando vuoi essere chiamato?",
      "CallbackDayLabels": [
        "Domenica",
        "Lunedì",
        "Martedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato"
      ],
      "CallbackMonthLabels": [
        "gen",
        "feb",
        "mar",
        "apr",
        "mag",
        "giu",
        "lug",
        "ago",
        "set",
        "ott",
        "nov",
        "dic"
      ],
      "CallbackConfirmDescription": "La prenotazione è stata completata!",
      "CallbackNumberDescription": "Ti chiameremo al numero fornito:",
      "CallbackNotes": "Note",
      "CallbackDone": "Chiudi",
      "AriaCallbackDone": "Chiudi",
      "CallbackOk": "OK",
      "AriaCallbackOk": "OK",
      "CallbackCloseConfirm": "Annullare la programmazione della richiamata?",
      "CallbackNoButtonText": "No",
      "AriaCallbackNoButtonText": "No",
      "CallbackYesButtonText": "Sì",
      "AriaCallbackYesButtonText": "Sì",
      "CallbackWaitTime": "Tempo di attesa",
      "CallbackWaitTimeText": "attesa min.",
      "CallbackOptionASAP": "Quanto prima",
      "CallbackOptionPickDateTime": "Scegli data e ora",
      "AriaCallbackOptionPickDateTime": "Apre un selettore date",
      "CallbackPlaceholderCalendar": "Seleziona data e ora",
      "AriaMinimize": "Riduci a icona Richiamata",
      "AriaWindowLabel": "Finestra Richiamata",
      "AriaMaximize": "Ingrandisci richiamata",
      "AriaClose": "Chiudi Richiamata",
      "AriaCalendarClosedStatus": "Il Calendario è chiuso",
      "Errors": {
        "501": "Impossibile accettare parametri non validi, controlla la documentazione dell'API del server di supporto per i parametri validi.",
        "503": "Chiave api mancante, assicurati che sia configurata correttamente.",
        "1103": "Chiave api mancante, assicurati che sia configurata correttamente.",
        "7030": "Inserisci un numero di telefono valido.",
        "7036": "Non è possibile richiamare questo numero. Riprova con un altro numero di telefono.",
        "7037": "La richiamata a questo numero non è consentita. Riprova con un altro numero di telefono.",
        "7040": "Configura un nome valido per il servizio.",
        "7041": "Troppe richieste in questo momento.",
        "7042": "Ufficio chiuso. Prova a programmare in orario d'ufficio.",
        "unknownError": "Si è verificato un errore, ci scusiamo per l'inconveniente. Controlla le impostazioni della connessione e riprova.",
        "phoneNumberRequired": "Il numero di telefono è richiesto."
      }
    },
    "channelselector": {
      "Title": "Assistenza live",
      "SubTitle": "Come preferisci contattarci?",
      "WaitTimeTitle": "Tempo di attesa",
      "AvailableTitle": "Disponibile",
      "AriaAvailableTitle": "Disponibile",
      "UnavailableTitle": "Non disponibile",
      "CobrowseButtonText": "CobrowseSubTitle",
      "CallbackTitle": "Ricevi una chiamata",
      "CobrowseSubTitle": "Per questa operazione è richiesta la connessione di un agente.",
      "AriaClose": "Chiudi Assistenza live",
      "AriaWarning": "Avvertenza",
      "AriaAlert": "Avviso",
      "minute": "min",
      "minutes": "min",
      "AriaWindowLabel": "Finestra Assistenza live"
    },
    "clicktocall": {
      "Title": "Fai clic per chiamare",
      "FirstName": "Nome",
      "PlaceholderRequired": "Obbligatorio",
      "PlaceholderOptional": "Facoltativo",
      "LastName": "Cognome",
      "PhoneNumber": "Telefono",
      "WaitTime": "Tempo di attesa",
      "FormCancel": "Annulla",
      "AriaFormCancel": "Annulla",
      "FormSubmit": "Richiedi un numero",
      "AriaFormSubmit": "Richiedi un numero",
      "PhoneLabel": "Connettiti ora",
      "AriaPhoneTitle": "Apre l'applicazione telefono",
      "AccessLabel": "Codice di accesso",
      "ExpireLabel": "Il numero scade tra",
      "AriaExpireLabel": "Timer Il numero scade tra",
      "DisplayClose": "Chiudi",
      "AriaDisplayClose": "Chiudi",
      "NetworkFail": "Si è verificato un errore, ci scusiamo per l'inconveniente. Controlla le impostazioni della connessione e riprova.",
      "NetworkRetry": "OK",
      "AriaNetworkRetry": "OK",
      "InvalidAccept": "OK",
      "AriaInvalidAccept": "OK",
      "PhoneExpired": "Il numero di telefono è scaduto!",
      "PhoneReRequest": "Richiedi un nuovo numero",
      "AriaPhoneReRequest": "Richiedi un nuovo numero",
      "LocalFormValidationEmptyPhoneNumber": "Inserisci un numero di telefono",
      "ConfirmCloseWindow": "Hai inviato dati del modulo. Desideri uscire?",
      "AriaConfirmCloseCancel": "No",
      "ConfirmCloseCancel": "No",
      "AriaConfirmCloseConfirm": "Sì",
      "ConfirmCloseConfirm": "Sì",
      "AriaWindowLabel": "Finestra Fai clic per chiamare",
      "AriaMaximize": "Ingrandisci Fai clic per chiamare",
      "AriaMinimize": "Riduci a icona Fai clic per richiamare",
      "AriaClose": "Chiudi Fai clic per richiamare"
    },
    "cobrowse": {
      "agentJoined": "Il rappresentante si è unito alla sessione.",
      "youLeft": "Hai abbandonato la sessione. La navigazione condivisa è terminata.",
      "sessionTimedOut": "La sessione è scaduta. La navigazione condivisa è terminata.",
      "sessionInactiveTimedOut": "La sessione è scaduta. La navigazione condivisa è terminata.",
      "agentLeft": "Il rappresentante ha abbandonato la sessione. La navigazione condivisa è stata terminata.",
      "sessionError": "Si è verificato un errore imprevisto. La navigazione condivisa è terminata.",
      "sessionStarted": "Sessione di navigazione condivisa avviata. In attesa che un rappresentante si connetta alla sessione…",
      "navRefresh": "Il rappresentante ha aggiornato la pagina. Caricamento in corso.",
      "navBack": "Il rappresentante ha premuto il pulsante \"Indietro\". Caricamento pagina in corso.",
      "navForward": "Il rappresentante ha premuto il pulsante \"Avanti\". Caricamento pagina in corso.",
      "navUrl": "Il rappresentante ha richiesto la navigazione. Caricamento della pagina in corso.",
      "navFailed": "Richiesta di navigazione del rappresentante non riuscita.",
      "toolbarContent": "ID sessione: {sessionId}",
      "contentMasked": "Il contenuto non viene visualizzato dal rappresentante.",
      "contentMaskedPartially": "Alcuni contenuti sono nascosti al rappresentante.",
      "exitBtnTitle": "Esci dalla sessione di navigazione condivisa",
      "areYouOnPhone": "Sei al telefono con uno dei nostri rappresentanti?",
      "areYouOnPhoneOrChat": "Stai parlando al telefono o per chat con uno dei nostri rappresentanti?",
      "connectBeforeCobrowse": "Per continuare la navigazione condivisa devi essere connesso con un nostro rappresentante. Chiamaci o avvia una chat live, quindi avvia di nuovo la navigazione condivisa.",
      "sessionStartedAutoConnect": "Sessione di navigazione condivisa avviata. In attesa che un rappresentante si connetta alla sessione…",
      "browserUnsupported": "Sfortunatamente, il tuo browser non è supportato al momento.<br><br> I browser supportati sono: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 e versioni successive</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 e versioni successive</a></li></ul>",
      "chatIsAlreadyRunning": "La chat è già in esecuzione in un'altra pagina.",
      "modalYes": "Sì",
      "modalNo": "No"
    },
    "knowledgecenter": {
      "SidebarButton": "Cerca",
      "SearchButton": "Cerca",
      "Title": "Fai una domanda",
      "Ask": "Chiedi",
      "AriaAsk": "Chiedi",
      "Close": "Chiudi",
      "AriaClose": "Chiudi Cerca",
      "Categories": "Categorie",
      "NoResults": "Nessun risultato",
      "NoResultsTextUnder": "Spiacenti, non è stato possibile trovare una risposta adeguata.",
      "NoResultsTextRephrase": "Puoi provare a riformulare la domanda?",
      "WasThisHelpful": "Ti è risultato utile?",
      "Yes": "Sì",
      "No": "No",
      "AriaYes": "Sì",
      "AriaNo": "No",
      "ArticleHelpfulnessYes": "Utilità articolo - 'Sì'",
      "ArticleHelpfulnessYesDesc": "Fantastico! Siamo lieti che l'articolo ti sia stato utile per la tua ricerca. Buona giornata!",
      "ArticleHelpfulnessNo": "Utilità articolo - 'No'",
      "ArticleHelpfulnessNoDesc": "Siamo spiacenti che l'articolo non fosse utile per la tua ricerca. Grazie per il feedback!",
      "TypeYourQuestion": "Scrivi la tua domanda",
      "Back": "Indietro",
      "AriaBack": "Torna ai risultati della ricerca",
      "More": "Altro",
      "Error": "Errore!",
      "GKCIsUnavailable": "Il server del Knowledge Center al momento non è disponibile.",
      "AriaClear": "Cancella il testo della ricerca",
      "AriaSearch": "Cerca",
      "AriaWindowLabel": "Finestra Cerca",
      "AriaSearchDropdown": "Risultati suggeriti",
      "AriaSearchMore": "Leggi di più su",
      "AriaResultsCount": "Numero totale di risultati",
      "KnowledgeAgentName": "Knowledge Center",
      "WelcomeMessage": "Ciao e benvenuto! Un agente ti assisterà a breve. Nel frattempo, posso aiutarti con qualsiasi dubbio o domanda? Inserisci la domanda nel campo di immissione qui sotto.",
      "SearchResult": "Mentre attendi che si connetta un agente, ecco le risposte più rilevanti alla tua query:",
      "NoDocumentsFound": "Spiacenti. Non ci sono articoli che corrispondano alla tua domanda. Vuoi fare un'altra domanda?",
      "SuggestedMessage": "È stato suggerito il seguente elemento della Knowledge Base:",
      "OpenDocumentHint": "fai clic per visualizzarne il contenuto",
      "SuggestedDocumentMessage": "È stato suggerito il documento '<%DocTitle%>'",
      "FeedbackQuestion": "Ti è risultato utile?",
      "FeedbackAccept": "Sì",
      "FeedbackDecline": "No",
      "TranscriptMarker": "Knowledge Center: ",
      "SearchMessage": "Cerca con la query '<%SearchQuery%>'↲",
      "VisitMessage": "Visita per il documento '<%VisitQuery%>'",
      "OpenMessage": "Visualizzato '<%OpenQuery%>'",
      "AnsweredMessage": "I risultati della query '<%AnsweredQuery%>' sono stati contrassegnati come rilevanti.",
      "UnansweredMessage": "I risultati della query '<%UnansweredQuery%>' sono stati contrassegnati come non risposti.",
      "PositiveVoteMessage": "Voto positivo per il documento '<%VoteQuery%>'.",
      "NegativeVoteMessage": "Voto negativo per il documento '<%VoteQuery%>'.",
      "SuggestedMarker": "È stato suggerito il documento[^\\0]*?"
    },
    "sendmessage": {
      "SendMessageButton": "Invia messaggio",
      "OK": "OK",
      "Title": "Invia messaggio",
      "PlaceholderFirstName": "Obbligatorio",
      "PlaceholderLastName": "Obbligatorio",
      "PlaceholderEmail": "Obbligatorio",
      "PlaceholderSubject": "Obbligatorio",
      "PlaceholderTypetexthere": "Scrivi qui il tuo messaggio...",
      "FirstName": "Nome",
      "LastName": "Cognome",
      "Email": "E-mail",
      "Subject": "Oggetto",
      "Attachfiles": "Allega file",
      "AriaAttachfiles": "Allega collegamento file, Apre una finestra di dialogo Upload file",
      "Send": "Invia",
      "AriaSend": "Invia messaggio",
      "Sent": "Il tuo messaggio è stato inviato...",
      "Close": "Chiudi",
      "ConfirmCloseWindow": "Chiudere il widget Invia messaggio?",
      "Cancel": "Annulla",
      "AriaMinimize": "Riduci a icona Invia messaggio",
      "AriaMaximize": "Ingrandisci Invia messaggio",
      "AriaWindowLabel": "Finestra Invia messaggio",
      "AriaClose": "Chiudi Invia messaggio",
      "AriaCloseAlert": "Casella di avviso chiusa",
      "AriaEndConfirm": "Sì",
      "AriaEndCancel": "Annulla",
      "AriaOK": "OK",
      "AriaRemoveFile": "Rimuovi file",
      "AriaFileIcon": "File",
      "AriaFileSize": "Dimensioni file",
      "Errors": {
        "102": "Nome richiesto",
        "103": "Cognome richiesto",
        "104": "Oggetto richiesto",
        "181": "Indirizzo e-mail richiesto",
        "182": "Testo del contenuto del messaggio richiesto",
        "connectionError": "Impossibile connettersi al server. Riprova.",
        "unknowError": "Si è verificato un errore, ci scusiamo per l'inconveniente. Controlla le impostazioni della connessione e riprova.",
        "attachmentsLimit": "Il numero totale di allegati supera il limite: ",
        "attachmentsSize": "Le dimensioni totali degli allegati superano il limite: ",
        "invalidFileType": "Tipo di file non supportato. Carica immagini, PDF, file di testo e file ZIP.",
        "invalidFromEmail": "E-mail non valida - Indirizzo mittente.",
        "invalidMailbox": "E-mail non valida - Indirizzo destinatario."
      }
    },
    "sidebar": {
      "SidebarTitle": "Bisogno di aiuto?",
      "ChannelSelectorName": "Assistenza live",
      "ChannelSelectorTitle": "Ottieni subito assistenza da uno dei nostri agenti",
      "SearchName": "Cerca",
      "SearchTitle": "Cerca",
      "OffersName": "Offerte",
      "OffersTitle": "Offerte",
      "CallUsName": "Chiamaci",
      "CallUsTitle": "Dettagli per chiamarci",
      "CallbackName": "Richiamata",
      "CallbackTitle": "Ricevi una chiamata",
      "ClickToCallName": "Fai clic per chiamare",
      "ClickToCallTitle": "Richiedi un numero telefonico di assistenza clienti",
      "SendMessageName": "Invia messaggio",
      "SendMessageTitle": "Invia messaggio",
      "WebChatName": "Chat live",
      "WebChatTitle": "Chat live",
      "AriaClose": "Chiudi menu Bisogno di aiuto?"
    },
    "webchat": {
      "ChatButton": "Chat",
      "ChatStarted": "Chat avviata",
      "ChatEnded": "Chat terminata",
      "AgentNameDefault": "Agente",
      "AgentConnected": "<%Agent%> connesso",
      "AgentDisconnected": "<%Agent%> disconnesso",
      "BotNameDefault": "Bot",
      "BotConnected": "<%Bot%> connesso",
      "BotDisconnected": "<%Bot%> disconnesso",
      "SupervisorNameDefault": "Supervisore",
      "SupervisorConnected": "<%Agent%> connesso",
      "SupervisorDisconnected": "<%Agent%> disconnesso",
      "AgentTyping": "...",
      "AriaAgentTyping": "L'agente sta scrivendo",
      "AgentUnavailable": "Spiacenti, non ci sono agenti disponibili. Riprova più tardi.",
      "ChatTitle": "Chat live",
      "ChatEnd": "X",
      "ChatClose": "X",
      "ChatMinimize": "Min",
	    "ChatFormName": "Nome",
      "ChatFormFirstName": "Nome",
      "ChatFormLastName": "Cognome",
      "ChatFormNickname": "Nickname",
	    "ChatFormCustomerId": "ID cliente",
      "ChatFormEmail": "E-mail",
      "ChatFormSubject": "Oggetto",
	    "ChatFormSubjectPassword": "Password",
	    "ChatFormSubjectService": "Servizio",
	    "ChatFormSubjectOrder": "Ordine",
	    "ChatFormSubjectFinance": "Finanza",
	    "ChatFormPlaceholderName": "Facoltativo",
      "ChatFormPlaceholderFirstName": "Obbligatorio",
      "ChatFormPlaceholderLastName": "Obbligatorio",
      "ChatFormPlaceholderNickname": "Facoltativo",
      "ChatFormPlaceholderEmail": "Obbligatorio",
      "ChatFormPlaceholderSubject": "Facoltativo",
	  "ChatFormPlaceholderCustomerId": "Facoltativo",
      "ChatFormSubmit": "Avvia chat",
      "AriaChatFormSubmit": "Avvia chat",
      "ChatFormCancel": "Annulla",
      "AriaChatFormCancel": "Annulla chat",
      "ChatFormClose": "Chiudi",
      "ChatInputPlaceholder": "Scrivi qui il tuo messaggio",
      "ChatInputSend": "Invia",
      "AriaChatInputSend": "Invia",
      "ChatEndQuestion": "Terminare la sessione della chat?",
      "ChatEndCancel": "Annulla",
      "ChatEndConfirm": "Termina chat",
      "AriaChatEndCancel": "Annulla",
      "AriaChatEndConfirm": "Termina",
      "ConfirmCloseWindow": "Chiudere la chat?",
      "ConfirmCloseCancel": "Annulla",
      "ConfirmCloseConfirm": "Chiudi",
      "AriaConfirmCloseCancel": "Annulla",
      "AriaConfirmCloseConfirm": "Chiudi",
      "ActionsDownload": "Scarica trascrizione",
      "ActionsEmail": "Trascrizione e-mail",
      "ActionsPrint": "Stampa trascrizione",
      "ActionsCobrowseStart": "Avvia navigazione condivisa",
      "AriaActionsCobrowseStartTitle": "Apre la sessione Navigazione condivisa",
      "ActionsSendFile": "Allega file",
      "AriaActionsSendFileTitle": "Allega collegamento file, Apre una finestra di dialogo Upload file",
      "ActionsEmoji": "Invia emoji",
      "ActionsCobrowseStop": "Esci da navigazione condivisa",
      "ActionsVideo": "Invita a chat video",
      "ActionsTransfer": "Trasferisci",
      "ActionsInvite": "Invita",
      "InstructionsTransfer": "Apri questo link su un altro dispositivo per trasferire la sessione della chat</br></br><%link%>",
      "InstructionsInvite": "Condividi il link con un'altra persona per aggiungerla a questa sessione della chat</br></br><%link%>",
      "InviteTitle": "Bisogno di aiuto?",
      "InviteBody": "Facci sapere se ti possiamo aiutare.",
      "InviteReject": "No grazie",
      "InviteAccept": "Avvia chat",
      "AriaInviteAccept": "Accetta invito e inizia a chattare",
      "AriaInviteReject": "Rifiuta invito",
      "ChatError": "Si è verificato un problema durante l'avvio della sessione chat. Riprova.",
      "ChatErrorButton": "OK",
      "AriaChatErrorButton": "OK",
      "ChatErrorPrimaryButton": "Sì",
      "ChatErrorDefaultButton": "No",
      "AriaChatErrorPrimaryButton": "Sì",
      "AriaChatErrorDefaultButton": "No",
      "DownloadButton": "Scarica",
      "AriaDownloadButton": "Scarica il file",
      "FileSent": "ha inviato:",
      "FileTransferRetry": "Riprova",
      "AriaFileTransferRetry": "Riprova a trasferire il file",
      "FileTransferError": "OK",
      "AriaFileTransferError": "OK",
      "FileTransferCancel": "Annulla",
      "AriaFileTransferCancel": "Annulla trasferimento file",
      "RestoreTimeoutTitle": "Chat terminata",
      "RestoreTimeoutBody": "La sessione della chat precedente è scaduta. Vuoi avviarne una nuova?",
      "RestoreTimeoutReject": "No grazie",
      "RestoreTimeoutAccept": "Avvia chat",
      "AriaRestoreTimeoutAccept": "Accetta invito e inizia a chattare",
      "AriaRestoreTimeoutReject": "Rifiuta invito",
      "EndConfirmBody": "Vuoi davvero terminare la sessione della chat?",
      "EndConfirmAccept": "Termina chat",
      "EndConfirmReject": "Annulla",
      "AriaEndConfirmAccept": "Termina chat",
      "AriaEndConfirmReject": "Annulla",
      "SurveyOfferQuestion": "Vuoi partecipare a un sondaggio?",
      "ShowSurveyAccept": "Sì",
      "ShowSurveyReject": "No",
      "AriaShowSurveyAccept": "Sì",
      "AriaShowSurveyReject": "No",
      "UnreadMessagesTitle": "non letto",
      "AriaYouSaid": "Hai detto",
      "AriaSaid": "ha detto",
      "AriaSystemSaid": "Messaggio di sistema:",
      "AriaWindowLabel": "Finestra Chat live",
      "AriaMinimize": "Riduci a icona chat live",
      "AriaMaximize": "Ingrandisci chat live",
      "AriaClose": "Chiudi chat live",
      "AriaEmojiStatusOpen": "Finestra di dialogo Selettore emoji aperta",
      "AriaEmojiStatusClose": "Finestra di dialogo Selettore emoji chiusa",
      "AriaEmoji": "emoji",
      "AriaEmojiPicker": "Selettore emoji",
      "AriaCharRemaining": "Caratteri rimanenti",
      "AriaMessageInput": "Casella messaggi",
      "AsyncChatEnd": "Termina chat",
      "AsyncChatClose": "Chiudi finestra",
      "AriaAsyncChatEnd": "Termina chat",
      "AriaAsyncChatClose": "Chiudi finestra",
      "DayLabels": [
        "do",
        "lu",
        "ma",
        "me",
        "Gio",
        "ve",
        "sa"
      ],
      "MonthLabels": [
        "gen",
        "feb",
        "mar",
        "apr",
        "mag",
        "giu",
        "lug",
        "ago",
        "Sett",
        "ott",
        "nov",
        "dic"
      ],
      "todayLabel": "Oggi",
      "Errors": {
        "102": "Il nome è richiesto",
        "103": "Il cognome è richiesto",
        "161": "Inserisci il tuo nome",
        "201": "Impossibile inviare il file.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>È stato superato il numero massimo di file allegati (<%MaxFilesAllowed%>)</p>",
        "202": "Impossibile inviare il file.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>È stato superato il limite di upload e/o il numero massimo di allegati (<%MaxAttachmentsSize%>)</p>",
        "203": "Impossibile inviare il file.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Il tipo di file non è consentito.</p>",
        "204": "Spiacenti, il tuo messaggio è troppo lungo, Scrivi un messaggio più breve",
        "240": "Spiacenti, ma non è possibile avviare una nuova chat al momento. Riprova più tardi",
        "364": "E-mail non valida",
        "401": "Non è possibile autorizzare la sessione di chat. Vuoi iniziare una nuova chat?",
        "404": "Non è stato possibile trovare la tua precedente sessione di chat. Vuoi iniziare una nuova chat?",
        "500": "Si è verificato un errore inaspettato associato al servizio. Vuoi chiudere e iniziare una nuova chat?",
        "503": "Il servizio non è attualmente disponibile o è occupato. Vuoi chiudere e ricominciare una nuova chat?",
        "ChatUnavailable": "Spiacenti, ma non è possibile avviare una nuova chat al momento. Riprova più tardi",
        "CriticalFault": "La sessione della chat è terminata a causa di un errore sconosciuto. Ci scusiamo per l'inconveniente",
        "StartFailed": "Si è verificato un errore durante l'avvio della sessione chat. Verifica la connessione e di aver inviato correttamente tutte le informazioni richieste, quindi riprova",
        "MessageFailed": "Il tuo messaggio non è stato ricevuto. Riprova",
        "RestoreFailed": "Spiacenti, non è possibile ripristinare la sessione della chat a causa di un errore sconosciuto",
        "TransferFailed": "Impossibile trasferire la chat al momento. Riprova più tardi",
        "FileTransferSizeError": "Impossibile inviare il file.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Le dimensioni del file sono superiori a quelle consentite (<%MaxSizePerFile%>)</p>",
        "InviteFailed": "Impossibile generare l'invito al momento. Riprova più tardi",
        "ChatServerWentOffline": "I messaggi al momento richiedono più tempo del normale per essere recapitati. Ci scusiamo per il ritardo",
        "RestoredOffline": "I messaggi al momento richiedono più tempo del normale per essere recapitati. Ci scusiamo per il ritardo",
        "Disconnected": "<div style='text-align:center'>Connessione persa</div>",
        "Reconnected": "<div style='text-align:center'>Connessione ripristinata</div>",
        "FileSendFailed": "Impossibile inviare il file.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>Si è verificata una disconnessione inesperata. Riprovare?</p>",
        "Generic": "<div style='text-align:center'>Si è verificato un errore sconosciuto</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE": "Tipo di file non valido. Sono consentite solo immagini",
        "pureengage-v3-rest-LIMIT_FILE_SIZE": "Le dimensioni del file sono superiori a quelle consentite",
        "pureengage-v3-rest-LIMIT_FILE_COUNT": "Il numero massimo di file allegati ha superato il limite",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER": "Configurazione trasferimento chiave-api-x non valida",
        "pureengage-v3-rest-INVALID_ENDPOINT": "Configurazione trasferimento non valida",
        "pureengage-v3-rest-INVALID_NICKNAME": "Il nome è richiesto",
        "pureengage-v3-rest-AUTHENTICATION_REQUIRED": "Non è possibile autorizzare la sessione di chat.",
        "purecloud-v2-sockets-400": "Spiacenti, si è verificato un errore. Verifica la connessione e di aver inviato correttamente tutte le informazioni richieste, quindi riprova.",
        "purecloud-v2-sockets-500": "Spiacenti, si è verificato un errore imprevisto con il servizio.",
        "purecloud-v2-sockets-503": "Spiacenti, il servizio non è disponibile al momento."
      }
    }
  }
}