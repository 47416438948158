import LoaderReducer from './LoaderReducer';
import PageContentReducer from './PageContentReducer';
import NotificationReducer from './NotificationReducer';
import SelectLanguageReducer from './SelectLanguageReducer';
import LoginReducer from './LoginReducer';
import UserInfoReducer from './UserInfoReducer';
import SearchReducer from './SearchReducer';
import HomeReducer from './HomeReducer';
import CartReducer from './CartReducer';
import ContactInfoReducer from './ContactInfoReducer';
import FinancialInformationReducer from './FinancialInformationReducer';
import ProductListReducer from './ProductListReducer';
import ProductDetailReducer from './ProductDetailReducer';
import OrderListReducer from './OrderListReducer';
import OrderDetailsReducer from './OrderDetailsReducer';
import OrderTemplateReducer from './OrderTemplateReducer';
import OrderTemplateDetailsReducer from './OrderTemplateDetailsReducer';
import InvoicesReducer from './InvoicesReducer';
import CustomerReportReducer from './CustomerReportReducer';
import PromotionsReducer from './PromotionsReducer';
import OrderConfirmReducer from './OrderConfirmReducer';
import creditInformationReducer from './CreditInformationReducer';
import CheckoutReducer from './CheckoutReducer';
import UserMaintenanceReducer from './UserMaintenanceReducer';
import AccountDataReducer from './AccountDataReducer';
import AccountStatementReducer from './AccountStatementReducer';
import ClaimHistoryReducer from './ClaimHistoryReducer';
import MarketingReducer from './MarketingReducer';
import CoolerReducer from './CoolerReducer';
import FavoritesReducer from './FavoritesReducer';
import LoyaltyReducer from './LoyaltyReducer';
import NotifyMeSubscriptionReducer from './NotifyMeSubscriptionReducer';
import MySalesReducer from './MySalesReducer';
import MicrosoftChatBotReducer from "./MicrosoftChatBotReducer";

const rootReducer = {
    isLoading: LoaderReducer,
    pageContent: PageContentReducer,
    notification: NotificationReducer,
    countryDetails: SelectLanguageReducer,
    loginPage: LoginReducer,
    userDetails: UserInfoReducer,
    searchDetails: SearchReducer,
    homePageData: HomeReducer,
    cartDetails: CartReducer,
    contactInfo: ContactInfoReducer,
    financialInfoData: FinancialInformationReducer,
    products: ProductListReducer,
    productDetailData: ProductDetailReducer,
    orderListData: OrderListReducer,
    orderDetailData: OrderDetailsReducer,
    orderTemplate: OrderTemplateReducer,
    orderTemplateDetails: OrderTemplateDetailsReducer,
    customerReport: CustomerReportReducer,
    promotionsData: PromotionsReducer,
    confirmationData: OrderConfirmReducer,
    invoicesDetails: InvoicesReducer,
    creditInformation: creditInformationReducer,
    checkoutData: CheckoutReducer,
    userMaintenanceDetails: UserMaintenanceReducer,
    commonData: AccountDataReducer,
    claimHistoryData: ClaimHistoryReducer,
    marketing: MarketingReducer,
    coolers: CoolerReducer,
    favorites: FavoritesReducer,
    loyalty: LoyaltyReducer, 
    mySales: MySalesReducer, 
    notifyMeData: NotifyMeSubscriptionReducer,
    accountStatement: AccountStatementReducer,
    microsoftChatBot: MicrosoftChatBotReducer
};

export default rootReducer;
